import React, { PureComponent } from 'react';
import { debounce, toFinite } from 'lodash';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import units from 'units-css';
import RenderPortal from "./RenderPortal";
import log from "../../../util/log";

//This code is mostly taken from https://github.com/matt-d-rat/react-middle-truncate
//I needed to make some changes, and I could have forked it, but I only had minor changes, and the whole thing fits in one file, so meh.

let DEFAULT_CANVAS = document.createElement('canvas');
const DEFAULT_FONT_WEIGHT = 400;
const DEFAULT_FONT_STYLE = 'normal';

const getMeasureCanvasCtx = () => {

  //Try accessing the measure canvas before using, in case it's a dead object.
  let thisCanvas = DEFAULT_CANVAS;
  let ctx = null;
  try{
    ctx = thisCanvas.getContext('2d');
  }
  catch(err){
    log.log('unable to fetch valid measuring canvas', err);
    thisCanvas = null;
    ctx = null;
  }

  if(!thisCanvas || !ctx){
    try{
      thisCanvas = document.createElement('canvas');
      ctx = thisCanvas.getContext('2d');
      DEFAULT_CANVAS = thisCanvas;
    }
    catch(err){
      log.warn('unable to create valid measuring canvas', err);
      thisCanvas = null;
      ctx = null;
    }
  }

  return ctx;
}

const measureHeight = (size, lineHeight) => {
  // If the line-height is unitless,
  // multiply it by the font size.
  if (!lineHeight.unit) {
    return units.parse(
      `${size.value * lineHeight.value}${size.unit}`
    );
  }

  // units-css requires the user to provide
  // DOM nodes for these units. We don't want
  // to pollute our API with that for the time being.
  const unitBlacklist = ['%', 'ch', 'cm', 'em', 'ex'];
  if (unitBlacklist.indexOf(lineHeight.unit) !== -1) { // eslint-disable-line no-magic-numbers
    throw new Error(
      `We do not currently support the unit ${lineHeight.unit}
      from the provided line-height ${lineHeight.value}.
      Unsupported units include ${unitBlacklist.join(', ')}.`
    );
  }

  // Otherwise, the height is equivalent
  // to the provided line height.
  // Non-px units need conversion.
  if (lineHeight.unit === 'px') {
    return lineHeight;
  }
  return units.parse(
    units.convert(lineHeight, 'px')
  );
};

const measureText = ({
                       text,
                       fontFamily,
                       fontSize = '',
                       lineHeight = 1,
                       fontWeight = DEFAULT_FONT_WEIGHT,
                       fontStyle = DEFAULT_FONT_STYLE,
                       canvasCtx
                     }) => {
  canvasCtx.font = `${fontWeight} ${fontStyle} ${fontSize} ${fontFamily}`;

  const measure = (line) => {
    return {
      text: line,
      width: units.parse(`${canvasCtx.measureText(line).width}px`),
      height: measureHeight(
        units.parse(fontSize, 'fontSize'),
        units.parse(lineHeight, 'lineHeight')
      )
    };
  };

  // If multiline, measure the bounds
  // of all of the lines combined
  if (Array.isArray(text)) {
    return text
      .map(measure)
      .reduce((prev, curr) => {
        const width = curr.width.value > prev.width.value
          ? curr.width : prev.width;
        const height = units.parse(
          `${prev.height.value + curr.height.value}${curr.height.unit}`
        );
        const longest = curr.text.length > prev.text.length
          ? curr.text : prev.text;
        return { width, height, text: longest };
      });
  }

  return measure(text);
};

const getStartOffset = (start, text) => {
  if (start === '' || start === null) {
    return 0;
  }

  if (!isNaN(parseInt(start, 10))) {
    return Math.round(toFinite(start));
  }

  const result = new RegExp(start).exec(text);
  return result ? result.index + result[0].length : 0;
};

const getEndOffset = (end, text) => {
  if (end === '' || end === null) {
    return 0;
  }

  if (!isNaN(parseInt(end, 10))) {
    return Math.round(toFinite(end));
  }

  const result = new RegExp(end).exec(text);
  return result ? result[0].length : 0;
};

// A React component for truncating text in the middle of the string.
//
// This component automatically calculates the required width and height of the text
// taking into consideration any inherited font and line-height styles, and compares it to
// the available space to determine whether to truncate or not.

// By default the component will truncate the middle of the text if
// the text would otherwise overflow using a position 0 at the start of the string,
// and position 0 at the end of the string.
//
// You can pass start and end props a number to offset this position, or alternatively
// a Regular Expression to calculate these positions dynamically against the text itself.
class VFMiddleTruncate extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    ellipsis: PropTypes.string,
    end: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(RegExp), PropTypes.string]),
    onResizeDebounceMs: PropTypes.number,
    smartCopy: PropTypes.oneOfType([PropTypes.oneOf(['partial', 'all']), PropTypes.bool]),
    start: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(RegExp), PropTypes.string]),
    style: PropTypes.object,
    drawGearIcon: PropTypes.func,
    text: PropTypes.string
  };

  static defaultProps = {
    className: '',
    ellipsis: '...',
    end: 0,
    onResizeDebounceMs: 100,
    smartCopy: 'all',
    start: 0,
    style: {},
    text: ''
  };

  constructor(props) {
    super(props);

    // Debounce the parsing of the text so that the component has had time to render its DOM for measurement calculations
    this.parseTextForTruncation = debounce(this.parseTextForTruncation.bind(this), 0);

    // Debounce the onResize handler
    this.onResize = debounce(this.onResize.bind(this), props.onResizeDebounceMs);
  }

  state = {
    truncatedText: this.props.text,
    start: getStartOffset(this.props.start, this.props.text),
    end: getEndOffset(this.props.end, this.props.text),
  };

  componentDidMount() {
    this.parseTextForTruncation(this.props.text);
    window.addEventListener('resize', this.onResize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.parseTextForTruncation(nextProps.text);
    }

    if (nextProps.start !== this.props.start) {
      this.setState({ start: getStartOffset(nextProps.start, nextProps.text) });
    }

    if (nextProps.end !== this.props.end) {
      this.setState({ end: getEndOffset(nextProps.end, nextProps.text) });
    }
  }

  componentWillUnmount() {
    // Cancel any pending debounced functions
    this.onResize.cancel();
    this.parseTextForTruncation.cancel();

    window.removeEventListener('resize', this.onResize);
  }

  onCopy = event => {
    const { smartCopy } = this.props;

    // If smart copy is not enabled, simply return and use the default behaviour of the copy event
    if (!smartCopy) {
      return;
    }

    const selectedText = window.getSelection().toString();

    // If smartCopy is set to partial or if smartCopy is set to all and the entire string was selected
    // copy the original full text to the user's clipboard
    if (smartCopy === 'partial' || (smartCopy === 'all' && selectedText === this.state.truncatedText)) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData;

      clipboardData.setData('text/plain', this.props.text);
    }
  }

  onResize() {
    this.parseTextForTruncation(this.props.text);
  }

  getTextMeasurement = ref => {
    const node = findDOMNode(ref);
    const text = node.textContent;

    const {
      fontFamily,
      fontSize,
      fontWeight,
      fontStyle
    } = window.getComputedStyle(node);

    let canvasCtx = getMeasureCanvasCtx();
    if(!canvasCtx){
      log.warn('unable to pull canvas ctx, defaulting to component measure');
      const offsetWidth = node && node.offsetWidth ? node.offsetWidth : 0;
      const offsetHeight = node && node.offsetHeight ? node.offsetHeight : 0;
      return {
        width: units.parse(offsetWidth, 'px'),
        height: units.parse(offsetHeight, 'px')
      };
    }

    const { width, height } = measureText({
      text,
      fontFamily,
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight: 1,
      canvasCtx,
    });

    return { width, height };
  }

  getComponentMeasurement = () => {
    const node = findDOMNode(this.refs.component);

    const offsetWidth = node && node.offsetWidth ? node.offsetWidth : 0;
    const offsetHeight = node && node.offsetHeight ? node.offsetHeight : 0;

    return {
      width: units.parse(offsetWidth, 'px'),
      height: units.parse(offsetHeight, 'px')
    };
  }

  calculateMeasurements() {
    return {
      component: this.getComponentMeasurement(),
      ellipsis: this.getTextMeasurement(this.refs.ellipsis),
      text: this.getTextMeasurement(this.refs.text)
    };
  }

  truncateText = measurements => {
    const { text, ellipsis } = this.props;
    const { start, end } = this.state;

    if (measurements.component.width.value <= measurements.ellipsis.width.value) {
      return ellipsis;
    }

    const delta = Math.ceil(measurements.text.width.value - measurements.component.width.value);
    const totalLettersToRemove = Math.ceil( delta / measurements.ellipsis.width.value);
    const middleIndex = Math.round(text.length / 2);

    const preserveLeftSide = text.slice(0, start);
    const leftSide = text.slice(start, middleIndex - totalLettersToRemove);
    const rightSide = text.slice(middleIndex + totalLettersToRemove, text.length - end);
    const preserveRightSide = text.slice(text.length - end, text.length);

    return `${preserveLeftSide}${leftSide}${ellipsis}${rightSide}${preserveRightSide}`;
  }

  parseTextForTruncation(text) {
    const measurements = this.calculateMeasurements();

    const truncatedText =
      Math.round(measurements.text.width.value) > Math.round(measurements.component.width.value)
        ? this.truncateText(measurements)
        : text;

    this.setState(() => ({ truncatedText }));
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { text, ellipsis, style, onResizeDebounceMs, smartCopy, drawGearIcon, ...otherProps } = this.props;
    const { truncatedText } = this.state;

    const componentStyle = {
      ...style,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    };

    const hiddenStyle = {
      display: 'none'
    };


    //One of my changes here is to render the truncatedText that gets measured in an invisible 0 height div.
    //This allows us to measure (and actually render) the string in place, while still yeilding a result
    //that has display: inline-block

    return (
      <>
        <div className="invisible" style={{height: '0px'}}>
          <div
            ref="component"
            style={componentStyle}
            onCopy={this.onCopy}
            {...otherProps}>
            <span ref="text" style={hiddenStyle}>{text}</span>
            <span ref="ellipsis" style={hiddenStyle}>{ellipsis}</span>

            { truncatedText }
          </div>
        </div>
        <div style={{
          ...style,
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}>
          { truncatedText }
          {drawGearIcon && drawGearIcon()}
        </div>
      </>
    );
  }
}

export default VFMiddleTruncate;
export { VFMiddleTruncate };
