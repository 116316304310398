import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";
import c from '../../../../util/const'
import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import Promise from "bluebird";
import accountActions from "../../../../actions/account-actions";
import modalActions from "../../../../actions/modal-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import _ from "lodash";
import companyLogo from "../../../../resources/logo-w-text-blue.png";
import Image from "../../elements/Image";
import UserBadge from "../../badges/UserBadge";
import colors from "../../../../util/colors";
import api from "../../../../util/api";

class InformationServicesCtrl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      publisherLogos : {},
      publisherSubscriptions : {}
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    this.props.updatePublisherList().then(() => this.rebuildPublisherState())

    // sapi.Contacts.list()
    //   .then((res) => {
    //     log.log('GOT CONTACTS LIST', res)
    //   })
  }

  rebuildPublisherState(){
    let { publisherList } = this.props;

    //local state, not populated if we can't get a logo.
    let publisherLogos = {};
    let publisherSubscriptions = {};

    let promises = [];
    _.each(publisherList, (p) => {
      promises.push(
        this.fetchLogo(p.uid)
          .then((dataUrl) => {
            if(dataUrl){
              publisherSubscriptions[p.uid] = p.subscribed_flag;
              publisherLogos[p.uid] = dataUrl;
            }
          })
      );
    })

    Promise.all(promises)
      .then((res) => {
        this.setState({
          publisherLogos,
          publisherSubscriptions
        })
      })
  }

  fetchLogo(uid){
    return new Promise((resolve, reject) => {
      if(this.state.publisherLogos[uid]){
        resolve(this.state.publisherLogos[uid]);
        return;
      }

      api.AcctLogo.logo(uid)
        .then((res) => {
          log.log('got logo', res);
          let dataUrl = _.get(res, 'data.logo');
          resolve(dataUrl);
        })
        .catch((err) => {
          log.log('error fetching logo', err);
          resolve(null);
        })
    })
  }

  updateIsSubscribed(uid, isSubscribed){
    let copy = _.extend({}, this.state.publisherSubscriptions);
    copy[uid] = isSubscribed;
    this.setState({publisherSubscriptions: copy});
  }

  togglePublisher(uid, evt){
    this.updateIsSubscribed(uid, evt.target.checked);

    let promise = null;
    if(evt.target.checked){
      promise = sapi.Contacts.subscribePublisher(uid);
    }
    else{
      promise = sapi.Contacts.unsubscribePublisher(uid);
    }

    promise
      .then((res) => {
        log.log('change subscribe success', res);
        this.props.updatePublisherList().then(() => this.rebuildPublisherState())
      })
      .catch((err) => {
        log.log('error changing subscribe status', err);
        this.props.updatePublisherList().then(() => this.rebuildPublisherState())
      })
  }

  renderPublisher(publisher){
    let { t } = this.props;
    let {
      publisherLogos,
      publisherSubscriptions
    } = this.state;
    let logo = publisherLogos[publisher.uid];
    let isSubscribed = !!(publisherSubscriptions[publisher.uid]);

    return (
      <div className="d-flex mb-3">
        <div className="ml-3 mr-2">
              <span className="custom-control custom-switch custom-switch-lg">
                <input type="checkbox"
                       className="custom-control-input"
                       onChange={(evt) => this.togglePublisher(publisher.uid, evt)}
                       checked={isSubscribed}
                       id={`is-subscribed-checkbox-${publisher.uid}`}/>
                  <label className="custom-control-label" htmlFor={`is-subscribed-checkbox-${publisher.uid}`}/>
              </span>
        </div>
        <div>
          <Image src={logo || c.BLANK_LOGO}
                 alt={t('Logo')}
                 imgHeight={50}/>
          <h6 style={{marginTop: '5px'}} className="mb-0 font-weight-bold">
            {publisher.title}
          </h6>
          <p>
            {publisher.description}
          </p>
        </div>

      </div>
    )
  }

  render() {
    let { publisherList } = this.props;
    return (
      <div style={Account.styles.wideRowContents} className={'mt-3 mb-3'}>

        <div className="row">
          {publisherList && _.map(publisherList, (publisher) => {
            return (
              <div key={publisher.uid} className="col-6">
                {this.renderPublisher(publisher)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

InformationServicesCtrl.propTypes = {
  doClose: PropTypes.func.isRequired,
  onRef: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    publisherList: state.shared.publisherList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(InformationServicesCtrl)));
