import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import modal from "../../reducers/modal";
import log from "../../util/log";
import {withVFTranslation} from "../../util/withVFTranslation";

class ContactConfirmDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    const {modalProps} = this.props;

    log.log('contact confirm mount', modalProps);
    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, t} = this.props;
    let { contactName, headerText, bodyText } = modalProps;

    let okBtn = modalProps.okBtn || t('OK');
    let cancelBtn = modalProps.cancelBtn || t('Cancel');

    return (
      <div className="modal-content">
        <div className="modal-body position-relative text-center pt-5 mb-3">
          <button type="button" className="close" style={styles.topLeftButton} onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
          <p className="secondary-text-color">{headerText}</p>
          {contactName && <p className="font-weight-bold">{contactName}</p> }
          <p className="secondary-text-color text-left">{bodyText}</p>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{cancelBtn}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this, true)}>{okBtn}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  topLeftButton : {
    position : 'absolute',
    top: '20px',
    left : '30px',
    fontSize : '60px',
    cursor: 'pointer',
    opacity: 1
  },
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

ContactConfirmDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactConfirmDialog));
