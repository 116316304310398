import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {withVFTranslation} from "../../util/withVFTranslation";
import _ from "lodash";
import log from "../../util/log";
import platformHelper from "../../util/platform-helper";
import ExpandableRow from "../partials/components/ExpandableRow";
import c from "../../util/const";
import sapi from "../../util/sapi";
import downloadActions from "../../actions/download-actions";

class ElectronAppDownloadDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      osType : platformHelper.OS_TYPES.UNKNOWN,
      showOtherPlatforms : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
    this.detectPlatform();
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  onEscapeKey(){
    this.closeModal();
  }

  detectPlatform(){
    let ua = _.get(window, 'navigator.userAgent', '');
    let thisPlatform = platformHelper.getOSType(ua);
    this.setState({
      osType : thisPlatform
    })
  }

  showAdditionalOptionsClick(){
    this.setState({showOtherPlatforms : !this.state.showOtherPlatforms})
  }

  renderOSDownloadButton(osType){
    let {
      t
    } = this.props;

    let buttonContent = null;
    if(osType === platformHelper.OS_TYPES.WINDOWS){
      buttonContent =
        <a className="btn btn-primary btn-lg electron-download-btn" href={c.electron.WINDOWS_DOWNLOAD_FILEPATH}>
          <div className="d-flex">
            <i className="icon ion-social-windows" />
            <div>
              <div className="top-text">
                {t("Download Verifyle for Windows")}
              </div>
              <div className="bottom-text">
                {c.electron.WINDOWS_DOWNLOAD_FILENAME} {c.electron.WINDOWS_DOWNLOAD_FILESIZE}
              </div>
            </div>
          </div>
        </a>
    }
    else if(osType === platformHelper.OS_TYPES.MAC){
      buttonContent =
        <a className="btn btn-primary btn-lg electron-download-btn" href={c.electron.MAC_DOWNLOAD_FILEPATH}>
          <div className="d-flex">
            <i className="icon ion-social-apple"/>
            <div>
              <div className="top-text">
                {t("Download Verifyle for MacOS")}
              </div>
              <div className="bottom-text">
                {c.electron.MAC_DOWNLOAD_FILENAME} {c.electron.MAC_DOWNLOAD_FILESIZE}
              </div>
            </div>
          </div>
        </a>
    }

    if(!buttonContent){
      return null;
    }

    return (
      <div key={osType} className="text-center" style={{paddingBottom: '10px'}}>
        {buttonContent}
      </div>
    )
  }

  renderButtonSection() {
    let { t } = this.props;
    let {
      osType,
      showOtherPlatforms
    } = this.state;

    if(osType === platformHelper.OS_TYPES.WINDOWS){
      return (
        <div>
          {this.renderOSDownloadButton(osType)}
          <ExpandableRow isActive={showOtherPlatforms}>
            <div>
              <div >
                {this.renderOSDownloadButton(platformHelper.OS_TYPES.MAC)}
              </div>
            </div>
          </ExpandableRow>
          <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <a className="btn btn-sm btn-link primary-color" onClick={() => this.showAdditionalOptionsClick()}>
              {showOtherPlatforms ? t("Hide additional options") : t("Show all download options")}
            </a>
          </div>
        </div>
      )
    }
    else{
      return (
        <div>
          <div>
            {this.renderOSDownloadButton(platformHelper.OS_TYPES.MAC)}
          </div>
          <ExpandableRow isActive={showOtherPlatforms}>
            <div>
              {this.renderOSDownloadButton(platformHelper.OS_TYPES.WINDOWS)}
            </div>
          </ExpandableRow>
          <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <a className="btn btn-sm btn-link primary-color" onClick={() => this.showAdditionalOptionsClick()}>
              {showOtherPlatforms ? t("Hide additional options") : t("Show all download options")}
            </a>
          </div>
        </div>
      )
    }
  }

  renderForceLinks(){
    let {t} = this.props;
    let {
      osType
    } = this.state;

    let text = null;
    let icon = null;
    if(osType === platformHelper.OS_TYPES.WINDOWS){
      text = t("Download Verifyle for Windows");
      icon = <i className="icon ion-social-windows" />
    }
    else{
      text = t("Download Verifyle for MacOS");
      icon = <i className="icon ion-social-apple" />
    }
    return (
      <div className="text-center">
        <a className="btn btn-primary btn-lg electron-download-btn" target="_blank" href={c.links.electronDownloadPage}>
          <div className="d-flex">
            {icon}
            <div>
              {text}
            </div>
          </div>
        </a>
      </div>
    )
  }

  render() {
    let {t} = this.props;

    let {
      isForceVersion,
      versionComment
    } = this.props.modalProps;

    let title = t("Install Verifyle on your Computer")
    if(isForceVersion){
      title = t("New Version Available")
    }

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          {!isForceVersion &&
            <>
              <p>
                {t("Verifyle is now available to install on your computer! This will give you convenient access to your Verifyle account while maintaining the highest levels of privacy and security for you and for your Contacts. You will also benefit from reliable native notifications, so you can be sure never to miss out on important communications.")}
              </p>
              <p style={{paddingBottom: '20px'}}>
                {t("We currently support Windows 10 or newer and MacOS 11 or newer.")}
              </p>
            </>
          }
          {isForceVersion && versionComment &&
            <p className="text-center" style={{padding: '30px 0px'}}>
              {versionComment}
            </p>
          }
          {isForceVersion && !versionComment &&
            <p className="text-center" style={{padding: '30px 0px'}}>
              {t("There is a new version of this app available.  Please download and update.")}
            </p>
          }
          {!isForceVersion && this.renderButtonSection()}
          {isForceVersion && this.renderForceLinks()}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url)),
  };
};

ElectronAppDownloadDialog.MODAL_LARGE = true;

ElectronAppDownloadDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ElectronAppDownloadDialog));
