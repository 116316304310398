import React, {Component} from 'react';
import PropTypes from 'prop-types';
import utils from "../../../util/util";
import colors from "../../../util/colors";

class DocExtensionPlaceholder extends Component {

  render() {
    let { filename, size } = this.props;

    let extension = utils.getFilenameExtension(filename);
    if (extension.length > 4) {
      extension = extension.substr(0, 4);
    }
    let style = null;
    if(size === 'md'){
      style = styles.md;
    }
    else if(size === 'lg'){
      style = styles.lg;
    }

    return (
      <>
        <div style={style.extensionWrap}>
          <i style={style.extensionIcon} className="icon icomoon-doc" />
          <div style={style.extensionText}>
            {extension}
          </div>
        </div>
      </>
    )
  }
}

const styles = {
  md : {
    extensionWrap : {
      display: 'flex',
      alignItems: 'center',
      position : 'relative',
      textAlign : 'center',
      width: '50px',
      height: '50px',
      margin: 'auto'
    },
    extensionText : {
      position : 'absolute',
      color : colors.PRIMARY,
      top: '15px',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: "Courier New,Courier,monospace",
      letterSpacing: '.5px',
      width: '100%'
    },
    extensionIcon : {
      position : 'absolute',
      color : colors.LIGHT_GREY,
      fontSize: '50px',
      top: '0px',
      width: '100%'
    }
  },
  lg : {
    extensionWrap : {
      display: 'flex',
      alignItems: 'center',
      position : 'relative',
      textAlign : 'center',
      width: '100px',
      height: '100px',
      margin: 'auto'
    },
    extensionText : {
      position : 'absolute',
      color : colors.PRIMARY,
      top: '25px',
      fontSize: '25px',
      fontWeight: '700',
      fontFamily: "Courier New,Courier,monospace",
      letterSpacing: '.5px',
      width: '100%'
    },
    extensionIcon : {
      position : 'absolute',
      color : colors.LIGHT_GREY,
      fontSize: '90px',
      top: '0px',
      width: '100%'
    }
  }
}

DocExtensionPlaceholder.LG_HEIGHT_PX = '100px';
DocExtensionPlaceholder.MD_HEIGHT_PX = '50px';

DocExtensionPlaceholder.propTypes = {
  filename: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['md', 'lg']),
}

DocExtensionPlaceholder.defaultProps = {
  size: "md"
}

export default DocExtensionPlaceholder;
