import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import c from '../../util/const';
import colors from '../../util/colors';
import vfLocalStorage from "../../util/local-storage";
import vfSessionStorage from "../../util/session-storage";
import sharedActions from '../../actions/shared-actions';
import modalActions from '../../actions/modal-actions';
import Header from "../partials/components/Header";
import log from "../../util/log";
import Loading from "../partials/util/Loading";
import Button from "../partials/elements/Button";
import sapi from "../../util/sapi";
import _ from "lodash";
import GuestRow from "../partials/rows/GuestRow";
import ThreadRow from "../partials/rows/ThreadRow";
import classNames from "classnames";
import ScrollingAccordion from "../partials/components/ScrollingAccordion";
import WorkspaceRow from "../partials/rows/WorkspaceRow";
import ScrollingTabView from "../partials/components/ScrollingTabView";
import ExpandableRow from "../partials/components/ExpandableRow";
import Scroll from "react-scroll";
import NotificationIcon from "../partials/components/NotificationIcon";
import ChatPanel from "../partials/chat/ChatPanel";
import DMPanel from "../partials/chat/DMPanel";
import workspaceActions from "../../actions/workspace-actions";
import Promise from "bluebird";
import homeActions from "../../actions/home-actions";
import moment from "moment";
import UploadHelper from "../partials/components/UploadHelper";
import SearchWindow from "../modals/SearchWindow";
import appActions from "../../actions/app-actions";
import {withRouter} from "react-router-dom";
import GuestList from "../partials/rows/GuestList";
import utils from "../../util/util";
import {Helmet} from "react-helmet";
import PendingMsgCache from "../../helpers/pending-msg-cache";
import querystring from "query-string";
import PlaceholderLoaders from "../partials/util/PlaceholderLoaders";
import {withVFTranslation} from "../../util/withVFTranslation";
import msgHelper from "../../helpers/msg-helper";
import threadActions from "../../actions/thread-actions";
import sentryHelper from "../../helpers/sentry-helper";
import UpgradeDialogNew from "../modals/UpgradeDialogNew";
import MerchantSetupWindow from "../modals/MerchantSetupWindow";
import localStorage from "../../util/local-storage";
import api from "../../util/api";

let scroll = Scroll.animateScroll;

const WORKSPACE_TAB = 'workspace';
const CONTACTS_TAB = 'contacts';

const TABVIEW_ID = 'home-scroll-tabview';

class Home extends Component {

  SORTS = {
    UNREAD : 'unread',
    READ : 'read',
    UPDATED_DATE_ASC : 'updated_date_asc',
    UPDATED_DATE_DESC : 'updated_date_desc',
    USER_FIRST_NAME_ASC : 'user_first_name_asc',
    USER_FIRST_NAME_DESC : 'user_first_name_desc',
    USER_LAST_NAME_ASC : 'user_last_name_asc',
    USER_LAST_NAME_DESC : 'user_last_name_desc',
    HOST_FIRST_NAME_ASC : 'host_first_name_asc',
    HOST_FIRST_NAME_DESC : 'host_first_name_desc',
    HOST_LAST_NAME_ASC : 'host_last_name_asc',
    HOST_LAST_NAME_DESC : 'host_last_name_desc',
    USER_EMAIL_ASC : 'user_email_asc',
    USER_EMAIL_DESC : 'user_email_desc',
    WORKSPACE_NAME_ASC : 'workspace_name_asc',
    WORKSPACE_NAME_DESC : 'workspace_name_desc'
  }

  constructor(props) {
    super(props);

    this.onAttachDocToThread = this.onAttachDocToThread.bind(this);
    this.onPdfSubmit = this.onPdfSubmit.bind(this);
    this.onRemoveGuest = this.onRemoveGuest.bind(this);
    this.guestClick = this.guestClick.bind(this);
    this.onDMRowFileDrop = this.onDMRowFileDrop.bind(this);

    this.refreshDMMessages = this.refreshDMMessages.bind(this);
    this.onDMPanelRef = this.onDMPanelRef.bind(this);
    this.onGuestListRef = this.onGuestListRef.bind(this);

    this.onContactSearchResult = this.onContactSearchResult.bind(this);

    let savedWsSort = vfLocalStorage.get(c.localstorage.wsSort);
    let savedContactSort = vfLocalStorage.get(c.localstorage.contactSort);

    this.state = {
      hasSelectedContactTabBefore : false,
      contactSort : savedContactSort || this.SORTS.UPDATED_DATE_DESC,
      workspaceSort : savedWsSort || this.SORTS.UPDATED_DATE_DESC,
      selected_tab : WORKSPACE_TAB,
      showArchivedWorkspaces : false,
      loading: false,
      activeChatPanelRef : null,
      guestListScrollRef : null,
      sortedContacts : null
    }
  }

  componentDidMount() {
    let { email } = this.props;

    //Important to set this only once the user has authenticated.
    if(email){
      vfLocalStorage.set(c.localstorage.email, email);
    }

    this.setState({loading:true})
    this.preSelectTabIfPossible();
    this.props.startup()
      .then(() => {
        return this.props.resolveUserLanguageSettings(this.props.i18n);
      })
      .then(() =>{
        this.setState({loading: false})

        //There are some subtle cases here.  Startup prompts are things like tutorial, change password window, account expiry window
        //We show this stuff first, and not in the main promise flow.  It's important that we don't wait for these startup prompts
        //due to some query string handler cases, such as unconfirmed users coming in via an unsubscribe link.
        //Showing these prompts first will cause them to show up BEHIND any other modals that get shown via querystring handling
        //which is what we want, that's a specific action that users took.  Once they've dealt with that, they might see some of these startup
        //prompts behind their initial action.
        this.showStartupPrompts()

        return this.processQueryStringIfNeeded()
      })
      .then((res) => {
        if(!res) {
          this.handleInitialTabSelection();
        }
      })
      .catch((err) =>{
        log.log('error on home startup', err);
        if(sapi.shouldUIErrorTriggerApplicationError(err)) {
          sentryHelper.captureMessage('home-startup-error', err);
          this.props.setApplicationError(err);
        }
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.contactSort !== this.state.contactSort ||
      prevProps.contacts !== this.props.contacts ||
      prevProps.directMessages !== this.props.directMessages) {
      this.updateSortedContacts();
    }
  }

  getSortOptions(){
    let { t } = this.props;
    return {
      [this.SORTS.UNREAD] : {
        icon: 'ion-ios-checkmark-outline',
        id: this.SORTS.UNREAD,
        display: t('Sort: Unread')
      },
      [this.SORTS.READ]: {
        icon: 'ion-ios-circle-outline',
        id: this.SORTS.READ,
        display: t('Sort: Read')
      },

      [this.SORTS.UPDATED_DATE_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.UPDATED_DATE_DESC,
        display: t('Sort: Updated Date')
      },
      [this.SORTS.UPDATED_DATE_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.UPDATED_DATE_ASC,
        display: t('Sort: Updated Date')
      },


      [this.SORTS.USER_FIRST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.USER_FIRST_NAME_ASC,
        display: t('Sort: First Name')
      },
      [this.SORTS.USER_FIRST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.USER_FIRST_NAME_DESC,
        display: t('Sort: First Name')
      },
      [this.SORTS.USER_LAST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.USER_LAST_NAME_ASC,
        display: t('Sort: Last Name')
      },
      [this.SORTS.USER_LAST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.USER_LAST_NAME_DESC,
        display: t('Sort: Last Name')
      },


      [this.SORTS.HOST_FIRST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.HOST_FIRST_NAME_ASC,
        display: t('Sort: Host First Name')
      },
      [this.SORTS.HOST_FIRST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.HOST_FIRST_NAME_DESC,
        display: t('Sort: Host First Name')
      },
      [this.SORTS.HOST_LAST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.HOST_LAST_NAME_ASC,
        display: t('Sort: Host Last Name')
      },
      [this.SORTS.HOST_LAST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.HOST_LAST_NAME_DESC,
        display: t('Sort: Host Last Name')
      },


      [this.SORTS.USER_EMAIL_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.USER_EMAIL_ASC,
        display: t('Sort: Email')
      },
      [this.SORTS.USER_EMAIL_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.USER_EMAIL_DESC,
        display: t('Sort: Email')
      },
      [this.SORTS.WORKSPACE_NAME_ASC] : {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.WORKSPACE_NAME_ASC,
        display: t('Sort: Workspace Name')
      },
      [this.SORTS.WORKSPACE_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.WORKSPACE_NAME_DESC,
        display: t('Sort: Workspace Name')
      }
    }
  }

  updateSortedContacts(){
    let sortedContacts = _.concat([], this.props.directMessages);
    sortedContacts = this.doSortContacts(sortedContacts);
    this.setState({
      sortedContacts
    })
  }

  processQueryStringIfNeeded(){
    return new Promise((resolve, reject) => {
      let {
        qs,
        qsActionNeeded,
        qsEmail,
        accountInfo,
        directMessages,
        accountInfoGuest,
        t
      } = this.props;
      if(qs && qsActionNeeded){
        log.log('handling querystring', qs, qsEmail, accountInfo);

        if(qsEmail && accountInfo && qsEmail.toLowerCase() !== accountInfo.login.toLowerCase()){
          //Bounce them out.  They have a valid session, but it's for a different user than
          //the notification was for.
          this.props.showAlert(
            t('Notification for different Account'),
            t('Another account was active on this device.  We have ended that session, so you can now log in and view the item you clicked.')
          )
          this.props.logout();
          vfLocalStorage.clearExceptEndpoint();
          this.props.history.push('/');

          //resolve true here so that subsequent actions aren't taken.
          resolve(true);
          return;
        }

        if(qs[c.querystring.goto_dnd]){
          //go to account page and deal with this
          this.props.history.push(`/account?doDND=true`);
          resolve(true);
          return;
        }
        else if(qs[c.querystring.goto_publisher_settings]){
          //go to account page and deal with this
          this.props.history.push(`/account?${c.querystring.goto_publisher_settings}`);
          resolve(true);
          return;
        }
        else if(_.has(qs, c.querystring.stripe_refresh) || _.has(qs, c.querystring.stripe_return)){
          if(_.has(qs, c.querystring.stripe_refresh)){
            this.props.history.push(`/account?${c.querystring.stripe_refresh}`);
          }
          else if(_.has(qs, c.querystring.stripe_return)){
            this.props.history.push(`/account?${c.querystring.stripe_return}`);
          }
          resolve(true);
          return;
        }
        else if(qs[c.querystring.goto_block_user] && qs.guest_uid){
          sapi.Contacts.get(qs.guest_uid)
            .then((res) => {
              //go to user and show block dialog
              this.props.showContactInfoWindow(qs.guest_uid, (res) => {
                log.log('show contact res', res);
                if(res){
                  this.props.updateDirectMessages();
                }
              })
            })
            .catch((err) => {
              log.log('error looking up guest from qs', err);
              //go to account page, same as DND
              this.props.history.push(`/account?doBlockUnfoundUser=true`);
            })
          resolve(true);
          return;
        }

        let handled = false;
        if(qs[c.querystring.guest_uid]){

          //then it's a DM
          let foundDM = _.find(directMessages, (dm) => dm.guest_uid === qs.guest_uid);
          if(!foundDM){
            this.props.setQsActionNeeded(false);
            log.error('unable to find dm in home');
            this.props.showAlert(t("Unable to Find Thread"), t("We were unable to locate this Thread.  It could have been deleted."));
            resolve(false);
          }
          else {
            let lastForum = null;
            if(qs[c.querystring.last_forum_id]){
              //last_forum_id check needed
              let { archivedWorkspaces, unarchivedWorkspaces} = this.props;
              let workspaces = _.concat(archivedWorkspaces, unarchivedWorkspaces);
              lastForum = _.find(workspaces, (ws) => ws.forum_id === qs[c.querystring.last_forum_id]);
            }
            if(lastForum){
              //Then the workspace will handle this.  Just shoot them over.
              this.props.history.push(`/workspace/${lastForum.forum_id}`);
            }
            else{
              //deal with link to doc, sign request, dm
              this.props.setQsActionNeeded(false);
              if(qs[c.querystring.sign_request_id]){

                sapi.DM.getSignatureRequest(qs[c.querystring.sign_request_id], qs[c.querystring.guest_uid])
                  .then((res) => {
                    let signatureRequest = msgHelper.formatSigningRequestForLegacyIfNeeded(res.data);
                    let foundSigner = _.find(signatureRequest, (r) => r.signer_uid === accountInfoGuest.guest_uid);
                    if(foundSigner && foundSigner.signed_date){
                      this.props.showAlert(t("Unable to Sign Document"), t("This document has already been signed.  You cannot sign it again."));
                    }
                    else{
                      let foundDM = _.find(directMessages, (dm) => dm.guest_uid === qs[c.querystring.guest_uid]);
                      this.selectDMDoc(foundDM, qs.doc_id, true)
                        .then(() => {
                          return utils.waitForCondition(() => { return !!this.state.activeChatPanelRef; })
                        })
                        .then(() => {
                          this.state.activeChatPanelRef.fulfillDMSignatureRequest(qs[c.querystring.mesg_id], qs.doc_id, signatureRequest, qs[c.querystring.sign_request_id]);
                          resolve(true);
                        })
                    }
                  })
                  .catch((err) => {
                    log.error('unable to find signature request', err);
                    this.props.showAlert(t("Unable to Sign Document"), t("We were unable to locate this signature request.  It could have been deleted."));
                    resolve(false);
                  })
              }
              else{
                this.guestClick(null, foundDM);
                resolve(true);
              }
            }
            handled = true;
          }
        }
        else if(qs[c.querystring.forum_id]){
          let { archivedWorkspaces, unarchivedWorkspaces} = this.props;
          let workspaces = _.concat(archivedWorkspaces, unarchivedWorkspaces);
          let found = _.find(workspaces, (ws) => ws.forum_id === qs.forum_id);
          if(found){
            handled = true;
            this.props.history.push(`/workspace/${found.forum_id}`);
          }
        }

        if(!handled){
          this.props.setQsActionNeeded(false);
          log.log('qs forum not found');
          resolve(false);
        }
        else{
          resolve(handled);
        }
      }
      else{
        this.props.setQsActionNeeded(false);
        //This is for routing outside of the main initial querystring.
        let searchString = _.get(this.props, 'location.search');
        let parsedSearch = querystring.parse(searchString);
        if(parsedSearch.guest_uid){
          let foundDM = _.find(directMessages, (dm) => dm.guest_uid === parsedSearch.guest_uid);
          this.guestClick(null, foundDM);
          resolve(true);
        }
        else{
          resolve(false);
        }
      }
    })
  }

  preSelectTabIfPossible(){
    let lastTab = vfLocalStorage.get(c.localstorage.lastSelectedHomeTab);
    if(lastTab){
      this.tabClick(lastTab);
      return true;
    }
    return false;
  }

  handleInitialTabSelection(){
    if(!this.preSelectTabIfPossible()){
      let { archivedWorkspaces, unarchivedWorkspaces } = this.props;
      //If there's no saved tab, land them on the workspaces tab, unless there aren't any workspaces.
      //In that case, put them on the contact tab.
      if(archivedWorkspaces.length === 0 && unarchivedWorkspaces.length === 0){
        this.tabClick(CONTACTS_TAB);
      }
      else{
        this.tabClick(WORKSPACE_TAB);
      }
    }
  }

  showStartupPrompts(){

    return this.doGeneratedPasswordStartupPrompt()
      .then((res) => {
        return this.doWarnAboutExpiryStartupPrompt()
      })
      .then((res) => {
        return this.doConfirmNamePrompt()
      })
      .then((res) => {
        return this.doTutorialStartupPrompt();
      })
      .then((res) => {
        return this.doInvalidEmailWarningIfNeeded();
      })
  }

  doTutorialStartupPrompt(){
    return new Promise((resolve, reject) => {
      let { accountInfo } = this.props;
      let introFlag = _.get(accountInfo, 'intro_status.webapp');
      if (introFlag) {
        sapi.AccountInfo.update({
            intro_status: { webapp: false }
          })
          .then((res) => {
            this.props.updateAccountInfo();
          })

        this.props.showTutorialWindow((res) => {
          log.log('show tutorial res', res);
          resolve(true);
        })
      }
      else{
        resolve(true);
      }
    })
  }

  doInvalidEmailWarningIfNeeded(){
    return new Promise((resolve, reject) => {
      let { accountInfo } = this.props;
      let email = _.get(accountInfo, 'login');
      if(email){
        sapi.AccountEmail.validationStatus(email)
          .then((res) => {
            if(utils.hasEmailValidationInvalidFlag(res.data)){
              let { t } = this.props;

              let cfmMessage = t("Your email address ") + "\"" + email + "\"" + t(" appears to be invalid. ") +
                t("Please check it for spelling errors. You may still use this email address, but it may not be able to receive messages.") +
                '\n\n' +
                t("This warning may be shown again if we encounter future delivery problems to this address.");

              this.props.showConfirm(
                t("Email Address could not be verified"),
                cfmMessage,
                (res) => {
                  if(res){
                    this.props.history.push(`/account?goToFixEmail=true`);
                  }
                  else{
                    sapi.AccountEmail.validationStatus(email, true)
                  }
                  resolve(true);
                }, t('Update address'), t('Use address anyway'));
            }
            else{
              resolve(true);
            }
          })
          .catch((err) => {
            log.log('error checking email validation status', err);
            resolve(true);
          })
      }
      else{
        resolve(true);
      }
    })
  }

  doConfirmNamePrompt(){
    return new Promise((resolve, reject) => {
      let { accountInfo } = this.props;
      let confirmName = _.get(accountInfo, 'intro_status.name');
      if (confirmName) {
        this.props.showConfirmName((res) => {
          log.log('show confirm name res', res);
          resolve(true);
        })
      }
      else{
        resolve(true);
      }
    })
  }

  doWarnAboutExpiryStartupPrompt(){
    return new Promise((resolve, reject) => {
      let { accountInfo, t } = this.props;

      // log.log('do warn about expiry', accountInfo);
      if (accountInfo && accountInfo['expiry_ttl'] <= c.account.ACC_EXPIRY_WARN_PERIOD) {
        this.props.updateStripeData()
          .then((stripeData) => {
            if (stripeData && stripeData.length > 0) {
              this.props.showConfirm(
                t("Payment Problem"),
                t("There appears to be something wrong with your payment.  Please update your payment method."),
                (res) => {
                  if(res){
                    this.props.history.push(`/account?goToFixPayment=true`);
                  }
                  resolve(true);
                }, t('Update Payment Method'), t('Cancel'));
            }
            else if(accountInfo['expiry_ttl'] <= 0){
              this.props.showConfirm(
                t("Your subscription has expired."),
                t("Your subscription has expired.  To continue your subscription, click \"Upgrade\" and enter your payment information."),
                (res) => {
                  if(res){
                    this.props.history.push(`/account?goToSubscriptionPage=true`);
                  }
                  resolve(true);
                }, t('Upgrade'), t('Cancel'));
            }
            else if (accountInfo['class_id'] === 100) {
              this.props.showConfirm(
                t("Your subscription expires soon."),
                t("Your Verifyle Pro subscription will expire soon.  To continue your subscription, click \"Upgrade\" and enter your payment information."),
                (res) => {
                  if(res){
                    this.props.history.push(`/account?goToSubscriptionPage=true`);
                  }
                  resolve(true);
                }, t('Upgrade'), t('Cancel'));
            }
            else {
              this.props.showConfirm(
                t("Your trial expires soon."),
                t("Your trial will expire soon.  To continue as a Verifyle Pro user, click \"Upgrade\" and enter your payment information."),
                (res) => {
                  if(res){
                    this.props.history.push(`/account?goToSubscriptionPage=true`);
                  }
                  resolve(true);
                }, t('Upgrade'), t('Cancel'));
            }
          })
          .catch((err) => {
            log.log('error pulling stripe data', err);
            //We still want to resolve true here. we're just signaling that these checks are finished.
            resolve(true);
          })
      }
      else{
        resolve(true);
      }
    })
  }

  doGeneratedPasswordStartupPrompt(){
    return new Promise((resolve, reject) => {
      let { accountInfo } = this.props;
      if(accountInfo && accountInfo.temp_pw_flag){
        this.props.showGeneratedPasswordWindow((res) => {
          log.log('generated password res', res);
          resolve(true);
        })
      }
      else{
        resolve(true);
      }
    })
  }

  tabClick(tab, preventInitialSelection) {

    if(tab !== CONTACTS_TAB && tab !== WORKSPACE_TAB){
      log.warn('unable to select tab.  It does not exist.', tab)
      return;
    }

    if(tab === this.state.selected_tab){
      log.log('tabClick cancelled, already selected');
      return;
    }

    this.setState({selected_tab: tab})

    if (tab === CONTACTS_TAB) {
      let isFirstTimeSelectingTab = !this.state.hasSelectedContactTabBefore;
      this.setState({ hasSelectedContactTabBefore : true });
      this.refreshContactList(true)
        .then(() => {
          if(!preventInitialSelection && isFirstTimeSelectingTab) {
            this.selectInitialDM();
          }
          else if(this.props.activeDM){
            this.scrollToDM(this.props.activeDM.guest_uid);
          }
        })
    }
    else {
      this.props.updateWorkspaces()
    }

    vfLocalStorage.set(c.localstorage.lastSelectedHomeTab, tab);
  }

  selectInitialDM(deletingDM){
    let { directMessages } = this.props;
    let mostRecentDM = null;
    _.each(directMessages, (dm) => {
      if(!deletingDM || deletingDM.guest_uid !== dm.guest_uid) {
        if (!mostRecentDM) {
          mostRecentDM = dm;
        }

        if (moment(dm.updated_date).isAfter(mostRecentDM.updated_date)) {
          mostRecentDM = dm;
        }
      }
    })

    if(mostRecentDM){
      return this.props.setActiveDM(mostRecentDM)
        .then((res) => {
          this.markDMAsViewed(mostRecentDM);
          this.scrollToDM(mostRecentDM.guest_uid);
          return res;
        })
    }
  }

  selectDMDoc(dm, doc_id, skipDocSelection){
    let wait = Promise.resolve(true);
    if(this.state.selected_tab !== CONTACTS_TAB){
      this.tabClick(CONTACTS_TAB, true);
      vfLocalStorage.set(c.localstorage.lastSelectedHomeTab, CONTACTS_TAB);
      wait = this.refreshContactList()
    }

    return wait
      .then(() => {
        return this.props.setActiveDM(dm);
      })
      .then(() => {
        this.markDMAsViewed(dm);
        this.scrollToDM(dm.guest_uid);

        if(!skipDocSelection) {
          let foundDoc = _.find(this.props.activeDMDocs, (doc) => doc.doc_id === doc_id);
          if (foundDoc) {
            setTimeout(() => {
              this.state.activeChatPanelRef.selectDocAndNavigate(foundDoc);
            })
          }
        }
      })
  }

  scrollToDM(guest_uid){
    let { sortedContacts, guestListScrollRef } = this.state;

    utils.waitForCondition(() => {
      return !!guestListScrollRef;
    })
      .then(() => {
        let index = _.findIndex(sortedContacts, (c) => { return guest_uid === c.guest_uid})
        guestListScrollRef.scrollToIndex(index);
      })
  }

  onContactSearchResult(guest_uid){
    this.scrollToDM(guest_uid);
  }

  markDMAsViewed(dm){
    if(!_.get(dm, 'notify_flag')){
      return;
    }

    return sapi.DM.mark(dm.guest_uid, 0)
      .then( () => {
        return Promise.all([
          this.props.updateDirectMessages()
        ])
      })
  }

  refreshContactList(checkImportQueue){

    return Promise.all([
        this.props.updateDirectMessages(checkImportQueue),
      ])
      .catch((err) => {
        log.error("Error loading contacts list", err);
      })
  }

  guestClick(guest, dm) {
    //Guarded against the dm not being present for a customer issue, could not reproduce.  VERIFYLE-WEB-3Q
    if(guest && guest.is_pending || !dm){
      return;
    }

    let wait = Promise.resolve(true);
    if(this.state.selected_tab !== CONTACTS_TAB){
      this.tabClick(CONTACTS_TAB, true);

      vfLocalStorage.set(c.localstorage.lastSelectedHomeTab, CONTACTS_TAB);
      wait = this.refreshContactList()
    }

    wait
      .then(() => {
        return this.props.setActiveDM(dm)
      })
      .then(() => {
        this.scrollToDM(dm.guest_uid);
        this.markDMAsViewed(dm);
      })
  }

  newContact(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.props.showAddContactWindow('', true, (res) => {
      if(res){
        //responses can come back in different ways when using bulk import.
        //Depending on if anything gets queued or not, we might get back a list on data, or data.contact
        //Parse that into an array, and then select the first in the list.
        let contactArray = null;
        if(_.isArray(_.get(res, 'data.contact'))){
          contactArray = _.get(res, 'data.contact');
        }
        else if(_.get(res, 'data')){
          contactArray = _.get(res, 'data');
        }
        else{
          contactArray = [res];
        }

        this.refreshContactList(true)
          .then(() => {
            if(!this.props.activeDM && contactArray.length === 0 && this.props.directMessages.length === 0){
              //If you have no activeDM, no contacts, and no directMessages already loaded, then we're in empty state territory
              //wait for something that isn't pending and set that active asap.
              utils.waitForCondition(() => {
                return !!_.find(this.props.directMessages, (dm) => !dm.is_pending);
              })
                .then(() => {
                  let found = _.find(this.props.directMessages, (dm) => !dm.is_pending);
                  if (found) {
                    this.props.setActiveDM(found)
                  }
                })
            }
            else if(contactArray.length === 1 || !this.props.activeDM) {
              let found = _.find(this.props.directMessages, (dm) => dm.guest_uid === contactArray[0].guest_uid);
              if (found) {
                this.props.setActiveDM(found)
              }
            }
          })
      }
    })
  }

  newWorkspace(evt){
    evt.preventDefault();
    evt.stopPropagation();
    let { t } = this.props;

    this.props.showNewWorkspace(t('New Workspace'), t('New Workspace Name'), t('Enter a name for your new Workspace'), (res) => {
      log.log('new workspace res', res);
      if(res){
        this.props.updateWorkspaces()
      }
    })
  }

  onWorkspaceClick(workspace){
    let {history} = this.props;
    history.push(`/workspace/${workspace.forum_id}`);
  }

  onArchiveClick(){
    let update = !this.state.showArchivedWorkspaces;

    this.setState({showArchivedWorkspaces : update})

    if(update) {
      setTimeout(() => {
        scroll.scrollMore(200, {
          containerId: TABVIEW_ID,
          duration: 300,
          smooth: true,
        })
      })
    }
  }

  refreshDMMessages(useDataDate){
    return this.props.threadAction.refreshActiveThreadMessages(useDataDate);
  }

  renderWorkspaceTabContents(){
    let { unarchivedWorkspaces, archivedWorkspaces, accountInfo, firstStartupCallsFinished, t } = this.props;
    let { showArchivedWorkspaces, loading } = this.state;

    if(!firstStartupCallsFinished || !archivedWorkspaces || !unarchivedWorkspaces){
      return null;
    }

    if(loading){
      return PlaceholderLoaders.renderWorkspacePlaceholderRows(10);
    }

    if(archivedWorkspaces.length === 0 && unarchivedWorkspaces.length === 0){
      return (
        <div className="text-center" style={{marginTop: '15vh'}}>
          <h4 className="dark-color">
            {t("You don't have any Workspaces")}
          </h4>
          <p className="secondary-text-color">
            {t("In Workspaces that you create, you have the power to make group threads and control exactly who sees what.")}
          </p>
          <div className="mt-5">
            <button className="btn btn-lg btn-primary"
                    onClick={this.newWorkspace.bind(this)}>
              {t("Add a Workspace")}
            </button>
          </div>
        </div>
      )
    }

    //Flag gets checked in sort.  Otherwise first/lkast name is null for hosts.
    _.each(_.concat(unarchivedWorkspaces, archivedWorkspaces), (ws) => {
      if(!ws.host_uid){
        ws.$first_name = accountInfo.first_name;
        ws.$last_name = accountInfo.last_name;
      }
      else{
        ws.$first_name = ws.first_name;
        ws.$last_name = ws.last_name;
      }
    })

    let sortedUnarchived = this.doSortWorkspaces(unarchivedWorkspaces);
    let sortedArchived = this.doSortWorkspaces(archivedWorkspaces);

    return (
      <Fragment>
        {sortedUnarchived.map((workspace) =>
          <WorkspaceRow key={workspace.forum_id}
                        row={workspace}
                        onItemClick={this.onWorkspaceClick.bind(this, workspace)}/>
        )}
        <Fragment>
          {sortedArchived.length > 0 &&
          <Fragment>
            <div className="d-flex flex-row workspace-row pt-2 pb-2"
                 onClick={this.onArchiveClick.bind(this)}>
              <div style={styles.notifyPlaceholder} />
              <div className="text-center"
                   style={styles.archiveRowIconWrap}>
                <i className="icon ion-ios-box-outline" />
              </div>
              <div>
                <h5 className="font-weight-bold m-0 pt-1">
                  {t("Archive")}
                  <i className={classNames('icon d-inline-block pl-5', (showArchivedWorkspaces ? 'ion-chevron-down' : 'ion-chevron-right'))}
                     style={styles.archiveRowTextChevron} />
                </h5>
              </div>
            </div>
            {showArchivedWorkspaces && sortedArchived.map((workspace) =>
              <WorkspaceRow key={workspace.forum_id}
                            row={workspace}
                            onItemClick={this.onWorkspaceClick.bind(this, workspace)}/>
            )}
          </Fragment>
          }
        </Fragment>
      </Fragment>
    )
  }

  onDMRowFileDrop = (guest, dm) => (files) => {
    log.log('got files home dm row', dm, files);

    if(!this.props.activeDM || this.props.activeDM.guest_uid !== dm.guest_uid){
      PendingMsgCache.addDocsToDmCache(dm.guest_uid, files);
    }
    else{
      utils.waitForCondition(() => {
          return _.get(this.props, 'activeDM.guest_uid') === dm.guest_uid;
        })
        .then(() => {
          this.state.activeChatPanelRef.chatFileDrop(files);
        })
    }

    this.props.setActiveDM(dm)
      .then(() => {
        this.markDMAsViewed(dm);
      })
  }

  onRemoveGuest(guest, dm){
    if(guest.is_pending){
      return;
    }

    let { activeDM } = this.props;
    if(dm.guest_uid === activeDM.guest_uid){
      this.selectInitialDM(activeDM)
    }
  }

  doUpdatedDateSort(contacts, isDesc) {

    //Note : We had some really handling here that I removed for bug 2566.
    if(isDesc) {
      return _.concat(
        _.sortBy((contacts), (contact) => {
          return -_.get(contact, 'updated_date') || -_.get(contact, 'created_date') || -1;
        })
      )
    }
    else {
      return _.concat(
        _.sortBy((contacts), (contact) => {
          return +_.get(contact, 'updated_date') || +_.get(contact, 'created_date') || 1;
        })
      )
    }
  }

  doSortContacts(contacts){
    let { contactSort } = this.state;

    if(contactSort === this.SORTS.READ){
      let contactsWithUnreadStatus = [];
      let contactsWithReadStatus = [];
      _.each(contacts, (c) => {
        let notifyFlag = _.get(c, 'notify_flag');
        if(notifyFlag){
          contactsWithUnreadStatus.push(c);
        }
        else{
          contactsWithReadStatus.push(c);
        }
      })
      return _.concat(
        this.doUpdatedDateSort(contactsWithReadStatus, false),
        this.doUpdatedDateSort(contactsWithUnreadStatus, false)
      )
    }
    else if(contactSort === this.SORTS.UNREAD){
      let contactsWithUnreadStatus = [];
      let contactsWithReadStatus = [];
      _.each(contacts, (c) => {
        let notifyFlag = _.get(c, 'notify_flag');
        if(notifyFlag){
          contactsWithUnreadStatus.push(c);
        }
        else{
          contactsWithReadStatus.push(c);
        }
      })
      return _.concat(
        this.doUpdatedDateSort(contactsWithUnreadStatus, true),
        this.doUpdatedDateSort(contactsWithReadStatus, true)
      )
    }
    if(contactSort === this.SORTS.UPDATED_DATE_DESC){
      return this.doUpdatedDateSort(contacts, true);
    }
    else if(contactSort === this.SORTS.UPDATED_DATE_ASC){
      return this.doUpdatedDateSort(contacts, false);
    }
    else if(contactSort === this.SORTS.USER_FIRST_NAME_DESC){
      return _.orderBy((contacts), [contact => contact['first_name'].toLowerCase()], ['desc']);
    }
    else if(contactSort === this.SORTS.USER_FIRST_NAME_ASC){
      return _.orderBy((contacts), [contact => contact['first_name'].toLowerCase()], ['asc']);
    }
    else if(contactSort === this.SORTS.USER_LAST_NAME_DESC){
      return _.orderBy((contacts), [contact => contact['last_name'].toLowerCase()], ['desc']);
    }
    else if(contactSort === this.SORTS.USER_LAST_NAME_ASC){
      return _.orderBy((contacts), [contact => contact['last_name'].toLowerCase()], ['asc']);
    }
    else if(contactSort === this.SORTS.USER_EMAIL_DESC){
      return _.orderBy((contacts), [contact => contact['email_address'].toLowerCase()], ['desc']);
    }
    else if(contactSort === this.SORTS.USER_EMAIL_ASC){
      return _.orderBy((contacts), [contact => contact['email_address'].toLowerCase()], ['asc']);
    }
    else{
      throw new Error('Unsupported Sort')
    }
  }

  doSortWorkspaces(workspaces){
    let { workspaceSort } = this.state;

    if(workspaceSort === this.SORTS.READ){
      let workspacesWithUnreadStatus = [];
      let workspacesWithReadStatus = [];
      _.each(workspaces, (c) => {
        let notifyFlag = _.get(c, 'notify_flag');
        if(notifyFlag){
          workspacesWithUnreadStatus.push(c);
        }
        else{
          workspacesWithReadStatus.push(c);
        }
      })
      return _.concat(
        _.sortBy((workspacesWithReadStatus), (ws) => {
          return ws.updated_date;
        }),
        _.sortBy((workspacesWithUnreadStatus), (ws) => {
          return ws.updated_date;
        }),
      )
    }
    else if(workspaceSort === this.SORTS.UNREAD){
      let workspacesWithUnreadStatus = [];
      let workspacesWithReadStatus = [];
      _.each(workspaces, (c) => {
        let notifyFlag = _.get(c, 'notify_flag');
        if(notifyFlag){
          workspacesWithUnreadStatus.push(c);
        }
        else{
          workspacesWithReadStatus.push(c);
        }
      })
      return _.concat(
        _.sortBy((workspacesWithUnreadStatus), (ws) => {
          return -ws.updated_date;
        }),
        _.sortBy((workspacesWithReadStatus), (ws) => {
          return -ws.updated_date;
        })
      )
    }
    if(workspaceSort === this.SORTS.UPDATED_DATE_DESC){
      return _.sortBy((workspaces), (ws) => {
        return -ws.updated_date;
      })
    }
    else if(workspaceSort === this.SORTS.UPDATED_DATE_ASC){
      return _.sortBy((workspaces), (ws) => {
        return ws.updated_date;
      })
    }
    else if(workspaceSort === this.SORTS.HOST_FIRST_NAME_DESC){
      return _.orderBy((workspaces), [ws => ws['$first_name'].toLowerCase()], ['desc']);
    }
    else if(workspaceSort === this.SORTS.HOST_FIRST_NAME_ASC){
      return _.orderBy((workspaces), [ws => ws['$first_name'].toLowerCase()], ['asc']);
    }
    else if(workspaceSort === this.SORTS.HOST_LAST_NAME_DESC){
      return _.orderBy((workspaces), [ws => ws['$last_name'].toLowerCase()], ['desc']);
    }
    else if(workspaceSort === this.SORTS.HOST_LAST_NAME_ASC){
      return _.orderBy((workspaces), [ws => ws['$last_name'].toLowerCase()], ['asc']);
    }
    else if(workspaceSort === this.SORTS.WORKSPACE_NAME_DESC){
      return _.orderBy((workspaces), [ws => ws['label'].toLowerCase()], ['desc']);
    }
    else if(workspaceSort === this.SORTS.WORKSPACE_NAME_ASC){
      return _.orderBy((workspaces), [ws => ws['label'].toLowerCase()], ['asc']);
    }
    else{
      throw new Error('Unsupported Sort')
    }
  }

  onDMPanelRef(ref) {
    this.setState({activeChatPanelRef : ref})
  }

  onGuestListRef(ref){
    this.setState({guestListScrollRef : ref})
  }

  doBeforePrint(guest_uid){
    let { activeDM, directMessages } = this.props;

    if(activeDM && activeDM.guest_uid === guest_uid){
      return this.state.activeChatPanelRef.doBeforePrint();
    }
    else{
      //we need to activate the proper thread, and print once it's done loading
      let dm = _.find(directMessages, (d) => d.guest_uid === guest_uid);
      return this.props.setActiveDM(dm)
        .then(() => {
          this.scrollToDM(dm.guest_uid);
          this.markDMAsViewed(dm);
          return utils.waitForCondition(() => !!this.state.activeChatPanelRef && guest_uid === this.props.messageBlocksId);
        })
        .then(() => {
          return this.state.activeChatPanelRef.doBeforePrint();
        })
    }
  }

  onPrintError(err){
    this.state.activeChatPanelRef.onPrintError(err);
  }

  doAfterPrintPreview(){
    this.state.activeChatPanelRef.doAfterPrintPreview();
  }

  getPrintPreviewContents(){
    return this.state.activeChatPanelRef.getPrintPreviewContents();
  }

  //Big debounce time!  These are scroll events, and we don't
  //want a string of mark requests to go out for each scroll event
  //so put a decent timeout on it.
  onThreadMessagePanelScroll = _.throttle((evt) => {
    let {
      activeDM
    } = this.props;
    if(activeDM && activeDM.notify_flag){
      this.markDMAsViewed(activeDM);
    }
  }, 5000)

  renderDMTabContents(){
    let { activeDM, activeDMMessageBlocks, messageBlocksId, activeDMDocs, directMessages, firstStartupCallsFinished, t } = this.props;
    let { sortedContacts, loading } = this.state;

    if(!directMessages || !firstStartupCallsFinished){
      return  null;
    }

    if(directMessages.length === 0){
      return (
        <div className="text-center"  style={{marginTop: '15vh'}}>
          <h4 className="dark-color">
            {t("Looks pretty empty in here!")}
          </h4>
          <p className="secondary-text-color">
            {t("Get started by adding your first Verifyle Contact.")}
          </p>
          <div className="mt-5">
            <button className="btn btn-lg btn-primary"
                    onClick={this.newContact.bind(this)}>
              {t("Add a Contact")}
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="row h-auto">
        <div style={{
          paddingRight: '0px',
          marginRight: '-5px',
          overflowY: 'auto'
        }} className={classNames('col-3 center-col tab-view')}>
          <GuestList contacts={sortedContacts}
                     onRef={this.onGuestListRef}
                     onRemoveGuest={this.onRemoveGuest}
                     onGuestRowFileDrop={this.onDMRowFileDrop}
                     guestClick={this.guestClick}
                     onPrintPreviewError={this.onPrintError.bind(this)}
                     onBeforePrintPreview={this.doBeforePrint.bind(this)}
                     onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                     getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                     onDocAttach={this.onAttachDocToThread}
                     onSendInvoiceClick={this.onSendInvoiceClick.bind(this)}
                     activeDM={activeDM}/>
        </div>
        <div style={{...styles.centerCol, ...{paddingRight: '10px'}}}
             className={classNames('col-9 center-col tab-view')}>
          <DMPanel dm={activeDM}
                   onMessagePanelScroll={this.onThreadMessagePanelScroll.bind(this)}
                   onSendInvoiceClick={this.onSendInvoiceClick.bind(this)}
                   isInTabView={true}
                   onRef={this.onDMPanelRef}
                   dmDocs={activeDMDocs}
                   onAttachDocToThread={this.onAttachDocToThread}
                   onPdfSubmit={this.onPdfSubmit}
                   refreshMessages={this.refreshDMMessages}
                   messageBlocksId={messageBlocksId}
                   messageBlocks={activeDMMessageBlocks}
          />
        </div>
      </div>
    )
  }

  onPdfSubmit(res){
    let { activeDM, directMessages } = this.props;
    log.log('onPdfSubmit in home', res);

    //Ewwww. copied code from onAttachDocToThread.  Figure out how to better share this.
    if(res.guest_uid){
      if(activeDM && activeDM.guest_uid === res.guest_uid){
        this.state.activeChatPanelRef.chatFileDrop([res.doc]);
      }
      else{
        PendingMsgCache.addDocsToDmCache(res.guest_uid, [res.doc]);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let dm = _.find(directMessages, (d) => d.guest_uid === res.guest_uid);
      return this.props.setActiveDM(dm)
        .then(() => {
          this.markDMAsViewed(dm);
        })
    }
    else{
      //save the docs in the pending cache, and navigate there
      PendingMsgCache.addDocsToThreadCache(res.chat_id, [res.doc]);

      //deeplink to workspace thread
      this.props.history.push(`/workspace/${res.forum_id}?chat_id=${res.chat_id}`);
    }
  }

  onSendInvoiceClick(dm){
    let {
      accountClassInfo,
      merchantInfo,
      t
    } = this.props;

    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    let isHealthy = MerchantSetupWindow.isMerchantStatusHealthy(merchantInfo);
    let fixMerchantWindowPromise = Promise.resolve(true);
    if (!merchant_flag || !isHealthy) {
      //A little bit of voodoo here.  The only thing that this can return us is cancel or
      //Reactivate.  For the setup cases, they will be navigated away from the verifyle
      //app to do the merchant setup.
      //So if they reactivated, continue with sending an invoice.  Otherwise they
      //did something that eventually ended in a cancel.
      fixMerchantWindowPromise = this.props.showMerchantSetupWindowUpgradeFlow()
        .then((res) => {
          return res === MerchantSetupWindow.WINDOW_RESULT.REACTIVATE;
        })
    }

    fixMerchantWindowPromise
      .then((res) => {
        if(!res){
          log.log('cancel received from merchant setup flow', res);
          return;
        }

        let foundInvoice = false;
        let cached = PendingMsgCache.fetchDm(dm.guest_uid);
        _.each(cached.docs, (d) => {
          if (d.isInvoice) {
            foundInvoice = true;
          }
        })

        if (foundInvoice) {
          //we only allow one invoice per thread/dm for now.
          this.props.showAlert(t("One Invoice at a time"), t("Only one invoice at a time can be added to a thread.  Please send your current invoice before creating a new one."));
          return;
        }

        this.props.showSendInvoiceWindow(null, null, null, dm.guest_uid, [dm], (res) => {
          log.log('showSendInvoiceWindow closed', res);
          if (res && res.guest_uid) {
            this.onAttachInvoice(res);
          }
        })
      })
  }

  onAttachInvoice(res){
    log.log('on attach invoice to thread in home', res);
    let { activeDM, directMessages } = this.props;

    //At this point, it's already in the pending cache, so just handle routing.
    //This is also awfully similar to onAttachDocToThread and onPdfSubmit.
    //We should refactor this to share routing code better.

    if(res.guest_uid){
      if(activeDM && activeDM.guest_uid === res.guest_uid){
        this.state.activeChatPanelRef.chatFileDrop([res.invoice]);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let dm = _.find(directMessages, (d) => d.guest_uid === res.guest_uid);
      return this.props.setActiveDM(dm)
        .then(() => {
          this.markDMAsViewed(dm);
        })
    }
    else{
      this.props.history.push(`/workspace/${res.forum_id}?chat_id=${res.chat_id}`);
    }
  }

  onAttachDocToThread(res){
    let { activeDM, directMessages } = this.props;

    let updatedDocs = [];
    _.each(res.docs, (d) => {
      updatedDocs.push({
        doc_label : d.label,
        doc_id : d.doc_id,
        forum_id : d.forum_id,
        host_uid : d.host_uid,
        forum_label : ''
      })
    })

    //Ewwww. duplicated code in onPdfSubmit.  Figure out how to better share this.
    //destination is either thread or DM.  Need to route to the proper place.
    if(res.dest_guest_uid){
      if(activeDM && activeDM.guest_uid === res.dest_guest_uid){
        this.state.activeChatPanelRef.mergeAttachDocsWithCurrentMsg(updatedDocs);
      }
      else{
        PendingMsgCache.attachPendingDocsToDM(res.dest_guest_uid, updatedDocs);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let dm = _.find(directMessages, (d) => d.guest_uid === res.dest_guest_uid);
      return this.props.setActiveDM(dm)
        .then(() => {
          this.markDMAsViewed(dm);
        })
    }
    else{
      //save the docs in the pending cache, and navigate there
      PendingMsgCache.attachPendingDocsToChat(res.dest_chat_id, updatedDocs);

      //deeplink to workspace thread
      this.props.history.push(`/workspace/${res.dest_forum_id}?chat_id=${res.dest_chat_id}`);
    }
  }

  setContactSort(sort){
    vfLocalStorage.set(c.localstorage.contactSort, sort);
    this.setState({contactSort : sort})
  }

  setWorkspaceSort(sort){
    vfLocalStorage.set(c.localstorage.wsSort, sort);
    this.setState({workspaceSort : sort})
  }

  renderSortOptionRow(activeSort, option1, option2, optionIfNeither, doSelectSort){
    let sortOptions = this.getSortOptions();
    return (
      <a onClick={doSelectSort.bind(this, activeSort === option1 ? option2 : option1)}
         className={`sort-row list-group-item list-group-item-action ${(activeSort === option1 || activeSort === option2) ? 'active' : ''}`}>
        {activeSort === option1 &&
        <Fragment>
          <i className={`icon ${sortOptions[option1].icon} mr-2`}/>
          {sortOptions[option1].display}
        </Fragment>
        }
        {activeSort === option2 &&
        <Fragment>
          <i className={`icon ${sortOptions[option2].icon} mr-2`}/>
          {sortOptions[option2].display}
        </Fragment>
        }
        {activeSort !== option2 && activeSort !== option1 &&
        <Fragment>
          <i className={`icon mr-2`}/>
          {sortOptions[optionIfNeither].display}
        </Fragment>
        }
      </a>
    )
  }

  renderTabView(){
    let { workspaceNotifyCount, directMessageNotifyCount, t } = this.props;
    let { selected_tab, contactSort, workspaceSort } = this.state;
    let tabs = [];

    tabs.push({
      id : CONTACTS_TAB,
      tabRenderFn: (tab) => {
        return (
          <div className="d-inline-block" style={{marginLeft : '10px'}}>
            <h4 onClick={this.tabClick.bind(this, CONTACTS_TAB, false)} className={'m-0 home-accordion-header'}>
              <NotificationIcon iconColorOverride={(tab.isSelected ? colors.DARK : colors.LIGHTER_GREY)}
                                iconCls="ion-android-person"
                                value={directMessageNotifyCount} />
              {t("Contacts")}
              <i onClick={selected_tab === CONTACTS_TAB ? this.newContact.bind(this) : _.noop.bind(this)}
                 className={`btn btn-lg btn-icon ion-ios-plus-outline no-focus tab-add-btn ${selected_tab === CONTACTS_TAB ? '' : 'disabled'}`} />
            </h4>
          </div>
        )
      },
      isSelected : selected_tab === CONTACTS_TAB,
      listRenderFn : () => {
        return this.renderDMTabContents();
      },
      getSortingPopoverContent: () => {
        return (
          <Fragment>
            {this.renderSortOptionRow(contactSort, this.SORTS.UNREAD, this.SORTS.READ, this.SORTS.UNREAD, this.setContactSort)}
            {this.renderSortOptionRow(contactSort, this.SORTS.UPDATED_DATE_DESC, this.SORTS.UPDATED_DATE_ASC, this.SORTS.UPDATED_DATE_DESC, this.setContactSort)}
            {this.renderSortOptionRow(contactSort, this.SORTS.USER_FIRST_NAME_ASC, this.SORTS.USER_FIRST_NAME_DESC, this.SORTS.USER_FIRST_NAME_ASC, this.setContactSort)}
            {this.renderSortOptionRow(contactSort, this.SORTS.USER_LAST_NAME_ASC, this.SORTS.USER_LAST_NAME_DESC, this.SORTS.USER_LAST_NAME_ASC, this.setContactSort)}
            {this.renderSortOptionRow(contactSort, this.SORTS.USER_EMAIL_ASC, this.SORTS.USER_EMAIL_DESC, this.SORTS.USER_EMAIL_ASC, this.setContactSort)}
          </Fragment>
        )
      },
      getSortLabel: () => {
        return this.getSortOptions()[contactSort].display
      }
    })

    tabs.push({
      id: WORKSPACE_TAB,
      tabRenderFn: (tab) => {
        return (
          <div className="d-inline-block" style={{marginLeft : '10px'}}>
            <h4 onClick={this.tabClick.bind(this, WORKSPACE_TAB, false)} className={'m-0 home-accordion-header'}>
              <NotificationIcon iconColorOverride={(tab.isSelected ? colors.DARK : colors.LIGHTER_GREY)}
                                iconCls="icomoon-workspace"
                                value={workspaceNotifyCount} />
              {t("Workspaces")}
              <i onClick={selected_tab === WORKSPACE_TAB ? this.newWorkspace.bind(this) : _.noop.bind(this)}
                 className={`btn btn-lg btn-icon ion-ios-plus-outline no-focus tab-add-btn ${selected_tab === WORKSPACE_TAB ? '' : 'disabled'}`} />
            </h4>
          </div>
        )
      },
      isSelected : selected_tab === WORKSPACE_TAB,
      listRenderFn: () => {
        return this.renderWorkspaceTabContents();
      },
      getSortingPopoverContent: () => {
        return (
          <Fragment>
            {this.renderSortOptionRow(workspaceSort, this.SORTS.UNREAD, this.SORTS.READ, this.SORTS.UNREAD, this.setWorkspaceSort)}
            {this.renderSortOptionRow(workspaceSort, this.SORTS.UPDATED_DATE_DESC, this.SORTS.UPDATED_DATE_ASC, this.SORTS.UPDATED_DATE_DESC, this.setWorkspaceSort)}
            {this.renderSortOptionRow(workspaceSort, this.SORTS.HOST_FIRST_NAME_ASC, this.SORTS.HOST_FIRST_NAME_DESC, this.SORTS.HOST_FIRST_NAME_ASC, this.setWorkspaceSort)}
            {this.renderSortOptionRow(workspaceSort, this.SORTS.HOST_LAST_NAME_ASC, this.SORTS.HOST_LAST_NAME_DESC, this.SORTS.HOST_LAST_NAME_ASC, this.setWorkspaceSort)}
            {this.renderSortOptionRow(workspaceSort, this.SORTS.WORKSPACE_NAME_ASC, this.SORTS.WORKSPACE_NAME_DESC, this.SORTS.WORKSPACE_NAME_ASC, this.setWorkspaceSort)}
          </Fragment>
        )
      },
      getSortLabel: () => {
        return this.getSortOptions()[workspaceSort].display
      }
    })

    return (
      <div className="row">
        <div className="col p-0">
          <ScrollingTabView id={TABVIEW_ID}
                            scrollOffset={70}
                            tabs={tabs}
                            customTabHeight={58} />
        </div>
      </div>
    )
  }

  renderHelmetTags(){

    return (
      <div>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: c.smartbanner.non_mobile_viewport_banner
          }]}
        />
        <Helmet>
          <meta name="viewport" content="" />
        </Helmet>
      </div>
    )
  }

  render() {
    let { selected_tab } = this.state;

    return (
      <div className={'column-scroll-layout has-header'}>
        {this.renderHelmetTags()}
        <Header isLockedToTop={true}
                showSearch={true}
                onContactSearchResult={this.onContactSearchResult}
                searchContext={selected_tab === WORKSPACE_TAB ? SearchWindow.SEARCH_CONTEXT.HOME : SearchWindow.SEARCH_CONTEXT.CONTACTS}
                showAcctHeader={true} />
        <div className="container-fluid">
          {this.renderTabView()}
        </div>
      </div>
    );
  }
}

const styles = {
  notifyPlaceholder : {
    minWidth : '30px'
  },
  archiveRowIconWrap : {
    fontSize : '34px',
    minWidth : '40px',
    margin : '-10px 10px 0px 10px',
  },
  archiveRowTextChevron : {
    verticalAlign : 'baseline',
    fontSize : '20px'
  },
  centerCol : {
    paddingLeft : '0px'
  },
  rightCol : {
    paddingLeft : '0px',
    paddingRight : '0px'
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsEmail : state.app.qsEmail,
    qsActionNeeded : state.app.qsActionNeeded,
    email : state.auth.email,
    accountInfo : state.shared.accountInfo,
    stripeData : state.shared.stripeData,
    unarchivedWorkspaces: state.home.unarchivedWorkspaces,
    archivedWorkspaces: state.home.archivedWorkspaces,
    workspaceNotifyCount : state.shared.workspaceNotifyCount,
    directMessageNotifyCount : state.shared.directMessageNotifyCount,
    directMessages : state.shared.directMessages,
    activeDM: state.thread.activeDM,
    activeDMDocs: state.thread.activeDMDocs,
    activeDMMessageBlocks: state.thread.activeDMMessageBlocks,
    messageBlocksId : state.thread.messageBlocksId,
    allStartupCallsFinished : state.shared.allStartupCallsFinished,
    firstStartupCallsFinished : state.shared.firstStartupCallsFinished,
    accountInfoGuest : state.shared.accountInfoGuest,
    accountClassInfo : state.shared.accountClassInfo,
    merchantInfo : state.shared.merchantInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    startup : () => dispatch(homeActions.startup()),
    updateWorkspaces : () => dispatch(sharedActions.updateWorkspaces()),
    updateStripeData : () => dispatch(sharedActions.updateStripeData()),
    updateDirectMessages: (checkImportQueue) => dispatch(sharedActions.updateDirectMessages(checkImportQueue)),
    setActiveDM: (dm) => dispatch(homeActions.setActiveDM(dm)),
    setQsEmail: (email) => dispatch(appActions.setQsEmail(email)),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    logout : () => dispatch(appActions.logout()),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    updateAccountInfo: () => dispatch(sharedActions.updateAccountInfo()),
    resolveUserLanguageSettings:(i18n) => dispatch(sharedActions.resolveUserLanguageSettings(i18n)),
    setAccountLanguage:(language) => dispatch(sharedActions.setAccountLanguage(language)),
    ...modalActions.mapToDispatch(dispatch),
    threadAction : {...threadActions.mapToDispatch(dispatch)}
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
