import React, {Component} from 'react';
import {connect} from 'react-redux';

import Button from "../partials/elements/Button";
import Loading from '../partials/util/Loading';

import c from '../../util/const';
import config from '../../util/config';
import log from '../../util/log';
import api from '../../util/api';
import redirectHelper from '../../util/redirect-helper';

import authActions from "../../actions/auth-actions";
import modalActions from '../../actions/modal-actions';
import vfLocalStorage from "../../util/local-storage";
import ReCAPTCHA from "react-google-recaptcha";
import Image from "../partials/elements/Image";
import logo from "../../resources/logo-w-text-white.png";
import cookieHelper from "../../helpers/cookie-helper";
import _ from 'lodash';
import {withVFTranslation} from "../../util/withVFTranslation";
import moment from "moment/moment";
import querystring from "query-string";

const recaptchaRef = React.createRef();

class PublisherSubscribe extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDoingSubscribeCall : false,
      callResult : null,
      errResult : null
    };
  }

  componentDidMount() {
    this.doSubscribe();
  }

  doSubscribe(){
    let { qs, qsEmail, t } = this.props;

    let subscribeUid = _.get(qs, 'uid');
    let subscribeEmail = qsEmail;

    if(!subscribeUid || !subscribeEmail){
      log.warn('missing arguments on publisher subscribe page', subscribeUid, subscribeEmail);
      this.goToLogin();
      return;
    }

    this.setState({isDoingSubscribeCall : true});
    api.Publisher.add(subscribeEmail, subscribeUid)
      .then((res) => {
        log.log('add publisher res', res);
        this.setState({callResult: res});
      })
      .catch((err) => {
        log.log('err during add publisher', err);
        this.setState({errResult: err});
      })
      .finally(() => {
        this.setState({isDoingSubscribeCall : false});
      })
  }

  //We want all navigation from this page to be authenticated redirects, meaning we need to do a full page
  //refresh in order to get tokens/emails handled in App.js.
  //We could probably clean this up a bit if needed, and just use App.js.startup() directly if this is going to be a more
  //regular thing.
  goToLogin() {
    let baseUrl = window.location.href.split('login')[0];
    window.location.href = `${baseUrl}login`;
  }

  goToContent() {
    let { qsEmail } = this.props;
    let {
      callResult
    } = this.state;

    //{
    //    "data" : {
    //       "chat_id" : "671123106a18b1e5",
    //       "doc_id" : "671123112f9b3697",
    //       "forum_id" : "671123106463ec7e",
    //       "mesg_id" : 0
    //    },
    //    "success" : true
    // }

    let qs = {};
    qs.email = qsEmail; //Since this has already been pulled off the querystring and separately store, we need to re-add it here.
    qs[c.querystring.forum_id] = _.get(callResult, 'data.forum_id');
    qs[c.querystring.chat_id] = _.get(callResult, 'data.chat_id');
    let stringified = querystring.stringify(qs);
    let baseUrl = window.location.href.split('login')[0];
    window.location.href = `${baseUrl}login?${stringified}`;
  }

  goToSettings(){
    let { qsEmail } = this.props;
    let qs = {};
    qs.email = qsEmail; //Since this has already been pulled off the querystring and separately store, we need to re-add it here.
    qs[c.querystring.goto_publisher_settings] = "yes";
    let stringified = querystring.stringify(qs);
    let baseUrl = window.location.href.split('login')[0];
    window.location.href = `${baseUrl}login?${stringified}`;
  }

  render() {
    let { t } = this.props;
    let { isDoingSubscribeCall, callResult, errResult } = this.state;

    let callWasSuccessful = !isDoingSubscribeCall && callResult && !errResult;

    let isAlreadySubscribed = false;
    let errText = "";
    if(errResult){
      let errName = _.get(errResult, 'name');
      if(errName === 'APP_GUEST_DUP'){
        isAlreadySubscribed = true;
        errText = t("You have already subscribed to this publisher.  Please log in to view.")
      }
      else{
        errText = t("We were unable to subscribe you to this publisher.  Please log in and check your settings page for more information.")
      }
    }

    return (
      <div className={'celestial-bg'}>
        <div className="container">
          <div className={'row justify-content-center'}>
            <div className={'auth-header-img col'}>
              <Image src={logo}
                     className={'mx-auto d-block'}
                     imgHeight={90}
                     alt={t('logo')}/>
            </div>
          </div>
          <div className={'row justify-content-center'} style={{marginTop: '3vh'}}>
            <div className={'col-sm-8 col-lg-6'}>
              <div className="card auth-card">
                <div className="card-body light-color">
                  <h3>{t("Subscribe to Publisher")}</h3>
                  <hr className={'light-border'} />
                  {isDoingSubscribeCall &&
                  <p className="card-text">
                    <Loading centered color="light" size="sm"/>
                  </p>
                  }

                  {callWasSuccessful &&
                    <p className="card-text">
                      {t("Success!  Click below to log in and view your new content")}
                    </p>
                  }
                  {!callWasSuccessful &&
                    <p className="card-text">
                      {errText}
                    </p>
                  }

                  {!isDoingSubscribeCall &&
                  <div className="row">
                    <div className="col text-center">
                      {callWasSuccessful &&
                        <Button disabled={isDoingSubscribeCall}
                                onClick={this.goToContent.bind(this)}
                                className="btn btn-link light-color light-color">{t("View Content")}</Button>
                      }
                      {!callWasSuccessful && !isAlreadySubscribed &&
                        <Button disabled={isDoingSubscribeCall}
                                onClick={this.goToSettings.bind(this)}
                                className="btn btn-link light-color light-color">{t("View Settings")}</Button>
                      }
                      {isAlreadySubscribed &&
                        <Button disabled={isDoingSubscribeCall}
                                onClick={this.goToLogin.bind(this)}
                                className="btn btn-link light-color light-color">{t("Log In")}</Button>
                      }
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsEmail : state.app.qsEmail, //worth noting that email has already been pulled off of querystring here.
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    setStep: step => dispatch(authActions.setNextStep(step)),
    setTFAAuth: auth => dispatch(authActions.setTFAAuth(auth)),
    alert: (title, msg, cb) => dispatch(modalActions.showAlert(title, msg, cb))
  };
};
export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PublisherSubscribe));
