import c from '../util/const';
import enums from '../util/enums'
import PdfPreview from "../components/partials/pdf-preview/PdfPreview";
import _ from 'lodash';
import log from "../util/log";
import PdfWritingSvc from "../helpers/pdf-writing-svc";

let initialState = {
  //data from the server
  rawPdf: null,

  //pdf js stuff
  pdf: null,
  pages: null,
  pdfPageCanvasLookup: {},
  pdfLoadErr : null,

  //pdf-lib stuff
  pdfWriter : null,
  pdfWriterLoadErr : null,

  //global window status
  // scale: 1,
  windowSigningState: enums.WINDOW_SIGNING_STATUS.NONE,

  //vf parameters
  forum_id : null,
  host_uid: null,
  doc_id: null,
  chat_id: null,
  dm : null,
  thread : null,
  doc_info : null,

  //sign archive context vars
  signArchiveRow : null,
  signArchiveInfo : null,
  isSignArchiveContext : false,

  //v2 stuff
  signatureRequestData: null,
  signatureRequestOverlays: [],
  signatureRequestOverlayLookup: {},
  isFulfillingSignatureRequest : false,
  confirmedSignatureCount : 0,
  signatureRequestEditingGuestIndex: -1,
  signTemplateList: null,
  signTemplateCache: {},

  //v1 signing only
  signatureIds: [],
  activeOverlays: [],
  invalidOverlays: [],
  hasCommittedSignatures: false,
  hasValidV1Signing : false,
  pdfChangesMade : false,
}

const pdfPreview = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.pdf.initialize:
      log.log('initialize called in reducer')
      if(action.isSignArchiveContext){
        return {
          ...state,
          signArchiveRow : action.signArchiveRow,
          signArchiveInfo : action.signArchiveInfo,
          isSignArchiveContext : action.isSignArchiveContext
        }
      }
      else{
        return {
          ...state,
          forum_id : action.forum_id,
          host_uid: action.host_uid,
          doc_id: action.doc_id,
          chat_id: action.chat_id,
          pdfWriter : new PdfWritingSvc()
        }
      }

    case c.actions.pdf.setRawPdf:
      return {
        ...state,
        rawPdf: action.rawPdf
      }

    case c.actions.pdf.setPdfPageCanvasLookup:
      return {
        ...state,
        pdfPageCanvasLookup : action.pdfPageCanvasLookup
      }

    case c.actions.pdf.setPdfJsModel:
      return {
        ...state,
        pdf: action.pdf
      }

    case c.actions.pdf.setPdfJsPages:
      return {
        ...state,
        pages: action.pages
      }

    case c.actions.pdf.setPdfJsLoadErr:
      return {
        ...state,
        pdfLoadErr: action.pdfLoadErr
      }

    case c.actions.pdf.setPdfWriterLoadErr:
      return {
        ...state,
        pdfWriterLoadErr: action.pdfWriterLoadErr
      }

    case c.actions.pdf.setSignatureRequestEditingGuestIndex:
      return {
        ...state,
        signatureRequestEditingGuestIndex : action.newIndex,
      }

    case c.actions.pdf.setSigningTemplates:
      return {
        ...state,
        signTemplateList : action.signTemplateList
      }

    case c.actions.pdf.clearSigningTemplate:
      return {
        ...state,
        signTemplateCache:  _.extend(state.signTemplateCache, {
          [action.template_id]: null
        })
      }

    case c.actions.pdf.loadSigningTemplate:
      return {
        ...state,
        signTemplateCache:  _.extend(state.signTemplateCache, {
          [action.template.template_id]: {
            template: action.template,
            guest_uid: action.guest_uid
          }
        })
      }

    case c.actions.pdf.loadSignatureRequestData:
      return {
        ...state,
        isFulfillingSignatureRequest : action.isFulfillingSignatureRequest,
        signatureRequestData : action.signatureRequestData
      }

    case c.actions.pdf.updateSignatureRequest:

      let signatureRequestDataUpdate = _.extend({}, state.signatureRequestData);
      signatureRequestDataUpdate.smsNumber = action.smsNumber;
      signatureRequestDataUpdate.terms = action.terms;

      return {
        ...state,
        signatureRequestData : signatureRequestDataUpdate
      }

    case c.actions.pdf.updateSignatureRequestOverlays:
      return {
        ...state,
        signatureRequestOverlays : action.signatureRequestOverlays,
        signatureRequestOverlayLookup : action.signatureRequestOverlayLookup,
        confirmSignatureCount : action.confirmSignatureCount
      }

    case c.actions.pdf.updateV1SignatureOverlays:
      log.log('updateV1 sig overlays', action);
      return {
        ...state,
        signatureIds : action.signatureIds
      }

    case c.actions.pdf.updateV1ActiveOverlays:
      return {
        ...state,
        activeOverlays : action.activeOverlays
      }

    case c.actions.pdf.updateV1InactiveOverlays:
      return {
        ...state,
        invalidOverlays : action.invalidOverlays
      }

    case c.actions.pdf.updateV1HasCommittedSignatures:
      return {
        ...state,
        hasCommittedSignatures : action.hasCommittedSignatures
      }

    case c.actions.pdf.updateV1HasValidSigning:
      return {
        ...state,
        hasValidV1Signing : action.hasValidV1Signing
      }

    case c.actions.pdf.updateV1PdfChangesMade:
      return {
        ...state,
        pdfChangesMade : action.pdfChangesMade
      }

    case c.actions.pdf.resetV1SigningState:
      return {
        ...state,
        signatureIds: [],
        activeOverlays: [],
        invalidOverlays: [],
        hasCommittedSignatures: false,
        hasValidV1Signing : false,
        pdfChangesMade : false,
      }

    case c.actions.pdf.setWindowSigningMode:
      return {
        ...state,
        windowSigningState : action.mode
      }

    case c.actions.pdf.cleanup:
      return {
        ...initialState,

        //we need to explicitly pass anything initialized with {}
        //in to reset here.  Other wise they doesn't actually get reset.
        signTemplateCache: {},
        pdfPageCanvasLookup: {},
        signatureRequestOverlayLookup: {},

        pdfWriter : new PdfWritingSvc()
      }

    default:
      return state;
  }
}

export default pdfPreview;
