import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';

import _ from 'lodash';

const uploadActions = {

  setActiveUpload(transaction){
    return {
      type : c.actions.upload.setActiveUpload,
      transaction
    }
  },

  //Might want to do more with this later?
  clearActiveUpload(){
    return {
      type : c.actions.upload.setActiveUpload,
      transaction : null
    }
  },

  updateQueue(queue){
    return {
      type : c.actions.upload.updateQueue,
      queue
    }
  },

  queueSignedPdfUpload(transaction_id, upload_id, original_mesg_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, filename, customInputArray, pdfData, last_forum_id){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        original_mesg_id,
        forum_id,
        host_uid,
        guest_uid,
        chat_id,
        doc_id,
        sign_request_id,
        upload_id,
        filename,
        pdfData,
        customInputArray,
        isSignedPdfUpload : true,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        last_forum_id
      },
    }
  },

  queuePDFSubmitUpload(transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, signature_requested, mesg, filename, pdfData, last_forum_id){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        forum_id,
        host_uid,
        guest_uid,
        chat_id,
        doc_id,
        sign_request_id,
        signature_requested,
        upload_id,
        filename,
        pdfData,
        isPDFXHRUpload : true,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg,
        last_forum_id
      },
    }
  },

  queueChatUpload(transaction_id, upload_id, forum_id, host_uid, chat_id, signature_requested, files, mesg){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        files,
        signature_requested,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg
      },
    }
  },

  queueThreadDocAttach(transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        files,
        isDocAttach : true,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg
      },
    }
  },

  queueDMDocAttach(transaction_id, upload_id, guest_uid, files, mesg, last_forum_id){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        guest_uid,
        files,
        isDocAttach : true,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg,
        last_forum_id
      },
    }
  },

  queueThreadInvoice(transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        files,
        isInvoice : true,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg
      },
    }
  },

  queueDMInvoice(transaction_id, upload_id, guest_uid, files, mesg, last_forum_id){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        guest_uid,
        files,
        isInvoice : true,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg,
        last_forum_id
      },
    }
  },

  queueDMUpload(transaction_id, upload_id, guest_uid, signature_requested, files, mesg, last_forum_id){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        transaction_id,
        guest_uid,
        files,
        signature_requested,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id,
        mesg,
        last_forum_id
      }
    }
  },

  queueDocUpload(upload_id, forum_id, host_uid, files){
    return {
      type : c.actions.upload.addToUploadQueue,
      transaction : {
        forum_id,
        host_uid,
        files,
        upload_id,
        isPending : true, //We add this to distinguish uploading files later.
        key : upload_id
      }
    }
  },

  updateProgress (progress){
    return {
      type : c.actions.upload.updateProgress,
      progress
    }
  },

  cleanup (){
    return {
      type : c.actions.upload.cleanup
    }
  }
}

export default uploadActions;
