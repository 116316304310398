import utils from "../util/util";
import c from "../util/const";
import _ from "lodash";
import he from "he";
import Autolinker from "autolinker";
import moment from "moment";
import sharedActions from "../actions/shared-actions";
import log from "../util/log";

const msgHelper = {

  trimWhitespace(msg){
    return _.trim(msg);
  },

  getUserMessageCounts(beforeMessageBlocks, afterMessageBlocks){

    let preMessageUserLookup = {};
    let postMessageUserLookup = {};

    _.each(beforeMessageBlocks, (beforeBlock) => {
      _.each(beforeBlock.blockList, (msg) => {
        let preExisting = preMessageUserLookup[msg.guest_uid] || 0;
        preMessageUserLookup[msg.guest_uid] = preExisting + 1;
      })
    })

    _.each(afterMessageBlocks, (afterBlock) => {
      _.each(afterBlock.blockList, (msg) => {
        let postExisting = postMessageUserLookup[msg.guest_uid] || 0;
        postMessageUserLookup[msg.guest_uid] = postExisting + 1;
      })
    })
    return {
      before : preMessageUserLookup,
      after : postMessageUserLookup
    }
  },

  buildPendingMessageList(object_id, accountInfo, threadTransactionQueue) {
    // for pending docs, we need to replicate the data structure that comes back from the server so we can
    //combine it with the messages that come back before it exists there.j
    //it looks like this:
    // {
    //   "first_name" : "Kevin",
    //   "guest_uid" : "5787c9ce0ca1040a",
    //   "last_name" : "Swartz",
    //   "mesg" : "asfasdfasdf",
    //   "mesg_date" : 1469048132,
    //   "mesg_id" : 0,
    //   "docs" : [
    //     {
    //       "doc_id" : "51f1b5d090e98a6b",
    //       "label" : null
    //     },
    //     {
    //       "doc_id" : "57bcc8e3693eb8b3",
    //       "label" : "file.pdf"
    //     }
    //   ],
    // },

    let pendingMsgs = [];
    let chatTransactions = {};
    _.each(threadTransactionQueue, (item) => {
      //A little lazy, don't add this transaction to the list we process unless it
      //pertains to the correct object_id, which is either guest_uid or chat_id
      if(item.chat_id === object_id || item.guest_uid === object_id) {
        if (chatTransactions[item.transaction_id]) {
          chatTransactions[item.transaction_id].push(item);
        }
        else {
          chatTransactions[item.transaction_id] = [item]
        }
      }
    })

    _.each(_.keys(chatTransactions), (transaction_id) => {
      let transactionItems = chatTransactions[transaction_id];

      let txn = transactionItems[0];

      let pendingMessage = {
        first_name : accountInfo.first_name,
        last_name : accountInfo.last_name,
        guest_uid : accountInfo.uid,
        mesg_date : txn.queue_time.unix(),
        mesg_id : txn.isComplete ? txn.mesg_id : transaction_id, //if we have the right mesg_id, map it here.
        mesg : '',
        isPending : true,
        isCompleteTransaction : txn.isComplete,
        transaction_id,
        docs : []
      }

      //If we have an original mesg_id, we don't want to add a new pending item
      //because the item already exists.  We just want to show progress in place.
      if(!_.has(txn, 'original_mesg_id') || !_.isNumber(txn.original_mesg_id)) {
        _.each(transactionItems, (item) => {
          if (item.type === sharedActions.TRANSACTION_TYPES.MESSAGE || item.type === sharedActions.TRANSACTION_TYPES.DM_MESSAGE) {
            pendingMessage.mesg = item.mesg
          }
          else if(item.type === sharedActions.TRANSACTION_TYPES.INVOICE){
            pendingMessage.mesg = item.mesg;
            pendingMessage.isInvoice = true;
            _.each(item.files, (f) => {
              pendingMessage.docs.push({
                key: f.uniqueId,
                isPending: true,
                mesg_id: f.mesg_id ? f.mesg_id : null,
                invoice : f
              })
            })
          }
          else if (item.type === sharedActions.TRANSACTION_TYPES.DOC_ATTACH) {
            pendingMessage.mesg = item.mesg;
            _.each(item.files, (f) => {
              pendingMessage.docs.push({
                key: f.uniqueId,
                filename: f.name,
                file_name: f.name,
                label: f.name,
                sizeString: f.sizeString,
                isPending: true,
                ...{
                  sign_request_id: (f.sign_request_id && f.sign_request_id.length > 0) ? f.sign_request_id[0] : null,
                  mesg_id: f.mesg_id ? f.mesg_id : null,
                  doc_id: (f.doc_id && f.doc_id.length > 0) ? f.doc_id[0] : f.uniqueId,
                  signer_info: []
                }
              })
            })
          }
          else if (item.type === sharedActions.TRANSACTION_TYPES.DOC || item.type === sharedActions.TRANSACTION_TYPES.DM_DOC) {
            pendingMessage.mesg = item.mesg;
            _.each(item.files, (f) => {
              pendingMessage.docs.push({
                key: f.uniqueId,
                filename: f.name,
                file_name: f.name,
                label: f.name,
                sizeString: f.sizeString,
                isPending: true,
                ...{
                  sign_request_id: (f.sign_request_id && f.sign_request_id.length > 0) ? f.sign_request_id[0] : null,
                  mesg_id: f.mesg_id ? f.mesg_id : null,
                  doc_id: (f.doc_id && f.doc_id.length > 0) ? f.doc_id[0] : f.uniqueId,
                  signer_info: []
                }
              })
            })
          }
        })
        pendingMsgs.push(pendingMessage);
      }
    })

    return pendingMsgs;
  },

  shouldDisplayTokenWithHTML(match){
    var extension = utils.getFilenameExtension(match);
    if(c.threads.fileExtensionConflicts.indexOf(extension) >= 0){
      //Then we have to look at if this looks like a real path or not.
      //starts with http, or www, or has a "/" in it somewhere

      var hasPrefix = false;
      _.each(c.threads.fullPathPrefixes, (prefix) => {
        if(match.indexOf(prefix) === 0){
          hasPrefix = true;
        }
      })

      if(hasPrefix){
        return true;
      }
      else{
        if(match.indexOf('/') >= 0){
          return true;
        }
        return false;
      }
    }
    return true;
  },

  buildMessageTokens(msg){
    if(!msg || !msg.mesg){
      return [];
    }

    let decoded = he.decode(msg.mesg);

    var tokenId = 0;
    var tokens = [];
    var currentPos = 0;

    let matches = Autolinker.parse(decoded);

    _.each(matches, (match) => {
      var matchPos = match.getOffset();

      var token = decoded.substring(currentPos, matchPos);
      if(token.length > 0) {
        tokens.push({
          text: token,
          useHtml: false,
          id: tokenId++
        })
      }

      currentPos += token.length;
      tokens.push({
        text: match.getMatchedText(),
        useHtml: this.shouldDisplayTokenWithHTML(match.getMatchedText()), //Extra check for matched tokens.
        id: tokenId++
      })

      currentPos += match.getMatchedText().length;
    })

    //Grab the last bit of the string, after the tokens.
    if(currentPos < decoded.length){
      tokens.push({
        text: decoded.substring(currentPos),
        useHtml: false,
        id: tokenId++
      })
    }

    return tokens;
  },

  formatMetadataForLegacyIfNeeded(metadata){
    if(!_.has(metadata, 'signer_data')){
      metadata = _.extend({}, metadata, {
        signer_data : [metadata]
      })
    }
    return metadata;
  },

  formatSigningRequestForLegacyIfNeeded(signingRequest){
    //code_check_flag: true
    // expiry_date: null
    // phone: "+15415109156"
    // request_date: 1636997128
    // sign_data: {signatures: Array(2)}
    // signed_date: null
    // signer_uid: "554bf91ca6d132cf"
    // source_doc_id: "619297a9dd463ec6"
    // terms: "I agree to do business electronically."

    if (!_.isArray(signingRequest)) {
      //If it's not an array, then this is previous to multi-signers.
      //just drop this in an array with sign_order 1
      let update = _.extend({}, signingRequest, { sign_order : 1});
      return [update];
    }
    else{
      return signingRequest;
    }
  },

  formatMessageSigningDataForLegacyIfNeeded(messagesResult){
    _.each(messagesResult, (msg) => {
      _.each(_.get(msg, 'docs', []), (d) => {
        let has_sign_status = _.has(d, 'sign_status');
        let has_signer_info = _.has(d, 'signer_info');
        if(!has_signer_info && has_sign_status){

          //If signer_info is not present, it means this data is from before
          //multiple signers.  So package the single-user response into
          //a multi-signer response.
          d._isLegacyDoc = true;

          let signer_uid = _.get(d, 'signer_uid', '');
          if(signer_uid && signer_uid.length > 0) {
            d.signer_info = [{
              signer_uid: signer_uid,
              sign_status: _.get(d, 'sign_status', ''),
            }]
          }
          else{
            d.signer_info = [];
          }
        }
      })
    })

    return messagesResult;
  },

  getMessageBlocks(messages) {
    //console.time('getMessageBlocks');
    let lookup = [];
    let msgs = _.concat([], messages);

    msgs.sort( (x, y) => {
      var xUnix = utils.getMomentDate(x.mesg_date).unix();
      var yUnix = utils.getMomentDate(y.mesg_date).unix();
      if (xUnix < yUnix) return -1;
      else if (xUnix > yUnix) return 1;
      return 0;
    });

    //console.time('getMessageBlocks-buildlist');
    let lastBlock = null;
    for (let i = 0; i < msgs.length; i++) {
      let thisMsg = msgs[i];
      thisMsg.tokens = this.buildMessageTokens(thisMsg);

      // let msgHashCode = utils.generateHashCode(thisMsg.mesg);
      // thisMsg.tokens = messageTokenCache[msgHashCode];
      // if(!thisMsg.tokens) {
      //   thisMsg.tokens = this.buildMessageTokens(thisMsg);
      //   messageTokenCache[msgHashCode] = thisMsg.tokens;
      // }

      //console.time('getMessageBlocks-buildlastblock');
      let partOfBlock = false;
      if(lastBlock){
        let firstOfLastBlock = lastBlock.blockList[0];
        if (thisMsg.guest_uid !== firstOfLastBlock.guest_uid) {
          partOfBlock = false;
        }
        else{
          let thisMsgDate = utils.getMomentDate(thisMsg.mesg_date);
          let lastMsgDate = utils.getMomentDate(firstOfLastBlock.mesg_date);
          let duration = moment.duration(thisMsgDate.diff(lastMsgDate));
          if(Math.abs(duration.asMinutes()) < 10){
            lastBlock.blockList.push(thisMsg);
            partOfBlock = true;
          }
        }
      }

      if(!partOfBlock){
        let newBlock = {
          guest_uid : thisMsg.guest_uid,
          blockList: [thisMsg]
        }
        lookup.push(newBlock);
        lastBlock = newBlock;
      }
      //console.timeEnd('getMessageBlocks-buildlastblock');
    }
    //console.timeEnd('getMessageBlocks-buildlist');

    //console.timeEnd('getMessageBlocks');
    return lookup;
  },

  isSigningStatusIncomplete(doc){
    let sign_request_id = _.get(doc, 'sign_request_id', false);
    let signer_info = _.get(doc, 'signer_info', false);

    // log.log('isSigningStatusIncomplete', doc, sign_request_id, signer_info)
    //Incomplete status now looks like a sign_request_id, but no signer_info.
    return sign_request_id && signer_info && sign_request_id.length > 0 && signer_info.length === 0;
  },

  isSigningStatusNone(doc){
    let sign_request_id = _.get(doc, 'sign_request_id', false);
    let signer_info = _.get(doc, 'signer_info', false);

    return !sign_request_id || !signer_info || signer_info.length === 0;
  },

  generateMessageHash(mesg){

    //short circuiting for now.
    //return 0;

    // console.time('hashblocks')
    //let hashString = '';

    // edited_flag: false
    // email_address: "swartzk@gmail.com"
    // first_name: "Kevin"
    // guest_uid: "60359d228bccf207"
    // last_name: "Swartz"
    // mesg: "one"
    // mesg_date: 1618514600
    // mesg_id: 0
    // tokens: [{…}]
    // updated_date: null

    //doc
    // doc_id: "6075b04fb29958c9"
    // label: "new doc (4) (1).pdf"
    // sign_request_id: "6075b04fdfdd86be"
    // sign_status: "pending signature"
    // signer_uid: "6035a424eea81529"

    let blockString = '';
    blockString += '' + mesg.guest_uid;
    //I think that capturing id, mesg, mesg_date, and update_date captures
    //all update possibilities here.  The other properties will change too, but
    //these properties will encapsulate those changes for us.
    blockString += '' + mesg.mesg_id;
    blockString += '' + mesg.mesg;
    blockString += '' + mesg.mesg_date;
    blockString += '' + mesg.updated_date;

    if(mesg.docs){
      _.each(mesg.docs, (d) => {
        blockString += '' + d.doc_id;
        blockString += '' + d.label;
        blockString += '' + d.sign_request_id;

        _.each(d.signer_info, (info) => {
          blockString += '' + info.sign_status;
          blockString += '' + info.signer_uid;
        })
      })
    }

    if(mesg.bill){
      blockString += '' + mesg.bill.amount;
      blockString += '' + mesg.bill.description;
      blockString += '' + mesg.bill.paid_flag;
      blockString += '' + mesg.bill.payer_uid;
      blockString += '' + mesg.bill.payment_date;
      blockString += '' + mesg.bill.refund_amount;
      blockString += '' + mesg.bill.request_date;
      blockString += '' + mesg.bill.invoice_url;
    }

    //gets the TEXT hashcode, and makes a big text list of them.
    //hashString += blockString;

    let res = utils.generateHashCode(blockString);
    //console.timeEnd('hashblocks')
    return res;
  }
}

export default msgHelper;
