import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import c from '../../util/const';
import colors from '../../util/colors';
import vfLocalStorage from "../../util/local-storage";
import vfSessionStorage from "../../util/session-storage";
import sharedActions from '../../actions/shared-actions';
import modalActions from '../../actions/modal-actions';
import Header from "../partials/components/Header";
import log from "../../util/log";
import Loading from "../partials/util/Loading";
import Button from "../partials/elements/Button";
import sapi from "../../util/sapi";
import _ from "lodash";
import GuestRow from "../partials/rows/GuestRow";
import ThreadRow from "../partials/rows/ThreadRow";
import classNames from "classnames";
import ScrollingAccordion from "../partials/components/ScrollingAccordion";
import WorkspaceRow from "../partials/rows/WorkspaceRow";
import ScrollingTabView from "../partials/components/ScrollingTabView";
import ExpandableRow from "../partials/components/ExpandableRow";
import Scroll from "react-scroll";
import NotificationIcon from "../partials/components/NotificationIcon";
import ChatPanel from "../partials/chat/ChatPanel";
import DMPanel from "../partials/chat/DMPanel";
import workspaceActions from "../../actions/workspace-actions";
import Promise from "bluebird";
import homeActions from "../../actions/home-actions";
import moment from "moment";
import UploadHelper from "../partials/components/UploadHelper";
import SearchWindow from "../modals/SearchWindow";
import appActions from "../../actions/app-actions";
import {withRouter} from "react-router-dom";
import GuestList from "../partials/rows/GuestList";
import utils from "../../util/util";
import {Helmet} from "react-helmet";
import PendingMsgCache from "../../helpers/pending-msg-cache";
import querystring from "query-string";
import PlaceholderLoaders from "../partials/util/PlaceholderLoaders";
import {withVFTranslation} from "../../util/withVFTranslation";
import msgHelper from "../../helpers/msg-helper";
import SignArchiveList from "../partials/rows/SignArchiveList";
import downloadActions from "../../actions/download-actions";
import {getMessageForError} from "../../util/errors";

let scroll = Scroll.animateScroll;

const CONTACTS_TAB = 'sign-archive-tab';

const TABVIEW_ID = 'sign-archive-scroll-tabview';

class SignArchive extends Component {

  SORTS = {
    UPDATED_DATE_ASC : 'updated_date_asc',
    UPDATED_DATE_DESC : 'updated_date_desc',
    RECORD_LABEL_ASC : 'record_label_asc',
    RECORD_LABEL_DESC : 'record_label_desc',
  }

  constructor(props) {
    super(props);

    let savedArchiveSort = vfLocalStorage.get(c.localstorage.signArchiveSort);

    this.state = {
      archiveListSort : savedArchiveSort || this.SORTS.UPDATED_DATE_DESC,
      loading: false,
      archiveListScrollRef : null,

      visibleItemRangeStart : 0,
      visibleItemRangeStop : 0,

      signArchiveInfoLookup : [],
      signArchiveList : null,
      sortedArchiveList : null
    }
  }

  componentDidMount() {
    this.setState({loading:true})
    this.doStartup()
      .then(() =>{
        this.setState({loading: false})
      })
      .catch((err) =>{
        log.log('error on sign archive startup', err);
        this.props.history.push('/home');
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.archiveListSort !== this.state.archiveListSort ||
      prevState.signArchiveList !== this.state.signArchiveList) {
      this.updateSortedList();
    }
  }

  doStartup(){
    return this.refreshData();
  }

  refreshData(){
    return sapi.SignArchive.list()
      .then((res) => {
        log.log('got sign archive res', res);
        this.setState({signArchiveList : _.get(res, 'data', [])})
      })
  }

  getSortOptions(){
    let { t } = this.props;
    return {

      [this.SORTS.UPDATED_DATE_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.UPDATED_DATE_DESC,
        display: t('Sort: Record Date')
      },
      [this.SORTS.UPDATED_DATE_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.UPDATED_DATE_ASC,
        display: t('Sort: Record Date')
      },

      [this.SORTS.RECORD_LABEL_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.RECORD_LABEL_ASC,
        display: t('Sort: File Name')
      },
      [this.SORTS.RECORD_LABEL_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.RECORD_LABEL_DESC,
        display: t('Sort: File Name')
      },
    }
  }

  updateSortedList(){
    let { signArchiveList } = this.state;
    this.setState({
      sortedArchiveList : this.doSortList(signArchiveList)
    }, () => {
      if(this.state.archiveListScrollRef) {
        this.state.archiveListScrollRef.recomputeList();
      }
    })
  }

  doSortList(signArchiveList){
    let { archiveListSort } = this.state;
    if(archiveListSort === this.SORTS.UPDATED_DATE_DESC){
      return this.doUpdatedDateSort(signArchiveList, true);
    }
    else if(archiveListSort === this.SORTS.UPDATED_DATE_ASC){
      return this.doUpdatedDateSort(signArchiveList, false);
    }
    else if(archiveListSort === this.SORTS.RECORD_LABEL_DESC){
      return _.orderBy((signArchiveList), [item => item['label'].toLowerCase()], ['desc']);
    }
    else if(archiveListSort === this.SORTS.RECORD_LABEL_ASC){
      return _.orderBy((signArchiveList), [item => item['label'].toLowerCase()], ['asc']);
    }
    else{
      throw new Error('Unsupported Sort')
    }
  }

  doUpdatedDateSort(archiveList, isDesc) {

    //Note : We had some really handling here that I removed for bug 2566.
    if(isDesc) {
      return _.concat(
        _.sortBy((archiveList), (item) => {
          return -_.get(item, 'updated_date') || -_.get(item, 'created_date') || -1;
        })
      )
    }
    else {
      return _.concat(
        _.sortBy((archiveList), (item) => {
          return +_.get(item, 'updated_date') || +_.get(item, 'created_date') || 1;
        })
      )
    }
  }

  onDeleteRecordClick(row) {
    log.log('onDeleteRecordClick', row);
    let {t} = this.props;
    this.props.showConfirm(
      t("Are you sure?"),
      t("Are you sure you want to delete this record?"),
      (res) => {
        if(res){
          sapi.SignArchive.delete(row.sign_request_id)
            .then((res) => {
              this.refreshData();
            })
            .catch((err) => {
              log.log('error deleting archive', err);
              this.props.showAlert(t("Error deleting record"), getMessageForError(err, t))
            })
        }
      })

  }

  onItemClick(row){
    log.log('onItemClick', row);
    this.onPreviewDocumentClick(row);
  }

  onPreviewDocumentClick(row){
    let foundInfo = _.find(this.state.signArchiveInfoLookup, (info) => info.sign_request_id === row.sign_request_id);
    this.props.showDocPreview(row, foundInfo, (res) => {
      log.log('sign context preview window closed', res);
    })
  }

  onViewSigningInfoClick(row){
    let foundInfo = _.find(this.state.signArchiveInfoLookup, (info) => info.sign_request_id === row.sign_request_id);
    log.log('onViewSigningInfoClick', row, foundInfo);
    this.props.showSignArchiveMetadataWindow(row.host_uid, row, foundInfo, (res) => {
      log.log('signing metadata window closed', res);
    })
  }

  getDownloadHeaders(item) {
    let params = {};

    if(item.host_uid){
      params.host_uid = item.host_uid;
    }
    if(item.sign_request_id){
      params.sign_request_id = item.sign_request_id;
    }
    if(item.label){
      params.label = item.label;
    }
    if(item.doc_key){
      params.doc_key = item.doc_key;
    }
    params[c.api.X_TOKEN] = sapi.getToken();
    return params;
  }

  onDocumentDownload(row){
    log.log('onDocumentDownload', row);
    this.props.doDownload(this.getDownloadHeaders(row), sapi.SignArchive.url('download'));
  }

  fetchSignInfo(sign_request_id, host_uid){
    let {
      signArchiveInfoLookup
    } = this.state;
    let found = _.find(signArchiveInfoLookup, (search) => search.sign_request_id === sign_request_id);
    // log.log('fetch info called', sign_request_id, host_uid, found, signArchiveInfoLookup);
    if(found){
      return Promise.resolve(found);
    }
    else{
      return new Promise((resolve, reject) => {
        sapi.SignArchive.info(sign_request_id, host_uid)
          .then((res) => {
            let cacheResult = {
              sign_request_id: sign_request_id,
              data: _.get(res, 'data')
            }
            this.setState({
              signArchiveInfoLookup: _.concat([cacheResult], this.state.signArchiveInfoLookup)
            }, () => {
              resolve(cacheResult);
            })
          })
          .catch((err) => {
            log.log('error fetching sign info', err);
            //If we do nothing here, we'll try and fetch this again in the next pass, hopefully this fixes things?
            reject(err);
          })
      })
    }
  }

  renderListContents(){
    let {
      t
    } = this.props;
    let {
      signArchiveInfoLookup,
      sortedArchiveList,
      signArchiveList,
      loading
    } = this.state;

    if(loading){
      return (
        <div className="text-center"  style={{marginTop: '15vh'}}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    if(!signArchiveList || !sortedArchiveList){
      return null;
    }

    if(signArchiveList.length === 0){
      return (
        <div className="text-center"  style={{marginTop: '15vh'}}>
          <p className="secondary-text-color">
            {t("When you participate in a Signing Request, a record of it will be displayed here.")}
          </p>
          {/*<div className="mt-5">*/}
          {/*  <button className="btn btn-lg btn-primary"*/}
          {/*          onClick={this.newContact.bind(this)}>*/}
          {/*    {t("Add a Contact")}*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      )
    }

    return (
      <div className="row h-auto">
        <div style={{
          paddingRight: '0px',
          marginRight: '-5px',
          overflowY: 'auto'
        }} className={classNames('col center-col tab-view')}>
          <SignArchiveList itemList={sortedArchiveList}
                           fetchSignInfo={this.fetchSignInfo.bind(this)}
                           onPreviewDocumentClick={this.onPreviewDocumentClick.bind(this)}
                           onViewSigningInfoClick={this.onViewSigningInfoClick.bind(this)}
                           onDocumentDownload={this.onDocumentDownload.bind(this)}
                           signArchiveInfoLookup={signArchiveInfoLookup}
                           onDeleteRecord={this.onDeleteRecordClick.bind(this)}
                           onItemClick={this.onItemClick.bind(this)}
                           onRef={(ref) => this.setState({archiveListScrollRef : ref})}/>
        </div>
      </div>
    )
  }

  setArchiveListSort(sort){
    vfLocalStorage.set(c.localstorage.signArchiveSort, sort);
    this.setState({archiveListSort : sort})
  }

  renderSortOptionRow(activeSort, option1, option2, optionIfNeither, doSelectSort){
    let sortOptions = this.getSortOptions();
    return (
      <a onClick={doSelectSort.bind(this, activeSort === option1 ? option2 : option1)}
         className={`sort-row list-group-item list-group-item-action ${(activeSort === option1 || activeSort === option2) ? 'active' : ''}`}>
        {activeSort === option1 &&
        <Fragment>
          <i className={`icon ${sortOptions[option1].icon} mr-2`}/>
          {sortOptions[option1].display}
        </Fragment>
        }
        {activeSort === option2 &&
        <Fragment>
          <i className={`icon ${sortOptions[option2].icon} mr-2`}/>
          {sortOptions[option2].display}
        </Fragment>
        }
        {activeSort !== option2 && activeSort !== option1 &&
        <Fragment>
          <i className={`icon mr-2`}/>
          {sortOptions[optionIfNeither].display}
        </Fragment>
        }
      </a>
    )
  }

  renderTabView(){
    let { t } = this.props;
    let { archiveListSort } = this.state;
    let tabs = [];

    tabs.push({
      id : CONTACTS_TAB,
      tabRenderFn: (tab) => {
        return (
          <div className="d-inline-block" style={{marginLeft : '10px'}}>
            <h4 className={'m-0 list-header'}>
              <NotificationIcon iconColorOverride={colors.DARK}
                                iconCls="ion-ios-bookmarks-outline"
                                value={0} />
              {t("Signing Archive")}
            </h4>
          </div>
        )
      },
      isSelected : true,
      listRenderFn : () => {
        return this.renderListContents();
      },
      getSortingPopoverContent: () => {
        return (
          <Fragment>
            {this.renderSortOptionRow(archiveListSort, this.SORTS.UPDATED_DATE_DESC, this.SORTS.UPDATED_DATE_ASC, this.SORTS.UPDATED_DATE_DESC, this.setArchiveListSort)}
            {this.renderSortOptionRow(archiveListSort, this.SORTS.RECORD_LABEL_ASC, this.SORTS.RECORD_LABEL_DESC, this.SORTS.RECORD_LABEL_ASC, this.setArchiveListSort)}
          </Fragment>
        )
      },
      getSortLabel: () => {
        return this.getSortOptions()[archiveListSort].display
      }
    })

    return (
      <div className="row">
        <div className="col p-0">
          <ScrollingTabView id={TABVIEW_ID}
                            headerCls="sign-archive-tabs"
                            scrollOffset={70}
                            tabs={tabs}
                            customTabHeight={58} />
        </div>
      </div>
    )
  }

  renderHelmetTags(){

    return (
      <div>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: c.smartbanner.non_mobile_viewport_banner
          }]}
        />
        <Helmet>
          <meta name="viewport" content="" />
        </Helmet>
      </div>
    )
  }

  render() {
    return (
      <div className={'column-scroll-layout has-header'}>
        {this.renderHelmetTags()}
        <Header isLockedToTop={true}
                showSearch={false}
                showBackButton={true}
                showAcctHeader={true} />
        <div className="container-fluid">
          {this.renderTabView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url)),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignArchive)));
