import _ from 'lodash'
//just a central place to share all these font constants

export const fontConstants = {
  LA_BELLE_AURORE : {
    name : 'LA_BELLE_AURORE', //just a lookup
    class : 'la_belle_aurore', //class name as defined in src/scss/font.scss
    familyName : 'La Belle Aurore' //font family name, as defined in public/fonts/font-import.css
  },
  HELVETICA : {
    name : 'HELVETICA',
    class : 'helvetica',
    familyName : 'Helvetica'
  },
  SACRAMENTO : {
    name : 'SACRAMENTO',
    class : 'sacramento',
    familyName : 'Sacramento-Regular'
  },
  CEDARVILLE_CURSIVE : {
    name : 'CEDARVILLE_CURSIVE',
    class : 'cedarville_cursive',
    familyName : 'Cedarville-Cursive'
  },
  DANCING_SCRIPT : {
    name : 'DANCING_SCRIPT',
    class : 'dancing_script',
    familyName : 'DancingScript-Regular'
  },
  COURIER : {
    name : 'COURIER',
    class : 'courier',
    familyName : 'Courier'
  },
  TIMES_NEW_ROMAN : {
    name : 'TIMES_NEW_ROMAN',
    class : 'times_new_roman',
    familyName : 'Times-Roman'
  },


  CAIRO : {
    name : 'CAIRO',
    class : 'cairo',
    familyName : 'Cairo'
  },
  NOTO_SANS_JP : {
    name : 'NOTO_SANS_JP',
    class : 'noto_sans_jp',
    familyName : 'Noto Sans JP'
  },
  NOTO_SANS_SC : {
    name : 'NOTO_SANS_SC',
    class : 'noto_sans_sc',
    familyName : 'Noto Sans SC'
  },
  NOTO_SANS_KR : {
    name : 'NOTO_SANS_KR',
    class : 'noto_sans_kr',
    familyName : 'Noto Sans KR'
  },
  ROBOTO_CYRILLIC : {
    name : 'ROBOTO_CYRILLIC',
    class : 'roboto_cyrillic',
    familyName : 'Roboto Cyrillic'
  },
}

export const buildFontOption = (displayName, opt) => {
  return _.extend({}, {display : displayName}, opt);
}

export const fontsNeedingPreload = [
  fontConstants.DANCING_SCRIPT,
  fontConstants.SACRAMENTO,
  fontConstants.CEDARVILLE_CURSIVE,
  fontConstants.LA_BELLE_AURORE,
  fontConstants.TIMES_NEW_ROMAN,
  fontConstants.COURIER
]

export const buildFontPicker = (options) => {
  
  let res = [];
  _.each(options, (opt) => {
    res.push({
      display : opt.display,
      val : opt.familyName,
      cls : opt.class
    })
  })
  return res;
  
}
