import electronUtil from "../util/electron-util";
import log from "../util/log";
import sapi from "../util/sapi";
import utils from "../util/util";
import {fetchToken, onMessageListener, unregisterToken, isWebPushSupported} from "../firebase";


const pushNotificationHelper = {

  initWebPushNotifications(){

    // collapseKey: undefined
    // data:
    //   chat_id: "61d5eb001ba63312"
    // column: "msgs"
    // forum_id: "61d5eb001b680424"
    // guest_uid: "61d5eacb01e8a9b7"
    // mesg_id: "298"
    //   [[Prototype]]: Object
    // from: "886821826621"
    // messageId: "8aa4f229-289e-46fd-b38e-59ad05e54b27"
    // notification:
    //   body: "New encrypted message from Kevin Junk (swartzk.junk@gmail.com)\nTap to decrypt and view\n"
    // title: "Kevin Junk\n"

    onMessageListener()
      .then((payload) => {

        //We don't do anything with foreground notifications, but we could at some point.

        log.log('fetch message result', payload);
      })
      .catch((err) => {
        log.log('err fetch message result', err);
      });
  },

  isElectronEnvironment(){
    return Promise.resolve(electronUtil.isRunningWithinElectron());
  },

  isWebPushEnvironment(){
    return Promise.resolve(isWebPushSupported());
  },

  isSupportedEnvironment(){
    return Promise.all([
      this.isWebPushEnvironment(),
      this.isElectronEnvironment()
    ])
      .then((res) => {
        return !!(res[0] || res[1]);
      })
  },

  submitPushToken(){
    return this.waitForPushToken()
      .then((res) => {
        if(res){
          return sapi.Notify.addPushToken(res);
        }
        else{
          return false;
        }
      })
  },

  fetchWebToken(){
    return fetchToken();
  },

  waitForPushToken(){

    let promise = null;
    if(electronUtil.isRunningWithinElectron()){
      promise = electronUtil.getPushToken();
    }
    else {
      promise = this.fetchWebToken();
    }

    return promise
      .then((res) => {
        if(res){
          return res;
        }
        else{
          return utils.waitFor(100)
            .then((res) => {
              return this.waitForPushToken();
            })
        }
      })
  },

  setupPushSvc(){
    return new Promise((resolve, reject) => {
      if(electronUtil.isRunningWithinElectron()){
        electronUtil.initPushSvc()
      }
      else{
        //web push doesn't need further initialization.
        this.initWebPushNotifications();
      }

      resolve(this.submitPushToken());
    })
  },

  resetPushSvc(){
    return new Promise((resolve, reject) => {
      if(electronUtil.isRunningWithinElectron()){
        electronUtil.resetPushSvc()
        resolve(true);
      }
      else{
        resolve(unregisterToken());
      }
    })
  }
}

export default pushNotificationHelper;