import React, {Component} from 'react';
import PropTypes from 'prop-types'
import log from "../../../util/log";
import utils from "../../../util/util";
import _ from "lodash";
import PdfSigningOverlay from "../pdf-preview/PdfSigningOverlay";

class TextInputMeasurer extends Component {
  
  constructor(props){
    super(props);
    
    this.measureInputRef = React.createRef();
    
    this.state = {
      isDoingMeasuring : false,
      textToMeasure : '',
      initialFontSize : 0,
      measureToWidth : 0,
      
      measureFontSize : 0
    };
  }
  
  componentDidMount(){
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  setMeasuredFontSizeAndWait(newFontSize){
    this.setState({measureFontSize : +(newFontSize).toFixed(3)});
    return utils.waitForCondition(() => {
      let inputRef = _.get(this.measureInputRef, 'current');
      if(!inputRef){
        return false;
      }
      let nodeStyle = window.getComputedStyle(inputRef)
      let fontSizeParsed = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('font-size'))
      // log.log('waiting for font size to update', fontSizeParsed.toFixed(1), newFontSize.toFixed(1));
      return fontSizeParsed.toFixed(3) === newFontSize.toFixed(3)
    })
  }
  
  getParsedFontSize(){
    let inputRef = _.get(this.measureInputRef, 'current');
    let nodeStyle = window.getComputedStyle(inputRef)
    let fontSizeParsed = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('font-size'))
    return fontSizeParsed;
  }
  
  getFontSizeChangeIncrement(inputFontSize){
    return Math.max(.5, (.05 * inputFontSize));
  }
  
  doMeasureAndUpdateFontSize(resetMeasure){
    return new Promise((resolve, reject) => {
      let parsedMeasuredFontSize = this.getParsedFontSize();
      let currentPassFontSize = resetMeasure ? this.state.initialFontSize : (parsedMeasuredFontSize || this.state.initialFontSize);
      // log.log('doMeasureAndUpdateFontSize', resetMeasure, parsedMeasuredFontSize, currentPassFontSize);
      
      this.setMeasuredFontSizeAndWait(currentPassFontSize)
        .then(() => {
          let offsetWidth = _.get(this.measureInputRef, 'current.offsetWidth');
  
          //Accounts for the difference of input width with visible
          //input space due to padding, border.
          let bufferSpace = this.props.measureBufferSpace;
          let measuredInputWidth = offsetWidth;
          let overlayWidth = this.state.measureToWidth - bufferSpace;
      
          log.log('measured width, with target', offsetWidth, overlayWidth);
      
          if (measuredInputWidth > overlayWidth) {
            // log.log('trying to shrink font size');
            let increment = this.getFontSizeChangeIncrement(this.state.initialFontSize);
            // log.log('shrinking font size with increment', increment, this.state.initialFontSize, this.state.measureFontSize);
            if((this.state.measureFontSize - increment) <= 0){
              log.warn('cannot fit resolve smallest');
              this.setState({
                measureFontSize : increment * .1
              }, () => {
                resolve(this.state.measureFontSize);
              })
            }
            else{
              let newSize = this.state.measureFontSize - increment;
              //log.log('shrinking measure size to', newSize );
              this.setMeasuredFontSizeAndWait(newSize)
                .then(() => {
                  resolve(this.doMeasureAndUpdateFontSize());
                })
            }
          }
          else if (measuredInputWidth < overlayWidth && this.state.measureFontSize < this.state.initialFontSize) {
            let newSize = this.state.measureFontSize + this.getFontSizeChangeIncrement(this.state.initialFontSize);
            //log.log('trying to grow font size to', newSize);
            this.setMeasuredFontSizeAndWait(newSize)
              .then(() => {
                var measuredWidth = _.get(this.measureInputRef, 'current.clientWidth');
                if (measuredWidth > overlayWidth) {
                  //revert incremental change, the previous is the winner.
                  this.setState({
                    measureFontSize : this.state.measureFontSize - this.getFontSizeChangeIncrement(this.state.initialFontSize)
                  }, () => {
                    // log.log('found measured font size', this.state.measureFontSize);
                    resolve(this.state.measureFontSize);
                  })
                }
                else {
                  resolve(this.doMeasureAndUpdateFontSize());
                }
              })
          }
          else {
            // log.log('found measured font size', this.state.measureFontSize);
            resolve(this.state.measureFontSize);
          }
        })
        .catch((err) => {
          log.log('error while measuring font size', err);
          reject(err);
        })
    })
  }
  
  startMeasure(textToMeasure, initialFontSize, measureToWidth){
    return new Promise((resolve, reject) => {
      if(this.state.isDoingMeasuring){
        log.log('is already measuring');
        reject('already measuring');
        return;
      }
      if(!textToMeasure || textToMeasure.length === 0){
        log.log('no text');
        reject('no measure text');
        return;
      }
  
      // log.log('about to start measure', this.state, textToMeasure, initialFontSize, measureToWidth);
      this.setState({
        textToMeasure,
        initialFontSize : this.props.scaleRelativeToOverlay(initialFontSize),
        measureToWidth : measureToWidth,
        isDoingMeasuring : true
      }, () => {
        //log.log('starting measure for', this.state);
  
        this.doMeasureAndUpdateFontSize(true)
          .then((res) => {
            //log.log('done measuring', res);
            this.setState({ isDoingMeasuring : false }, () => resolve(res))
          })
          .catch((err) => {
            log.log('error during measuring', err);
            this.setState({ isDoingMeasuring : false }, () => reject(err))
          })
      })
    })
  }
  
  render() {
    let {
      textToMeasure,
      measureFontSize,
  
      isDoingMeasuring
    } = this.state;
    
    if(!isDoingMeasuring){
      return null;
    }
      let classNames = "d-block invisible " + (this.props.fontClass || '');

    return (
      <div className={classNames}
           style={{
             position: 'absolute',
             pointerEvents: 'none',
             whiteSpace: 'nowrap',
             display: 'inline-block',
             zIndex : 0
           }}>
        <div style={{
               visibility: 'hidden',
               width: 'auto',
               whiteSpace: 'nowrap',
               display: 'inline-block',
               fontSize : `${measureFontSize}px`,
               letterSpacing: `${-0.5}px`,
             }}
             ref={this.measureInputRef}>
          {textToMeasure}
        </div>
      </div>
    )
  }
}

TextInputMeasurer.propTypes = {
  onRef : PropTypes.func.isRequired,
  scaleRelativeToOverlay : PropTypes.func.isRequired,
  measureBufferSpace : PropTypes.number.isRequired,
  fontClass : PropTypes.string
}

export default TextInputMeasurer;
