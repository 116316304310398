import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import _ from 'lodash';
import classNames from 'classnames';
import utils from "../../../../util/util";
import log from "../../../../util/log";
import colors from "../../../../util/colors";
import AccountSignaturesPanel from "./AccountSignaturesPanel";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class SignaturePanelCanvas extends Component {

  constructor(props){
    super(props);

    this.signatureRef = React.createRef();

    this.state = {
      measuredWidth : 1,
      isMouseDown : false,

      fromCoords : null,
      isDrawMode : false,

      drawnChangesMade : false,
      canvasMeasuredPxWidth : 1,
      canvasMeasuredPxHeight : 1
    };
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  setDrawMode(isDrawMode){
    this.setState({
      isDrawMode,
      drawnChangesMade : false
    })
  }

  haveDrawnChangesBeenMade(){
    return this.state.drawnChangesMade;
  }

  generateCanvasImage(){
    return this.signatureRef.current.toDataURL('image/png', 1.0);
  }

  resetCanvas(){
    return new Promise((resolve, reject) => {
      this.setState({
        measuredWidth : 1
      }, () => {
        resolve(true);
      })
    })
  }

  clearDrawnCanvas(){
    let canvasRef = _.get(this, 'signatureRef.current');
    if(canvasRef) {
      let ctx = canvasRef.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
    }
  }

  doRedrawCanvas(font, text, fontSize = 32, topPadding = 10, leftPadding = 8){
    setTimeout(() => {
      this.redrawCanvas(font, text, fontSize, topPadding, leftPadding)
        .then(() => {
          setTimeout(() => {
            this.redrawCanvas(font, text, fontSize, topPadding, leftPadding)
          }, 100)
        })
    })
  }

  redrawCanvas(font, text, fontSize, topPadding, leftPadding) {
    return new Promise((resolve, reject) => {
      this.waitForCanvasRef()
        .then(() => {
          let canvasRef = _.get(this, 'signatureRef.current');
          if(!canvasRef){
            log.warn('no canvas ref in redraw', this.signatureRef);
            resolve(true);
            return
          }

          let ctx = canvasRef.getContext('2d');
          ctx.font = `${fontSize}px ${font}`;
          let measuredText = ctx.measureText(text);
          this.setState({
            measuredWidth: ((leftPadding * 2) + measuredText.width)
          }, () => {
            setTimeout(() => {
              let canvasRef = _.get(this, 'signatureRef.current');
              if(canvasRef) {
                let ctx = canvasRef.getContext('2d');
                ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
                ctx.font = `${fontSize}px ${font}`;

                ctx.fillText(text, leftPadding, topPadding + (fontSize / 2));
                //ctx.fillText(text, 2, 32);
              }
              resolve(true);
            })
          })
        })
    })
  }

  waitForCanvasRef(){
    return utils.waitForCondition(() => {
      return _.get(this, 'signatureRef.current', false);
    })
  }

  drawLine(fromCoords, toCoords){
    let canvas = _.get(this.signatureRef, 'current');
    if (!canvas) {
      return;
    }

    if(!fromCoords || !toCoords){
      console.warn('was passed empty coords');
      return;
    }

    const context = canvas.getContext('2d');
    if (context) {
      // log.log('drawLine', toCoords);

      context.fillStyle = colors.DARK;
      context.lineWidth = 1;
      context.moveTo(0,0);

      context.beginPath();
      context.moveTo(fromCoords.x, fromCoords.y);
      context.lineTo(toCoords.x, toCoords.y);
      context.closePath();

      context.stroke();
    }
    this.setState({drawnChangesMade : true})
  }

  getMouseCoordinates(event) {
    // log.log('get mouse coords', this.signatureRef);
    let canvas = _.get(this.signatureRef, 'current');
    if (!canvas) {
      return;
    }
    let rect = event.target.getBoundingClientRect();
    if(event.targetTouches && event.targetTouches.length > 0){
      let offsetX = event.targetTouches[0].pageX - rect.left;
      let offsetY = event.targetTouches[0].pageY - rect.top;

      // log.log('getMouseCoordinates', offsetX, offsetY, rect)
      return{
        x : offsetX,
        y : offsetY
      };
    }
    else{
      let offsetX = event.nativeEvent.pageX - rect.left;
      let offsetY = event.nativeEvent.pageY - rect.top - window.scrollY;

      // log.log('getMouseCoordinates', offsetX, offsetY, rect)
      return {
        x : offsetX,
        y : offsetY
      };
    }
  }

  onCanvasMouseDown(evt){
    if(!this.state.isDrawMode){
      return;
    }
    let coordinates = this.getMouseCoordinates(evt);
    // log.log('on canvas mouse down', coordinates);
    this.setState({
      fromCoords : coordinates,
      isMouseDown : true
    })
  }

  onCanvasMouseMove(evt){
    if(!this.state.isDrawMode){
      return;
    }
    if(!this.state.isMouseDown){
      return;
    }
    let coordinates = this.getMouseCoordinates(evt);
    // log.log('on canvas mouse move', coordinates);

    this.drawLine(this.state.fromCoords, coordinates);
    this.setState({
      fromCoords : coordinates
    })
  }

  onCanvasMouseUp(evt) {
    if (!this.state.isDrawMode || !this.state.isMouseDown) {
      return;
    }
    let coordinates = this.getMouseCoordinates(evt);
    // log.log('on canvas mouse up', coordinates);

    this.drawLine(this.state.fromCoords, coordinates);
    this.setState({
      isMouseDown: false,
      fromCoords: null
    })
  }

  onCanvasResize(contentRect){
    if(!this.state.isDrawMode){
      //Then the canvas is used in the background, no need for this.
      return;
    }

    log.log('onCanvasResize', contentRect);

    this.setState({
      canvasMeasuredPxWidth : contentRect.bounds.width,
      canvasMeasuredPxHeight : contentRect.bounds.height
    })
  }

  render() {
    let {
      canvasHeight,
      styleObj,
      className,
      t
    } = this.props;

    let {
      measuredWidth,
      isDrawMode,

      canvasMeasuredPxWidth
    } = this.state;

    // log.log('render sig canvas', isDrawMode, canvasMeasuredPxWidth, canvasMeasuredPxHeight);

    if(!isDrawMode){
      let realStyle = _.extend({
        width : `${measuredWidth}px`,
        height : `${canvasHeight}px`
      }, styleObj);
      return (
        <canvas ref={this.signatureRef}
                width={measuredWidth}
                height={canvasHeight}
                style={realStyle}
                className={className}/>
      )
    }
    else{
      let realStyle = _.extend({
        width : `100%`,
        height : AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px'
      }, styleObj);
      return (
        <Measure
          bounds
          margin
          onResize={this.onCanvasResize.bind(this)}
        >
          {({measureRef}) => {
            return (
              <div ref={measureRef} className="w-100 h-100" style={{pointerEvents: 'none'}}>
                <div className="w-100 h-100" style={{pointerEvents: 'none'}}>
                  <canvas ref={this.signatureRef}
                          onTouchStart={e => this.onCanvasMouseDown(e)}
                          onMouseDown={e => this.onCanvasMouseDown(e)}
                          onMouseMove={e => this.onCanvasMouseMove(e)}
                          onTouchMove={e => this.onCanvasMouseMove(e)}
                          onMouseUp={e => this.onCanvasMouseUp(e)}
                          onMouseLeave={e => this.onCanvasMouseUp(e)}
                          onTouchEndCapture={e => this.onCanvasMouseUp(e)}
                          width={isDrawMode ? canvasMeasuredPxWidth : measuredWidth}
                          height={isDrawMode ? AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT : canvasHeight}
                          style={realStyle}
                          className={className}/>
                  <a className="btn btn-link primary-color hover-underline"
                     style={{
                       position: 'absolute',
                       right: '10px',
                       bottom: '10px',
                       zIndex: 100,
                       pointerEvents: 'auto'
                     }}
                     onClick={this.clearDrawnCanvas.bind(this)}>
                    {t("Clear")}
                  </a>
                </div>
              </div>
            )
          }}
        </Measure>
      )
    }
  }
}

SignaturePanelCanvas.propTypes = {
  onRef: PropTypes.func.isRequired,
  styleObj : PropTypes.object.isRequired,
  className : PropTypes.string.isRequired,
  canvasHeight : PropTypes.number.isRequired,
}

export default withVFTranslation()(SignaturePanelCanvas);
