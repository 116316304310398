import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import sapi from "../../../../util/sapi";
import log from "../../../../util/log";
import Image from "../../elements/Image";
import colors from "../../../../util/colors";
import utils from "../../../../util/util";
import _ from 'lodash'
import ValidationErrors from "../../components/ValidationErrors";
import {fontConstants, buildFontPicker, buildFontOption} from "../../../../util/font-constants";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import SignaturePanelCanvas from "./SignaturePanelCanvas";

class AccountSignaturesPanel extends Component {
  
  DRAWN_SIGNATURE = "drawn_signature";
  
  constructor(props) {
    super(props);
    
    this.signatureRef = null;
    this.signatureTextAreaRef = React.createRef();
    this.initialsRef = null;
    this.initialsTextAreaRef = React.createRef();
  
    let {accountInfo} = props;
    
    let firstState = {
      sigFont : fontConstants.LA_BELLE_AURORE.familyName,
      sigFontCls : fontConstants.LA_BELLE_AURORE.class,
      initFont : fontConstants.LA_BELLE_AURORE.familyName,
      initFontCls : fontConstants.LA_BELLE_AURORE.class,
      saving : false,
      showDrawSignature : false,
      showSignatureImage : !!(props.accountSignatures && props.accountSignatures.sign_image),
      showDrawInitials : false,
      showInitialsImage : !!(props.accountSignatures && props.accountSignatures.init_image),
      
      changesMade : false,
      validationErr : []
    };
    
    if(!props.accountSignatures){
      firstState.sigText = `${accountInfo.first_name} ${accountInfo.last_name}`
      firstState.initText = `${accountInfo.first_name[0]}${accountInfo.last_name[0]}`.toUpperCase()
    }
    else{
      firstState.sigText = "";
      firstState.initText = "";
    }
    
    this.state = firstState;
  }
  
  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
    
    if(!this.props.accountSignatures) {
      this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
      this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
      this.setState({changesMade : true})
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.accountInfo.first_name !== this.props.accountInfo.first_name){
      if(this.signatureRef) {
        this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
      }
      if(this.initialsRef) {
        this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
      }
    }
    else if(prevProps.accountInfo.last_name !== this.props.accountInfo.last_name){
      if(this.signatureRef) {
        this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
      }
      if(this.initialsRef) {
        this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
      }
    }
    if(prevProps.accountSignatures !== this.props.accountSignatures){
      this.setState({
        showSignatureImage : false,
        showInitialsImage : false
      }, () => {
        if(this.signatureRef) {
          this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
        }
        if(this.initialsRef) {
          this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
        }
      })
    }
  }
  
  getFontOptionPicker() {
    let {t} = this.props;
    let pickerOptions = buildFontPicker([
      buildFontOption(t('Handwriting Font #1'), fontConstants.LA_BELLE_AURORE),
      buildFontOption(t('Handwriting Font #2'), fontConstants.CEDARVILLE_CURSIVE),
      buildFontOption(t('Handwriting Font #3'), fontConstants.SACRAMENTO),
      buildFontOption(t('Handwriting Font #4'), fontConstants.DANCING_SCRIPT),
    ])
    
    pickerOptions.push({
      display: t("Draw Your Own"),
      cls : "",
      val: this.DRAWN_SIGNATURE
    })
    
    return pickerOptions;
  }

  getPixelRatio(canvas) {
    var ctx = canvas.getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio || 1;
    
    return dpr / bsr;
  }
  
  reset() {
    let {accountInfo, accountSignatures} = this.props;
  
    let resetState = {
      sigFont : fontConstants.LA_BELLE_AURORE.familyName,
      sigFontCls : fontConstants.LA_BELLE_AURORE.class,
      initFont : fontConstants.LA_BELLE_AURORE.familyName,
      initFontCls : fontConstants.LA_BELLE_AURORE.class,
      saving : false,
      showSignatureImage : !!(accountSignatures && accountSignatures.sign_image),
      showInitialsImage : !!(accountSignatures && accountSignatures.init_image),
      validationErr : []
    }
  
    if(!this.props.accountSignatures){
      resetState.sigText = `${accountInfo.first_name} ${accountInfo.last_name}`
      resetState.initText = `${accountInfo.first_name[0]}${accountInfo.last_name[0]}`.toUpperCase()
    }
    else{
      resetState.sigText = "";
      resetState.initText = "";
    }
    
    this.setState(resetState);
  
    if(this.signatureRef){
      this.signatureRef.resetCanvas()
        .then(() => {
          this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
        })
    }
    if(this.initialsRef){
      this.initialsRef.resetCanvas()
        .then(() => {
          this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
        })
    }
  }
  
  hasChanges(){
    return this.state.changesMade;
  }
  
  onSigFontChange(evt){
    let selectedValue = _.get(evt, 'target.value');
    let opt = _.find(this.getFontOptionPicker(), (opt) => opt.val === selectedValue);
    
    this.setState({
      changesMade : true,
      sigFont : selectedValue,
      sigFontCls : opt.cls,
      showDrawSignature : selectedValue === this.DRAWN_SIGNATURE,
      showSignatureImage : false
    }, () => {
      if(selectedValue === this.DRAWN_SIGNATURE){
        this.signatureRef.setDrawMode(true);
        this.setState({sigText : ''})
      }
      else{
        this.signatureRef.setDrawMode(false);
        this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
      }
    })
  }
  
  onSigImgClick(){
    if(this.props.preventEdit){
      return;
    }
    
    this.setState({
      changesMade : true,
      showSignatureImage : false,
      showDrawSignature : this.state.sigFont === this.DRAWN_SIGNATURE
    }, () => {
      this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
      utils.waitForCondition(() => {
        return !!_.get(this, 'signatureTextAreaRef.current');
      })
        .then(() => {
          this.signatureTextAreaRef.current.focus();
        })
    })
  }
  
  onInitImgClick(){
    if(this.props.preventEdit){
      return;
    }
    
    this.setState({
      changesMade : true,
      showInitialsImage : false,
      showDrawInitials : this.state.initFont === this.DRAWN_SIGNATURE
    }, () => {
      this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
      utils.waitForCondition(() => {
          return !!_.get(this, 'initialsTextAreaRef.current');
        })
        .then(() => {
          this.initialsTextAreaRef.current.focus();
        })
    })
  }
  
  onInitFontChange(evt){
    let selectedValue = _.get(evt, 'target.value');
    let opt = _.find(this.getFontOptionPicker(), (opt) => opt.val === selectedValue);
    
    this.setState({
      changesMade : true,
      initFont : selectedValue,
      initFontCls : opt.cls,
      showDrawInitials : selectedValue === this.DRAWN_SIGNATURE,
      showInitialsImage : false
    }, () => {
      if(selectedValue === this.DRAWN_SIGNATURE){
        this.initialsRef.setDrawMode(true);
        this.setState({initText : ''})
      }
      else{
        this.initialsRef.setDrawMode(false);
        this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
      }
    })
  }
  
  generateImages(){
    let { accountSignatures } = this.props;
    let { showInitialsImage, showSignatureImage } = this.state;
  
    let sig = null;
    let init = null;
    if(showInitialsImage){
      init = accountSignatures.init_image;
    }
    else{
      init = this.initialsRef.generateCanvasImage();
    }
    if(showSignatureImage){
      sig = accountSignatures.sign_image;
    }
    else{
      sig = this.signatureRef.generateCanvasImage();
    }
    
    return {
      sign_image : sig,
      init_image : init
    }
  }
  
  onSignatureTextUpdate(evt){
    this.setState({sigText : evt.target.value}, () => {
      this.signatureRef.doRedrawCanvas(this.state.sigFont, this.state.sigText);
    })
  }
  
  onInitTextUpdate(evt){
    this.setState({initText : evt.target.value}, () => {
      this.initialsRef.doRedrawCanvas(this.state.initFont, this.state.initText);
    })
  }
  
  doValidation(){
    let {
      showSignatureImage,
      showInitialsImage,
      initText,
      sigText,
  
      sigFont,
      initFont,
    } = this.state;
    
    let err = [];
    if(!showSignatureImage){
      if(sigFont === this.DRAWN_SIGNATURE){
        if(!this.signatureRef.haveDrawnChangesBeenMade()){
          err.push(this.props.t("Please draw your signature"));
        }
      }
      else if(sigText.length === 0){
        err.push(this.props.t("Please enter your signature"));
      }
    }
    
    if(!showInitialsImage){
      if(initFont === this.DRAWN_SIGNATURE){
        if(!this.initialsRef.haveDrawnChangesBeenMade()){
          err.push(this.props.t("Please draw your initials"));
        }
      }
      else if(initText.length === 0){
        err.push(this.props.t("Please enter your initials"));
      }
    }
    
    this.setState({validationErr : err});
    return err.length === 0;
  }
  
  render(){
    let {
      accountSignatures,
      preventEdit,
      t
    } = this.props;
    let {
      sigFont,
      initFont,
      showSignatureImage,
      showInitialsImage,
      showDrawInitials,
      showDrawSignature,
      sigText,
      sigFontCls,
      initText,
      initFontCls,
      validationErr
    } = this.state;
    
    return(
      <>
        <div className="row">
          <div className="col-7">
            <div className="d-table mb-2">
              <label className="d-table-cell pr-2 pl-4">{t('Signature')}</label>
              {!preventEdit &&
              <select className="form-control d-table-cell"
                      value={sigFont}
                      onChange={this.onSigFontChange.bind(this)}>
                {this.getFontOptionPicker().map((option) => {
                  return <option className={option.cls}
                                 key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
              }
            </div>
            <div style={styles.signatureBox}>
              {showSignatureImage &&
              <div style={styles.sigImgBox}
                   onClick={this.onSigImgClick.bind(this)}>
                {accountSignatures &&
                <div style={styles.sigImgWrap}>
                  <Image style={styles.sigImg} src={accountSignatures.sign_image} alt={t('Signature')} />
                </div>
                }
              </div>
              }
              {!showSignatureImage &&
              <>
                {!showDrawSignature &&
                <textarea className={"m-auto text-center d-table position-absolute no-resize " + sigFontCls}
                          style={styles.textarea}
                          maxLength={AccountSignaturesPanel.SIG_MAX_LENGTH}
                          onChange={this.onSignatureTextUpdate.bind(this)}
                          ref={this.signatureTextAreaRef}
                          value={sigText}/>
                }
                <SignaturePanelCanvas onRef={(ref) => this.signatureRef = ref}
                                      styleObj={{ zIndex : 5, pointerEvents : 'auto' }}
                                      className={showDrawSignature ? "m-auto text-center d-table position-absolute" : "m-auto text-center d-table invisible position-absolute"}
                                      canvasHeight={AccountSignaturesPanel.SIG_IMG_HEIGHT} />
              </>
              }
      
            </div>
          </div>
          <div className="col-5 ">
            <div className="d-table mb-2">
              <label className="d-table-cell pr-2 pl-4">{t("Initials")}</label>
              {!preventEdit &&
              <select className="form-control d-table-cell"
                      value={initFont}
                      onChange={this.onInitFontChange.bind(this)}>
                {this.getFontOptionPicker().map((option) => {
                  return <option className={option.cls}
                                 key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
              }
            </div>
            <div style={styles.signatureBox}>
              {showInitialsImage &&
              <div style={styles.sigImgBox}
                   onClick={this.onInitImgClick.bind(this)}>
                {accountSignatures &&
                  <div style={styles.sigImgWrap}>
                    <Image style={styles.sigImg} src={accountSignatures.init_image} alt={t('Initials')} />
                  </div>
                }
              </div>
              }
              {!showInitialsImage &&
              <>
                {!showDrawInitials &&
                <textarea className={"m-auto text-center d-table position-absolute no-resize " + initFontCls}
                          style={styles.textarea}
                          maxLength={AccountSignaturesPanel.INIT_MAX_LENGTH}
                          onChange={this.onInitTextUpdate.bind(this)}
                          ref={this.initialsTextAreaRef}
                          value={initText}/>
                }
                <SignaturePanelCanvas onRef={(ref) => this.initialsRef = ref}
                                      styleObj={{ zIndex : 5, pointerEvents : 'auto' }}
                                      className={showDrawInitials ? "m-auto text-center d-table position-absolute" : "m-auto text-center d-table invisible position-absolute"}
                                      canvasHeight={AccountSignaturesPanel.SIG_IMG_HEIGHT} />
              </>
              }
            </div>
          </div>
        </div>
        {validationErr.length > 0 &&
          <div className="row">
            <div className="col mt-2">
              <ValidationErrors errors={validationErr} />
            </div>
          </div>
        }
      </>
    )
  }
  
}

AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT = 167;
AccountSignaturesPanel.SIG_MAX_LENGTH = 40;
AccountSignaturesPanel.INIT_MAX_LENGTH = 5;
AccountSignaturesPanel.SIG_IMG_HEIGHT = 48;

const styles = {
  signatureBox : {
    border : `1px solid ${colors.LIGHT_GREY}`,
    borderRadius : '5px',
    textAlign : 'center',
    minHeight : AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px',
    position : 'relative',
  },
  textarea : {
    border: 'none',
    fontSize : '32px',
    lineHeight : '124px',
    padding: '16px',
    zIndex: 6,
    width: "100%",
    height: "100%",
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    overflow:'hidden'
  },
  sigImgBox : {
    maxHeight: AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px',
    height: AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sigImgWrap : {
    lineHeight: AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px',
    height: '100%',
    //margin: 'auto',
    textAlign: 'center'
  },
  sigImg : {
    display: 'inline',
    verticalAlign : 'middle',
    height: 'auto',
    margin: 'auto',
  }
}

AccountSignaturesPanel.propTypes = {
  onRef : PropTypes.func.isRequired,
  preventEdit : PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    accountSignatures : state.shared.signatures,
    accountInfo : state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountSignaturesPanel));
