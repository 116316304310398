import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Workspace from "./components/pages/Workspace";
import Home from "./components/pages/Home";
import Confirm from "./components/pages/Confirm";
import Auth from "./components/pages/Auth";
import Account from "./components/pages/Account";
import SignArchive from "./components/pages/SignArchive";
import Handle from "./components/pages/Handle";
import InvoiceHistory from "./components/pages/InvoiceHistory";
import PublisherSubscribe from "./components/pages/PublisherSubscribe";

export default {
  getRoutes(includeMainApp) {
    return <Switch>
      <Route exact path="/" component={Auth} />
      <Route exact path="/confirm" component={Confirm} />
      <Route exact path="/@:handle" component={Handle} />
      <Route exact path="/publisher_subscribe" component={PublisherSubscribe} />
      {includeMainApp && <Route exact path="/home" component={Home} />}
      {includeMainApp && <Route exact path="/account" component={Account} />}
      {includeMainApp && <Route exact path="/sign_archive" component={SignArchive} />}
      {includeMainApp && <Route exact path="/invoice_history" component={InvoiceHistory} />}
      {includeMainApp && <Route exact path="/workspace/:forum_id" component={Workspace} />}
    </Switch>
  }
}
