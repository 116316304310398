import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import c from '../../util/const'
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import NotificationIcon from "../partials/components/NotificationIcon";
import {getMessageForError} from "../../util/errors";
import ExpandableRow from "../partials/components/ExpandableRow";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import {withVFTranslation} from "../../util/withVFTranslation";
import Scroll from "react-scroll";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {CSSTransition} from "react-transition-group";
import Promise from "bluebird";
import workspaceActions from "../../actions/workspace-actions";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Checkbox from "../partials/elements/Checkbox";
import sharedActions from "../../actions/shared-actions";
import Loading from "../partials/util/Loading";
import utils from "../../util/util";

const WINDOW_MODE = {
  GUEST : 'guest',
  TERMS : 'terms'
}

const GUEST_RESULT = {
  guest_uid : null,
  terms : '',
  original_terms : '',
  requireTerms : true,
  requireSMS : false,
  smsNumber : '',
}

class RequestSignatureWindow extends Component {

  MAX_SIGNERS = 9;

  CONTAINER_ID = 'request-signature-window-container'
  TERMS_SECTION_ID = 'terms-header';
  SMS_SECTION_ID = 'sms-header'

  DEFAULT_STATE = {
    selectedGuestUids: [],
    mode: WINDOW_MODE.GUEST,
    guestResults: [],
    guestTermEditIndex: 0,
    validationErr: [],
    guestResultLookup: [],
    useCancelLanguage : false,

    isUpdatingTermsTemplates : false,
    saveDefaultTermsEnabled : false,
    saveDefaultTermsChecked : false
  }

  constructor(props) {
    super(props);

    log.log('loading request sig window', props);

    //requestData is a SignatureRequest object
    if(props.requestData){
      let guestResults = _.get(props.requestData, 'signer_results', []);
      //Just FYI, we load this window up to work, but it still returns the same data structures
      //This means in the load case, we need to do the remapping of signature request data, this window does not do that.

      log.log('loading request sig window for review', guestResults);
      if(props.is_dm){
        this.state = _.extend(this.DEFAULT_STATE, {
          selectedGuestUids: _.map(guestResults, (r) => r.guest_uid),
          mode: WINDOW_MODE.TERMS,
          guestResults: guestResults,
          guestTermEditIndex: 0,
          validationErr: [],
          guestResultLookup: [],
          useCancelLanguage : props.useCancelLanguage
        })
      }
      else{
        this.state = _.extend(this.DEFAULT_STATE, {
          selectedGuestUids : _.map(guestResults, (r) => r.guest_uid),
          mode : WINDOW_MODE.GUEST,
          guestResults: guestResults,
          guestTermEditIndex : 0,
          validationErr : [],
          guestResultLookup: [],
          useCancelLanguage : props.useCancelLanguage
        })
      }
    }
    else if(props.is_dm){
      this.state = _.extend(this.DEFAULT_STATE, {
        selectedGuestUids : [props.guest_uid],
        mode : WINDOW_MODE.TERMS,
        guestResults: [this.getDefaultGuestResult(props.guest_uid)],
        guestTermEditIndex : 0,
        validationErr : [],
        guestResultLookup: [],
        useCancelLanguage : props.useCancelLanguage
      })
    }
    else{
      this.state = _.extend(this.DEFAULT_STATE, {
        selectedGuestUids : [],
        mode : WINDOW_MODE.GUEST,
        guestResults: [],
        guestTermEditIndex : -1,
        validationErr : [],
        guestResultLookup: [],
        useCancelLanguage : props.useCancelLanguage
      })
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  getVerifyleDefaultTerms(){
    let {
      t
    } = this.props;

    return t('I agree to do business electronically.');
  }

  getDefaultTerms(){
    let {
      defaultTermsTemplate,
      t
    } = this.props;

    let terms = _.get(defaultTermsTemplate, 'terms');
    if(terms){
      return terms;
    }
    else{
      return this.getVerifyleDefaultTerms();
    }
  }

  getDefaultGuestResult(guest_uid){
    let newResult = _.extend({}, GUEST_RESULT);
    newResult.guest_uid = guest_uid;
    newResult.terms = this.getDefaultTerms();
    newResult.original_terms = newResult.terms;
    return newResult;
  }

  doAreYouSureForCancel() {
    //WARNING - partially duplicated language in PdfPreview closeModal().

    let {
      useCancelLanguage
    } = this.state;
    let {t} = this.props;

    let title = "";
    let msg = "";
    if(!useCancelLanguage){
      title = t("Exit Request");
      msg = t("Are you sure you want to exit?");
    }
    else{
      title = t("Cancel Request");
      msg = t("Your signature request is incomplete. Your progress will not be saved if you exit now. Are you sure you want to cancel this request?");
    }

    this.props.showConfirm(
      title,
      msg,
      (res) => {
        if (res) {
          this.closeModal(null);
        }
      }, t("Yes"), t("No"))
  }

  backClick(){
    let { is_dm } = this.props;
    let { mode } = this.state;

    if(mode === WINDOW_MODE.GUEST){
      //then it's a cancel, just close out
      this.doAreYouSureForCancel();
    }
    else{
      if(is_dm){
        this.doAreYouSureForCancel();
        return;
      }
      else{
        //then go back within terms one user.
        //If you're on the first terms user, flip back to guest mode
        let nextIndex = this.state.guestTermEditIndex - 1;
        if(nextIndex < 0){
          this.setState({
            mode : WINDOW_MODE.GUEST,
            guestTermEditIndex : -1,
            validationErr : []
          })
        }
        else{
          this.setState({
            guestTermEditIndex : nextIndex,
            validationErr : []
          }, () => {
            this.setState({
              saveDefaultTermsEnabled : this.getDefaultTerms() !== this.getEditingGuestResult().terms,
              saveDefaultTermsChecked : false
            })
          })
        }
      }
    }
  }

  nextClick(){
    let { mode } = this.state;

    if(mode === WINDOW_MODE.GUEST){
      if(!this.doGuestValidation()){
        return;
      }
      this.setState({
        mode : WINDOW_MODE.TERMS,
        guestTermEditIndex : 0,
        validationErr : []
      })
    }
    else{
      this.goNextGuestTerms();
    }
  }

  getEditingGuestResult(){
    return this.state.guestResults[this.state.guestTermEditIndex];
  }

  goNextGuestTerms(){
    if(!this.doGuestTermsValidation(this.getEditingGuestResult().guest_uid)){
      return;
    }

    let {
      t
    } = this.props;
    this.saveDefaultTermsIfNeeded()
      .then(() => {
        let nextIndex = this.state.guestTermEditIndex + 1;
        if(nextIndex < this.state.selectedGuestUids.length){
          this.setState({
            guestTermEditIndex : nextIndex,
            validationErr : []
          }, () => {
            this.setState({
              saveDefaultTermsEnabled : this.getDefaultTerms() !== this.getEditingGuestResult().terms,
              saveDefaultTermsChecked : false
            })

            //scroll back to top
            this.scrollToElement(this.TERMS_SECTION_ID)
          })
        }
        else{
          this.doFinishAndClose();
        }
      })
      .catch((err) => {
        log.log('error while saving default terms', err);
        this.setState({validationErr : [getMessageForError(err, t)]})
      })
  }

  saveDefaultTermsIfNeeded(){
    let {
      saveDefaultTermsEnabled,
      saveDefaultTermsChecked
    } = this.state;

    let editingGuest = this.getEditingGuestResult();
    let requireTerms = _.get(editingGuest, 'requireTerms');
    let newTerms = _.get(editingGuest, 'terms');

    if(saveDefaultTermsEnabled && saveDefaultTermsChecked && requireTerms) {
      this.setState({isUpdatingTermsTemplates : true})
      let {
        defaultTermsId
      } = this.props;
      let promise = null;
      if(defaultTermsId){
        promise = sapi.SignTermsTemplate.update(defaultTermsId, newTerms)
      }
      else{
        promise = sapi.SignTermsTemplate.add(newTerms)
      }

      return promise
        .then((res) => {
          return this.props.sharedActions.refreshTermsTemplates()
        })
        .then(() => {
          //now we need to update terms for all subsequent users
          let {
            guestTermEditIndex,
            guestResults
          } = this.state;

          let update = _.concat([], guestResults);
          _.each(update, (gResult, i) => {
            if(i > guestTermEditIndex){
              if(gResult.terms === gResult.original_terms){
                gResult.terms = this.getDefaultTerms();
              }
            }
          })

          this.setState({
            guestResults : update,
            isUpdatingTermsTemplates : false,
            saveDefaultTermsEnabled : false,
            saveDefaultTermsChecked : false
          })
        })
    }
    else{
      //nothing to do, move along.
      return Promise.resolve(true);
    }
  }

  doFinishAndClose(){
    log.log('do finish');
    let { guestResults } = this.state;
    this.closeModal(guestResults);
  }

  doGuestValidation(){
    let err = [];
    let { t } = this.props;
    let {
      selectedGuestUids
    } = this.state;

    if(selectedGuestUids.length === 0){
      err.push(t("Please add at least one guest."))
    }
    else if(selectedGuestUids.length > this.MAX_SIGNERS){
      err.push(t("Sorry, only") + ` ${this.MAX_SIGNERS} ` + t("guests can sign at a time"))
    }

    this.setState({validationErr : err});
    return err.length === 0;
  }

  doGuestTermsValidation(guest_uid){
    let {
      guestResults,
    } = this.state;
    let { t } = this.props;

    let foundResult = _.find(guestResults, (res) => res.guest_uid === guest_uid);
    let {
      terms,
      requireTerms,
      requireSMS,
      smsNumber
    } = foundResult;

    let err = [];
    if(requireTerms && (!terms || terms.length === 0)){
      err.push(t("Please either enter terms, or do not require them."));
    }

    if(requireSMS){
      //There's decent rational for not being super strict with this phone number
      //validation.  Read the library's thoughts here: https://gitlab.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
      if(!isPossiblePhoneNumber(smsNumber)){
        err.push(t("Please enter a valid phone number."))
      }
    }

    this.setState({validationErr : err});
    return err.length === 0;
  }

  onGuestCheckChange(guest_uid, evt){
    //The only weirdness here is because I remove guest results from this list
    //when you uncheck them the data they saved might be lost.
    //In order to work around this, I save their old results when they uncheck people, and restore
    //from that old version if they re-check the user.

    let guestUpdate = _.concat(this.state.selectedGuestUids, []);
    let resultUpdate = _.concat(this.state.guestResults, []);

    if(_.find(guestUpdate, (uid) => uid === guest_uid)){
      _.remove(guestUpdate, (uid) => uid === guest_uid);
      let oldGuestResult = _.remove(resultUpdate, (r) => r.guest_uid === guest_uid);
      let guestResultLookupUpdate = _.concat(this.state.guestResultLookup, oldGuestResult);
      this.setState({ guestResultLookup : guestResultLookupUpdate})
    }
    else{
      guestUpdate.push(guest_uid);
      let foundGuestResult = _.find(this.state.guestResultLookup, (gResult) => gResult.guest_uid === guest_uid);
      if(foundGuestResult){
        resultUpdate.push(foundGuestResult);
      }
      else{
        resultUpdate.push(this.getDefaultGuestResult(guest_uid));
      }
    }
    this.setState({
      selectedGuestUids : guestUpdate,
      guestResults : resultUpdate
    })
  }

  setRequireTerms(guest_uid, requireTerms){
    let update = _.concat([], this.state.guestResults);
    let foundResult = _.find(update, (r) => r.guest_uid === guest_uid);
    foundResult.requireTerms = requireTerms;
    this.setState({guestResults : update})
    this.scrollToElement(this.TERMS_SECTION_ID)
  }

  setTerms(guest_uid, terms){
    let update = _.concat([], this.state.guestResults);
    let foundResult = _.find(update, (r) => r.guest_uid === guest_uid);
    foundResult.terms = terms;
    this.setState({saveDefaultTermsEnabled : this.getDefaultTerms() !== terms})
    this.setState({guestResults : update})
  }

  setSmsNumber(guest_uid, smsNumber){
    let update = _.concat([], this.state.guestResults);
    let foundResult = _.find(update, (r) => r.guest_uid === guest_uid);
    foundResult.smsNumber = smsNumber;
    this.setState({guestResults : update})
  }

  setRequireSMS(guest_uid, requireSMS){
    let update = _.concat([], this.state.guestResults);
    let foundResult = _.find(update, (r) => r.guest_uid === guest_uid);
    foundResult.requireSMS = requireSMS;
    this.setState({guestResults : update})
    this.scrollToElement(this.SMS_SECTION_ID)
  }

  renderGuestMode(){
    let {
      modalProps,
      directMessages,
      t
    } = this.props;
    let {
      filename,
      participants,
    } = modalProps;
    let {
      selectedGuestUids,
      guestResults,
      validationErr
    } = this.state;

    return (
      <div>
        <div>
          <h6>{t("Toggle the switches below to select the signers and set the order of signing for the Document:")}</h6>
        </div>
        <div className="text-center">
          <h4 style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '1.4rem'
          }}>
              <span className="primary-color">
                <i className={`icon mr-3 ion-document-text`}/>
                {filename}
              </span>
          </h4>
        </div>
        {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
        <div>
          <h4>
            {t("Thread Participants")}
          </h4>
        </div>

        <div className="p-2">
          {_.map(participants, (guest) => {
            let signingOrder = _.findIndex(guestResults, (r) => r.guest_uid === guest.guest_uid);
            let userChecked = selectedGuestUids.indexOf(guest.guest_uid) >= 0;
            let userHasInvalidEmail = utils.hasEmailValidationInvalidFlag(_.find(directMessages, (dm) => dm.guest_uid === guest.guest_uid));
            return (
              <div key={guest.guest_uid} className="d-flex mb-2">
                <div style={styles.userColumn} className="mr-3">
                  <UserBadge guest={guest}/>
                </div>
                <div className="flex-grow-1" style={styles.userColumn}>
                  <h6 className="mb-0"
                      style={userChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                    {guest.first_name} {guest.last_name}
                  </h6>
                  <p className="mb-0"
                     style={userChecked ? styles.selectedUserEmail : styles.unselectedUser}>
                    {guest.email_address}
                  </p>
                  {userHasInvalidEmail &&
                    <p className="mb-0 text-warning small">
                      <span>
                          <i className="icon ion-android-warning pr-1" />
                          {t("Review Email")}
                      </span>
                    </p>
                  }
                </div>
                <div style={styles.userColumn} className="ml-3 mr-2">
                  <span className="custom-control custom-switch custom-switch-lg">
                    <input type="checkbox"
                           className="custom-control-input"
                           onChange={this.onGuestCheckChange.bind(this, guest.guest_uid)}
                           checked={userChecked}
                           id={"request-signature-recipient-" + guest.guest_uid}/>
                    <label className="custom-control-label" htmlFor={"request-signature-recipient-" + guest.guest_uid} />
                    <CSSTransition in={signingOrder >= 0}
                                   unmountOnExit
                                   timeout={200}
                                   classNames={'fast-fade'}>
                      <div className="signing-order-badge">
                        {(signingOrder >= 0 ? signingOrder + 1 : '')}
                      </div>
                    </CSSTransition>
                  </span>

                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  scrollToElement(elName){
    setTimeout(() => {
      Scroll.scroller.scrollTo(elName, {
        containerId: this.CONTAINER_ID,
        duration: 300,
        smooth: true,
      })
    }, 250)
  }

  onSaveAsDefaultCheckChange(guest_uid, e){
    this.setState({saveDefaultTermsChecked : e.target.checked});

    if(e.target.checked){
      let guestResult = this.getEditingGuestResult();
      let guestTerms = _.get(guestResult, 'terms', '');
      if(guestTerms.length === 0){
        //This closely resembles this.setTerms() however it doesn't contain
        //the logic around enabling the save as default checkbox if the terms are different from the original.
        let update = _.concat([], this.state.guestResults);
        let foundResult = _.find(update, (r) => r.guest_uid === guest_uid);
        foundResult.terms = this.getVerifyleDefaultTerms();
        this.setState({guestResults : update})
      }
    }
  }

  renderUserTermsHeader(){
    let {
      participants,
      directMessages,
      t
    } = this.props;
    let {
      guestResults,
      guestTermEditIndex
    } = this.state;

    return (
      <Carousel className="user-terms-carousel"
                onChange={_.noop}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                selectedItem={guestTermEditIndex}
      >
        {_.map(guestResults, (r) => {
          let foundGuest = _.find(participants, (g) => g.guest_uid === r.guest_uid);
          let userHasInvalidEmail = utils.hasEmailValidationInvalidFlag(_.find(directMessages, (dm) => dm.guest_uid === r.guest_uid));
          return (
            <div key={foundGuest.guest_uid} className="d-flex mb-2">
              <div style={styles.userColumn} className="mr-3">
                <UserBadge guest={foundGuest}/>
              </div>
              <div className="flex-grow-1 text-left" style={styles.userColumn}>
                <h6 className="mb-0"
                    style={styles.selectedUserHeader}>
                  {foundGuest.first_name} {foundGuest.last_name}
                </h6>
                <p className="mb-0"
                   style={styles.selectedUserEmail}>
                  {foundGuest.email_address}
                </p>
                {userHasInvalidEmail &&
                  <p className="mb-0 text-warning small">
                      <span>
                          <i className="icon ion-android-warning pr-1" />
                        {t("Review Email")}
                      </span>
                  </p>
                }
              </div>
            </div>
          )
        })}
      </Carousel>
    )
  }

  renderTermsMode(){
    let {
      validationErr,
      saveDefaultTermsChecked,
      saveDefaultTermsEnabled
    } = this.state;
    let {
      t,
      participants,
      directMessages
    } = this.props;
    let guestResult = this.getEditingGuestResult();
    if(!guestResult){
      return null;
    }

    // log.log('render request sig window terms mode', guestResult, participants);
    let {
      guest_uid,
      requireTerms,
      requireSMS,
      smsNumber,
      terms,
    } = guestResult;

    let userHasInvalidEmail = utils.hasEmailValidationInvalidFlag(_.find(directMessages, (dm) => dm.guest_uid === guest_uid));

    return (
      <div id={this.CONTAINER_ID}>
        <div className="d-flex mb-2">
          {this.renderUserTermsHeader()}
        </div>
        {userHasInvalidEmail &&
          <div className="alert alert-info mb-0" role="alert">
            <h5 className="alert-heading mb-1">
              <i className="icon ion-information-circled pr-2" />
              {t("Informational")}
            </h5>
            <p style={{fontSize: '14px'}}>
              {t("The email address you entered may contain a typo, or may be an invalid email address. Please double-check what you’ve entered for errors.")}
            </p>
          </div>
        }

        <p>{t("Does this signer need to agree to terms?")}</p>
        <div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="termsRadio"
                   onChange={this.setRequireTerms.bind(this, guest_uid, true)}
                   checked={requireTerms}
                   value={requireTerms}/>
            <label className="form-check-label">
              <div className="bol">{t("Yes, require the signer to agree to the terms below.")}</div>
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="termsRadio"
                   onChange={this.setRequireTerms.bind(this, guest_uid, false)}
                   checked={!requireTerms}
                   value={!requireTerms}/>
            <label className="form-check-label">
              <div className="bol">{t("No, do not include a statement of terms.")}</div>
            </label>
          </div>
        </div>
        <ExpandableRow isActive={requireTerms}>
          <div id={this.TERMS_SECTION_ID} className="form-group mt-3">
            <label>{t("Statement of Terms")}</label>
            <textarea className="form-control no-resize"
                      rows={3}
                      disabled={!requireTerms}
                      value={terms}
                      maxLength={c.limits.maxTermsChars}
                      onChange={(evt) => this.setTerms(guest_uid, evt.target.value)}
                      placeholder={this.getVerifyleDefaultTerms()} />
          </div>
          <div>
            <Checkbox isChecked={saveDefaultTermsChecked}
                      disabled={!saveDefaultTermsEnabled}
                      onChange={this.onSaveAsDefaultCheckChange.bind(this, guest_uid)}
                      label={t('Save terms as default')}
                      rootCls={'text-left'}
            />
          </div>
        </ExpandableRow>

        <p className="mt-2">{t("Do you want to add SMS verification?")}</p>
        <div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="smsRadio"
                   onChange={this.setRequireSMS.bind(this, guest_uid, false)}
                   checked={!requireSMS}
                   value={!requireSMS}/>
            <label className="form-check-label">
              <div className="bol">{t("No, don't send a Signature Code.")}</div>
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="smsRadio"
                   onChange={this.setRequireSMS.bind(this, guest_uid, true)}
                   checked={requireSMS}
                   value={requireSMS}/>
            <label className="form-check-label">
              <div className="bol">{t("Yes, require the signer to input a one-time Signature Code.")}</div>
            </label>
          </div>
        </div>
        <ExpandableRow isActive={requireSMS}>
          <div id={this.SMS_SECTION_ID} className="form-group mt-3">
            <label>{t("Signer's Verified Phone Number")}</label>
            <div className="d-flex">

              {/*One important note here, I chose to embed the flags rather than rely on unicode because of varying OS support*/}
              {/*Passing flags in increases the package size a little, but not that much*/}
              {/*I think this is probably alright*/}
              <PhoneInput
                flags={flags}
                international
                className="form-control mr-3 w-50"
                defaultCountry="US"
                placeholder={t("Enter phone number")}
                value={smsNumber}
                onChange={(val) => this.setSmsNumber(guest_uid, val)}/>
              <div className="text-muted small">
                <p className="mb-0">{t("Be sure to include country code.")}</p>
                <p className="mb-0">{t("e.g. + 1 408 555 1234")}</p>
              </div>
            </div>
          </div>
        </ExpandableRow>
        {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
      </div>
    )
  }

  getBackButtonText(){
    let { t, is_dm } = this.props;
    let { mode } = this.state;
    if(mode === WINDOW_MODE.GUEST){
      return t("Cancel");
    }
    else{
      if(is_dm){
        //Nothing to do
        return t("Cancel");
      }

      return t("Back");
    }
  }

  getNextButtonText(){
    let { t, is_dm } = this.props;
    let { mode } = this.state;
    if(mode === WINDOW_MODE.GUEST){
      return t("Next");
    }
    else{
      if(is_dm){
        //Nothing to do
        return t("Continue");
      }
      else{
        let nextIndex = this.state.guestTermEditIndex + 1;
        if(nextIndex < this.state.selectedGuestUids.length){
          return t("Next");
        }
        else{
          return t("Continue");
        }
      }
    }
  }

  onEscapeKey(){
    this.doAreYouSureForCancel();
  }

  render() {
    let { t } = this.props;
    let { selectedGuestUids, mode, validationErr, isUpdatingTermsTemplates } = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Request Signature")}</h5>
          <button type="button" className="close" onClick={this.doAreYouSureForCancel.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>

        <div className="modal-body">
          {mode === WINDOW_MODE.GUEST &&
          this.renderGuestMode()
          }
          {mode === WINDOW_MODE.TERMS &&
          this.renderTermsMode()
          }
        </div>

        <div className="modal-footer">
          <Button className={'btn btn-secondary'}
                  disabled={isUpdatingTermsTemplates}
                  onClick={this.backClick.bind(this)}>
            {this.getBackButtonText()}
          </Button>
          <Button className={'btn btn-primary'}
                  disabled={isUpdatingTermsTemplates}
                  onClick={this.nextClick.bind(this)}>
            {isUpdatingTermsTemplates &&
              <Loading inline={true}
                       color="light"
                       size={'sm'}/>
            }
            {!isUpdatingTermsTemplates && this.getNextButtonText()}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px',
    color : colors.SECONDARY_TEXT
  },
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    directMessages : state.shared.directMessages,
    defaultTermsTemplate : state.shared.defaultTermsTemplate,
    defaultTermsId : state.shared.defaultTermsId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshGuests: (forum_id, host_uid) => dispatch(workspaceActions.refreshGuests(forum_id, host_uid)),
    refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(workspaceActions.refreshThreads(forum_id, host_uid, doRefreshGuests)),
    sharedActions : { ...sharedActions.mapToDispatch(dispatch) },
    ...modalActions.mapToDispatch(dispatch)
  };
};

RequestSignatureWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequestSignatureWindow));
