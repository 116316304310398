import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import c from '../../../util/const'
import TextareaAutosize from 'react-textarea-autosize';

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import UserBadge from "../badges/UserBadge";
import utils from "../../../util/util";
import modalActions from "../../../actions/modal-actions";
import UpgradeDialogNew from "../../modals/UpgradeDialogNew";
import {withRouter} from "react-router-dom";
import {getMessageForError} from "../../../util/errors";
import MaxChatMessageWarning from "./MaxChatMessageWarning";
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import ChatEmojiMenu from "./ChatEmojiMenu";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import sentryHelper from "../../../helpers/sentry-helper";
import filters from "../../../helpers/filters";
import pendingMsgCache from "../../../helpers/pending-msg-cache";
import TruncateMarkup from "react-truncate-markup";

class ChatPanelFooter extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      pendingDocs : [],
      msg : '',
      isSending : false,
      textAreaRef : null
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  sortPendingDocs(docs){
    return _.sortBy(docs, (d) => d.isInvoice);
  }

  initPendingMessage(msg, docs){
    this.setState({
      pendingDocs : this.sortPendingDocs(_.concat([], docs || [])),
      msg : msg || ''
    })
  }

  updatePendingDocs(docs){
    this.setState({
      pendingDocs : this.sortPendingDocs(_.concat([], this.state.pendingDocs, docs || [])),
    })
  }

  getPendingData(){
    return {
      msg : this.state.msg,
      docs : this.state.pendingDocs
    }
  }

  clear() {
    this.setState({
      pendingDocs : [],
      msg : '',
      isSending : false
    })
  }

  setNotSending() {
    this.setState({
      isSending : false
    })
  }

  sendMessage(){
    if(this.state.isSending){
      return;
    }
    else{
      this.setState({isSending : true}, () => {
        this.props.sendMessage(this.state.msg, this.state.pendingDocs);
      });
    }
  }

  onMsgChange(evt){
    this.setState({msg : evt.target.value})
  }

  removePendingDoc(doc){
    let {pendingDocs} = this.state;
    _.remove(pendingDocs, (f) => {
      return f.uniqueId === doc.uniqueId
    })
    let update = this.sortPendingDocs(_.concat([], pendingDocs));
    this.setState({
      pendingDocs: update
    })

    let { workspace, thread, dm } = this.props;
    if(!thread){
      pendingMsgCache.removeDocFromDMCache(dm.guest_uid, doc.uniqueId);
    }
    else{
      pendingMsgCache.removeDocFromThreadCache(thread.chat_id, doc.uniqueId);
    }
  }

  addCopyDocs(files){
    _.each(files, (f) => {
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-')
    })

    let {pendingDocs} = this.state;
    let update = this.sortPendingDocs(_.concat(pendingDocs, files));
    this.setState({
      pendingDocs: update
    })
  }

  docFileDrop(files) {
    let { workspace, thread, dm, t } = this.props;

    _.each(files, (f) => {
      if(f.doCheckRequestSignature){
        f.signature_requested = true;
        //Maybe over-kill, but once you find this flag, set it checked and then delete it
        //I was worried that this object might get stashed and restored at some point
        //and then we would auto-check again.
        delete f.doCheckRequestSignature;
      }
      else{
        f.signature_requested = false;
      }
    })
    //Need to make this preupload call with the entire pending set, since that will get sent
    //as the upload
    let fileSet = this.sortPendingDocs(_.concat([], this.state.pendingDocs, files));

    //Do not include invoices in this fileset for preupload stuff.
    _.remove(fileSet, (f) => f.isInvoice);

    let totalSize = 0;
    _.each(fileSet, (f) => {
      totalSize += f.sizeBytes;
    })

    let preuploadPromise = Promise.resolve(true);
    if(fileSet.length > 0) {
      if (!thread) {
        preuploadPromise = sapi.DM.preupload(dm.guest_uid, fileSet.length, totalSize);
      }
      else {
        let chat_id = _.get(thread, 'chat_id');
        preuploadPromise = sapi.Threads.docPreupload(workspace.forum_id, workspace.host_uid, chat_id, fileSet.length, totalSize);
      }
    }

    preuploadPromise
      .then((res) => {
        log.log('preupload res', res);

        let {pendingDocs} = this.state;
        let update = this.sortPendingDocs(_.concat(pendingDocs, files));
        this.setState({
          pendingDocs: update
        })
      })
      .catch((err) => {
        log.log('preupload err', err);

        if (sapi.isConnectionError(err)) {
          this.props.showAlert(t("Unable to Connect"), getMessageForError(err, t));
          return;
        }
        else {
          if(this.shouldWeIgnoreThisPreuploadError(err)){
            //see what these conditions are I suppose
            sentryHelper.captureMessage('ignorable-preupload-error', err);

            //Then this is an error that we don't expect here.
            //allow users to try uploading anyway, maybe it will work?
            let {pendingDocs} = this.state;
            let update = this.sortPendingDocs(_.concat(pendingDocs, files));
            this.setState({
              pendingDocs: update
            })
          }
          let errName = _.get(err, 'name', null);
          if(dm){
            if(errName === 'APP_FILE_TOOLARGE'){
              this.props.showUpgradeDialogOrCustomError(
                UpgradeDialogNew.UPGRADE_TYPES.STORAGE,
                t("You've reached your limit on Storage. You can delete documents to make space or contact customer support for additional options."),
                t,
                (res) => {
                log.log('showUpgradeDialogOrError res', res);
              })
            }
            else{
              this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.DOCS, err, t, (res) => {
                log.log('showUpgradeDialogOrError res', res);
              })
            }
          }
          else if(workspace && workspace.host_uid){
            //Then you're a guest.  You should see an error, not an upgrade prompt.
            let errName = _.get(err, 'name', null);
            //If these two errors come back for a guest, it needs special handling.
            //This is the same message we show in the upload manager.
            if(errName === 'APP_FORUM_MAX_COL' || errName === 'APP_FILE_TOOLARGE'){
              this.props.showAlert(t('Error Uploading'), t("Your host workspace doesn't have the resources to accommodate this file.  Please contact your host about making more space in their account."))
            }
            else{
              this.props.showAlert(t('Limit Reached'), getMessageForError(err, t));
            }
          }
          else{
            if(errName === 'APP_FILE_TOOLARGE'){
              this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.STORAGE, err, t, (res) => {
                log.log('showUpgradeDialogOrError res', res);
              })
            }
            else{
              this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.DOCS, err, t, (res) => {
                log.log('showUpgradeDialogOrError res', res);
              })
            }
          }
        }
      })
  }

  shouldWeIgnoreThisPreuploadError(err){
    let ignoreThisError = false;
    let errName = _.get(err, 'name', null);
    if(errName === 'APP_REQ_INVALID' || errName === 'APP_UNKNOWN_ERROR' || errName === 'APP_SERVER_ERROR'){
      ignoreThisError = true;
    }
    return ignoreThisError;
  }

  infoBtnClick(){
    let { t } = this.props;
    if(this.props.thread) {
      this.props.showAlert(
        t("Signing Requests"),
        t("If you request a signature on a document, you will get to select the signers, and how and where the document should be signed. After submitting your document, click \"Finish Request\" and follow the instructions. The signer will not be notified until you finish the signature request. This is not available for Verifyle Basic users."))
    }
    else{
      this.props.showAlert(
        t("Signing Requests"),
        t("If you request a signature on a document, you will get to indicate how and where the document should be signed. After submitting your document, click \"Finish Request\" and follow the instructions. The signer will not be notified until you finish the signature request. This is not available for Verifyle Basic users."))
    }
  }

  updateSigningChecked(doc, checked){
    let { pendingDocs } = this.state;
    let update = this.sortPendingDocs(_.concat([], pendingDocs));

    _.each(update, (f) => {
      if(f.uniqueId === doc.uniqueId){
        f.signature_requested = checked;
      }
    })
    this.setState({pendingDocs : update})
  }

  onRequestSigningInputChange(doc, evt){

    let isChecked = false;
    if(doc.signature_requested){
      isChecked = false;
    }
    else{
      isChecked = true;
    }

    if(isChecked){
      let {
        accountInfo,
        accountClassInfo,
        t
      } = this.props;

      log.log('sign request check change', accountInfo, accountClassInfo);
      let proceedWithCheckChange = false;
      let sign_req_max = _.get(accountClassInfo, 'class_info.sign_req_max', null);
      // -if class/info:[user class_id] has null sign_req_max
      // the user has unlimited signing requests; do nothing
      if(!sign_req_max){
        //no max on their account.  Allow signing without message.
        proceedWithCheckChange = true;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count > 0:
      // the user has free request credits available; show free trial info message
      if(_.isNumber(sign_req_max) && accountInfo.sign_req_count > 0){
        //show a trial message
        // allow check change
        //this.props.showAlert(t("Trial Signing"), t("You have ") + accountInfo.sign_req_count + t(" free signature ") + (accountInfo.sign_req_count === 1 ? t("request") : t("requests")) + t(" remaining."));
        proceedWithCheckChange = true;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count = 0:
      // the user has run out of free trial credits; show upgrade dialog
      if(_.isNumber(sign_req_max) && accountInfo.sign_req_count <= 0){
        // DO NOT allow check change
        // show upgrade dialog
        this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.MAX_V2_SIGNING_LIMIT, null, t, (res) => {
          log.log('showUpgradeDialogOrError res', res);
        })
      }

      if(proceedWithCheckChange){
        let isDocAttach = _.get(doc, 'is_doc_copy', false);
        let docSize = _.get(doc, 'sizeBytes', 0);
        if(!isDocAttach && _.has(doc, 'sizeBytes') && docSize === 0){
          proceedWithCheckChange = false;
          this.props.showAlert(t("File is Empty"), t("The size of this file is 0 bytes, and it cannot be signed.  If you would like to request a signature, please upload a valid PDF."));
        }
      }

      if(proceedWithCheckChange){
        this.updateSigningChecked(doc, isChecked);
      }
      else{
        this.updateSigningChecked(doc, false);
      }
    }
    else{
      this.updateSigningChecked(doc, isChecked);
    }
  }

  renderPendingInvoice(invoice){
    let { t } = this.props;
    return (
      <div key={invoice.uniqueId} className="d-flex" style={styles.docWrap}>
        <span style={styles.previewIconWrap}>
          {/*<i style={{fontSize: '28px'}} className="icon ion-document-text dark-color" />*/}
        </span>
        <div className="flex-grow-1 pending-file-upload">
          <div style={styles.docTopRow} className="mb-0">
            <a className="no-pointer primary-color font-weight-bold">
              <TruncateMarkup lines={2} ellipsis={t('...')}>
                <span>{invoice.description}</span>
              </TruncateMarkup>
            </a>
          </div>
          <div style={styles.docTopRow} className="mb-0 auto-ellipsis no-pointer">
            <span className="font-weight-bold d-inline dark-color">{filters.formatCurrency(this.props.i18n.language, invoice.currency, invoice.amount)}</span>
            <span className="small d-inline ml-2 dark-color">{invoice.currency}</span>
          </div>
        </div>
        <button style={styles.docDeleteBtn}
                onClick={this.removePendingDoc.bind(this, invoice)}
                className="btn btn-lg dark-bg light-color">
          <i className="icon ion-close" />
        </button>
      </div>
    )
  }

  renderPendingDocUpload(doc){
    if(doc.isInvoice){
      return this.renderPendingInvoice(doc);
    }

    let { signingIsAllowed, thread, t } = this.props;
    let isPDFable = utils.isPDFPreview(doc.name);
    let isTooBigToConvert = isPDFable && !utils.fileExtensionIsPDF(doc.name) && doc.sizeBytes > c.limits.maxFileSizeSigningBytes;

    return (
      <div key={doc.uniqueId} className="d-flex" style={styles.docWrap}>
        <span style={styles.previewImgWrap}>
          {doc.preview && <img style={styles.previewImg} src={doc.preview} /> }
          {!doc.preview && <DocExtensionPlaceholder filename={doc.name} /> }
        </span>
        <div className="flex-grow-1 pending-file-upload">
          <p style={styles.docTopRow} className="mb-0 auto-ellipsis">
            <span style={styles.docTitle}>{doc.name}</span>
            {!!(doc.size === 0) &&
            <i className="icon ion-android-warning align-baseline pr-1 text-warning"
               style={{fontSize: '18px'}}/>
            }
            <span className={`${doc.size === 0 ? 'text-warning' : ''}`} style={styles.docSize}>{doc.sizeString}</span>
          </p>
          {((thread && signingIsAllowed) || !thread) && isPDFable && !isTooBigToConvert &&
          <div style={styles.docBottomRow} className="mb-0">
            <span className="form-check d-inline-block" style={{lineHeight: '18px'}}>
              <div onClick={this.onRequestSigningInputChange.bind(this, doc)} className="d-inline-block has-pointer pr-2">
                <i style={{fontSize: '18px'}} className={`icon ${doc.signature_requested ? 'ion-android-checkbox green-color' : 'ion-android-checkbox-outline-blank secondary-text-color'}`} />
              </div>
              <label className="form-check-label green-color">{t("Request Signing")}</label>
            </span>
            <span>
              <button className="btn btn-sm btn-link light-grey-color"
                      onClick={this.infoBtnClick.bind(this)}>
                <i className="icon ion-information-circled"/>
              </button>
            </span>
          </div>
          }
        </div>
        <button style={styles.docDeleteBtn}
                onClick={this.removePendingDoc.bind(this, doc)}
                className="btn btn-lg dark-bg light-color">
          <i className="icon ion-close" />
        </button>
      </div>
    )
  }

  onEmojiMenuOpen(){
    let emojiPosition = _.get(this.state, 'textAreaRef.selectionStart', null);

    //Weird workaround!  Emoji characters can take up more than 1 character internally.
    //selectionStart accounts for this, giving us the proper index according to the length of the characters
    //in the textarea.  The problem is this isn't necessarily the visible index for the user
    //because the cursor counts emojis as one character.  This counts the pre-selection
    //characters, and adjusts the count to reflect the character count according to the cursor.
    let ar = _.split(this.state.msg, '');
    _.each(ar, (char, index) => {
      if(index < emojiPosition){
        if(char.length > 1){
          emojiPosition -= (char.length - 1);
        }
      }
    })
    this.setState({
      emojiPosition
    }, () => {
      this.state.textAreaRef.blur()
    })
  }

  onEmojiSelect(evt){
    let emojiPosition = this.state.emojiPosition;
    if(emojiPosition === null) {
      emojiPosition = this.state.msg.length;
    }

    let ar = _.split(this.state.msg, '');
    ar.splice(emojiPosition, 0, evt.native);

    this.setState({
      msg : ar.join(''),
      emojiPosition : emojiPosition + 1
    }, () => {
      let txAreaRef = _.get(this.state, 'textAreaRef');
      if(txAreaRef) {
        this.state.textAreaRef.blur()
      }
    })
  }

  render() {
    let {
      msg,
      pendingDocs,
      isSending
    } = this.state;

    let {
      thread,
      t
    } = this.props;

    let isDM = !thread;

    return (
      <div className={`light-color py-2 ${isDM ? 'dark-bg' : 'primary-bg'}`} style={styles.wrapper}>

        <div className="d-flex" style={styles.flexWrapper}>
          <UploadHelper allowMultiple={true}
                        clickOnly={true}
                        onDrop={this.docFileDrop.bind(this)}>
            <Button className={`btn ${isDM ? 'btn-dark' : 'btn-primary'}`} type="button" style={{height : '100%', width : `${UserBadge.MEDIUM_SIZE_WIDTH+ 10}px`}}>
              <i style={{fontSize: '30px'}} className={'ion-paperclip light-color'}/>
            </Button>
          </UploadHelper>
          <div style={styles.centerBlock} className="flex-grow-1 chat-footer-wrap light-bg">

            <div className="d-flex flex-column position-relative">
              <ChatEmojiMenu onMenuOpen={() => this.onEmojiMenuOpen()}
                             onEmojiSelect={this.onEmojiSelect.bind(this)}
                             isDM={isDM} />
              <MaxChatMessageWarning textLength={msg.length} isDM={isDM} />
              <TextareaAutosize className="form-control chat-footer-textarea no-focus no-resize"
                                ref={(el) => this.setState({textAreaRef : el})}
                                style={styles.textarea}
                                onChange={this.onMsgChange.bind(this)}
                                value={msg}
                                placeholder={t("Type a message...")}
                                maxRows={10}
                                minRows={2}/>

              {pendingDocs.length > 0 &&
              <div style={styles.docSection}>
                {_.map(pendingDocs, (doc) => {
                  return this.renderPendingDocUpload(doc);
                })}
              </div>
              }
            </div>

          </div>
          <Button className={`btn ${isDM ? 'btn-dark' : 'btn-primary'}`}
                  style={{
                    width: `${UserBadge.MEDIUM_SIZE_WIDTH + 10}px`,
                    fontSize: '13.5px',
                    paddingLeft: '3px',
                    paddingRight: '3px'
                  }}
                  disabled={isSending || msg.length === 0 && pendingDocs.length === 0 || msg.length > c.limits.maxThreadMessage}
                  onClick={this.sendMessage.bind(this)}
                  type="button">
            {t("Send")}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  wrapper : {

  },
  flexWrapper : {
    border : '1px solid ' + colors.LIGHT,
    borderRadius : '.25rem'
  },
  centerBlock : {

  },
  textarea : {
    zIndex : 1,
    fontSize : '16px',
    border : 'none',
  },
  attachBtn : {
    borderTopRightRadius : '0px',
    borderBottomRightRadius : '0px'
  },
  sendBtn : {
    borderTopLeftRadius : '0px',
    borderBottomLeftRadius : '0px'
  },
  docSection : {
    backgroundColor : colors.LIGHT,
    border : '1px solid ' + colors.DARK,
    borderRadius : '5px',
    margin : '10px',
    padding: '10px',
    maxHeight: '150px',
    overflowY : 'auto'
  },
  docWrap : {
    marginBottom : '5px'
  },
  docTopRow : {
    marginRight : '10px',
    marginLeft : '10px',
    lineHeight : '16px',
    maxWidth : '100%'
  },
  docBottomRow : {

  },
  docTitle : {
    fontSize : '14px',
    fontWeight : 'bold',
    marginRight : '10px',
    color : colors.DARK,
    wordWrap: 'break-word',
    width: '100%',
    whiteSpace: 'normal'
  },
  docSize : {
    fontSize: '12px',
    color : colors.LIGHT_GREY
  },
  previewImgWrap : {
    width : '50px',
    height : '50px',
    textAlign : 'center'
  },
  previewIconWrap : {
    width : '50px',
    textAlign : 'center'
  },
  previewImg : {
    maxWidth : '50px',
    maxHeight : '50px',
    border : '1px solid ' + colors.DARK,
    marginRight : '10px'
  },
  previewImgPlaceholder : {
    maxWidth : '50px',
    maxHeight : '50px',
    marginRight : '10px',
    lineHeight : '40px',
    fontSize : '40px',
    color : colors.DARK
  },
  docDeleteBtn : {
    borderRadius : '50%',
    width : '50px',
    height : '50px'
  }
}

ChatPanelFooter.propTypes = {
  dm: PropTypes.object,
  thread: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  signingIsAllowed : PropTypes.bool,
  onRef : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    accountInfo : state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,
    workspace : state.workspace.workspace
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPanelFooter)));
