import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import log from "../../util/log";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import {getErrorMessage, getMessageForError} from "../../util/errors";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import _ from 'lodash'
import he from "he";
import sentryHelper from "../../helpers/sentry-helper";
import {withVFTranslation} from "../../util/withVFTranslation";
import c from "../../util/const";
import {SEND_CODE_TYPE} from "./SMSVerificationDialog";

class RequestSignatureCodeDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      smsDisplay : '',
    }
  }

  componentDidMount() {
    const {modalProps} = this.props;
    if(!modalProps.callback){
      throw Error('Showing modal without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {
      smsNumber,
      signatureRequest
    } = this.props;

    log.log('RequestSignatureCodeDialog load', smsNumber, signatureRequest);

    let formattedNumber = formatPhoneNumberIntl(smsNumber);
    this.setState({
      smsDisplay : formattedNumber
    })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  onSendSignatureCodeClick(){
    this.closeModal(SEND_CODE_TYPE.SMS);
  }

  onPhoneCallButtonCLick(){
    this.closeModal('other');
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t } = this.props;
    let {
      smsDisplay,
    } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Signature Code")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>{t("The person requesting your signature requires that you input a one-time passcode. Click below to receive a signature code from Verifyle via SMS at the phone number, ")}</p>

          <div className="m-3 text-center">
            <p className="font-italic secondary-text-color">{smsDisplay}</p>
          </div>
          <div className="mx-auto mb-3" style={{maxWidth: '250px'}}>
            <Button className="btn btn-primary btn-block"
                    onClick={() => this.onSendSignatureCodeClick()}>
              {t("Send Signature Code")}
            </Button>
          </div>
          <div className="text-center mb-3">
            <Button className="btn btn-link"
                    style={{fontSize: '12px'}}
                    onClick={() => this.onPhoneCallButtonCLick()}>
              {t("Can't or don't want to receive SMS at the above Number?")}
            </Button>
          </div>

          <p className="mb-3">
            {t("Message and data rates may apply.")}
          </p>
          <p className="mb-3">
            {t("You will only receive one message per signature request authorization.")}
          </p>
          <p className="mb-3">
            {t("Reply HELP for help. Reply STOP to opt out.")}
          </p>
          <p className="mb-3">
            {t("SMS Terms of Service: ")}<a className="dark-color" target={'_blank'} href={c.links.smsTerms}>{"verifyle.com/smsterms.html"}</a>
          </p>
          <p className="mb-3">
            {t("Privacy Policy: ")}<a className="dark-color" target={'_blank'} href={c.links.privacy}>{"verifyle.com/privacypolicy.html"}</a>
          </p>

        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

RequestSignatureCodeDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequestSignatureCodeDialog));
