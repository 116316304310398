import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import ValidationErrors from "../partials/components/ValidationErrors";
import {withVFTranslation} from "../../util/withVFTranslation";

class SubmitSignedPdfDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filename: props.modalProps.suggestedFilename,
      mesg: '',
      validationErr: []
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    let {close} = this.props;
    let { filename, mesg } = this.state;
    close({filename, mesg});
  }

  doValidation(){
    let { t } = this.props;
    let { filename } = this.state;

    let err = [];
    if(!filename || filename.length === 0){
      err.push(t("Please enter a filename"));
    }

    this.setState({
      validationErr : err
    })

    return err.length === 0;
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, t} = this.props;

    let {
      showMesg
    } = modalProps;

    let {
      filename,
      mesg,
      validationErr
    } = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">

          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                {t("Last step!  You can include a message below then click \"Submit\" and we'll take care of the rest.")}
              </div>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("File Name")}</label>
                <input className={'form-control'}
                       readOnly={true}
                       type={'text'}
                       value={filename}
                       onChange={(evt) => this.setState({filename: evt.target.value})}
                       placeholder={t('File Name')}/>
              </div>
            </div>
          </div>
          {showMesg &&
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Message(optional)")}</label>
                <textarea className={'form-control no-resize'}
                          rows={4}
                          value={mesg}
                          onChange={(evt) => this.setState({mesg: evt.target.value})}
                          placeholder={t('Message')}/>
              </div>
            </div>
          </div>
          }
          {validationErr.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validationErr}/>
            </div>
          </div>
          }
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.save.bind(this)}>{t("Submit")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

SubmitSignedPdfDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SubmitSignedPdfDialog));
