import React, {Component} from 'react';
import PropTypes from 'prop-types';

import dots from '../../../resources/three-dots.svg';
import lightDots from '../../../resources/three-dots-light.svg';

import classnames from 'classnames';
import {withVFTranslation} from "../../../util/withVFTranslation";
import {withRouter} from "react-router-dom";

class Loading extends Component {

  render() {
    let { centered, inline, size, color, t, ...otherProps } = this.props;

    let img = null;
    if(!color || color === 'primary'){
      img = dots;
    }
    else if(color && color === 'light'){
      img = lightDots;
    }

    let loadStyle = {};
    if(size){
      if(size === 'xs'){
        loadStyle.maxWidth = '30px';
      }
      else if(size === 'sm'){
        loadStyle.maxWidth = '50px';
      }
      else if(size === 'md'){
        loadStyle.maxWidth = '80px';
      }
      else if(size === 'lg'){
        loadStyle.maxWidth = '200px';
      }
    }

    let cn = {
      'd-inline': inline,
      'text-center' : centered
    }

    return <div className={classnames(cn)}>
      <img style={loadStyle}
           alt={t('loading')}
           { ...otherProps }
           src={img} />
    </div>
  }
}

Loading.propTypes = {
  inline: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'light'])
}

export default withVFTranslation()(Loading);
