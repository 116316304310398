import React, {PureComponent, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import UserBadge from "../badges/UserBadge";
import colors from "../../../util/colors";
import log from "../../../util/log";
import Button from "../elements/Button";
import appActions from "../../../actions/app-actions";
import modalActions from "../../../actions/modal-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import VFPopover from "./VFPopover";
import popoverActions from "../../../actions/popover-actions";
import c from "../../../util/const";
import sharedActions from "../../../actions/shared-actions";
import electronUtil from "../../../util/electron-util";
import _ from "lodash";
import platformHelper from "../../../util/platform-helper";
import MerchantSetupWindow from "../../modals/MerchantSetupWindow";

class AccountHeader extends PureComponent {

  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      isHovering : false,
      isRunningWithinElectron : false,
      osType : platformHelper.OS_TYPES.UNKNOWN
    }
  }

  componentDidMount() {
    let ua = _.get(window, 'navigator.userAgent', '');

    this.setState({
      isRunningWithinElectron : electronUtil.isRunningWithinElectron(),
      osType : platformHelper.getOSType(ua)
    })
  }

  goToAccount() {
    let {history} = this.props;

    this.hidePopover();
    history.push('/account');
  }

  goToSignArchive() {
    let {history} = this.props;

    this.hidePopover();
    history.push('/sign_archive');
  }

  goToInvoiceHistory() {
    let {history} = this.props;

    this.hidePopover();
    history.push('/invoice_history');
  }

  hidePopover(evt) {
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.hidePopover()
  }

  showPopover(evt) {
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.showPopover(c.popovers.ACCOUNT_MENU);
  }

  helpClick(){
    this.hidePopover();
    this.props.showHelpResourceWindow();
  }

  openElectronDownloadPage(evt){
    this.hidePopover(evt);

    this.props.showElectronDownloadDialog()
  }

  logout() {
    let {history, logout} = this.props;

    this.hidePopover();
    logout();
    history.push('/');
  }

  upgradeMenuClick(){
    this.hidePopover();
    this.props.showUpgradeDialog(null, (res) => {
      log.log('upgrade dlg res', res);
    })
  }

  renderElectronDownloadLinkIfNeeded(){
    let {
      isRunningWithinElectron,
      osType
    } = this.state;
    let { t } = this.props;

    //Don't show if running within electron already.
    //Don't show if running within electron already.
    if(isRunningWithinElectron || osType === platformHelper.OS_TYPES.UNKNOWN){
      return null;
    }

    return (
      <a target="_blank" href={c.links.electronDownloadPage}
         style={styles.menuItem}
         className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
        {osType === platformHelper.OS_TYPES.WINDOWS &&
          <span>
              <i style={styles.menuIcons} className="icon ion-social-windows"/>
            {t("Get Verifyle for Windows")}
          </span>
        }
        {!!(osType === platformHelper.OS_TYPES.MAC) &&
          <span>
              <i style={styles.menuIcons} className="icon ion-social-apple-outline"/>
            {t("Get Verifyle for MacOS")}
          </span>
        }
      </a>
    )
  }

  getPopoverContent() {
    let {
      accountInfo,
      merchantInfo,
      t
    } = this.props;
    let showUpgrade = accountInfo && c.isFreeTier(accountInfo.class_id);
    return (
      <div>
        <ul className="account-header-popover popover-content list-group" style={styles.popoverWrap}>

          {showUpgrade &&
          <a onClick={this.upgradeMenuClick.bind(this)}
             style={styles.menuItemTop}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item primary-color">
            <span>
              <i style={styles.menuIcons} className="icon ion-ios-star-outline"/>
              {t("Upgrade to Pro!")}
            </span>
          </a>
          }

          <a onClick={this.helpClick.bind(this)}
             style={showUpgrade ? styles.menuItem : styles.menuItemTop}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-ios-help-outline"/>
              {t("Help")}
            </span>
          </a>

          <a onClick={this.goToAccount.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-gear-b"/>
              {t("Account Settings")}
            </span>
          </a>

          <a onClick={this.goToSignArchive.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-ios-bookmarks-outline"/>
              {t("Signing Archive")}
            </span>
          </a>

          <a onClick={this.goToInvoiceHistory.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-clipboard"/>
              {t("Invoice History")}
            </span>
          </a>

          {this.renderElectronDownloadLinkIfNeeded()}

          <a onClick={this.logout.bind(this)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-log-out"/>
              {t("Logout")}
            </span>
          </a>

        </ul>
      </div>
    )
  }

  onMouseEnter(){
    if(this.state.isHovering){
      return;
    }

    this.setState({isHovering : true});
  }

  onMouseLeave(){
    this.setState({isHovering : false});
  }

  render() {
    let {accountInfo} = this.props;
    let { isHovering} = this.state;

    if (!accountInfo) {
      return <div/>
    }

    return (
      <VFPopover
        isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.ACCOUNT_MENU)}
        positions={['bottom', 'left']}
        onClickOutside={this.hidePopover.bind(this)}
        getMenuContent={this.getPopoverContent.bind(this)}>

        <div className="d-flex account-badge has-pointer"
             onMouseEnter={this.onMouseEnter}
             onMouseLeave={this.onMouseLeave}
             onClick={this.showPopover.bind(this)}>
          <div className={'text-right pr-2'}>
            <UserBadge guest={accountInfo} overrideColor={isHovering ? colors.DARK : colors.SECONDARY_TEXT}/>
          </div>
          <div className={'text-left pl-0 pt-3'} style={styles.userInfoWrap}>
            <div className={`${isHovering ? 'dark-color' : 'secondary-text-color'} account-name`}
                 style={styles.userInfoTitle}>
              {accountInfo.first_name} {accountInfo.last_name}
            </div>
            <div className={`${isHovering ? 'dark-color' : 'secondary-text-color'}`} style={styles.userInfoEmail}>
              {accountInfo.login}
            </div>
          </div>
        </div>

      </VFPopover>
    )
  }
}

const styles = {
  userInfoWrap: {
    fontSize: '14px'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px'
  },
  menuIcon: {
    minWidth: '20px',
    fontSize: '24px'
  },
  menuItemTop: {
    padding: '6px 15px',
    borderBottomColor: colors.TRANSPARENT,
    width: 'auto',
    whiteSpace: 'nowrap'
  },
  menuItem: {
    padding: '6px 15px',
    borderBottomColor: colors.TRANSPARENT,
    borderTopColor: colors.TRANSPARENT,
    width: 'auto',
    whiteSpace: 'nowrap'
  },
  menuItemBottom: {
    padding: '7px 15px',
    borderTopColor: colors.TRANSPARENT,
    width: 'auto',
    whiteSpace: 'nowrap'
  },
  menuIcons: {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  popoverWrap : {

  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    merchantInfo : state.shared.merchantInfo,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout : () => dispatch(appActions.logout()),
    ...modalActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    ...sharedActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHeader)));
