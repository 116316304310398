import React, {Component} from 'react';
import PropTypes from 'prop-types';
import modalActions from "../../actions/modal-actions";
import {connect} from "react-redux";
import Draggable from "react-draggable";
import {isMobile} from "react-device-detect";

//This wrapper helps us manage the loading of modals.
//For now it's mostly just the animation of modals, but this could perform other helper
//functions later.
class ModalContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      doFade: false,
      timer : null
    }
  }

  componentDidMount(){
    var me = this;

    if(this.props.onRef) {
      this.props.onRef(this)
    }

    setTimeout(() => {
      this.setState({
        doFade: true
      })
    })

  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }

    if(this.timer){
      clearTimeout(this.timer);
    }
  }

  onDragModal(evt){
    let screenX = evt.clientX;
    let screenY = evt.clientY;
    let bodyRect = this.props.appWindowDimensions;

    //If you can't measure it, don't allow drag by always returning false here.
    if(!bodyRect){
      return false;
    }

    if(screenX < 0){
      return false;
    }
    if(screenY < 0){
      return false;
    }
    if(screenX > bodyRect.width) {
      return false;
    }
    if(screenY > bodyRect.height) {
      return false;
    }

    return true;
  }

  doClose(result) {
    let {callback, closeModal} = this.props;

    this.setState({doFade:false});

    let timer = setTimeout(() =>{
      this.setState({timer : null});

      if(callback) {
        callback(result);
      }

      closeModal();
    }, 200)

    this.setState({timer});
  }

  render() {
    let {children, zIndex} = this.props;
    let {doFade} = this.state;

    if(isMobile){
      return (
          <div style={{zIndex}} className={'modal fade' + (doFade ? ' show' : '')} role="dialog">
            {children}
          </div>
      )
    }
    else{
      return (
          <Draggable handle=".draggable-header"
                     onDrag={this.onDragModal.bind(this)}>
            <div style={{zIndex}} className={'modal fade' + (doFade ? ' show' : '')} role="dialog">
              {children}
            </div>
          </Draggable>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    appWindowDimensions : state.utility.appWindowDimensions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

ModalContainer.propTypes = {
  onRef: PropTypes.func.isRequired,
  closeModal : PropTypes.func.isRequired,
  callback : PropTypes.func.isRequired,
  zIndex : PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
