import _ from "lodash";
import log from "../util/log";

const PendingMsgCache = {
  dmCache : {},
  threadCache : {},

  cacheDm(guest_uid, msg, docs){
    this.dmCache[guest_uid] = {
      msg,
      docs
    }
  },

  addPendingInvoiceToDmCache(guest_uid, invoice){
    if(!this.dmCache[guest_uid]){
      this.dmCache[guest_uid] = { msg : '', docs: [invoice]};
    }
    else{
      this.dmCache[guest_uid].docs.push(invoice);
    }
  },

  addPendingInvoiceToThreadCache(chat_id, invoice){
    if(!this.threadCache[chat_id]){
      this.threadCache[chat_id] = { msg : '', docs: [invoice]};
    }
    else{
      this.threadCache[chat_id].docs.push(invoice);
    }
  },

  addDocsToDmCache(guest_uid, docs){
    if(!this.dmCache[guest_uid]){
      this.dmCache[guest_uid] = { msg : '', docs};
    }
    else{
      _.each(docs, (d) => {
        this.dmCache[guest_uid].docs.push(d);
      })
    }
  },

  removeDocFromDMCache(guest_uid, uniqueId){
    _.remove(this.dmCache[guest_uid] ? this.dmCache[guest_uid].docs : [], (d) => d.uniqueId === uniqueId);
  },

  removeDocFromThreadCache(chat_id, uniqueId){
    _.remove(this.threadCache[chat_id] ? this.threadCache[chat_id].docs : [], (d) => d.uniqueId === uniqueId);
  },

  addDocsToThreadCache(chat_id, docs){
    if(!this.threadCache[chat_id]){
      this.threadCache[chat_id] = { msg : '', docs};
    }
    else{
      _.each(docs, (d) => {
        this.threadCache[chat_id].docs.push(d);
      })
    }
  },

  attachPendingDocsToChat(chat_id, docs){
    if(!this.threadCache[chat_id]){
      this.threadCache[chat_id] = { msg : '', docs: []};
    }

    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-')

      this.threadCache[chat_id].docs.push(f);
    })
  },

  attachPendingDocsToDM(guest_uid, docs){
    if(!this.dmCache[guest_uid]){
      this.dmCache[guest_uid] = { msg : '', docs: []};
    }

    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-')

      this.dmCache[guest_uid].docs.push(f);
    })
  },

  cacheThread(chat_id, msg, docs){
    this.threadCache[chat_id] = {
      msg,
      docs
    }
  },

  clearDM(guest_uid){
    delete this.dmCache[guest_uid];
  },

  clearThread(chat_id){
    delete this.threadCache[chat_id];
  },

  fetchDm(guest_uid){
    return this.dmCache[guest_uid] || {
      msg : '',
      docs : []
    }
  },

  fetchThread(chat_id){
    return this.threadCache[chat_id] || {
      msg : '',
      docs : []
    }
  },

  cleanup(){
    this.dmCache = {};
    this.threadCache = {};
  }
}

export default PendingMsgCache;
