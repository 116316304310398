import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import c from '../../util/const';
import colors from '../../util/colors';
import vfLocalStorage from "../../util/local-storage";
import vfSessionStorage from "../../util/session-storage";
import sharedActions from '../../actions/shared-actions';
import modalActions from '../../actions/modal-actions';
import Header from "../partials/components/Header";
import log from "../../util/log";
import Loading from "../partials/util/Loading";
import Button from "../partials/elements/Button";
import sapi from "../../util/sapi";
import _ from "lodash";
import GuestRow from "../partials/rows/GuestRow";
import ThreadRow from "../partials/rows/ThreadRow";
import classNames from "classnames";
import ScrollingAccordion from "../partials/components/ScrollingAccordion";
import WorkspaceRow from "../partials/rows/WorkspaceRow";
import ScrollingTabView from "../partials/components/ScrollingTabView";
import ExpandableRow from "../partials/components/ExpandableRow";
import Scroll from "react-scroll";
import NotificationIcon from "../partials/components/NotificationIcon";
import ChatPanel from "../partials/chat/ChatPanel";
import DMPanel from "../partials/chat/DMPanel";
import workspaceActions from "../../actions/workspace-actions";
import Promise from "bluebird";
import homeActions from "../../actions/home-actions";
import moment from "moment";
import UploadHelper from "../partials/components/UploadHelper";
import SearchWindow from "../modals/SearchWindow";
import appActions from "../../actions/app-actions";
import {withRouter} from "react-router-dom";
import GuestList from "../partials/rows/GuestList";
import utils from "../../util/util";
import {Helmet} from "react-helmet";
import PendingMsgCache from "../../helpers/pending-msg-cache";
import querystring from "query-string";
import PlaceholderLoaders from "../partials/util/PlaceholderLoaders";
import {withVFTranslation} from "../../util/withVFTranslation";
import msgHelper from "../../helpers/msg-helper";
import downloadActions from "../../actions/download-actions";
import {getMessageForError} from "../../util/errors";
import filters from "../../helpers/filters";
import UserBadge from "../partials/badges/UserBadge";
import classnames from "classnames";
import {isMobile} from "react-device-detect";
import VFPopover from "../partials/components/VFPopover";
import popoverActions from "../../actions/popover-actions";
import {AutoSizer, Column, Table} from "react-virtualized";
import Draggable from "react-draggable";
import ElectronAwareLink from "../partials/elements/ElectronAwareLink";
import MerchantSetupWindow from "../modals/MerchantSetupWindow";

const INVOICE_HISTORY_TAB = 'invoice-history-tab';

//Just fyi that this tabview id has some css targeting it
//in order to work around a double scrollbar in edge.  bug 3882
const TABVIEW_ID = 'invoice-history-scroll-tabview';

class InvoiceHistory extends Component {

  mounted = false;
  didUnmount = false;

  SORTS = {
    PAYMENT_STATUS_ASC : 'payment_status_asc',
    PAYMENT_STATUS_DESC : 'payment_status_desc',
    REQUEST_DATE_ASC : 'request_date_asc',
    REQUEST_DATE_DESC : 'request_date_desc',
    PAYMENT_DATE_ASC : 'payment_date_asc',
    PAYMENT_DATE_DESC : 'payment_date_desc',
    AMOUNT_ASC : 'amount_asc',
    AMOUNT_DESC : 'amount_desc',

    PAYER_NAME_ASC : 'payer_name_asc',
    PAYER_NAME_DESC : 'payer_name_desc',
    REQUESTED_BY_NAME_ASC : 'requested_by_name_asc',
    REQUESTED_BY_NAME_DESC : 'requested_by_name_desc',
  }

  constructor(props) {
    super(props);

    this.mounted = false;
    this.didUnmount = false;

    let savedSort = vfLocalStorage.get(c.localstorage.invoiceHistorySort);

    let paymentStateWidth = 0.1;
    // let controlIconWidth = 0.05;
    let nonStaticColumnWidth = (1 - paymentStateWidth) / 5;
    this.state = {
      invoiceHistoryListSort : savedSort || this.SORTS.REQUEST_DATE_DESC,
      loading: false,
      invoiceHistoryListScrollRef : null,

      hoveringInvoiceId : null,

      sortedMerchantBillList : null,

      //These are percents, calculated above.
      columnWidths: {
        paymentState: paymentStateWidth,
        paymentAmount: nonStaticColumnWidth,
        payer: nonStaticColumnWidth,
        requestedby: nonStaticColumnWidth,
        description: nonStaticColumnWidth,
        dateOfRequest: nonStaticColumnWidth,
        dateOfPayment: nonStaticColumnWidth,
        // control: controlIconWidth
      }
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.setState({loading:true})
    this.doStartup()
      .then(() =>{
        this.setState({loading: false})
      })
      .catch((err) =>{
        log.log('error on invoice history startup', err);
        this.props.history.push('/home');
      })
  }

  componentWillUnmount() {
    this.mounted = false;
    this.didUnmount = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.invoiceHistoryListSort !== this.state.invoiceHistoryListSort ||
      prevProps.merchantBillList !== this.props.merchantBillList) {
      this.updateSortedList();
    }
  }

  doStartup(){
    return this.refreshData();
  }

  refreshData(){
    return this.props.refreshInvoiceHistoryList();
  }

  getSortOptions(){
    let { t } = this.props;
    return {

      [this.SORTS.PAYMENT_STATUS_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.PAYMENT_STATUS_DESC,
        display: t('Sort: Status')
      },
      [this.SORTS.PAYMENT_STATUS_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.PAYMENT_STATUS_ASC,
        display: t('Sort: Status')
      },

      [this.SORTS.REQUEST_DATE_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.REQUEST_DATE_DESC,
        display: t('Sort: Request Date')
      },
      [this.SORTS.REQUEST_DATE_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.REQUEST_DATE_ASC,
        display: t('Sort: Request Date')
      },

      [this.SORTS.PAYMENT_DATE_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.PAYMENT_DATE_DESC,
        display: t('Sort: Payment Date')
      },
      [this.SORTS.PAYMENT_DATE_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.PAYMENT_DATE_ASC,
        display: t('Sort: Payment Date')
      },

      [this.SORTS.AMOUNT_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.AMOUNT_DESC,
        display: t('Sort: Amount')
      },
      [this.SORTS.AMOUNT_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.AMOUNT_ASC,
        display: t('Sort: Amount')
      },

      [this.SORTS.PAYER_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.PAYER_NAME_DESC,
        display: t('Sort: Payer Name')
      },
      [this.SORTS.PAYER_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.PAYER_NAME_ASC,
        display: t('Sort: Payer Name')
      },
      [this.SORTS.REQUESTED_BY_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.REQUESTED_BY_NAME_DESC,
        display: t('Sort: Requested By Name')
      },
      [this.SORTS.REQUESTED_BY_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.REQUESTED_BY_NAME_ASC,
        display: t('Sort: Requested By Name')
      },
    }
  }

  updateSortedList(){
    let { merchantBillList } = this.props;
    this.setState({
      sortedMerchantBillList : this.doSortList(merchantBillList)
    }, () => {
      if(this.state.invoiceHistoryListScrollRef) {
        this.state.invoiceHistoryListScrollRef.recomputeList();
      }
    })
  }

  doSortList(merchantBillList){
    let { invoiceHistoryListSort } = this.state;
    log.log('doSortList', invoiceHistoryListSort);

    if(invoiceHistoryListSort === this.SORTS.REQUEST_DATE_DESC){
      return this.doDateSort(merchantBillList, 'request_date', true);
    }
    else if(invoiceHistoryListSort === this.SORTS.REQUEST_DATE_ASC){
      return this.doDateSort(merchantBillList, 'request_date', false);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYMENT_STATUS_DESC){
      return _.orderBy((merchantBillList), [item => this.getPaymentStateStringForSort(item)], ['desc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYMENT_STATUS_ASC){
      return _.orderBy((merchantBillList), [item => this.getPaymentStateStringForSort(item)], ['asc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYMENT_DATE_DESC){
      return this.doDateSort(merchantBillList, 'payment_date', true);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYMENT_DATE_ASC){
      return this.doDateSort(merchantBillList, 'payment_date', false);
    }
    else if(invoiceHistoryListSort === this.SORTS.AMOUNT_DESC){
      return _.orderBy((merchantBillList), [item => item['amount']], ['desc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.AMOUNT_ASC){
      return _.orderBy((merchantBillList), [item => item['amount']], ['asc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYER_NAME_DESC){
      return _.orderBy((merchantBillList), [item => this.getPayerNameForSort(item)], ['desc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.PAYER_NAME_ASC){
      return _.orderBy((merchantBillList), [item => this.getPayerNameForSort(item)], ['asc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.REQUESTED_BY_NAME_DESC){
      return _.orderBy((merchantBillList), [item => this.getRequestedByNameForSort(item)], ['desc']);
    }
    else if(invoiceHistoryListSort === this.SORTS.REQUESTED_BY_NAME_ASC){
      return _.orderBy((merchantBillList), [item => this.getRequestedByNameForSort(item)], ['asc']);
    }
    else{
      throw new Error('Unsupported Sort')
    }
  }

  getPayerNameForSort(bill){
    let { accountInfo, t } = this.props;
    let areYouPayer = accountInfo.uid === bill.payer_uid;
    if(areYouPayer){
      return t("You").toLowerCase()
    }
    else{
      return bill['payer_first_name'].toLowerCase() + bill['payer_last_name'].toLowerCase();
    }
  }

  getRequestedByNameForSort(bill) {
    let { accountInfo, t } = this.props;
    let areYouRequestor = !bill.merchant_flag;
    if(areYouRequestor){
      return t("You").toLowerCase()
    }
    else{
      return bill['merchant_first_name'].toLowerCase() + bill['merchant_last_name'].toLowerCase();
    }
  }

  doDateSort(list, dateProperty, isDesc) {
    //Note : We had some really handling here that I removed for bug 2566.
    if(isDesc) {
      return _.concat(
        _.sortBy((list), (item) => {
          return -_.get(item, dateProperty) || -1;
        })
      )
    }
    else {
      return _.concat(
        _.sortBy((list), (item) => {
          return +_.get(item, dateProperty) || 1;
        })
      )
    }
  }

  getPaymentStateStringForSort(bill){
    let { t } = this.props;
    if(bill.refund_amount && _.isNumber(bill.refund_amount)){
      return t("Refunded");
    }
    else if(bill.paid_flag){
      return t("Paid");
    }
    else if(!bill.paid_flag){
      return t("Pending")
    }
    return "";
  }

  renderPaymentState(bill){
    let { t } = this.props;
    if(bill.refund_amount && _.isNumber(bill.refund_amount)){
      return (
        <div className="payment-state-box refunded">
          <span className="text-uppercase">
            {t("Refunded")}
          </span>
        </div>
      )
    }
    else if(bill.paid_flag){
      return (
        <div className="payment-state-box paid">
          <span className="text-uppercase">
            <i className="icon ion-checkmark green-color mr-1" />
            {t("Paid")}
          </span>
        </div>
      )
    }
    else if(!bill.paid_flag){
      return (
        <div className="payment-state-box pending">
          <span className="text-uppercase">
            {t("Pending")}
          </span>
        </div>
      )
    }

  }

  renderPaymentAmount(bill){
    let refundedAmount = bill.refund_amount ? bill.refund_amount : 0;

    return (
      <div>
        <div>
          <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}</span>
          <span className="small d-inline ml-2">{bill.currency}</span>
        </div>
        {bill.refund_amount &&
          <div className="small">
            -{filters.formatCurrency(this.props.i18n.language, bill.currency, refundedAmount)}
          </div>
        }
      </div>
    )
  }

  renderPayer(bill){
    let { accountInfo, t } = this.props;
    let areYouPayer = accountInfo.uid === bill.payer_uid;

    let first_name = areYouPayer ? accountInfo.first_name : bill.payer_first_name;
    let last_name = areYouPayer ? accountInfo.last_name : bill.payer_last_name;
    let email = areYouPayer ? accountInfo.login : bill.payer_email_address;
    let guest_uid = areYouPayer ? accountInfo.uid : bill.payer_uid;

    let guestObj = {
      first_name : first_name,
      last_name : last_name,
      guest_uid : guest_uid
    }

    return (
      <div className="d-flex">
        <div className={'text-right pr-2'}>
          <UserBadge guest={guestObj} overrideColor={areYouPayer ? colors.PRIMARY : null} small={true}/>
        </div>
        <div className={'text-left small pl-0'}>
          {areYouPayer &&
            <div className={`dark-color`}>
              {t("You")}
            </div>
          }
          {!areYouPayer &&
            <div className={`dark-color`}>
              {first_name} {last_name}
            </div>
          }
          <div className={`dark-color`}>
            {email}
          </div>
        </div>
      </div>
    )
  }

  renderRequestedBy(bill){
    let { accountInfo, t } = this.props;
    let areYouRequestor = !bill.merchant_flag;

    let first_name = areYouRequestor ? accountInfo.first_name : bill.merchant_first_name;
    let last_name = areYouRequestor ? accountInfo.last_name : bill.merchant_last_name;
    let email = areYouRequestor ? accountInfo.login : bill.merchant_email_address;
    let guest_uid = areYouRequestor ? accountInfo.uid : bill.uid;

    let guestObj = {
      first_name : first_name,
      last_name : last_name,
      guest_uid : guest_uid
    }

    return (
      <div className="d-flex">
        <div className={'text-right pr-2'}>
          <UserBadge guest={guestObj} overrideColor={areYouRequestor ? colors.PRIMARY : null} small={true}/>
        </div>
        <div className={'text-left small pl-0'}>
          {areYouRequestor &&
            <div className={`dark-color`}>
              {t("You")}
            </div>
          }
          {!areYouRequestor &&
            <div className={`dark-color`}>
              {first_name} {last_name}
            </div>
          }
          <div className={`dark-color`}>
            {email}
          </div>
        </div>
      </div>
    )
  }

  onRowClick(bill, evt){
    log.log('mouse click', bill);
    this.props.showInvoiceHistoryItemDialog(bill.invoice_id, (res) => {
      log.log('invoice history item closed', res);
    })
  }

  onMouseEnter(invoice_id, evt){
    this.setState({
      hoveringInvoiceId : invoice_id
    })
  }

  onMouseLeave(invoice_id, evt){
    this.setState({hoveringInvoiceId : null})
  }

  headerRenderer = ({
                      columnData,
                      dataKey,
                      disableSort,
                      label,
                      sortBy,
                      sortDirection
                    }) => {
    return (
      <React.Fragment key={dataKey}>
        <div className="ReactVirtualized__Table__headerTruncatedText">
          {label}
        </div>
        <Draggable
          axis="x"
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
          onDrag={(event, {deltaX}) => this.resizeColumn({dataKey, deltaX})}
          position={{x: 0}}
          zIndex={999}
        >
          <span className="DragHandleIcon">⋮</span>
        </Draggable>
      </React.Fragment>
    );
  };

  resizeColumn = ({ dataKey, deltaX }) =>
    this.setState(prevState => {
      const prevWidths = prevState.columnWidths;
      const percentDelta = deltaX / prevState.autoSizerWidth;

      let list = _.map(prevWidths, (w) => w);
      let nextIndex = _.findIndex(list, (w) => w.dataKey === dataKey);
      const nextDataKey = list[nextIndex + 1];

      return {
        columnWidths: {
          ...prevWidths,
          [dataKey]: prevWidths[dataKey] + percentDelta,
          [nextDataKey]: prevWidths[nextDataKey] - percentDelta
        }
      };
    });

  onAutosizerResize({height, width}){
    if(this.didUnmount){
      log.log('ignoring resizer height update after unmounting');
      return;
    }

    this.setState({
      autoSizerHeight: height,
      autoSizerWidth: width
    })
  }

  onSetupMerchantAccountClick(){
    this.props.showMerchantSetupWindowUpgradeFlow()
  }

  renderEmptyState(){
    let {
      accountClassInfo,
      merchantInfo,
      t
    } = this.props;
    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    let isHealthy = MerchantSetupWindow.isMerchantStatusHealthy(merchantInfo);

    return (
      <div className="row">
        <div className="offset-3 col-6 text-center"  style={{marginTop: '15vh'}}>
          <p className="secondary-text-color">
            {t("When you send an invoice, a record of it will be displayed here.")}
          </p>
          <p className="secondary-text-color">
            {t("When one of your Contacts has requested a payment from you, or when you have requested a payment from one of your Contacts, you’ll find the invoice listed here.")}
          </p>
          {(!merchant_flag || !isHealthy) &&
          <div className="mt-5">
            <button className="btn btn-lg btn-primary"
                    onClick={() => this.onSetupMerchantAccountClick()}>
              {t("Setup Merchant Account")}
            </button>
          </div>
          }
        </div>
      </div>
    )
  }

  renderListContents(){
    let {
      merchantBillList,
      t
    } = this.props;
    let {
      sortedMerchantBillList,
      columnWidths,
      loading
    } = this.state;

    if(loading){
      return (
        <div className="text-center"  style={{marginTop: '15vh'}}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    if(!merchantBillList || !sortedMerchantBillList || merchantBillList.length === 0){
      return this.renderEmptyState();
    }

    //Important to place px-0 and mx-0 here, because we have to absolutely calculate
    //resizable columns widths based on the screen width, which would mess up our math and cause scrollbars.
    return (
      <div className="container-fluid px-0 mx-0">
        <AutoSizer onResize={(res) => this.onAutosizerResize(res)}>
          {({height, width}) => (
            <Table
              width={width}
              height={height}
              rowClassName={'invoice-history-row'}
              headerHeight={20}
              rowHeight={60}
              rowCount={sortedMerchantBillList.length}
              onRowMouseOver={({ event, index }) => this.onMouseEnter(sortedMerchantBillList[index].invoice_id, event)}
              onRowMouseOut={({ event, index }) => this.onMouseLeave(sortedMerchantBillList[index].invoice_id, event)}
              onRowClick={({ event, index }) => this.onRowClick(sortedMerchantBillList[index], event)}
              rowGetter={({index}) => sortedMerchantBillList[index]}
              estimatedRowSize={60}>
              <Column
                headerRenderer={this.headerRenderer}
                dataKey="paymentState"
                label={t("Status")}
                cellRenderer={({rowIndex}) => this.renderPaymentState(sortedMerchantBillList[rowIndex])}
                width={columnWidths.paymentState * width}
              />
              <Column
                headerRenderer={this.headerRenderer}
                dataKey="paymentAmount"
                label={t("Amount")}
                cellRenderer={({rowIndex}) => this.renderPaymentAmount(sortedMerchantBillList[rowIndex])}
                width={columnWidths.paymentAmount * width}
              />

              <Column
                headerRenderer={this.headerRenderer}
                dataKey="requestedby"
                label={t("Requested By")}
                cellRenderer={({rowIndex}) => this.renderRequestedBy(sortedMerchantBillList[rowIndex])}
                width={columnWidths.requestedby * width}
              />
              <Column
                headerRenderer={this.headerRenderer}
                dataKey="payer"
                label={t("Payer")}
                cellRenderer={({rowIndex}) => this.renderPayer(sortedMerchantBillList[rowIndex])}
                width={columnWidths.payer * width}
              />

              <Column
                headerRenderer={this.headerRenderer}
                dataKey="description"
                label={t("Description")}
                width={columnWidths.description * width}
              />
              <Column
                headerRenderer={this.headerRenderer}
                dataKey="dateOfRequest"
                label={t("Date of Request")}
                cellRenderer={({rowIndex}) => filters.momentFilter(sortedMerchantBillList[rowIndex].request_date, 'l')}
                width={columnWidths.dateOfRequest * width}
              />
              <Column
                headerRenderer={this.headerRenderer}
                dataKey="dateOfPayment"
                label={t("Date of Payment")}
                cellRenderer={({rowIndex}) => {
                  let bill = sortedMerchantBillList[rowIndex];
                  return bill.payment_date ? filters.momentFilter(bill.payment_date, 'l') : '-';
                }}
                width={columnWidths.dateOfPayment * width}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  setInvoiceHistoryListSort(sort){
    vfLocalStorage.set(c.localstorage.invoiceHistorySort, sort);
    this.setState({invoiceHistoryListSort : sort})
  }

  renderSortOptionRow(activeSort, option1, option2, optionIfNeither, doSelectSort){
    let sortOptions = this.getSortOptions();
    return (
      <a onClick={doSelectSort.bind(this, activeSort === option1 ? option2 : option1)}
         className={`sort-row list-group-item list-group-item-action ${(activeSort === option1 || activeSort === option2) ? 'active' : ''}`}>
        {activeSort === option1 &&
        <Fragment>
          <i className={`icon ${sortOptions[option1].icon} mr-2`}/>
          {sortOptions[option1].display}
        </Fragment>
        }
        {activeSort === option2 &&
        <Fragment>
          <i className={`icon ${sortOptions[option2].icon} mr-2`}/>
          {sortOptions[option2].display}
        </Fragment>
        }
        {activeSort !== option2 && activeSort !== option1 &&
        <Fragment>
          <i className={`icon mr-2`}/>
          {sortOptions[optionIfNeither].display}
        </Fragment>
        }
      </a>
    )
  }

  renderTabView(){
    let { t } = this.props;
    let { invoiceHistoryListSort } = this.state;
    let tabs = [];

    tabs.push({
      id : INVOICE_HISTORY_TAB,
      tabRenderFn: (tab) => {
        return (
          <div className="d-inline-block" style={{marginLeft : '10px'}}>
            <h4 className={'m-0 list-header'}>
              <NotificationIcon iconColorOverride={colors.DARK}
                                iconCls="ion-clipboard"
                                value={0} />
              {t("Invoice History")}
            </h4>
          </div>
        )
      },
      isSelected : true,
      listRenderFn : () => {
        return this.renderListContents();
      },
      getSortingPopoverContent: () => {
        return (
          <Fragment>
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.PAYMENT_STATUS_ASC, this.SORTS.PAYMENT_STATUS_DESC, this.SORTS.PAYMENT_STATUS_ASC, this.setInvoiceHistoryListSort)}
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.REQUEST_DATE_DESC, this.SORTS.REQUEST_DATE_ASC, this.SORTS.REQUEST_DATE_DESC, this.setInvoiceHistoryListSort)}
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.PAYMENT_DATE_DESC, this.SORTS.PAYMENT_DATE_ASC, this.SORTS.PAYMENT_DATE_DESC, this.setInvoiceHistoryListSort)}
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.AMOUNT_ASC, this.SORTS.AMOUNT_DESC, this.SORTS.AMOUNT_ASC, this.setInvoiceHistoryListSort)}
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.PAYER_NAME_ASC, this.SORTS.PAYER_NAME_DESC, this.SORTS.PAYER_NAME_ASC, this.setInvoiceHistoryListSort)}
            {this.renderSortOptionRow(invoiceHistoryListSort, this.SORTS.REQUESTED_BY_NAME_ASC, this.SORTS.REQUESTED_BY_NAME_DESC, this.SORTS.REQUESTED_BY_NAME_ASC, this.setInvoiceHistoryListSort)}
          </Fragment>
        )
      },
      getSortLabel: () => {
        return this.getSortOptions()[invoiceHistoryListSort].display
      }
    })

    return (
      <div className="row">
        <div className="col p-0">
          <ScrollingTabView id={TABVIEW_ID}
                            headerCls="sign-archive-tabs"
                            scrollOffset={70}
                            tabs={tabs}
                            customTabHeight={58} />
        </div>
      </div>
    )
  }

  renderHelmetTags(){

    return (
      <div>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: c.smartbanner.non_mobile_viewport_banner
          }]}
        />
        <Helmet>
          <meta name="viewport" content="" />
        </Helmet>
      </div>
    )
  }

  render() {
    return (
      <div className={'column-scroll-layout has-header'}>
        {this.renderHelmetTags()}
        <Header isLockedToTop={true}
                showSearch={false}
                showBackButton={true}
                showAcctHeader={true} />
        <div className="container-fluid">
          {this.renderTabView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showingPopoverKey : state.popover.showingPopoverKey,
    workspaces : state.shared.workspaces,
    directMessages : state.shared.directMessages,
    merchantBillList : state.shared.merchantBillList,
    appWindowDimensions : state.utility.appWindowDimensions,
    accountInfo : state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url)),
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory)));
