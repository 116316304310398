import c from '../util/const';
import Promise from "bluebird";
import log from "../util/log";
import sapi from "../util/sapi";
import enums from '../util/enums';
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf"
import _ from "lodash";
import PdfSignatureRequestOverlay from "../components/partials/pdf-preview/PdfSignatureRequestOverlay";
import PdfPreview from "../components/partials/pdf-preview/PdfPreview";
import {PDFDocument} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import SignatureRequest from "../models/SignatureRequest";
import sharedActions from "./shared-actions";
import ColorGenerator from "../helpers/color-generator";

const pdfPreviewActions = {

  initialize(forum_id, host_uid, doc_id, chat_id){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.pdf.initialize,
        forum_id,
        host_uid,
        doc_id,
        chat_id,
      })
    }
  },

  initializeSignArchiveContext(signArchiveRow, signArchiveInfo){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.pdf.initialize,
        isSignArchiveContext : true,
        signArchiveRow,
        signArchiveInfo
      })
    }
  },

  loadPdf(){
    return (dispatch, getState) => {
      let {
        forum_id,
        host_uid,
        doc_id,
        pdfWriter,

        isSignArchiveContext,
        signArchiveRow,
        signArchiveInfo
      } = getState().pdfPreview;

      log.log('load pdf in actions', isSignArchiveContext, signArchiveRow, signArchiveInfo);

      if(isSignArchiveContext){
        return this.downloadSignArchivePdfData(signArchiveRow.sign_request_id, signArchiveRow.host_uid, signArchiveRow.label, signArchiveRow.doc_key)
          .then((rawPdf) => {
            dispatch({
              type : c.actions.pdf.setRawPdf,
              rawPdf
            })
            return dispatch(this.loadPdfModels(rawPdf));
          })
      }
      else{
        return this.downloadPdfData(forum_id, host_uid, doc_id)
          .then((rawPdf) => {
            dispatch({
              type : c.actions.pdf.setRawPdf,
              rawPdf
            })
            return dispatch(this.loadPdfModels(rawPdf));
          })
      }
    }
  },

  loadPdfModels(rawPdf, isRefresh){
    return (dispatch, getState) => {

      let {
        isSignArchiveContext,
      } = getState().pdfPreview;

      if(!isSignArchiveContext) {
        //This is a promise, but not needed in main promise flow
        dispatch(this.tryLoadPdfWriter(rawPdf, isRefresh));
      }

      let init = {
        data: rawPdf,
        cMapUrl: "/node_modules/pdfjs-dist/cmaps/",
        cMapPacked: true,
      }

      //NOTE: this had to be relocated to the PUBLIC directory.  There were weird runtime problems referencing
      //it from node_modules
      pdfjsLib.GlobalWorkerOptions.workerSrc = c.pdf.pdfjs_workerPath;

      let pdf = null;
      return pdfjsLib.getDocument(init).promise
        .then((pdfRes) => {
          log.log('pdf loaded', pdfRes);
          pdf = pdfRes
          return pdf.getMetadata();
        })
        .then((pdfMetadata) => {
          log.log('got pdf metadata', pdfMetadata);
          let isPdfPortfolio = _.get(pdfMetadata, 'info.IsCollectionPresent');
          if(isPdfPortfolio){
            let pdfLoadErr = { isPdfCollection : true };
            this.setPdfJsLoadErr(pdfLoadErr)
            return Promise.reject(pdfLoadErr);
          }

          dispatch({
            type : c.actions.pdf.setPdfJsModel,
            pdf : pdf
          })

          let calls = [];
          for (let num = 1; num <= pdf.numPages; num++) {
            //Kind of a hack, the app expects pageIndex to be available on page, but
            //They renamed this property to _pageIndex at some point.
            calls.push(pdf.getPage(num)
              .then((page) => {
                page.pageIndex = page._pageIndex
                return page;
              }));
          }

          return Promise.all(calls);
        })
        .then((pageRes) => {
          log.log('pageRes', pageRes);
          dispatch({
            type : c.actions.pdf.setPdfJsPages,
            pages : pageRes
          })
        })
    }
  },

  reloadPdfDataFromPdfWriter(){
    return (dispatch, getState) => {
      let { pdfWriter } = getState().pdfPreview;
      return pdfWriter.getUintPdfData()
        .then((rawPdf) => {

          dispatch({
            type : c.actions.pdf.setRawPdf,
            rawPdf
          })

          return dispatch(this.loadPdfModels(rawPdf, true))
        })
    }
  },

  downloadAndMergePdfDocs(mergePdfWindowRes){
    return (dispatch, getState) => {
      let results = {};

      //This concurrency: 1 enforces that only 1 promise is evaluated at a time.  It's important
      //because if you select more than one, we want to attach all pages from the first,
      //before moving on to the second.
      let mapOptions = {concurrency : 1};

      return Promise.map(mergePdfWindowRes, (pdfToDownload) => {
          let key = '' + pdfToDownload.forum_id + pdfToDownload.host_uid + pdfToDownload.doc_id;
          results[key] = {
            info : pdfToDownload,
            isDownloaded : false,
            isMerged : false,
            error : null,
            isEncrypted : false
          }

          log.log('initiating pdf download', results[key])
          return this.downloadPdfData(pdfToDownload.forum_id, pdfToDownload.host_uid, pdfToDownload.doc_id)
            .then((pdfData) => {

              results[key].isDownloaded = true;
              return new Promise((resolve, reject) => {
                PDFDocument.load(pdfData, { ignoreEncryption: true })
                  .then((loadedDoc) => {
                    if(loadedDoc.isEncrypted){
                      results[key].isEncrypted = true;
                      throw new Error('This pdf is encrypted');
                    }

                    log.log('loaded pdf', loadedDoc);
                    let { pdfWriter } = getState().pdfPreview;

                    return pdfWriter.attachAllPages(loadedDoc);
                  })
                  .then((res)=> {
                    log.log('attach all pages result', res);

                    results[key].isMerged = true;
                    resolve(true);
                  })
                  .catch((err) => {
                    log.log('pdf writer was unable to load pdf', err);
                    results[key].error = err;
                    reject(err);
                  })
              })
            })
            .catch((err) => {
              log.log('download pdf problem', err);
              results[key].error = err;
            })
        }, mapOptions)
        .then(() => {
          //we're all done I guess.  Reload pdf
          return dispatch(this.reloadPdfDataFromPdfWriter());
        })
        .then(() => {
          //Then return results of the merge
          return results;
        })
    }
  },

  downloadPdfData(forum_id, host_uid, doc_id){
    return sapi.Docs.view(forum_id, host_uid, doc_id, true)
      .then((res) => {
        return new Uint8Array(res);
      })
  },

  downloadSignArchivePdfData(sign_request_id, host_uid, label, doc_key){
    return sapi.SignArchive.view(sign_request_id, host_uid, label, doc_key, true)
      .then((res) => {
        return new Uint8Array(res);
      })
  },

  tryLoadPdfWriter(rawPdf, isDataRefresh){
    return (dispatch, getState) => {
      let {forum_id, host_uid, doc_id} = getState().pdfPreview;
      log.log('tryLoadWriter', forum_id, host_uid, doc_id, getState().pdfPreview)
      let { pdfWriter } = getState().pdfPreview;
      pdfWriter.load(rawPdf, isDataRefresh)
        .then(() => {
          if (!pdfWriter.hasLoaded()) {
            log.log('pdf writer was unable to load pdf')
            dispatch({
              type : c.actions.pdf.setPdfWriterLoadErr,
              pdfWriterLoadErr : "Unable to load PDF"
            })
          }
          else {
            dispatch({
              type : c.actions.pdf.setPdfWriterLoadErr,
              pdfWriterLoadErr : null
            })
          }
        })
        .catch((err) => {
          log.log('pdf writer was unable to load pdf', err);
          dispatch({
            type : c.actions.pdf.setPdfWriterLoadErr,
            pdfWriterLoadErr : err
          })
        })
    }
  },

  setPdfJsLoadErr(err){
    return {
      type : c.actions.pdf.setPdfJsLoadErr,
      pdfLoadErr : err
    }
  },

  setWindowSigningMode(mode){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.pdf.setWindowSigningMode,
        mode
      })
    }
  },

  onPageCanvasRef(id, ref) {
    return (dispatch, getState) => {
      let originalLookup = _.get(getState(), 'pdfPreview.pdfPageCanvasLookup');
      dispatch({
        type: c.actions.pdf.setPdfPageCanvasLookup,
        pdfPageCanvasLookup: _.extend(originalLookup,
          {
            [id]: ref
          }
        )
      })
    }
  },

  //v2 stuff

  getCurrentlyEditingGuestInfo(){
    return (dispatch, getState) => {
      let {
        signatureRequestEditingGuestIndex,
        signatureRequestData
      } = getState().pdfPreview;

      if(signatureRequestEditingGuestIndex < 0){
        return null;
      }

      if(!signatureRequestData){
        return null;
      }

      let editResult = signatureRequestData.signer_results[signatureRequestEditingGuestIndex];
      if(!editResult){
        return null;
      }
      return _.find(signatureRequestData.guest_infos, (info) => info.guest_uid === editResult.guest_uid);
    }
  },

  refreshSigningTemplates(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        sapi.SignTemplate.list()
          .then((res) => {
            dispatch({
              type : c.actions.pdf.setSigningTemplates,
              signTemplateList : res.data
            })
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  unmapUserFromSigningTemplate(guest_uid){
    return (dispatch, getState) => {
      let templateCache = _.get(getState(), 'pdfPreview.signTemplateCache');
      _.each(_.keys(templateCache), (tpl_id) => {
        let mappedGuestUid = _.get(templateCache[tpl_id], 'guest_uid');
        if (mappedGuestUid === guest_uid) {
          dispatch({
            type : c.actions.pdf.clearSigningTemplate,
            template_id : tpl_id
          })
        }
      })
    }
  },

  loadSigningTemplateForUser(template_id, guest_uid){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        let templateCache = _.get(getState(), 'pdfPreview.signTemplateCache');
        if(templateCache[template_id]){
          let mappedGuestUid = _.get(templateCache[template_id], 'guest_uid');
          if(mappedGuestUid && mappedGuestUid === guest_uid){
            resolve(templateCache[template_id].template)
            return;
          }
          else{
            //Then this template is mapped to somebody else.  Clear it so we can map it to this user.
            dispatch({
              type : c.actions.pdf.clearSigningTemplate,
              template_id
            })
          }
        }
        else{
          //try to find this user mapped to another template.
          //If they are, clear it, we're about to map them to something different.
          _.each(_.keys(templateCache), (tpl_id) => {
            if(tpl_id !== template_id) {
              let mappedGuestUid = _.get(templateCache[tpl_id], 'guest_uid');
              if (mappedGuestUid === guest_uid) {
                dispatch({
                  type : c.actions.pdf.clearSigningTemplate,
                  template_id : tpl_id
                })
              }
            }
          })
        }

        sapi.SignTemplate.info(template_id)
          .then((res) => {
            //for some reason the backend doesn't return the template_id on the response.
            let fixedTemplate = _.extend({template_id}, res.data);
            dispatch({
              type : c.actions.pdf.loadSigningTemplate,
              template : fixedTemplate,
              guest_uid
            })
            resolve(fixedTemplate);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  loadSignatureRequestData(doesSignatureRequestExist, signatureRequestData){
    return (dispatch, getState) => {
      let isFulfillingSignatureRequest = !!(signatureRequestData && signatureRequestData.sign_requestor);

      dispatch({
        type : c.actions.pdf.loadSignatureRequestData,
        isFulfillingSignatureRequest,
        signatureRequestData
      })

      if(!isFulfillingSignatureRequest) {
        dispatch(this.setSignatureRequestEditingGuestIndex(0))
        dispatch(this.refreshSigningTemplates());
      }

      dispatch({
        type : c.actions.pdf.setWindowSigningMode,
        mode : enums.WINDOW_SIGNING_STATUS.V2_SIGNING
      })

      dispatch(this.loadSignatureOverlaysIfNeeded(doesSignatureRequestExist, isFulfillingSignatureRequest, signatureRequestData))
    }
  },

  updateSignatureRequestData(signatureRequestData){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.pdf.loadSignatureRequestData,
        isFulfillingSignatureRequest : false,
        signatureRequestData
      })

      dispatch(this.setSignatureRequestEditingGuestIndex(0))

      dispatch({
        type : c.actions.pdf.setWindowSigningMode,
        mode : enums.WINDOW_SIGNING_STATUS.V2_SIGNING
      })
    }
  },

  setSignatureRequestEditingGuestIndex(newIndex){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.pdf.setSignatureRequestEditingGuestIndex,
        newIndex
      })
    }
  },

  findSignInfoForCurrentUser(signatureRequestData){
    return (dispatch, getState) => {
      let { accountInfoGuest } = getState().shared;
      return _.find(signatureRequestData.signature_request, (userSignInfo) => userSignInfo.signer_uid === accountInfoGuest.guest_uid);
    }
  },

  loadSignatureOverlaysIfNeeded(doesSignatureRequestExist, isFulfillingSignatureRequest, signatureRequestData){
    return (dispatch, getState) => {
      log.log('loadSignatureOverlays', doesSignatureRequestExist, isFulfillingSignatureRequest, signatureRequestData);
      if(!doesSignatureRequestExist){
        return;
      }

      let { accountInfoGuest } = getState().shared;

      //doc_annotate_id: "618c209a94f3e113"
      // expiry_date: null
      // phone: null
      // request_date: 1636573338
      // sign_data: {signatures: Array(1)}
      // sign_order: 1
      // signed_date: null
      // signer_uid: "6181826c434cd496"
      // source_doc_id: "618c20512a32671a"
      // terms: null

      // let foundRequest = _.find(signatureRequestData.signature_request, (userSignInfo) => userSignInfo.signer_uid === accountInfoGuest.guest_uid);
      // let sign_data = foundRequest.sign_data;
      // let signaturesToLoad = [];
      let overlays = [];
      let topMostRequest = null;
      _.each(signatureRequestData.signature_request, (userSignInfo) => {
        let signer_uid = userSignInfo.signer_uid;
        let isOverlayYours = signer_uid === accountInfoGuest.guest_uid;
        _.each(userSignInfo.sign_data.signatures, (sig) => {
          let overlay = SignatureRequest.convertSignaturePointToOverlayPoint(sig, signer_uid);

          if(isFulfillingSignatureRequest && isOverlayYours){
            if(!topMostRequest){
              topMostRequest = overlay;
            }

            if(overlay.pageIndex < topMostRequest.pageIndex) {
              topMostRequest = overlay;
            }
            else if (overlay.pageIndex === topMostRequest.pageIndex && overlay.coords.y < topMostRequest.coords.y) {
              topMostRequest = overlay;
            }
            overlay.confirmed = false;
          }

          overlays.push(overlay);
        })
      })

      if(isFulfillingSignatureRequest) {
        _.each(overlays, (overlay) => {
          if (topMostRequest.id === overlay.id) {
            overlay.selected = true;
          }
          else {
            overlay.selected = false;
          }
        })
      }

      log.log('loading overlays', overlays);
      dispatch(this.updateSignatureRequestOverlays(overlays));
    }
  },

  updateSignatureRequest(terms, smsNumber){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.pdf.updateSignatureRequest,
        terms,
        smsNumber,
      })
    }
  },

  saveSignatureRequest(){
    return (dispatch, getState) => {
      let {signatureRequestOverlays, signatureRequestData} = getState().pdfPreview;

      let signer_info = [];
      //signer_results is order-dependent!
      _.each(signatureRequestData.signer_results, (r) => {
        let info = {
          signer_uid : r.guest_uid,
          sign_data : {
            signatures : [] //we will populate this below.
          },
        }
        if(r.requireSMS){
          info.phone = r.smsNumber;
        }
        if(r.requireTerms){
          info.terms = r.terms;
        }
        signer_info.push(info);
      })

      _.each(signatureRequestOverlays, (overlay) => {
        let signatureData = SignatureRequest.convertOverlayPointToSignaturePoint(overlay);

        let foundGuestInfo = _.find(signer_info, (info) => info.signer_uid === overlay.guest_uid);
        foundGuestInfo.sign_data.signatures.push(signatureData);
      })

      log.log('submitting request', signatureRequestData, signer_info);

      let addSignatureRequestPromise = null;
      if(signatureRequestData.dm_guest_uid){
        addSignatureRequestPromise = sapi.DM.addThreadSignatureRequest(
          signatureRequestData.dm_guest_uid,
          signatureRequestData.mesg_id,
          signatureRequestData.doc_id,
          signer_info,
          null)
      }
      else{
        addSignatureRequestPromise = sapi.Workspace.addThreadSignatureRequest(
          signatureRequestData.forum_id,
          signatureRequestData.chat_id,
          signatureRequestData.mesg_id,
          signatureRequestData.doc_id,
          signer_info,
          null)
      }

      return addSignatureRequestPromise
        .then((res) => {
          //update user limits now that they may have changed.
          dispatch(sharedActions.updateAccountInfoForLimits())
          return res;
        })
    }
  },

  setRequestOverlays(overlays){
    return (dispatch, getState) => {
      let args = {
        signatureRequestOverlays: overlays,
        signatureRequestOverlayLookup: PdfPreview.sortV2OverlaysToPageIndex(overlays),
      }

      dispatch({
        type: c.actions.pdf.updateSignatureRequestOverlays,
        ...args
      });
    }
  },

  updateSignatureRequestOverlays(overlays, confirmSignatureCount){
    return (dispatch, getState) => {

      let isFulfillingRequest = _.get(getState(), 'pdfPreview.isFulfillingSignatureRequest');
      if(!isFulfillingRequest) {
        let currentGuestInfo = dispatch(this.getCurrentlyEditingGuestInfo());
        if (currentGuestInfo) {
          dispatch(this.unmapUserFromSigningTemplate(currentGuestInfo.guest_uid));
        }
      }

      let args = {
        signatureRequestOverlays : overlays,
        signatureRequestOverlayLookup : PdfPreview.sortV2OverlaysToPageIndex(overlays),
      }

      if(_.isNumber(confirmSignatureCount)){
        args.confirmSignatureCount = confirmSignatureCount;
      }

      dispatch({
        type : c.actions.pdf.updateSignatureRequestOverlays,
        ...args
      });
    }
  },

  //v1 stuff

  updateV1SigningOverlays(signatureIds){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1SignatureOverlays,
        signatureIds
      })
    }
  },

  updateV1ActiveOverlays(activeOverlays){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1ActiveOverlays,
        activeOverlays
      })
    }
  },

  updateV1InactiveOverlays(invalidOverlays){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1InactiveOverlays,
        invalidOverlays
      })
    }
  },

  updateV1HasPdfChanges(pdfChangesMade){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1PdfChangesMade,
        pdfChangesMade
      })
    }
  },

  updateHasValidV1Signing(hasValidV1Signing){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1HasValidSigning,
        hasValidV1Signing
      })
    }
  },

  updateHasCommittedV1Signatures(hasCommittedSignatures){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1HasCommittedSignatures,
        hasCommittedSignatures
      })
    }
  },

  resetV1Signing(){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.resetV1SigningState
      })
    }
  },

  teardown(){
    return (dispatch, getState) => {

      //destroy existing pdf if it exists.
      //Hoping to fix the occasional error in sentry:
      //Cannot use the same canvas during multiple render() operations. Use different canvas or ensure previous operations were cancelled or completed.
      let pdf = _.get(getState(), 'pdfPreview.pdf');
      if(pdf){
        pdf.destroy();
      }

      dispatch({
        type : c.actions.pdf.cleanup
      })
    }
  },

  mapToDispatch(dispatch) {
    return {
      initialize: (forum_id, host_uid, doc_id, chat_id) => dispatch(this.initialize(forum_id, host_uid, doc_id, chat_id)),
      initializeSignArchiveContext: (signArchiveRow, signArchiveInfo) => dispatch(this.initializeSignArchiveContext(signArchiveRow, signArchiveInfo)),
      loadPdf : () =>  dispatch(this.loadPdf()),
      loadPdfModels: (rawPdf) => dispatch(this.loadPdfModels(rawPdf)),
      onPageCanvasRef: (id, ref) => dispatch(this.onPageCanvasRef(id, ref)),
      setPdfJsLoadErr : (err) => dispatch(this.setPdfJsLoadErr(err)),
      reloadPdfDataFromPdfWriter : () => dispatch(this.reloadPdfDataFromPdfWriter()),
      setWindowSigningMode : (mode) => dispatch(this.setWindowSigningMode(mode)),

      downloadAndMergePdfDocs : (mergePdfWindowRes) => dispatch(this.downloadAndMergePdfDocs(mergePdfWindowRes)),

      getCurrentlyEditingGuestInfo: () => dispatch(this.getCurrentlyEditingGuestInfo()),
      updateSignatureRequestData: (signatureRequestData) => dispatch(this.updateSignatureRequestData(signatureRequestData)),
      findSignInfoForCurrentUser: (signatureRequestData) => dispatch(this.findSignInfoForCurrentUser(signatureRequestData)),
      loadSignatureRequestData : (doesSignatureRequestExist, signatureRequestData) => dispatch(this.loadSignatureRequestData(doesSignatureRequestExist ,signatureRequestData)),
      updateSignatureRequestOverlays : (overlays, confirmSignatureCount) => dispatch(this.updateSignatureRequestOverlays(overlays, confirmSignatureCount)),
      updateSignatureRequest : (terms, smsNumber) => dispatch(this.updateSignatureRequest(terms, smsNumber)),
      saveSignatureRequest : () => dispatch(this.saveSignatureRequest()),
      setSignatureRequestEditingGuestIndex : (newIndex) => dispatch(this.setSignatureRequestEditingGuestIndex(newIndex)),
      refreshSigningTemplates : () => dispatch(this.refreshSigningTemplates()),
      loadSigningTemplateForUser : (template_id, guest_uid) => dispatch(this.loadSigningTemplateForUser(template_id, guest_uid)),
      unmapUserFromSigningTemplate : (guest_uid) => dispatch(this.unmapUserFromSigningTemplate(guest_uid)),
      setRequestOverlays : (overlays) => dispatch(this.setRequestOverlays(overlays)),

      updateV1HasPdfChanges : (pdfChangesMade) => dispatch(this.updateV1HasPdfChanges(pdfChangesMade)),
      updateHasValidV1Signing : (hasValidV1Signing) => dispatch(this.updateHasValidV1Signing(hasValidV1Signing)),
      updateHasCommittedV1Signatures : (hasCommittedSignatures) => dispatch(this.updateHasCommittedV1Signatures(hasCommittedSignatures)),
      updateV1SigningOverlays : (signatureIds) => dispatch(this.updateV1SigningOverlays(signatureIds)),
      updateV1ActiveOverlays : (activeOverlays) => dispatch(this.updateV1ActiveOverlays(activeOverlays)),
      updateV1InactiveOverlays : (inactiveOverlays) => dispatch(this.updateV1InactiveOverlays(inactiveOverlays)),
      resetV1Signing: () => dispatch(this.resetV1Signing()),

      teardown : () => dispatch(this.teardown())
    }
  },
}

export default pdfPreviewActions;
