import React, {PureComponent} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ColorGenerator from "../../../helpers/color-generator";
import colors from "../../../util/colors";
import _ from 'lodash'
import memoizeOne from "memoize-one";

class UserBadge extends PureComponent {

  getCharString = memoizeOne((guest) => {
    if(!guest.first_name || !guest.last_name){
      return '';
    }
    let charString = guest.first_name[0] + guest.last_name[0];
    return charString.toUpperCase();
  })

  getGuestUid = memoizeOne((guest) => {
    if(!guest.first_name || !guest.last_name){
      return '';
    }
    return guest.guest_uid || guest.uid || null;
  })

  getUserColor = memoizeOne((guest_uid) => {
    return ColorGenerator.generateColorFromId(guest_uid);
  })

  getBadgeStyle = memoizeOne((small, large) => {
    let badgeStyle = styles.badgeStyle;
    if(small){
      badgeStyle = styles.badgeStyleSmall
    }
    else if(large){
      badgeStyle = styles.badgeStyleLarge;
    }
    return badgeStyle;
  })

  getColorStyle = memoizeOne((userColor, overrideColor) => {
    let colorStyle = {backgroundColor : userColor};
    if(overrideColor){
      colorStyle = {backgroundColor : overrideColor}
    }
    return colorStyle;
  })

  render(){
    let {
      small,
      large,
      overrideColor,
      guest,
      publisherInfo
    } = this.props;

    let badgeStyle = this.getBadgeStyle(small, large);

    if(publisherInfo){
      let publisherColor = this.getUserColor(publisherInfo.title);
      return (
        <div className="light-color" style={{...badgeStyle, ...{backgroundColor : publisherColor}}}>
          <i className="icon icomoon-newspaper enlarged-badge-font" />
        </div>
      )
    }

    let guest_uid = this.getGuestUid(guest);
    let userColor = this.getUserColor(guest_uid);
    let charString = this.getCharString(guest);
    let colorStyle = this.getColorStyle(userColor, overrideColor)

    if(guest_uid) {
      return (
        <div className="light-color" style={{...badgeStyle, ...colorStyle}}>
          {charString}
        </div>
      )
    }
    else{
      //No guest_uid, show special color and icon
      return (
        <div className="light-color" style={{...badgeStyle, ...{backgroundColor : colors.SECONDARY_TEXT}}}>
          <i className="icon ion-android-person" />
        </div>
      )
    }
  }

}

UserBadge.MEDIUM_SIZE_WIDTH = 48;
const styles = {
  badgeStyle : {
    fontSize: '22px',
    padding: '5px',
    borderRadius: '6px',
    width: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    height: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '36px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  },
  badgeStyleSmall : {
    fontSize: '10px',
    padding: '4px',
    borderRadius: '3px',
    width: '26px',
    height: '26px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '15px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  },
  badgeStyleLarge : {
    fontSize: '30px',
    padding: '10px',
    borderRadius: '6px',
    width: '70px',
    height: '70px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '45px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  }
}

UserBadge.propTypes = {
  guest : PropTypes.object.isRequired,
  publisherInfo : PropTypes.object,
  overrideColor : PropTypes.string,
  small : PropTypes.bool,
  large : PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBadge));
