import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {List, AutoSizer} from 'react-virtualized';
import UploadHelper from "../components/UploadHelper";
import GuestRow from "./GuestRow";
import Measure from "react-measure";
import log from "../../../util/log";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import SignArchiveRow from "./SignArchiveRow";
import _ from 'lodash'
import Loading from "../util/Loading";

class SignArchiveList extends PureComponent {
  
  SignArchiveRowHeight = 50;
  
  constructor(props) {
    super(props);
    
    this.listRef = React.createRef();
    
    this.state = {};
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.signArchiveInfoLookup !== this.props.signArchiveInfoLookup && this.listRef) {
      this.recomputeList();
    }
  }

  recomputeList(){
    let ref = _.get(this.listRef, 'current');
    if(ref){
      ref.recomputeRowHeights(0);
      ref.forceUpdateGrid();
    }
  }

  rowRenderer = ({ index, style, parent }) => {
    let {
      itemList
    } = this.props;
    
    //log.log('row renderer', signArchiveInfoLookup, itemList);
    let item = itemList[index];

    return (
      <div key={item.sign_request_id} style={style}>
        <SignArchiveRow row={item}
                        fetchSignInfo={this.props.fetchSignInfo}
                        onDocumentDownload={(row) => this.props.onDocumentDownload(row)}
                        onPreviewDocumentClick={(row) => this.props.onPreviewDocumentClick(row)}
                        onViewSigningInfoClick={(row) => this.props.onViewSigningInfoClick(row)}
                        onDeleteRecord={(row) => this.props.onDeleteRecord(row)}
                        onItemClick={(row) => this.props.onItemClick(row)} />
      </div>
    )
  };

  getItemHeight(signArchiveInfoLookup, index){
    return this.SignArchiveRowHeight;
  }

  render() {
    const {
      itemList,
      signArchiveInfoLookup
    } = this.props;
    
    if (!itemList) {
      return PlaceholderLoaders.renderContactInfoPlaceholderRows(10);
    }
    
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            overscanRowCount={10}
            rowCount={itemList.length}
            rowHeight={obj => this.getItemHeight(signArchiveInfoLookup, obj.index)}
            rowRenderer={this.rowRenderer}
          />
        )}
      </AutoSizer>
    )
  }
}

SignArchiveList.propTypes = {
  itemList: PropTypes.array.isRequired,
  onDeleteRecord : PropTypes.func.isRequired,
  onViewSigningInfoClick : PropTypes.func.isRequired,
  onPreviewDocumentClick : PropTypes.func.isRequired,
  onDocumentDownload : PropTypes.func.isRequired,
  onItemClick : PropTypes.func.isRequired,
  signArchiveInfoLookup : PropTypes.array.isRequired,
  onRef: PropTypes.func.isRequired,
  fetchSignInfo : PropTypes.func.isRequired,
}

export default SignArchiveList;
