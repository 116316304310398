import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError, getMessageForVFOrTwilioError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import appActions from "../../../../actions/app-actions";
import Account from "../../../pages/Account";
import vfLocalStorage from "../../../../util/local-storage";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import Image from "../../elements/Image";
import PhoneInput, {formatPhoneNumberIntl} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import c from "../../../../util/const";
import AuthCodeInput from "../../elements/AuthCodeInput";
import config from "../../../../util/config";
import _ from "lodash";
import accountActions from "../../../../actions/account-actions";
import VFPopover from "../../components/VFPopover";
import popoverActions from "../../../../actions/popover-actions";
import popupHelper from "../../../../helpers/popup-helper";
import {SEND_CODE_TYPE} from "../../../modals/SMSVerificationDialog";

class TFAPhoneCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      firstSendCodeAttemptFailed : false,
      smsNumber: '',
      verifiedNumberForDisplay : '',
      authCode: '',
      password: '',
      sendingVerificationCodeType : '',
      isSendingCode : false,
      enablingTFA : false,
      confirmCodeSuccess : false,
      disableTFASuccess : false,
      smsCodeSent : false,
      errors : []
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let oldNumber = _.get(prevProps, 'tfaStatus.number', '');
    let newNumber = _.get(this.props, 'tfaStatus.number', '');
    if(oldNumber !== newNumber && newNumber){
      this.setState({
        verifiedNumberForDisplay : formatPhoneNumberIntl('' + newNumber)
      })
    }
  }

  init() {
    this.setState({
      firstSendCodeAttemptFailed : false,
      smsNumber: '',
      verifiedNumberForDisplay : '',
      password: '',
      isSendingCode : false,
      disablingTFA : false,
      enablingTFA : false,
      confirmCodeSuccess : false,
      disableTFASuccess : false,
      smsCodeSent : false,
      errors : []
    })

    let newNumber = '' + _.get(this.props, 'tfaStatus.number', '');
    if(newNumber){
      let verifiedNumberForDisplay  = formatPhoneNumberIntl('+' + newNumber)
      this.setState({
        verifiedNumberForDisplay,
      })
    }
  }

  doEnableTFAValidation(){
    let { t } = this.props;
    let { password } = this.state;
    let code = this.codeField.assembleCode();
    let err = [];
    if(!code || code.length !== 6){
      err.push(t("Please enter your Authentication Code"));
    }
    if(!password || password.length === 0){
      err.push(t("Please enter your password"));
    }

    this.setState({errors : err})
    return err.length === 0;
  }

  doDisableTFAValidation(){
    let { t } = this.props;
    let { password } = this.state;

    let err = [];

    if(!password || password.length === 0){
      err.push(t("Please enter your password"));
    }

    this.setState({errors : err})

    return err.length === 0;
  }

  doSendCodeValidation(){
    let { t } = this.props;
    let { smsNumber } = this.state;

    let err = [];

    if(!smsNumber || smsNumber.length === 0){
      err.push(t("Please enter your phone number"));
    }

    this.setState({errors : err})

    return err.length === 0;
  }

  doConfirmCodeValidation(){
    let { t } = this.props;

    let code = this.codeField.assembleCode();

    let err = [];

    if(!code || code.length === 0){
      err.push(t("Please enter your confirmation code"));
    }

    this.setState({errors : err})

    return err.length === 0;
  }

  enableTFA(){
    if(!this.doEnableTFAValidation()){
      return;
    }

    let { t } = this.props;
    let { password } = this.state;
    let code = this.codeField.assembleCode();
    this.setState({enablingTFA : true})
    sapi.TFA.enableSMS(code, password)
      .then((res) => {
        log.log('tfa enabled result', res);
        this.setState({confirmCodeSuccess : true})

        return Promise.all([
          this.props.updateTFAStatus(),
          this.props.updatePasswordInfo()
        ])
      })
      .then((res) => {
        setTimeout(() => {
          this.props.doClose();
        }, 2000);
      })
      .catch((err) => {
        log.log('error enabling tfa', err);
        this.setState({errors: [getMessageForError(err, t)]})
      })
      .finally(() => {
        this.setState({enablingTFA : false})
      })
  }

  disableTFA(){
    if(!this.doDisableTFAValidation()){
      return;
    }

    let { t } = this.props;
    let { password } = this.state;

    this.setState({disablingTFA : true})
    sapi.TFA.disableSMS(password)
      .then((res) => {
        log.log('tfa disabled result', res);
        this.setState({disableTFASuccess : true})
        return Promise.all([
          this.props.updateTFAStatus(),
          this.props.updatePasswordInfo()
        ])
      })
      .then((res) => {
        setTimeout(() => {
          this.props.doClose();
        }, 2000);
      })
      .catch((err) => {
        log.log('error disabling tfa', err);
        this.setState({errors: [getMessageForError(err, t)]})
      })
      .finally(() => {
        this.setState({disablingTFA : false})
      })
  }

  cancelTFA(){
    let { doClose } = this.props;

    doClose();
  }

  sendVerificationCodeClick(type){
    if(!this.doSendCodeValidation()){
      return;
    }

    let { t } = this.props;
    let { smsNumber, smsCodeSent } = this.state;
    let call_flag = type === SEND_CODE_TYPE.PHONE;

    this.setState({
      isSendingCode : true,
      sendingVerificationCodeType : type
    })
    sapi.TFA.registerSMS(smsNumber, call_flag)
      .then((res) => {
        log.log('register sms res', res);

        this.setState({
          smsCodeSent : true,
          isSendingCode : false,
          sendingVerificationCodeType : '',
        })
      })
      .catch((err) => {
        log.log('error doing send code', err);

        //There are tons of error twilio error codes: https://www.twilio.com/docs/api/errors
        //We want a couple special cases.

        let validationErr = null;
        let twilioCode = +_.get(err, 'body.data.code');
        if(twilioCode === 21211){
          //{
          //    "data" : {
          //       "code" : 21211,
          //       "message" : "The &#39;To&#39; number 11111111111 is not a valid phone number.",
          //       "more_info" : "https://www.twilio.com/docs/errors/21211",
          //       "status" : 400
          //    },
          //    "error" : {
          //       "code" : 101,
          //       "name" : "APP_REQ_INVALID"
          //    },
          //    "success" : false
          // }
          validationErr = t("That is not a valid phone number. Please try again.");
        }
        else if(twilioCode === 21614 || twilioCode === 21606 || twilioCode === 21215){
          //{
          //    "data" : {
          //       "code" : 21614,
          //       "message" : "To number: 15005550009, is not a mobile number",
          //       "more_info" : "https://www.twilio.com/docs/errors/21614",
          //       "status" : 400
          //    },
          //    "error" : {
          //       "code" : 101,
          //       "name" : "APP_REQ_INVALID"
          //    },
          //    "success" : false
          // }
          validationErr = <span>
              {t("That phone number is not reachable via SMS. ")}
            <a className="btn btn-link" onClick={() => this.sendVerificationCodeClick(SEND_CODE_TYPE.PHONE)}>
                {t("Send code by voice call.")}
              </a>
            </span>
        }
        else{
          validationErr = getMessageForVFOrTwilioError(err, t);
          if(!smsCodeSent){
            this.setState({firstSendCodeAttemptFailed : true})
          }
        }
        this.setState({
          errors : [validationErr],
          sendingVerificationCodeType : '',
          isSendingCode : false
        })
      })
      .finally(() => {
        this.hideResendPopover();
      })
  }

  showResendPopover(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.showPopover(c.popovers.RESEND_CODE_POPOVER);
  }

  hideResendPopover(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.hidePopover();
  }

  renderPopoverContents(args){
    let {position, childRect, popoverRect} = args;

    let popupArgs = {
      position,
      childRect,
      popoverRect
    }
    let data = {
      sendingVerificationCodeType : this.state.sendingVerificationCodeType
    }
    let controls = {
      sendSMSVerificationCode : (type) => this.sendVerificationCodeClick(type),
      t : this.props.t
    }
    return popupHelper.getResendCodePopoverContents(popupArgs, data, controls)
  }

  renderDidntGetCodeLink(){
    let { t } = this.props;
    return (
      <VFPopover isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.RESEND_CODE_POPOVER)}
                 positions={['top', 'bottom']}
                 reposition={false}
                 containerClassName="preview-container-cls"
                 onClickOutside={(evt) => this.hideResendPopover(evt)}
                 getMenuContent={(args) => this.renderPopoverContents(args)}>
        <a className="btn btn-link primary-color"
           href="#"
           style={{
             minWidth: '11rem',
             height: '3rem',
             lineHeight: '6.5rem',
             padding: '0px',
             margin: '1px',
             textWrap: 'nowrap'
           }}
           onClick={(evt) => this.showResendPopover(evt)}>
          {t("Didn't get a code?")}
        </a>
      </VFPopover>
    )
  }

  renderEnterCodeSection() {
    let {t} = this.props;
    let {
      enablingTFA,
      confirmCodeSuccess,
      authCode,
      password
    } = this.state;

    return (
      <div className={'row fade-in-up'}>
        <div className={'col'}>
          <div className="green-color mb-2">
            <i className="icon ion-android-checkmark-circle mr-2"/>
            {t("One more step! Enter the 6-digit code we texted you.")}
          </div>

          {/*This form is important so Safari doesn't autofill beyond this input.*/}
          {/*Removing this form results in bug 3695. */}
          <form onSubmit={() => _.noop}>
            <div className="form-group">
              <label>{t("Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={password}
                     onChange={(evt) => this.setState({password: evt.target.value})}
                     placeholder={t('Enter your password')}/>
            </div>

            <div className="d-flex mt-3" style={{justifyContent: 'space-between'}}>
              <AuthCodeInput onRef={ref => this.codeField = ref}
                             label={t("Authentication Code")}
                             useNonFloatingLabel={true}
                             onChange={(val) => this.setState({authCode : val})}
                             inputCls="tfa-auth-input-in-modal"
                             inputDisabled={false}/>
              {this.renderDidntGetCodeLink()}
            </div>

            {this.renderValidationErrors()}

            <div className={'text-right'}>
              {enablingTFA &&
                <Loading inline={true}
                         className={'mr-2'}
                         size={'sm'}/>
              }
              <Button disabled={enablingTFA || confirmCodeSuccess}
                      className={'btn btn-secondary'}
                      type={'button'}
                      onClick={this.cancelTFA.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={password.length === 0 || authCode.length === 0 || confirmCodeSuccess || enablingTFA}
                      type={'button'}
                      className={`btn ${confirmCodeSuccess ? 'btn-success' : 'btn-primary'} ml-2`}
                      onClick={this.enableTFA.bind(this)}>
                {confirmCodeSuccess &&
                  <i className="icon ion-android-checkmark-circle mr-2"/>
                }
                {confirmCodeSuccess &&
                  <span>{t("Success!")}</span>
                }
                {!confirmCodeSuccess &&
                  <span>{t("Confirm Code")}</span>
                }
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  renderValidationErrors(){
    let {
      errors
    } = this.state;

    if(errors.length > 0){
      return (
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={errors}/>
          </div>
        </div>
      )
    }
    return null;
  }

  renderSendCodeSection() {
    let { isSendingCode, firstSendCodeAttemptFailed } = this.state;
    let {t} = this.props;
    return (
      <div className={'row'}>
        <div className={'col'}>
          <div className="mb-3">

            {!firstSendCodeAttemptFailed &&
              <>
                <Button className={'btn btn-primary mr-2'}
                        disabled={isSendingCode}
                        onClick={() => this.sendVerificationCodeClick(SEND_CODE_TYPE.SMS)}>
                  {t("Send Authentication Code")}
                </Button>
                {isSendingCode &&
                  <Loading inline={true}
                           color="primary"
                           size={'sm'}/>
                }
              </>
            }
            {firstSendCodeAttemptFailed && this.renderDidntGetCodeLink()}
          </div>
          {this.renderValidationErrors()}
          <div>
            <p className="mb-3">
              {t("Message and data rates may apply.")}
            </p>
            <p className="mb-3">
              {t("You will only receive one message per authentication request.")}
            </p>
            <p className="mb-3">
              {t("Reply HELP for help. Reply STOP to opt out.")}
            </p>
            <p className="mb-3">
              {t("SMS Terms of Service: ")}<a className="dark-color" target={'_blank'}
                                              href={c.links.smsTerms}>{"verifyle.com/smsterms.html"}</a>
            </p>
            <p className="mb-3">
              {t("Privacy Policy: ")}<a className="dark-color" target={'_blank'}
                                        href={c.links.privacy}>{"verifyle.com/privacypolicy.html"}</a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  getTFAEnabledPanel(){
    let { t } = this.props;
    let {
      password,
      disablingTFA,
      verifiedNumberForDisplay,
      disableTFASuccess
    } = this.state;

    return (
      <Fragment>
        <div style={Account.styles.rowContents} className={'mb-3'}>
          <div className={'row'}>
            <div className={'col'}>
              <p>
                {t("To disable 2FA via the following phone number enter your password below.")}
              </p>
              <div className="text-center font-italic secondary-text-color">
                {verifiedNumberForDisplay}
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Password")}</label>
                <input className={'form-control'}
                       type={'password'}
                       value={password}
                       onChange={(evt) => this.setState({password: evt.target.value})}
                       placeholder={t('Enter your password')}/>
              </div>
            </div>
          </div>

          {this.renderValidationErrors()}

          <div className={'row'}>
            <div className={'col'}>
              <div className={'text-right'}>
                {disablingTFA &&
                  <Loading inline={true}
                           className={'mr-2'}
                           size={'sm'}/>
                }
                <Button disabled={disablingTFA || disableTFASuccess}
                        className={'btn btn-secondary'}
                        onClick={this.cancelTFA.bind(this)}>{t("Cancel")}</Button>
                <Button disabled={disablingTFA || disableTFASuccess || password.length === 0}
                        className={`btn ${disableTFASuccess ? 'btn-success' : 'btn-primary'} ml-2`}
                        onClick={this.disableTFA.bind(this)}>
                  {disableTFASuccess &&
                    <i className="icon ion-android-checkmark-circle mr-2"/>
                  }
                  {disableTFASuccess &&
                    <span>{t("Success!")}</span>
                  }
                  {!disableTFASuccess &&
                    <span>{t("Disable")}</span>
                  }
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  getTFADisabledPanel(){
    let { t } = this.props;
    let {
      smsNumber,
      smsCodeSent,
    } = this.state;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>

        <div className={'row'}>
          <div className={'col'}>
            <p>
              {t("Enter the phone number you would like to use for Two-Factor Authentication:")}
            </p>
            <div className="form-group mt-3">
              <label>{t("Phone Number")}</label>
              <div className="d-flex">
                <PhoneInput
                  flags={flags}
                  international
                  className={`form-control mr-3 w-50 ${smsCodeSent ? 'phone-input-disabled' : ''}`}
                  defaultCountry="US"
                  disabled={smsCodeSent}
                  placeholder={t("Enter phone number")}
                  value={smsNumber}
                  onChange={(val) => this.setState({smsNumber : val ? val : ''})}/>
                <div className="text-muted small">
                  <p className="mb-0">{t("Be sure to include country code.")}</p>
                  <p className="mb-0">{t("e.g. + 1 408 555 1234")}</p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {!smsCodeSent && this.renderSendCodeSection()}
        {smsCodeSent && this.renderEnterCodeSection()}

      </div>
    )
  }

  render() {
    let {
      showLoading,
      confirmCodeSuccess,
      disableTFASuccess
    } = this.state;

    let { passwordInfo } = this.props;

    if(showLoading){
      return (
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    //this is a little hacky, we want to show the success button breifly
    //after modifying.  This means we need to wait until this flag is toggled to
    //render the "enabled" state.
    if(disableTFASuccess){
      return this.getTFAEnabledPanel();
    }
    else if(confirmCodeSuccess){
      return this.getTFADisabledPanel();
    }
    else{
      if(!passwordInfo.tfa_sms_flag){
        return this.getTFADisabledPanel();
      }
      else{
        return this.getTFAEnabledPanel();
      }
    }
  }
}

TFAPhoneCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    passwordInfo : state.account.passwordInfo,
    tfaStatus : state.account.tfaStatus,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    updatePasswordInfo : () => dispatch(accountActions.updatePasswordInfo()),
    updateTFAStatus : () => dispatch(accountActions.updateTFAStatus()),
    cleanup : () => dispatch(appActions.cleanup())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TFAPhoneCtrl)));
