import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import classnames from 'classnames';
import colors from "../../../util/colors";
import modalActions from "../../../actions/modal-actions";
import sapi from "../../../util/sapi";
import sharedActions from "../../../actions/shared-actions";
import {getMessageForError} from "../../../util/errors";
import WorkspaceBadge from "../badges/WorkspaceBadge";
import ColorGenerator from "../../../helpers/color-generator";
import { isMobile } from 'react-device-detect';
import {withVFTranslation} from "../../../util/withVFTranslation";
import VFPopover from "../components/VFPopover";
import c from "../../../util/const";
import popoverActions from "../../../actions/popover-actions";
import _ from "lodash";
import memoizeOne from "memoize-one";

class WorkspaceRow extends PureComponent {

  constructor(props){
    super(props);

    this.itemClick = this.itemClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      hovering : false
    }
  }

  onMouseEnter(){
    this.setState({
      hovering : true
    })
  }

  onMouseLeave(){
    this.setState({hovering : false})
  }

  showMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    let { row } = this.props;
    this.props.popoverAction.showPopover(c.popovers.WORKSPACE_MENU, row.forum_id);
  }

  hidePopover(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
  }

  itemClick(){
    let {onItemClick, row} = this.props;

    onItemClick(row);
  }

  showRename(evt){
    let { row, updateWorkspaces, t } = this.props;

    this.hidePopover(evt);

    let doRename = (label) => {
      return sapi.Workspace.rename(row.forum_id, label);
    }

    this.props.showRename(t('Rename Workspace'), row.label, doRename, (res) => {
      if(res){
        updateWorkspaces();
      }
    })
  }

  showArchive(archive_flag, evt){
    let { row, showAlert, updateWorkspaces, t } = this.props;

    this.hidePopover(evt);

    sapi.Workspace.archive(row.forum_id, row.host_uid, archive_flag)
      .then((res) => {
        updateWorkspaces();
      })
      .catch((err) => {
        showAlert(t('Error Updating Workspace'), getMessageForError(err, t))
      })
  }

  setNotifyFlag(notify_flag, evt){
    let { row, showAlert, updateWorkspaces, t } = this.props;

    this.hidePopover(evt);

    sapi.Workspace.setNotifyFlag(row.forum_id, row.host_uid, notify_flag)
      .then((res) => {
        updateWorkspaces();
      })
      .catch((err) => {
        showAlert(t('Error Updating Workspace'), getMessageForError(err, t))
      })
  }

  showWorkspaceDelete(evt){
    let { row, t } = this.props;
    this.hidePopover(evt);

    this.props.showConfirm(
      t("Delete Workspace"),
      t('WARNING: Deleting ') + row.label + t(' permanently deletes the Workspace and all of its contents.  This cannot be undone.  Are you sure you want to do this?'),
      (res) => {
      if(res){
        sapi.Workspace.delete(row.forum_id)
          .then((res) => {
            this.props.updateWorkspaces();
          })
          .catch((err) => {
            this.props.showAlert(t('Error Deleting Workspace'), getMessageForError(err, t))
          })
      }
    })
  }

  getPopoverContent(){
    let { row, t } = this.props;

    let isContentWorkspace = _.get(row, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
    let isHost = !isContentWorkspace && !row.host_uid;

    //when viewing a content workspace from workspace/list, we pull the title from 'label'
    let iconColor = ColorGenerator.generateColorFromId(isContentWorkspace ? _.get(row, 'label', '') : _.get(row, 'forum_id', ''))

    return (
      <div>
        <ul className="popover-content list-group">
          <a onClick={this.itemClick}
             style={{...styles.menuHeader, ...{backgroundColor : iconColor}}}
             className="list-group-item list-group-item-action has-pointer">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className={`icon ${!isContentWorkspace ? 'icomoon-workspace' : 'icomoon-newspaper'}`}/>
            {row.label}
          </a>

          <a onClick={this.itemClick}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("View Workspace")}
          </a>
          {isHost &&
          <a onClick={this.showRename.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-compose"/>
            {t("Rename Workspace")}
          </a>
          }

          {!row.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, true)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
            {t("Mark as Unread")}
          </a>
          }
          {row.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, false)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
            {t("Mark as Read")}
          </a>
          }

          {!row.hide_flag &&
          <a onClick={this.showArchive.bind(this, true)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-box-outline"/>
            {t("Archive Workspace")}
          </a>
          }
          {row.hide_flag &&
          <a onClick={this.showArchive.bind(this, false)}
             style={isHost ? styles.menuItem : styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-refresh-empty"/>
            {t("Un-Archive Workspace")}
          </a>
          }
          {row.hide_flag && isHost &&
          <a onClick={this.showWorkspaceDelete.bind(this)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Delete Workspace")}
          </a>
          }
        </ul>
      </div>
    )
  }

  lookupPublisher = memoizeOne((forum_id, publisherList) => {
    return _.find(publisherList, (p) => p.forum_id === forum_id);
  })

  renderHostRow(){
    let {
      row,
      accountInfo,
      publisherList,
      t
    } = this.props;

    if(_.get(row, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT){
      let publisher = this.lookupPublisher(_.get(row, 'forum_id'), publisherList);
      return (
        <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
          {t("Publisher:")} {_.get(publisher, 'name')}
        </p>
      )
    }
    else if(accountInfo && row.host_uid){
      return (
        <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
          {t("Host:")} {row.first_name} {row.last_name}
        </p>
      )
    }
    else if(accountInfo && !row.host_uid){
      return (
        <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
          {t("Host:")} {accountInfo.first_name} {accountInfo.last_name} {t("(You)")}
        </p>
      )
    }
    else{
      return null;
    }
  }

  render() {
    let { row, t } = this.props;
    let { hovering } = this.state;

    let labelStyle = {};
    if(row.hide_flag){
      labelStyle = {
        color : colors.SECONDARY_TEXT
      }
    }

    return (
      <div onClick={this.itemClick}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           className="d-flex flex-row workspace-row pt-2 pb-2 pl-2" style={{marginLeft: '10px'}}>
        <div style={styles.iconStyle}>
            <i className={'icon ion-record ' + (row.notify_flag ? ' primary-color' : ' transparent-color')}/>
        </div>
        <div style={styles.workspaceRowStyle}>
          {row.hide_flag && <WorkspaceBadge overrideColor={colors.LIGHT_GREY} forum={row} /> }
          {!row.hide_flag && <WorkspaceBadge forum={row} /> }
        </div>
        <div>
          <p className={classnames('workspace-label mb-0')} style={{...labelStyle, ...styles.headerText}}>
            {row.label}
            <VFPopover
              isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.WORKSPACE_MENU, row.forum_id)}
              positions={['right', 'top', 'bottom']}
              onClickOutside={this.hidePopover.bind(this)}
              getMenuContent={this.getPopoverContent.bind(this)}>
                <span style={{lineHeight: '18px'}}
                      className="pl-2 d-inline-block"
                      onClick={this.showMenu.bind(this)}>
                  <i style={styles.gearIcon}
                     className={classnames("icon ion-gear-b item-menu-icon", {'invisible' : !isMobile && !hovering})}/>
                </span>
            </VFPopover>
          </p>
          {this.renderHostRow()}
          <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
            {t("Last Updated:")} {filters.getFriendlyDate(t, row.updated_date)}
          </p>
        </div>
      </div>
    )
  }
}

const styles = {
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  menuHeaderIcon : {
    verticalAlign : 'text-bottom',
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },
  workspaceRowStyle : {
    marginRight : '10px'
  },
  headerText : {
    fontSize: '16px',
    lineHeight : '16px',
    height: '19px'
  },
  secondaryText : {
    fontSize: '12px',
    lineHeight: '14px',
    height : '15px'
  }
}


WorkspaceRow.propTypes = {
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    accountInfo : state.shared.accountInfo,
    showingPopoverKey : state.popover.showingPopoverKey,
    publisherList: state.shared.publisherList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkspaceRow));
