import browser from "./util/browser";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, isSupported, onMessage } from "firebase/messaging";
import log from "./util/log";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//docs on these calls:
//https://firebase.google.com/docs/reference/js/messaging_#functions

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXdaVKV8XtmDWLlZgb94Clz4M0nVp8Phc",
  authDomain: "verifyle-push.firebaseapp.com",
  databaseURL: "https://verifyle-push.firebaseio.com",
  projectId: "verifyle-push",
  storageBucket: "verifyle-push.appspot.com",
  messagingSenderId: "886821826621",
  appId: "1:886821826621:web:2a2d223176e883e26d3a54"
};

//Generated from firebase console -> Project Settings -> Web Configuration -> Web Push Certificates -> Key Pair
const VAPID_KEY = "BFI-VYMoV06Ic4s5wWeFHady_C9UXkqWsF5hWW-p1wI4ra1JTqBPGeWuzYU_NkkSuCgzheh2obbiHLYqQDBS28M";

//Important: trying to load any of this stuff, or call any function in safari throws exceptions
let firebaseApp = null;
let messaging = null;

//Disabling web push 9/29/22 - Kevin
// if(!browser.isSafari()){
//   firebaseApp = initializeApp(firebaseConfig);
//   messaging = getMessaging(firebaseApp);
// }

export const isWebPushSupported = () => {
  //Disabling web push 9/29/22 - Kevin
  return Promise.resolve(false);

  if(browser.isSafari()){
    return Promise.resolve(false);
  }

  return isSupported();
}

export const unregisterToken = () => {
  return deleteToken(messaging);
}

export const fetchToken = () => {
  return getToken(messaging, {vapidKey: VAPID_KEY})
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
