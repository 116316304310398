import request from 'superagent';
import Promise from 'bluebird';
import config from './config';
import log from './log';
import c from './const';

import vfLocalStorage from './local-storage';
import sapi from "./sapi";

const api = {};
api.endpoint = config.prodEndpoint;

let apiRoot = (uri) => {
  let root = config.debug ? api.endpoint : (window.location.protocol + '//' + window.location.host);
  return root + uri;
}

api.setEndpoint = (endpoint) => {
  api.endpoint = endpoint;
}

api.getRoot = (uri) => {
  return apiRoot(uri);
}

api.globalHeaders = () => {
  let headers = {};
  return headers;
}

api.responseHdl = (res, err) => {
  // log.log('api response', res.body || null, err);

  if (err) {
    return Promise.reject(err);
  }

  if (res) {
    if(res.body){
      if(res.body.error){
        throw {
          name: (res.body && res.body.error && res.body.error.name) || null,
          code: (res.body && res.body.error && res.body.error.code) || null,
          error: (res.body && res.body.error) || null,
          body: res.body
        }
      }
      else{
        //looks weird to call something in sapi, but it's just doing
        //the html decoding of object properties.
        sapi.htmlDecodeResponse(res.body);
        return Promise.resolve(res.body);
      }
    }
    else{
      log.error('no body in request');
      return Promise.reject(new Error('no body in request'));
    }
  }

  log.error('no error, and no data!');
  return Promise.reject(new Error('no error or body in request'));
}

api.Login = {
  uri: '/api/acct/login',

  url() {
    return apiRoot(this.uri);
  },

  getDeviceHeader() {
    let deviceLabel = vfLocalStorage.get(c.localstorage.device);
    if(deviceLabel && deviceLabel.length > 0){
      return { [c.api.X_VF_DEVICE] : deviceLabel }
    }
    return {};
  },

  post(email, pwd, paymentMethodId, class_id, coupon) {

    let req = {
      email,
      password : pwd
    }

    if(paymentMethodId){
      req.payment_method_id = paymentMethodId;
    }

    if(class_id && class_id >= 0){
      req.class_id = class_id;
    }

    if(coupon){
      req.coupon = coupon;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .set(this.getDeviceHeader())
      .send(req)
      .then(api.responseHdl)
  }
}

api.Handle = {
  uri: '/api/handle',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  info(handle) {
    let params = {handle};
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}

api.Config = {
  uri: '/api/config',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  get(){
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(api.responseHdl)
  },
}

api.AcctLogo = {
  uri: '/api/acct/logo',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  logo(uid){
    return request
      .post(this.url(uid))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(api.responseHdl)
  },
}

api.ClassInfo = {
  uri: '/api/acct/class/info',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  get(class_id){
    return request
      .post(this.url(class_id))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(api.responseHdl)
  },

  list(inst_id){
    return request
      .post(this.url(inst_id))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(api.responseHdl)
  }
}

api.Stripe = {
  uri: '/api/stripe',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  validateCoupon(id, class_id) {
    let req = { id};
    if(class_id){
      req.class_id = class_id;
    }

    return request
      .post(this.url('coupon/validate'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send(req)
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(api.responseHdl)
  },

  pubKey() {
    return request
      .post(this.url('pub_key'))
      .timeout({response: c.api.TIMEOUT_MS})
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(api.responseHdl)
  },
}

api.Version = {
  uri: '/api/version/list',

  url() {
    return apiRoot(this.uri);
  },

  list() {
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .then(api.responseHdl)
  }
}

api.Publisher = {
  uri: '/api/publisher',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  add(email, uid){

    let params = {email, uid};
    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  },
}

api.AccountEmail = {
  uri: '/api/acct/email',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  validateEmail(email){

    let params = {email};
    return request
      .post(this.url('validate'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  },
}

api.Boarding = {
  uri: '/api/acct/register',

  url() {
    return apiRoot(this.uri);
  },

  post(email, first_name, last_name, inst_id, number, payment_method_id, class_id, coupon, language, no_link_flag) {
    let req = { email, first_name, last_name };

    if(inst_id){
      req.inst_id = inst_id;
    }

    if(number){
      req.number = number;
    }

    if(payment_method_id){
      req.payment_method_id = payment_method_id;
    }

    if(class_id && class_id >= 0){
      req.class_id = class_id;
    }

    if(coupon){
      req.coupon = coupon;
    }

    if(language){
      req.language = language;
    }

    if(no_link_flag){
      req.no_link_flag = no_link_flag ? 1 : 0
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(api.responseHdl)
  },

  resend(email) {
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ email })
      .then(api.responseHdl)
  }
}

api.Notify = {
  uri: '/api/notify',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  block(guest_uid, uid) {
    let params = { guest_uid, uid };
    return request
      .post(this.url('block'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  },

  unblock(guest_uid, uid) {
    let params = { guest_uid, uid };
    return request
      .post(this.url('unblock'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  }
}

api.PasswordRegisterConfirm = {
  uri: '/api/acct/register/confirm',

  url() {
    return apiRoot(this.uri);
  },

  post(email, code, recaptcha_token, no_link_flag) {

    let params = { email, code };

    if(recaptcha_token) {
      params.recaptcha_token = recaptcha_token;
    }
    if(no_link_flag){
      params.no_link_flag = no_link_flag ? 1 : 0;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  }
}

api.PasswordResetConfirm = {
  uri: '/api/acct/reset/confirm',

  url() {
    return apiRoot(this.uri);
  },

  post(email, code, password, no_link_flag) {

    let req = {
      email,
      code
    }

    if(password){
      req.password = password;
    }
    if(no_link_flag){
      req.no_link_flag = no_link_flag ? 1 : 0;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(api.responseHdl)
  }
}

api.PasswordReset = {
  uri: '/api/acct/reset',

  url() {
    return apiRoot(this.uri);
  },

  post(email, no_link_flag) {

    let params = { email: email };
    if(no_link_flag){
      params.no_link_flag = no_link_flag ? 1 : 0;
    }
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(api.responseHdl)
  }
}

api.TFA = {
  uri: '/api/acct/tfa',

  url(action) {
    return apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  sendCode(email, call_flag) {
    let req = {email};
    req.call_flag = call_flag ? 1 : 0;

    return request
      .post(this.url('sms/send'))
      .timeout({response: c.api.TIMEOUT_MS})
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(api.responseHdl)
  },
}

api.Lookup = {
  uri: '/api/acct/lookup',

  url() {
    return apiRoot(this.uri);
  },

  post(email, inst_id, number) {
    let req = { email }

    if(inst_id){
      req.inst_id = inst_id;
    }

    if(number){
      req.number = number;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(api.responseHdl)
  }
}

api.Institution = {
  uri: '/api/inst/info',

  url(inst_id) {
    return apiRoot(this.uri) + '/' + inst_id;
  },

  post(inst_id) {
    return request
      .get(this.url(inst_id))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(api.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(api.responseHdl)
  }
}

export default api;
