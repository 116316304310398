import appActions from "../../../actions/app-actions";
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import React, { Component } from 'react';
import {connect} from 'react-redux';
import log from "../../../util/log";
import companyLogo from "../../../resources/logo-w-text-blue.png";
import Image from "../elements/Image";
import sapi from "../../../util/sapi";
import {getMessageForError} from "../../../util/errors";
import config from "../../../util/config";
import ValidationErrors from "../components/ValidationErrors";
import Loading from "./Loading";
import {withVFTranslation} from "../../../util/withVFTranslation";
import _ from "lodash";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes : '',
      isSubmitting : false,
      reportSubmitted : false,
      submitError : null
    };
  }

  componentDidMount() {
    log.log('error page mounted', this.props.applicationError)
  }

  getErrorString(error){
    return JSON.stringify(error, Object.getOwnPropertyNames(error));
  }

  reloadPage(){
    window.location.reload();
  }

  submitReport(){
    let { notes } = this.state;
    let { applicationError, t } = this.props;

    let emailSubject = "Log submission from web";
    if(config.debug){
      emailSubject = "DEBUG: " + emailSubject;
    }

    this.setState({isSubmitting : true});

    let errorObj = {
      build : _.get(config, 'versions.web'),
      ua : _.get(window, 'navigator.userAgent'),
      stack : this.getErrorString(applicationError),
    }

    sapi.Notify.reportError(emailSubject, notes, JSON.stringify(errorObj))
      .then((res) => {
        setTimeout(() => {
          this.setState({
            reportSubmitted : true,
            isSubmitting : false
          });
        }, 1000);
      })
      .catch((err) => {
        log.warn('error submitting report', err);
        this.setState({
          submitError: getMessageForError(err, t),
          isSubmitting : false
        })
      })
  }

  render() {
    let { t } = this.props;
    let {
      notes,
      submitError,
      reportSubmitted,
      isSubmitting
    } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col text-center" style={{marginTop : '5vh'}}>
            <Image className={'mt-2'} src={companyLogo} alt={'Logo'} imgHeight={70}/>
          </div>
        </div>
        <div className="row">
          <div className="col text-center" style={{marginTop : '10vh'}}>
            <h1>
              <i style={{fontSize : '60px'}} className="icon ion-bug" />
            </h1>
            <h3>
              {t("Whoops, looks like something went wrong.")}
            </h3>
            <p>
              {t("Sorry for the inconvenience.  If you choose, you can help us out by submitting debugging information to us to help us fix this problem.")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-center" style={{
            marginTop: '50px'
          }}>
            <textarea className="no-resize form-control m-auto"
                      rows={5}
                      style={{
                        maxWidth: '35vw',
                      }}
                      disabled={reportSubmitted || isSubmitting}
                      onChange={(evt)=> this.setState({notes : evt.target.value})}
                      placeholder={t("Extra Notes (Optional)")}
                      value={notes} />
            <div className="d-inline-block text-center" style={{marginTop: '50px'}}>
              <button className="btn btn-primary"
                      disabled={reportSubmitted || isSubmitting}
                      onClick={this.submitReport.bind(this)}>
                <>
                  {isSubmitting && !reportSubmitted &&
                  <Loading inline={true}
                           size={'sm'}/>
                  }
                  {reportSubmitted &&
                    <>{t("Thanks for submitting!")}</>
                  }
                  {!isSubmitting && !reportSubmitted &&
                    <>{t("Email to Support")}</>
                  }
                </>
              </button>
              <button className="btn btn-secondary ml-3"
                      onClick={this.reloadPage.bind(this)}>
                {t("Reload Page")}
              </button>
            </div>
            {submitError &&
            <div className="mt-5 mx-auto"
                 style={{maxWidth: '35vw'}}>
              <ValidationErrors errors={[submitError]}/>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    applicationError : state.app.applicationError
  }
};

//Reminder...You're in an error state.  The app is entirely unmounted.
//no modals or anything.
const mapDispatchToProps = (dispatch) => {return {};};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPage)));
