import c from '../util/const';
import SearchWindow from "../components/modals/SearchWindow";
import workspace from "../reducers/workspace";
import {getMessageForError} from "../util/errors";
import _ from 'lodash'
import log from "../util/log";
import popoverActions from "./popover-actions";
import Promise from "bluebird";
import MerchantSetupWindow from "../components/modals/MerchantSetupWindow";
import UpgradeDialogNew from "../components/modals/UpgradeDialogNew";

const modalActions = {

  show(type, props) {

    return (dispatch, getState) => {

      //hide any open popover when you show or close a modal
      dispatch(popoverActions.hidePopover());

      let modalCount = getState().modal.modals.length;
      if(modalCount === 0){
        document.body.classList.add('modal-open');
      }

      dispatch({
        type: c.actions.modal.show,
        modalType: type,
        modalProps : props
      })
    }
  },

  updateSize(id, isLarge){
    return (dispatch) => {
      dispatch({
        type: c.actions.modal.updateSize,
        id,
        isLarge
      })
    }
  },

  showConfirm(title, message, callback, okBtn, cancelBtn){
    return this.show(c.dialog.confirm, {
      callback,
      title,
      message,
      okBtn,
      cancelBtn
    })
  },

  showDoNotShowAgain(title, message, callback, okBtn, cancelBtn){
    return this.show(c.modal.doNotShowAgain, {
      callback,
      title,
      message,
      okBtn,
      cancelBtn
    })
  },

  showSendInvoiceWindow(forum_id, host_uid, chat_id, guest_uid, participants, callback){
    return this.show(c.modal.sendInvoiceWindow, {
      callback,
      forum_id,
      host_uid,
      chat_id,
      guest_uid,
      participants
    })
  },

  showElectronForceVersionDialog(versionComment, callback){
    return this.show(c.modal.electronAppDownloadDialog, {
      callback,
      isForceVersion : true,
      versionComment
    })
  },

  showNewItem(title, labelText, placeholderText, addNewFn, callback){
    return this.show(c.modal.newItem, {
      title,
      labelText,
      placeholderText,
      addNewFn,
      callback
    })
  },

  showNewWorkspace(title, labelText, placeholderText, callback){
    return this.show(c.modal.newWorkspace, {
      title,
      labelText,
      placeholderText,
      callback
    })
  },

  showFinishSignatureRequestDialog(callback){
    return this.show(c.modal.finishSignatureRequestDialog, {
      callback
    })
  },

  showAgreeToTerms(terms, callback){
    return this.show(c.dialog.agree_to_terms, {
      callback,
      terms
    })
  },

  showRequestSignatureCodeDialog(smsNumber, signatureRequest, callback){
    return this.show(c.modal.requestSignatureCodeDialog, {
      callback,
      smsNumber,
      signatureRequest
    })
  },

  showRequestSignatureCodePhoneCallDialog(smsNumber, signatureRequest, callback){
    return this.show(c.modal.requestSignatureCodePhoneCallDialog, {
      callback,
      smsNumber,
      signatureRequest
    })
  },

  showSignatureCodeVerificationDialog(verificationType, smsNumber, signatureRequest, callback){
    return this.show(c.modal.smsVerificationDialog, {
      callback,
      verificationType,
      smsNumber,
      signatureRequest
    })
  },

  showAddSignaturesDialog(callback){
    return this.show(c.dialog.add_signatures, {
      callback
    })
  },

  showSearchContacts(searchString, directMessages, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.CONTACTS,
      searchString,
      directMessages,
      callback
    })
  },

  showSearchHome(searchString, workspaces, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.HOME,
      searchString,
      workspaces,
      callback
    })
  },

  showSearchWorkspace(searchString, workspace, workspaces, guests, threads, docs, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.WORKSPACE,
      searchString,
      workspace,
      workspaces,
      guests,
      threads,
      docs,
      callback
    })
  },

  showRename(title, oldName, renameFn, callback){
    return this.show(c.modal.renameItem, {
      title,
      oldName,
      renameFn,
      callback
    })
  },

  showAuthInfo(title, contents, callback){
    return this.show(c.modal.authInfo, {
      title,
      callback,
      contents
    })
  },

  showTFAInfo(callback){
    return this.show(c.modal.tfaInfo, {
      callback,
    })
  },

  showAlert(title, message, callback){
    return this.show(c.dialog.alert, {
      callback,
      title,
      message
    })
  },

  showAlertCustomContent(title, content, callback){
    return this.show(c.dialog.alertCustomContent, {
      callback,
      title,
      content
    })
  },

  showPdfSubmit(title, suggestedFilename, thread, dm, callback){
    return this.show(c.dialog.pdf_submit, {
      callback,
      title,
      suggestedFilename,
      thread,
      dm
    })
  },

  showSubmitSignedDoc(title, suggestedFilename, showMesg, callback){
    return this.show(c.dialog.submit_signed_doc, {
      callback,
      title,
      suggestedFilename,
      showMesg
    })
  },

  showDocInfo(forum_id, host_uid, doc_id, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      callback
    })
  },

  showDocInfoDM(forum_id, host_uid, doc_id, dm, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      dm,
      callback
    })
  },

  showDocInfoThread(forum_id, host_uid, doc_id, thread, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      chat_id : thread.chat_id,
      thread,
      callback
    })
  },

  showDocPreview(signArchiveRow, signArchiveInfo, callback){
    return this.show(c.modal.preview, {
      isSignArchiveContext : true,
      signArchiveRow,
      signArchiveInfo,
      callback
    })
  },

  showManageObjectPermissionsWindow(object_id, label, object, object_type, workspace, callback){
    return this.show(c.modal.manageObjectPermissionsWindow, {
      object_id,
      object_type,
      object,
      label,
      workspace,
      callback
    })
  },

  showAddGuestWindow(callback){
    return this.show(c.modal.addGuestWindow, {
      callback
    })
  },

  showAddContactWindow(searchString, allowBulkImport, callback){
    return this.show(c.modal.addContactWindow, {
      searchString,
      allowBulkImport,
      callback
    })
  },

  showNotifications(callback){
    return this.show(c.modal.notifications, {
      callback
    })
  },

  showAddDocWindow(forum_id, dest_guest_uid, dest_chat_id, callback){
    return this.show(c.modal.addDoc, {
      forum_id,
      dest_guest_uid,
      dest_chat_id,
      callback
    })
  },

  showMergePdfWindow(forum_id, dest_guest_uid, dest_chat_id, callback){
    return this.show(c.modal.mergePdfs, {
      forum_id,
      dest_guest_uid,
      dest_chat_id,
      callback
    })
  },

  showDownloadMultiple(initial_selection_forum_id, initial_selection_guest_uid, initial_selection_chat_id, selectedDocIds, callback){
    return this.show(c.modal.downloadMultiple, {
      initial_selection_forum_id,
      initial_selection_guest_uid,
      initial_selection_chat_id,
      selectedDocIds,
      callback
    })
  },

  showFulfillSignatureRequest(thread, dm, signatureRequestData, callback){
    return this.show(c.modal.pdfSignatureRequest, {
      thread,
      dm,
      signatureRequestData,
      doesSignatureRequestExist : true,
      callback
    })
  },

  completeSignatureRequest(thread, dm, signatureRequestData, callback){
    return this.show(c.modal.pdfSignatureRequest, {
      thread,
      dm,
      signatureRequestData,
      doesSignatureRequestExist : false,
      callback
    })
  },

  showRequestSignatureWindow(forum_id, chat_id, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback){
    return this.show(c.modal.requestSignature, {
      forum_id,
      chat_id,
      mesg_id,
      doc_id,
      filename,
      participants,
      selectedGuest,
      terms,
      useCancelLanguage,
      callback
    })
  },

  showReviewSignatureRequest(filename, participants, is_dm, requestData, useCancelLanguage, callback){
    return this.show(c.modal.requestSignature, {
      is_dm,
      filename,
      participants,
      requestData,
      useCancelLanguage,
      callback
    })
  },

  showDMRequestSignatureWindow(guest_uid, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback){
    return this.show(c.modal.requestSignature, {
      guest_uid,
      mesg_id,
      is_dm : true,
      doc_id,
      filename,
      participants,
      selectedGuest,
      terms,
      useCancelLanguage,
      callback
    })
  },

  showContactConfirm(headerText, contactName, bodyText, okBtn, cancelBtn, callback){
    return this.show(c.dialog.contactConfirm, {
      headerText,
      contactName,
      bodyText,
      okBtn,
      cancelBtn,
      callback
    })
  },

  showInvoiceHistoryItemDialog(invoice_id, callback){
    return this.show(c.modal.invoiceHistoryItemDialog, {
      invoice_id,
      callback
    })
  },

  showContactInfoWindow(guest_uid, callback){
    return this.show(c.modal.contactInfoWindow, {
      guest_uid,
      callback
    })
  },

  close(id) {
    return (dispatch, getState) => {
      let modalCount = getState().modal.modals.length;
      if(modalCount === 1){
        document.body.classList.remove('modal-open');
      }

      dispatch( {
        type: c.actions.modal.close,
        id
      })
    }
  },

  showGeneratedPasswordWindow(callback){
    return this.show(c.modal.generatedPasswordDialog, {callback});
  },

  showDocViewHistory(params, callback) {
    return this.show(c.modal.docViewHistory, {
      forum_id: params.forum_id || null,
      host_uid: params.host_uid || null,
      guest_uid: params.guest_uid || null,
      doc_id : params.doc_id,
      callback
    })
  },

  showEditThreadMessage(workspace, thread, msg, callback){
    return this.show(c.modal.mesgEditWindow, {
      workspace,
      thread,
      msg,
      callback
    })
  },

  showEditDMMessage(dm, msg, callback){
    return this.show(c.modal.mesgEditWindow, {
      dm,
      msg,
      callback
    })
  },

  showThreadMessageHistory(thread, mesg_id, mesg_edit_flag, fetchMessageFn, refreshMessageFn, callback){
    return this.show(c.modal.mesgHistoryWindow, {
      mesg_edit_flag,
      fetchMessageFn,
      refreshMessageFn,
      thread,
      mesg_id,
      callback
    })
  },

  showDMMessageHistory(dm, mesg_id, fetchMessageFn, refreshMessageFn, callback){
    return this.show(c.modal.mesgHistoryWindow, {
      mesg_edit_flag : true,
      fetchMessageFn,
      refreshMessageFn,
      dm,
      mesg_id,
      callback
    })
  },

  showPromoCodeWindow(classInfo, callback){
    return this.show(c.modal.addPromoCodeDialog, {
      classInfo,
      callback
    })
  },

  showTutorialWindow(callback){
    return this.show(c.modal.tutorialWindow, {
      callback
    })
  },

  showElectronDownloadDialog(callback){
    return this.show(c.modal.electronAppDownloadDialog, {
      callback
    })
  },

  showConfirmName(callback){
    return this.show(c.modal.showConfirmName, {
      callback
    })
  },

  showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback){
    return this.show(c.modal.attachDocToThreadWindow, {
      initial_selection_forum_id,
      initial_selection_guest_uid,
      src_forum_id,
      src_host_uid,
      doc,
      callback
    })
  },

  showFinalizeAttachmentWindow(initial_selection_forum_id, initial_selection_chat_id, initial_selection_guest_uid, filename, callback){
    return this.show(c.modal.finalizeAttachmentWindow, {
      initial_selection_forum_id,
      initial_selection_chat_id,
      initial_selection_guest_uid,
      filename,
      callback
    })
  },

  showSigningMetadataWindow(forum_id, host_uid, dm_guest_uid, metadata, callback){
    return this.show(c.modal.signingMetadata, {
      forum_id,
      host_uid,
      dm_guest_uid,
      metadata,
      callback
    })
  },

  showSignArchiveMetadataWindow(host_uid, doc, info, callback){
    return this.show(c.modal.signingMetadata, {
      isSignArchiveContext : true,
      host_uid,
      doc,
      metadata : info,
      callback
    })
  },

  showContactUsWindow(callback){
    return this.show(c.modal.contactUsWindow, {
      callback
    })
  },

  showHelpResourceWindow(callback){
    return this.show(c.modal.helpResourceWindow, {
      callback
    })
  },

  showUpgradeDialog(upgradeType, callback){
    return this.show(c.modal.upgradeDialogNew, {
      callback,
      upgradeType
    })
  },

  showUpgradeDialogOrError(upgradeType, serverErr, t, callback){
    return (dispatch, getState) => {
      let available = getState().shared.stripeAvailables;

      log.log('modal action upgrade check', getState().shared);
      if(available && available.class_info && !_.isEmpty(available.class_info)){
        dispatch(this.show(c.modal.upgradeDialogNew, {
          upgradeType,
          callback
        }))
      }
      else{
        dispatch(this.showAlert(t('Limit Reached'), getMessageForError(serverErr, t), callback))
      }
    }
  },

  showUpgradeDialogOrCustomError(upgradeType, customErrMsg, t, callback){
    return (dispatch, getState) => {
      let available = getState().shared.stripeAvailables;

      log.log('modal action upgrade check', getState().shared);
      if(available && available.class_info && !_.isEmpty(available.class_info)){
        dispatch(this.show(c.modal.upgradeDialogNew, {
          upgradeType,
          callback
        }))
      }
      else{
        dispatch(this.showAlert(t('Limit Reached'), customErrMsg, callback))
      }
    }
  },

  showStripeCheckout(stripePubKey, description, termCostCents, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.stripeCheckoutDialog, {
        stripePubKey,
        description,
        termCostCents,
        callback
      }))
    }
  },

  showResendConfirmationEmailDialog(confirmationEmail, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.resendConfirmationEmailDialog, {
        confirmationEmail,
        callback
      }))
    }
  },

  showCustomInputReviewDialog(text, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.customInputReviewDialog, {
        text,
        callback
      }))
    }
  },

  showRefundInvoiceDialog(invoice, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.refundInvoiceDialog, {
        invoice,
        callback
      }))
    }
  },

  showMerchantSetupWindowUpgradeFlow() {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(this.showMerchantSetupWindow(false, false, (res) => {
          if(res === MerchantSetupWindow.WINDOW_RESULT.UPGRADE){
            dispatch(this.showUpgradeDialog(UpgradeDialogNew.UPGRADE_TYPES.MERCHANT_INVOICING, (res) => {
              //the upgrade dialog does all the api call updates needed if a change is made
              if(res){
                //worth pointing out that they could have upgraded, but to a plan that still did not support this.
                //Just send them in again post upgrade.
                resolve(dispatch(this.showMerchantSetupWindowUpgradeFlow()));
              }
              else{
                resolve(MerchantSetupWindow.WINDOW_RESULT.CANCEL);
              }
            }))
          }
          else{
            resolve(res)
          }
        }))
      })
    }
  },

  showMerchantSetupWindow(isStripeRefresh, isStripeReturn, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.merchantSetupWindow, {
        isStripeRefresh,
        isStripeReturn,
        callback
      }))
    }
  },

  showViewInvoiceLinkModal(sender_uid, invoice, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.viewInvoiceLinkModal, {
        sender_uid,
        invoice,
        callback
      }))
    }
  },

  showPDFFixerTool(callback){
    return this.show(c.modal.pdfFixerToolDialog, {
      callback
    })
  },

  closeTopLevelModal(){
    return (dispatch, getState) => {
      let modals = _.get(getState(), 'modal.modals', []);
      let rootRef = _.get(getState(), 'modal.rootRef', null);
      if (rootRef && modals && modals.length > 0) {
        let found = modals[modals.length - 1];
        rootRef.doClose(found.id, null);
      }
    }
  },

  setRootRef(ref){
    return {
      type : c.actions.modal.setRootRef,
      ref
    }
  },

  cleanup(){
    return {
      type : c.actions.modal.cleanup
    }
  },

  mapToDispatch(dispatch) {
    return {
      closeTopLevelModal:() => dispatch(this.closeTopLevelModal()),
      setRootRef:(ref) => dispatch(this.setRootRef(ref)),
      updateSize: (id, isLarge) => dispatch(this.updateSize(id, isLarge)),
      showMerchantSetupWindowUpgradeFlow:() => dispatch(this.showMerchantSetupWindowUpgradeFlow()),
      showMerchantSetupWindow:(isStripeRefresh, isStripeReturn, callback) => dispatch(this.showMerchantSetupWindow(isStripeRefresh, isStripeReturn, callback)),
      showViewInvoiceLinkModal:(sender_uid, invoice, callback) => dispatch(this.showViewInvoiceLinkModal(sender_uid, invoice, callback)),
      showAuthInfo:(title, contents, callback) => dispatch(this.showAuthInfo(title, contents, callback)),
      showTFAInfo:(callback) => dispatch(this.showTFAInfo(callback)),
      showDoNotShowAgain:(title, msg, cb, okBtn, cancelBtn) => dispatch(this.showDoNotShowAgain(title, msg, cb, okBtn, cancelBtn)),
      showSendInvoiceWindow:(forum_id, host_uid, chat_id, guest_uid, participants, callback) => dispatch(this.showSendInvoiceWindow(forum_id, host_uid, chat_id, guest_uid, participants, callback)),
      showStripeCheckout: (stripePubKey, description, termCostCents, callback) => dispatch(this.showStripeCheckout(stripePubKey, description, termCostCents, callback)),
      showTutorialWindow: () => dispatch(this.showTutorialWindow()),
      showInvoiceHistoryItemDialog:(invoice_id, callback) => dispatch(this.showInvoiceHistoryItemDialog(invoice_id, callback)),
      showRefundInvoiceDialog:(invoice, callback) => dispatch(this.showRefundInvoiceDialog(invoice, callback)),
      showElectronDownloadDialog: () => dispatch(this.showElectronDownloadDialog()),
      showConfirmName: () => dispatch(this.showConfirmName()),
      showCustomInputReviewDialog:(text, callback) => dispatch(this.showCustomInputReviewDialog(text, callback)),
      showContactUsWindow: (cb) => dispatch(this.showContactUsWindow(cb)),
      showHelpResourceWindow: (cb) => dispatch(this.showHelpResourceWindow(cb)),
      showPromoCodeWindow: (classInfo, cb) => dispatch(this.showPromoCodeWindow(classInfo, cb)),
      showPDFFixerTool: (cb) => dispatch(this.showPDFFixerTool(cb)),
      showResendConfirmationEmailDialog: (confirmationEmail, cb) => dispatch(this.showResendConfirmationEmailDialog(confirmationEmail, cb)),
      showNewItem: (title, labelText, placeholderText, addNewFn, callback) => dispatch(this.showNewItem(title, labelText, placeholderText, addNewFn, callback)),
      showAttachDocToThreadWindow: (initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback) => dispatch(this.showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback)),
      showFinalizeAttachmentWindow: (initial_selection_forum_id, initial_selection_chat_id, initial_selection_guest_uid, filename, callback) => dispatch(this.showFinalizeAttachmentWindow(initial_selection_forum_id, initial_selection_chat_id, initial_selection_guest_uid, filename, callback)),
      showNewWorkspace: (title, labelText, placeholderText, callback) => dispatch(this.showNewWorkspace(title, labelText, placeholderText, callback)),
      showMergePdfWindow: (forum_id, dest_guest_uid, dest_chat_id, callback) => dispatch(this.showMergePdfWindow(forum_id, dest_guest_uid, dest_chat_id, callback)),
      showAddDocWindow: (forum_id, dest_guest_uid, dest_chat_id, callback) => dispatch(this.showAddDocWindow(forum_id, dest_guest_uid, dest_chat_id, callback)),
      showAddGuestWindow: (callback) => dispatch(this.showAddGuestWindow(callback)),
      showContactConfirm: (headerText, contactName, bodyText, okBtn, cancelBtn, callback) => dispatch(this.showContactConfirm(headerText, contactName, bodyText, okBtn, cancelBtn, callback)),
      showUpgradeDialogOrError: (upgradeType, serverErr, t, callback) => dispatch(this.showUpgradeDialogOrError(upgradeType, serverErr, t, callback)),
      showUpgradeDialogOrCustomError: (upgradeType, customErrMsg, t, callback) => dispatch(this.showUpgradeDialogOrCustomError(upgradeType, customErrMsg, t, callback)),
      showUpgradeDialog : (upgradeType, callback) => dispatch(this.showUpgradeDialog(upgradeType, callback)),
      showAlert: (title, msg, cb) => dispatch(this.showAlert(title, msg, cb)),
      showAlertCustomContent: (title, content, cb) => dispatch(this.showAlertCustomContent(title, content, cb)),
      showConfirm:(title, msg, cb, okBtn, cancelBtn) => dispatch(this.showConfirm(title, msg, cb, okBtn, cancelBtn)),
      showElectronForceVersionDialog:(versionComment, callback) => dispatch(this.showElectronForceVersionDialog(versionComment, callback)),
      showFinishSignatureRequestDialog:(callback) => dispatch(this.showFinishSignatureRequestDialog(callback)),
      showAgreeToTerms:(terms, cb) => dispatch(this.showAgreeToTerms(terms, cb)),
      showSignatureCodeVerificationDialog:(verificationType, smsNumber, signatureRequest, cb) => dispatch(this.showSignatureCodeVerificationDialog(verificationType, smsNumber, signatureRequest, cb)),
      showRequestSignatureCodeDialog:(smsNumber, signatureRequest, cb) => dispatch(this.showRequestSignatureCodeDialog(smsNumber, signatureRequest, cb)),
      showRequestSignatureCodePhoneCallDialog:(smsNumber, signatureRequest, cb) => dispatch(this.showRequestSignatureCodePhoneCallDialog(smsNumber, signatureRequest, cb)),
      showAddSignatures:(cb) => dispatch(this.showAddSignaturesDialog(cb)),
      showDocInfoDM: (forum_id, host_uid, doc_id, dm, callback) => dispatch(this.showDocInfoDM(forum_id, host_uid, doc_id, dm, callback)),
      showDocInfoThread: (forum_id, host_uid, doc_id, thread, callback) => dispatch(this.showDocInfoThread(forum_id, host_uid, doc_id, thread, callback)),
      showDocPreview: (signArchiveRow, signArchiveInfo, callback) => dispatch(this.showDocPreview(signArchiveRow, signArchiveInfo, callback)),
      showDocInfo: (forum_id, host_uid, doc_id, callback) => dispatch(this.showDocInfo(forum_id, host_uid, doc_id, callback)),
      showRename : (title, oldName, renameFn, callback) => dispatch(this.showRename(title, oldName, renameFn, callback)),
      showContactInfoWindow : (guest_uid, callback) => dispatch(this.showContactInfoWindow(guest_uid, callback)),
      showAddContactWindow : (searchString, allowBulkImport, callback) => dispatch(this.showAddContactWindow(searchString, allowBulkImport, callback)),
      showNotifications: (callback) => dispatch(this.showNotifications(callback)),
      showEditThreadMessage: (workspace, thread, msg, callback) => dispatch(this.showEditThreadMessage(workspace, thread, msg, callback)),
      showEditDMMessage: (dm, msg, callback) => dispatch(this.showEditDMMessage(dm, msg, callback)),
      showThreadMessageHistory: (thread, mesg_id, mesg_edit_flag, fetchMessageFn, refreshMessageFn, callback) => dispatch(this.showThreadMessageHistory(thread, mesg_id, mesg_edit_flag, fetchMessageFn, refreshMessageFn, callback)),
      showDMMessageHistory: (dm, mesg_id, fetchMessageFn, refreshMessageFn, callback) => dispatch(this.showDMMessageHistory(dm, mesg_id, fetchMessageFn, refreshMessageFn, callback)),
      showSearchContacts: (searchString, directMessages, callback) => dispatch(this.showSearchContacts(searchString, directMessages, callback)),
      showSearchHome: (searchString, workspaces, callback) => dispatch(this.showSearchHome(searchString, workspaces, callback)),
      showSearchWorkspace: (searchString, workspace, workspaces, guests, threads, docs, callback) => dispatch(this.showSearchWorkspace(searchString, workspace, workspaces, guests, threads, docs, callback)),
      showGeneratedPasswordWindow:(callback) => dispatch(this.showGeneratedPasswordWindow(callback)),
      showDocViewHistory:( params, callback) => dispatch(this.showDocViewHistory(params, callback)),
      showDownloadMultiple: (initial_selection_forum_id, initial_selection_guest_uid, initial_selection_chat_id, selectedDocIds, callback) => dispatch(this.showDownloadMultiple(initial_selection_forum_id, initial_selection_guest_uid, initial_selection_chat_id, selectedDocIds, callback)),
      showManageObjectPermissionsWindow: (object_id, label, object, object_type, workspace, callback) => dispatch(this.showManageObjectPermissionsWindow(object_id, label, object, object_type, workspace, callback)),
      showPdfSubmit: (title, suggestedFilename, thread, dm, cb) => dispatch(this.showPdfSubmit(title, suggestedFilename, thread, dm, cb)),
      showSubmitSignedDoc: (title, suggestedFilename, showMesg, cb) => dispatch(this.showSubmitSignedDoc(title, suggestedFilename, showMesg, cb)),
      showRequestSignatureWindow: (forum_id, chat_id, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback) => dispatch(this.showRequestSignatureWindow(forum_id, chat_id, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback)),
      showReviewSignatureRequest:(filename, participants, is_dm, requestData, useCancelLanguage, callback) => dispatch(this.showReviewSignatureRequest(filename, participants, is_dm, requestData, useCancelLanguage, callback)),
      completeSignatureRequest : (thread, dm, signatureRequestData, callback)  => dispatch(this.completeSignatureRequest(thread, dm, signatureRequestData, callback)),
      showFulfillSignatureRequest : (thread, dm, signatureRequestData, callback)  => dispatch(this.showFulfillSignatureRequest(thread, dm, signatureRequestData, callback)),
      showSigningMetadataWindow : (forum_id, host_uid, dm_guest_uid, metadata, callback) => dispatch(this.showSigningMetadataWindow(forum_id, host_uid, dm_guest_uid, metadata, callback)),
      showSignArchiveMetadataWindow : (host_uid, doc, info, callback) => dispatch(this.showSignArchiveMetadataWindow(host_uid, doc, info, callback)),
      showDMRequestSignatureWindow: (guest_uid, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback) => dispatch(this.showDMRequestSignatureWindow(guest_uid, mesg_id, doc_id, filename, participants, selectedGuest, terms, useCancelLanguage, callback)),
    }
  },
}

export default modalActions;
