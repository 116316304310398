import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";
import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import EmptyState from "../../components/EmptyState";
import accountActions from "../../../../actions/account-actions";
import Account from "../../../pages/Account";
import { withTranslation, Trans } from 'react-i18next';
import {withVFTranslation} from "../../../../util/withVFTranslation";
import _ from 'lodash';
import moment from 'moment';
import c from "../../../../util/const";

class LanguageCtrl extends Component {

  static lookupLanguageDisplay(t, lang){
    let found = null;
    let opts = LanguageCtrl.getLanguageOptions(t);
    _.each(opts, (opt) => {
      if(opt.val === lang){
        found = opt.display;
      }
    })
    return found;
  }

  static getLanguageOptions(t){
    return [
      {
        val : c.language.en_us,
        display : t('English')
      },
      {
        val : c.language.fr_fr,
        display : t('French')
      },
      {
        val : c.language.es_es,
        display : t('Spanish')
      },
      // {
      //   val : 'dev',
      //   display : t('Development')
      // }
    ]
  }

  constructor(props){
    super(props);

    this.state = {
      selectedLanguage : ''
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  changeLanguage(evt){
    this.props.i18n.changeLanguage(evt.target.value, (err, t) => {
      if (err){
        return console.log('something went wrong changing language', err);
      }
      else{
        //No need to update moment locale here, we subscribe to the language
        //change event in i18n.js.
        this.props.setAccountLanguage(this.props.i18n.language)
        this.setState({selectedLanguage : this.props.i18n.language})
      }
    });
  }

  init() {
    this.setState({selectedLanguage : this.props.i18n.language})
  }

  render() {
    let { selectedLanguage} = this.state;
    let { t } = this.props;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'row'}>
          <div className={'col'}>

            <div className="form-group">
              <label>{t('Language')}</label>
              <select className="form-control"
                      value={selectedLanguage}
                      onChange={this.changeLanguage.bind(this)}>
                {LanguageCtrl.getLanguageOptions(t).map((option) => {
                  return <option key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

LanguageCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAccountLanguage:(language) => dispatch(sharedActions.setAccountLanguage(language)),
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageCtrl)));
