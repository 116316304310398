import c from '../util/const';
import _ from 'lodash'

let initialState = {
  allStartupCallsFinished : false,
  firstStartupCallsFinished : false,
  workspaces: [],
  accountInfo: null,
  accountClassInfo: null,
  accountInfoGuest : null,
  notifyCount: null,
  threadTransactionQueue: [],
  signatures : null,
  merchantBillList : [],
  publisherList : [],
  directMessages: [],
  directMessageNotifyCount : 0,
  workspaceNotifyCount : 0,
  contacts : [],
  stripeAvailables: null,
  stripeData: null,
  defaultTermsTemplate: null,
  defaultTermsId: null,
  logo : null,
}

const shared = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.shared.updateWorkspaces:
      let workspaceNotifyCount = 0;
      _.each(action.workspaces, (ws) => {
        if(ws.notify_flag){
          workspaceNotifyCount++;
        }
      })

      return {
        ...state,
        workspaces: action.workspaces,
        workspaceNotifyCount
      };

    case c.actions.shared.updateTermsTemplates:
      return {
        ...state,
        defaultTermsTemplate: action.template,
        defaultTermsId: action.terms_id
      }

    case c.actions.shared.updateLogo:
      return {
        ...state,
        logo : action.data
      }

    case c.actions.shared.updateMerchantBillList:
      return {
        ...state,
        merchantBillList : action.merchantBillList,
      }

    case c.actions.shared.updateContacts:
      return {
        ...state,
        contacts : action.contacts
      }

    case c.actions.shared.updateStripeAvailables:
      return {
        ...state,
        stripeAvailables: action.stripeAvailables
      }

    case c.actions.shared.updateStripeData:
      return {
        ...state,
        stripeData: action.stripeData
      }

    case c.actions.shared.updatePublisherList:
      return {
        ...state,
        publisherList: action.publisherList
      }

    case c.actions.shared.updateDirectMessages:
      let notifyCount = 0;
      _.each(action.directMessages, (dm) => {
        if(dm.notify_flag){
          notifyCount++;
        }
      })

      return {
        ...state,
        directMessages: action.directMessages,
        directMessageNotifyCount: notifyCount
      }

    case c.actions.shared.updateAccountSignatures:
      return {
        ...state,
        signatures : action.signatures
      }

    case c.actions.shared.updateAccountClassInfo:
      return {
        ...state,
        accountClassInfo: action.accountClassInfo,
      }

    case c.actions.shared.updateMerchantInfo:
      return {
        ...state,
        merchantInfo: action.merchantInfo,
      }

    case c.actions.shared.updateAccountInfo:
      return {
        ...state,
        accountInfo: action.accountInfo,
        accountInfoGuest : action.accountInfoGuest
      };

    case c.actions.shared.updateNotificationCount:
      return {
        ...state,
        notifyCount: action.notifyCount
      };

    case c.actions.shared.setLoaded:
      return {
        ...state,
        allStartupCallsFinished: action.hasLoaded
      };

    case c.actions.shared.setStartupFirstCallsLoaded:
      return {
        ...state,
        firstStartupCallsFinished: action.hasLoaded
      };

    case c.actions.shared.queueThreadTransaction:
      return {
        ...state,
        threadTransactionQueue : _.concat(state.threadTransactionQueue, action.threadTransactionQueue)
      }

    case c.actions.shared.updateTransaction:
      let copy = _.concat([], state.threadTransactionQueue);
      let updateItem = _.remove(copy, (item) => {
        return item.transaction_id === action.transaction.transaction_id;
      })

      updateItem = _.extend(updateItem[0], action.transaction);

      if (updateItem.files && updateItem.files.length === 1 && updateItem.res) {
        let docServerRes = _.get(updateItem, 'res.data');
        if(docServerRes) {
          let doc = updateItem.files[0];
          if(doc.signature_requested){
            updateItem.files[0] = _.extend({}, doc, docServerRes);
          }
        }
      }
      return {
        ...state,
        threadTransactionQueue :_.concat(copy, [updateItem])
      }

    case c.actions.shared.clearTransaction:
      let tCopy = _.concat([], state.threadTransactionQueue);
      _.remove(tCopy, (item) => {
        return item.transaction_id === action.transaction_id;
      })

      return {
        ...state,
        threadTransactionQueue :tCopy
      }

    case c.actions.shared.cleanup:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default shared;
