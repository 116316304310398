import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";
import c from '../../../../util/const'
import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import Promise from "bluebird";
import accountActions from "../../../../actions/account-actions";
import modalActions from "../../../../actions/modal-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class SubscriptionCtrl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    let {accountInfo} = this.props;

    this.setState({
      validation_errors: [],
      isSaving: false,
    })
  }

  cancelSubscription() {
    let {doCancelSubscription} = this.props;

    doCancelSubscription();
  }

  render() {
    let { t } = this.props;
    let {isSaving, validation_errors} = this.state;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>

        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              {t("For any questions regarding your subscription, contact us at")} <a href={c.links.mailToSupport}
                                                                              className="hover-underline primary-color">{t("support@verifyle.com")}</a>
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-danger'} onClick={this.cancelSubscription.bind(this)}>{t("Cancel Subscription")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SubscriptionCtrl.propTypes = {
  doClose: PropTypes.func.isRequired,
  onRef: PropTypes.func,
  doCancelSubscription: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo: () => dispatch(sharedActions.updateAccountInfo()),
    showConfirm: (title, msg, cb) => dispatch(modalActions.showConfirm(title, msg, cb))
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionCtrl)));
