import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import ChatPanelHeader from "./ChatPanelHeader";
import ChatPanelMessages from "./ChatPanelMessages";
import ChatPanelFooter from "./ChatPanelFooter";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import uploadActions from "../../../actions/upload-actions";
import workspaceActions from "../../../actions/workspace-actions";
import sharedActions from "../../../actions/shared-actions";
import AttachedDocsPanel from "../docs/AttachedDocsPanel";
import Scroll from "react-scroll";
import UpgradeDialogNew from "../../modals/UpgradeDialogNew";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import SignatureRequest from "../../../models/SignatureRequest";
import {getMessageForError} from "../../../util/errors";
import PendingMsgCache from "../../../helpers/pending-msg-cache";
import utils from "../../../util/util";
import msgHelper from "../../../helpers/msg-helper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import threadActions from "../../../actions/thread-actions";
import c from "../../../util/const";

class ChatPanel extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      chatMessagesRef: null,
      footerRef : null,
      docsPanelRef: null,
      sendingMsg: false
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    utils.waitForCondition(() => {
        return !!this.state.footerRef;
      })
      .then(() => {
        if(this.props.thread) {
          let cached = PendingMsgCache.fetchThread(this.props.thread.chat_id);
          this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
        }
      })
  }

  componentWillUnmount() {
    let { footerRef } = this.state;
    if(this.props.thread && footerRef) {
      let previousData = footerRef.getPendingData();
      PendingMsgCache.cacheThread(this.props.thread.chat_id, previousData.msg, previousData.docs);
    }

    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.thread && prevProps.thread !== this.props.thread){

      //Kind of ugly, but the reference can change, but the ids are the same.
      //We only want to run this if they're changing the panel.
      if(!prevProps.thread || prevProps.thread.chat_id !== this.props.thread.chat_id) {
        if (prevProps.thread) {
          //save previous pending data, and restore the upcoming data
          let previousData = this.state.footerRef.getPendingData();
          PendingMsgCache.cacheThread(prevProps.thread.chat_id, previousData.msg, previousData.docs);
        }
        let cached = PendingMsgCache.fetchThread(this.props.thread.chat_id);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);

        // if(prevProps.thread) {
        //   setTimeout(() => {
        //     this.state.chatMessagesRef.scrollToBottomWhenReady();
        //   })
        // }
      }
    }
  }

  mergeAttachDocsWithCurrentMsg(docs){
    let files = [];
    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-');
      files.push(f);
    })

    this.state.footerRef.updatePendingDocs(files);
  }

  doMessagePolling(){
    let {workspace, thread, activeThreadDataDate} = this.props;
    return this.props.threadAction.refreshActiveThreadMessages(true)
      .then((res) => {
        let doFullUpdate = false;
        if(activeThreadDataDate){
          //then do our compare, and update if needed
          let newMsgs = _.get(res, 'data.mesg_data', []);
          if(newMsgs.length > 0){
            log.log('do message polling chat, we have new messages for polling', newMsgs);
            doFullUpdate = true;
          }
        }
        else{
          doFullUpdate = true;
        }

        return doFullUpdate;
      })
      .then((res) => {
        if(res){
          return Promise.all([
            this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true),
            this.props.updateNotifications(),
            this.props.refreshDocs(workspace.forum_id, workspace.host_uid)
          ])
        }
      })
  }

  refreshMessages(useDataDate) {
    let {workspace} = this.props;

    return Promise.all([
      this.props.threadAction.refreshActiveThreadMessages(useDataDate),
      this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true),
      this.props.updateNotifications(),
      this.props.refreshDocs(workspace.forum_id, workspace.host_uid)
    ])
  }

  sendMessageWithDocs(pendingMsg, pendingFiles){
    let {workspace, thread, t} = this.props;

    let pdfSubmitFiles = [];
    let docAttachFiles = [];
    let sigRequestFiles = [];
    let nonSigRequestFiles = [];
    let invoiceFiles = [];
    _.each(pendingFiles, (f) => {
      if(f.isInvoice){
        invoiceFiles.push(f);
      }
      else if(f.isPDFXHRUpload){
        pdfSubmitFiles.push(f);
      }
      else if(f.is_doc_copy){
        docAttachFiles.push(f);
      }
      else{
        if(f.signature_requested){
          sigRequestFiles.push(f);
        }
        else{
          nonSigRequestFiles.push(f);
        }
      }
    })

    let queuePromise = Promise.resolve(true);
    let msgSent = false;
    if(invoiceFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadInvoiceTransaction(docAttachTransaction, workspace.forum_id, workspace.host_uid, thread.chat_id, invoiceFiles, msgSent ? null : pendingMsg);
      this.props.queueThreadInvoice(docAttachTransaction, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, invoiceFiles, msgSent ? null : pendingMsg)
      msgSent = true;
    }
    if(nonSigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocTransaction(transaction_id, null, workspace.forum_id, workspace.host_uid, thread.chat_id, upload_id, nonSigRequestFiles, pendingMsg);
      this.props.queueChatUpload(transaction_id, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, false, nonSigRequestFiles, pendingMsg);
      msgSent = true;
    }
    if(sigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocTransaction(transaction_id, null, workspace.forum_id, workspace.host_uid, thread.chat_id, upload_id, sigRequestFiles, msgSent ? null : pendingMsg);
      this.props.queueChatUpload(transaction_id, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, true, sigRequestFiles, msgSent ? null : pendingMsg);
      msgSent = true;
    }
    if(docAttachFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocAttachTransaction(docAttachTransaction, workspace.forum_id, workspace.host_uid, thread.chat_id, docAttachFiles, msgSent ? null : pendingMsg);
      this.props.queueThreadDocAttach(docAttachTransaction, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, docAttachFiles, msgSent ? null : pendingMsg)
      msgSent = true;
    }
    if(pdfSubmitFiles.length > 0){
      const queuePdfSubmit = (pdfSubmit) => {
        let pdfSubmitTxn = _.uniqueId('vf-transaction-id');
        let upload_id = _.uniqueId('vf-upload-id-');
        this.props.queueThreadDocTransaction(pdfSubmitTxn, null, workspace.forum_id, workspace.host_uid, thread.chat_id, upload_id, [pdfSubmit], msgSent ? null : pendingMsg);
        //transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, mesg, filename, pdfData, last_forum_id
        this.props.uploadActions.queuePDFSubmitUpload(pdfSubmitTxn, upload_id, workspace.forum_id, workspace.host_uid, null, thread.chat_id, pdfSubmit.doc_id, null, pdfSubmit.signature_requested, msgSent ? null : pendingMsg, pdfSubmit.filename, pdfSubmit.data, null);
      }

      //If there's only one pdf submit, and we haven't attached a message yet, attach it as part of the
      //submit call, so the file is attached to the message.
      //Otherwise, just send it attached to nothing, and queue the pdf submit
      //Otherwise, just send all pdfs, one at a time.
      if(pdfSubmitFiles.length === 1 && !msgSent){
        queuePdfSubmit(pdfSubmitFiles[0])
        msgSent = true;
      }
      else if(!msgSent && (pendingMsg && pendingMsg.length > 0)){
        queuePromise = new Promise((resolve, reject) => {
          this.sendMessageForPdfSubmit(pendingMsg)
            .then((res) => {
              msgSent = true;
              _.each(pdfSubmitFiles, (pdfSubmit) => {
                queuePdfSubmit(pdfSubmit);
              })
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            })
        })
      }
      else{
        _.each(pdfSubmitFiles, (pdfSubmit) => {
          queuePdfSubmit(pdfSubmit);
        })
      }
    }

    return queuePromise
      .then((res) => {
        this.state.footerRef.clear();
        return this.refreshMessages()
          .then(() => {
            PendingMsgCache.clearThread(thread.chat_id)
            setTimeout(() => {
              this.state.chatMessagesRef.scrollToBottom(250);
            })
          })
          .catch((err) => {
            log.log('error during message refresh', err);
            this.state.footerRef.setNotSending();
            this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
          })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
  }

  sendMessageForPdfSubmit(pendingMsg){

    return new Promise((resolve, reject) => {
      let {workspace, thread, t} = this.props;
      let transaction_id = _.uniqueId('vf-transaction-id');
      this.props.queueThreadMsgTransaction(transaction_id, workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg);
      sapi.Threads.sendMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg)
        .then((res) => {
          log.log('send message res', res);

          this.props.mapTransactionResult(transaction_id, res);
          resolve(res);
        })
        .catch((err) => {
          log.log('error during message send', err);
          this.props.mapTransactionResult(transaction_id, null);
          this.state.footerRef.setNotSending();
          this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
          reject(err);
        })
    })
  }

  sendMessage(pendingMsg, pendingFiles) {
    let {workspace, thread, t} = this.props;

    pendingMsg = msgHelper.trimWhitespace(pendingMsg);

    if(pendingFiles.length > 0){
      this.sendMessageWithDocs(pendingMsg, pendingFiles)
        .catch((err) => {
          log.log('error during chatpanel message sendwdocs', err);
        })
      return;
    }

    let transaction_id = _.uniqueId('vf-transaction-id');

    this.props.queueThreadMsgTransaction(transaction_id, workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg);
    this.setState({sendingMsg: true})
    sapi.Threads.sendMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg)
      .then((res) => {
        log.log('send message res', res);

        this.props.mapTransactionResult(transaction_id, res);
        this.state.footerRef.clear();

        return this.refreshMessages()
      })
      .then(() => {
        PendingMsgCache.clearThread(thread.chat_id)
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.props.mapTransactionResult(transaction_id, null);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({sendingMsg: false})
      })
  }

  cancelSignatureRequestClick(msg, doc){
      let { t } = this.props;
      this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to cancel this signature request?"), (res) => {
        if(res){
          let sign_request_id = _.get(doc, 'sign_request_id');
          sapi.Workspace.deleteSignatureRequest(sign_request_id)
            .then(() => {
              return Promise.all([
                this.props.updateAccountInfoForLimits(),
                this.refreshMessages()
              ])
            })
            .catch((err) => {
              log.error('error deleting signature request', err);
              this.props.showAlert(t("Unable to delete signature request"), getMessageForError(err, t));
            })
        }
      }, t('Yes'), t('No'))
  }

  fulfillThreadSignatureRequest(mesg_id, doc_id, signatureRequest, sign_request_id){
    let {workspace, threadParticipantLookup, thread, accountInfoGuest, t} = this.props;

    Promise.all([
        sapi.Threads.docInfo(workspace.forum_id, workspace.host_uid, doc_id),
        sapi.Docs.mark(workspace.forum_id, workspace.host_uid, doc_id, false)
      ])
      .then((res) => {
        log.log('signature request load', res, signatureRequest, sign_request_id);

        let fakeDocStatus = {
          doc_id,
          sign_request_id,
          label : _.get(res[0], 'data.label'),
          signer_info : _.map(signatureRequest, (r) => {
            return ({
              sign_status : r.signed_date ? SignatureRequest.SIGN_STATUS.SIGNED : SignatureRequest.SIGN_STATUS.REQUESTED,
              signer_uid : r.signer_uid
            })
          })
        }
        let sigRequest = SignatureRequest.newThreadSignatureRequest(
          workspace.forum_id,
          workspace.host_uid,
          doc_id,
          thread.chat_id,
          mesg_id,
          fakeDocStatus
        )

        let host = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === workspace.host_uid});
        let guestInfos = [];
        _.each(fakeDocStatus.signer_info, (info) => {
          let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === info.signer_uid});
          if(guest){
            guestInfos.push(guest);
          }
          else if(info.signer_uid === accountInfoGuest.guest_uid){
            guestInfos.push(accountInfoGuest);
          }
        })

        sigRequest.setSigners(fakeDocStatus.signer_info, guestInfos);
        sigRequest.setRequestor(host);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(signatureRequest);
        this.props.showFulfillSignatureRequest(thread, null, sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }

  fulfillSignatureRequest(msg, doc){
    let {workspace, threadParticipantLookup, thread, accountInfoGuest, t} = this.props;
    let sigRequest = SignatureRequest.newThreadSignatureRequest(
      workspace.forum_id,
      workspace.host_uid,
      doc.doc_id,
      thread.chat_id,
      msg.mesg_id,
      doc
    )

    Promise.all([
        sapi.Threads.docInfo(workspace.forum_id, workspace.host_uid, doc.doc_id),
        sapi.Workspace.getSignatureRequest(doc.sign_request_id, workspace.host_uid),
        sapi.Docs.mark(workspace.forum_id, workspace.host_uid, doc.doc_id, false)
      ])
      .then((res) => {

        let guestInfos = [];
        _.each(doc.signer_info, (info) => {
          let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === info.signer_uid});
          if(guest){
            guestInfos.push(guest);
          }
          else if(info.signer_uid === accountInfoGuest.guest_uid){
            guestInfos.push(accountInfoGuest);
          }
        })

        sigRequest.setSigners(doc.signer_info, guestInfos);

        let host = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === workspace.host_uid});

        sigRequest.setRequestor(host);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(msgHelper.formatSigningRequestForLegacyIfNeeded(res[1].data));

        log.log('chat panel fulfilling request', sigRequest);
        this.props.showFulfillSignatureRequest(thread, null, sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }

  warnNonProUsersAboutSigning(){
    return new Promise((resolve, reject) => {
      let {
        accountInfo,
        accountClassInfo,
        t
      } = this.props;

      log.log('warnNonProUsersAboutSigning', accountInfo, accountClassInfo);
      let sign_req_max = _.get(accountClassInfo, 'class_info.sign_req_max', null);

      if (_.isNumber(sign_req_max) && accountInfo.sign_req_count > 0) {
        //show a trial message
        this.props.showAlert(t("Trial Signing"),
          t("This signing request is on us! You have ") + accountInfo.sign_req_count + (accountInfo.sign_req_count === 1 ? t(" free signing request left, including this one.") : t(" free signing requests left, including this one.")),
          () => {
            resolve(true);
          });
        return;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count = 0:
      // the user has run out of free trial credits; show upgrade dialog
      if(_.isNumber(sign_req_max) && accountInfo.sign_req_count <= 0){
        // warn user
        // show upgrade dialog
        this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.MAX_V2_SIGNING_LIMIT, null, this.props.t, (res) => {
          log.log('showUpgradeDialogOrError res', res);
        })
        resolve(false);
        return;
      }

      resolve(true);
    })
  }

  completeSignatureRequest(msg, docStatus){
    this.warnNonProUsersAboutSigning()
      .then((proceed) => {
        if(!proceed){
          return;
        }

        let { workspace, threadParticipantLookup, thread, accountInfoGuest, t } = this.props;

        let sigRequest = SignatureRequest.newThreadSignatureRequest(
          workspace.forum_id,
          workspace.host_uid,
          docStatus.doc_id,
          thread.chat_id,
          msg.mesg_id,
          docStatus
        );

        let participants = _.filter(this.props.threadParticipantLookup[thread.chat_id], (user) => {
          return user.guest_uid !== accountInfoGuest.guest_uid;
        })

        if(participants.length === 0){
          this.props.showAlert(t('No Guests in Thread'), t("There are no Guests in this Thread.  To request a signature, please add a Guest first."));
          return;
        }

        log.log('showing request sig window', sigRequest, msg, docStatus);
        this.props.showRequestSignatureWindow(
          workspace.forum_id,
          thread.chat_id,
          msg.mesg_id,
          docStatus.doc_id,
          docStatus.label,
          participants,
          null,
          null,
          true,
          (res) => {
            log.log('request signature form closed', res);
            if(res){

              //No further data has been saved at this point, the signature request is still incomplete.
              Promise.all([
                  sapi.Threads.docInfo(sigRequest.forum_id, sigRequest.host_uid, sigRequest.doc_id),
                ])
                .then((loadRes) => {
                  let guestInfos = [];
                  _.each(res, (guestRes) => {
                    let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === guestRes.guest_uid});
                    if(guest){
                      guestInfos.push(guest);
                    }
                  })

                  sigRequest.setUseCancelLanguage(true);
                  sigRequest.setSigners(res, guestInfos);
                  sigRequest.setDocInfo(loadRes[0].data);

                  this.props.completeSignatureRequest(thread, null, sigRequest, (res) => {
                    log.log('pdf signature request window closed', res);

                    this.refreshMessages();
                  })
                })
                .catch((err) => {
                  log.error('error loading signature request data', err);
                  this.props.showAlert(t('Error loading signature request data'), getMessageForError(err, t));
                })
            }
          }
        )
      })
  }

  chatFileDrop(files) {
    this.state.footerRef.docFileDrop(files);
  }

  onDocAttach(docs){
    this.state.footerRef.addCopyDocs(docs);
  }

  onThreadDocClick(doc){
    this.state.docsPanelRef.selectDocAndNavigate(doc);
  }

  selectDocAndNavigate(doc, thread){
    this.state.docsPanelRef.selectDocAndNavigate(doc, thread);
  }

  onPdfSubmit(res){
    this.props.onPdfSubmit(res);
  }

  onAttachDocToThread(res){
    this.props.onAttachDocToThread(res);
  }

  doBeforePrint(){
    return this.state.chatMessagesRef.doPrintPreview()
  }

  onPrintError(err){
    log.error("error printing chat", err);
    this.state.chatMessagesRef.cleanupPrintPreview();
  }

  doAfterPrintPreview(){
    this.state.chatMessagesRef.cleanupPrintPreview();
  }

  getPrintPreviewContents(){
    return this.state.chatMessagesRef.printPreviewRef.printRef.current
  }

  findDocInfo(doc_id){
    let { docs } = this.props;

    let found = null;
    _.each(docs, (chatBlock) => {
      _.each(chatBlock.docs, (doc) => {
        if(doc.doc_id === doc_id){
          found = doc;
          return false;
        }
      })
      if(found){
        return false;
      }
    })

    return found;
  }

  onMesgHistoryClick(msg) {
    let { thread, activeThreadMesgEditFlag } = this.props;

    let fetchMessageFn = (mesg_id) => {
      let { activeThreadMessageBlocks } = this.props;
      let found = null;
      _.each(activeThreadMessageBlocks, (block) => {
        found = _.find(block.blockList, (b) => b.mesg_id === mesg_id);
        if(found){
          return false;
        }
      })
      return found;
    }

    let refreshMsgFn = () => {
      return this.refreshMessages();
    }

    this.props.showThreadMessageHistory(thread, msg.mesg_id, activeThreadMesgEditFlag, fetchMessageFn, refreshMsgFn)
  }

  onMesgEditClick (msg) {
    let { thread, workspace } = this.props;

    this.props.showEditThreadMessage(workspace, thread, msg, (res) => {
      if(res){
        this.refreshMessages();
      }
    })
  }

  renderChatFooter(){
    let {
      workspace,
      thread,
      t
    } = this.props;

    if(_.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT){
      return (
        <div className="text-center light-color" style={{padding: '30px', fontSize: '14px'}}>
          {t("Messaging in Content Workspaces has been disabled.")}
        </div>
      )
    }

    let isSigningAllowed = !(_.get(workspace, 'host_uid', null));
    return (
      <ChatPanelFooter thread={thread}
                       onRef={(ref) => this.setState({footerRef : ref})}
                       sendMessage={this.sendMessage.bind(this)}
                       signingIsAllowed={isSigningAllowed} />
    )
  }

  render() {
    let {
      workspace,
      thread,
      threadParticipantLookup,
      threadDocs,
      messageBlocksId,
      activeThreadMessageBlocks,
      activeThreadMesgEditFlag
    } = this.props;

    let docNotifyCount = 0;
    _.each(threadDocs, (doc) => {
      if(doc.notify_flag) docNotifyCount++
    })

    let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
    let threadGuests = threadParticipantLookup ? (threadParticipantLookup[thread.chat_id] || []) : [];
    return (
      <UploadHelper onDrop={this.chatFileDrop.bind(this)}
                    disabled={isContentWorkspace}
                    generateImagePreviews={true}
                    allowMultiple={true}
                    disableClick={true}>
        <div className="center-col d-flex flex-column">

          <div>
            <ChatPanelHeader thread={thread}
                             onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                             onBeforePrintPreview={this.doBeforePrint.bind(this)}
                             onPrintPreviewError={this.onPrintError.bind(this)}
                             getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                             sendInvoiceClick={() => this.props.onSendInvoiceClick(thread)}
                             guests={threadGuests}/>
          </div>

          <div className="flex-grow-1" style={styles.messagePanelFlex}>
            <div className="row mx-0 height-100">
              <div className="col-7 height-100 pl-2 pr-0">
                <div className="d-flex flex-column" style={styles.messagePanelFlex}>
                  <div className="flex-grow-1" style={styles.messageContents}>
                    <ChatPanelMessages thread={thread}
                                       mesg_edit_flag={activeThreadMesgEditFlag}
                                       messageBlocksId={messageBlocksId}
                                       messageBlocks={activeThreadMessageBlocks}
                                       refreshMessagesForPolling={this.doMessagePolling.bind(this)}
                                       docClick={this.onThreadDocClick.bind(this)}
                                       onUserInteractionScroll={(evt) => this.props.onMessagePanelScroll(evt)}
                                       onRef={(ref) => {
                                         this.setState({chatMessagesRef: ref})
                                       }}
                                       onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                       onPdfSubmit={this.onPdfSubmit.bind(this)}
                                       onMesgEditClick={this.onMesgEditClick.bind(this)}
                                       onMesgHistoryClick={this.onMesgHistoryClick.bind(this)}
                                       findDocInfo={this.findDocInfo.bind(this)}
                                       fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                       cancelSignatureRequestClick={this.cancelSignatureRequestClick.bind(this)}
                                       completeSignatureRequestClick={this.completeSignatureRequest.bind(this)} />
                  </div>
                  <div>
                    {this.renderChatFooter()}
                  </div>
                </div>
              </div>
              <div className="col-5 height-100 pl-2 pr-2 pb-2">
                <div id="attached-docs-col" className="scrollable-col" style={styles.docsPanelContents}>
                  <AttachedDocsPanel docs={threadDocs}
                                     workspace={workspace}
                                     thread={thread}
                                     onRef={(ref) => {
                                       this.setState({docsPanelRef: ref})
                                     }}
                                     fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                     onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                     onPdfSubmit={this.onPdfSubmit.bind(this)}
                                     docNotifyCount={docNotifyCount || 0}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UploadHelper>
    )
  }
}

const styles = {
  messagePanelFlex: {
    height: '100%',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: colors.PRIMARY,
    borderBottomLeftRadius: '5px'
  },
  messageContents: {
    backgroundColor: colors.LIGHT,
    padding: '0px 5px',
    borderRadius: '5px',
    height: '0%', //I don't know why this works, but any other value screws things up in safari
  },
  docsPanelContents: {
    backgroundColor: colors.LIGHT,
    borderRadius: '5px',
    height: '100%',
    overflowY: 'auto'
  }
}

ChatPanel.propTypes = {
  thread: PropTypes.object,
  threadDocs: PropTypes.array,
  onRef : PropTypes.func.isRequired,
  onAttachDocToThread : PropTypes.func.isRequired,
  onSendInvoiceClick : PropTypes.func.isRequired,
  onPdfSubmit : PropTypes.func.isRequired,
  onMessagePanelScroll : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    docs : state.workspace.docs,
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    accountInfo : state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,
    accountInfoGuest : state.shared.accountInfoGuest,
    activeThreadMessageBlocks : state.thread.activeThreadMessageBlocks,
    messageBlocksId : state.thread.messageBlocksId,
    activeThreadMesgEditFlag : state.thread.activeThreadMesgEditFlag,
    activeThreadDataDate: state.thread.activeThreadDataDate,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadActions : {
      queuePDFSubmitUpload: (transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, signature_requested, mesg, filename, pdfData, last_forum_id) => dispatch(uploadActions.queuePDFSubmitUpload(transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, signature_requested, mesg, filename, pdfData, last_forum_id))
    },
    queueChatUpload: (transaction_id, upload_id, forum_id, host_uid, chat_id, signature_requested, files, mesg) => dispatch(uploadActions.queueChatUpload(transaction_id, upload_id, forum_id, host_uid, chat_id, signature_requested, files, mesg)),
    queueThreadDocAttach: (transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(uploadActions.queueThreadDocAttach(transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg)),
    queueThreadInvoice: (transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(uploadActions.queueThreadInvoice(transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg)),

    ...sharedActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    threadAction : {...threadActions.mapToDispatch(dispatch)}
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPanel)));
