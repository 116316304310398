import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Button from '../partials/elements/Button';
import WorkspaceBadge from "../partials/badges/WorkspaceBadge";
import classnames from "classnames";
import UserBadge from "../partials/badges/UserBadge";
import ThreadBadge from "../partials/badges/ThreadBadge";
import DocBadge from "../partials/badges/DocBadge";
import _ from "lodash";
import colors from "../../util/colors";
import log from "../../util/log";
import searchHelper from "../../helpers/search-helper";
import {withVFTranslation} from "../../util/withVFTranslation";

class SearchWindow extends Component {

  searchInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      searchString : props.modalProps.searchString,
      guestResults : [],
      threadResults: [],
      docResults: [],
      workspaceResults: [],
      contactResults: []
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    this.searchInputRef.current.focus();

    this.updateSearchResults();
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    this.props.close({
      searchString : this.state.searchString,
      item : null
    });
  }

  onSearchChange(evt){
    this.setState({searchString: evt.target.value}, () => {
      this.updateSearchResults();
    });
  }

  updateSearchResults(){
    let { context, workspace, workspaces, directMessages, guests, threads, docs } = this.props.modalProps;
    let { searchString } = this.state;

    if(context === SearchWindow.SEARCH_CONTEXT.HOME){
      let results = [];
      _.each(workspaces, (ws) => {
        let tokens = [ws.label];
        let found = searchHelper.findInTokens(searchString, tokens);
        if(found){
          results.push(ws);
        }
      })
      this.setState({workspaceResults : results})
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.WORKSPACE){
      let guestResults = [];
      let threadResults = [];
      let docResults = [];
      _.each(guests, (guest) => {
        let tokens = [guest.first_name, guest.last_name, guest.email_address];
        let found = searchHelper.findInTokens(searchString, tokens);
        if(found){
          guestResults.push(guest);
        }
      })
      _.each(threads, (thread) => {
        let tokens = [thread.label];
        let found = searchHelper.findInTokens(searchString, tokens);
        if(found){
          threadResults.push(thread);
        }
      })
      _.each(docs, (chatBlock) => {
        _.each(chatBlock.docs, (doc) => {
          let foundThread = this.findThreadForDoc(doc);
          let tokens = [doc.label];
          if(foundThread){
            tokens.push(foundThread.label)
          }
          let found = searchHelper.findInTokens(searchString, tokens);
          if(found){
            docResults.push(doc);
          }
        })
      })
      this.setState({
        guestResults,
        threadResults,
        docResults
      })
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.CONTACTS){
      let results = [];
      _.each(directMessages, (guest) => {
        let tokens = [guest.first_name, guest.last_name, guest.email_address];
        let found = searchHelper.findInTokens(searchString, tokens);
        if(found){
          results.push(guest);
        }
      })
      this.setState({contactResults : results})
    }
  }

  getModalHeader(){
    let { t } = this.props;
    let { context, workspace } = this.props.modalProps;

    if(context === SearchWindow.SEARCH_CONTEXT.HOME){
      return (
        <Fragment>
          <i className="icon icomoon-workspace align-baseline mr-3" />
          {t("Workspaces")}
        </Fragment>
      )
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.WORKSPACE){
      return (
        <Fragment>
          <div className="d-inline-block" style={{marginRight: '10px'}}>
            <WorkspaceBadge forum={workspace} />
          </div>
          {workspace.label}
        </Fragment>
      )
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.CONTACTS){
      return (
        <Fragment>
          <i className="icon ion-android-person align-baseline mr-3" />
          {t("Contacts")}
        </Fragment>
      )
    }
  }

  getSearchPlaceholderText(){
    let { t } = this.props;
    let { context, workspace } = this.props.modalProps;

    if(context === SearchWindow.SEARCH_CONTEXT.HOME){
      return t("Type to Search Workspaces...");
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.WORKSPACE){
      return t("Type to Search this Workspace...");
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.CONTACTS){
      return t("Type to Search Contacts...");
    }
  }

  findThreadForDoc(doc){
    let { docs, threads } = this.props.modalProps;

    let foundThread = null;
    _.each(docs, (chatBlock) => {
      _.each(chatBlock.docs, (d) => {
        if(d.doc_id === doc.doc_id){
          foundThread = _.find(threads, (t) => t.chat_id === chatBlock.chat_id);
          return false;
        }
      })

      if(foundThread){
        return false;
      }
    })
    return foundThread;
  }

  selectWorkspace(ws){
    this.props.close({
      searchString : this.state.searchString,
      item : ws
    });
  }

  selectGuest(guest){
    this.props.close({
      searchString : this.state.searchString,
      item : guest
    });
  }

  selectThread(thread){
    this.props.close({
      searchString : this.state.searchString,
      item : thread
    });
  }

  selectDoc(doc, thread){
    this.props.close({
      searchString : this.state.searchString,
      item : doc,
      thread
    });
  }

  selectContact(contact){
    this.props.close({
      searchString : this.state.searchString,
      item : contact
    });
  }

  renderSearchResults(){
    let { accountInfo, t } = this.props;
    let { context, workspace } = this.props.modalProps;
    let {
      workspaceResults,
      guestResults,
      threadResults,
      docResults,
      contactResults
    } = this.state;

    if(context === SearchWindow.SEARCH_CONTEXT.HOME){
      return (
        <Fragment>
          {workspaceResults.length === 0 &&
          <p className="text-center secondary-text-color font-italic mt-5">
            {t("-No Matches -")}
          </p>
          }
          {_.map(workspaceResults, (ws) => {
            return (
              <div key={ws.forum_id}
                   onClick={this.selectWorkspace.bind(this, ws)}
                   className="d-flex flex-row workspace-row pt-2 pb-2">
                <div style={{marginRight : '10px'}}>
                  <WorkspaceBadge forum={ws} />
                </div>
                <div>
                  <p className={classnames('workspace-label mb-0')}>
                    {ws.label}
                  </p>
                  {ws.host_uid &&
                  <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
                    {t("Host:")} {ws.first_name} {ws.last_name}
                  </p>
                  }
                  {!ws.host_uid &&
                  <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
                    {t("Host:")} {accountInfo.first_name} {accountInfo.last_name} {t("(You)")}
                  </p>
                  }
                </div>
              </div>
            )
          })}
        </Fragment>
      )
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.WORKSPACE){
      return (
        <Fragment>
          {!workspace.host_uid &&
          <div>
            <h3>
              <i className="icon ion-android-person align-baseline mr-3"/>
              {t("Guests")}
            </h3>
            {guestResults.length === 0 &&
            <p className="text-center secondary-text-color font-italic my-3">
              {t("-No Matches -")}
            </p>
            }
            {_.map(guestResults, (guest) => {
              return (
                <div key={guest.guest_uid}
                     onClick={this.selectGuest.bind(this, guest)}
                     className="d-flex flex-row workspace-row py-2 pl-3">
                  <div style={{marginRight : '10px'}}>
                    <UserBadge guest={guest} />
                  </div>
                  <div>
                    <p className={classnames('workspace-label mb-0')}>
                      {guest.first_name} {guest.last_name}
                    </p>
                    <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
                      {guest.email_address}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
          }
          <div>
            <h3>
              <i className="icon ion-chatbox align-baseline mr-3"/>
              {t("Threads")}
            </h3>
            {threadResults.length === 0 &&
            <p className="text-center secondary-text-color font-italic my-3">
              {t("-No Matches -")}
            </p>
            }
            {_.map(threadResults, (thread) => {
              return (
                <div key={thread.chat_id}
                     onClick={this.selectThread.bind(this, thread)}
                     className="d-flex flex-row workspace-row py-2 pl-3">
                  <div className="ml-1">
                    <p className={classnames('workspace-label mb-0')}>
                      {thread.label}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <h3>
              <i className="icon ion-document-text align-baseline mr-3"/>
              {t("Documents")}
            </h3>
            {docResults.length === 0 &&
            <p className="text-center secondary-text-color font-italic my-3">
              {t("-No Matches -")}
            </p>
            }
            {_.map(docResults, (doc, index) => {
              let thread = this.findThreadForDoc(doc);
              let generatedId = index + doc.doc_id;
              return (
                <div key={generatedId}
                     onClick={this.selectDoc.bind(this, doc, thread)}
                     className="d-flex flex-row workspace-row py-2 pl-3">
                  <div className="ml-1">
                    <p className={classnames('workspace-label mb-0')}>
                      {doc.label}
                    </p>
                    {thread &&
                    <p style={styles.secondaryText} className={'mb-0 secondary-text-color ml-2'}>
                      <i className="icon ion-chatbox align-baseline mr-2" />
                      {thread.label}
                    </p>
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </Fragment>
      )
    }
    else if(context === SearchWindow.SEARCH_CONTEXT.CONTACTS){
      return (
        <Fragment>
          {contactResults.length === 0 &&
          <p className="text-center secondary-text-color font-italic mt-5">
            {t("-No Matches -")}
          </p>
          }
          {_.map(contactResults, (contact) => {
            return (
              <div key={contact.guest_uid}
                   onClick={this.selectContact.bind(this, contact)}
                   className="d-flex flex-row workspace-row py-2 pl-3">
                <div style={{marginRight : '10px'}}>
                  <UserBadge guest={contact} />
                </div>
                <div>
                  <p className={classnames('workspace-label mb-0')}>
                    {contact.first_name} {contact.last_name}
                  </p>
                  <p style={styles.secondaryText} className={'mb-0 secondary-text-color'}>
                    {contact.email_address}
                  </p>
                </div>
              </div>
            )
          })}
        </Fragment>
      )
    }
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t } = this.props;
    let {searchString} = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">
            {this.getModalHeader()}
          </h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-header py-0">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" style={styles.searchInputAddon}>
                <i className="icon ion-ios-search-strong"/>
              </div>
            </div>
            <input className="form-control no-focus"
                   style={{borderLeft : 'none'}}
                   ref={this.searchInputRef}
                   type={'search'}
                   autoComplete="off"
                   autoCorrect="off"
                   autoCapitalize="off"
                   spellCheck="false"
                   value={searchString}
                   onChange={this.onSearchChange.bind(this)}
                   placeholder={this.getSearchPlaceholderText()} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row my-2" style={{minHeight: '300px'}}>
            <div className="col">
              {this.renderSearchResults()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SearchWindow.MODAL_LARGE = true

SearchWindow.SEARCH_CONTEXT = {
  WORKSPACE : 'workspace',
  HOME : 'home',
  CONTACTS : 'contacts'
}

const styles = {
  secondaryText : {
    fontSize: '12px',
    lineHeight: '14px',
    height : '15px'
  },
  searchWrap : {
    marginTop : '15px'
  },
  searchInputAddon : {
    paddingRight : '2px',
    backgroundColor: colors.LIGHT
  },
}

const mapStateToProps = (state) => {
  return {
    accountInfo : state.shared.accountInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

SearchWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SearchWindow));
