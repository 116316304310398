import c from '../util/const';
import sapi from "../util/sapi";
import log from "../util/log";

import Promise from 'bluebird';
import _ from "lodash";
import msgHelper from "../helpers/msg-helper";
import threadActions from "./thread-actions";
import sharedActions from "./shared-actions";

const homeActions = {

  startup(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let allStartupCallsFinished = _.get(getState(), 'shared.allStartupCallsFinished');
        let firstStartupCallsFinished = _.get(getState(), 'shared.firstStartupCallsFinished');

        //If startup calls haven't been made, do them now.
        //Otherwise, just refresh what you need to.
        if(!firstStartupCallsFinished || !allStartupCallsFinished){
          resolve(dispatch(sharedActions.startup()));
        }
        else{

          //Don't wait for logo, bounce it out a bit so other things finish first.
          setTimeout(() => {
            dispatch(sharedActions.updateLogo());
          }, 500);

          resolve(Promise.all([
            dispatch(sharedActions.updateDirectMessages()),
            dispatch(sharedActions.updateWorkspaces()),
            dispatch(sharedActions.updateNotifications()),
          ]))
        }
      })
    }

  },

  updateWorkspaceLists(workspaces){
    let unarchivedWorkspaces = [];
    let archivedWorkspaces = [];
    _.each(workspaces, (ws) => {
      if(ws.hide_flag){
        archivedWorkspaces.push(ws);
      }
      else{
        unarchivedWorkspaces.push(ws);
      }
    })
  
    return {
      type: c.actions.home.updateWorkspaceLists,
      archivedWorkspaces,
      unarchivedWorkspaces
    };
  },
  
  setActiveDM(dm){
    return (dispatch) => {
      return dispatch(threadActions.initDM(dm));
    }
  },
  
  cleanup(){
    return {
      type : c.actions.home.cleanup
    }
  }
}

export default homeActions;
