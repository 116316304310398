import c from '../../../util/const'
import log from '../../../util/log'
import enums from '../../../util/enums'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import PdfPrintPreviewSvc from "./PdfPrintPreviewSvc";
import SignatureRequest from "../../../models/SignatureRequest";
import UpgradeDialogNew from "../../modals/UpgradeDialogNew";
import downloadActions from "../../../actions/download-actions";
import {FiSidebar} from 'react-icons/fi';
import PdfPreview from "./PdfPreview";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfPreviewHeader extends Component {
  static LOWEST_ZOOM = .5;
  static GREATEST_ZOOM = 1.9;
  static ScaleOptions = [
    {option: PdfPreviewHeader.LOWEST_ZOOM, display: '50%'},
    {option: .7, display: '70%'},
    {option: .9, display: '90%'},
    {option: 1, display: '100%'},
    {option: 1.1, display: '110%'},
    {option: 1.3, display: '130%'},
    {option: 1.5, display: '150%'},
    {option: PdfPreviewHeader.GREATEST_ZOOM, display: '190%'}
  ]

  constructor(props) {
    super(props);

    this.printPreviewRef = React.createRef();

    this.state = {
      isScrolling: false,
      pageInput: '1',
      previewContent: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.activePdfPageIndex !== this.props.activePdfPageIndex) {
      this.setState({
        pageInput: '' + (this.props.activePdfPageIndex + 1)
      })
    }
  }

  goPreviousPage(event) {

    if (this.state.isScrolling) {
      event.preventDefault();
      return;
    }

    if (this.props.activePdfPageIndex > 0) {
      this.setPage(this.props.activePdfPageIndex - 1)
    }
  }

  goNextPage(event) {

    if (this.state.isScrolling) {
      event.preventDefault();
      return;
    }

    let {pdf} = this.props;
    if(!pdf){
      return;
    }

    if ((this.props.activePdfPageIndex + 1) < pdf.numPages) {
      this.setPage(this.props.activePdfPageIndex + 1);
    }
  }

  setPage(index, preventAnimation) {
    let {setVisiblePage} = this.props;

    if (this.state.isScrolling) {
      return;
    }

    this.setState({
      isScrolling: true
    })
    let delay = setVisiblePage(index, preventAnimation);
    setTimeout(() => {
      this.setState({
        isScrolling: false
      })
    }, delay)
  }

  zoomIn() {
    let {scale, setScale} = this.props;

    let zoomIndex = null;
    _.each(PdfPreviewHeader.ScaleOptions, (option, index) => {
      if (option.option === scale) {
        zoomIndex = index;
      }
    })

    if (zoomIndex < PdfPreviewHeader.ScaleOptions.length) {
      let newScale = PdfPreviewHeader.ScaleOptions[zoomIndex + 1];
      setScale(newScale);
    }
  }

  zoomOut() {
    let {scale, setScale} = this.props;

    let zoomIndex = null;
    _.each(PdfPreviewHeader.ScaleOptions, (option, index) => {
      if (option.option === scale) {
        zoomIndex = index;
      }
    })

    if (zoomIndex > 0) {
      let newScale = PdfPreviewHeader.ScaleOptions[zoomIndex - 1];
      setScale(newScale);
    }
  }

  getZoomDisplayValue() {
    let {scale} = this.props;

    let display = null;
    _.each(PdfPreviewHeader.ScaleOptions, (option) => {
      if (option.option === scale) {
        display = option.display;
      }
    })
    return display;
  }

  doBeforePrint() {
    let {doBeforePrintPreview} = this.props;

    let ppRef = this.printPreviewRef;
    return doBeforePrintPreview()
      .then((res) => {
        return ppRef.loadAndRender(res);
      })
  }

  getDownloadHeaders() {
    let {doc_id, forum_id, host_uid} = this.props;

    let params = {
      doc_id,
      forum_id
    }

    if (host_uid) {
      params.host_uid = host_uid;
    }

    params[c.api.X_TOKEN] = sapi.getToken();

    return params;
  }

  getSignArchiveDownloadHeaders() {
    let {
      signArchiveRow
    } = this.props.pdfState;
    let params = {};

    if(signArchiveRow.host_uid){
      params.host_uid = signArchiveRow.host_uid;
    }
    if(signArchiveRow.sign_request_id){
      params.sign_request_id = signArchiveRow.sign_request_id;
    }
    if(signArchiveRow.label){
      params.label = signArchiveRow.label;
    }
    if(signArchiveRow.doc_key){
      params.doc_key = signArchiveRow.doc_key;
    }
    params[c.api.X_TOKEN] = sapi.getToken();
    return params;
  }

  downloadDoc() {
    let {
      isSignArchiveContext
    } = this.props;

    if(isSignArchiveContext){
      this.props.doDownload(this.getSignArchiveDownloadHeaders(), sapi.SignArchive.url('download'));
    }
    else{
      this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));
    }
  }

  onPrintError(err, err1, err2) {
    log.log('error printing', err, err1, err2);
  }

  isAllowedToDoSigningV1() {
    let {dm, thread, accountInfo, doc_info, t} = this.props;
    if (!dm && !thread) {
      this.props.showAlert(t('Unable to Sign Document'), t('Documents must be submitted to a Thread before it can be signed.'));
      return false;
    }

    if (c.isFreeTier(accountInfo.class_id) && !doc_info.signing_flag) {
      this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.SIGNING_V1, null, this.props.t, (res) => {
        log.log('showUpgradeDialogOrError res', res);
      })
      return false;
    }

    return true;
  }

  isAllowedToDoSigningV2() {

    //This resolves a promise because of the need to alert users of their trial count.
    //If we don't show that alert blocking, it gets buried in other alerts that potentially pop up.
    return new Promise((resolve, reject) => {
      let {dm, thread, host_uid, accountInfo, accountClassInfo, t} = this.props;
      if (!dm && !thread) {
        this.props.showAlert(t('Unable to Sign Document'), t('Documents must be submitted to a Thread before it can be signed.'));
        resolve(false);
        return;
      }

      if (thread && host_uid) {
        //Just FYI, we COULD potentially allow the user to go in and sign something if this
        //signature request is FOR them.  But this changes the purpose of the button.
        this.props.showAlert(t('Unable to Sign Document'), t('Guests are not allowed to request signatures.  To request a signature, you must the host of the Thread.'));
        resolve(false);
        return;
      }

      let sign_req_max = _.get(accountClassInfo, 'class_info.sign_req_max', null);
      // -if class/info:[user class_id] has null sign_req_max
      // the user has unlimited signing requests; do nothing
      if (!sign_req_max) {
        //no max on their account.  Allow signing without message.
        resolve(true);
        return;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count > 0:
      // the user has free request credits available; show free trial info message
      if (_.isNumber(sign_req_max) && accountInfo.sign_req_count > 0) {
        //show a trial message
        // allow check change
        this.props.showAlert(t("Trial Signing"),
          t("This signing request is on us! You have ") + accountInfo.sign_req_count + (accountInfo.sign_req_count === 1 ? t(" free signing request left, including this one.") : t(" free signing requests left, including this one.")),
          () => {
            resolve(true);
          });
        return;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count = 0:
      // the user has run out of free trial credits; show upgrade dialog
      if (_.isNumber(sign_req_max) && accountInfo.sign_req_count <= 0) {
        // DO NOT allow check change
        // show upgrade dialog
        this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.MAX_V2_SIGNING_LIMIT, null, t, (res) => {
          log.log('showUpgradeDialogOrError res', res);
        })
        resolve(false);
        return;
      }

      resolve(true);
    })
  }

  initiateV1Signing() {
    if (this.showPDFErrorIfNeeded()) {
      return;
    }

    if (!this.isAllowedToDoSigningV1()) {
      return;
    }

    this.props.initiateV1Signing();
  }

  initiateSignatureRequest() {
    if (this.showPDFErrorIfNeeded()) {
      return;
    }

    this.isAllowedToDoSigningV2()
      .then((res) => {
        if (res) {
          this.props.onInitiateSignatureRequest();
        }
      })
  }

  showPDFErrorIfNeeded() {
    let {isDisabled, pdfWriterLoadErr, pdfPreviewLoadErr, t} = this.props;

    if (pdfWriterLoadErr) {
      if (pdfPreviewLoadErr) {
        this.props.showAlert(t('Unable to Sign Document'),
          t("There was a problem loading this file, and because of this signing is disabled.  Saving this file in a PDF reader and re-uploading it may resolve the issue."));
        return true;
      }
      else {
        this.props.showAlert(t('Unable to Sign Document'),
          t("There was a problem loading this file.  You can still preview it, but signing is disabled.  Saving this file in a PDF reader and re-uploading it may resolve the issue."));
        return true;
      }
    }
  }

  render() {
    let {
      pdf,
      scale,
      doc_info,
      sharedCanvasRef,
      windowSigningState,
      isDisabled,
      showingThumbnails,
      activePdfPageIndex,
      isSignArchiveContext,
      workspace,
      isFulfillingSignatureRequest
    } = this.props;
    let { pageInput} = this.state;

    let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;

    //this is a hack...so that we can make pdf optional.
    //There's a tricky edge case with print preview and zooming where
    //we need to refresh the current pdf, but we can't unmount the header or we lose our reference
    //to the preview service in the middle of the update
    let pageCount = pdf ? pdf.numPages : 0;
    return (
      <div className="container-fluid">
        <PdfPrintPreviewSvc sharedCanvasRef={sharedCanvasRef}
                            onRef={ref => (this.printPreviewRef = ref)}/>
        <div className="row py-2 black-bg">
          <div className="col">
            <div className="text-center">
              <Button
                className={`${showingThumbnails ? 'active' : ''}` + " btn d-inline-block btn-outline-light float-left"}
                aria-pressed={showingThumbnails}
                style={PdfPreviewHeader.styles.headerBtn}
                disabled={isDisabled}
                onClick={() => this.props.toggleShowingThumbnails()}>
                <FiSidebar/>
              </Button>
              <div className="clearfix d-inline-block"/>
              <Button className="btn d-inline-block btn-outline-light"
                      style={PdfPreviewHeader.styles.headerBtn}
                      disabled={isDisabled || scale === PdfPreviewHeader.LOWEST_ZOOM}
                      onClick={this.zoomOut.bind(this)}>
                <i className="icon ion-minus"/>
              </Button>
              <p className="mx-2 mb-0 d-inline-block align-middle light-color">
                {this.getZoomDisplayValue()}
              </p>
              <Button className="btn d-inline-block btn-outline-light"
                      style={PdfPreviewHeader.styles.headerBtn}
                      disabled={isDisabled || scale === PdfPreviewHeader.GREATEST_ZOOM}
                      onClick={this.zoomIn.bind(this)}>
                <i className="icon ion-plus"/>
              </Button>
            </div>
          </div>
          <div className="col text-center">
            <Button className="btn d-inline-block btn-outline-light"
                    disabled={isDisabled || activePdfPageIndex <= 0}
                    style={PdfPreviewHeader.styles.headerBtn}
                    onClick={this.goPreviousPage.bind(this)}>
              <i className="icon ion-arrow-left-a"/>
            </Button>
            <p className="mx-2 mb-0 d-inline-block align-middle light-color">
              {pageInput} / {pageCount}
            </p>
            <Button className="btn btn-outline-light d-inline-block"
                    disabled={isDisabled || activePdfPageIndex >= (pageCount - 1)}
                    style={PdfPreviewHeader.styles.headerBtn}
                    onClick={this.goNextPage.bind(this)}>
              <i className="icon ion-arrow-right-a"/>
            </Button>
          </div>
          <div className="col text-center">
            <Button className="btn btn-outline-light d-inline-block mr-1"
                    onClick={this.downloadDoc.bind(this)}
                    disabled={isDisabled || isFulfillingSignatureRequest}
                    style={PdfPreviewHeader.styles.headerBtn}>
              <i className="icon ion-android-download"/>
            </Button>
            <ReactToPrint onBeforeGetContent={this.doBeforePrint.bind(this)}
                          onPrintError={this.onPrintError.bind(this)}
                          removeAfterPrint={false}
                          copyStyles={false}
                          content={() => this.printPreviewRef.printRef.current}
                          trigger={() => {
                            return (
                              <Button className="btn btn-outline-light d-inline-block mr-1"
                                      disabled={isDisabled || isFulfillingSignatureRequest}
                                      style={PdfPreviewHeader.styles.headerBtn}>
                                <i className="icon ion-printer"/>
                              </Button>
                            )
                          }}/>
            {!isSignArchiveContext &&
            <Button className="btn btn-outline-light d-inline-block mr-1"
                    style={PdfPreviewHeader.styles.headerBtn}
                    disabled={!doc_info.previewable_flag || isDisabled || windowSigningState !== enums.WINDOW_SIGNING_STATUS.NONE || isContentWorkspace}
                    onClick={this.initiateV1Signing.bind(this)}>
              <i className="icon ion-edit"/>
            </Button>
            }
            {!isSignArchiveContext &&
            <Button className="btn btn-outline-light d-inline-block"
                    style={PdfPreviewHeader.styles.headerBtn}
                    disabled={!doc_info.previewable_flag || isDisabled || windowSigningState !== enums.WINDOW_SIGNING_STATUS.NONE || isContentWorkspace}
                    onClick={this.initiateSignatureRequest.bind(this)}>
              <i className="icon ion-bookmark"/>
            </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

PdfPreviewHeader.styles = {
  input: {
    maxWidth: '50px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  headerBtn: {
    minWidth: '41px'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    pdfState : {...state.pdfPreview},
    workspace : state.workspace.workspace,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

PdfPreviewHeader.propTypes = {
  forum_id: PropTypes.string,
  host_uid: PropTypes.string,
  doc_id: PropTypes.string,
  setVisiblePage: PropTypes.func.isRequired,
  setScale: PropTypes.func.isRequired,
  activePdfPageIndex: PropTypes.number.isRequired,
  pdf: PropTypes.object,
  scale: PropTypes.number.isRequired,
  sharedCanvasRef: PropTypes.object.isRequired,
  initiateV1Signing: PropTypes.func.isRequired,
  doBeforePrintPreview: PropTypes.func.isRequired,
  onInitiateSignatureRequest: PropTypes.func.isRequired,
  windowSigningState: PropTypes.string.isRequired,
  isSignArchiveContext: PropTypes.bool,
  dm: PropTypes.object,
  thread: PropTypes.object,
  doc_info: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFulfillingSignatureRequest : PropTypes.bool,
  pdfWriterLoadErr: PropTypes.object,
  pdfPreviewLoadErr: PropTypes.object,
  showingThumbnails: PropTypes.bool.isRequired,
  toggleShowingThumbnails: PropTypes.func.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfPreviewHeader));
