import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import downloadActions from "../../../actions/download-actions";
import threadActions from "../../../actions/thread-actions";
import popoverActions from "../../../actions/popover-actions";
import modalActions from "../../../actions/modal-actions";
import workspaceActions from "../../../actions/workspace-actions";
import {withVFTranslation} from "../../../util/withVFTranslation";
import {connect} from "react-redux";
import _ from "lodash";
import c from "../../../util/const";
import classnames from "classnames";
import {isMobile} from "react-device-detect";
import VFPopover from "../components/VFPopover";
import colors from "../../../util/colors";
import filters from "../../../helpers/filters";
import VFMiddleTruncate from "../util/VFMiddleTruncate";
import UserBadge from "../badges/UserBadge";
import memoizeOne from "memoize-one";
import log from "../../../util/log";
import sapi from "../../../util/sapi";
import {getMessageForError} from "../../../util/errors";
import Button from "../elements/Button";
import ElectronAwareLink from "../elements/ElectronAwareLink";
import MerchantSetupWindow from "../../modals/MerchantSetupWindow";
import popupHelper from "../../../helpers/popup-helper";
import Loading from "../util/Loading";
import sharedActions from "../../../actions/shared-actions";
import vfLocalStorage from "../../../util/local-storage";
import util from "../../../util/util";
import {withRouter} from "react-router-dom";

class ThreadBill extends PureComponent {

  PAYMENT_STATUS = {
    REQUESTED : 'requested',
    PAID : 'paid',
    REFUNDED : 'refunded'
  }

  constructor(props) {
    super(props);

    this.state = {}
  }

  findGuest = memoizeOne((guest_uid) => {
    let {
      accountInfoGuest,
      dm
    } = this.props;

    let user = null;
    if(dm){
      if(guest_uid === accountInfoGuest.guest_uid){
        user = _.extend({}, accountInfoGuest);
      }
      else{
        user = _.extend({}, dm);
      }
    }
    else{
      if(guest_uid === accountInfoGuest.guest_uid){
        user = _.extend({}, accountInfoGuest);
      }
      else{
        user = this.props.findGuestInActiveThreadParticipants(guest_uid);
      }
    }
    return user;
  })

  getPaymentState = memoizeOne((bill) => {
    if(bill.refund_amount && _.isNumber(bill.refund_amount)){
      return this.PAYMENT_STATUS.REFUNDED;
    }
    else if(bill.paid_flag){
      return this.PAYMENT_STATUS.PAID;
    }
    else if(!bill.paid_flag){
      return this.PAYMENT_STATUS.REQUESTED;
    }
  })

  getPaymentStateText = memoizeOne((paymentStatus) => {
    let { t } = this.props;
    let displayText = "";
    if(paymentStatus === this.PAYMENT_STATUS.PAID){
      displayText = t("Payment Received");
    }
    else if(paymentStatus === this.PAYMENT_STATUS.REQUESTED){
      displayText = t("Payment Requested");
    }
    else if(paymentStatus === this.PAYMENT_STATUS.REFUNDED){
      displayText = t("Payment Refunded");
    }
    return displayText;
  })

  showThreadInvoiceMenu(trans_id, evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.showPopover(c.popovers.THREAD_INVOICE_MENU, trans_id);
  }

  hidePopover(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
  }

  onRefundPaymentClick(bill, evt){
    this.hidePopover(evt);
    log.log('onRefundPaymentClick', bill);
    let { t } = this.props;

    this.props.modalAction.showRefundInvoiceDialog(bill, (res) => {
      if(res){
        sapi.Merchant.refundInvoice(bill.payer_uid, bill.trans_id, res.amount, bill.currency === c.INVOICE_CURRENCY_CODES.JPY, res.reason)
          .then((res) => {
            log.log('refund invoice res', res);
            this.props.threadAction.refreshActiveThreadMessages();
          })
          .catch((err) => {
            log.log('error refunding invoice', err);
            this.props.modalAction.showAlert(t("Error Refunding Invoice"), getMessageForError(err, t));
            this.props.threadAction.refreshActiveThreadMessages();
          })
      }
    })
  }

  onVoidInvoiceClick(bill, evt){
    this.hidePopover(evt);
    log.log('onVoidInvoiceClick', bill, evt);
    let { t } = this.props;

    let msgString = '';
    let amountString = `${filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)} ${bill.currency}`
    let payerGuestInfo = this.findGuest(bill.payer_uid);
    if(payerGuestInfo){
      msgString = t("Are you sure you want to void the invoice you sent to ") + _.get(payerGuestInfo, 'first_name') + " " + _.get(payerGuestInfo, 'last_name') + t(" for ") + amountString + t("?");
    }
    else{
      msgString = t("Are you sure you want to void the invoice you sent for ") + amountString + t("?");
    }

    this.props.modalAction.showConfirm(
      t("Are you sure?"),
      msgString,
      (res) => {
        if (res) {
          sapi.Merchant.voidInvoice(bill.payer_uid, bill.trans_id)
            .then((res) => {
              log.log('void invoice res', res);
              this.props.threadAction.refreshActiveThreadMessages();
            })
            .catch((err) => {
              log.log('error voiding invoice', err);
              this.props.modalAction.showAlert(t("Error Voiding Invoice"), getMessageForError(err, t));
              this.props.threadAction.refreshActiveThreadMessages();
            })
        }
      }, t("Yes"), t("No"))
  }

  onViewInInvoiceHistoryClick(bill, evt){
    this.hidePopover(evt);

    this.props.history.push('/invoice_history');
  }

  showViewInvoiceLinkModal(evt, bill){
    // log.log('showViewInvoiceLinkModal', evt, bill);

    this.hidePopover(evt);
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    let { msg } = this.props;
    this.props.modalAction.showViewInvoiceLinkModal(msg.guest_uid, bill, () => {
      log.log('done showing invoice link modal');
    })
  }

  renderInvoiceLink(invoice, classList, styleObj, children) {
    return (
      <a className={classList}
         style={styleObj}
         onClick={(evt) => this.showViewInvoiceLinkModal(evt, invoice)}>
        {children}
      </a>
    )
  }

  getMerchantBillPopoverContent(bill){
    let {
      merchantInfo,
      t
    } = this.props;

    let guestInfo = this.findGuest(bill.payer_uid);
    let menuHeaderText = `${filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}`;
    if(guestInfo){
      menuHeaderText += ` - ${_.get(guestInfo, 'last_name')}`
    }

    //don't allow a refund if it's already been fully refunded.
    let maxRefund = +_.get(bill, 'amount', 0) - +_.get(bill, 'refund_amount', 0);
    let allowRefund = bill.paid_flag && maxRefund > 0;
    let isHealthyMerchantAccount = MerchantSetupWindow.isMerchantStatusHealthy(merchantInfo);

    return (
      <div>
        <ul className="popover-content list-group">

          <a onClick={_.noop}
             style={styles.menuHeader}
             className="list-group-item list-group-item-action has-pointer secondary-text-bg light-color">
            {menuHeaderText}
          </a>

          <a className="list-group-item list-group-item-action has-pointer"
             style={styles.menuItem}
             onClick={(evt) => this.showViewInvoiceLinkModal(evt, bill)}>
            <i style={styles.menuIcons} className="icon ion-link"/>
            {t("View Invoice")}
          </a>

          {allowRefund && isHealthyMerchantAccount &&
            <a onClick={this.onRefundPaymentClick.bind(this, bill)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-cash"/>
              {t("Refund Payment")}
            </a>
          }

          {!bill.paid_flag && isHealthyMerchantAccount &&
            <a onClick={this.onVoidInvoiceClick.bind(this, bill)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-close"/>
              {t("Void Invoice")}
            </a>
          }

          <a onClick={this.onViewInInvoiceHistoryClick.bind(this, bill)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-clipboard"/>
            {t("View all Invoices")}
          </a>

        </ul>
      </div>
    )
  }

  renderMerchant(bill){
    let {
      showBillControls,
      t
    } = this.props;

    let guestInfo = this.findGuest(bill.payer_uid);
    let paymentStatus = this.getPaymentState(bill);
    let displayText = this.getPaymentStateText(paymentStatus);
    if(!guestInfo){
      displayText = `${t("This guest does not have access to this thread")}`;
    }

    let refundedAmount = bill.refund_amount ? bill.refund_amount : 0;
    return (
      <div>
        <div className="d-inline-block align-top thread-doc-wrap font-weight-bold primary-color">

          {this.renderInvoiceLink(
            bill,
            "has-pointer primary-color",
            null,
            <div>
              <VFMiddleTruncate
                text={bill.description}
                start={3}
                end={3}
                drawGearIcon={() => {
                  let isShowing = this.props.popoverAction.isShowing(c.popovers.THREAD_INVOICE_MENU, bill.trans_id)
                  if (!showBillControls && !isShowing) {
                    return null;
                  }
                  return (
                    <VFPopover
                      isPopoverOpen={isShowing}
                      positions={['right', 'top', 'bottom']}
                      onClickOutside={this.hidePopover.bind(this)}
                      getMenuContent={this.getMerchantBillPopoverContent.bind(this, bill)}>
                    <span style={{lineHeight: '14px'}}
                          className="pl-2 d-inline-block primary-color"
                          onClick={this.showThreadInvoiceMenu.bind(this, bill.trans_id)}>
                      <i style={{fontSize: '18px'}}
                         className={classnames(`icon item-menu-icon ion-gear-b has-pointer primary-color`)}/>
                    </span>
                    </VFPopover>
                  )
                }}
              />
            </div>
          )}
        </div>
        <div>
          <div>
            <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}</span>
            <span className="small d-inline ml-2">{bill.currency}</span>
          </div>
          {bill.refund_amount &&
            <div className="small">
              -{filters.formatCurrency(this.props.i18n.language, bill.currency, refundedAmount)}
            </div>
          }
        </div>
        <div>
          <div style={styles.paymentText}>
            {!guestInfo &&
              <span className="mr-1" style={styles.warningIconWrap}>
                <i className="icon ion-android-warning align-baseline text-warning" />
              </span>
            }
            {guestInfo &&
              <span className="mr-1">
                <UserBadge guest={guestInfo} small={true}/>
              </span>
            }
            {displayText}
            {paymentStatus === this.PAYMENT_STATUS.PAID &&
              <i className="icon ion-checkmark-circled green-color ml-2"/>
            }
          </div>
        </div>
      </div>
    )
  }

  renderPayer(bill){
    let { t } = this.props;
    let guestInfo = this.findGuest(bill.payer_uid);
    let paymentStatus = this.getPaymentState(bill);
    let displayText = this.getPaymentStateText(paymentStatus);
    let refundedAmount = bill.refund_amount ? bill.refund_amount : 0;

    return (
      <div>
        <div className="d-inline-block align-top thread-doc-wrap font-weight-bold primary-color">
          {this.renderInvoiceLink(
            bill,
            "has-pointer primary-color",
            null,
            <VFMiddleTruncate
              text={bill.description}
              start={3}
              end={3}/>
          )}
        </div>
        <div>
          <div>
            <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}</span>
            <span className="small d-inline ml-2">{bill.currency}</span>
          </div>
          {bill.refund_amount &&
            <div className="small">
              -{filters.formatCurrency(this.props.i18n.language, bill.currency, refundedAmount)}
            </div>
          }
        </div>
        <div>
          <div style={styles.paymentText}>
            {guestInfo &&
              <span className="mr-1">
                <UserBadge guest={guestInfo}
                           overrideColor={colors.PRIMARY}
                           small={true}/>
              </span>
            }
            {displayText}
            {paymentStatus === this.PAYMENT_STATUS.PAID &&
              <i className="icon ion-checkmark-circled green-color ml-2"/>
            }

            {this.renderInvoiceLink(
              bill,
              "btn btn-sm btn-primary light-color ml-2",
              null,
              <>
                {paymentStatus === this.PAYMENT_STATUS.REQUESTED ? t("View and Pay Invoice") : t("View Invoice")}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderBillForNonparticipant(bill){
    let guestInfo = this.findGuest(bill.payer_uid);
    let paymentStatus = this.getPaymentState(bill);
    let displayText = this.getPaymentStateText(paymentStatus);
    let refundedAmount = bill.refund_amount ? bill.refund_amount : 0;

    return (
      <div>
        <div className="d-inline-block align-top thread-doc-wrap font-weight-bold">

          <VFMiddleTruncate
            text={bill.description}
            start={3}
            end={3} />

        </div>
        <div>
          <div>
            <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}</span>
            <span className="small d-inline ml-2">{bill.currency}</span>
          </div>
          {bill.refund_amount &&
            <div className="small">
              -{filters.formatCurrency(this.props.i18n.language, bill.currency, refundedAmount)}
            </div>
          }
        </div>
        <div>
          <div style={styles.paymentText}>
            {guestInfo &&
              <span className="mr-1">
                <UserBadge guest={guestInfo}
                           small={true}/>
              </span>
            }
            {displayText}
            {paymentStatus === this.PAYMENT_STATUS.PAID &&
              <i className="icon ion-checkmark-circled green-color ml-2"/>
            }
          </div>
        </div>
      </div>
    )
  }

  render(){
    let {
      bill,
      msg
    } = this.props;
    let {
      accountInfo
    } = this.props;

    //If you're the payer, your guest_uid will be the payer_uid.
    //If you're the merchant, you will have sent the message, so this message was sent by you.
    let isPayer = _.get(accountInfo, 'uid') === _.get(bill, 'payer_uid');
    let isMerchant =  _.get(accountInfo, 'uid') === _.get(msg, 'guest_uid');
    return (
      <div>
        {isPayer && this.renderPayer(bill)}
        {isMerchant && this.renderMerchant(bill)}
        {!isPayer && !isMerchant && this.renderBillForNonparticipant(bill)}
      </div>
    )
  }
}

const styles = {
  paymentText : {
    fontSize : '14px',
    lineHeight: '31px',
    minHeight: '31px',
    color: colors.DARK,
    marginLeft: '25px',
    marginBottom: '5px'
  },
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    borderBottom: 'none'
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  warningIconWrap: {
    width: '26px',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '22px'
  }
}

ThreadBill.propTypes = {
  msg : PropTypes.object.isRequired,
  bill: PropTypes.object.isRequired,
  showBillControls: PropTypes.bool,
  dm : PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    accountInfo : state.shared.accountInfo,
    merchantInfo : state.shared.merchantInfo,
    accountInfoGuest : state.shared.accountInfoGuest,
    activeThread : state.thread.activeThread,
    showingPopoverKey : state.popover.showingPopoverKey,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    modalAction: {...modalActions.mapToDispatch(dispatch)},
    threadAction : {...threadActions.mapToDispatch(dispatch)},
    findGuestInActiveThreadParticipants: (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid)),
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ThreadBill)));
