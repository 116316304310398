import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import c from '../../util/const'
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import NotificationIcon from "../partials/components/NotificationIcon";
import {getMessageForError} from "../../util/errors";
import ExpandableRow from "../partials/components/ExpandableRow";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import {withVFTranslation} from "../../util/withVFTranslation";
import Scroll from "react-scroll";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {CSSTransition} from "react-transition-group";
import Promise from "bluebird";
import workspaceActions from "../../actions/workspace-actions";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Checkbox from "../partials/elements/Checkbox";
import sharedActions from "../../actions/shared-actions";
import Loading from "../partials/util/Loading";
import vfLogo from "../../resources/vf-stripe-logo.png";
import Image from "../partials/elements/Image";
import CurrencyInput from "../partials/elements/CurrencyInput";
import moment from "moment";
import pendingMsgCache from "../../helpers/pending-msg-cache";
import VFPopover from "../partials/components/VFPopover";
import popoverActions from "../../actions/popover-actions";
import popupHelper from "../../helpers/popup-helper";
import filters from "../../helpers/filters";
import flagIconHelper from "../../util/flag-icon-helper";

const WINDOW_MODE = {
  GUEST : 'guest',
  INVOICE_DETAILS: 'invoice_details',
  INVOICE_REVIEW: 'invoice_review'
}

class SendInvoiceWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedGuestUid: null,
      selectedGuest : null,
      mode: WINDOW_MODE.GUEST,
      description: this.getDefaultInvoiceDescription(),
      invoiceAmount : 0,
      mToday : moment(),
      daysUntilDue : 14,
      validationErr: [],

      selectedCurrencyCode : c.INVOICE_CURRENCY_CODES.USD,

      showNextButtonLoading : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
    let{
      guest_uid,
      participants
    } = this.props.modalProps;
    if(guest_uid){
      this.setState({
        selectedGuestUid: guest_uid,
        selectedGuest : participants[0],
        mode: WINDOW_MODE.INVOICE_DETAILS,
      })
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  doAreYouSureForCancel() {
    let {t} = this.props;
    this.props.showConfirm(
      t("Are you sure?"),
      t("Are you sure you want to cancel this payment request?"),
      (res) => {
        if (res) {
          this.closeModal(null);
        }
      }, t("Yes"), t("No"))
  }

  backClick(){
    let { mode } = this.state;
    let {
      guest_uid
    } = this.props.modalProps;
    if(guest_uid){
      if(mode === WINDOW_MODE.INVOICE_DETAILS){
        this.doAreYouSureForCancel();
      }
      else if(mode === WINDOW_MODE.INVOICE_REVIEW){
        this.setState({
          mode : WINDOW_MODE.INVOICE_DETAILS,
          validationErr : []
        })
        let {
          modalId
        } = this.props.modalProps;
        this.props.updateSize(modalId, false);
      }
    }
    else{
      if(mode === WINDOW_MODE.GUEST){
        //then it's a cancel, just close out
        this.doAreYouSureForCancel();
      }
      else if(mode === WINDOW_MODE.INVOICE_DETAILS){
        this.setState({
          mode : WINDOW_MODE.GUEST,
          validationErr : []
        })
      }
      else if(mode === WINDOW_MODE.INVOICE_REVIEW){
        this.setState({
          mode : WINDOW_MODE.INVOICE_DETAILS,
          validationErr : []
        })
        let {
          modalId
        } = this.props.modalProps;
        this.props.updateSize(modalId, false);
      }
    }
  }

  nextClick(){
    let { mode } = this.state;

    if(mode === WINDOW_MODE.GUEST){
      if(!this.doGuestValidation()){
        return;
      }
      this.setState({
        mode : WINDOW_MODE.INVOICE_DETAILS,
        validationErr : []
      })
    }
    else if(mode === WINDOW_MODE.INVOICE_DETAILS){
      if(!this.doInvoiceValidation()){
        return;
      }
      this.setState({
        mode : WINDOW_MODE.INVOICE_REVIEW,
        validationErr : []
      })
      let {
        modalId
      } = this.props.modalProps;
      this.props.updateSize(modalId, true);
    }
    else if(mode === WINDOW_MODE.INVOICE_REVIEW){
      this.doFinishAndClose();
    }
  }

  doGuestValidation(){
    let { selectedGuestUid } = this.state;
    let { t } = this.props;
    let err = [];

    if(!selectedGuestUid){
      err.push(t("Please select a guest."))
    }

    this.setState({validationErr : err})
    return err.length === 0;
  }

  doInvoiceValidation(){
    let { invoiceAmount, daysUntilDue } = this.state;
    let { t } = this.props;
    let err = [];

    if(invoiceAmount <= 0){
      err.push(t("Please enter the amount for your payment."))
    }

    if(_.isNumber(daysUntilDue) && daysUntilDue <= 0){
      err.push(t("Please enter the number of days until your payment is due."))
    }

    this.setState({validationErr : err})
    return err.length === 0;
  }

  doFinishAndClose(){
    let {
      selectedGuestUid,
      description,
      invoiceAmount,
      daysUntilDue,
      selectedCurrencyCode
    } = this.state;
    let {
      forum_id,
      host_uid,
      chat_id,
      guest_uid
    } = this.props.modalProps;
    let {
      participants,
    } = this.props.modalProps;

    let foundGuest = _.find(participants, (p) => p.guest_uid === selectedGuestUid);
    let invoice = {
      uniqueId : _.uniqueId('vf-invoice-doc-'),
      guest_uid : selectedGuestUid,
      guest_name : _.get(foundGuest, 'first_name') + " " + _.get(foundGuest, 'last_name'),
      amount : +invoiceAmount,
      amountCents : selectedCurrencyCode !== c.INVOICE_CURRENCY_CODES.JPY ? +invoiceAmount * 100 : +invoiceAmount,
      currency : selectedCurrencyCode,
      no_decimal_flag : selectedCurrencyCode === c.INVOICE_CURRENCY_CODES.JPY,
      description,
      forum_id,
      host_uid,
      chat_id,
      days_until_due : +daysUntilDue,
      isInvoice : true
    }
    if(guest_uid){
      pendingMsgCache.addPendingInvoiceToDmCache(guest_uid, invoice);
      this.closeModal({guest_uid, invoice})
    }
    else if(chat_id){
      pendingMsgCache.addPendingInvoiceToThreadCache(chat_id, invoice);
      this.closeModal({forum_id, host_uid, chat_id, invoice})
    }
  }

  onGuestCheckChange(guest_uid, evt){
    let {
      selectedGuestUid
    } = this.state;
    let {
      participants
    } = this.props.modalProps;

    if(selectedGuestUid === guest_uid){
      this.setState({
        selectedGuestUid : null,
        selectedGuest : null
      })
    }
    else{
      this.setState({
        selectedGuestUid : guest_uid,
        selectedGuest : _.find(participants, (p) => p.guest_uid === guest_uid)
      });
    }
  }

  getDefaultInvoiceDescription(){
    let {
      t
    } = this.props;

    return t('Services rendered');
  }

  onDaysUntilDueChanged(evt){
    let val = +evt.target.value;
    if(_.isNaN(val)){
      evt.preventDefault();
    }
    else{
      this.setState({daysUntilDue: evt.target.value})
    }
  }

  getPopoverContent({position, childRect, popoverRect}){
    let popupArgs = {
      position,
      childRect,
      popoverRect
    }
    return popupHelper.getCurrencyMenuPopoverContents(popupArgs, _.map(c.INVOICE_CURRENCY_CODES, (code) => code), (code) => {
      this.props.popoverAction.hidePopover()
      log.log('currency code selected', code);
      this.setState({selectedCurrencyCode : code})
    })
  }

  renderGuestMode(){
    let {modalProps, t} = this.props;
    let {
      participants,
    } = modalProps;
    let {
      selectedGuestUid,
      validationErr
    } = this.state;

    return (
      <div>
        <div>
          <h6>{t("Select the thread participant you want to invoice.")}</h6>
        </div>
        {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
        <div>
          <h5 className="mt-2">
            {t("Thread Participants")}
          </h5>
        </div>

        <div className="p-2">
          {_.map(participants, (guest) => {
            let userChecked = selectedGuestUid === guest.guest_uid;
            return (
              <div key={guest.guest_uid} className="d-flex mb-2">
                <div style={styles.userColumn} className="mr-3">
                  <UserBadge guest={guest}/>
                </div>
                <div className="flex-grow-1" style={styles.userColumn}>
                  <h6 className="mb-0"
                      style={userChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                    {guest.first_name} {guest.last_name}
                  </h6>
                  <p className="mb-0"
                     style={userChecked ? styles.selectedUserEmail : styles.unselectedUser}>
                    {guest.email_address}
                  </p>
                </div>
                <div style={styles.userColumn} className="ml-3 mr-2">
                  <div className="d-inline-block">
                    <Checkbox isChecked={!!userChecked}
                              rootCls="big-round-checkbox form-control-lg"
                              label={' '}
                              onChange={this.onGuestCheckChange.bind(this, guest.guest_uid)}
                              labelCls={'primary-color'} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onInvoiceAmountChange(evt){
    this.setState({invoiceAmount: evt.target.value})
  }

  renderInvoiceDetails(){
    let { t } = this.props;
    let {
      validationErr,
      selectedGuest,
      description,
      invoiceAmount,
      daysUntilDue,
      selectedCurrencyCode
    } = this.state;
    return (
      <div>
        <div className="d-flex mb-2">
          <div className="d-flex mb-2">
            <div style={styles.userColumn} className="mr-3">
              <UserBadge guest={selectedGuest}/>
            </div>
            <div className="flex-grow-1 text-left" style={styles.userColumn}>
              <h6 className="mb-0"
                  style={styles.selectedUserHeader}>
                {selectedGuest.first_name} {selectedGuest.last_name}
              </h6>
              <p className="mb-0"
                 style={styles.selectedUserEmail}>
                {selectedGuest.email_address}
              </p>
            </div>
          </div>
        </div>

        <p>{t("Please provide a description. This will appear on the invoice.")}</p>

        {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
        <div className="p-2">
          <div className="form-group">
            <label>{t("Description")}</label>
            <input className="form-control"
                   value={description}
                   maxLength={c.limits.maxInvoiceDescription}
                   onChange={(evt) => this.setState({description: evt.target.value})}
                   placeholder={this.getDefaultInvoiceDescription()}/>
          </div>

          <div className="form-group">
            <label>{t("Amount")}</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <VFPopover
                  isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.CURRENCY_MENU)}
                  positions={['right', 'bottom']}
                  containerClassName="preview-container-cls"
                  onClickOutside={() => this.props.popoverAction.hidePopover()}
                  getMenuContent={this.getPopoverContent.bind(this)}>
                  <button className="btn btn-currency dropdown-toggle"
                          style={{paddingRight: '30px'}}
                          onClick={() => this.props.popoverAction.showPopover(c.popovers.CURRENCY_MENU)}>
                    <span className="icon pr-2" style={{lineHeight: '18px'}}>
                      {flagIconHelper.getFlagForCurrencyCode(selectedCurrencyCode)}
                    </span>
                  </button>
                </VFPopover>
              </div>
              <CurrencyInput className="form-control"
                             currencyConfig={{
                               locale: this.props.i18n.language,
                               currencyCode: selectedCurrencyCode,
                               minimumFractionDigits: selectedCurrencyCode === c.INVOICE_CURRENCY_CODES.JPY ? 0 : undefined
                             }}
                             onChange={(evt) => this.onInvoiceAmountChange(evt)}
                             value={invoiceAmount}/>
            </div>
          </div>

          <div className="form-group">
            <label>{t("Days until Due")}</label>
            <input className="form-control"
                   value={daysUntilDue}
                   type="tel"
                   onChange={(evt) => this.onDaysUntilDueChanged(evt)}
                   placeholder={t("Days until Due")}/>
          </div>
        </div>
      </div>
    )
  }

  renderDraftWatermark(){

    return (
      <div className="row">
        <div className="col">
          <div className="text-uppercase light-grey-color text-center"
               style={{
                 padding: '30px',
                 opacity: '.3',
                 fontSize: '120px',
                 pointerEvents: 'none'
               }}>
            Draft
          </div>

        </div>
      </div>
    )
  }

  renderReviewInvoice(){

    //So it might seem weird, but we don't want any localization here!
    //We want this preview invoice to be in english.  bug 3672
    // let { t } = this.props;

    let {
      invoiceAmount,
      description,
      mToday,
      selectedGuest,
      daysUntilDue,
      selectedCurrencyCode
    } = this.state;

    let formattedAmount = filters.formatCurrency(this.props.i18n.language, selectedCurrencyCode, invoiceAmount);
    let dueByDate = moment(mToday).add(daysUntilDue, 'd');
    return (
      <div className="m-3">
        <div className="row mb-4">
          <div className="col-8">
            <h2 className="mb-3">
              Invoice
            </h2>

            <div className="row">
              <div className="col-4">
                <p className="font-weight-bold mb-0">
                  Invoice Number
                </p>
                <p className="mb-0">
                  Date of Issue
                </p>
                <p className="mb-0">
                  Date Due
                </p>
              </div>
              <div className="col-8">
                <p className="font-weight-bold mb-0">
                  XXXXXXXX-XXXX
                </p>
                <p className="mb-0">
                 {mToday.format("LL")}
                </p>
                <p className="mb-0">
                  {dueByDate.format("LL")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 text-right">
            <Image src={vfLogo} alt={this.props.t('Logo')} imgHeight={50}/>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-5">
            <p className="font-italic secondary-text-color mb-0" style={{maxWidth: '200px'}}>
              Your merchant contact information as registered in Stripe will appear here
            </p>
          </div>
          <div className="col-7 pl-0">
            <p className="font-weight-bold mb-0">
              Bill to
            </p>
            <p className="mb-0">
              {selectedGuest.first_name} {selectedGuest.last_name}
            </p>
            <p className="mb-0">
              {selectedGuest.email_address}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>
              {`${formattedAmount} due ${dueByDate.format("LL")}`}
            </h4>
            <p>
              <a style={{textDecoration: 'underline'}}
                 className="btn btn-link primary-color pl-1">Pay Online</a>
            </p>
          </div>
        </div>

        <div className="row mx-2" style={{borderBottom: '1px solid #000'}}>
          <div className="col-5 px-0">
            <p className="my-2">
              Description
            </p>
          </div>
          <div className="col pl-0">
            <p className="my-2">
              Qty
            </p>
          </div>
          <div className="col text-right">
            <p className="my-2">
              Unit price
            </p>
          </div>
          <div className="col text-right px-0">
            <p className="my-2">
              Amount
            </p>
          </div>
        </div>
        <div className="row mx-2">
          <div className="col-5 px-0">
            <p className="my-2">
              {description}
            </p>
          </div>
          <div className="col pl-0">
            <p className="my-2">
              1
            </p>
          </div>
          <div className="col">
            <p className="text-right my-2">
              {formattedAmount}
            </p>
          </div>
          <div className="col px-0">
            <p className="text-right my-2">
              {formattedAmount}
            </p>
          </div>
        </div>

        <div className="row mx-2 mt-3">
          <div className="col-5"></div>
          <div className="col-7" style={{borderTop: '1px solid #ccc'}}>
            <div className="row">
              <div className="col px-0">
                <p className="my-2">
                  Subtotal
                </p>
              </div>
              <div className="col text-right px-0">
                <p className="my-2">
                  {formattedAmount}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-2">
          <div className="col-5"></div>
          <div className="col-7" style={{borderTop: '1px solid #ccc'}}>
            <div className="row">
              <div className="col px-0">
                <p className="my-2">
                  Total
                </p>
              </div>
              <div className="col text-right px-0">
                <p className="my-2">
                  {formattedAmount}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-2">
          <div className="col-5"></div>
          <div className="col-7" style={{borderTop: '1px solid #ccc'}}>
            <div className="row">
              <div className="col font-weight-bold px-0">
                <p className="my-2">
                  Amount Due
                </p>
              </div>
              <div className="col text-right font-weight-bold px-0">
                <p className="my-2">
                  {formattedAmount}
                </p>
              </div>
            </div>
          </div>
        </div>

        {this.renderDraftWatermark()}
      </div>
    )
  }

  getBackButtonText(){
    let { t } = this.props;
    let { mode } = this.state;
    let {
      guest_uid
    } = this.props.modalProps;

    if(guest_uid){
      if(mode === WINDOW_MODE.INVOICE_DETAILS){
        return t("Cancel");
      }
      else{
        return t("Back");
      }
    }
    else{
      if(mode === WINDOW_MODE.GUEST){
        return t("Cancel");
      }
      else{
        return t("Back");
      }
    }
  }

  getNextButtonText(){
    let { t } = this.props;
    let { mode } = this.state;
    if(mode === WINDOW_MODE.GUEST){
      return t("Next");
    }
    else if(mode === WINDOW_MODE.INVOICE_DETAILS){
      return t("Next");
    }
    else if(mode === WINDOW_MODE.INVOICE_REVIEW){
      return t("Request Payment")
    }
  }

  onEscapeKey(){
    this.doAreYouSureForCancel();
  }

  render() {
    let { t } = this.props;
    let { mode, showNextButtonLoading } = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Request Payment")}</h5>
          <button type="button" className="close" onClick={this.doAreYouSureForCancel.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>

        <div className="modal-body">
          {mode === WINDOW_MODE.GUEST &&
          this.renderGuestMode()
          }
          {mode === WINDOW_MODE.INVOICE_DETAILS &&
          this.renderInvoiceDetails()
          }
          {mode === WINDOW_MODE.INVOICE_REVIEW &&
            this.renderReviewInvoice()
          }
        </div>

        <div className="modal-footer">
          <Button className={'btn btn-secondary'}
                  disabled={showNextButtonLoading}
                  onClick={this.backClick.bind(this)}>
            {this.getBackButtonText()}
          </Button>
          <Button className={'btn btn-primary'}
                  disabled={showNextButtonLoading}
                  onClick={this.nextClick.bind(this)}>
            {showNextButtonLoading &&
              <Loading inline={true}
                       color="light"
                       size={'sm'}/>
            }
            {!showNextButtonLoading && this.getNextButtonText()}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px',
    color : colors.SECONDARY_TEXT
  },
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    showingPopoverKey : state.popover.showingPopoverKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sharedActions : { ...sharedActions.mapToDispatch(dispatch) },
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    ...modalActions.mapToDispatch(dispatch)
  };
};

SendInvoiceWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SendInvoiceWindow));
