import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import { validateEmail } from '../../../util/validation';

import FancyInput from '../elements/FancyInput';
import PulseButton from '../elements/PulseButton';
import Button from '../elements/Button';

import authActions from "../../../actions/auth-actions";
import Loading from "../util/Loading";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import HandleConfirmCodeInput from "../elements/HandleConfirmCodeInput";
import _ from "lodash";

class HandleForgotPasswordCard extends Component {

  codeField = null;

  constructor(props){
    super(props);

    this.state = {
      showNoPwdReset: false,
      passwordResetSuccess: false
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    setTimeout(() => {
      this.doNextButtonClicked();
    })
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  validateForm() {
    let { email } = this.props;

    this.props.setValidationErrors([]);
    if(!validateEmail(email)){
      this.props.emailFieldRef.triggerValidationErr();
      return false;
    }
    else{
      return true;
    }
  }

  doPasswordReset(){
    let { email } = this.props;
    let { setStep, setTFAAuth, setEmailFieldVisible, t } = this.props;

    if(!this.validateForm()){
      return;
    }

    this.props.setLoading(true);
    api.PasswordReset.post(email, true)
      .then((res) => {
        log.log('pwd reset', res);
        this.props.setLoading(false);
        if (!res.data.disabled_flag) {
          if (res.data.tfa_totp_flag || res.data.tfa_sms_flag) {
            //password reset is enabled, but tfa is on.  do that first.
            setTFAAuth(res.data);
            setStep(c.authSteps.tfaPwdReset);
          }
          else{
            //success
            setEmailFieldVisible(false);
            // this.props.setHideAuthButtonControls(true);
            this.setState({
              passwordResetSuccess : true,
            })
          }
        }
        else{
          //password reset is disabled, show something scary
          this.props.setHideAuthButtonControls(true);
          this.setState({
            showNoPwdReset: true,
          })
        }
      })
      .catch((err) => {
        this.props.setValidationErrors([
          getMessageForError(err, t)
        ])
        this.props.setLoading(false);
      })
  }

  doNextButtonClicked(){
    let { passwordResetSuccess } = this.state;
    if(!passwordResetSuccess){
      this.doPasswordReset();
    }
    else{
      this.doSubmitResetCode();
    }
  }

  onStartOverButtonClick(evt){
    let { setStep, setEmailFieldVisible } = this.props;

    setEmailFieldVisible(true);
    setStep(c.authSteps.email)
  }

  doSubmitResetCode(){
    let code = this.codeField.assembleCode();
    log.log('on submit reset code', code);
    if(code.length !== 5){
      this.codeField.triggerValidationErr();
      return;
    }
    let {
      setAuth,
      email,
      t
    } = this.props;

    api.PasswordResetConfirm.post(email, code, null, true)
      .then((res) => {
        log.log('pwd reset cfm', res);
        setAuth(res.data.sid, res.data.vip, res.data.token);
        if(this.props.onTokenReceived){
          this.props.onTokenReceived(_.get(res, 'data.token'))
        }
      })
      .catch((err) => {
        log.log('error doing cfm pwd reset', err);
        this.props.setValidationErrors([
          getMessageForError(err, t)
        ])
        this.props.setLoading(false);
      })
  }

  render() {
    let { showNoPwdReset, passwordResetSuccess } = this.state;
    let { email, isLoading, step, t } = this.props;

    if(isLoading){
      return (
        <div className="pt-3 pb-3">
          <Loading centered color={'light'} size={'sm'}/>
        </div>
      )
    }

    let card = null;
    if(showNoPwdReset){
      card =
        <div className={'light-color'}>
          <h3>{t("Unable to Reset Password")}</h3>
          <p>
            {t("Password reset has been disabled for")} {email}{t(".")}
          </p>
          <hr className={'light-border'} />
          <div className={'text-center'}>
            <Button className="btn btn-link light-color"
                    disabled={this.props.isLoading}
                    onClick={() => this.props.doStartOverButtonClick()}>{t("Start Over")}</Button>
          </div>
        </div>
    }
    else if(passwordResetSuccess){
      card =
        <div className={'light-color text-center mt-3'}>
          <h3>{t("Check your Email")}</h3>
          <p className="mb-4">
            {t("We sent an email to")} <br/><b>{email}</b><br/> {t("to make sure you are you.")}
          </p>
          <div className="position-relative text-center">
            <HandleConfirmCodeInput onRef={ref => this.codeField = ref}
                                    onEnter={this.doSubmitResetCode.bind(this)}
                                    inputDisabled={false}/>
          </div>
        </div>
    }
    else{
      card = <div></div>
    }

    return (
      <div>
        {card}
      </div>
    );
  }
}

HandleForgotPasswordCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  setHideAuthButtonControls : PropTypes.func.isRequired,
  doStartOverButtonClick : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  emailFieldRef : PropTypes.object,
  onTokenReceived : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    step : state.auth.step,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    setTFAAuth: tfaAuth => dispatch(authActions.setTFAAuth(tfaAuth)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible))
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(HandleForgotPasswordCard));
