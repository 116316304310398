import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import log from "../../../util/log";
import _ from 'lodash';

import classNames from 'classnames';
import colors from "../../../util/colors";
import c from "../../../util/const";
import utils from "../../../util/util";
import PdfSigningOverlay from "./PdfSigningOverlay";
import TextInputMeasurer from "../elements/TextInputMeasurer";

class PdfSignatureRequestCustomScalingInput extends Component {
  
  constructor(props){
    super(props)
    
    this.inputRef = React.createRef();
    
    this.state = {
      measuredText : this.props.inputText,
      inputFontSize : props.initialFontSize,
      
      textMeasurerRef : null
    }
  }
  
  componentDidMount(){
    if(this.props.onRef) {
      this.props.onRef(this)
    }
    this.pickTextAndMeasure();
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    let measureText = this.pickMeasureText();
    if(this.state.measuredText !== measureText){
      this.pickTextAndMeasure();
    }
    else if(prevProps.width !== this.props.width){
      this.pickTextAndMeasure();
    }
  }
  
  pickMeasureText(){
    let textToMeasure = this.props.inputText;
    if(textToMeasure.length === 0){
      //then try placeholder text
      if(this.props.placeholderText && this.props.placeholderText.length > 0){
        textToMeasure = this.props.placeholderText;
      }
    }
    return textToMeasure;
  }
  
  pickTextAndMeasure(){
    
    utils.waitForCondition(() => {
      return !!(_.get(this.state, 'textMeasurerRef'));
    })
      .then(() => {
        let textToMeasure = this.pickMeasureText();
        this.setState({ measuredText : textToMeasure })
        let measurer = _.get(this.state, 'textMeasurerRef');
        measurer.startMeasure(textToMeasure, this.props.initialFontSize, this.props.width)
          .then((measuredFontSize) => {
            log.warn('custom input done measuring', measuredFontSize);
            this.setState({inputFontSize : measuredFontSize});
          })
          .catch((err) => {
            log.warn('error during measuring', err);
          })
      })
  }

  onInputChange(evt){
    let { onInputChange } = this.props;
    
    if(onInputChange && evt.target) {
      onInputChange(evt.target.value);
    }
  }
  
  getInputRef(){
    return this.inputRef;
  }
  
  render(){
    let {
      inputText,
      inputClassName,
      width,
      height,
      lineHeight,
      inputDisabled,
      placeholderText,
      inputPaddingStyle,
      hasRemoveButton,
      signaturePrimaryColor,
      overlayIsConfirmed
    } = this.props;
    
    let {
      inputFontSize
    } = this.state;

    let baseColor = !overlayIsConfirmed ? (signaturePrimaryColor || colors.SIGNING_REQUEST) : "transparent";
    let bgColor = !overlayIsConfirmed ? utils.transparentizeHex(baseColor, c.pdf.SIGNATURE_REQUEST_BG_OPACITY) : "transparent";
    let textColor = overlayIsConfirmed ? colors.DARK : baseColor;
    return (
      <>
        <TextInputMeasurer onRef={(ref) => this.setState({textMeasurerRef: ref})}
                           measureBufferSpace={hasRemoveButton ? 29: 12}
                           fontClass={'helvetica'}
                           scaleRelativeToOverlay={this.props.scaleRelativeToOverlay} />
        <input className={inputClassName}
               style={{
                 border: `1px solid ${baseColor}`,
                 backgroundColor: bgColor,
                 color: `${textColor}`,
                 padding : inputPaddingStyle,
                 width: `${width}px`,
                 height: `${height}px`,
                 lineHeight: `${lineHeight}px`,
                 fontSize : `${inputFontSize}px`,
                 letterSpacing: `${-0.5}px`,
                 zIndex : 10
               }}
               value={inputText}
               placeholder={placeholderText}
               onChange={this.onInputChange.bind(this)}
               ref={this.inputRef}
               disabled={inputDisabled}/>
      </>
    )
  }
}

PdfSignatureRequestCustomScalingInput.propTypes = {
  hasRemoveButton : PropTypes.bool.isRequired,
  inputPaddingStyle : PropTypes.string.isRequired,
  initialFontSize : PropTypes.number.isRequired,
  signaturePrimaryColor : PropTypes.string,
  scaleRelativeToOverlay : PropTypes.func.isRequired,
  onInputChange : PropTypes.func.isRequired,
  inputClassName : PropTypes.string.isRequired,
  inputDisabled : PropTypes.bool,
  inputText : PropTypes.string.isRequired,
  placeholderText : PropTypes.string,
  width : PropTypes.number.isRequired,
  height : PropTypes.number.isRequired,
  lineHeight : PropTypes.number.isRequired,
  overlayIsConfirmed : PropTypes.bool
}

export default PdfSignatureRequestCustomScalingInput;
