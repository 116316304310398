import React, {Component} from 'react';
import {connect} from 'react-redux';

import { withRouter } from 'react-router-dom';

import c from '../../util/const';
import accountActions from '../../actions/account-actions';
import classNames from 'classnames';
import log from "../../util/log";
import Loading from "../partials/util/Loading";
import GeneralTab from "../partials/account/GeneralTab";
import SecurityTab from "../partials/account/SecurityTab";
import SubscriptionTab from "../partials/account/SubscriptionTab";
import {Waypoint} from "react-waypoint";

import Scroll from 'react-scroll';
import Header from "../partials/components/Header";
import _ from "lodash";
import querystring from "query-string";
import appActions from "../../actions/app-actions";
import sapi from "../../util/sapi";
import {getMessageForError} from "../../util/errors";
import modalActions from "../../actions/modal-actions";
import sharedActions from "../../actions/shared-actions";
import PlaceholderLoaders from "../partials/util/PlaceholderLoaders";
import {withVFTranslation} from "../../util/withVFTranslation";
import sentryHelper from "../../helpers/sentry-helper";
import electronUtil from "../../util/electron-util";

class Account extends Component {

  mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading:true,
      selectedTab : 'general',
      generalTabRef : null,
      subscriptionTabRef : null,
      electronConfigVersion : null
    }
  }

  componentDidMount() {
    this.mounted = true;
    let { startup } = this.props;

    log.log('Account will mount')
    this.setState({loading:true})
    startup()
      .then(() => {

        if(electronUtil.isRunningWithinElectron()){
          electronUtil.getConfigVersion()
            .then((version) => this.setState({electronConfigVersion : version}))
            .catch((err) => log.log('error getting electron config version', err))
        }

        this.waitForRefs()
          .then(() => {
            setTimeout(() => {
              this.handleRouting();
            })
          })
      })
      .catch((err) =>{
        log.log('error on account startup', err);
        if(sapi.shouldUIErrorTriggerApplicationError(err)) {
          sentryHelper.captureMessage('acct-startup-error', err);
          this.props.setApplicationError(err);
        }
      })
      .finally(() => {
        this.setState({loading: false});
      })
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  waitForRefs(){
    return new Promise((resolve, reject) => {
      let { generalTabRef, subscriptionTabRef } = this.state;

      if(generalTabRef && subscriptionTabRef){
        resolve(true);
      }
      else{
        setTimeout(() => {
          resolve(this.waitForRefs())
        }, 50)
      }
    })
  }

  handleRouting(){
    let { qsActionNeeded, qs, t } = this.props;
    let searchString = _.get(this.props, 'location.search');
    let parsedSearch = querystring.parse(searchString);
    if (parsedSearch.goToSubscriptionPage) {
      log.log('go to subscription page');
      this.selectTab('subscription');
      this.state.subscriptionTabRef.trySubscriptionClick();
    }
    else if (parsedSearch.goToFixPayment) {
      log.log('go to payment page');
      this.selectTab('subscription');
      this.state.subscriptionTabRef.tryUpdateFixPayment();
    }
    else if(parsedSearch.goToFixEmail){
      log.log('go to fix email');
      this.selectTab('general');
      this.state.generalTabRef.doEmailFixProcess();
    }
    else if(parsedSearch.doDND){
      if(qsActionNeeded) {
        log.log('go to dnd page');
        this.state.generalTabRef.promptToBlockDocDownloadAlertsIfNotAlreadyBlocked();
        this.props.setQsActionNeeded(false);
      }
    }
    else if(_.has(parsedSearch, c.querystring.goto_publisher_settings)){
      this.state.subscriptionTabRef.tryInfoServicesClick();
      this.props.setQsActionNeeded(false);
    }
    else if(parsedSearch.doBlockUnfoundUser){
      if(qsActionNeeded) {
        this.props.setQsActionNeeded(false);
        this.props.showContactConfirm(
          t('Blocking Contact'),
          null,
          t("Are you sure you want to block all email alerts from this user? The user will not know they've been blocked."),
          t("Block"),
          t("Cancel"),
          (res) => {
            if(res){
              sapi.NotifyBlock.blockGuest(qs.guest_uid)
                .then((res) => {
                  return Promise.all([
                    this.props.updateDirectMessages(),
                    this.props.updateUserBlockList()
                  ])
                })
                .then(() => {
                  //refresh block list, go there and expand it.
                  this.state.generalTabRef.userBlockListClick();
                  this.state.generalTabRef.scrollToComponentId('user_block_list');
                })
                .catch((err) => {
                  log.error('error blocking guest', err);
                  this.props.showAlert(t("Error blocking contact"), getMessageForError(err, t))
                })
            }
          }
        )
      }
    }
    else if(_.has(parsedSearch, c.querystring.stripe_return) || _.has(parsedSearch, c.querystring.stripe_refresh)){
      this.selectTab('general');
      this.state.generalTabRef.showClientInvoicingDetailsClick(_.has(parsedSearch, c.querystring.stripe_refresh), _.has(parsedSearch, c.querystring.stripe_return));
      this.props.setQsActionNeeded(false);
    }
  }

  selectTab(tab){
    this.setState({selectedTab: tab});

    Scroll.scroller.scrollTo(tab + '-tab', {
      duration: 300,
      smooth: true,
      offset: -15,
    })
  }

  getTabIndex(tab){
    if(tab === 'general'){
      return 0;
    }
    else if(tab === 'security'){
      return 1;
    }
    else{
      return 2;
    }
  }

  getTabForIndex(index){
    if(index === 0){
      return 'general'
    }
    else if(index === 1){
      return 'security';
    }
    else{
      return 'subscription'
    }
  }

  onContentsEnter(tab){
    let { selectedTab } = this.state;

    let selectedIndex = this.getTabIndex(selectedTab);
    let enterIndex = this.getTabIndex(tab);

    if(enterIndex < selectedIndex){
      this.setState({selectedTab : tab})
    }
  }

  onContentsLeave(tab){
    let leftIndex = this.getTabIndex(tab);

    if(leftIndex < 2){
      this.setState({selectedTab : this.getTabForIndex(leftIndex + 1)})
    }
  }

  renderVersion(){
    let {
      electronConfigVersion
    } = this.state;
    if(electronConfigVersion){
      return (
        <div className={'text-right light-grey-color small mb-3'}>
          electron v{electronConfigVersion}
        </div>
      )
    }
    else{
      return null;
    }
  }

  render() {
    let { loading, selectedTab } = this.state;

    if (loading) {
      return (
        <div>
          <Header showAcctHeader={true}
                  showSearch={false}
                  showBackButton={true}/>
          <div style={{paddingTop: '10vh', paddingBottom: '10vh'}}>
            <div className={'container'}>
              <div className={'row'}>
                <div className="col">
                  {PlaceholderLoaders.renderAccountPlaceholderRows(3)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Header showAcctHeader={true}
                showSearch={false}
                showBackButton={true}/>
        <div style={{paddingTop: '10vh', paddingBottom: '10vh'}}>
          <div className={'container'}>
            <div className={'row'}>
              {/*<div className={'col'}>*/}
              {/*  <nav className="position-fixed account-tabs nav flex-column">*/}
              {/*    <a href={'#'}*/}
              {/*       className={classNames('nav-link', {'active': selectedTab === 'general'})}*/}
              {/*       onClick={this.selectTab.bind(this, 'general')}>General</a>*/}
              {/*    <a href={'#'}*/}
              {/*       className={classNames('nav-link', {'active': selectedTab === 'security'})}*/}
              {/*       onClick={this.selectTab.bind(this, 'security')}>Security</a>*/}
              {/*    <a href={'#'}*/}
              {/*       className={classNames('nav-link', {'active': selectedTab === 'subscription'})}*/}
              {/*       onClick={this.selectTab.bind(this, 'subscription')}>Subscription</a>*/}
              {/*  </nav>*/}
              {/*</div>*/}
              <div className={'col'}>
                <Waypoint onEnter={this.onContentsEnter.bind(this, 'general')}
                          onLeave={this.onContentsLeave.bind(this, 'general')}>
                  <div id={'general-tab'}>
                    <GeneralTab onRef={(ref) => this.setState({generalTabRef : ref})}/>
                  </div>
                </Waypoint>
                <Waypoint onEnter={this.onContentsEnter.bind(this, 'security')}
                          onLeave={this.onContentsLeave.bind(this, 'security')}>
                  <div id={'security-tab'} className={'mt-5'}>
                    <SecurityTab/>
                  </div>
                </Waypoint>
                <Waypoint onEnter={this.onContentsEnter.bind(this, 'subscription')}
                          onLeave={this.onContentsLeave.bind(this, 'subscription')}>
                  <div id={'subscription-tab'} className={'mt-5'}>
                    <SubscriptionTab onRef={(ref) => this.setState({subscriptionTabRef : ref})}/>
                  </div>
                </Waypoint>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              {this.renderVersion()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Account.styles = {
  rowContents : {
    maxWidth: '30rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  wideRowContents : {
    maxWidth: '50rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsEmail : state.app.qsEmail,
    qsActionNeeded : state.app.qsActionNeeded,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    startup : () => dispatch(accountActions.startup()),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    updateUserBlockList: () => dispatch(accountActions.updateUserBlockList()),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Account)));
