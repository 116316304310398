import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import log from "../../../util/log";
import _ from 'lodash';
import c from "../../../util/const";
import classNames from 'classnames';
import colors from "../../../util/colors";
import utils from "../../../util/util";
import PdfSigningOverlay from "./PdfSigningOverlay";
import SignatureRequest from "../../../models/SignatureRequest";
import filters from "../../../helpers/filters";
import {withVFTranslation} from "../../../util/withVFTranslation";
import TextInputMeasurer from "../elements/TextInputMeasurer";

class PdfSignatureRequestSignedDateInput extends Component {
  
  constructor(props) {
    super(props);
    
    this.signatureRef = React.createRef();
    this.measureRef = React.createRef();
    
    this.state = {
      measuredText : '',
      inputFontSize : props.initialFontSize,
      
      textMeasurerRef : null
    }
  }
  
  componentDidMount(){
    if(this.props.onRef) {
      this.props.onRef(this)
    }
    
    this.pickTextAndMeasure();
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    let measureText = this.pickMeasureText();
    if(this.state.measuredText !== measureText){
      this.pickTextAndMeasure();
    }
    else if(prevProps.scale !== this.props.scale){
      this.pickTextAndMeasure();
    }
  }
  
  pickMeasureText(){
    let {
      overlay,
      calculatedDate
    } = this.props;
    
    let textToMeasure = '';
    if(SignatureRequest.isSignedDateUTC(overlay.signatureType)){
      textToMeasure = filters.momentUTCFilter(calculatedDate, SignatureRequest.getDateMaskForSignatureType(overlay.signatureType));
    }
    else{
      textToMeasure = filters.momentFilter(calculatedDate, SignatureRequest.getDateMaskForSignatureType(overlay.signatureType));
    }
    return textToMeasure;
  }
  
  pickTextAndMeasure(){
    
    utils.waitForCondition(() => {
        return !!(_.get(this.state, 'textMeasurerRef'));
      })
      .then(() => {
        let textToMeasure = this.pickMeasureText();
        this.setState({ measuredText : textToMeasure })
        let measurer = _.get(this.state, 'textMeasurerRef');
        measurer.startMeasure(textToMeasure, this.props.initialFontSize, this.props.overlayWidth)
          .then((measuredFontSize) => {
            log.warn('done measuring signed date input', measuredFontSize);
            this.setState({inputFontSize : measuredFontSize});
          })
          .catch((err) => {
            log.warn('error during measuring', err);
          })
      })
  }
  
  getInputRef(){
    return this.signatureRef;
  }
  
  getSignedDateFontSize(){
    return this.state.inputFontSize;
  }
  
  render(){
    let {
      t,
      containerHeight,
      overlayWidth,
      overlay,
      calculatedDate,
      dateFieldWarningSize,
    } = this.props;
    
    let {
      inputFontSize
    } = this.state;
  
    let baseColor = !overlay.confirmed ? (this.props.signaturePrimaryColor || colors.SIGNING_REQUEST) : null
    let bgColor = !overlay.confirmed ? utils.transparentizeHex(baseColor, c.pdf.SIGNATURE_REQUEST_BG_OPACITY) : null;
    return (
      <>
        <TextInputMeasurer onRef={(ref) => this.setState({textMeasurerRef: ref})}
                           measureBufferSpace={24}
                           fontClass={'courier'}
                           scaleRelativeToOverlay={this.props.scaleRelativeToOverlay} />
        <div className={`signature-request-input text-left ${overlay.confirmed ? 'confirmed' : ''}`}
             ref={this.signatureRef}
             style={{
               backgroundColor: bgColor,
               borderColor: baseColor,
               width: `${overlayWidth}px`,
               height: `${containerHeight}px`,
               lineHeight: `${overlay.confirmed ? containerHeight : containerHeight - dateFieldWarningSize}px`,
             }}>
          <div className="courier animate-font-size"
               style={{fontSize : `${inputFontSize}px`}}>
            {SignatureRequest.isSignedDateUTC(overlay.signatureType) ?
              filters.momentUTCFilter(calculatedDate, SignatureRequest.getDateMaskForSignatureType(overlay.signatureType)) :
              filters.momentFilter(calculatedDate, SignatureRequest.getDateMaskForSignatureType(overlay.signatureType))
            }
          </div>
          {!overlay.confirmed &&
          <div
            style={{
              color: colors.PRIMARY,
              zIndex : 0,
              fontSize : '8px',
              height: `${dateFieldWarningSize}px`,
              lineHeight: `${dateFieldWarningSize}px`,
              whiteSpace: 'nowrap'
            }}>
            ({t("Dated on Submission")})
          </div>
          }
        </div>
      </>
    )
  }
}

PdfSignatureRequestSignedDateInput.propTypes = {
  overlay : PropTypes.object.isRequired,
  containerHeight : PropTypes.number.isRequired,
  overlayWidth : PropTypes.number.isRequired,
  calculatedDate : PropTypes.number,
  initialFontSize : PropTypes.number.isRequired,
  scaleRelativeToOverlay : PropTypes.func.isRequired,
  dateFieldWarningSize : PropTypes.number.isRequired,
  signaturePrimaryColor : PropTypes.string,
  scale : PropTypes.number.isRequired
}

export default withVFTranslation()(PdfSignatureRequestSignedDateInput);
