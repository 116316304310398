import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";
import _ from 'lodash';
import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import filters from "../../../../helpers/filters";
import colors from "../../../../util/colors";
import Slider from 'rc-slider';
import "rc-slider/assets/index.css";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider);

const sliderCurve = Math.exp;
const inverseCurve = Math.log;

const MILLISECOND_MINUTE = (1000 * 60);
const SECONDS_PER_MINUTE = 60;
const MINUTES_HOUR = 60;
const MINUTES_DAY = MINUTES_HOUR * 24;
const MINUTES_WEEK = MINUTES_DAY * 7;
const MINUTES_YEAR = MINUTES_DAY * 365;

class SessionManagementCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      loading : false,
      isSaving : false,
      errors : [],
      sessionList : [],
      initialExpirationTimeMinutes : 0,
      expirationTimeMinutes : 0
    }
  }

  millisecondsToMinutes(val){
    return val / MILLISECOND_MINUTE
  }

  secondsToMinutes(val){
    return val / SECONDS_PER_MINUTE
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  sortSessionList(list){
    return _.sortBy(list, (session) => { return -session.updated_date })
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  updateExpirationTime(){
    let { expirationTimeMinutes } = this.state;
    let { updateAccountInfo } = this.props;

    let req = {
      session_expiry : (expirationTimeMinutes * SECONDS_PER_MINUTE)
    }
    sapi.AccountInfo.update(req)
      .then((res) => {
        log.log('update expiry res', res);

        return updateAccountInfo();
      })
      .then(() => {
        this.setState({expirationTimeMinutes : this.secondsToMinutes(this.props.accountInfo.session_expiry)})
        let { doClose } = this.props;
        doClose();
      })
      .catch((err) => {
        log.log('error saving expiry', err);
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  setInitialExpiry(sessionList){
    let { accountInfo } = this.props;

    let expiry = 0;
    if(accountInfo.session_expiry && accountInfo.session_expiry > 0){
      expiry = accountInfo.session_expiry;
    }
    else{
      let activeSession = _.find(sessionList, (session) => {
        return session.active_session_flag;
      })
      expiry = activeSession.expiry;
    }

    this.setState({
      initialExpirationTimeMinutes: this.secondsToMinutes(expiry),
      expirationTimeMinutes : this.secondsToMinutes(expiry)
    })
  }

  init() {
    let { t } = this.props;
    this.setState({loading : true});
    sapi.AccountInfo.sessionList()
      .then((res) => {
        log.log('session list', res);
        this.setState({sessionList : this.sortSessionList(res.data)});
        this.setInitialExpiry(res.data);
      })
      .catch((err) => {
        log.log('error loading sessions', err);
        this.setState({errors : [getMessageForError(err, t)]})
      })
      .finally(() => {
        this.setState({loading : false});
      })
  }

  terminateClick(session){
    let { t } = this.props;
    return sapi.AccountInfo.sessionDelete(session.sid)
      .then(() => {
        return sapi.AccountInfo.sessionList();
      })
      .then((res) => {
        log.log('session list', res);
        this.setState({sessionList : this.sortSessionList(res.data)});
      })
      .catch((err) => {
        log.log('error terminating session', err);
        this.setState({errors : [getMessageForError(err, t)]})
      })
  }

  getSliderTooltipForValue(x){
    let { t } = this.props;
    let val = Math.ceil(sliderCurve(x));
    if(val < MINUTES_HOUR){
      let numMinutes = Math.ceil(val);
      return (numMinutes === 1 ? t('1 minute') : numMinutes + t(' minutes'))
    }
    else if(val < MINUTES_DAY){
      let numHours = Math.ceil(val / MINUTES_HOUR);
      return (numHours === 1 ? t('1 hour') : numHours + t(' hours'))
    }
    else if(val < MINUTES_WEEK){
      let numDays = Math.ceil(val / MINUTES_DAY);
      return (numDays === 1 ? t('1 day') : numDays + t(' days'))
    }
    else if(val < MINUTES_YEAR){
      let numWeeks = Math.ceil(val / MINUTES_WEEK);
      return (numWeeks === 1 ? t('1 week') : numWeeks + t(' weeks'))
    }
    else if(val === MINUTES_YEAR){
      return t('1 year')
    }
    else{
      log.warn('invalid value');
      return val + t(' minutes')
    }
  }

  onSliderChange(val){
    let slideValMinutes = Math.ceil(sliderCurve(val));

    this.setState({expirationTimeMinutes : slideValMinutes})
  }

  render() {
    let { loading, errors, sessionList, isSaving, expirationTimeMinutes, initialExpirationTimeMinutes } = this.state;
    let { accountInfo, t } = this.props;

    if(loading){
      return (
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    log.log("session mgmt render", initialExpirationTimeMinutes, expirationTimeMinutes)
    return (
      <div style={styles.contentsWrap} className={'mt-3 mb-3'}>
        <div className="row">
          <div className="col text-center">

            <div style={styles.tableRow} className="row">
              <div className="col">

              </div>
              <div className="col" style={styles.flexVerticalCenter}>
                <span style={styles.tableTitle}>
                  {t("Created")}
                </span>
              </div>
              <div className="col" style={styles.flexVerticalCenter}>
                <span style={styles.tableTitle}>
                  {t("Last Authorized")}
                </span>
              </div>
              <div className="col">

              </div>
            </div>
            {_.map(sessionList, (session, index) => {
              return (
                <div key={session.sid} style={styles.tableRow} className="row">
                  <div className="col text-right" style={styles.flexVerticalCenter}>
                    {session.active_session_flag &&
                    <span>{t("Current Session")}</span>
                    }
                    {!session.active_session_flag &&
                    <span>{t("Session")} {index}</span>
                    }
                  </div>
                  <div className="col secondary-text-color font-italic"
                       style={styles.flexVerticalCenter}>
                    <span>
                      {filters.momentFilter(session.created_date, 'LL')}
                    </span>
                  </div>
                  <div className="col secondary-text-color font-italic"
                       style={styles.flexVerticalCenter}>
                    <span>
                      {filters.momentFilter(session.updated_date, 'LL')}
                    </span>
                  </div>
                  <div className="col" style={styles.flexVerticalCenter}>
                    {!session.active_session_flag &&
                    <a className="btn btn-link primary-color" onClick={this.terminateClick.bind(this, session)}>
                      {t("Terminate")}
                    </a>
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div style={styles.expiryWrap} className="row">
          <div className="col">
            <p className="secondary-text-color">
              {t("Sessions are automatically terminated after a period of inactivity of:")}
            </p>
            <div style={styles.sliderWrap}>
              <SliderWithTooltip min={inverseCurve(1)}
                                 max={inverseCurve(MINUTES_YEAR)}
                                 value={inverseCurve(expirationTimeMinutes)}
                                 marks={{
                                   [inverseCurve(1)]: t('1 min'),
                                   [inverseCurve(MINUTES_HOUR)]: t('1 hour'),
                                   [inverseCurve(MINUTES_DAY)]: t('1 day'),
                                   [inverseCurve(MINUTES_WEEK)]: t('1 week'),
                                   [inverseCurve(MINUTES_YEAR)]: t('1 year'),
                                 }}
                                 step={(inverseCurve(MINUTES_YEAR) - inverseCurve(1)) / 100} // 100 steps in range
                                 tipFormatter={this.getSliderTooltipForValue.bind(this)}
                                 onChange={this.onSliderChange.bind(this)} />
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-secondary mr-2'} onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isSaving || initialExpirationTimeMinutes === expirationTimeMinutes}
                      className={'btn btn-primary'}
                      onClick={this.updateExpirationTime.bind(this)}>
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  flexVerticalCenter : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  expiryWrap : {
    marginTop : '15px',
    textAlign : 'center'
  },
  tableRow : {
    borderBottom : `1px solid ${colors.STABLE}`,
    height : '49px',
    verticalAlign : 'baseline'
  },
  tableTitle : {
    height : '14px',
    lineHeight : '14px',
    display: 'inline-block'
  },
  contentsWrap : {
    maxWidth: '45rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  sliderWrap : {
    margin : '30px 0px 50px 0px'
  }
}

SessionManagementCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionManagementCtrl)));
