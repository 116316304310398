import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import PulseButton from '../elements/PulseButton';
import FancyInput from '../elements/FancyInput';
import Button from '../elements/Button';
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class AuthControls extends Component {

  constructor(props){
    super(props);

    this.state = {

    }
  }

  renderEmailValidationWarning(){
    let {
      showEmailValidationWarning,
      t
    } = this.props;
    if(showEmailValidationWarning) {
      return (
        <div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="alert alert-info mb-0" role="alert">
                <h5 className="alert-heading mb-1">
                  <i className="icon ion-information-circled pr-2" />
                  {t("Informational")}
                </h5>
                <p style={{fontSize: '14px'}}>
                  {t("The email address you entered may contain a typo, or may be an invalid email address. Please double-check what you’ve entered for errors.") + " " + t("You can tap \"Next\" if the email address is correct.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    let {
      step,
      authValidationErrors,
      isLoading,
      hideControls,
      hideStartOverButton,
      loginWithPayment,
      validCouponDeets,
      disabled,
      t
    } = this.props;

    if(hideControls){
      return null;
    }
    return (
      <>
        {(step === c.authSteps.email || step === c.authSteps.password) &&
        <div className="row">
          <div className="col text-right">
            <Button className="btn btn-sm btn-link light-color auth-disable-color"
                    type={'button'}
                    tabIndex={-1}
                    disabled={isLoading || disabled}
                    onClick={() => this.props.newPasswordLinkClick()}>{t("Forgot Password?")}</Button>
          </div>
        </div>
        }

        {step === c.authSteps.email && loginWithPayment && this.props.promoCodeLinkClick &&
        <div>
          <div className="row">
            <div className="col text-right">
              <Button className="btn btn-sm btn-link light-color auth-disable-color"
                      disabled={isLoading || disabled}
                      type={'button'}
                      onClick={() => this.props.promoCodeLinkClick()}>
                {validCouponDeets ? t("Remove promo code") : t("Add promo code")}
              </Button>
            </div>
          </div>
        </div>
        }

        <div className="row">
          <div className="col">
            <div style={{lineHeight: '110px'}}>
              {!hideStartOverButton &&
              <Button className="btn btn-sm btn-link light-color auth-disable-color"
                      disabled={isLoading || disabled}
                      type={'button'}
                      onClick={() => this.props.startOverBtnClick()}>
                <i className={'ion-chevron-left light-color mr-2'}/>
                {t("Start Over")}
              </Button>
              }
            </div>
          </div>
          <div className="col">
            <PulseButton onClick={() => this.props.nextBtnClick()}
                         disabled={disabled}
                         isLoading={isLoading}
                         className={'ml-auto'}
                         btnText={t('Next')}/>
          </div>
        </div>
        {this.renderEmailValidationWarning()}
        {_.map(authValidationErrors, (err) => {
          return (
            <div key={err}
                 className="alert alert-danger"
                 role="alert">
              {err}
            </div>
          )
        })}
      </>
    )
  }
}

AuthControls.propTypes = {
  nextBtnClick : PropTypes.func.isRequired,
  startOverBtnClick : PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading : PropTypes.bool.isRequired,
  authValidationErrors : PropTypes.array.isRequired,
  hideControls : PropTypes.bool.isRequired,
  hideStartOverButton : PropTypes.bool.isRequired,
  showEmailValidationWarning : PropTypes.bool,

  validCouponDeets : PropTypes.object,
  promoCodeLinkClick : PropTypes.func,
  newPasswordLinkClick : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    step : state.auth.step,
    loginWithPayment : state.auth.loginWithPayment
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthControls))
