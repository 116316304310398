import React, {Component, Fragment} from 'react';
import c from '../../util/const';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

import workspaceActions from '../../actions/workspace-actions';
import modalActions from '../../actions/modal-actions';
import uploadActions from '../../actions/upload-actions';

import _ from 'lodash';
import log from "../../util/log";
import Loading from "../partials/util/Loading";
import Header from "../partials/components/Header";
import GuestRow from "../partials/rows/GuestRow";
import ThreadRow from "../partials/rows/ThreadRow";
import DocRow from "../partials/rows/DocRow";
import Button from "../partials/elements/Button";
import sapi from "../../util/sapi";
import UploadHelper from "../partials/components/UploadHelper";

import ChatPanel from "../partials/chat/ChatPanel";
import ScrollingAccordion from "../partials/components/ScrollingAccordion";
import moment from "moment";
import NotificationIcon from "../partials/components/NotificationIcon";
import Promise from "bluebird";
import DMPanel from "../partials/chat/DMPanel";
import sharedActions from "../../actions/shared-actions";
import SearchWindow from "../modals/SearchWindow";
import appActions from "../../actions/app-actions";
import {getMessageForError} from "../../util/errors";
import SignatureRequest from "../../models/SignatureRequest";
import utils from "../../util/util";
import PendingMsgCache from "../../helpers/pending-msg-cache";
import querystring from "query-string";
import PlaceholderLoaders from "../partials/util/PlaceholderLoaders";
import {withVFTranslation} from "../../util/withVFTranslation";
import msgHelper from "../../helpers/msg-helper";
import threadActions from "../../actions/thread-actions";
import sentryHelper from "../../helpers/sentry-helper";
import UpgradeDialogNew from "../modals/UpgradeDialogNew";
import pendingMsgCache from "../../helpers/pending-msg-cache";
import ManageObjectPermissionsWindow from "../modals/ManageObjectPermissionsWindow";
import MerchantSetupWindow from "../modals/MerchantSetupWindow";

class Workspace extends Component {

  constructor(props) {
    super(props);

    this.onPdfSubmit = this.onPdfSubmit.bind(this);
    this.onAttachDocToThread = this.onAttachDocToThread.bind(this);
    this.onActiveChatPanelRef = this.onActiveChatPanelRef.bind(this);
    this.onDMRowFileDrop = this.onDMRowFileDrop.bind(this);

    this.onThreadRowFileDrop = this.onThreadRowFileDrop.bind(this);
    this.onDeleteThread = this.onDeleteThread.bind(this);
    this.threadClick = this.threadClick.bind(this);

    this.onDocSearchResultHdl = this.onDocSearchResultHdl.bind(this);

    this.onRemoveGuest = this.onRemoveGuest.bind(this);
    this.guestClick = this.guestClick.bind(this);

    this.state = {
      loading: true,
      activeChatPanelRef : null,
      leftAccordionRef : null
    }
  }

  componentWillUnmount() {
    this.props.cleanup();
    this.props.threadAction.cleanup();
  }

  componentDidMount() {
    let {history, workspaces, init} = this.props;

    let forum_id = _.get(this.props, 'match.params.forum_id');
    if (!forum_id || !workspaces || workspaces.length === 0) {
      history.push('/home');
      return;
    }

    let foundWorkspace = _.find(workspaces, ['forum_id', forum_id]);
    if(!foundWorkspace){
      log.warn(`Workspace ${forum_id} not found`);
      history.push('/home');
      return;
    }

    log.log('loading workspace', foundWorkspace);

    this.setState({loading: true})
    this.waitAndSetLoadingForum(foundWorkspace.forum_id)
      .then(() => {
        return init(foundWorkspace);
      })
      .then(() => {
        this.setState({loading: false})
        return this.handleRouting()
      })
      .then((res) => {
        if(!res){
          return this.makeInitialSelection()
        }
      })
      .then(() => {
        this.props.setLoadingForumId(null);
      })
      .catch((err) =>{
        log.log('error on ws startup', err);
        if(sapi.shouldUIErrorTriggerApplicationError(err)) {
          sentryHelper.captureMessage('ws-startup-error', err);
          this.props.setApplicationError(err);
        }
      })
  }

  waitAndSetLoadingForum(forum_id){
    //wait to clear it, then set, and wait for it to show up.
    return utils.waitForCondition(() => {
      return !_.get(this.props, 'loadingForumId');
    })
      .then(() => {
        this.props.setLoadingForumId(forum_id);
        return utils.waitForCondition(() => {
          return _.get(this.props, 'loadingForumId') === forum_id;
        })
      })
  }

  handleRouting(){
    return new Promise((resolve, reject) => {
      let {
        workspace,
        qs,
        qsActionNeeded,
        threads,
        docs,
        directMessages,
        accountInfoGuest,
        t
      } = this.props;

      if(qsActionNeeded){
        if(qs[c.querystring.sign_request_id]){
          if(qs[c.querystring.guest_uid]){
            this.props.setQsActionNeeded(false);
            sapi.DM.getSignatureRequest(qs[c.querystring.sign_request_id], qs[c.querystring.guest_uid])
              .then((res) => {
                let signatureRequest = msgHelper.formatSigningRequestForLegacyIfNeeded(res.data);
                let foundSigner = _.find(signatureRequest, (r) => r.signer_uid === accountInfoGuest.guest_uid);
                if(foundSigner && foundSigner.signed_date){
                  this.props.showAlert(t("Unable to Sign Document"), t("This document has already been signed.  You cannot sign it again."));
                  resolve(false);
                }
                else{
                  let foundDM = _.find(directMessages, (dm) => dm.guest_uid === qs[c.querystring.guest_uid]);
                  this.selectDMDoc(foundDM, qs.doc_id, true)
                    .then(() => {
                      this.state.activeChatPanelRef.fulfillDMSignatureRequest(qs[c.querystring.mesg_id], qs.doc_id, signatureRequest, qs[c.querystring.sign_request_id]);
                      resolve(true);
                    })
                }
              })
              .catch((err) => {
                log.error('unable to find signature request', err);
                this.props.showAlert(t("Unable to Sign Document"), t("We were unable to locate this signature request.  It could have been deleted."));
                resolve(false);
              })
          }
          else{
            this.props.setQsActionNeeded(false);
            sapi.Workspace.getSignatureRequest(qs[c.querystring.sign_request_id], workspace.host_uid)
              .then((res) => {
                let signatureRequest = msgHelper.formatSigningRequestForLegacyIfNeeded(res.data);
                let foundSigner = _.find(signatureRequest, (r) => r.signer_uid === accountInfoGuest.guest_uid);
                if(foundSigner && foundSigner.signed_date){
                  this.props.showAlert(t("Unable to Sign Document"), t("This document has already been signed.  You cannot sign it again."));
                  resolve(false);
                }
                else{
                  let foundThreadId = null;
                  let foundDoc = null;
                  _.each(docs, (docBlock) => {
                    _.each(docBlock.docs, (doc) => {
                      if(doc.doc_id === qs.doc_id){
                        foundDoc = doc;
                        foundThreadId = docBlock.chat_id;
                      }
                    })
                  })

                  let foundThread = _.find(threads, (t) => t.chat_id === foundThreadId);
                  if(!foundThread && !foundDoc){
                    log.error('unable to find doc or thread in ws startup handler');
                    throw new Error('unable to find thread or doc for sig request');
                  }
                  else {
                    this.onDocSearchResultHdl(foundDoc, foundThread, true)
                      .then(() => {
                        let activeChatPanelRef = _.get(this.state, 'activeChatPanelRef');
                        if (activeChatPanelRef) {
                          activeChatPanelRef.fulfillThreadSignatureRequest(qs[c.querystring.mesg_id], qs.doc_id, signatureRequest, qs[c.querystring.sign_request_id]);
                        }
                        resolve(true);
                      })
                  }
                }
              })
              .catch((err) => {
                log.error('unable to find signature request', err);
                this.props.showAlert(t("Unable to Sign Document"), t("We were unable to locate this signature request.  It could have been deleted."));
                resolve(false);
              })
          }
        }
        else if(qs[c.querystring.guest_uid]){
          this.props.setQsActionNeeded(false);
          let dm = _.find(directMessages, (dm) => dm.guest_uid === qs.guest_uid);
          if(dm) {
            this.guestClick(null, dm);
            resolve(true);
          }
          else{
            log.error('unable to find dm in ws startup handler');
            this.props.showAlert(t("Unable to Find Thread"), t("We were unable to locate this thread.  It could have been deleted."));
            resolve(false);
          }
        }
        else if(qs.forum_id && qs.chat_id){
          this.props.setQsActionNeeded(false);
          let thread = _.find(threads, (t) => t.chat_id === qs.chat_id);
          if(thread){
            this.handleThreadClick(thread);
            resolve(true);
          }
          else{
            log.error('unable to find thread in ws startup handler');
            this.props.showAlert(t("Unable to Find Thread"), t("We were unable to locate this thread.  It could have been deleted."));
            resolve(false);
          }
        }
        else if(qs.forum_id && qs.doc_id){
          this.props.setQsActionNeeded(false);
          let foundThreadId = null;
          let foundDoc = null;
          _.each(docs, (docBlock) => {
            _.each(docBlock.docs, (doc) => {
              if(doc.doc_id === qs.doc_id){
                foundDoc = doc;
                foundThreadId = docBlock.chat_id;
              }
            })
          })

          let foundThread = _.find(threads, (t) => t.chat_id === foundThreadId);
          if(!foundThread && !foundDoc){
            log.error('unable to find doc or thread in ws startup handler');
            this.props.showAlert(t("Unable to Find Document"), t("We were unable to locate this document.  It could have been removed."));
            resolve(false);
          }
          else{
            this.onDocSearchResultHdl(foundDoc, foundThread);
            resolve(true);
          }
        }
        else{
          this.props.setQsActionNeeded(false);
          resolve(false);
        }
      }
      else{
        //This is for routing outside of the main initial querystring.
        let searchString = _.get(this.props, 'location.search');
        let parsedSearch = querystring.parse(searchString);
        if(parsedSearch.chat_id){
          let thread = _.find(threads, (t) => t.chat_id === parsedSearch.chat_id);
          this.handleThreadClick(thread);
          resolve(true);
        }
        else{
          resolve(false);
        }
      }
    })
  }

  makeInitialSelection(deletingThread, deletingDM){
    let { workspace, threads, directMessages, guests } = this.props;

    if(_.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT){
      //then there's no DM and only one thread.  Select it.
      let thread = threads[0];
      return this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
        .then((res) => {
          this.markThreadAsViewed(thread);
        })
    }

    let mostRecentThread = null;
    _.each(threads, (thread) => {
      if(!deletingThread || deletingThread.chat_id !== thread.chat_id){
        if(!mostRecentThread){
          mostRecentThread = thread;
        }

        if(moment(thread.updated_date).isAfter(mostRecentThread.updated_date)){
          mostRecentThread = thread;
        }
      }
    })

    let mostRecentDM = null;
    _.each(directMessages, (dm) => {
      if(!deletingDM || deletingDM.guest_uid !== dm.guest_uid) {
        let found = _.find(guests, (guest) => dm.guest_uid === guest.guest_uid)

        if (found) {
          if (!mostRecentDM) {
            mostRecentDM = dm;
          }

          if (moment(dm.updated_date).isAfter(mostRecentDM.updated_date)) {
            mostRecentDM = dm;
          }
        }
      }
    })

    let recentThreadUpdatedDate = _.get(mostRecentThread, 'updated_date');
    let recentDMUpdatedDate = _.get(mostRecentDM, 'updated_date');
    let mostRecentIsThread = false;

    log.log('detecting mostRecent Things', recentThreadUpdatedDate, recentDMUpdatedDate);

    if(recentThreadUpdatedDate && recentDMUpdatedDate){
      mostRecentIsThread = moment(recentThreadUpdatedDate).isAfter(recentDMUpdatedDate)
    }
    else if(!recentThreadUpdatedDate && recentDMUpdatedDate){
      mostRecentIsThread = false;
    }
    else if(recentThreadUpdatedDate && !recentDMUpdatedDate){
      mostRecentIsThread = true;
    }

    if(mostRecentIsThread){
      return this.props.setActiveThread(workspace.forum_id, workspace.host_uid, mostRecentThread)
        .then((res) => {

          this.markThreadAsViewed(mostRecentThread);

          //We need to scroll here, since threads are shown below dms, and the top thread could be hidden.
          setTimeout(() => {
            if(this.state.leftAccordionRef){
              //the list index is 1 here, because we add threads to the accordion as the second item.
              this.state.leftAccordionRef.scrollElementToView(1, `thread-row-${mostRecentThread.chat_id}`)
            }
          })

          return res;
        })
    }
    else if(mostRecentDM){
      return this.props.setActiveDM(mostRecentDM)
        .then((res) => {
          this.markDMAsViewed(mostRecentDM);
          return res;
        })
    }
    else if(workspace.host_uid){
      let host = _.find(directMessages, (dm) => dm.guest_uid === workspace.host_uid);
      if(!host){
        this.props.threadAction.clearActiveDM();
        return Promise.resolve(true);
      }
      return this.props.setActiveDM(host)
        .then((res) => {
          this.markDMAsViewed(host);
          return res;
        })
    }
    else{
      this.props.threadAction.clearActiveDM();
      this.props.threadAction.clearActiveThread();
    }
  }

  newGuestClick() {
    this.props.showAddGuestWindow((res) => {
      if(res){
        let { workspace } = this.props;
        Promise.all([
            this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true),
            this.props.refreshGuests(workspace.forum_id, workspace.host_uid),
            this.props.updateDirectMessages()
          ])
          .then(() => {
            let found = _.find(this.props.guests, (g) => g.guest_uid === res.data[0].guest_uid);
            let foundDM = _.find(this.props.directMessages, (g) => g.guest_uid === found.guest_uid);
            if(found){
              this.guestClick(found, foundDM);
            }
          })

      }
    })
  }

  newThreadClick() {
    let {showNewItem, workspace, refreshThreads, setActiveThread, t} = this.props;

    let addNewThread = (label) => {
      return sapi.Threads.add(label, workspace.forum_id)
    }

    showNewItem(t('New Thread'), t('New Thread Name'), t('Enter a name for your new Thread'), addNewThread, (res) => {
      if (res) {
        refreshThreads(workspace.forum_id, workspace.host_uid, true)
          .then(() => {
            let { threads } = this.props;
            let foundThread = null;
            _.each(threads, (thread) => {
              if(thread.chat_id === res.data.chat_id){
                foundThread = thread;
              }
            })
            if(foundThread) {
              setActiveThread(workspace.forum_id, workspace.host_uid, foundThread)
            }
          })
      }
    })
  }

  guestClick(guest, dm) {
    this.props.setActiveDM(dm)
      .then(() => {
        this.markDMAsViewed(dm);
      })
  }

  markDMAsViewed(dm){
    if(!_.get(dm, 'notify_flag')){
      return;
    }

    let { workspace } = this.props;

    return sapi.DM.mark(dm.guest_uid, 0)
      .then( () => {
        return Promise.all([
          this.props.refreshWorkspace(workspace.forum_id),
          this.props.updateDirectMessages()
        ])
      })
  }

  markThreadAsViewed(thread){
    if(!_.get(thread, 'notify_flag')){
      return;
    }

    let { workspace, refreshWorkspace, refreshThreads, activeThread } = this.props;

    return sapi.Threads.mark(workspace.forum_id, workspace.host_uid, thread.chat_id, 0)
      .then( () => {
        return Promise.all([
          refreshWorkspace(workspace.forum_id),
          refreshThreads(workspace.forum_id, workspace.host_uid, true)
        ])
      })
  }

  handleThreadClick(thread){
    let {workspace, setActiveThread, activeThread} = this.props;
    if(activeThread && activeThread.chat_id === thread.chat_id){
      if(thread.notify_flag){
        this.markThreadAsViewed(thread);
      }

      //otherwise nothing to do.
    }
    else{
      return setActiveThread(workspace.forum_id, workspace.host_uid, thread)
        .then(() => {
          this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
          this.markThreadAsViewed(thread);
        })
    }
  }

  threadClick = (thread) => () => {
    this.handleThreadClick(thread);
  }

  onDMRowFileDrop = (dm) => (files) =>{
    log.log('got files dm row workspace', dm, files);

    if(!this.props.activeDM || this.props.activeDM.guest_uid !== dm.guest_uid){
      PendingMsgCache.addDocsToDmCache(dm.guest_uid, files);
    }
    else{
      utils.waitForCondition(() => {
          return _.get(this.props, 'activeDM.guest_uid') === dm.guest_uid;
        })
        .then(() => {
          this.state.activeChatPanelRef.chatFileDrop(files);
        })
    }

    this.props.setActiveDM(dm)
      .then(() => {
        this.markDMAsViewed(dm);
      })
  }

  selectDMDoc(dm, doc_id, skipDocSelection){
    return this.props.setActiveDM(dm)
      .then(() => {
        this.markDMAsViewed(dm);

        if(!skipDocSelection) {
          let foundDoc = _.find(this.props.activeDMDocs, (doc) => doc.doc_id === doc_id);
          if (foundDoc) {
            setTimeout(() => {
              this.state.activeChatPanelRef.selectDocAndNavigate(foundDoc);
            })
          }
        }
      })
  }

  onDocSearchResultHdl(doc, thread, skipDocSelection){
    if (thread) {
      let {workspace} = this.props;
      return this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
        .then((res) => {
          if (!skipDocSelection) {
            utils.waitForCondition(() => {
              return _.get(this.props, 'activeThread.chat_id') === thread.chat_id && this.state.activeChatPanelRef;
            })
              .then(() => {
                this.state.activeChatPanelRef.selectDocAndNavigate(doc, thread);
              })
          }
        })
    }
    else if (!skipDocSelection) {
      utils.waitForCondition(() => {
        return !!this.state.activeChatPanelRef;
      })
        .then(() => {
          this.state.activeChatPanelRef.selectDocAndNavigate(doc);
        })

    }
  }

  onThreadRowFileDrop = (thread) => (files) => {
    log.log('got files thread row', thread, files);

    if(!this.props.activeThread || this.props.activeThread.chat_id !== thread.chat_id){
      PendingMsgCache.addDocsToThreadCache(thread.chat_id, files);
    }
    else{
      utils.waitForCondition(() => {
          return _.get(this.props, 'activeThread.chat_id') === thread.chat_id;
        })
        .then(() => {
          this.state.activeChatPanelRef.chatFileDrop(files);
        })
    }

    let { workspace } = this.props;
    this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
      .then(() => {
        this.markThreadAsViewed(thread);
      })
  }

  onRemoveGuest(guest, dm){
    let { activeDM } = this.props;
    if(activeDM && dm.guest_uid === activeDM.guest_uid){
      this.makeInitialSelection(null, dm);
    }
  }

  onDeleteThread(thread){
    let { activeThread } = this.props;
    if(activeThread && thread.chat_id === activeThread.chat_id){
      this.props.threadAction.clearActiveThread();
      this.makeInitialSelection(thread);
    }
  }

  doBeforeDMPrint(guest_uid){
    log.log('doBeforeDMPrint', guest_uid);

    let { activeDM, directMessages } = this.props;

    if(activeDM && activeDM.guest_uid === guest_uid){
      return this.state.activeChatPanelRef.doBeforePrint();
    }
    else{
      //we need to activate the proper thread, and print once it's done loading
      let dm = _.find(directMessages, (d) => d.guest_uid === guest_uid);
      return this.props.setActiveDM(dm)
        .then(() => {
          this.markDMAsViewed(dm);
          return utils.waitForCondition(() => !!this.state.activeChatPanelRef);
        })
        .then(() => {
          return this.state.activeChatPanelRef.doBeforePrint();
        })
    }
  }

  doBeforeThreadPrint(chat_id){
    log.log('doBeforeThreadPrint', chat_id);

    let { workspace, activeThread, threads } = this.props;

    if(activeThread && activeThread.chat_id === chat_id){
      return this.state.activeChatPanelRef.doBeforePrint();
    }
    else{
      //we need to activate the proper thread, and print once it's done loading
      let thread = _.find(threads, (t) => t.chat_id === chat_id);
      return this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
        .then(() => {
          this.markThreadAsViewed(thread);
          return utils.waitForCondition(() => !!this.state.activeChatPanelRef);
        })
        .then(() => {
          return this.state.activeChatPanelRef.doBeforePrint();
        })
    }
  }

  onPrintError(err){
    this.state.activeChatPanelRef.onPrintError(err);
  }

  doAfterPrintPreview(){
    this.state.activeChatPanelRef.doAfterPrintPreview();
  }

  getPrintPreviewContents(){
    return this.state.activeChatPanelRef.getPrintPreviewContents();
  }

  renderLeftColumn(){
    let {
      threads,
      guests,
      directMessages,
      activeThread,
      activeDM,
      threadParticipantLookup,
      threadNotifyCount,
      workspace,
      t
    } = this.props;
    let { loading } = this.state;

    if(!workspace){
      return null;
    }

    let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
    let isWorkspaceHost = !workspace.host_uid;
    let accordionItems = [];

    if(!isContentWorkspace && isWorkspaceHost) {
      accordionItems.push({
        id: 'guests',
        headerRenderFn: () => {
          let notifyCount = 0;
          _.each(guests, (g) => {
            let dm = _.find(directMessages, (d) => d.guest_uid === g.guest_uid);
            if(dm && dm.notify_flag){
              notifyCount++;
            }
          })
          return (
            <div className="d-inline-block pl-2 w-100">
              <h4 className="m-0 d-flex w-100 ws-accordion-header">
                <NotificationIcon iconCls="ion-android-person"
                                  value={notifyCount} />
                <span className="accordion-title-host auto-ellipsis">{t("Guests")}</span>
                <Button onClick={this.newGuestClick.bind(this)}
                        className={'btn btn-lg btn-icon ion-ios-plus-outline no-focus tab-add-btn'}/>
              </h4>
            </div>
          )
        },
        listRenderFn: () => {
          if(!guests){
            return null;
          }

          let sortedGuests = [];
          _.each(guests, (guest) => {
            let dm = _.find(directMessages, (item) => {
              return item.guest_uid === guest.guest_uid
            });
            guest.$dm = dm;
            sortedGuests.push(guest);
          })
          sortedGuests = _.sortBy((sortedGuests), (guest) => {
            return guest.$dm ? -guest.$dm.updated_date : -1;
          });
          return (
            <div>
              {sortedGuests.map((guest) => {
                return (
                  <div key={guest.guest_uid} className="position-relative">
                    <UploadHelper onDrop={this.onDMRowFileDrop(guest.$dm)}
                                  generateImagePreviews={true}
                                  allowMultiple={true}
                                  disableClick={true}>
                      <GuestRow row={guest}
                                dm={guest.$dm}
                                isActive={!!(activeDM && activeDM.guest_uid === guest.guest_uid)}
                                onRemoveGuest={this.onRemoveGuest}
                                onSendInvoiceClick={(dm) => this.onSendInvoiceClick(null, dm)}
                                onPrintPreviewError={this.onPrintError.bind(this)}
                                onBeforePrintPreview={this.doBeforeDMPrint.bind(this)}
                                onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                                getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                                onDocAttach={this.onAttachDocToThread}
                                onItemClick={this.guestClick}/>
                    </UploadHelper>
                  </div>
                )
              })}
            </div>
          )
        }
      })
    }
    else if(!isContentWorkspace){
      let host = _.find(directMessages, (dm) => dm.guest_uid === workspace.host_uid);
      accordionItems.push({
        id: 'guests',
        headerRenderFn: () => {
          return (
            <div className="d-inline-block pl-2 w-100">
              <h4 className="m-0 d-flex w-100 ws-accordion-header">
                <NotificationIcon iconCls="ion-android-person"
                                  value={host && host.notify_flag ? 1 : 0} />
                <span className="accordion-title-guest auto-ellipsis">{t("Workspace Host")}</span>
              </h4>
            </div>
          )
        },
        listRenderFn: () => {
          if(!host){
            return PlaceholderLoaders.renderContactInfoPlaceholderRows(1);
          }

          return (
            <div className="position-relative">
              <UploadHelper onDrop={this.onDMRowFileDrop(host)}
                            generateImagePreviews={true}
                            allowMultiple={true}
                            disableClick={true}>
                <GuestRow row={host}
                          dm={host}
                          isActive={!!(activeDM && activeDM.guest_uid === host.guest_uid)}
                          onRemoveGuest={this.onRemoveGuest}
                          onSendInvoiceClick={(dm) => this.onSendInvoiceClick(null, dm)}
                          onPrintPreviewError={this.onPrintError.bind(this)}
                          onBeforePrintPreview={this.doBeforeDMPrint.bind(this)}
                          onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                          getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                          onDocAttach={this.onAttachDocToThread}
                          onItemClick={this.guestClick}/>
              </UploadHelper>
            </div>
          )
        }
      })
    }

    if(threads || loading) {
      accordionItems.push({
        id: 'threads',
        headerRenderFn: () => {
          return (
            <div className="d-inline-block pl-2 w-100">
              <h4 className="m-0 d-flex w-100 ws-accordion-header">
                <NotificationIcon iconCls="ion-chatbox"
                                  value={threadNotifyCount} />
                <span className={`${workspace.host_uid ? 'accordion-title-guest' : 'accordion-title-host'} auto-ellipsis`}>{t("Workspace Threads")}</span>
                {isWorkspaceHost && !isContentWorkspace &&
                <Button onClick={this.newThreadClick.bind(this)}
                        style={{
                          fontSize: '26px'
                        }}
                        className={`btn btn-lg btn-icon ion-ios-plus-outline tab-add-btn no-focus`}/>
                }
              </h4>
            </div>
          )
        },
        listRenderFn: () => {
          if(!threads){
            return PlaceholderLoaders.renderDocPlaceholderRows(10);
          }

          let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
          return (
            <div>
              {threads.map((thread) => {
                let threadGuests = threadParticipantLookup ? (threadParticipantLookup[thread.chat_id] || []) : [];
                return (
                  <div key={thread.chat_id} className="position-relative">
                    <UploadHelper onDrop={this.onThreadRowFileDrop(thread)}
                                  disabled={isContentWorkspace}
                                  generateImagePreviews={true}
                                  allowMultiple={true}
                                  disableClick={true}>
                      <ThreadRow guests={threadGuests}
                                 isActive={!!(activeThread && activeThread.chat_id === thread.chat_id)}
                                 row={thread}
                                 onPrintPreviewError={this.onPrintError.bind(this)}
                                 onBeforePrintPreview={this.doBeforeThreadPrint.bind(this)}
                                 onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                                 getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                                 onDocAttach={this.onAttachDocToThread}
                                 onDeleteThread={this.onDeleteThread}
                                 onSendInvoiceClick={this.onSendInvoiceClick.bind(this)}
                                 onItemClick={this.threadClick(thread)}/>
                    </UploadHelper>
                  </div>
                )}
              )}
            </div>
          )
        }
      })
    }

    return (
      <div style={styles.leftCol} className={classNames('col-3')}>
        <div style={styles.leftColInner}>
          {/*because has-header is present on this container, we have to pass the offset to the scrolling accordion*/}
          {/*Could we improve this?*/}
          <ScrollingAccordion scrollOffset={70}
                              onRef={(ref) => { this.setState({leftAccordionRef : ref}) }}
                              items={accordionItems}/>
        </div>
      </div>
    )
  }

  refreshDMMessages(useDataDate){
    return this.props.threadAction.refreshActiveThreadMessages(useDataDate);
  }

  changeWorkspaces(newWorkspaceId){
    let { workspace } = this.props;
    //Short circuit if you're changing to the same workspace.  bug 2256
    if(newWorkspaceId === workspace.forum_id){
      return Promise.resolve(true);
    }

    this.setState({loading: true})
    return new Promise((resolve, reject) => {
      this.props.cleanup();
      this.props.threadAction.cleanup();

      this.props.history.push(`/workspace/${newWorkspaceId}`);
      utils.waitForCondition(() => {
        return !this.props.workspace;
      })
        .then(() => {
          let { workspaces } = this.props;
          let foundWorkspace = _.find(workspaces, ['forum_id', newWorkspaceId])
          this.setState({loading: true})
          return this.props.init(foundWorkspace)
        })
        .then(() => {
          this.setState({loading: false})
          return this.handleRouting()
        })
        .then((res) => {
          if(!res){
            return this.makeInitialSelection();
          }
        })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          log.error('error changing workspaces', err);
          reject(err);
          this.props.history.push('/home');
        })
    })
  }

  onPdfSubmit(res){
    let { activeThread, activeDM, guests, directMessages, workspace } = this.props;
    log.log('onPdfSubmit in workspace', res);

    //Ewwwww, this is copied in onAttachDocToThread.  Fix this immediately.
    //destination is either thread or DM.  Need to route to the proper place.
    if(res.guest_uid){
      if(activeDM && activeDM.guest_uid === res.guest_uid){
        this.state.activeChatPanelRef.chatFileDrop([res.doc]);
      }
      else{
        PendingMsgCache.addDocsToDmCache(res.guest_uid, [res.doc]);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let foundGuest = _.find(guests, (g) => g.guest_uid === res.guest_uid);
      //If they're a guest in the workspace, OR they're the host of the workspace.
      if(foundGuest || workspace.host_uid && workspace.host_uid === res.guest_uid){
        let dm = _.find(directMessages, (d) => d.guest_uid === res.guest_uid);
        return this.props.setActiveDM(dm)
          .then(() => {
            this.markDMAsViewed(dm);
          })
      }
      else{
        //deeplink to homepage, select DM, docs are already loaded in pending cache.
        this.props.history.push(`/home?guest_uid=${res.guest_uid}`);
      }
    }
    else{
      //So the pending msg cache is only hooked up to update on mount or unmounting of the chatpanel/dmpanel.
      //that means if you're adding some attach files to the current thread we need to tell it to update.
      //This is a little tricky, since you could have pending messages or docs already in progress.
      //This just adds it directly in that case rather than going through the pending msg cache.
      if(activeThread && activeThread.chat_id === res.chat_id){
        this.state.activeChatPanelRef.chatFileDrop([res.doc]);
      }
      else{
        PendingMsgCache.addDocsToThreadCache(res.chat_id, [res.doc]);
      }
      this.changeWorkspaces(res.forum_id)
        .then(() => {
          let { workspace, threads } = this.props;
          let thread = _.find(threads, (t) => t.chat_id === res.chat_id);
          this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
            .then(() => {
              this.markThreadAsViewed(thread);
            })
        })
    }
  }

  onAttachInvoice(res){
    log.log('on attach invoice to thread in ws', res);

    let { activeThread, activeDM, guests, directMessages, workspace } = this.props;

    //Ewwwwww, this is similar to onPdfSubmit, as well as onAttachDocToThread.  Fix this immediately
    //It's already stored in the pending cache, we just need to route to the proper place.
    if(res.guest_uid){
      if(activeDM && activeDM.guest_uid === res.guest_uid){
        this.state.activeChatPanelRef.chatFileDrop([res.invoice]);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let foundGuest = _.find(guests, (g) => g.guest_uid === res.guest_uid);
      //If they're a guest in the workspace, OR they're the host of the workspace.
      if(foundGuest || workspace.host_uid && workspace.host_uid === res.guest_uid){
        let dm = _.find(directMessages, (d) => d.guest_uid === res.guest_uid);
        return this.props.setActiveDM(dm)
          .then(() => {
            this.markDMAsViewed(dm);
          })
      }
      else{
        //deeplink to homepage, select DM, docs are already loaded in pending cache.
        this.props.history.push(`/home?guest_uid=${res.guest_uid}`);
      }
    }
    else{
      //So the pending msg cache is only hooked up to update on mount or unmounting of the chatpanel/dmpanel.
      //that means if you're adding some attach files to the current thread we need to tell it to update.
      //This is a little tricky, since you could have pending messages or docs already in progress.
      //This just adds it directly in that case rather than going through the pending msg cache.
      if(activeThread && activeThread.chat_id === res.chat_id){
        this.state.activeChatPanelRef.chatFileDrop([res.invoice]);
      }

      this.changeWorkspaces(res.forum_id)
        .then(() => {
          let { workspace, threads } = this.props;
          let thread = _.find(threads, (t) => t.chat_id === res.chat_id);
          this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
            .then(() => {
              this.markThreadAsViewed(thread);
            })
        })
    }
  }

  managePermissionsThread(thread) {
    let {workspace} = this.props;

    this.props.showManageObjectPermissionsWindow(
      thread.chat_id,
      thread.label,
      thread,
      ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD,
      workspace,
      (res) => {
        if (res) {
          this.props.updateNotifications();
          this.props.refreshGuests(workspace.forum_id, workspace.host_uid);
          this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
          this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
          this.props.updateDirectMessages();
        }
      })
  }

  onSendInvoiceClick(thread, dm){
    let {
      workspace,
      accountInfo,
      accountClassInfo,
      merchantInfo,
      t
    } = this.props;

    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    let isHealthy = MerchantSetupWindow.isMerchantStatusHealthy(merchantInfo);
    let fixMerchantWindowPromise = Promise.resolve(true);
    if (!merchant_flag || !isHealthy) {
      //A little bit of voodoo here.  The only thing that this can return us is cancel or
      //Reactivate.  For the setup cases, they will be navigated away from the verifyle
      //app to do the merchant setup.
      //So if they reactivated, continue with sending an invoice.  Otherwise they
      //did something that eventually ended in a cancel.
      fixMerchantWindowPromise = this.props.showMerchantSetupWindowUpgradeFlow()
        .then((res) => {
          return res === MerchantSetupWindow.WINDOW_RESULT.REACTIVATE;
        })
    }

    fixMerchantWindowPromise
      .then((res) => {
        if (!res) {
          log.log('cancel received from merchant setup flow', res);
          return;
        }

        let participants = null;
        if (thread) {
          participants = _.filter(this.props.threadParticipantLookup[thread.chat_id], (user) => user.guest_uid !== accountInfo.uid)
          if (participants.length === 0) {
            this.props.showConfirm(
              t('No Guests in Thread'),
              t("There are no Guests in this Thread yet. To create an invoice, please add a Guest first."),
              (res) => {
                if (res) {
                  this.managePermissionsThread(thread);
                }
              },
              t('Add Guest'),
              t('Cancel')
            );
            return;
          }
        }
        else {
          participants = [dm];
        }

        let foundInvoice = false;
        if (thread) {
          let cached = pendingMsgCache.fetchThread(thread.chat_id);
          _.each(cached.docs, (d) => {
            if (d.isInvoice) {
              foundInvoice = true;
            }
          })
        }
        else if (dm) {
          let cached = pendingMsgCache.fetchDm(dm.guest_uid);
          _.each(cached.docs, (d) => {
            if (d.isInvoice) {
              foundInvoice = true;
            }
          })
        }

        if (foundInvoice) {
          //we only allow one invoice per thread/dm for now.
          this.props.showAlert(t("One Invoice at a time"), t("Only one invoice at a time can be added to a thread.  Please send your current invoice before creating a new one."));
          return;
        }

        if (thread) {
          this.props.showSendInvoiceWindow(workspace.forum_id, workspace.host_uid, thread.chat_id, null, participants, (res) => {
            if (res && res.chat_id) {
              //then route to the guest_uid, and restore pending messages.
              this.onAttachInvoice(res);
            }
          })
        }
        else if (dm) {
          this.props.showSendInvoiceWindow(null, null, null, dm.guest_uid, participants, (res) => {
            log.log('showSendInvoiceWindow closed', res);
            if (res && res.guest_uid) {
              this.onAttachInvoice(res);
            }
          })
        }

      })
  }

  onAttachDocToThread(res){
    log.log('on attach to thread in ws', res);

    let { activeThread, activeDM, guests, directMessages, workspace } = this.props;

    let updatedDocs = [];
    _.each(res.docs, (d) => {
      updatedDocs.push({
        doc_label : d.label,
        doc_id : d.doc_id,
        forum_id : d.forum_id,
        host_uid : d.host_uid,
        forum_label : ''
      })
    })

    //Ewwwwww, this is copied in onPdfSubmit.  Fix this immediately
    //destination is either thread or DM.  Need to route to the proper place.
    if(res.dest_guest_uid){
      if(activeDM && activeDM.guest_uid === res.dest_guest_uid){
        this.state.activeChatPanelRef.mergeAttachDocsWithCurrentMsg(updatedDocs);
      }
      else{
        PendingMsgCache.attachPendingDocsToDM(res.dest_guest_uid, updatedDocs);
      }

      //It's loaded in the right place now.  We just need to navigate properly.
      let foundGuest = _.find(guests, (g) => g.guest_uid === res.dest_guest_uid);
      //If they're a guest in the workspace, OR they're the host of the workspace.
      if(foundGuest || workspace.host_uid && workspace.host_uid === res.dest_guest_uid){
        let dm = _.find(directMessages, (d) => d.guest_uid === res.dest_guest_uid);
        return this.props.setActiveDM(dm)
          .then(() => {
            this.markDMAsViewed(dm);
          })
      }
      else{
        //deeplink to homepage, select DM, docs are already loaded in pending cache.
        this.props.history.push(`/home?guest_uid=${res.dest_guest_uid}`);
      }
    }
    else{
      //So the pending msg cache is only hooked up to update on mount or unmounting of the chatpanel/dmpanel.
      //that means if you're adding some attach files to the current thread we need to tell it to update.
      //This is a little tricky, since you could have pending messages or docs already in progress.
      //This just adds it directly in that case rather than going through the pending msg cache.
      if(activeThread && activeThread.chat_id === res.dest_chat_id){
        this.state.activeChatPanelRef.mergeAttachDocsWithCurrentMsg(updatedDocs);
      }
      else{
        PendingMsgCache.attachPendingDocsToChat(res.dest_chat_id, updatedDocs);
      }
      this.changeWorkspaces(res.dest_forum_id)
        .then(() => {
          let { workspace, threads } = this.props;
          let thread = _.find(threads, (t) => t.chat_id === res.dest_chat_id);
          this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread)
            .then(() => {
              this.markThreadAsViewed(thread);
            })
        })
    }
  }

  onActiveChatPanelRef(ref){
    this.setState({activeChatPanelRef : ref})
  }

  //Big debounce time!  These are scroll events, and we don't
  //want a string of mark requests to go out for each scroll event
  //so put a decent timeout on it.
  onThreadMessagePanelScroll = _.throttle((evt) => {
    let {
      activeThread,
      activeDM
    } = this.props;
    if(activeThread && activeThread.notify_flag){
      this.markThreadAsViewed(activeThread);
    }
    else if(activeDM && activeDM.notify_flag){
      this.markDMAsViewed(activeDM);
    }
  }, 5000)

  renderCenterColumn() {
    let { loading } = this.state;
    let { activeThread, threads, guests, activeDM, activeDMMessageBlocks, messageBlocksId, activeDMDocs, activeThreadDocs, workspace, t } = this.props;

    if (!loading && (activeThread || activeDM)) {
      return (
        <div style={styles.centerCol} className={classNames('col-9')}>
          {activeThread &&
          <ChatPanel thread={activeThread}
                     onMessagePanelScroll={this.onThreadMessagePanelScroll.bind(this)}
                     onAttachDocToThread={this.onAttachDocToThread}
                     onSendInvoiceClick={(thread) => this.onSendInvoiceClick(thread, null)}
                     onPdfSubmit={this.onPdfSubmit}
                     onRef={this.onActiveChatPanelRef}
                     threadDocs={activeThreadDocs}/>
          }
          {activeDM &&
          <DMPanel dm={activeDM}
                   onMessagePanelScroll={this.onThreadMessagePanelScroll.bind(this)}
                   isInTabView={false}
                   onPdfSubmit={this.onPdfSubmit}
                   onSendInvoiceClick={(dm) => this.onSendInvoiceClick(null, dm)}
                   onAttachDocToThread={this.onAttachDocToThread}
                   onRef={this.onActiveChatPanelRef}
                   dmDocs={activeDMDocs}
                   refreshMessages={this.refreshDMMessages.bind(this)}
                   messageBlocksId={messageBlocksId}
                   messageBlocks={activeDMMessageBlocks} />
          }
        </div>
      )
    }
    else if(!workspace || !threads || !guests){
      return null;
    }
    else if(workspace.host_uid || (threads.length > 0 || guests.length > 0)){
      return null;
    }

    return (
      <div style={styles.centerCol} className={classNames('col-9')}>
        <div className="center-col d-flex flex-column empty-state" >
          <div className="text-center" style={{marginTop: '15vh'}}>
            <h4 className="dark-color">
              {t("Get started by adding a guest")}
            </h4>
            <p className="secondary-text-color">
              {t("Once you have guests, you can share documents and threads with them.")}
            </p>
            <div className="mt-5">
              <button className="btn btn-lg btn-primary"
                      onClick={this.newGuestClick.bind(this)}>
                {t("Add a Guest")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { workspace } = this.props;
    return (
      <div className={'column-scroll-layout has-header'}>
        <Header isLockedToTop={true}
                showSearch={_.get(workspace, 'forum_type') !== c.FORUM_TYPES.FORUM_CONTENT}
                searchContext={SearchWindow.SEARCH_CONTEXT.WORKSPACE}
                showAcctHeader={true}
                onDocSearchResult={this.onDocSearchResultHdl}
                showBackButton={true}/>
        <div className="container-fluid">
          <div className={'row'}>

            {this.renderLeftColumn()}
            {this.renderCenterColumn()}

          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  leftCol : {
    paddingLeft : '0px',
    paddingRight : '0px'
  },
  leftColInner : {
  },
  centerCol : {
    paddingLeft : '0px',
    paddingRight : '0px'
  },
  rightCol : {
    paddingLeft : '0px',
    paddingRight : '0px'
  }
}

const mapStateToProps = (state) => {
  return {
    loadingForumId: state.workspace.loadingForumId,
    workspaces: state.shared.workspaces,
    workspace: state.workspace.workspace,
    threads: state.workspace.threads,
    docs : state.workspace.docs,
    directMessages: state.shared.directMessages,
    threadNotifyCount: state.workspace.threadNotifyCount,
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    docNotifyCount: state.workspace.docNotifyCount,
    guests: state.workspace.guests,
    activeThread: state.thread.activeThread,
    activeDMDataDate: state.thread.activeDMDataDate,
    activeDoc: state.workspace.activeDoc,
    uploadQueue: state.upload.uploadQueue,
    activeUpload: state.upload.activeUpload,
    activeDM: state.thread.activeDM,
    activeDMDocs: state.thread.activeDMDocs,
    activeDMMessageBlocks: state.thread.activeDMMessageBlocks,
    activeThreadDocs : state.thread.activeThreadDocs,
    messageBlocksId : state.thread.messageBlocksId,
    qs : state.app.qs,
    qsActionNeeded : state.app.qsActionNeeded,
    accountInfo : state.shared.accountInfo,
    accountInfoGuest : state.shared.accountInfoGuest,
    accountClassInfo : state.shared.accountClassInfo,
    merchantInfo : state.shared.merchantInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: workspace => dispatch(workspaceActions.init(workspace)),
    setLoadingForumId: (forum_id) => dispatch(workspaceActions.setLoadingForumId(forum_id)),
    cleanup: () => dispatch(workspaceActions.cleanup()),
    refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(workspaceActions.refreshThreads(forum_id, host_uid, doRefreshGuests)),
    refreshDocs: (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid)),
    refreshGuests: (forum_id, host_uid) => dispatch(workspaceActions.refreshGuests(forum_id, host_uid)),
    refreshWorkspace: (forum_id) => dispatch(workspaceActions.refreshWorkspace(forum_id)),
    setActiveThread: (forum_id, host_uid, thread) => dispatch(workspaceActions.setActiveThread(forum_id, host_uid, thread)),
    setActiveDM: (dm) => dispatch(workspaceActions.setActiveDM(dm)),
    showNewItem: (title, labelText, placeholderText, addNewFn, callback) => dispatch(modalActions.showNewItem(title, labelText, placeholderText, addNewFn, callback)),
    showAddGuestWindow: (callback) => dispatch(modalActions.showAddGuestWindow(callback)),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    threadAction : {...threadActions.mapToDispatch(dispatch)}
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Workspace)));
