import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import uploadActions from '../actions/upload-actions';
import modalActions from '../actions/modal-actions';

import workspaceActions from '../actions/workspace-actions'

import PropTypes from 'prop-types';

import log from "../util/log";
import sapi from "../util/sapi";
import _ from "lodash";
import sharedActions from "../actions/shared-actions";
import homeActions from "../actions/home-actions";
import UpgradeDialogNew from "../components/modals/UpgradeDialogNew";
import {getMessageForError} from "../util/errors";
import {withVFTranslation} from "../util/withVFTranslation";
import threadActions from "../actions/thread-actions";

class UploadManager extends Component {

  constructor(props){
    super(props);

    this.state = { isUploading : false }
  }

  promptToRetry(transaction){
    let me = this;
    let { showConfirm, clearActiveUpload, t } = this.props;

    let message = '';
    if(transaction.isPDFXHRUpload || transaction.isSignedPdfUpload){
      message = t("Your file could not be uploaded.  Do you want to retry?")
    }
    else{
      if(transaction.files.length === 1){
        message = t("Your file could not be uploaded.  Do you want to retry?")
      }
      else{
        message = t("Your files could not be uploaded.  Do you want to retry?")
      }
    }

    showConfirm(t('Upload failed'), message, (res) => {
      if(res){
        me.doUploadWithRetry(transaction);
      }
      else{
        this.dequeueTransaction(transaction, null);
        clearActiveUpload(transaction);
        this.setState({ isUploading : false });

        this.refreshTransactionData(transaction);
      }
    })
  }

  refreshTransactionData(transaction){
    this.props.updateNotifications()
    if(transaction.isPDFXHRUpload || transaction.isSignedPdfUpload || transaction.isInvoice){
      if(transaction.guest_uid){
        this.props.threadAction.refreshActiveThreadMessages();
        if(this.props.activeDM){
          this.props.threadAction.refreshDocsDM();
        }
      }
      else if(transaction.forum_id){
        return Promise.all([
          this.props.refreshWorkspace(transaction.forum_id),
          this.props.refreshDocs(transaction.forum_id, transaction.host_uid)
        ])
          .then(() => {
            if(this.props.activeDM){
              this.props.threadAction.refreshDocsDM();
            }
            else if(transaction.chat_id){
              this.props.refreshThreads(transaction.forum_id, transaction.host_uid, true)
            }
            this.props.threadAction.refreshActiveThreadMessages();
          })
      }
    }
    else if(transaction.guest_uid){
      this.props.updateDirectMessages();

      this.props.threadAction.refreshActiveThreadMessages();
      if(this.props.activeDM){
        this.props.threadAction.refreshDocsDM();
      }
    }
    else{
      this.props.refreshDocs(transaction.forum_id, transaction.host_uid);

      if(transaction.chat_id){
        this.props.refreshThreads(transaction.forum_id, transaction.host_uid, true)
        this.props.threadAction.refreshActiveThreadMessages();
      }
    }
  }

  pollForQueueIdFinished(queue_id){
    return new Promise((resolve, reject) => {
      sapi.Threads.attachStatus(queue_id)
        .then((res) => {
          if(res.data && res.data.length > 0){
            let status = _.get(res, 'data[0].queue_status');
            if(status && status === 'done'){
              //don't worry about waiting for this.  just clear the queue item.
              sapi.Threads.removeQueueStatus(queue_id);
              resolve(res);
            }
            else if(status && status === 'error'){
              //don't worry about waiting for this.  just clear the queue item.
              sapi.Threads.removeQueueStatus(queue_id);
              let error = _.get(res, 'data[0].error_item');

              if(error && error.error_code){
                reject({
                  code : error.error_code,
                  name : error.error_code,
                  isFailedAttach : true
                })
              }
              else{
                reject(error || res);
              }
            }
            else{
              setTimeout(() => {
                resolve(this.pollForQueueIdFinished(queue_id));
              }, 1000)
            }
          }
          else{
            //If no results come back for this queue_id, just call it success
            log.warn(`No results for queue_id=${queue_id}, returning successful.`);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  doAttachAndWait(transaction){
    return new Promise((resolve, reject) => {
      let attachPromise = null;
      if(transaction.guest_uid){
        attachPromise = sapi.DM.dmMesgAttach(transaction.guest_uid, transaction.mesg, transaction.files, transaction.last_forum_id)
      }
      else{
        attachPromise = sapi.Threads.threadDocAttach(transaction.forum_id, transaction.host_uid, transaction.chat_id, transaction.mesg, transaction.files);
      }

      attachPromise
        .then((res) => {
          if(res.data.queue_id){
            return this.pollForQueueIdFinished(res.data.queue_id);
          }
          else{
            return res;
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject({
            ...err,
            isFailedAttach : true
          });
        })
    })
  }

  dequeueTransaction(transaction, res){
    this.props.mapTransactionResult(transaction.transaction_id, res);
  }

  cleanupErrorTransaction(transaction){
    let {
      clearActiveUpload,
      updateProgress,
    } = this.props;

    this.dequeueTransaction(transaction, null);
    clearActiveUpload(transaction);
    updateProgress(null);
    this.setState({ isUploading : false });
    this.refreshTransactionData(transaction);
  }

  doSendInvoice(transaction, progressFn){
    if(transaction.files.length > 1){
      log.warn("More than one invoice per message send is not supported!  Only the first invoice will be sent.", transaction.files);
    }
    let invoice = transaction.files[0];
    let mesg = transaction.mesg;

    if(invoice.isInvoiceResend){
      if(transaction.guest_uid){
        return sapi.DM.resendBill(invoice.guest_uid, invoice.original_bill.mesg_id, mesg.length > 0 ? mesg : null)
      }
      else{
        return sapi.Merchant.resendBill(invoice.forum_id, invoice.chat_id, invoice.original_bill.mesg_id, mesg.length > 0 ? mesg : null)
      }
    }
    else{
      if(transaction.guest_uid){
        return sapi.DM.addBill(invoice.guest_uid, invoice.amount, invoice.currency, invoice.description, invoice.days_until_due, invoice.no_decimal_flag, mesg.length > 0 ? mesg : null)
      }
      else{
        return sapi.Merchant.addBill(invoice.guest_uid, invoice.amount, invoice.currency, invoice.description, invoice.forum_id, invoice.host_uid, invoice.chat_id, invoice.days_until_due, invoice.no_decimal_flag, mesg.length > 0 ? mesg : null)
      }
    }
  }

  doUploadWithRetry(transaction){
    let me = this;
    let {
      setActiveUpload,
      clearActiveUpload,
      updateProgress,
      t
    } = this.props;

    log.log('starting upload', transaction);
    this.setState({ isUploading : true });
    setActiveUpload(transaction)

    let progressFn = (prog) => {
      updateProgress(prog);
    }

    let upload = null;
    if(transaction.isDocAttach){
      upload = this.doAttachAndWait(transaction);
    }
    else if(transaction.isInvoice){
      upload = this.doSendInvoice(transaction, progressFn);
    }
    else if(transaction.isSignedPdfUpload){
      if(transaction.guest_uid){
        upload = sapi.DM.uploadSignedDocument(transaction.guest_uid, transaction.doc_id, transaction.filename, transaction.sign_request_id, transaction.customInputArray, transaction.pdfData, transaction.last_forum_id, progressFn);
      }
      else if(transaction.chat_id){
        upload = sapi.Workspace.uploadSignedThreadDocument(transaction.forum_id, transaction.host_uid, transaction.doc_id, transaction.filename, transaction.chat_id, transaction.sign_request_id, transaction.customInputArray, transaction.pdfData, progressFn);
      }
    }
    else if(transaction.isPDFXHRUpload){
      if(transaction.guest_uid){
        upload = sapi.DM.uploadXhr(transaction.guest_uid, transaction.doc_id, transaction.filename, transaction.sign_request_id, transaction.signature_requested, transaction.mesg, transaction.pdfData, transaction.last_forum_id, progressFn);
      }
      else{
        if(transaction.chat_id){
          upload = sapi.Threads.uploadXhr(transaction.forum_id, transaction.host_uid, transaction.doc_id, transaction.filename, transaction.chat_id, transaction.sign_request_id, transaction.signature_requested, transaction.mesg, transaction.pdfData, progressFn);
        }
        else{
          upload = sapi.Docs.uploadXhr(transaction.forum_id, transaction.host_uid, transaction.doc_id, transaction.filename, transaction.pdfData, progressFn);
        }
      }
    }
    else {
      if(transaction.guest_uid){
        upload = sapi.DM.upload(transaction.guest_uid, transaction.files, transaction.mesg, transaction.signature_requested, progressFn, transaction.last_forum_id);
      }
      else{
        if(transaction.chat_id){
          upload = sapi.Threads.upload(transaction.forum_id, transaction.host_uid, transaction.chat_id, transaction.files, transaction.mesg, transaction.signature_requested, progressFn)
        }
        else{
          upload = sapi.Docs.upload(transaction.forum_id, transaction.host_uid, transaction.files, progressFn)
        }
      }
    }

    upload
      .then((res) => {
        log.log('upload finished', transaction, res);

        this.dequeueTransaction(transaction, res);

        clearActiveUpload(transaction);
        updateProgress(null);
        this.setState({ isUploading : false });

        this.refreshTransactionData(transaction);
      })
      .catch((err) => {

        log.log('error during upload', err);

        if(err && err.isFailedAttach){
          this.props.showAlert(t('Error Uploading'), getMessageForError(err, t));
          this.cleanupErrorTransaction(transaction);
          return;
        }

        let isThreadGuestUpload = transaction.chat_id && transaction.host_uid;
        let errName = _.get(err, 'name');

        if(!isThreadGuestUpload && errName === 'APP_ACCT_QUOTA'){
          this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.DOCS, err, t, (res) => {
            log.log('showUpgradeDialogOrError res', res);
          })

          this.cleanupErrorTransaction(transaction);
          return;
        }

        //This is for the edge case that guests are uploading to a thread, and they get back
        //A couple specific error types.  We can't retry here, so we show them an error
        //and don't allow retry. bug 2249
        if(isThreadGuestUpload){
          if(errName === 'APP_FORUM_MAX_COL' || errName === 'APP_PARTIAL_FAIL' || errName === 'APP_ACCT_QUOTA'){
            //then show special message and cancel
            this.props.showAlert(t('Error Uploading'), t("Your host workspace doesn't have the resources to accommodate this file.  Please contact your host about making more space in their account."))

            this.cleanupErrorTransaction(transaction);
            return;
          }
        }
        else if(transaction.chat_id){
          //then this is a thread, and you're the host, and you hit some upload problem.
          if(errName === 'APP_FORUM_MAX_COL'){
            this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.DOCS, err, t, (res) => {
              log.log('showUpgradeDialogOrError res', res);
            })
            this.cleanupErrorTransaction(transaction);
            return;
          }
        }

        if(transaction.isInvoice){
          //Invoices can't be retried, just show an error and bail.
          this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t));
          this.cleanupErrorTransaction(transaction);
          return;
        }

        log.log('error uploading', err);
        me.promptToRetry(transaction);
      })
  }

  processQueue(){
    let { isUploading } = this.state;
    let { uploadQueue, updateQueue } = this.props;

    if(isUploading){
      return;
    }

    if(uploadQueue.length > 0){
      //Then queue it up.
      //remove next transaction from queue, and pass to doUploadWithRetry()

      let nextTransaction = uploadQueue[0];
      uploadQueue.splice(0, 1);
      updateQueue(uploadQueue);
      this.doUploadWithRetry(nextTransaction);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.processQueue();
  }

  render(){ return null }
}

const mapStateToProps = (state) => {
  return {
    uploadQueue : state.upload.uploadQueue,
    activeUpload : state.upload.activeUpload,

    activeDM: state.thread.activeDM,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProgress : (progress) => dispatch(uploadActions.updateProgress(progress)),
    setActiveUpload : (transaction) => dispatch(uploadActions.setActiveUpload(transaction)),
    clearActiveUpload : (transaction) => dispatch(uploadActions.clearActiveUpload(transaction)),
    ...modalActions.mapToDispatch(dispatch),
    updateQueue : (queue) => dispatch(uploadActions.updateQueue(queue)),
    refreshWorkspace: (forum_id) => dispatch(workspaceActions.refreshWorkspace(forum_id)),
    refreshDocs: (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid)),
    refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(workspaceActions.refreshThreads(forum_id, host_uid, doRefreshGuests)),
    ...sharedActions.mapToDispatch(dispatch),
    threadAction : {...threadActions.mapToDispatch(dispatch)}
  };
};

UploadManager.propTypes = {

}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadManager)));
