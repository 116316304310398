import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Promise from 'bluebird';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import log from "../../util/log";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import Checkbox from "../partials/elements/Checkbox";
import workspaceActions from "../../actions/workspace-actions";
import DocAttach from "../../models/DocAttach";
import {withVFTranslation} from "../../util/withVFTranslation";
import ValidationErrors from "../partials/components/ValidationErrors";
import c from "../../util/const";

class FinalizeAttachmentWindow extends Component {

  PRIVATE_MESSAGES = 'private_messages'

  constructor(props) {
    super(props);

    let selectedForumId = null;
    let selectedChatId = null;
    if(props.initial_selection_forum_id){
      selectedForumId = props.initial_selection_forum_id;
      if(props.initial_selection_chat_id){
        selectedChatId = props.initial_selection_chat_id;
      }
    }
    else if(props.initial_selection_guest_uid){
      selectedForumId = this.PRIVATE_MESSAGES;
      selectedChatId = props.initial_selection_guest_uid;
    }
    else{
      selectedForumId = this.PRIVATE_MESSAGES;
      if(props.directMessages.length > 0) {
        selectedChatId = props.directMessages[0].guest_uid;
      }
    }

    this.state = {
      new_filename : props.filename,
      selectedForumId,
      selectedChatId,
      destinationOpts : [],
      workspaceThreadList : [],
      recipientList : [],
      threadLookup : {},
      contactList : _.map(props.directMessages, (c) => {
        return {
          key : c.guest_uid,
          val : c.first_name + ' ' + c.last_name
        }
      }),
      recipientLookup : {},
      validationErr: [],
      loading : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;

    this.setState({loading : true});
    this.load()
      .catch((err) => {
        log.error('error loading finalize docs window', err);
        this.props.showAlert(t('Error loading'), getMessageForError(err, t), () => {
          this.closeModal(false);
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  getAttachableWorkspaces(){
    let {
      workspaces
    } = this.props;
    return _.filter(workspaces, (ws) => _.get(ws, 'forum_type') !== c.FORUM_TYPES.FORUM_CONTENT);
  }

  load(){
    let { t } = this.props;

    //Cannot attach to content workspaces
    let validWorkspaces = this.getAttachableWorkspaces();

    this.setState({
      destinationOpts : _.concat(
        [{
          isPrivateMessageOption : true,
          key : this.PRIVATE_MESSAGES,
          value : t('Private Messages')
        }],
        validWorkspaces
      )
    })

    return this.updateThreadList(this.state.selectedChatId)
      .then(() => {
        return this.updateRecipientList();
      })
  }

  updateRecipientList(){
    let { workspaces } = this.props;
    let { selectedForumId, selectedChatId, recipientLookup } = this.state;

    if(selectedForumId === this.PRIVATE_MESSAGES){
      let found = _.find(this.props.directMessages, (i) => {
        return i.guest_uid === selectedChatId;
      })

      return new Promise((resolve, reject) => {
        this.setState({recipientList : found ? [found] : []}, () => {
          resolve(found);
        })
      })
    }

    let selectedWorkspace = _.find(workspaces, (ws) => ws.forum_id === selectedForumId);

    log.log('update recipient list', selectedChatId, recipientLookup);
    let promise = null;
    if(!recipientLookup[selectedChatId]){
      promise = sapi.Threads.guests(selectedWorkspace.forum_id, selectedWorkspace.host_uid, selectedChatId)
        .then((guestRes) => {

          let update = _.extend({}, this.state.recipientLookup);
          update[selectedChatId] = _.uniqBy(guestRes.data, 'guest_uid');
          this.setState({recipientLookup : update})

          return update[selectedChatId];
        })
    }
    else{
      promise = Promise.resolve(recipientLookup[selectedChatId]);
    }

    return promise
      .then((workspaceGuests) => {
        return new Promise((resolve, reject) => {
          log.log('workspace guests', workspaceGuests);
          this.setState({
            recipientList: workspaceGuests
          }, () => {
            resolve(true);
          })
        })
      })

  }

  updateThreadList(chatIdToSelect){
    let { workspaces } = this.props;
    let { selectedForumId, threadLookup } = this.state;

    log.log('updateThreadList', selectedForumId, chatIdToSelect);
    if(selectedForumId === this.PRIVATE_MESSAGES){
      return new Promise((resolve, reject) => {
        if(chatIdToSelect){
          this.setState({
            selectedChatId : chatIdToSelect
          }, () => {
            resolve(true);
          })
        }
        else{
          this.setState({
            selectedChatId : this.props.directMessages.length > 0 ? this.props.directMessages[0].guest_uid : null
          }, () => {
            resolve(true);
          })
        }
      })
    }

    let selectedWorkspace = _.find(workspaces, (ws) => ws.forum_id === selectedForumId);

    let promise = null;
    if(!threadLookup[selectedForumId]){
      promise = sapi.Threads.get(selectedWorkspace.forum_id, selectedWorkspace.host_uid)
        .then((threadRes) => {

          let update = _.extend({}, this.state.threadLookup);
          update[selectedForumId] = threadRes.data;
          this.setState({threadLookup : update})

          return threadRes.data;
        })
    }
    else{
      promise = Promise.resolve(threadLookup[selectedForumId]);
    }

    return promise
      .then((workspaceThreads) => {
        return new Promise((resolve, reject) => {
          log.log('workspace threads', workspaceThreads);

          let sortedThreads = _.sortBy((workspaceThreads), (thread) => {
            return -thread.updated_date;
          });

          let selectedChatId = null;
          if(chatIdToSelect){
            selectedChatId = chatIdToSelect;
          }
          else{
            selectedChatId = sortedThreads.length > 0 ? sortedThreads[0].chat_id : null;
          }
          this.setState({
            workspaceThreadList : sortedThreads,
            selectedChatId,
          }, () => {
            resolve(true);
          })
        })
      })
  }

  onCurrentWorkspaceChange(evt){
    log.log('on current workspace change', evt.target.value);
    this.setState({selectedForumId : evt.target.value}, () => {
      this.updateThreadList()
        .then(() => {
          this.updateRecipientList();
        })
    });
  }

  onCurrentThreadChange(evt){
    log.log('on current thread change', evt.target.value);
    this.setState({selectedChatId : evt.target.value}, () => {
      this.updateRecipientList();
    });
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  doValidation(){
    let err = [];

    let { t } = this.props;
    let {
      new_filename
    } = this.state;

    if(new_filename.length === 0){
      err.push(t('Please enter a name for this file'))
    }

    this.setState({
      validationErr : err
    })
    return err.length === 0;
  }

  submit() {
    if(!this.doValidation()){
      return;
    }

    let {selectedForumId, selectedChatId, new_filename} = this.state;

    let result = {
      filename : new_filename
    }

    if (selectedForumId === this.PRIVATE_MESSAGES) {
      result.guest_uid = selectedChatId;
    }
    else{
      result.forum_id = selectedForumId;
      result.chat_id = selectedChatId;
    }

    this.closeModal(result);
  }

  renderDestination(){
    let { t } = this.props;
    let {selectedForumId, selectedChatId, workspaceThreadList, recipientList, contactList} = this.state;

    if(selectedForumId === this.PRIVATE_MESSAGES){
      return (
        <>
          <div className="form-inline mb-3 d-table w-100">
            <label className="d-table-cell"
                   style={styles.inlineLabel}>
              {t("Contact")}
            </label>
            {contactList.length === 0 &&
            <select className="form-control d-table-cell w-100"
                    value={''}
                    disabled={true}
                    onChange={this.onCurrentThreadChange.bind(this)}>
              <option value={''}>
                {t("No Contacts")}
              </option>
            </select>
            }
            {contactList.length > 0 &&
            <select className="form-control d-table-cell w-100"
                    value={selectedChatId}
                    onChange={this.onCurrentThreadChange.bind(this)}>
              {contactList.map((c) => {
                return <option key={c.key}
                               value={c.key}>
                  {c.val}
                </option>
              })}
            </select>
            }
          </div>
          <div className="d-flex mb-3 recipients-block">
            <div className="label-wrap text-right">
              <label>{t("To:")}</label>
            </div>
            <p className="mb-0 flex-grow-1">
              {recipientList.map((guest, i) => {
                return (
                  <Fragment key={guest.guest_uid}>
                    <span>
                      {`${guest.first_name} ${guest.last_name}`}
                    </span>
                    {i < recipientList.length - 1 &&
                    <span>, </span>
                    }
                  </Fragment>
                )
              })}
            </p>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="form-inline mb-3 d-table w-100">
          <label className="d-table-cell"
                 style={styles.inlineLabel}>
            {t("Thread")}
          </label>
          {workspaceThreadList.length === 0 &&
          <select className="form-control d-table-cell w-100"
                  value={''}
                  disabled={true}
                  onChange={this.onCurrentThreadChange.bind(this)}>
            <option value={''}>
              {t("No Threads in Selected Workspace")}
            </option>
          </select>
          }
          {workspaceThreadList.length > 0 &&
          <select className="form-control d-table-cell w-100"
                  value={selectedChatId}
                  onChange={this.onCurrentThreadChange.bind(this)}>
            { workspaceThreadList.map((thread) => {
              return <option key={thread.chat_id}
                             value={thread.chat_id}>
                {thread.label}
              </option>
            })}
          </select>
          }
        </div>
        {workspaceThreadList.length > 0 &&
        <div className="d-flex mb-3 recipients-block">
          <div className="label-wrap text-right">
            <label>{t("To:")}</label>
          </div>
          <p className="mb-0 flex-grow-1">
            {recipientList.length === 0 &&
            <span style={styles.recipient}>
                  {t("No Guests have access yet.")}
                </span>
            }
            {recipientList.map((guest, i) => {
              return (
                <Fragment key={guest.guest_uid}>
                    <span>
                      {`${guest.first_name} ${guest.last_name}`}
                    </span>
                  {i < recipientList.length - 1 &&
                  <span>, </span>
                  }
                </Fragment>
              )
            })}
          </p>
        </div>
        }
      </>
    )
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t } = this.props;
    let {new_filename, selectedForumId, selectedChatId, destinationOpts, validationErr} = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">
            {t("Finalize Attachment")}
          </h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body attach-doc-to-thread-window">
          <div className="text-center">
            <h6 className="mb-4">{t("Confirm the name and destination for your new file.")}</h6>
          </div>

          <div className="form-inline mb-3 d-table w-100">
            <label className="d-table-cell" style={styles.inlineLabel}>{t("New Name")}</label>
            <input className={'form-control'}
                   type={'text'}
                   value={new_filename}
                   onChange={(evt) => this.setState({new_filename: evt.target.value})}
                   placeholder={t('File Name')}/>
          </div>

          <div className="form-inline mb-3 d-table w-100">
            <label
              className={`${selectedForumId === this.PRIVATE_MESSAGES ? 'invisible' : ''}` + " d-table-cell"}
              style={styles.inlineLabel}>
              {t("Workspace")}
            </label>
            <select className="form-control d-table-cell w-100"
                    value={selectedForumId}
                    onChange={this.onCurrentWorkspaceChange.bind(this)}>
              { destinationOpts.map((opt) => {
                if(opt.isPrivateMessageOption){
                  return (
                    <option key={opt.key}
                            value={opt.key}>
                      {opt.value}
                    </option>
                  )
                }
                else{
                  return (
                    <option key={opt.forum_id}
                            value={opt.forum_id}>
                      {opt.label}
                    </option>
                  )
                }
              })}
            </select>
          </div>
          {this.renderDestination()}
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} />}
        </div>
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className="btn btn-primary"
                  disabled={selectedForumId && !selectedChatId}
                  onClick={this.submit.bind(this)}>
            {t("Next")}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px',
    color : colors.SECONDARY_TEXT
  },
  recipient : {
    lineHeight: '38px',
    display : 'inline-block'
  },
  inlineLabel : {
    width: '100px'
  },
}

const mapStateToProps = (state) => {
  return {
    workspaces : state.shared.workspaces,
    directMessages : state.shared.directMessages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshGuests: (forum_id, host_uid) => dispatch(workspaceActions.refreshGuests(forum_id, host_uid)),
    ...modalActions.mapToDispatch(dispatch)
  };
};

FinalizeAttachmentWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired,
  filename : PropTypes.string.isRequired,
  initial_selection_forum_id : PropTypes.string,
  initial_selection_guest_uid : PropTypes.string,
  initial_selection_chat_id : PropTypes.string
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(FinalizeAttachmentWindow));
