const config = {
  debug : false,
  authGoesToVIP: false,

  automatedTesting: false,

  versions: {
    web : 112
  },

  showPDFFixerTool : false,

  prodEndpoint: 'https://verifyle.com',
  endpoints: [
    'https://verifyle.com',
    'https://stage.vfltest.com',
    'https://qa1.vfltest.com',
    'https://qa2.vfltest.com',
    'https://qa3.vfltest.com',
    'https://qa4.vfltest.com',
    'https://dev2.vfltest.com',
    'https://dev3.vfltest.com',
    'https://dev4.vfltest.com',
    'https://dev5.vfltest.com',
  ],
}

export default config;
