import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import uploadActions from "../../../actions/upload-actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import classNames from "classnames";
import UploadHelper from "../components/UploadHelper";
import NotificationIcon from "../components/NotificationIcon";
import Button from "../elements/Button";
import PendingDocRow from "./PendingDocRow";
import DocRow from "../rows/DocRow";
import log from "../../../util/log";
import _ from "lodash";
import Promise from 'bluebird'
import sapi from "../../../util/sapi";
import Scroll from "react-scroll";
import colors from "../../../util/colors";
import EmptyState from "../components/EmptyState";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class AttachedDocsPanel extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      sortedDocs : null,
      activeDocId : null,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(null)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.docs){
      if(prevProps.docs !== this.props.docs || prevProps.thread !== this.props.thread){
        this.updateSortedDocLists();
      }
      else if(!this.state.sortedDocs){
        this.updateSortedDocLists();
      }
    }
  }

  updateSortedDocLists(){
    let { docs } = this.props;
    let sortedDocs = _.sortBy(_.concat([], docs), (doc) => -doc.updated_date);

    this.setState({
      sortedDocs
    })
  }

  selectDocAndNavigate(doc){
    let { docs } = this.props;
    let foundDoc = _.find(docs, (d) => d.doc_id === doc.doc_id);
    if(foundDoc){
      this.scrollToDoc(doc)
        .then(() => {
          this.docClick(doc);
        })
    }
    else{
      log.log('doc not found', doc);
    }
  }

  scrollToDoc(doc){
    return new Promise((resolve, reject) => {
      Scroll.scroller.scrollTo(`doc-${doc.doc_id}`, {
        smooth: true,
        duration: 500,
        offset: -(63 + 58 + 69), //HACK - this is the header height, plus the chat header height.
        containerId: 'attached-docs-col',
      })
      setTimeout(() => {
        resolve(true);
      }, 600)
    })
  }

  docClick(doc){
    this.setState({activeDocId : (this.state.activeDocId === doc.doc_id ? null : doc.doc_id)})
  }

  findThread(chat_id){
    if(!chat_id){
      return null;
    }

    let { threads } = this.props;
    return _.find(threads, (thread) => thread.chat_id === chat_id)
  }

  onPdfSubmit(res){
    this.props.onPdfSubmit(res);
  }

  onAttachDocToThread(res){
    this.props.onAttachDocToThread(res);
  }

  render() {
    let { docNotifyCount, docs, workspace, thread, dm, t } = this.props;
    let { sortedDocs, activeDocId } = this.state;

    let contents = null;
    if(!docs){
      contents = (
        PlaceholderLoaders.renderDocPlaceholderRows(15)
      )
    }
    else{
      contents = (
        <>
          {docs.length === 0 &&
          <EmptyState>
            <div className="text-center secondary-text-color attach-docs-empty-state" style={{margin : '0px 100px'}}>
              <p>
                {t("There are no Documents in this thread.")}
              </p>
            </div>
          </EmptyState>
          }

          {_.map(sortedDocs, (doc) => {
            if (doc.isPending) {
              return <PendingDocRow key={doc.key}
                                    row={doc} />
            }
            else {
              return <DocRow key={doc.doc_id}
                             thread={thread}
                             isOutsideCurrentContext={false}
                             fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                             isSelected={activeDocId === doc.doc_id}
                             dm={dm}
                             workspace={workspace}
                             row={doc}
                             onAttachToThread={this.onAttachDocToThread.bind(this)}
                             onPdfSubmit={this.onPdfSubmit.bind(this)} />
            }
          })}
        </>
      )
    }

    return (
      <Fragment>
        <div style={styles.gridWrap} className="accordion-header pt-2 pl-2">
          <h4 className={'m-0'}>
            <NotificationIcon iconCls="ion-document-text"
                              value={docNotifyCount}/>
            {t("Attached Documents")}
          </h4>
        </div>
        {contents}
      </Fragment>
    )
  }
}

const styles = {
  gridWrap : {
    backgroundColor : colors.TRANSPARENT
  }
}

AttachedDocsPanel.propTypes = {
  thread : PropTypes.object,
  dm : PropTypes.object,
  workspace : PropTypes.object,
  onRef : PropTypes.func.isRequired,
  docs : PropTypes.array,
  docNotifyCount :PropTypes.number.isRequired,
  onAttachDocToThread : PropTypes.func,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  onPdfSubmit : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    threads : state.workspace.threads
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AttachedDocsPanel)));
