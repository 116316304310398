import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from 'react-select';
import Draggable from "react-draggable";
import log from '../../../util/log';
import colors from "../../../util/colors";
import config from "../../../util/config";
import Button from "../elements/Button";
import classnames from 'classnames';
import _ from 'lodash'
import SignatureRequest from "../../../models/SignatureRequest";
import {fontConstants} from "../../../util/font-constants";
import utils from "../../../util/util";
import {withVFTranslation} from "../../../util/withVFTranslation";
import { Resizable } from 'react-resizable';
import PdfSignatureRequestCustomScalingInput from "./PdfSignatureRequestCustomScalingInput";
import PdfSignatureFulfillOverlay from "./PdfSignatureFulfillOverlay";
import PdfSignatureRequestSelectInput from "./PdfSignatureRequestSelectInput";
import c from '../../../util/const'

class PdfSignatureRequestOverlay extends PureComponent {

  static MIN_OVERLAY_WIDTH = 60;
  static MIN_OVERLAY_HEIGHT = 30;

  INITIAL_STATE = {
    isDragging : false
  }

  customInputRef = null;

  constructor(props){
    super(props);

    this.state = _.extend({}, this.INITIAL_STATE);
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, null)
    }
  }

  deleteButtonClick(){
    this.props.deleteSignature();
  }

  onRequestOptionChanged(val){
    this.props.onSignatureTypeChange(val);

    if(val === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      utils.waitForCondition(() => {
        return this.props.overlay.signatureType === val
      }, 250)
        .then(() => {
          let inputRef = _.get(this.customInputRef.getInputRef(), 'current')
          if(inputRef){
            inputRef.focus();
          }
        })
    }
  }

  onRequestCustomLabelChange(val){
    this.props.onSignatureCustomLabelChange(val);
  }

  onCustomLabelClear(){
    this.props.onSignatureTypeChange(SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE);
    this.props.onSignatureCustomLabelChange('');
  }

  getScaledMoveCoordinates(position){
    //Move events come in scaled, however we manage relative scaling within render.
    //This means that we need to reverse scale the move coordinates because we'll re-scale it back during render.
    //This might seem like extra work, and it is, but

    let { overlay, viewScale } = this.props;
    return {
      x :  PdfSignatureRequestOverlay.calculateOverlayRelativeScale(position.x, viewScale, overlay.scale),
      y :  PdfSignatureRequestOverlay.calculateOverlayRelativeScale(position.y, viewScale, overlay.scale)
    }
  }

  handleDragStart(e, position){
    this.setState({ isDragging : true })
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }

  handleDrag(e, position){
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }

  handleDragStop(e, position){
    this.setState({ isDragging : false })
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }

  static calculateOverlayRelativeScale(val, overlayScale, viewScale){
    return val * (viewScale / overlayScale);
  }

  scaleRelativeToOverlay(val){
    let { overlay, viewScale } = this.props;
    return PdfSignatureRequestOverlay.calculateOverlayRelativeScale(val, overlay.scale, viewScale);
  }

  onSignatureRequestResize = (event, {element, size, handle}) => {
    let { overlay, viewScale } = this.props;

    this.props.onSignatureResize({
      width : PdfSignatureRequestOverlay.calculateOverlayRelativeScale(size.width, viewScale, overlay.scale),
      height : PdfSignatureRequestOverlay.calculateOverlayRelativeScale(size.height, viewScale, overlay.scale)
    })
  };

  render(){
    let {
      overlay,
      allowChanges,
      scaledPageBounds,
      signaturePrimaryColor,
      isSignatureForCurrentUser
    } = this.props;

    let scaledOverlayX = this.scaleRelativeToOverlay(overlay.coords.x);
    let scaledOverlayY = this.scaleRelativeToOverlay(overlay.coords.y);

    //have to calculate our own resizable bounds.
    //Page width minus overlay position, minus the scaled overlay handle.
    let maxSignatureOverlayWidth = scaledPageBounds.width - scaledOverlayX - this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH);
    let maxSignatureOverlayHeight = scaledPageBounds.height - scaledOverlayY;

    let scaledOverlayWidth = this.scaleRelativeToOverlay(overlay.width);
    let scaledOverlayHeight = this.scaleRelativeToOverlay(overlay.height);

    let baseColor = signaturePrimaryColor || colors.SIGNING_REQUEST;

    return (
      <Draggable
        handle=".handle"
        disabled={!allowChanges}
        bounds={'parent'}
        position={{
          x : scaledOverlayX,
          y : scaledOverlayY
        }}
        onStart={this.handleDragStart.bind(this)}
        onDrag={this.handleDrag.bind(this)}
        onStop={this.handleDragStop.bind(this)}>
        <div style={{
          position : 'absolute',
          zIndex : 1000000,
          pointerEvents: 'all'
        }}>
          <div className="d-flex">
            <div>
              <button className="btn btn-icon handle"
                      style={{
                        paddingTop: '0px',
                        paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                        paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                        fontSize: `${this.scaleRelativeToOverlay(32)}px`,
                        color: baseColor,
                        boxShadow: 'none',
                        display: 'block',
                        minWidth: `${this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH)}px`,
                        height: this.scaleRelativeToOverlay(30) + 'px',
                      }}>
                <i className="icon icon-top ion-bookmark has-grabber"
                   style={{color : baseColor}}/>
              </button>
            </div>

            {isSignatureForCurrentUser && overlay.signatureType !== SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER &&
            <Resizable height={scaledOverlayHeight}
                       width={scaledOverlayWidth}
                       minConstraints={[this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.MIN_OVERLAY_WIDTH), this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.MIN_OVERLAY_HEIGHT)]}
                       maxConstraints={[maxSignatureOverlayWidth, maxSignatureOverlayHeight]}
                       resizeHandles={allowChanges ? ['se'] : []}
                       onResize={this.onSignatureRequestResize}>
              <div className="position-relative">

                <PdfSignatureRequestSelectInput selectedValue={overlay.signatureType}
                                                scale={overlay.scale}
                                                onInputChange={this.onRequestOptionChanged.bind(this)}
                                                inputPaddingStyle={"0px"}
                                                initialFontSize={14}
                                                scaleRelativeToOverlay={(val) => this.scaleRelativeToOverlay(val)}
                                                inputClassName={"form-control flex-grow-1 signature-request-input py-0 animate-font-size"}
                                                width={scaledOverlayWidth}
                                                height={scaledOverlayHeight}
                                                lineHeight={scaledOverlayHeight}
                                                signaturePrimaryColor={signaturePrimaryColor}
                                                inputDisabled={!allowChanges}/>
              </div>
            </Resizable>
            }
            {isSignatureForCurrentUser && overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER &&
            <Resizable height={scaledOverlayHeight}
                       width={scaledOverlayWidth}
                       minConstraints={[this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.MIN_OVERLAY_WIDTH), this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.MIN_OVERLAY_HEIGHT)]}
                       maxConstraints={[maxSignatureOverlayWidth, maxSignatureOverlayHeight]}
                       resizeHandles={allowChanges ? ['se'] : []}
                       onResize={this.onSignatureRequestResize}>
              <div className="position-relative">
                <PdfSignatureRequestCustomScalingInput
                  hasRemoveButton={true}
                  inputClassName={`form-control flex-grow-1 signature-request-input helvetica animate-font-size`}
                  inputText={overlay.signatureCustomLabel}
                  placeholderText={''}
                  scaleRelativeToOverlay={(val) => this.scaleRelativeToOverlay(val)}
                  onInputChange={this.onRequestCustomLabelChange.bind(this)}
                  width={scaledOverlayWidth}
                  height={scaledOverlayHeight}
                  lineHeight={scaledOverlayHeight}
                  onRef={(ref) => this.customInputRef = ref}
                  inputPaddingStyle="0px 22px 0px 5px"
                  signaturePrimaryColor={signaturePrimaryColor}
                  initialFontSize={PdfSignatureFulfillOverlay.INITIAL_CUSTOM_INPUT_FONT_SIZE}
                  inputDisabled={!allowChanges}/>
                {allowChanges &&
                <div onClick={this.onCustomLabelClear.bind(this)}
                     style={{
                       lineHeight: `${scaledOverlayHeight}px`,
                       position: 'absolute',
                       right: '6px',
                       top: '0px',
                       color: colors.DARK
                     }}>
                  <i className="icon ion-close dark-color"/>
                </div>
                }
              </div>
            </Resizable>
            }
            {isSignatureForCurrentUser &&
            <div>
              <button disabled={!allowChanges}
                      className="btn btn-icon"
                      style={{
                        paddingTop: '0px',
                        paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                        paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                        fontSize: `${this.scaleRelativeToOverlay(26)}px`,
                        color: baseColor,
                        boxShadow: 'none',
                        display: 'block',
                        minWidth: `${this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH)}px`,
                        height: this.scaleRelativeToOverlay(30) + 'px',
                      }}
                      onClick={this.deleteButtonClick.bind(this)}>
                <i className="icon icon-top ion-close-circled signature-request-color"/>
              </button>
            </div>
            }
          </div>
        </div>
      </Draggable>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

//This is calculated at scale = 1.  I need this from outside the component in order to calculate
//Proper overlay positions with the draggable handle.
//Just a heads up that I just measured this in the browser and hardcoded the size.  It's probably
//possible to calculate this and pass it around, but that involves references to rendered components which is harder to deal with in code.
PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH = 51;

PdfSignatureRequestOverlay.propTypes = {
  viewScale : PropTypes.number.isRequired,
  scaledPageBounds : PropTypes.object.isRequired,
  overlay : PropTypes.object.isRequired,
  onSignatureMove : PropTypes.func.isRequired,
  onSignatureResize : PropTypes.func.isRequired,
  onSignatureTypeChange : PropTypes.func.isRequired,
  onSignatureCustomLabelChange : PropTypes.func.isRequired,
  deleteSignature : PropTypes.func.isRequired,
  allowChanges : PropTypes.bool.isRequired,
  isSignatureForCurrentUser: PropTypes.bool.isRequired,
  isFulfillingSignatureRequest : PropTypes.bool.isRequired,
  signaturePrimaryColor : PropTypes.string,
  onRef : PropTypes.func,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSignatureRequestOverlay));
