import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter";
import _ from "lodash";
import util from "../../../../util/util";

class ChangePwdCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      current_pwd : '',
      new_pwd : '',
      new_pwd_cfm : '',
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    this.setState({
      current_pwd : '',
      new_pwd : '',
      new_pwd_cfm : '',
      validation_errors: [],
      isSaving: false,
    })
  }

  doValidation(){
    let { current_pwd, new_pwd, new_pwd_cfm } = this.state;
    let { t } = this.props;

    let err = [];
    if(!current_pwd || current_pwd.length === 0){
      err.push(t("Please enter your current password"));
    }

    if(!new_pwd || new_pwd.length === 0){
      err.push(t("Please enter your new password"));
    }
    else if(!new_pwd_cfm || new_pwd_cfm.length === 0){
      err.push(t("Please confirm your new password"))
    }
    else if(new_pwd.length < 6){
      err.push(t("Your new password must be at least 6 characters."))
    }
    else if(new_pwd !== new_pwd_cfm){
      err.push(t("The new passwords you entered do not match."))
    }

    if(err.length === 0){
      let score = util.scorePassword(new_pwd);
      if(score < PasswordStrengthMeter.MEDIUM_PASS_SCORE){
        err.push(t("Please choose a stronger password."))
      }
    }

    this.setState({validation_errors : err})
    return err.length === 0;
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    this.setState({isSaving: true});

    let { current_pwd, new_pwd } = this.state;
    let { doClose, t } = this.props;

    sapi.Password.reset(current_pwd, new_pwd, true)
      .then((res) => {
        log.log('password change res', res);

        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error changing password', err);
        this.setState({
          validation_errors : [ getMessageForError(err, t) ]
        })
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  onFormSubmit(evt){
    //This form is here to prevent a browser warning about password fields outside forms
    //I think it might be related to a problem focusing the password input in some cases.
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    return false;
  }

  render() {
    let { current_pwd, new_pwd, new_pwd_cfm, isSaving, validation_errors } = this.state;
    let { accountInfo, t } = this.props;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <form onSubmit={this.onFormSubmit.bind(this)}>
          <div className="d-none">
            <input type={'text'}
                   name="email"
                   autoComplete={'username'}
                   onChange={_.noop}
                   value={accountInfo.login}/>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Current Password")}</label>
                <input className={'form-control'}
                       autoComplete="current-password"
                       type={'password'}
                       value={current_pwd}
                       onChange={(evt) => this.setState({current_pwd: evt.target.value})}
                       placeholder={t('Current Password')}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("New Password")}</label>
                <input className={'form-control'}
                       type={'password'}
                       autoComplete="new-password"
                       value={new_pwd}
                       onChange={(evt) => this.setState({new_pwd: evt.target.value})}
                       placeholder={t('New Password')}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("New Password (confirm)")}</label>
                <input className={'form-control'}
                       type={'password'}
                       autoComplete="new-password"
                       value={new_pwd_cfm}
                       onChange={(evt) => this.setState({new_pwd_cfm: evt.target.value})}
                       placeholder={t('New Password (confirm)')}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>
                  <span className="mr-2">
                    {t("Password Strength")}
                  </span>
                  {PasswordStrengthMeter.renderPasswordStrengthText(t, new_pwd)}
                </label>
                <PasswordStrengthMeter password={new_pwd}/>
              </div>
            </div>
          </div>
        </form>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-secondary mr-2'} onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isSaving || current_pwd.length === 0 || new_pwd.length === 0 || new_pwd_cfm.length === 0}
                      className={'btn btn-primary'}
                      onClick={this.save.bind(this)}>
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ChangePwdCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePwdCtrl)));
