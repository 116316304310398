import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import c from "../../util/const";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class HelpResourceWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  contactUsClick(){
    this.props.showContactUsWindow()
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t } = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Verifyle Help Resources")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className="text-center">
            <p className="mt-3 mb-5">
              {t("We're here for you! If you're having trouble, let us help you get back on track. Here are the best ways to get your questions answered:")}
            </p>

            <div style={{marginLeft: '5rem', display: 'list-item'}}>
              <div className="form-inline">
                <div className="form-group ml-2">
                  {t("Watch the videos on our")}
                </div>
                <div className="form-group">
                  <a href={c.links.youtubeChannel} style={{textDecoration: 'underline'}}
                     target="_blank"
                     className="btn btn-link dark-color pl-1">{t("YouTube channel")}</a>
                </div>
              </div>
            </div>

            <div style={{marginLeft: '5rem', display: 'list-item'}}>
              <div className="form-inline">
                <div className="form-group ml-2">
                  {t("Read our")}
                </div>
                <div className="form-group mr-auto">
                  <a href={c.links.faq} style={{textDecoration: 'underline'}}
                     target="_blank"
                     className="btn btn-link dark-color pl-1">{t("FAQ page")}</a>
                </div>
              </div>
            </div>

            <div style={{marginLeft: '5rem', display: 'list-item'}}>
              <div className="form-inline">
                <div className="form-group ml-2">
                  {t("Contact us at")}
                </div>
                <div className="form-group mr-auto">
                  <a onClick={this.contactUsClick.bind(this)} style={{textDecoration: 'underline'}}
                     className="btn btn-link dark-color pl-1">{t("support@verifyle.com")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

HelpResourceWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(HelpResourceWindow));
