import React, {Component} from 'react';
import electronUtil from "../../../util/electron-util";

class ElectronAwareLink extends Component {

  //We need to be careful not to use hrefs to navigate outside our app
  //without target="_blank" when within electron.
  //If we do, the user has no browser controls to navigate, which leaves them stranded until
  //they restart the app.
  //If we navigate with target="_blank", electron opens a new Verifyle-context electron browser window
  //which we can monitor the state of if needed.

  //It's worth noting that you can still pass target="_blank" here if you want,
  //but this just forces it if within electron.

  render(){
    let {
      children,
      ...otherProps
    } = this.props;

    if(electronUtil.isRunningWithinElectron()){
      return (
        <a target="_blank" {...otherProps}>{children}</a>
      )
    }
    else{
      return (
        <a {...otherProps}>{children}</a>
      )
    }
  }
}

export default ElectronAwareLink;
