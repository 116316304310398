import c from '../util/const';
import _ from 'lodash'

let initialState = {
  archivedWorkspaces: [],
  unarchivedWorkspaces:[],
}

const home = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.home.updateWorkspaceLists:

      return {
        ...state,
        archivedWorkspaces : action.archivedWorkspaces,
        unarchivedWorkspaces : action.unarchivedWorkspaces
      }

    case c.actions.home.cleanup:
      return {
        ...initialState
      }

    default:
      return state;
  }
}

export default home;
