import React, {Component} from "react";
import modalActions from "../../../actions/modal-actions";
import PropTypes from "prop-types";
import {withVFTranslation} from "../../../util/withVFTranslation";
import {connect} from "react-redux";
import Button from "../elements/Button";
import _ from "lodash";
import VFPopover from "../components/VFPopover";
import c from "../../../util/const";
import popoverActions from "../../../actions/popover-actions";
import ColorGenerator from "../../../helpers/color-generator";
import colors from "../../../util/colors";
import log from "../../../util/log";
import SignatureRequest from "../../../models/SignatureRequest";
import pdfPreviewActions from "../../../actions/pdf-preview-actions";
import util from "../../../util/util";
import {getMessageForError} from "../../../util/errors";
import sapi from "../../../util/sapi";
import VFMiddleTruncate from "../util/VFMiddleTruncate";
import PdfPreview from "./PdfPreview";

class SignTemplatePanel extends Component {

  SCROLL_CONTAINER_ID = 'sign-template-scroll-panel';
  static THUMBNAIL_PANEL_WIDTH = 300;

  constructor(props) {
    super(props);

    this.state = {
      hoveringKey : ''
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  showTemplateMenu(evt, key){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.showPopover(c.popovers.SIGN_TEMPLATE_MENU, key);
  }

  hideTemplateMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.hidePopover();
  }

  onRenameSigningTemplate(evt, item){
    log.log('onrename', evt, item);
    this.hideTemplateMenu(evt);

    let {
      t
    } = this.props;

    let doRename = (label) => {
      return sapi.SignTemplate.rename(item.template_id, label);
    }

    this.props.modalAction.showRename(t('Rename Template'), item.label, doRename, (res) => {
      if(res){
        this.props.pdfActions.refreshSigningTemplates();
      }
    })
  }

  onDeleteSigningTemplate(evt, item){
    this.hideTemplateMenu(evt);
    log.log('ondelete', evt, item);

    let {
      t
    } = this.props;

    this.props.modalAction.showConfirm(t("Are you sure?"), t("Are you sure you want to delete this Signing Template?"), (res) => {
      if(res){
        sapi.SignTemplate.delete(item.template_id)
          .then((res) => {

            let signTemplateCache = _.get(this.props, 'pdfState.signTemplateCache');
            _.each(_.keys(signTemplateCache), (template_id) => {
              if(template_id === item.template_id){
                let mappedGuestUid = _.get(signTemplateCache, `[${template_id}].guest_uid`);
                this.props.pdfActions.unmapUserFromSigningTemplate(mappedGuestUid);
              }
            })

            this.props.pdfActions.refreshSigningTemplates();
          })
          .catch((err) => {
            this.props.modalAction.showAlert(t("Error deleting template. Please try again."), getMessageForError(err, t));
          })
      }
    })
  }

  onSaveSigningTemplate(evt){
    this.hideTemplateMenu(evt);
    log.log('onsave template', evt, this.props);

    let {
      t
    } = this.props;
    //we don't want any user-data saved in sign_data
    //you can apply a template towards anyone.
    //the sign template highlighting is driven purely by the comparing sign_data for the active request against
    //the sign_data in the row.

    //get currently suggested user
    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    let signatureRequestData = _.get(this.props, 'pdfState.signatureRequestData');
    let pdfLabel = _.get(signatureRequestData, 'doc_info.label');
    let sign_order = _.findIndex(signatureRequestData.signer_results, (r) => r.guest_uid === currentGuest.guest_uid);

    //findIndex is 0-based, we want to be 1-based for display.
    sign_order += 1;

    let pdfNameWithoutExtension = util.getFilenameWithoutExtension(pdfLabel);
    let pdfFileExtension = util.getFilenameExtension(pdfLabel);
    let suggestedName = `${pdfNameWithoutExtension}_signer_${sign_order}.${pdfFileExtension}`;

    this.props.modalAction.showPdfSubmit(
      t("Save Template"),
      suggestedName,
      null,
      null,
      (res) => {
        if(res) {
          let filename = _.get(res, 'filename');
          let signatureRequestOverlays = _.get(this.props, 'pdfState.signatureRequestOverlays')
          let userOverlays = _.filter(signatureRequestOverlays, (o) => o.guest_uid === currentGuest.guest_uid);
          let sign_data = {signatures: []};
          _.each(userOverlays, (overlay) => {
            let signatureData = SignatureRequest.convertOverlayPointToSignaturePoint(overlay);
            sign_data.signatures.push(signatureData);
          })

          log.log('save signing tpl here', filename, sign_data);
          let template_id = null;
          sapi.SignTemplate.add(filename, sign_data)
            .then((res) => {
              template_id = _.get(res, 'data.template_id');
              log.log('save sign template res', res);
              return this.props.pdfActions.refreshSigningTemplates();
            })
            .then((res) => {
              if(template_id){
                this.props.pdfActions.loadSigningTemplateForUser(template_id, currentGuest.guest_uid);
              }
            })
            .catch((err) => {
              this.props.modalAction.showAlert(t("Error saving template. Please try again."), getMessageForError(err, t));
            })
        }
      })
  }

  getTemplateMenuContent(item){
    let {
      t
    } = this.props;

    return (
      <div>
        <ul className="popover-content list-group">
          <a style={styles.menuHeader}
             className="list-group-item list-group-item-action">
            {item.label}
          </a>

          <a onClick={(evt) => this.onRenameSigningTemplate(evt, item)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-compose"/>
            {t("Rename Template")}
          </a>

          <a onClick={(evt) => this.onDeleteSigningTemplate(evt, item)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Delete Template")}
          </a>
        </ul>
      </div>
    )
  }

  isCurrentUserMappedToATemplate(){
    let isUserMapped = false;
    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    let signTemplateCache = _.get(this.props, 'pdfState.signTemplateCache');
    _.each(_.keys(signTemplateCache), (template_id) => {
      let mappedGuestUid = _.get(signTemplateCache, `[${template_id}].guest_uid`);
      if (mappedGuestUid === currentGuest.guest_uid) {
        isUserMapped = true;
      }
    })
    return isUserMapped;
  }

  getOverlaysForCurrentUser(){
    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    let signatureRequestOverlays = _.get(this.props, 'pdfState.signatureRequestOverlays')
    return _.filter(signatureRequestOverlays, (o) => o.guest_uid === currentGuest.guest_uid);
  }

  loadValidOverlaysAndWarnIfNeeded(sign_data){
    let {
      t,
      pdfScale,
    } = this.props;
    let {
      pages,
      pdfPageCanvasLookup
    } = this.props.pdfState;
    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();

    let invalidOverlays = [];
    let newOverlays = [];
    _.each(sign_data.signatures, (sig) => {
      let newOverlay = SignatureRequest.convertSignaturePointToOverlayPoint(sig, currentGuest.guest_uid);
      let foundPage = _.find(pages, (p) => p.pageIndex === newOverlay.pageIndex);

      if(!foundPage){
        log.log('did not find page, invalid', newOverlay);
        invalidOverlays.push(newOverlay);
      }
      else{
        let mappedCoords = PdfPreview.mapToCoordinates(pdfScale, newOverlay.coords.x, newOverlay.coords.y, pages, pdfPageCanvasLookup);
        if(!mappedCoords){
          log.log('no mapped coords, invalid', newOverlay);
          invalidOverlays.push(newOverlay)
        }
        else{
          newOverlays.push(newOverlay);
        }
      }
    })

    if(newOverlays.length === 0){
      // no valid points, warn and bail
      this.props.modalAction.showAlert(
        t("Incompatible Template"),
        t("This signing template is not compatible with this document. The template may have been created for a document with different dimensions or more pages.")
      )

      //I think we want to unmap the guest here.  Their data didn't really load.
      this.props.pdfActions.unmapUserFromSigningTemplate(currentGuest.guest_uid);
    }
    else if(invalidOverlays.length > 0){
      // some points did not load, just warn
      this.props.modalAction.showAlert(
        t("Invalid signature data"),
        t("This signing template has signing fields that are incompatible with this document. The template may have been created for a document with different dimensions or more pages.")
      )
    }

    if(newOverlays.length > 0){
      let { signatureRequestOverlays } = this.props.pdfState;
      let overlayUpdate = _.concat([], signatureRequestOverlays);

      //Remove whatever the guest already had there, we're replacing it.
      _.remove(overlayUpdate, (o) => o.guest_uid === currentGuest.guest_uid);
      overlayUpdate = _.concat(overlayUpdate, newOverlays);
      this.props.pdfActions.setRequestOverlays(overlayUpdate);
    }
  }

  onSelectTemplate(item){
    let {
      t
    } = this.props;
    let {
      signTemplateCache
    } = this.props.pdfState;

    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    let mappedSignerUid = _.get(signTemplateCache, `[${item.template_id}].guest_uid`);

    //If this template is mapped to someone else, just do nothing.
    //It doesn't make sense to map a template to two people.
    if(mappedSignerUid && mappedSignerUid !== currentGuest.guest_uid){
      return null;
    }

    let proceed = Promise.resolve(true);
    let userOverlays = this.getOverlaysForCurrentUser();
    let isMappedToTemplateAlready = this.isCurrentUserMappedToATemplate();
    if(!isMappedToTemplateAlready && userOverlays.length > 0){
      proceed = new Promise((resolve, reject) => {
        //warn them about losing unsaved signature points
        this.props.modalAction.showConfirm(
          t("Unsaved Changes"),
          t("Are you sure you want to discard the signing fields you've added and instead apply the template \"") + item.label + t("\"?"),
          (res) => {
            resolve(res);
          },
          t("Yes"),
          t("No")
        )
      })
    }

    proceed
      .then((res) => {
        if (!res) {
          return false;
        }
        return this.props.pdfActions.loadSigningTemplateForUser(item.template_id, currentGuest.guest_uid)
      })
      .then((res) => {
        let sign_data = _.get(res, 'sign_data');
        if(sign_data) {
          this.loadValidOverlaysAndWarnIfNeeded(sign_data);
        }
      })
      .catch((err) => {
        this.props.modalAction.showAlert(t("Unable to load Template"), getMessageForError(err, t));
      })
  }

  getSaveTemplateBtnDisabled(){
    let userOverlays = this.getOverlaysForCurrentUser();

    let isUserMapped = false;
    if(userOverlays.length > 0) {
      isUserMapped = this.isCurrentUserMappedToATemplate();
    }

    return isUserMapped || userOverlays.length === 0;
  }

  render(){
    let {
      hoveringKey,
    } = this.state;
    let {
      isShowingPanel,
      t
    } = this.props;
    let {
      signTemplateList,
      signTemplateCache
    } = this.props.pdfState;

    if(!signTemplateList || !isShowingPanel){
      return null;
    }

    return (
      <>
        <div id={this.SCROLL_CONTAINER_ID}
             className={`sign-template-scroll-container ${isShowingPanel ? '' : 'not-visible'}`}>
          <div style={{margin: '10px', maxWidth: '100%'}}>
            <h5 className="light-color font-weight-bold mb-4" style={{fontSize: '16px'}}>
              {t("Templates")}
            </h5>
            {signTemplateList.length === 0 &&
              <div>
                <p className="light-grey-color text-center mb-4" style={{fontSize: '12px'}}>
                  {t("You don't have any saved signature request templates. After setting the signing fields for a single signer, you can have us remember the locations of those signing fields for future use on another document by clicking the button below.")}
                </p>
              </div>
            }
            {_.map(signTemplateList, (item) => {
              let isHoveringItem = item.template_id === hoveringKey;
              let mappedSignerUid = _.get(signTemplateCache, `[${item.template_id}].guest_uid`);
              return (
                <div key={item.template_id}
                     onMouseEnter={() => this.setState({hoveringKey : item.template_id})}
                     onMouseLeave={() => this.setState({hoveringKey : ''})}
                     onClick={() => this.onSelectTemplate(item)}
                     className="d-flex sign-template-row has-pointer"
                     style={{
                       maxHeight: '45px',
                       lineHeight: '45px'
                     }}>
                  <i className="icon ion-record mx-2"
                     style={{
                       color: mappedSignerUid ? ColorGenerator.generateColorFromId(mappedSignerUid) : colors.LIGHT
                     }}/>
                  <div className={mappedSignerUid ? 'font-weight-bold' : ''}
                       title={item.label}
                       style={{
                         whiteSpace: 'nowrap',
                         width: 'calc(100% - 1.0rem - 1.0rem - 27px)'
                       }}>
                    <VFMiddleTruncate
                      text={item.label}
                      start={3}
                      end={3}
                      drawGearIcon={() => {
                        return (
                          <VFPopover getMenuContent={() => this.getTemplateMenuContent(item)}
                                     onClickOutside={(evt) => this.hideTemplateMenu(evt)}
                                     positions={['left', 'bottom', 'top']}
                                     isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.SIGN_TEMPLATE_MENU, item.template_id)}>
                            <i style={{fontSize: '18px'}}
                               onClick={(evt) => this.showTemplateMenu(evt, item.template_id)}
                               className={`icon ion-gear-b sign-template-menu-icon has-pointer mx-2 ${!isHoveringItem ? 'invisible' : ''}`}/>
                          </VFPopover>
                        )
                      }}/>
                  </div>
                </div>
              )
            })}
            <div className="text-center mt-4">
              <Button className="btn btn-primary btn-block"
                      onClick={(evt) => this.onSaveSigningTemplate(evt)}
                      disabled={this.getSaveTemplateBtnDisabled()}>
                {t("Save as Template")}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const styles = {
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.DARK
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

const mapStateToProps = (state) => {
  return {
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    modalAction : {...modalActions.mapToDispatch(dispatch)},
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

SignTemplatePanel.propTypes = {
  onRef : PropTypes.func.isRequired,
  pdfScale : PropTypes.number.isRequired,
  isShowingPanel : PropTypes.bool.isRequired,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SignTemplatePanel));
