import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import accountActions from "../../../../actions/account-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import UploadHelper from "../../components/UploadHelper";
import Image from "../../elements/Image";
import {getMessageForError} from "../../../../util/errors";
import colors from "../../../../util/colors";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import _ from "lodash";
import modalActions from "../../../../actions/modal-actions";
import c from "../../../../util/const";
import config from "../../../../util/config";
import popupHelper from "../../../../helpers/popup-helper";
import VFPopover from "../../components/VFPopover";
import popoverActions from "../../../../actions/popover-actions";

class EditHandleCtrl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      handleName: '',
      handleText: '',
      copyLinkSuccess : false,
      copyHTMLSuccess : false,
      existingHandle: null,
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    let { t } = this.props;
    let existingHandle = this.props.userHandle;

    this.setState({
      validation_errors: [],
      isSaving: false,
      existingHandle,
      handleName: _.get(existingHandle, 'handle', ''),
      handleText: _.get(
        existingHandle,
        'text',
        t("Hello! Verifyle is a secure way to share information with me. The attachments " +
          "you select and then send will be encrypted and delivered to me inside Verifyle " +
          "along with your message."))
    })
  }

  getHandleNameValidationError(handleName){
    let { t } = this.props;
    if(handleName.length < 3){
      return t("Your Handle must be at least 2 characters long.");
    }
    else{
      //handle rules:
      //Starts with an alphanumeric
      // May contain a-z, 0-9, and _

      let alphaNumericStartMatch = handleName.match(/^[a-z0-9]/);
      if(!alphaNumericStartMatch || alphaNumericStartMatch.length <= 0){
        return t("Your Handle must start with an alphanumeric character.");
      }
      else{
        let fullMatch = handleName.match(/^[a-z0-9]+[a-z0-9_]*$/);
        if(!fullMatch || fullMatch.length <= 0){
          return t("Your Handle may only contain alphanumeric characters, and underscores.");
        }
      }
    }

    return false;
  }

  updateHandleClick() {
    this.setState({isSaving: true});

    let {
      handleText
    } = this.state;
    let {
      doClose,
      t
    } = this.props;

    let handle = _.get(this.props, 'userHandle.handle', '')
    sapi.Handle.update(handle, handleText)
      .then((res) => {
        log.log('update handle res', res);

        return Promise.all([
          this.props.updateUserHandle(),
        ]);
      })
      .then((res) => {
        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error updating handle', err);
        this.setState({
          validation_errors: [getMessageForError(err, t)]
        })
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  activateBtnClick(){
    let {
      handleName,
    } = this.state;
    let handleNameErrorMessage = this.getHandleNameValidationError(handleName);
    if(handleNameErrorMessage){
      this.setState({
        validation_errors: [handleNameErrorMessage]
      })
      return;
    }

    let {
      doClose,
      t
    } = this.props;
    this.props.modalAction.showConfirm(t("Are you sure?"), t("Once you choose the Handle for your Public Upload Page, you will not be able to change it. Are you sure?"), (res) => {
      if(res){
        this.setState({isSaving: true});
        let {
          handleName,
          handleText,
        } = this.state;

        sapi.Handle.add(handleName, handleText)
          .then((res) => {
            log.log('update handle res', res);

            return Promise.all([
              this.props.updateUserHandle(),
            ]);
          })
          .then((res) => {
            setTimeout(() => {
              doClose();
            })
          })
          .catch((err) => {
            log.log('error updating handle', err);
            this.setState({
              validation_errors: [getMessageForError(err, t)]
            })
          })
          .finally(() => {
            this.setState({isSaving: false})
          })
      }
    })
  }

  reactivateBtnClick(){
    this.setState({isSaving: true});

    let {
      handleName,
      handleText,
    } = this.state;
    let {
      doClose,
      t
    } = this.props;

    sapi.Handle.update(handleName, handleText, true)
      .then((res) => {
        log.log('reactivate handle res', res);

        return Promise.all([
          this.props.updateUserHandle(),
        ]);
      })
      .then((res) => {
        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error updating handle', err);
        this.setState({
          validation_errors: [getMessageForError(err, t)]
        })
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  disableHandle(){
    let {
      doClose,
      t
    } = this.props;
    this.props.modalAction.showConfirm(t("Are you sure?"), t("Are you sure you want to disable your Public Upload Page?"), (res) => {
      if (res) {
        this.setState({isSaving: true});

        let handle = _.get(this.props, 'userHandle.handle', '')
        let handleText = _.get(this.props, 'userHandle.text', '')
        sapi.Handle.update(handle, handleText, false)
          .then((res) => {
            log.log('update handle res', res);

            return Promise.all([
              this.props.updateUserHandle(),
            ]);
          })
          .then((res) => {
            setTimeout(() => {
              doClose();
            })
          })
          .catch((err) => {
            log.log('error updating handle', err);
            this.setState({
              validation_errors: [getMessageForError(err, t)]
            })
          })
          .finally(() => {
            this.setState({isSaving: false})
          })
      }
    })
  }

  cancel() {
    let {doClose} = this.props;

    this.setState({
      validation_errors: [],
      isSaving: false,
      existingHandle: null,
      handleName : '',
      handleText : ''
    })

    doClose();
  }

  onHandleNameChange(evt){
    this.setState({handleName: _.toLower(evt.target.value)})
  }

  whatIsThisClick(){
    let {
      t
    } = this.props;

    this.props.modalAction.showAuthInfo(
      t('What is a Public Upload Page?'),
      <div>
        <div>
          <p>
            {t("Your Public Upload Page is a public webpage where other people can securely share documents and messages with you. You will get a unique link that you can add to your website or email signature to give people access to your page.")}
          </p>
          <p>
            {t("Your Public Upload Page Link will look like this: verifyle.com/@your_handle. You will choose your personalized Handle when you activate your Public Upload Page. Keep in mind that this Handle cannot be changed, so make sure you use something that makes your page identifiable for anyone who might visit your Public Upload Page (e.g., your name or the name of your business).")}
          </p>
          <p>
            {t("Anything submitted to you through your Public Upload Page will appear in a Private Message Thread between you and the submitter. ")}
          </p>
          <p>
            {t("The Public Upload Page is only available to our Gold and Platinum subscribers.")}
          </p>
          <p>
            {t("Your handle is subject to review and may be rejected.")}
          </p>
        </div>
      </div>
    )
  }

  getLinkRoot(){
    let {
      vip,
    } = this.props;
    let rootUrl = `https://${vip}`;
    if(config.debug){
      rootUrl = window.location.origin;
    }
    return rootUrl;
  }

  copyHandleLinkClick(evt){
    log.log('copy link click', evt);

    let contents =this.getWorkingHandleLink();
    navigator.clipboard.writeText(contents)
      .then(
        (evt) => {
          log.log('copy clipboard succes', evt)
          this.setState({copyLinkSuccess : true}, () => {
            setTimeout(() => {
              this.props.popoverAction.hidePopover();
              setTimeout(() => {
                this.setState({copyLinkSuccess : false});
              }, 500)
            }, 1000)
          })
        },
        (err) => {
          log.log('copy clipboard fail', err)
        },
      );
  }

  copyHandleHTMLClick(evt){
    log.log('copy html click', evt);
    let {
      i18n
    } = this.props;

    let handleImgUrl = c.HANDLE_SECURE_PAGE_IMG_URL_EN;
    if(i18n.language === c.language.es_es){
      handleImgUrl = c.HANDLE_SECURE_PAGE_IMG_URL_ES;
    }
    else if(i18n.language === c.language.fr_fr){
      handleImgUrl = c.HANDLE_SECURE_PAGE_IMG_URL_FR;
    }

    let contents =
      `<a href="${this.getWorkingHandleLink()}">` + '\n' +
      `<img width="250" src="${handleImgUrl}" />` + '\n' +
      `</a>`;

    navigator.clipboard.writeText(contents)
      .then(
      (evt) => {
        log.log('copy clipboard succes', evt);
        this.setState({copyHTMLSuccess : true}, () => {
          setTimeout(() => {
            this.props.popoverAction.hidePopover();
            setTimeout(() => {
              this.setState({copyHTMLSuccess : false});
            }, 500)
          }, 1000)
        })
      },
      (err) => {
        log.log('copy clipboard fail', err)
      },
    );
  }

  renderShareLinkPopover({position, childRect, popoverRect}){
    let popupArgs = {
      position,
      childRect,
      popoverRect
    }
    let data = {
      copyLinkSuccess : this.state.copyLinkSuccess,
      copyHTMLSuccess : this.state.copyHTMLSuccess
    }
    let controls = {
      copyLinkClick : this.copyHandleLinkClick.bind(this),
      copyHTMLLinkLick : this.copyHandleHTMLClick.bind(this),
      t : this.props.t
    }
    return popupHelper.getHandleShareLinkPopoverContents(popupArgs, data, controls)
  }

  getWorkingHandleLink(){
    let {
      handleName
    } = this.state;
    return `${this.getLinkRoot()}/@${handleName}`;
  }

  renderHandleLinkSection(){
    let {
      existingHandle,
      handleName
    } = this.state;
    let {
      accountClassInfo,
      vip,
      t
    } = this.props;

    let doesHandleExist = !!(existingHandle);
    let isLocked = _.get(existingHandle, 'locked_flag', false);
    let isActive = _.get(existingHandle, 'active_flag', false);
    let allowedToMakeHandles = _.get(accountClassInfo, 'class_info.handle_flag', false);

    let showDeactivated = (!isActive || !allowedToMakeHandles);

    let workingLink = '';
    if(doesHandleExist && isLocked){
      workingLink = this.getWorkingHandleLink();
    }

    return (
      <div className="form-group">
        <div className="text-center">
          {(!doesHandleExist) &&
            <a className="primary-color ml-2 small has-pointer" onClick={() => this.whatIsThisClick()}>
              {t("More Info")}
            </a>
          }
        </div>
        <label>
          {t("Public Upload Page Link")}
        </label>

        {(!doesHandleExist || !isLocked) &&
          <div style={{display: 'flex'}}>
            <span className={'px-1'} style={{lineHeight: '38px'}}>
              {t("verifyle.com/@")}
            </span>
            <input className={'form-control'}
                   title={t("Your Handle")}
                   type={'text'}
                   disabled={!allowedToMakeHandles}
                   maxLength={c.limits.maxHandleChars}
                   value={handleName}
                   onChange={(evt) => this.onHandleNameChange(evt)}/>
          </div>
        }
        {(doesHandleExist && isLocked) &&
          <div style={{display: 'flex', lineHeight:'32px'}}>
            <a href={workingLink}
               className={`${showDeactivated ? 'secondary-text-color' : 'primary-color'}`}
               target="_blank">
              {workingLink}
            </a>
            {showDeactivated &&
              <a className="text-danger ml-2 small">
                {t("Disabled")}
              </a>
            }
            {!showDeactivated &&
              <VFPopover isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.SHARE_HANDLE_POPOVER)}
                         positions={['top', 'bottom']}
                         containerClassName="preview-container-cls"
                         onClickOutside={() => this.props.popoverAction.hidePopover()}
                         getMenuContent={this.renderShareLinkPopover.bind(this)}>
                <a className="btn btn-link share-link ml-5 small"
                   onClick={() => this.props.popoverAction.showPopover(c.popovers.SHARE_HANDLE_POPOVER)}>
                  <i className="icon ion-android-share mr-2" />
                  {t("Share")}
                </a>
              </VFPopover>
            }
          </div>
        }

      </div>
    )
  }

  renderButtonSection(){
    let {
      isSaving,
      existingHandle
    } = this.state;
    let {
      userHandle,
      accountClassInfo,
      t
    } = this.props;

    let isLocked = _.get(existingHandle, 'locked_flag', false);
    let isActive = _.get(existingHandle, 'active_flag', false);
    let allowedToMakeHandles = _.get(accountClassInfo, 'class_info.handle_flag', false);

    let showUpgradeButton = !allowedToMakeHandles;
    let showSaveButton = (allowedToMakeHandles && !!existingHandle && isActive);
    let showActivateButton = (allowedToMakeHandles && !existingHandle);
    let showReactivateButton = (allowedToMakeHandles && existingHandle && !isActive);

    return (
      <div className={'row'}>
        <div className={'col'}>
          {allowedToMakeHandles && existingHandle && isActive &&
            <div className="text-left">
              <Button disabled={!userHandle}
                      className={'btn btn-danger'}
                      onClick={this.disableHandle.bind(this)}>{t("Disable Page")}</Button>
            </div>
          }
        </div>
        <div className="col-8">
          <div className={'text-right'}>
            {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
            }

            <Button disabled={isSaving}
                    className={'btn btn-secondary mr-2'}
                    onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>

            {showActivateButton &&
              <Button disabled={isSaving}
                      className={'btn btn-primary'}
                      onClick={this.activateBtnClick.bind(this)}>{t("Activate Page")}</Button>
            }
            {showReactivateButton &&
              <Button disabled={isSaving}
                      className={'btn btn-primary'}
                      onClick={this.reactivateBtnClick.bind(this)}>{t("Activate Page")}</Button>
            }
            {showSaveButton &&
              <Button disabled={isSaving}
                      className={'btn btn-primary'}
                      onClick={this.updateHandleClick.bind(this)}>{t("Save")}</Button>
            }
            {showUpgradeButton &&
              <Button disabled={isSaving}
                      className={'btn btn-primary'}
                      onClick={() => this.props.doUpgradeClick()}>{t("Upgrade to Activate")}</Button>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      isSaving,
      validation_errors,
      existingHandle,
      handleName,
      handleText
    } = this.state;
    let {
      userHandle,
      accountClassInfo,
      t
    } = this.props;

    let doesHandleExist = !!(existingHandle);
    let isLocked = _.get(existingHandle, 'locked_flag', false);
    let isActive = _.get(existingHandle, 'active_flag', false);
    let allowedToMakeHandles = _.get(accountClassInfo, 'class_info.handle_flag', false);

    // log.log('render handle', userHandle);

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'row'}>
          <div className={'col'}>
            {(!doesHandleExist) &&
              <div className="text-center py-2">
                <iframe src={c.links.youtubeHandleTutorialLink}
                        className="handle-embed-video"
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        title='video'
                />
              </div>
            }
            {this.renderHandleLinkSection()}
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group mt-4">
              <label>{t("Public Upload Page Message")}</label>
              <textarea className={'form-control no-resize'}
                        disabled={!allowedToMakeHandles}
                        rows={4}
                        maxLength={c.limits.maxHandleTextChars}
                        value={handleText}
                        onChange={(evt) => this.setState({handleText: evt.target.value})}/>
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        {this.renderButtonSection()}
      </div>
    )
  }
}

EditHandleCtrl.propTypes = {
  doClose: PropTypes.func.isRequired,
  doUpgradeClick: PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    logo: state.shared.logo,
    vip: state.auth.vip,
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    userHandle : state.account.userHandle,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserHandle: () => dispatch(accountActions.updateUserHandle()),
    modalAction : {
      ...modalActions.mapToDispatch(dispatch),
    },
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditHandleCtrl)));
