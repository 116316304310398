import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash'
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import utils from "../../util/util";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class MesgEditWindow extends Component {

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      mesg : _.get(props, 'msg.mesg', '')
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    utils.waitForCondition(() => {
        return !!(_.get(this.inputRef, 'current'))
      })
      .then(() => {
        let ref = _.get(this.inputRef, 'current');
        if (ref) {
          ref.focus();
          if (this.state.mesg) {
            ref.selectionStart = this.state.mesg.length;
          }
        }
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  saveChanges(){
    let { workspace, dm, thread, t } = this.props;
    let { msg } = this.props.modalProps;
    let { mesg } = this.state;

    let docIdsToDetach = {};
    if(thread){
      sapi.Threads.editMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, msg.mesg_id, mesg, docIdsToDetach)
        .then((res) => {
          log.log('edit message res', res);
          this.closeModal(res);
        })
        .catch((err) => {
          log.error('error saving message', err);
          this.props.showAlert(t('Error saving message'), getMessageForError(err, t))
        })
    }
    else if(dm){
      sapi.DM.editMessage(dm.guest_uid, msg.mesg_id, mesg, docIdsToDetach)
        .then((res) => {
          log.log('edit message res', res);
          this.closeModal(res);
        })
        .catch((err) => {
          log.error('error saving message', err);
          this.props.showAlert(t('Error saving message'), getMessageForError(err, t))
        })
    }
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, accountInfoGuest, t} = this.props;
    let { msg } = modalProps;
    let { mesg } = this.state;

    let time = utils.getMomentDate(msg.mesg_date).format('LT');

    let rowStyle = {backgroundColor : colors.THREAD_COLOR_YOU};
    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Edit Message")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">

          <div className="d-flex">
            <div className="mr-2">
              <UserBadge guest={accountInfoGuest}
                         overrideColor={colors.PRIMARY}/>
            </div>
            <div className="flex-grow-1 msg-edit-block" style={{...styles.messageBlock, ...rowStyle}}>
              <p className="d-inline-block mb-0" style={{fontWeight: '600'}}>
                {accountInfoGuest.first_name} {accountInfoGuest.last_name}
                <span style={styles.blockTime}>
                  [{time}]
                </span>
              </p>
              <div>
                <textarea value={mesg}
                          rows={5}
                          ref={this.inputRef}
                          onChange={(evt) => this.setState({mesg : evt.target.value})}
                          className="no-resize mesg-edit-textarea form-control" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={mesg === msg.mesg}
                  onClick={this.saveChanges.bind(this, true)}>{t("Save")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  docControlWrap: {
    borderRadius: '20px',
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  },
  docDeletedWrap: {
    color: colors.SECONDARY_TEXT,
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

MesgEditWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(MesgEditWindow));
