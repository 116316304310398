import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import { getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import _ from "lodash";
import Checkbox from "../partials/elements/Checkbox";
import log from "../../util/log";
import colors from "../../util/colors";
import sapi from "../../util/sapi";
import c from "../../util/const";
import Promise from "bluebird";
import modalActions from "../../actions/modal-actions";
import downloadActions from "../../actions/download-actions";
import Loading from "../partials/util/Loading";
import {withVFTranslation} from "../../util/withVFTranslation";
import searchHelper from "../../helpers/search-helper";
import limitHelper from "../../util/limit-helper";
import utils from "../../util/util";

class DownloadMultipleWindow extends Component {

  PRIVATE_MESSAGES = 'private_messages'
  ALL_THREADS = 'all_threads'
  mounted = false;

  constructor(props) {
    super(props);

    let {
      initial_selection_forum_id,
      initial_selection_guest_uid,
      initial_selection_chat_id,
      selectedDocIds
    } = this.props.modalProps;

    let selectedWsOption = null;
    let selectedDestinationOption = null;
    if(initial_selection_guest_uid){
      selectedWsOption = this.PRIVATE_MESSAGES;
      selectedDestinationOption = initial_selection_guest_uid
    }
    else if(initial_selection_forum_id){
      selectedWsOption = initial_selection_forum_id;
      selectedDestinationOption = initial_selection_chat_id;
    }

    log.log('download multiple window', this.props.modalProps);
    this.state = {

      initialDocIdSelection : selectedDocIds,
      checkedDocs : [],
      availableDocs : [],
      filteredDocs : [],
      searchString : '',
      docListLookup: {},
      threadLookup : {},
      threadMenuList : [],
      contactMenuList : _.map(props.directMessages, (c) => {
        return {
          key : c.guest_uid,
          val : c.first_name + ' ' + c.last_name
        }
      }),
      selectedWsOption,
      selectedDestinationOption,

      loading : false,
      validationErr: null,
      downloadReadyDocId : null,
      downloadReadyForumId : null,
      downloadQueueId : null,
      isZipping : false,
      zippingSuccessful : false
    }
  }

  componentDidMount() {
    this.mounted = true;

    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;
    this.setState({loading : true});
    this.refreshAvailableDocs()
      .then(() => {
        let { initial_selection_guest_uid, initial_selection_chat_id } = this.props.modalProps;
        this.updateDestinationOptions(initial_selection_guest_uid, initial_selection_chat_id);
      })
      .catch((err) => {
        log.error('error loading available docs', err);
        this.props.showAlert(t('Error loading documents'), t("There was a problem loading available documents.  Please try again"), () => {
          this.closeModal(false);
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }

  componentWillUnmount() {
    this.mounted = false;
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    this.props.close();
  }

  getWorkspaceMenuList(){
    let { workspaces, t } = this.props;

    return _.concat(
      [
        {
          isPrivateMessageOption: true,
          key: this.PRIVATE_MESSAGES,
          val: t('Private Messages')
        },
      ],
      _.map(workspaces, (w) => {
        return {
          key: w.forum_id,
          val: w.label
        }
      })
    );
  }

  refreshAvailableDocs(){
    let { workspaces, accountInfo} = this.props;

    return sapi.Docs.availableDocs()
      .then((res) => {

        //set host_uids so they get passed through later.
        let docs = [];
        _.each(res.data, (doc) => {
          let workspace = _.find(workspaces, (ws) => ws.forum_id === doc.forum_id);
          if(workspace) {
            docs.push({
              ...doc,
              host_uid : workspace.host_uid || accountInfo.uid
            })
          }
        })
        log.log('availableDocs', docs);
        this.setState({
          availableDocs : docs
        }, () => {
          this.refreshDocFilters();
        });
      })
  }

  fetchThreadsForWorkspace(forum_id, host_uid){
    return new Promise((resolve, reject) => {
      if(this.state.threadLookup[forum_id]){
        resolve(this.state.threadLookup[forum_id])
      }
      else{
        sapi.Threads.get(forum_id, host_uid)
          .then((res) => {
            let update = _.extend({}, this.state.threadLookup);
            update[forum_id] = res.data;
            this.setState({ threadLookup : update }, () => {
              resolve(res.data);
            })
          })
          .catch((err) => {
            log.warn('error fetching threads', err);
            resolve([])
          })
      }
    })
  }

  fetchThreadDocs(forum_id, host_uid, chat_id){
    return new Promise((resolve, reject) => {
      let key = chat_id ? chat_id : forum_id + host_uid;
      if(this.state.docListLookup[key]){
        resolve(this.state.docListLookup[key]);
      }
      else{
        sapi.Docs.list(forum_id, host_uid, chat_id)
          .then((res) => {

            //this is weird in here!  So bug 2532 forced me to stop just returning the availableDocs
            //In the case where they've selected All Threads.  It really means that I need to allow calling this
            //api call without a chat_id.  However that's a little weird, because it can return results in multiple threads.
            //In that case, we need to save those results, but we have to carefully update the cache, since we can't duplicate a doc, or you could have weird selection issues.
            //We also want to make sure that call's result gets cached properly, so in the cases where there's no chat_id
            //we make a new lookup key here, the concatenation of forum_id and host_uid, which should be unique for our purposes and stored on docListLookup
            let docResult = [];
            let update = _.extend({}, this.state.docListLookup);
            _.each(res.data, (block) => {
              if(!update[block.chat_id]) {
                update[block.chat_id] = block.docs;
                docResult = _.concat(docResult, block.docs);
              }
              else{
                docResult = _.concat(docResult, update[block.chat_id]);
              }
            })

            if(!chat_id){
              update[key] = docResult;
            }

            this.setState({ docListLookup : update }, () => {
              resolve(docResult);
            })
          })
          .catch((err) => {
            log.warn('error fetching thread docs', err);
            resolve([])
          })
      }
    })
  }

  fetchDMDocs(guest_uid){
    let { t } = this.props;
    return new Promise((resolve, reject) => {
      if(this.state.docListLookup[guest_uid]){
        resolve(this.state.docListLookup[guest_uid]);
      }
      else{
        log.log('fetchDmDocs', guest_uid);
        sapi.DM.docList(guest_uid)
          .then((res) => {
            //we need to make this look like the /avail response.
            let foundDM = _.find(this.props.directMessages, (dm) => dm.guest_uid === guest_uid);
            let results = [];
            _.each(res.data, (block) => {
              _.each(block.docs, (d) => {
                results.push({
                  forum_label : t('Conversation with ') + foundDM.first_name + ' ' + foundDM.last_name,
                  forum_id : foundDM.forum_id,
                  doc_label : d.label,
                  doc_id : d.doc_id,
                  host_uid : foundDM.host_uid || guest_uid,
                  updated_date : d.updated_date,
                  isDM : true
                })
              })
            })

            log.log('dm doc results', results);

            let update = _.extend({}, this.state.docListLookup);
            update[guest_uid] = results;
            this.setState({ docListLookup : update }, () => {
              resolve(results);
            })
          })
          .catch((err) => {
            log.warn('error fetching dm docs', err);
            resolve([])
          })
      }
    })
  }

  onSearchUpdate(evt){
    this.setState({searchString : evt.target.value}, () => { this.refreshDocFilters() });
  }

  onSearchClear(){
    this.setState({searchString : ''}, () => { this.refreshDocFilters() });
  }

  filterDocsByDestination(availableDocs){
    return new Promise((resolve, reject) => {
      let { selectedWsOption, selectedDestinationOption } = this.state;
      let { accountInfo } = this.props;

      if(selectedWsOption === this.PRIVATE_MESSAGES){

        if(!selectedDestinationOption || selectedDestinationOption.length === 0){
          resolve([])
        }
        else{
          //We have to make special calls here.  We're not using the /avail call for these results
          //because we have to actually pull the dm doc list.  This is the result we return here
          //rather than some filtered list of availableDocs because dm docs don't come back in that list.
          resolve(this.fetchDMDocs(selectedDestinationOption));
        }
      }
      else {

        //NOTE: this handles the ALL THREADS case as well by passing null to fetchThreadDocs()

        let workspace = _.find(this.props.workspaces, (ws) => ws.forum_id === selectedWsOption);
        if (!workspace) {
          resolve([])
        }

        let fetchChatId = selectedDestinationOption === this.ALL_THREADS ? null : selectedDestinationOption;
        this.fetchThreadDocs(workspace.forum_id, workspace.host_uid, fetchChatId)
          .then((res) => {
            let filtered = [];
            _.each(res, (d) => {

              let found = false;
              _.each(availableDocs, (avail) => {
                if (d.doc_id === avail.doc_id) {
                  found = true;
                  filtered.push(
                    _.extend({}, avail, d, {forum_label: workspace.label})
                  );
                }
              })
              if (!found) {
                //Didn't come back in doc/avail, we need to explicitly add it. (but mimic the doc/avail structure)
                filtered.push(
                  _.extend({}, d, {
                    //set host_uids so they get passed through later.
                    host_uid: workspace.host_uid || accountInfo.uid,
                    doc_label: d.label,
                    forum_id: workspace.forum_id,
                    forum_label: workspace.label
                  })
                );
              }
            })

            resolve(filtered);
          })
          .catch((err) => {
            log.warn('error loading thread docs', err);
            resolve([]);
          })
      }
    })
  }

  refreshDocFilters() {
    let {searchString, availableDocs} = this.state;

    this.filterDocsByDestination(availableDocs)
      .then((destDocs) => {
        let filteredDocs = [];
        _.each(destDocs, (doc) => {
          let searchIndex = [doc.doc_label, doc.forum_label];
          if(searchHelper.findInTokens(searchString, searchIndex)){
            filteredDocs.push(doc);
          }
        })

        filteredDocs = _.uniqBy(filteredDocs, 'doc_id');
        filteredDocs = _.sortBy(filteredDocs, (doc) => -doc.updated_date);

        //A little messy, but the dialog gets initialized before we have a doc list.
        // We need to wait for the first refresh to populate the initial selection
        if(this.state.initialDocIdSelection){
          let checkedDocs = [];
          _.each(this.state.initialDocIdSelection, (doc_id) => {
            let found = _.find(filteredDocs, (d) => d.doc_id === doc_id);
            if(found){
              checkedDocs.push(found);
            }
          })
          this.setState({
            filteredDocs,
            checkedDocs,
            initialDocIdSelection : null //reset this so we only use at startup.
          })
        }
        else{
          this.setState({filteredDocs})
        }
      })
  }

  docCheckChange(doc, evt){
    let update = _.concat([], this.state.checkedDocs)
    if(!evt.target.checked){
      _.remove(update, (checkedDoc) => { return checkedDoc.doc_id === doc.doc_id})
    }
    else{
      update.push(doc);
    }
    log.log('check change', update);
    this.setState({checkedDocs : update});
  }

  onWSOptionChange(evt){
    log.log('on current workspace change', evt.target.value);
    this.setState({
      checkedDocs : [],
      selectedWsOption : evt.target.value
    }, () => {
      this.updateDestinationOptions();
    });
  }

  getSelectedWorkspace(){
    let {
      selectedWsOption,
      selectedDestinationOption
    } = this.state;
    let {
      workspaces
    } = this.props;

    if(selectedWsOption === this.PRIVATE_MESSAGES){
      let foundDM = _.find(this.props.directMessages, (dm) => dm.guest_uid === selectedDestinationOption);
      log.log('get selected dm', foundDM);
      return foundDM;
    }
    else{
      let found = _.find(workspaces, (ws) => ws.forum_id === selectedWsOption)
      log.log('get selected workspace', found);
      return found;
    }
  }

  updateDestinationOptions(initial_guest_uid, initial_chat_id){
    let { selectedWsOption, contactMenuList } = this.state;
    let { t } = this.props;

    if(selectedWsOption === this.PRIVATE_MESSAGES){
      log.log('updateDestinationOtpions', selectedWsOption, initial_guest_uid);
      if(initial_guest_uid){
        this.setState({selectedDestinationOption : initial_guest_uid}, () => {
          this.refreshDocFilters();
        })
      }
      else if(contactMenuList.length > 0){
        this.setState({selectedDestinationOption : contactMenuList[0].key}, () => {
          this.refreshDocFilters();
        })
      }
      else{
        this.setState({selectedDestinationOption : ''}, () => {
          this.refreshDocFilters();
        })
      }
    }
    else{
      let found = _.find(this.props.workspaces, (w) => w.forum_id === selectedWsOption);
      if(!found){
        log.warn('no results found for workspace', selectedWsOption);
        this.setState({threadMenuList : []})
      }
      else {
        this.fetchThreadsForWorkspace(found.forum_id, found.host_uid)
          .then((res) => {

            let selectedDest = initial_chat_id;
            if(!selectedDest){
              if(res.length > 0){
                selectedDest = res[0].chat_id;
              }
            }

            let threadMenuList = [];
            if (res.length > 0) {
              threadMenuList = _.concat([{
                  key: this.ALL_THREADS,
                  val: t('All Threads')
                }],
                _.map(res, (t) => {
                    return {
                      key: t.chat_id,
                      val: t.label
                    }
                  }
                ))
            }

            this.setState({
              selectedDestinationOption: selectedDest,
              threadMenuList
            }, () => {
              this.refreshDocFilters();
            })
          })
      }
    }
  }

  onDestinationChange(evt){
    this.setState({
      checkedDocs : [],
      selectedDestinationOption : evt.target.value
    }, () => {
      this.refreshDocFilters();
    })
  }

  getDownloadHeaders(){
    let { downloadReadyForumId, downloadReadyDocId, checkedDocs } = this.state;
    if(downloadReadyForumId && downloadReadyDocId){
      let params = {
        forum_id : downloadReadyForumId,
        doc_id : downloadReadyDocId
      }
      params[c.api.X_TOKEN] = sapi.getToken();
      return params;
    }
    else if(checkedDocs.length === 1){
      let foundWorkspace = this.getSelectedWorkspace();
      let params = {
        forum_id : foundWorkspace.forum_id,
        doc_id : checkedDocs[0].doc_id
      }
      if (foundWorkspace.host_uid) {
        params.host_uid = foundWorkspace.host_uid;
      }
      params[c.api.X_TOKEN] = sapi.getToken();

      return params;
    }
    return {};
  }

  selectClick(){
    let { checkedDocs } = this.state;

    if(checkedDocs.length === 1) {
      //Just forces us to success view.  Handling single file vs zip file is handled in the download handler.
      this.setState({
        zippingSuccessful : true
      })
    }
    else{
      this.zipAndDownload();
    }
  }

  waitForZipToFinish(queue_id){
    let { t } = this.props;

    return sapi.Workspace.downloadZipStatus(queue_id)
      .then((statusRes) => {
        if(!this.mounted){
          return;
        }

        if(statusRes.data.queue_status === 'done'){
          this.setState({
            downloadReadyDocId : statusRes.data.doc_id,
            downloadReadyForumId : statusRes.data.forum_id,
            zippingSuccessful : true,
            downloadQueueId : null,
            isZipping : false
          })
        }
        else if(statusRes.queue_status === 'error'){
          this.setState({
            validationErr : t('There was an error packaging your Documents.  Please try again.'),
            downloadReadyDocId : null,
            downloadReadyForumId : null,
            downloadQueueId : null,
            zippingSuccessful : false,
            isZipping : false
          })
        }
        else{
          //retry
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(this.waitForZipToFinish(queue_id))
            }, 2000)
          })
        }
      })
  }

  zipAndDownload(){
    let { t } = this.props;
    let foundWorkspace = this.getSelectedWorkspace();
    let { checkedDocs } = this.state;
    sapi.Workspace.downloadZip(foundWorkspace.forum_id, foundWorkspace.host_uid, _.map(checkedDocs, 'doc_id'))
      .then((res) => {
        log.log('zip queue res', res);
        this.setState({
          downloadQueueId : res.data.queue_id,
          isZipping : true
        })
        return this.waitForZipToFinish(res.data.queue_id);
      })
      .catch((err) => {
        if(!this.mounted){
          return;
        }
        log.error('error zipping files', err);

        let errMsg = '';
        let errName = _.get(err, 'name');
        if(errName === 'APP_FILE_TOOLARGE'){
          let size = utils.bytesToSize(t, limitHelper.getMaxBulkDocDownloadSizeBytes(this.props.serverConfig));
          errMsg = t("Your total file size is over the ") + size + t(" limit for multiple document download. Please select a smaller set of documents and try again.");
        }
        else{
          errMsg = getMessageForError(err, t);
        }
        this.props.showAlert(t('Error Downloading Files'), errMsg);
        this.setState({
          downloadQueueId : null,
          isZipping : false
        })
      })
  }

  downloadClick(){
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));
    this.closeModal(true);
  }

  getSuccessView(){
    let { t } = this.props;
    let { checkedDocs } = this.state;

    if(checkedDocs.length === 1){
      return (
        <>
          <h3 className="text-center green-color mb-3">
            <i className="icon ion-checkmark-circled green-color mr-2" />
            {t("Success!")}
          </h3>
          <p className="text-center mb-5">
            {t("Your Document is ready for download.")}
          </p>
          <div className="text-center mb-5">
            <button className="btn btn-lg btn-primary" onClick={this.downloadClick.bind(this)}>
              {t("Download File")}
            </button>
          </div>
        </>
      )
    }
    else{
      return (
        <>
          <h3 className="text-center green-color mb-3">
            <i className="icon ion-checkmark-circled green-color mr-2" />
            {t("Success!")}
          </h3>
          <p className="text-center mb-5">
            {t("Your Documents have been packaged and are ready for download.")}
          </p>
          <div className="text-center mb-5">
            <button className="btn btn-lg btn-primary" onClick={this.downloadClick.bind(this)}>
              {t("Download Zip File")}
            </button>
          </div>
        </>
      )
    }
  }

  getProgressView(){
    let { t } = this.props;
    return (
      <>
        <div>
          <h3 className="text-center mb-3">
            {t("Preparing Download...")}
          </h3>
          <p className="text-center mb-5">
            {t("We're packaging your Documents into a single zip file for download.")}
          </p>
        </div>
        <div className="mb-5">
          <Loading centered={true}
                   size={'sm'}/>
        </div>
      </>
    )
  }

  getSelectionView(){
    let { t } = this.props;
    let {
      checkedDocs,
      availableDocs,
      filteredDocs,
      searchString,
    } = this.state;

    return (
      <div style={{
        minHeight : '300px'
      }}>
        {_.map(filteredDocs, (doc) => {
          let docChecked = _.find(checkedDocs, (checkedDoc) => checkedDoc.doc_id === doc.doc_id);
          return (
            <div key={doc.doc_id} className="d-flex mb-3">
              <div className="flex-grow-1" style={styles.userColumn}>
                <h6 className="mb-0 word-break-all"
                    style={docChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                  {doc.doc_label}
                </h6>
                <p className="mb-0"
                   style={docChecked ? styles.selectedUserEmail : styles.unselectedUser}>
                  {doc.isDM ? doc.forum_label :  t("Workspace: ") + doc.forum_label}
                </p>
              </div>
              <div style={styles.userColumn} className="ml-3 mr-2">
                <div className="d-inline-block">
                  <Checkbox isChecked={!!docChecked}
                            rootCls="big-round-checkbox form-control-lg"
                            label={' '}
                            onChange={this.docCheckChange.bind(this, doc)}
                            labelCls={'primary-color'} />
                </div>
              </div>
            </div>
          )
        })}
        {searchString.length > 0 && filteredDocs.length === 0 && availableDocs.length > 0 &&
        <h4 className="font-weight-light text-center py-5">
          {t("No matches found.")}
        </h4>
        }
        {searchString.length === 0 && filteredDocs.length === 0 &&
        <h4 className="font-weight-light text-center py-5">
          {t("No documents are available")}
        </h4>
        }
      </div>
    )
  }

  renderDestination(){
    let { selectedWsOption, selectedDestinationOption, contactMenuList, threadMenuList } = this.state;
    let { t } = this.props;

    if(selectedWsOption === this.PRIVATE_MESSAGES){
      return (
        <div className="form-inline mb-3 d-table w-100">
          <label className="d-table-cell"
                 style={styles.inlineLabel}>
            {t("Contact")}
          </label>
          {contactMenuList.length === 0 &&
          <select className="form-control d-table-cell w-100"
                  disabled={true}
                  value={selectedDestinationOption}
                  onChange={this.onDestinationChange.bind(this)}>
            <option value={''}>
              {t("No Contacts")}
            </option>
          </select>
          }
          {contactMenuList.length > 0 &&
          <select className="form-control d-table-cell w-100"
                  value={selectedDestinationOption}
                  onChange={this.onDestinationChange.bind(this)}>
            {contactMenuList.map((c) => {
              return <option key={c.key}
                             value={c.key}>
                {c.val}
              </option>
            })}
          </select>
          }
        </div>
      )
    }
    else{
      return (
        <div className="form-inline mb-3 d-table w-100">
          <label className="d-table-cell"
                 style={styles.inlineLabel}>
            {t("Thread")}
          </label>
          {threadMenuList.length === 0 &&
          <select className="form-control d-table-cell w-100"
                  disabled={true}
                  value={selectedDestinationOption}
                  onChange={this.onDestinationChange.bind(this)}>
            <option value={''}>
              {t("No Threads")}
            </option>
          </select>
          }
          {threadMenuList.length > 0 &&
          <select className="form-control d-table-cell w-100"
                  value={selectedDestinationOption}
                  onChange={this.onDestinationChange.bind(this)}>
            {threadMenuList.map((c) => {
              return <option key={c.key}
                             value={c.key}>
                {c.val}
              </option>
            })}
          </select>
          }
        </div>
      )
    }
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {
      validationErr,
      checkedDocs,
      searchString,
      selectedWsOption,
      isZipping,
      zippingSuccessful
    } = this.state;
    let { t } = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className={`modal-title ${(zippingSuccessful || isZipping) ? 'invisible' : ''}`}>
            {t("Download Documents")}
          </h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>

        {!zippingSuccessful && !isZipping &&
        <div className="modal-header">
          <div className="d-block w-100">
            <div className="w-100">
              <div className="form-inline mb-3 d-table w-100">
                <label
                  className={`${selectedWsOption === this.PRIVATE_MESSAGES ? 'invisible' : ''}` + " d-table-cell"}
                  style={styles.inlineLabel}>
                  {t("Workspace")}
                </label>
                <select className="form-control d-table-cell w-100"
                        value={selectedWsOption}
                        onChange={this.onWSOptionChange.bind(this)}>
                  {this.getWorkspaceMenuList().map((opt) => {
                    return (
                      <option key={opt.key}
                              value={opt.key}>
                        {opt.val}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="w-100">
              {this.renderDestination()}
            </div>
            <div className="w-100">
              <div>
                <p className={`text-center small ${(checkedDocs.length <= limitHelper.getMaxBulkDocDownloadLimit(this.props.serverConfig) ? 'text-muted' : 'text-danger')}`}>
                  {t('Maximum')} {utils.bytesToSize(t, limitHelper.getMaxBulkDocDownloadSizeBytes(this.props.serverConfig))}, {limitHelper.getMaxBulkDocDownloadLimit(this.props.serverConfig)} {t('Documents')}
                </p>
              </div>
              <div className="input-group position-relative">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="icon ion-ios-search-strong"/>
                  </div>
                </div>
                <input className="form-control"
                       type="search"
                       autoComplete="off"
                       autoCorrect="off"
                       autoCapitalize="off"
                       spellCheck="false"
                       value={searchString}
                       onChange={this.onSearchUpdate.bind(this)}
                       placeholder={t('Search Documents')}/>
                {searchString.length > 0 &&
                <div style={styles.customLabelClearBtn}
                     onClick={this.onSearchClear.bind(this)}>
                  <i className="icon ion-close dark-color"/>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        }

        <div className="modal-body">
          {!zippingSuccessful && !isZipping && this.getSelectionView()}
          {!zippingSuccessful && isZipping && this.getProgressView()}
          {zippingSuccessful && !isZipping && this.getSuccessView()}
        </div>
        {!zippingSuccessful && !isZipping &&
        <div className="modal-footer">
          {validationErr && <ValidationErrors errors={[validationErr]}/>}
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button disabled={checkedDocs.length === 0 || checkedDocs.length > limitHelper.getMaxBulkDocDownloadLimit(this.props.serverConfig)}
                  className={'btn btn-primary'}
                  onClick={this.selectClick.bind(this)}>
            {t("Download")} {checkedDocs.length === 1 ? t('1 Document') : checkedDocs.length + t(' Documents')}
          </Button>
        </div>
        }
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
    fontWeight: '100'
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
    fontWeight: '100'
  },
  inlineLabel : {
    width: '100px'
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  }
}

const mapStateToProps = (state) => {
  return {
    serverConfig : state.app.serverConfig,
    workspaces : state.shared.workspaces,
    directMessages : state.shared.directMessages,
    accountInfo : state.shared.accountInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

DownloadMultipleWindow.propTypes = {
  close: PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DownloadMultipleWindow));
