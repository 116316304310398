import React, {PureComponent, Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import utils from '../../../util/util';
import c from '../../../util/const';

import moment from 'moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import MessageBlock from "./MessageBlock";
import colors from "../../../util/colors";
import Scroll from 'react-scroll';
import Autolinker from "autolinker";
import he from 'he';
import filters from "../../../helpers/filters";
import EmptyState from "../components/EmptyState";
import Button from "../elements/Button";
import ReactToPrint from "react-to-print";
import ChatPrintPreviewSvc from "./ChatPrintPreviewSvc";
import {List, AutoSizer} from 'react-virtualized';
import Measure from "react-measure";
import msgHelper from "../../../helpers/msg-helper";
import workspaceActions from "../../../actions/workspace-actions";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import ReactDOM from "react-dom";
import RenderPortal from "../util/RenderPortal";
import SignatureRequest from "../../../models/SignatureRequest";

class VirtualMessageBlock extends Component {

  componentDidMount() {
    this.mounted = true;
    this.updateItemHeight(0);
  }

  updateItemHeight(waitTime = 50){
    setTimeout(() => {
      if (!this.mounted) {
        return;
      }
      this.setState(
        {
          loaded: true
        },
        () => {
          let { height } = this.node.getBoundingClientRect();
          //log.log('calc item bounding height', index, height);
          this.props.onHeightUpdate(height);
        }
      );
    }, waitTime);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let thisItem = _.get(this.props, 'currentItem');
    let nextItem = _.get(nextProps, 'currentItem');

    if (!_.isEqual(thisItem, nextItem)) {
      //log.log('cmp updating because currentItem', thisItem, nextItem);
      this.updateItemHeight(0);
      return true;
    }

    let thisPrevious = _.get(this.props, 'previousItem');
    let nextPrevious = _.get(nextProps, 'previousItem');
    if (!_.isEqual(thisPrevious, nextPrevious)) {
      //log.log('cmp updating because currentItem', thisPrevious, nextPrevious);
      this.updateItemHeight(0);
      return true;
    }

    let thisWindowHeight = _.get(this.props, 'windowHeight');
    let nextWindowHeight = _.get(nextProps, 'windowHeight');
    if (!_.isEqual(thisWindowHeight, nextWindowHeight)) {
      //log.log('msg updating window height', thisWindowHeight, nextWindowHeight);
      this.updateItemHeight(0);
      return true;
    }

    let thisWindowWidth = _.get(this.props, 'windowWidth');
    let nextWindowWidth = _.get(nextProps, 'windowWidth');
    if (!_.isEqual(thisWindowWidth, nextWindowWidth)) {
      //log.log('msg updating window width', thisWindowWidth, nextWindowWidth);
      this.updateItemHeight(0);
      return true;
    }

    let thisData = _.get(this.props, 'data.dm');
    let nextData = _.get(nextProps, 'data.dm');

    if (!_.isEqual(thisData, nextData)) {
      //log.log('cmp updating because dm change', thisData, nextData);
      return true;
    }

    let thisThead = _.get(this.props, 'data.thread');
    let nextThread = _.get(nextProps, 'data.thread');

    if (!_.isEqual(thisThead, nextThread)) {
      //log.log('cmp updating because thread change', thisThead, nextThread);
      return true;
    }
    return false;
  }

  render() {
    let {index, style, currentItem, previousItem, data} = this.props;
    // log.log('message wrap render', currentItem, previousItem);

    let {
      thread,
      dm,
      mesg_edit_flag,
      findDocInfo,
      findGuestInActiveThreadParticipants,
      onMesgEditClick,
      onMesgHistoryClick,
      onDocClick,
      completeSignatureRequestClick,
      fulfillSignatureRequestClick,
      cancelSignatureRequestClick,
      onAttachDocToThread,
      onPdfSubmit
    } = data;

    const item = currentItem;
    let showDay = false;
    let currentDate = null;
    if (index === 0) {
      showDay = true;
      currentDate = filters.momentDate(_.get(item, 'blockList[0].mesg_date'));
    }
    else {
      let previousDate = filters.momentDate(_.get(previousItem, 'blockList[0].mesg_date'));
      currentDate = filters.momentDate(_.get(item, 'blockList[0].mesg_date'));

      if(previousDate && currentDate) {
        showDay = !previousDate.isSame(currentDate, 'day');
      }
    }

    let guest = dm;
    if (thread) {
      guest = findGuestInActiveThreadParticipants(item.guest_uid);
      if (!guest) {
        //A little weird looking, but if we can't find the guest from thread participants,
        //just pass in the message itself, since it has guest_uid, first_name, last_name on it.
        guest = _.get(item, 'blockList[0]');
      }
    }

    return (
      <div ref={node => (this.node = node)}>
        <div className="d-inline-block w-100">
          {showDay && currentDate &&
          <div style={styles.msgDateWrap}>
            <div style={styles.msgDateBlock}>
              <span className="light-grey-color">{currentDate.format('LL')}</span>
            </div>
          </div>
          }
          {(dm || thread) &&
          <MessageBlock guest={guest}
                        dm={dm}
                        thread={thread}
                        mesg_edit_flag={mesg_edit_flag}
                        docClick={onDocClick}
                        block={item}
                        onMesgEditClick={onMesgEditClick}
                        onMesgHistoryClick={onMesgHistoryClick}
                        findDocInfo={findDocInfo}
                        onAttachDocToThread={onAttachDocToThread}
                        onPdfSubmit={onPdfSubmit}
                        cancelSignatureRequestClick={cancelSignatureRequestClick}
                        fulfillSignatureRequestClick={fulfillSignatureRequestClick}
                        completeSignatureRequestClick={completeSignatureRequestClick}/>
          }
        </div>
      </div>
    )
  }
}

const styles = {
  msgDateWrap: {
    borderTop: '1px solid ' + colors.LIGHT_GREY,
    margin: '20px auto 0',
    pointerEvents: 'none'
  },
  msgDateBlock: {
    position: 'relative',
    backgroundColor: colors.LIGHT,
    textAlign: 'center',
    display: 'table',
    margin: 'auto',
    top: '-9px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '11px'
  }
}

VirtualMessageBlock.propTypes = {
  data: PropTypes.object,
  previousItem: PropTypes.object,
  currentItem: PropTypes.object,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  recalculateHeight: PropTypes.func,
  onHeightUpdate: PropTypes.func
}

export default VirtualMessageBlock;
