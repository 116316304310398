import React, {Component, Fragment} from 'react';
import utilityActions from "../actions/utility-actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import electronUtil from "../util/electron-util";
import _ from "lodash";
import log from "../util/log";


class NotificationUpdater extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    let prevDMCount = _.get(prevProps, 'directMessageNotifyCount');
    let nowDMCount = _.get(this.props, 'directMessageNotifyCount');

    let prevWSCount = _.get(prevProps, 'workspaceNotifyCount');
    let nowWSCount = _.get(this.props, 'workspaceNotifyCount');

    if(prevDMCount !== nowDMCount || prevWSCount !== nowWSCount){
      this.updateNotificationCountIfNeeded();
    }
  }

  getCurrentNotificationCount(){
    let nowDMCount = +_.get(this.props, 'directMessageNotifyCount', 0);
    let nowWSCount = +_.get(this.props, 'workspaceNotifyCount', 0);
    log.log('*** getCurrentnotificationCount', nowDMCount, nowWSCount)
    return nowDMCount + nowWSCount;
  }

  updateNotificationCountIfNeeded(){
    electronUtil.trySetNotificationCount(this.getCurrentNotificationCount());
  }

  render(){
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    directMessageNotifyCount : state.shared.directMessageNotifyCount,
    workspaceNotifyCount : state.shared.workspaceNotifyCount
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

NotificationUpdater.propTypes = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationUpdater));
