import c from '../../../util/const'
import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import Loading from "../util/Loading";
import downloadActions from "../../../actions/download-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import log from "../../../util/log";
import _ from "lodash";
import utils from "../../../util/util";

class ImgPreview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      img: null
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {forum_id, host_uid, doc_id, doc_info, t} = this.props;

    let isHeic = utils.isHeicPreview(_.get(doc_info,'label'));

    this.setState({loading: true})
    let promise = null;
    if(isHeic){
      promise = sapi.Docs.viewImgHeic(forum_id, host_uid, doc_id, true);
    }
    else{
      promise = sapi.Docs.viewImg(forum_id, host_uid, doc_id, true);
    }
    promise
      .then((res) => {
        this.setState({img: res})
      })
      .catch((err) => {
        log.log('err viewing image', err);
        this.props.showAlert(t('Error viewing image'), t("There was a problem loading this image.  Please try again."), () => {
          this.closeModal();
        })
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  closeModal() {
    this.props.close();
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  setWindowMode(mode) {
    let {setWindowMode} = this.props;

    setWindowMode(mode);
  }

  getDownloadHeaders() {
    let {doc_id, forum_id, host_uid} = this.props;

    let params = {
      doc_id,
      forum_id
    }

    if (host_uid) {
      params.host_uid = host_uid;
    }

    params[c.api.X_TOKEN] = sapi.getToken();

    return params;
  }

  downloadDoc() {
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'))
  }

  render() {
    let {doc_info, t} = this.props;
    let {img, loading} = this.state;

    if(loading){
      return (
        <div className="modal-content">

          <div className="modal-header draggable-header">
            <h5 className="modal-title">{doc_info.label}</h5>
            <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
              <i className="icon ion-ios-close-empty" />
            </button>
          </div>
          <div className="modal-body text-center">
            <div style={{padding: '200px'}}>
              <Loading centered size={'sm'}/>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{doc_info.label}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body text-center">
          {img && <Image src={img} alt={t('Document')}/>}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-primary'} onClick={this.downloadDoc.bind(this)}>{t("Download")}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload:(headers, url) => dispatch(downloadActions.doDownload(headers, url)),
    ...modalActions.mapToDispatch(dispatch)
  };
};

ImgPreview.propTypes = {
  close: PropTypes.func.isRequired,
  setWindowMode: PropTypes.func.isRequired,
  doc_info: PropTypes.object.isRequired,
  forum_id: PropTypes.string.isRequired,
  host_uid: PropTypes.string,
  doc_id: PropTypes.string.isRequired,
  onRef : PropTypes.func,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImgPreview));
