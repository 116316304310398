import PdfSignatureRequestOverlay from "../components/partials/pdf-preview/PdfSignatureRequestOverlay";
import _ from "lodash";
import ColorGenerator from "../helpers/color-generator";

class SignatureRequest{

  static newThreadSignatureRequest(forum_id, host_uid, doc_id, chat_id, mesg_id, status){
    return new SignatureRequest(null, forum_id, host_uid, doc_id, chat_id, mesg_id, status)
  }

  static newDMSignatureRequest(dm_guest_uid, forum_id, host_uid, doc_id, mesg_id, status){
    return new SignatureRequest(dm_guest_uid, forum_id, host_uid, doc_id, null, mesg_id, status)
  }

  constructor(dm_guest_uid, forum_id, host_uid, doc_id, chat_id, mesg_id, status){
    this.dm_guest_uid = dm_guest_uid;
    this.forum_id = forum_id;
    this.host_uid = host_uid;
    this.doc_id = doc_id;
    this.chat_id = chat_id;
    this.mesg_id = mesg_id;
    this.status = status;
  }

  setUseCancelLanguage(useCancelLanguage){
    this.useCancelLanguage = useCancelLanguage;
  }

  setSigners(signerResults, guest_infos){
    this.signer_results = signerResults;
    this.guest_infos = guest_infos;
  }

  setRequestor(guest_info){
    this.sign_requestor = guest_info;
  }

  setDocInfo(doc_info){
    this.doc_info = doc_info;
  }

  setSignatureRequest(sigRequest){
    this.signature_request = sigRequest;
  }
}

SignatureRequest.convertSignaturePointToOverlayPoint = (sig, signer_uid) => {
  if(sig.scale !== 1){
    sig = _.extend({}, sig,
      {
        x : sig.x / sig.scale,
        y : sig.y / sig.scale,
        width : sig.width / sig.scale,
        height : sig.height / sig.scale,
        scale : 1
      })
  }
  let scale = sig.scale || 1;
  sig.height = sig.height / scale;
  sig.width = sig.width / scale;

  let overlay = {
    id : _.uniqueId('vf-sig-request-overlay-'),
    pageIndex : sig.pageIndex,
    coords : {
      x : sig.x, //no need to scale this, because the handle size is relative to the overlay size already.
      y : sig.y
    },
    scale : sig.scale,
    width : sig.width,
    height : sig.height,
    signatureType : sig.signatureType,
    signatureCustomLabel : sig.signatureCustomLabel || '',
    guest_uid : signer_uid,
    signaturePrimaryColor : ColorGenerator.generateColorFromId(signer_uid)
  }
  return overlay;
}

SignatureRequest.convertOverlayPointToSignaturePoint = (overlay) => {
  return {
    pageIndex : overlay.pageIndex,
    x : overlay.coords.x,
    y : overlay.coords.y,
    scale : overlay.scale,
    width : overlay.width,
    height : overlay.height,
    signatureType : overlay.signatureType,
    signatureCustomLabel : overlay.signatureCustomLabel
  }
}

SignatureRequest.isSignedDate = (signatureType) => {
  if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE){
    return true;
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_1){
    return true;
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_2){
    return true;
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_3){
    return true;
  }
  else{
    return false;
  }
}

SignatureRequest.isSignedDateUTC = (signatureType) => {
  if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_3){
    return true;
  }
  else{
    return false;
  }
}

SignatureRequest.getDateMaskForSignatureType = (signatureType) => {
  if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE){
    return "YYYY-MM-DD";
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_1){
    return "MM/DD/YYYY";
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_2){
    return "DD/MM/YYYY";
  }
  else if(signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_3){
    return "YYYY-MM-DD HH:mm:ss UTC";
  }
  else{
    return null;
  }
}

SignatureRequest.SIGNATURE_REQUEST_TYPE = {
  SIGNATURE : 'signature',
  INITIALS : 'initials',
  SIGNED_DATE : 'signed_date',
  SIGNED_DATE_1 : 'signed_date_1',
  SIGNED_DATE_2 : 'signed_date_2',
  SIGNED_DATE_3 : 'signed_date_3',
  OTHER : 'other'
}

SignatureRequest.SIGN_STATUS = {
  INCOMPLETE : 'pending request',
  NONE : 'none',
  SIGNED : 'signed',
  EXPIRED : 'expired',
  REQUESTED : 'pending signature',
  UNKNOWN : 'unknown state'
}

export default SignatureRequest;
