import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {withVFTranslation} from "../../util/withVFTranslation";
import {SEND_CODE_TYPE} from "./SMSVerificationDialog";
import c from "../../util/const";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {getMessageForError, getMessageForVFOrTwilioError} from "../../util/errors";
import Loading from "../partials/util/Loading";
import _ from "lodash";
import modalActions from "../../actions/modal-actions";
import utils from "../../util/util";
import api from "../../util/api";

class TFAInfoDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resendingCodeType : null,
      resendCodeSuccess : false,
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let { close } = this.props;

    close();
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  sendNewSmsCode(type) {
    if(this.state.resendingCodeType !== null){
      return;
    }

    let {
      t,
      tfaAuth,
      email } = this.props;
    let call_flag = type === SEND_CODE_TYPE.PHONE;

    this.setState({resendingCodeType : type});


    let sendCodePromise = null;
    let tfaToken = _.get(tfaAuth, 'token');
    if(tfaToken){
      sendCodePromise = sapi.TFA.sendCode(tfaToken, call_flag);
    }
    else{
      sendCodePromise = api.TFA.sendCode(email, call_flag);
    }

    //This promise.all is so we wait a minimum time for progress to show.
    Promise.all([
      utils.waitFor(1000),
      sendCodePromise
    ])
      .then((res) => {
        log.log('send code res', res);
        this.setState({
          resendCodeSuccess : true
        }, () => {
          //show the save success flag for a sec before we close.
          setTimeout(() => {
           this.closeModal();
          }, 2000);
        })
      })
      .catch((err) => {
        log.log('error doing send code', err);
        this.props.showAlert(t("Error resending code"), getMessageForVFOrTwilioError(err, t));
        this.setState({
          resendingCodeType : null,
          resendCodeSuccess : false
        })
      })
  }

  renderResendSMSStatus(type){
    let {
      resendingCodeType,
      resendCodeSuccess
    } = this.state;

    if(type !== resendingCodeType){
      return;
    }

    let { t } = this.props;
    return (
      <>
        {resendingCodeType && !resendCodeSuccess &&
          <span className="ml-2 secondary-text-color">
            <Loading size="sm" inline={true} className="mr-2" color="primary"/>
          </span>
        }
        {resendCodeSuccess &&
          <span className="ml-2 green-color">
            <i className="icon ion-checkmark-circled mr-2"/>
            {t("Success!")}
          </span>
        }
      </>
    )
  }

  renderTOTPSMS(){
    let { t } = this.props;
    return (
      <div>
        <p>
          {t("You enabled two-factor authentication (2FA) with an authenticator app. Look in that app to find your current code.")}
        </p>
        <p>
          {t("If you can't access that authenticator app, there are a still a few ways we can get you into your account.")}
        </p>

        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', lineHeight: '38px'}}>
              <Button className="btn btn-link"
                      onClick={() => this.sendNewSmsCode(SEND_CODE_TYPE.SMS)}>
                {t("Send a new code by SMS")}
              </Button>
              {this.renderResendSMSStatus(SEND_CODE_TYPE.SMS)}
            </li>
          </ul>
        </div>
        <div className="text-left dark-color px-2 mb-2" style={{marginLeft: '50px', fontSize: '12px'}}>
          <p className="mb-0">
            {t("Message and data rates may apply.")}
          </p>
          <p className="mb-0">
            {t("You will only receive one message per authentication request.")}
          </p>
          <p className="mb-0">
            {t("Reply HELP for help. Reply STOP to opt out.")}
          </p>
          <p className="mb-0">
            {t("SMS Terms of Service: ")}<a target={'_blank'}
                                            className="underline dark-color"
                                            href={c.links.smsTerms}>{"verifyle.com/smsterms.html"}</a>
          </p>
          <p className="mb-0">
            {t("Privacy Policy: ")}<a target={'_blank'}
                                      className="underline dark-color"
                                      href={c.links.privacy}>{"verifyle.com/privacypolicy.html"}</a>
          </p>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', lineHeight: '38px'}}>
              <Button className="btn btn-link" onClick={() => this.sendNewSmsCode(SEND_CODE_TYPE.PHONE)}>
                {t("Send a new code by voice call")}
              </Button>
              {this.renderResendSMSStatus(SEND_CODE_TYPE.PHONE)}
            </li>
          </ul>
        </div>

        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you have a copy of your original QR code, just scan that with any authenticator app as you did when you enabled 2FA.")}
              </p>
            </li>
          </ul>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you told us to trust a device, you should be able to log in with that device and then disable 2FA.")}
              </p>
            </li>
          </ul>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you are still logged into Verifyle on another device, you should be able to disable 2FA there.")}
              </p>
            </li>
          </ul>
        </div>
        <p>
          {t("If you still can't log in, contact us at ")}
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          {t(" and we will see if we can find another way to authenticate you.")}
        </p>
      </div>
    )
  }

  renderTOTP(){
    let { t } = this.props;

    return (
      <div>
        <p>
          {t("You enabled two-factor authentication (2FA) with an authenticator app. Look in that app to find your current code.")}
        </p>
        <p>
          {t("If you can't access that authenticator app, there are a still a few ways we can get you into your account.")}
        </p>

        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you have a copy of your original QR code, just scan that with any authenticator app as you did when you enabled.")}
              </p>
            </li>
          </ul>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you told us to trust a device, you should be able to log in with that device and then disable 2FA.")}
              </p>
            </li>
          </ul>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you are still logged into Verifyle on another device, you should be able to disable 2FA there.")}
              </p>
            </li>
          </ul>
        </div>

        <p>
          {t("If you still can't log in, contact us at ")}
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          {t(" and we will see if we can find another way to authenticate you.")}
        </p>
      </div>
    )
  }

  renderSMS(){
    let { t, tfaAuth } = this.props;
    let phoneLast2 = _.get(tfaAuth, 'phone_last2', '');
    return (
      <div>
        <p>
          {t("You enabled two-factor authentication (2FA) with a phone number ending in [") + phoneLast2 + t("]. Check that phone for your current code.")}
        </p>
        <p>
          {t("If you haven't received your code, you can have us resend your code by clicking below:")}
        </p>

        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', lineHeight: '38px'}}>
              <Button className="btn btn-link"
                      onClick={() => this.sendNewSmsCode(SEND_CODE_TYPE.SMS)}>
                {t("Send a new code by SMS")}
              </Button>
              {this.renderResendSMSStatus(SEND_CODE_TYPE.SMS)}
            </li>
          </ul>
        </div>
        <div className="text-left dark-color px-2 mb-2" style={{marginLeft: '50px', fontSize: '12px'}}>
          <p className="mb-0">
            {t("Message and data rates may apply.")}
          </p>
          <p className="mb-0">
            {t("You will only receive one message per authentication request.")}
          </p>
          <p className="mb-0">
            {t("Reply HELP for help. Reply STOP to opt out.")}
          </p>
          <p className="mb-0">
            {t("SMS Terms of Service: ")}<a target={'_blank'}
                                            className="underline dark-color"
                                            href={c.links.smsTerms}>{"verifyle.com/smsterms.html"}</a>
          </p>
          <p className="mb-0">
            {t("Privacy Policy: ")}<a target={'_blank'}
                                      className="underline dark-color"
                                      href={c.links.privacy}>{"verifyle.com/privacypolicy.html"}</a>
          </p>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', lineHeight: '38px'}}>
              <Button className="btn btn-link" onClick={() => this.sendNewSmsCode(SEND_CODE_TYPE.PHONE)}>
                {t("Send a new code by voice call")}
              </Button>
              {this.renderResendSMSStatus(SEND_CODE_TYPE.PHONE)}
            </li>
          </ul>
        </div>

        <p>
          {t("If you can't access that phone, there are a still a few ways we can get you into your account.")}
        </p>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you told us to trust a device, you should be able to log in with that device and then disable 2FA.")}
              </p>
            </li>
          </ul>
        </div>
        <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
          <ul className="list-group list-group-flush black-color mt-1">
            <li style={{listStyle: 'disc', paddingLeft: '12px'}} className="py-2">
              <p className="mb-0">
                {t("If you are still logged into Verifyle on another device, you should be able to disable 2FA there.")}
              </p>
            </li>
          </ul>
        </div>
        <p>
          {t("If you still can't log in, contact us at ")}
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          {t(" and we will see if we can find another way to authenticate you.")}
        </p>
      </div>
    )
  }

  renderContents(){
    let { t, tfaAuth } = this.props;
    let {
      tfa_sms_flag,
      tfa_totp_flag
    } = tfaAuth || {};

    //tfaAuth:
    //{
    //   {
    //       "phone_last2" : "56",
    //       "sid" : "89e4a801e57ece4d",
    //       "test" : {
    //          "code" : "908537"
    //       },
    //       "tfa_required" : true,
    //       "tfa_sms_flag" : true,
    //       "tfa_totp_flag" : false,
    //       "token" : "B7JJ71yFc4f7XFWF82O49_YJzi_FU4kZUOfAqpKg8xn0p4T6ayMjsg",
    //       "vip" : "dev5.vfltest.com"
    //    }
    // }

    if(tfa_totp_flag && tfa_sms_flag){
      return this.renderTOTPSMS();
    }
    else if(tfa_totp_flag){
      return this.renderTOTP();
    }
    else if(tfa_sms_flag){
      return this.renderSMS();
    }
  }

  render() {
    let { t } = this.props;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Two-Factor Authentication Help")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          {this.renderContents()}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tfaAuth: state.auth.tfaAuth,
    email: state.auth.email
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

TFAInfoDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(TFAInfoDialog));
