import DocExtensionPlaceholder from "../components/partials/elements/DocExtensionPlaceholder";
import Image from "../components/partials/elements/Image";
import React from "react";
import {ArrowContainer} from "react-tiny-popover";
import log from "../util/log";
import colors from "../util/colors";
import utils from "../util/util";
import Loading from "../components/partials/util/Loading";
import _ from "lodash";
import {SEND_CODE_TYPE} from "../components/modals/SMSVerificationDialog";
import flagIconHelper from "../util/flag-icon-helper";
import ElectronAwareLink from "../components/partials/elements/ElectronAwareLink";

const renderSMSVerificationButton = (t, sendingVerificationCodeType, sendSMSVerificationCode) => {
  let showLoading = sendingVerificationCodeType === SEND_CODE_TYPE.SMS;
  return (
    <a style={{
      flex: 1,
      borderRight: '1px solid ' + colors.LIGHT_GREY,
      borderRadius: '0px',
      width: '115px',
      paddingLeft: '0px',
      paddingRight: '5px'
    }}
       onClick={(evt) => showLoading ? _.noop : sendSMSVerificationCode(SEND_CODE_TYPE.SMS, true)}
       className={`has-pointer btn resend-code-btn`}>
      <div>
        <div style={{
          border: '1px solid ' + colors.PRIMARY,
          padding: '5px 0px',
          borderRadius: '4px',
          width: '40px',
          margin: 'auto'
        }}>
          <i style={{fontSize: '18px', lineHeight: '30px', fontStyle: 'normal'}} className="icon">SMS</i>
        </div>
        <div style={{
          fontSize: '12px',
          lineHeight: '12px',
          margin: '5px auto',
          maxWidth: '80%',
          wordBreak: 'break-word'
        }}>
          {!showLoading ?
            t("Send a new code by text") :
            <Loading inline={true} className="my-1" size={'sm'}/>
          }
        </div>
      </div>
    </a>
  )
}

const renderPhoneVerificationButton = (t, sendingVerificationCodeType, sendSMSVerificationCode) => {
  let showLoading = sendingVerificationCodeType === SEND_CODE_TYPE.PHONE;
  return (
    <a style={{
      flex: 1,
      borderLeft: '1px solid ' + colors.TRANSPARENT,
      width: '115px',
      paddingLeft: '5px',
      paddingRight: '0px'
    }}
       onClick={(evt) => showLoading ? _.noop : sendSMSVerificationCode(SEND_CODE_TYPE.PHONE, true)}
       className={`has-pointer btn resend-code-btn`}>
      <div>
        <div style={{
          border: '1px solid ' + colors.PRIMARY,
          padding: '5px 0px',
          borderRadius: '4px',
          width: '40px',
          margin: 'auto'
        }}>
          <i style={{fontSize: '28px', lineHeight: '30px'}} className="icon ion-android-call"/>
        </div>
        <div style={{
          fontSize: '12px',
          lineHeight: '12px',
          margin: '5px auto',
          wordBreak: 'break-word',
          maxWidth: '80%',
        }}>
          {!showLoading ?
            t("Send a new code by voice") :
            <Loading inline={true} className="my-1" size={'sm'}/>
          }
        </div>
      </div>
    </a>
  )
}

const renderShareLinkButton = (t, linkText, iconCls, showSuccess, onClick, showBorderRight) => {
  return (
    <a style={{
      flex: 1,
      borderRight: showBorderRight ? '1px solid ' + colors.LIGHT_GREY : 'none',
      borderRadius: '0px',
      width: '115px',
      paddingLeft: '0px',
      paddingRight: '5px'
    }}
       onClick={(evt) => showSuccess ? _.noop : onClick(evt)}
       className={`has-pointer btn resend-code-btn`}>
      <div>
        <div style={{
          border: '1px solid ' + colors.PRIMARY,
          padding: '5px 0px',
          borderRadius: '4px',
          width: '40px',
          margin: 'auto'
        }}>
          <i style={{fontSize: '18px', lineHeight: '30px', fontStyle: 'normal'}} className={`icon ${iconCls}`} />
        </div>
        {!showSuccess &&
          <div style={{
            fontSize: '12px',
            lineHeight: '12px',
            margin: '5px auto',
            maxWidth: '80%',
            wordBreak: 'break-word',
            height: '36px'
          }}>
            {linkText}
          </div>
        }
        {showSuccess &&
          <div style={{
            fontSize: '12px',
            lineHeight: '12px',
            margin: '5px auto',
            maxWidth: '80%',
            wordBreak: 'break-word',
            color: colors.GREEN,
            height: '36px'
          }}>
            {t("Copied!")}
          </div>
        }
      </div>
    </a>
  )
}

const popupHelper = {

  getCopiedContents(popupText, onClick){
    let content =
      <div>
        <ul className="popover-content doc-preview-popover list-group no-pointer">
          <div className="text-center small p-2 light-color" onClick={() => onClick()}>
            {popupText}
          </div>
        </ul>
      </div>

    return content;
  },

  getDidYouMeanContents(t, didYouMeanSuggestion, popupArgs, useItClick, loseItClick){
    let content = (
      <div className="no-pointer">
        <ul className="popover-content doc-preview-popover list-group no-pointer">
          <div className="text-center small px-3 pt-3 light-color">
            <span>{`${t("Did you mean")}`} </span>
            <span className="font-weight-bold">{didYouMeanSuggestion}</span>
            <span>{t("?")}</span>
          </div>
          <div className="text-center px-3 pb-3 pt-1">
            <a onClick={() => loseItClick()}
               className="primary-color has-pointer"
               style={{pointerEvents: 'all'}}>
              {t("No")}
            </a>
            <a onClick={() => useItClick()}
               className="primary-color has-pointer ml-4"
               style={{pointerEvents: 'all'}}>
              {t("Yes")}
            </a>
          </div>
        </ul>
      </div>
    )

    let {position, childRect, popoverRect} = popupArgs;

    let wrapper = <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={colors.DARK}
      arrowSize={13}
    >
      {content}
    </ArrowContainer>

    return wrapper;
  },

  getCurrencyMenuPopoverContents(popupArgs, currencyCodes, onSelectCurrency){
    let {
      position,
      childRect,
      popoverRect,
    } = popupArgs || {};

    let content =
      <div>
        <ul className="popover-content flag-popover">
          <div className="row">
            {_.map(currencyCodes, (code, i) => {
              return (
                <div key={code} className="col text-center">
                  <a style={{
                       paddingBottom: '5px',
                       borderTopColor : colors.TRANSPARENT,
                       borderBottomColor : colors.TRANSPARENT,
                       width: '4rem'
                     }}
                     onClick={() => onSelectCurrency(code)}
                     className="d-inline-block has-pointer">
                    {flagIconHelper.getFlagForCurrencyCode(code)}
                    <div className="text-center small secondary-text-color">
                      {code}
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </ul>
      </div>

    let wrapper = content;
    if(popupArgs){
      // log.log('popup args here', popupArgs);
      wrapper = <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
        position={position}
        childRect={childRect}
        popoverRect={popoverRect}
        arrowColor={colors.LIGHT}
        arrowSize={13}
      >
        {content}
      </ArrowContainer>
    }

    return wrapper;
  },

  getResendCodePopoverContents(popupArgs, data, controls){
    let {
      position,
      childRect,
      popoverRect,
    } = popupArgs || {};

    let {
      sendingVerificationCodeType
    } = data;
    let {
      sendSMSVerificationCode,
      t
    } = controls;

    let content =
      <div>
        <ul className="resend-code-popover-content list-group">
          <div className="text-center d-flex flex-row list-group-item resend-code-item"
               style={{
                 padding: '5px',
               }}>
            {renderSMSVerificationButton(t, sendingVerificationCodeType, sendSMSVerificationCode)}
            {renderPhoneVerificationButton(t, sendingVerificationCodeType, sendSMSVerificationCode)}
          </div>
        </ul>
      </div>

    let wrapper = content;
    if(popupArgs){
      // log.log('popup args here', popupArgs);
      wrapper = <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
        position={position}
        childRect={childRect}
        popoverRect={popoverRect}
        arrowColor={colors.LIGHT}
        arrowSize={13}
      >
        {content}
      </ArrowContainer>
    }

    return wrapper;
  },

  getHandleShareLinkPopoverContents(popupArgs, data, controls){
    let {
      position,
      childRect,
      popoverRect,
    } = popupArgs || {};

    let {
      copyLinkSuccess,
      copyHTMLSuccess
    } = data;

    let {
      copyLinkClick,
      copyHTMLLinkLick,
      t
    } = controls;

    let content =
      <div>
        <ul className="resend-code-popover-content list-group">
          <div className="text-center d-flex flex-row list-group-item resend-code-item"
               style={{
                 padding: '5px',
               }}>
            {renderShareLinkButton(t, t("Copy Link"), 'ion-android-share-alt', copyLinkSuccess, (evt) => copyLinkClick(evt), true)}
            {renderShareLinkButton(t, t("Copy HTML for Your Website"), 'ion-code', copyHTMLSuccess, (evt) => copyHTMLLinkLick(evt))}
          </div>
        </ul>
      </div>

    let wrapper = content;
    if(popupArgs){
      // log.log('popup args here', popupArgs);
      wrapper = <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
        position={position}
        childRect={childRect}
        popoverRect={popoverRect}
        arrowColor={colors.LIGHT}
        arrowSize={13}
      >
        {content}
      </ArrowContainer>
    }

    return wrapper;
  },

  getThumbnailPreviewContent(popupArgs, data, controls){
    let {
      position,
      childRect,
      popoverRect,
    } = popupArgs || {};

    let {
      label,
      thumbnail,
      isLoadingThumbnail,
      nextUserToSignIsYou
    } = data;

    let {
      hideMenu,
      onPreviewClick,
      onDownloadClick,
      fulfillSignatureRequestClick,
      t
    } = controls;

    let isPreviewable = utils.isPreviewable(label);

    let popoverWrapStyle = {
      height: '300px',
      width: '300px',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'hidden'
    }
    let imgStyle = {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: '100%',
      maxWidth: '100%'
    }
    let content =
      <div>
        <ul className="popover-content doc-preview-popover list-group">
          <div className="dark-bg text-center image-section list-group-item list-group-item-action"
               style={{padding: '10px 10px 0px 10px', display: 'flex'}}>
            {isLoadingThumbnail &&
              <>
                <div className="d-inline-block light-bg"
                     style={popoverWrapStyle}>
                  <div style={{marginTop: '70px', height: '90px'}}>
                    <Loading centered size={'sm'}/>
                  </div>
                </div>
              </>
            }
            {!isLoadingThumbnail &&
              <>
                {!thumbnail &&
                  <div className="d-inline-block light-bg"
                       style={popoverWrapStyle}>
                    <div style={{marginTop: '50px', height: '90px'}}>
                      <DocExtensionPlaceholder size="lg"
                                               filename={label}/>
                    </div>
                  </div>
                }
                {thumbnail &&
                  <div className="d-inline-block"
                       style={popoverWrapStyle}>
                    <Image src={thumbnail}
                           style={imgStyle}
                           className={"no-pointer"}
                           imgHeight={'auto'}
                           imgWidth={'auto'}
                           alt={t('Thumbnail')}/>
                  </div>
                }
              </>
            }
          </div>

          {nextUserToSignIsYou &&
              <div className="dark-bg text-center d-flex flex-row list-group-item list-group-item-action"
                   style={{padding: '10px'}}>
                <a style={{flex: 1}}
                   title={t("Click Here to Sign")}
                   onClick={(evt) => fulfillSignatureRequestClick(evt)}
                   className="btn btn-sm btn-signing mr-1">
                  <div>
                    <div style={{fontSize: '14px', display: 'inline-block', paddingLeft: '10px', lineHeight: '30px'}}>
                      {t("Click Here to Sign")}
                    </div>
                  </div>
                </a>
              </div>
          }

          {!nextUserToSignIsYou &&
          <div className="dark-bg text-center d-flex flex-row list-group-item list-group-item-action"
               style={{padding: '10px'}}>
            {isPreviewable &&
              <a style={{flex: 1}}
                 title={t("Preview") + ` ${label}`}
                 onClick={(evt) => onPreviewClick(evt)}
                 className="has-pointer btn btn-outline-light mr-1">
                <div>
                  <i style={{fontSize: '28px', lineHeight: '30px'}} className="icon ion-eye"/>
                  <div style={{fontSize: '14px', display:'inline-block', paddingLeft: '10px', lineHeight: '30px'}}>
                    {t("Preview")}
                  </div>
                </div>
              </a>
            }
            <a style={{flex: 1}}
               title={t("Download") + ` ${label}`}
               onClick={(evt) => onDownloadClick(evt)}
               className={`has-pointer btn btn-outline-light ${isPreviewable ? 'ml-1' : ''}`}>
              <div>
                <i style={{fontSize: '28px', lineHeight: '30px'}} className="icon ion-android-download"/>
                <div style={{fontSize: '14px', display:'inline-block', paddingLeft: '10px', lineHeight: '30px'}}>
                  {t("Download")}
                </div>
              </div>
            </a>
          </div>
          }
        </ul>
      </div>

    let wrapper = content;
    if(popupArgs){
      // log.log('popup args here', popupArgs);
      wrapper = <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
        position={position}
        childRect={childRect}
        popoverRect={popoverRect}
        arrowColor={colors.DARK}
        arrowSize={13}
      >
        {content}
      </ArrowContainer>
    }

    return wrapper;
  }
}

export default popupHelper;
