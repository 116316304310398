import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import log from "../../util/log";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import {getErrorMessage, getMessageForError} from "../../util/errors";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import _ from 'lodash'
import he from "he";
import sentryHelper from "../../helpers/sentry-helper";
import {withVFTranslation} from "../../util/withVFTranslation";
import {buildFontOption, buildFontPicker, fontConstants} from "../../util/font-constants";
import {PDFDocument} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import PdfWritingSvc from "../../helpers/pdf-writing-svc";
import Promise from "bluebird";
import PdfFontLoader from "../../helpers/pdf-font-loader";

class CustomInputReviewDialog extends Component {

  dummyPdf = null;
  fontLoader = null;

  constructor(props) {
    super(props);

    this.state = {
      originalText : props.text,
      editText : props.text,
      previewText : props.text,
      selectedFont : fontConstants.HELVETICA.familyName, //default font is helvetica
      selectedFontCls : null,
      selectedFontHandle : null,
      validationErr : []
    }
  }

  componentDidMount() {
    const {modalProps} = this.props;
    if(!modalProps.callback){
      throw Error('Showing modal without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }

    log.log('custom input review shown', this.props);

    this.fontLoader = new PdfFontLoader();
    this.fontLoader.loadFonts();
    this.updateStateOnFontChange();
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  updateStateOnFontChange(){
    return new Promise((resolve, reject) => {
      this.resetDummyPdfWithPickerFont()
          .then(() => {
            this.setState({
              previewText : this.generatePreviewText(this.state.editText)
            }, () => {
              resolve(true);
            })
          })
    })
  }

  resetDummyPdfWithPickerFont(){
    return new Promise((resolve, reject) => {
      return PDFDocument.create()
          .then((res) => {
            this.dummyPdf = res;
            this.dummyPdf.registerFontkit(fontkit);
            return this.loadPickerFontIntoDummyPdf();
          })
          .then((fontHandle) => {
            this.setState({
              selectedFontHandle : fontHandle
            }, () => {
              resolve(true);
            })
          })
          .catch((err) => {
            reject(err);
          })
    })
  }

  loadPickerFontIntoDummyPdf(){
    let {
      selectedFont
    } = this.state;
    if(PdfWritingSvc.isStandardFont(selectedFont)){
      return Promise.resolve(this.dummyPdf.embedStandardFont(selectedFont));
    }
    else{
      return this.dummyPdf.embedFont(this.fontLoader.getFontData(selectedFont))
    }
  }

  destroyDummyPdf(){
    if(this.dummyPdf){
      this.dummyPdf = null;
    }
  }

  testTextEncodeAgainstLoadedPDF(text){
    return this.props.pdfState.pdfWriter.doesTextIncludeSpecialCharacters(text);
  }

  testTextEncodeAgainstDummyPDF(text){
    return CustomInputReviewDialog.testEncodeText(this.dummyPdf, text, this.state.selectedFontHandle);
  }

  static testEncodeText(pdfWriter, text, fontObj){
    try {
      let encoded = fontObj.encodeText(text);
    }
    catch(err){
      return false;
    }

    return true;
  }

  generatePreviewText(input){
    //algorithm here is to test each char to find out if it's invalid, strip it if it is
    //beware of performance problems, exceptions are bad for conrolling flow

    console.time('generate-preview-text');
    let resultText = '';
    _.each(input, (c) => {
      if(this.testTextEncodeAgainstDummyPDF(c)){
        resultText += c;
      }
    })
    console.timeEnd('generate-preview-text');
    return resultText;
  }

  getFontOptionPicker() {
    let {t} = this.props;

    return buildFontPicker([
      buildFontOption(t('Helvetica'), fontConstants.HELVETICA),
      buildFontOption(t('Arabic'), fontConstants.CAIRO),
      buildFontOption(t('Japanese'), fontConstants.NOTO_SANS_JP),
      buildFontOption(t('Chinese (Simplified)'), fontConstants.NOTO_SANS_SC),
      buildFontOption(t('Korean'), fontConstants.NOTO_SANS_KR),
      buildFontOption(t('Cyrillic'), fontConstants.ROBOTO_CYRILLIC),
    ])
  }

  confirmText() {

    if(!this.doValidation()){
      return;
    }

    //heads up, we actually return preview text here, so that we
    //know it's sanitized.
    let {
      previewText,
      selectedFont,
      selectedFontCls,
    } = this.state;
    let {close} = this.props;
    close({
      text: previewText,
      font: selectedFont,
      fontCls : selectedFontCls
    });
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  doValidation(){
    let err = [];
    let { t } = this.props;
    let {
      editText
    } = this.state;

    if (!editText || editText.length === 0) {
      err.push(t("Please enter some text"));
    }

    this.setState({validationErr : err});
    return err.length === 0;
  }

  onEditTextChanged(evt){
    //log.log('onEditTextChanged', evt.target.value);
    this.setState({
      editText : evt.target.value,
      previewText : this.generatePreviewText(evt.target.value)
    })
  }

  onFontChanged(evt){
    let selectedValue = _.get(evt, 'target.value');
    let opt = _.find(this.getFontOptionPicker(), (opt) => opt.val === selectedValue);
    log.log('on font change', selectedValue, opt);
    this.setState({
      selectedFont : selectedValue,
      selectedFontCls : opt.cls
    }, () => {
      this.updateStateOnFontChange();
    })
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {t} = this.props;
    let {
      previewText,
      editText,
      selectedFont,
      selectedFontCls,
      validationErr
    } = this.state;

    let showSuccessfulEncode = editText.length > 0 && editText.length === previewText.length;

    return (
        <div className="modal-content">
          <div className="modal-header draggable-header">
            <h5 className="modal-title">{t("Review Input")}</h5>
            <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
              <i className="icon ion-ios-close-empty"/>
            </button>
          </div>
          <div className="modal-body">
            <p>{t("It looks like our default font doesn't support your input. You can try editing your input to resolve the issue.")}</p>
            <div className="d-flex mb-3">
              <div className="text-right mr-2"
                   style={{width: '200px', lineHeight: '38px'}}>
                Your Input:
              </div>
              <input type="text"
                     inputMode="text"
                     autoComplete="none"
                     onChange={this.onEditTextChanged.bind(this)}
                     value={editText}
                     placeholder={t("Enter input text")}
                     className="form-control"
                     aria-label=""/>
            </div>
            {/*<div className="d-flex mb-3">*/}
            {/*  <div className="text-right mr-2"*/}
            {/*       style={{width: '200px', lineHeight: '38px'}}>*/}
            {/*    Font:*/}
            {/*  </div>*/}
            {/*  <select className="form-control d-table-cell"*/}
            {/*          value={selectedFont}*/}
            {/*          onChange={this.onFontChanged.bind(this)}>*/}
            {/*    {this.getFontOptionPicker().map((option) => {*/}
            {/*      return <option className={option.cls}*/}
            {/*                     key={option.val}*/}
            {/*                     value={option.val}>*/}
            {/*        {option.display}*/}
            {/*      </option>*/}
            {/*    })}*/}
            {/*  </select>*/}
            {/*</div>*/}

            {/*<p className="mb-3">{t("You can see below how your input would show up on the document with the selected font.")}</p>*/}

            <div className="d-flex mb-3">
              <div className="text-right mr-2"
                   style={{width: '200px', lineHeight: '38px'}}>
                <i className={`icon align-baseline pr-2 ${showSuccessfulEncode ? 'text-success ion-android-checkmark-circle' : 'text-warning ion-android-warning'}`} />
                Result:
              </div>
              <input type="text"
                     readOnly={true}
                     value={previewText}
                     className={`form-control ${selectedFontCls} ${showSuccessfulEncode ? 'form-control-success' : 'form-control-warning'}`}
                     aria-label=""/>
            </div>

            {validationErr.length > 0 && <ValidationErrors errors={validationErr}/>}
          </div>
          <div className="modal-footer">
            <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
            <Button className={'btn btn-primary'} onClick={this.confirmText.bind(this)}>{t("Confirm")}</Button>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

CustomInputReviewDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomInputReviewDialog));
