import c from '../util/const';
import sapi from "../util/sapi";

import sharedActions from './shared-actions';

import Promise from 'bluebird';
import log from "../util/log";
import _ from "lodash";
import DocPermissionsHelper from "../helpers/doc-permissions-helper";
import msgHelper from "../helpers/msg-helper";
import utils from "../util/util";

const threadActions = {

  initThread(thread){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let activeThread = _.get(getState(), 'thread.activeThread');
        if(activeThread && thread && activeThread.chat_id === thread.chat_id){
          log.log('initThread called unnecessarily');
        }
        else{
          log.log('thread-action initThread', thread);
          dispatch({
            type: c.actions.thread.setActiveDM,
            dm: null
          })
          dispatch({
            type: c.actions.thread.setActiveThread,
            thread
          })
          dispatch({
            type: c.actions.thread.updateThreadMessages,
            mesg_edit_flag: false,
            messages: null,
            messageBlocks: null,
            messageBlocksId : null,
            data_date: null,
            docs: null
          })
          dispatch({
            type: c.actions.thread.updateActiveDMMessages,
            messages: null,
            messageBlocks: null,
            messageBlocksId : null,
            data_date: null,
            docs: null
          })
        }

        dispatch(this.refreshMessagesThread())
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  initDM(dm){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let activeDM = _.get(getState(), 'thread.activeDM');
        if(activeDM && dm && activeDM.guest_uid === dm.guest_uid){
          log.log('initDM called unnecessarily');
        }
        else{
          log.log('thread-action initDM', dm);
          dispatch({
            type: c.actions.thread.setActiveThread,
            thread : null
          })
          dispatch({
            type: c.actions.thread.setActiveDM,
            dm
          })
          dispatch({
            type: c.actions.thread.updateThreadMessages,
            mesg_edit_flag: false,
            messages: null,
            messageBlocks: null,
            messageBlocksId : null,
            data_date: null,
            docs: null
          })
          dispatch({
            type: c.actions.thread.updateActiveDMMessages,
            messages: null,
            messageBlocks: null,
            messageBlocksId : null,
            data_date: null,
            docs: null
          })
        }

        return Promise.all([[
          dispatch(this.refreshMessagesDM()),
          dispatch(this.refreshDocsDM())
        ]])
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  clearActiveThread(){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.thread.setActiveThread,
        thread : null
      })
      dispatch({
        type: c.actions.thread.updateThreadMessages,
        mesg_edit_flag: false,
        messages: null,
        messageBlocks: null,
        messageBlocksId : null,
        data_date: null,
        docs: null
      })
    }
  },

  clearActiveDM(){
    return (dispatch) => {
      dispatch({
        type: c.actions.thread.setActiveDM,
        dm : null
      })
      dispatch({
        type: c.actions.thread.updateActiveDMMessages,
        messages: [],
        messageBlocks: [],
        messageBlocksId : null,
        data_date: null,
        docs: []
      })
    }
  },

  refreshActiveThreadMessages(useDataDate = false){
    return (dispatch, getState) => {
      log.log('thread-action refreshActiveThreadMessages', useDataDate);
      let activeThread = _.get(getState(), 'thread.activeThread');
      let activeDM = _.get(getState(), 'thread.activeDM');
      if(activeThread){
        return dispatch(this.refreshMessagesThread(useDataDate));
      }
      else if(activeDM){
        return dispatch(this.refreshMessagesDM(useDataDate));
      }
      else{
        log.warn("refresh messages called, nothing active");
        return Promise.resolve(false);
      }
    }
  },

  refreshPendingMessages(){
    return (dispatch, getState) => {
      let activeThread = _.get(getState(), 'thread.activeThread');
      let activeDM = _.get(getState(), 'thread.activeDM');
      let {accountInfo, threadTransactionQueue} = getState().shared;

      if(activeThread){
        let {activeThreadMessages} = getState().thread;
        let pendingMsgs = msgHelper.buildPendingMessageList(activeThread.chat_id, accountInfo, threadTransactionQueue);
        let messageBlocks =  msgHelper.getMessageBlocks(_.concat(pendingMsgs, activeThreadMessages));
        dispatch({
          type: c.actions.thread.updateThreadMessages,
          messageBlocks: messageBlocks,
        })
      }
      else if(activeDM){
        let {activeDMMessages} = getState().thread;
        let pendingMsgs = msgHelper.buildPendingMessageList(activeDM.guest_uid, accountInfo, threadTransactionQueue);
        //log.log('refreshing pending messages', pendingMsgs, activeDMMessages);
        let messageBlocks =  msgHelper.getMessageBlocks(_.concat(pendingMsgs, activeDMMessages));
        dispatch({
          type: c.actions.thread.updateActiveDMMessages,
          messageBlocks: messageBlocks,
        })
      }
      else{
        log.warn("refresh messages called, nothing active");
      }
    }
  },

  filterPendingResultWithServerData(messagesFromServer, pendingMessages){
    // log.log('thread-action filterPendingResultWithServerData', messagesFromServer, pendingMessages);
    let pendingMessageResults = [];
    _.each(pendingMessages, (pendingMessage) => {
      if (pendingMessage.isCompleteTransaction) {
        let foundMesgId = _.find(messagesFromServer, (msg) => msg.mesg_id === pendingMessage.mesg_id);

        //If we found the completed message in the response, then we're done, this message has been fully committed.
        if(!foundMesgId) {
          pendingMessageResults.push(pendingMessage);
        }
      }
      else{
        pendingMessageResults.push(pendingMessage);
      }
    })
    // log.log('thread-action  filterPendingResultWithServerData', pendingMessageResults, messagesFromServer, pendingMessages)
    return pendingMessageResults;
  },

  clearFinishedTransactions(messagesFromServer, threadTransactionQueue){
    return (dispatch, getState) => {
      let finishedTransactionIds = [];
      _.each(threadTransactionQueue, (txn) => {
        if (txn.isComplete) {
          let foundMesgId = _.find(messagesFromServer, (msg) => msg.mesg_id === txn.mesg_id);
          //If we found the completed message in the response, then we're done, this message has been fully committed.
          if(foundMesgId) {
            finishedTransactionIds.push(txn.transaction_id);
          }
        }
      })

      _.each(finishedTransactionIds, (txn_id) => {
        dispatch(sharedActions.clearTransaction(txn_id));
      })
    }
  },

  refreshMessagesDM(useDataDate){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        let activeDM = _.get(getState(), 'thread.activeDM');
        if(!activeDM){
          log.warn("refreshMessagesDM called when dm not active");
          resolve(true);
          return;
        }

        let data_date = null;
        if(useDataDate){
          data_date = _.get(getState(), 'thread.activeDMDataDate');
        }

        sapi.DM.messages(activeDM.guest_uid, data_date)
          .then((res) => {
            let {accountInfo, threadTransactionQueue} = getState().shared;
            let pendingMsgs = msgHelper.buildPendingMessageList(activeDM.guest_uid, accountInfo, threadTransactionQueue);

            if (data_date) {
              let foundIds = [];
              let newMsgs = _.get(res, 'data.mesg_data', []);
              let existingMsgs = _.get(getState(), 'thread.activeDMMessages', []);
              let combinedMsgs = [];
              _.each(existingMsgs, (existingMsg) => {
                let found = _.find(newMsgs, (newMsg) =>  {
                  return newMsg.mesg_id === existingMsg.mesg_id
                })
                if (found) {
                  foundIds.push(found.mesg_id);
                  combinedMsgs.push(found);
                }
                else {
                  combinedMsgs.push(existingMsg);
                }
              })

              //Add all the new messages that we haven't added yet.
              _.each(newMsgs, (msg) => {
                if(foundIds.indexOf(msg.mesg_id) < 0){
                  combinedMsgs.push(msg);
                }
              })

              let msgs = msgHelper.formatMessageSigningDataForLegacyIfNeeded(combinedMsgs);
              pendingMsgs = this.filterPendingResultWithServerData(msgs, pendingMsgs);
              let messageBlocks = msgHelper.getMessageBlocks(_.concat(pendingMsgs, combinedMsgs));
              dispatch({
                type: c.actions.thread.updateActiveDMMessages,
                messages: msgs,
                messageBlocks: messageBlocks,
                messageBlocksId : activeDM.guest_uid,
                data_date: res.data.data_date,
              })
              dispatch(this.clearFinishedTransactions(msgs, threadTransactionQueue))
            }
            else {
              let msgs = msgHelper.formatMessageSigningDataForLegacyIfNeeded(_.get(res, 'data.mesg_data', []))
              pendingMsgs = this.filterPendingResultWithServerData(msgs, pendingMsgs);
              let messageBlocks =  msgHelper.getMessageBlocks(_.concat(pendingMsgs, msgs));
              dispatch({
                type: c.actions.thread.updateActiveDMMessages,
                messages: msgs,
                messageBlocks: messageBlocks,
                messageBlocksId : activeDM.guest_uid,
                data_date: res.data.data_date,
              })
              dispatch(this.clearFinishedTransactions(msgs, threadTransactionQueue))
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  updateThreadReadlog(forum_id, host_uid, chat_id){
    return (dispatch, getState) => {
      sapi.Threads.updateReadlog(forum_id, host_uid, chat_id)
        .catch((err) => {
          log.log('error updating readlog', err);
        })
    }
  },

  refreshMessagesThread(useDataDate){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let activeWorkspace = _.get(getState(), 'workspace.workspace');
        let activeThread = _.get(getState(), 'thread.activeThread');

        if(!activeWorkspace){
          log.warn("refreshMessagesDM called when ws not active");
          resolve(true);
          return;
        }

        if(!activeThread){
          log.warn("refreshMessagesDM called when thread not active");
          resolve(true);
          return;
        }

        let data_date = null;
        if(useDataDate){
          data_date = _.get(getState(), 'thread.activeThreadDataDate');
        }

        Promise.all([
          sapi.Threads.messages(activeWorkspace.forum_id, activeWorkspace.host_uid, activeThread.chat_id, data_date, true)
        ])
          .then((res) => {
            let threadDocs = _.get(getState(), 'workspace.docs', []);
            let foundChatBlock = _.find(threadDocs, (chatBlock) => chatBlock.chat_id === activeThread.chat_id);
            let foundChatDocs = (foundChatBlock && foundChatBlock.docs) ? foundChatBlock.docs : [];

            let {accountInfo, threadTransactionQueue} = getState().shared;
            let pendingMsgs = msgHelper.buildPendingMessageList(activeThread.chat_id, accountInfo, threadTransactionQueue);

            if (data_date) {
              let foundIds = [];
              let newMsgs = _.get(res[0], 'data.mesg_data', [])
              if(newMsgs.length > 0){
                //If we passed data_date, and got something new, then we need to mark the readlog
                let threadForumId = _.get(activeWorkspace, 'forum_id');
                let threadHostuid = _.get(activeWorkspace, 'host_uid');
                let threadChatId = _.get(activeThread, 'chat_id');
                dispatch(this.updateThreadReadlog(threadForumId, threadHostuid, threadChatId));
              }

              let existingMsgs = _.get(getState(), 'thread.activeThreadMessages', []);
              let combinedMsgs = [];
              _.each(existingMsgs, (existingMsg) => {
                let found = _.find(newMsgs, (newMsg) => {
                  return newMsg.mesg_id === existingMsg.mesg_id
                })
                if (found) {
                  foundIds.push(found.mesg_id);
                  combinedMsgs.push(found);
                }
                else {
                  combinedMsgs.push(existingMsg);
                }
              })

              //Add all the new messages that we haven't added yet.
              _.each(newMsgs, (msg) => {
                if (foundIds.indexOf(msg.mesg_id) < 0) {
                  combinedMsgs.push(msg);
                }
              })

              let msgs = msgHelper.formatMessageSigningDataForLegacyIfNeeded(combinedMsgs)
              pendingMsgs = this.filterPendingResultWithServerData(msgs, pendingMsgs);
              let messageBlocks = msgHelper.getMessageBlocks(_.concat(pendingMsgs, combinedMsgs));
              dispatch({
                type: c.actions.thread.updateThreadMessages,
                mesg_edit_flag: res[0].data.mesg_edit_flag,
                messages: msgs,
                messageBlocks: messageBlocks,
                messageBlocksId: activeThread.chat_id,
                data_date: res[0].data.data_date,
                docs: foundChatDocs
              })
              dispatch(this.clearFinishedTransactions(msgs, threadTransactionQueue))
            }
            else {

              //no need to wait for the readlog to update.  Just fire it off.
              let threadForumId = _.get(activeWorkspace, 'forum_id');
              let threadHostuid = _.get(activeWorkspace, 'host_uid');
              let threadChatId = _.get(activeThread, 'chat_id');
              dispatch(this.updateThreadReadlog(threadForumId, threadHostuid, threadChatId));

              let msgs = msgHelper.formatMessageSigningDataForLegacyIfNeeded(_.get(res[0], 'data.mesg_data', []));
              pendingMsgs = this.filterPendingResultWithServerData(msgs, pendingMsgs);
              let messageBlocks = msgHelper.getMessageBlocks(_.concat(pendingMsgs, msgs));
              dispatch({
                type: c.actions.thread.updateThreadMessages,
                mesg_edit_flag: res[0].data.mesg_edit_flag,
                guest_data : _.get(res[0], 'data.guest_data', {}),
                messages: msgs,
                messageBlocks: messageBlocks,
                messageBlocksId: activeThread.chat_id,
                data_date: res[0].data.data_date,
                docs: foundChatDocs
              })
              dispatch(this.clearFinishedTransactions(msgs, threadTransactionQueue))
            }

            resolve(res[0]);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  refreshDocsDM(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        log.log('thread-action refreshDocsDM');
        let activeDM = _.get(getState(), 'thread.activeDM');
        if(!activeDM){
          log.warn("refreshDocsDM called when dm not active");
          resolve(true);
          return;
        }

        sapi.DM.docList(activeDM.guest_uid)
          .then((res) => {
            let docs = [];
            _.each(res.data, (block) => {
              _.each(block.docs, (doc) => {
                doc.$chatId = block.chat_id;
                docs.push(doc);
              })
            })
            dispatch({
              type: c.actions.thread.updateActiveDMMessages,
              docs: docs ? docs : []
            })
            resolve(docs);
          })
          .catch((err) => {
            log.log('refresh dm doc list err', err);
            reject(err);
          })
      })
    }
  },

  cleanup(){
    return {
      type: c.actions.thread.cleanup
    }
  },

  mapToDispatch(dispatch) {
    return {
      initThread : (thread) => dispatch(this.initThread(thread)),
      initDM : (dm) => dispatch(this.initDM(dm)),
      clearActiveThread : () => dispatch(this.clearActiveThread()),
      clearActiveDM : () => dispatch(this.clearActiveDM()),
      refreshActiveThreadMessages : (useDataDate) => dispatch(this.refreshActiveThreadMessages(useDataDate)),
      refreshDocsDM : () => dispatch(this.refreshDocsDM()),
      refreshPendingMessages : () => dispatch(this.refreshPendingMessages()),
      cleanup : () => dispatch(this.cleanup())
    }
  }
}

export default threadActions;
