import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import _ from "lodash";
import c from "../../util/const";
import Loading from "../partials/util/Loading";

class ResendConfirmationEmailDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSendingCode : false,
      sendCodeSuccess : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    this.props.close(res);
  }

  resendEmailClick(){
    let { t } = this.props;
    this.setState({isSendingCode : true})
    sapi.AccountEmail.addEmail(this.props.confirmationEmail)
      .then((res) => {
        log.log('add email res', res);
        this.setState({
          isSendingCode : false,
          sendCodeSuccess : true,
        })
        setTimeout(() => {
          this.closeModal(true);
        }, 2000)
      })
      .catch((err) => {
        log.log('error adding email', err);
        this.setState({validation_errors : getMessageForError(err, t)})
        this.setState({
          isSendingCode : false,
          sendCodeSuccess : false,
        })
      })
  }

  contactUsClick(){
    this.props.showContactUsWindow()
  }

  renderResendCode(){
    let { t } = this.props;
    let {
      isSendingCode,
      sendCodeSuccess
    } = this.state;

    if(isSendingCode){
      return (
        <div className="text-center">
          <Loading inline={true}
                   size={'sm'}/>
        </div>
      )
    }
    else if(sendCodeSuccess){
      return (
        <div className="text-center">
          <button className="btn btn-link green-color"
                  disabled={true}>
            <i className="icon ion-android-checkmark-circle mr-2"/>
            {t("Sent Successfully")}
          </button>
        </div>
      )
    }
    else{
      return (
        <div className="text-center">
          <button className="btn btn-link"
                  onClick={this.resendEmailClick.bind(this)}>
            {t("Send another email")}
          </button>
        </div>
      )
    }
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t} = this.props;

    let itemStyle = {
      listStyle: 'disc'
    }
    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Confirmation Email")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, null)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>

              <div className="text-left">
                <p>
                  {t("You should have received an email at the new email account that you just entered.  If you aren't seeing it:")}
                </p>
              </div>

              <div className="text-left px-2" style={{marginLeft: '40px'}}>
                <ul className="list-group list-group-flush black-color my-1">
                  <li style={itemStyle} className="py-1">
                    {t("Double check the email address for typos.")}
                  </li>
                  <li style={itemStyle} className="py-1">
                    {t("Try waiting a few minutes and check your email again.")}
                  </li>
                  <li style={itemStyle} className="py-1">
                    {t("Check ALL folders, including any junk or spam folders.")}
                  </li>
                  <li style={itemStyle} className="py-1">
                    {t("Search for \"verifyle\" using the search function in the web interface for your email provider.")}
                  </li>
                  <li style={itemStyle} className="py-1">
                    {t("Add donotreply@verifyle.com or the verifyle.com domain to your email account whitelist and then use the link below to retry.")}
                  </li>
                  <li style={itemStyle} className="py-1">
                    <a onClick={this.contactUsClick.bind(this)}
                       className="hover-underline primary-color has-pointer">{t("Contact us")}</a> {t("if you're still having trouble.")}
                  </li>
                </ul>
              </div>

              {this.renderResendCode()}

            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, null)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

ResendConfirmationEmailDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps: PropTypes.object.isRequired,
  confirmationEmail : PropTypes.string.isRequired
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationEmailDialog)));
