import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import { getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import moment from 'moment'
import Loading from "../partials/util/Loading";
import filters from '../../helpers/filters'
import companyLogo from "../../resources/logo-w-text-blue.png";
import Image from "../partials/elements/Image";
import ReactToPrint from "react-to-print";
import _ from 'lodash';
import browser from "../../util/browser";
import {withVFTranslation} from "../../util/withVFTranslation";

class SigningMetadataWindow extends Component {

  constructor(props) {
    super(props);

    this.bodyRef = React.createRef();

    //{
    //    "data" : {
    //       "chat_id" : "61941548330299eb",
    //       "doc_id" : "619811d6e2c6c644",
    //       "forum_id" : "619415402484f9a8",
    //       "owner_uid" : "61818238377157bb",
    //       "request_date" : 1637355778,
    //       "sign_request_id" : "61980dec0b96287a",
    //       "signer_data" : [
    //          {
    //             "doc_annotate_id" : "61981102f04db7a2",
    //             "email_address" : "swartzk.junk@gmail.com",
    //             "first_name" : "Kevin",
    //             "ip_address" : "174.204.193.213",
    //             "last_name" : "Junk",
    //             "phone" : null,
    //             "sign_data" : {
    //                "signatures" : [
    //                   {
    //                      "height" : 46,
    //                      "pageIndex" : 0,
    //                      "scale" : 1,
    //                      "signatureType" : "signed_date_3",
    //                      "width" : 269,
    //                      "x" : 188,
    //                      "y" : 42
    //                   }
    //                ]
    //             },
    //             "signed_date" : 1637355936,
    //             "signer_uid" : "6181826c434cd496",
    //             "terms" : "I agree to do business electronically."
    //          },
    //          {
    //             "doc_annotate_id" : "6198110328d104e1",
    //             "email_address" : "swartzk+test2@gmail.com",
    //             "first_name" : "Kevin",
    //             "ip_address" : "174.204.193.213",
    //             "last_name" : "Test2",
    //             "phone" : null,
    //             "sign_data" : {
    //                "signatures" : [
    //                   {
    //                      "height" : 39,
    //                      "pageIndex" : 0,
    //                      "scale" : 1,
    //                      "signatureType" : "signed_date",
    //                      "width" : 222,
    //                      "x" : 308,
    //                      "y" : 14
    //                   }
    //                ]
    //             },
    //             "signed_date" : 1637355991,
    //             "signer_uid" : "618d63a2539392e7",
    //             "terms" : "I agree to do business electronically."
    //          }
    //       ],
    //       "source_doc_id" : "61980debefa60f20"
    //    },
    //    "success" : true
    // }

    this.state = {
      signed_doc_info : null,
      sign_requestor : null,
      signer_data : null,
      request_date : null,
      mToday : moment(),
      loading : true
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;
    this.setState({loading : true})
    this.refreshInfo()
      .then(() => {
        this.setState({loading : false})
      })
      .catch((err) => {
        log.log('error refreshing info', err);
        this.props.showAlert(t('Unable to load Metadata'), getMessageForError(err, t), () => {
          this.closeModal();
        });
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  fetchUser(guest_uid){
    if(guest_uid === this.props.accountInfoGuest.guest_uid){
      log.log('returning guest', this.props.accountInfoGuest);
      return Promise.resolve(this.props.accountInfoGuest);
    }
    else{
      return new Promise((resolve, reject) => {
        sapi.Contacts.get(guest_uid)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            log.log('error fetching user', err);
            resolve(null);
          })
      })
    }
  }

  fetchRequestorFromDMList(host_uid){
    if(host_uid === this.props.accountInfoGuest.guest_uid){
      return Promise.resolve(this.props.accountInfoGuest);
    }
    else{
      return new Promise((resolve, reject) => {
        let {
          directMessages,
          metadata
        } = this.props;
        let foundDM = _.find(directMessages, (dm) => dm.host_uid === host_uid);
        if(foundDM){
          //then we're in a dm, and we found it.  We need to figure out who is the requestor,
          //so look yourself up in the signer list.
          let areYouTheSigner = _.find(metadata.data, (info) => info.signer_uid === this.props.accountInfoGuest.guest_uid);
          if(areYouTheSigner){
            resolve(foundDM);
          }
          else{
            resolve(this.props.accountInfoGuest);
          }
        }
        else{
          //the host_uid could be a guest_uid in the case this is in a workspace thread.
          //If this is the case, we found our match
          foundDM = _.find(directMessages, (dm) => dm.guest_uid === host_uid)
        }
        resolve(foundDM);
      })
    }
  }

  tryFetchSourceDoc(forum_id, host_uid, doc_id){
    return new Promise((resolve, reject) => {

      sapi.Threads.docInfo(forum_id, host_uid, doc_id)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          //nothing to do, source doc not existing is a handled scenario.
          resolve(null);
        })

    })
  }

  refreshInfoForSignArchiveContext() {
    let {
      host_uid,
      metadata,
      doc,
      directMessages
    } = this.props;
    return new Promise((resolve, reject) => {
      Promise.all([
          this.fetchRequestorFromDMList(host_uid) //See above for why we do this.
        ])
        .then((res) => {
          //Heads up, lots of hacks in here to massage the data
          //and make it resemble dm/thread context with an actual metadata object.

          // log.log('sign context requestor', host_uid, res[0], directMessages, metadata);
          let signed_doc_info = {
            sign_request_id: metadata.sign_request_id,
            ...doc
          }
          this.setState({
            signed_doc_info,
            sign_requestor: {
              ...res[0],
              email : _.get(res[0], 'email_address')
            },
            signer_data: _.map(metadata.data, (info) => {
              return {
                ...info,
                first_name: _.get(info, 'signer_first_name'),
                last_name: _.get(info, 'signer_last_name'),
                email_address: _.get(info, 'signer_email'),
              }
            }),
            metadata_doc_id: _.get(metadata, 'data[0].source_doc_id'),
            request_date: _.get(metadata, 'data[0].request_date')
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          log.log('error refreshing info', err);
          reject(err);
        })
    });
  }

  refreshInfoForForumContext() {
    let {
      forum_id,
      host_uid,
      metadata,
      doc
    } = this.props;
    return new Promise((resolve, reject) => {
      Promise.all([
          this.tryFetchSourceDoc(forum_id, host_uid, metadata.source_doc_id),
          this.fetchUser(metadata.owner_uid)
        ])
        .then((res) => {
          log.log('refresh signing info', metadata, res);
          let signed_doc_info = res[0];
          let sign_requestor = res[1];

          this.setState({
            signed_doc_info,
            sign_requestor,
            metadata_doc_id : metadata.doc_id,
            signer_data : metadata.signer_data,
            request_date : _.get(metadata, 'request_date')
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          log.log('error refreshing info', err);
          reject(err);
        })
    })
  }

  refreshInfo(){
    let {
      isSignArchiveContext
    } = this.props;

    if(isSignArchiveContext){
      return this.refreshInfoForSignArchiveContext();
    }
    else{
      return this.refreshInfoForForumContext();
    }
  }

  closeModal() {
    this.props.close();
  }

  renderBody(){
    let {
      signed_doc_info,
      sign_requestor,
      signer_data,
      request_date,
      metadata_doc_id,
      mToday,
      loading
    } = this.state;
    let { metadata, t } = this.props;

    if(loading){
      return (
        <>
          <Loading centered size={'sm'} />
        </>
      )
    }

    return (
      <>
        <div className="row mb-3">
          <div className={`col text-center allow-preview-imgs browser-${browser.getBrowser().name}`}>
            <Image className={'mt-2'} src={companyLogo} alt={t('Logo')} imgHeight={50}/>
          </div>
        </div>
        <div className={'row mb-3'}>
          <div className={'col-8 text-left'}>
            <h5 className="primary-color font-weight-bold">
              {t("Verifyle Document Signing Audit Report")}
            </h5>
          </div>
          <div className={'col-4 text-right'}>
            <h5 className="primary-color font-weight-bold">
              {mToday.format('LL')}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Document")}
            </h5>
            <p className="text-uppercase">
              {t("Filename:")} {signed_doc_info.label}
            </p>
            <p className="text-uppercase">
              {t("Requested Date:")} {filters.momentUTCFilter(request_date, "YYYY-MM-DD HH:mm:ss") + t(' UTC')}
            </p>
            <p className="text-uppercase">
              {t("Document ID:")} {metadata_doc_id}
            </p>
            <p className="text-uppercase">
              {t("Sign ID:")} {metadata.sign_request_id}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Signature Requestor")}
            </h5>
            {sign_requestor &&
            <>
              <p className="text-uppercase">
                {t("Name:")} {sign_requestor.first_name} {sign_requestor.last_name}
              </p>
              <p className="text-uppercase">
                {t("Email:")} {sign_requestor.email}
              </p>
              <p className="text-uppercase">
                {t("User ID:")} {sign_requestor.guest_uid}
              </p>
            </>
            }
            {!sign_requestor &&
            <p className="text-uppercase">
              {t("User ID:")} {metadata.owner_uid}
            </p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Signers")}
            </h5>

            {_.map(signer_data, (signer) => {
              let smsNumber = _.get(signer, 'phone') || _.get(signer, 'sign_data.smsNumber');

              if(!signer.signed_date){
                return null;
              }

              return (
                <div key={signer.signer_uid} className="mb-3">
                  <p className="text-uppercase">
                    {t("Name:")} {signer.first_name} {signer.last_name}
                  </p>
                  <p className="text-uppercase">
                    {t("Email:")} {signer.email_address}
                  </p>
                  <p className="text-uppercase">
                    {t("User ID:")} {signer.signer_uid}
                  </p>
                  <p className="text-uppercase">
                    {t("Signed Date:")} {filters.momentUTCFilter(signer.signed_date, "YYYY-MM-DD HH:mm:ss") + t(' UTC')}
                  </p>
                  <p className="text-uppercase">
                    {t("IP Address:")} {signer.ip_address}
                  </p>

                  {signer.terms &&
                  <>
                    <p className="text-uppercase">
                      {t("Terms:")} "{signer.terms}"
                    </p>
                    <p className="text-uppercase">
                      {t("Terms Status: Accepted")}
                    </p>
                  </>
                  }
                  {smsNumber &&
                  <>
                    <p className="text-uppercase">
                      {t("Sms Verification Number:")} {smsNumber}
                    </p>
                    <p className="text-uppercase">
                      {t("Sms Verification Status: Success")}
                    </p>
                  </>
                  }
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  onPrintError(err, err2, err3) {
    log.error('onPrint Error', err, err2, err3);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t } = this.props;
    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Document Signing Audit Report")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div ref={this.bodyRef}
             className={`modal-body signing-metadata-window`}>
          {this.renderBody()}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
          <ReactToPrint onPrintError={this.onPrintError.bind(this)}
                        content={() => this.bodyRef.current}
                        trigger={() => {
                          return (
                            <Button className={'btn btn-primary'}>
                              <i className="icon ion-printer mr-2" />
                              {t("Print")}
                            </Button>
                          )
                        }}/>
        </div>
      </div>
    )
  }
}

const styles = {
  topRightButton : {
    position : 'absolute',
    top: '5px',
    right : '16px',
    fontSize : '30px',
    zIndex : 1000,
    cursor: 'pointer'
  },
}

SigningMetadataWindow.MODAL_LARGE = true;

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    directMessages : state.shared.directMessages
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

SigningMetadataWindow.propTypes = {
  close: PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SigningMetadataWindow));
