import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import UpgradeDialogNew from "./UpgradeDialogNew";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";
import c from "../../util/const";
import CurrencyInput from "../partials/elements/CurrencyInput";
import sharedActions from "../../actions/shared-actions";
import Account from "../pages/Account";
import Loading from "../partials/util/Loading";
import ElectronAwareLink from "../partials/elements/ElectronAwareLink";
import Promise from "bluebird";
import electronUtil from "../../util/electron-util";

class MerchantSetupWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      merchantRegisterResp : null,
      isLoading : false,
      disableAnyButtons : false,
      validation_errors: []
    }
  }

  static isMerchantStatusHealthy = (merchantInfo) => {
    let pending_flag = _.get(merchantInfo, 'pending_flag', false);
    let active_flag = _.get(merchantInfo, 'active_flag', false);
    return !!(merchantInfo && !pending_flag && active_flag);
  }

  static WINDOW_RESULT = {
    CANCEL : 'cancel',
    UPGRADE : 'upgrade',
    REACTIVATE : 'reactivate'
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {
      isStripeRefresh,
      isStripeReturn
    } = this.props.modalProps;
    if(isStripeRefresh || isStripeReturn){
      //This special update in refresh/return cases is to prevent a race condition on checking
      //the variables that this loads.  The app's startup sequence does not wait for these
      //calls to complete, so we have to make sure it happens here since we're about to check them for a deeplink.
      let {
        accountInfo
      } = this.props;
      Promise.all([
        this.props.updateAccountClassInfo(accountInfo.class_id, true),
        this.props.updateMerchantInfo()
      ])
        .finally(() => {
          this.init(isStripeRefresh, isStripeReturn);
        })
    }
    else{
      this.init(isStripeRefresh, isStripeReturn);
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  closeModal(res) {
    this.props.close(res);
  }

  onEscapeKey(){
    this.closeModal(MerchantSetupWindow.WINDOW_RESULT.CANCEL);
  }

  init(isStripeRefresh, isStripeReturn) {
    let {
      merchantInfo,
      accountClassInfo,
      t
    } = this.props;

    let validationErrors = [];
    if(isStripeRefresh){
      //then something about this was not successful.  We want to show an error and
      //re-register if applicable.
      validationErrors.push(t("It looks like something went wrong. Please click below to try again."))
    }

    //Set a loading flag with a spinner while we make this call.  We only want to call
    //register if we have a pending_flag.
    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    let pending_flag = _.get(merchantInfo, 'pending_flag', false);

    // log.log('init merchant setup window', merchantInfo, isStripeRefresh, isStripeReturn, accountClassInfo, merchantInfo);
    if(merchant_flag && (!merchantInfo || pending_flag)){
      this.doRegisterMerchant(validationErrors, isStripeReturn);
    }
    else{
      this.setState({
        validation_errors: validationErrors
      })
    }
  }

  doRegisterMerchant(validationErrors, isStripeReturn){
    return new Promise((resolve, reject) => {

      //Warning!  This promise isn't returned the usual way you do things.
      //It just indicates that the register call has finished one way or another.
      //It does not reject.

      let { merchantInfo, t } = this.props;
      let merchant_id = _.get(merchantInfo, 'merchant_id', false);
      this.setState({ isLoading : true })
      sapi.Merchant.register(merchant_id)
        .then((res) => {
          let redirect_url = _.get(res.data, 'url');
          if(!redirect_url){
            validationErrors.push(t("We aren't able to enable invoicing right now. Please try again later."))
            this.setState({
              validation_errors: validationErrors,
              isLoading : false
            })
          }
          else{
            this.setState({
              redirect_url,
              isLoading : false
            });
          }
          return this.props.updateMerchantInfo()
        })
        .then(() => {

          //If we get a stripe return, but your merchant details still say pending after all the registering and refreshing,
          //then add an extra message prompting users to do setup again.
          if(isStripeReturn){
            let pending_flag = _.get(merchantInfo, 'pending_flag', false);
            let active_flag = _.get(merchantInfo, 'active_flag', false);
            if(active_flag && pending_flag){
              this.setState({ validation_errors : [t("Your Stripe account may not be fully enabled yet. Please click Proceed to continue setup.")]})
            }
          }

          resolve(true);
        })
        .catch((err) => {
          log.log('error registering merchant', err);

          if(err && err.name === "APP_MER_ACCT_EXISTS"){
            let errMsg = "";
            if(electronUtil.isRunningWithinElectron()){
              errMsg = t("It looks like you have already activated your merchant account. Please try restarting the application. If this error persists, contact us at support@verifyle.com.")
            }
            else{
              errMsg = t("It looks like you have already activated your merchant account. Please try refreshing your browser. If this error persists, contact us at support@verifyle.com.")
            }
            this.props.modalAction.showAlert(t("Error activating merchant account"), errMsg);
          }
          else{
            validationErrors.push(getMessageForError(err, t))
          }

          this.setState({
            validation_errors: validationErrors,
            isLoading : false
          })
          resolve(true);
        })
    })
  }

  learnMoreLinkClick(){
    let { t } = this.props;

    this.props.modalAction.showAuthInfo(
      t('Verifyle Payment Requests'),
      <div>
        <div>
          <p>
            {t("Verifyle Platinum users pay no transaction fee to Verifyle. All other Verifyle subscribers pay a small flat transaction fee depending on their plan. Additional external processing fees apply. Details are available at stripe.com/pricing.")}
          </p>
        </div>
      </div>
    )
  }

  deactivateMerchantClick() {
    let {t} = this.props;
    this.props.modalAction.showConfirm(
      t('Are you sure?'),
      t("Disabling means you will no longer be able to request or refund payments in Verifyle, and you will no longer have access to your Invoice History in Verifyle. Existing invoices will still be accessible and payable in Threads. This does not affect your Stripe merchant account. Are you sure you want to disable?"),
      (res) => {
        if (res) {
          this.setState({disableAnyButtons : true})
          sapi.Merchant.deregister()
            .then((res) => {
              return this.props.updateMerchantInfo()
            })
            .then(() => {
              this.setState({disableAnyButtons : false})
              this.closeModal(MerchantSetupWindow.WINDOW_RESULT.CANCEL);
            })
            .catch((err) => {
              this.props.modalAction.showAlert(t("Error Deactivating Merchant Account"), getMessageForError(err, t));
              this.props.updateMerchantInfo();
              this.setState({disableAnyButtons : false})
            })
        }
      }, t("Yes"), t("No"))
  }

  reactivateMerchantClick(){
    let validationErrors = [];
    this.setState({disableAnyButtons : true})
    this.doRegisterMerchant(validationErrors)
      .then(() => {
        return this.props.updateMerchantInfo();
      })
      .then(() => {
        this.setState({disableAnyButtons : false})
        this.closeModal(MerchantSetupWindow.WINDOW_RESULT.REACTIVATE);
      })
  }

  showContactUsWindow(){
    this.props.modalAction.showContactUsWindow()
  }

  showUpgradeDialog(){
    this.closeModal(MerchantSetupWindow.WINDOW_RESULT.UPGRADE)
  }

  renderUpgradeForMerchant(){
    let { t } = this.props;
    let {
      validation_errors,
    } = this.state;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>

            <div className="text-center py-2">
              <iframe src={c.links.merchantSetupVideoLink}
                      className="handle-embed-video"
                      frameBorder='0'
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
              />
            </div>

            <div className="form-group">
              <p>
                {t("Upgrade your account to request and receive payments from your Contacts without leaving Verifyle!")}
                <a className="btn btn-link primary-color ml-2"
                   onClick={() => this.learnMoreLinkClick()}
                   style={{
                     padding: '0px',
                     lineHeight: '24px',
                     border: 'none',
                     verticalAlign: 'baseline'
                   }}>
                  {t("Learn more.")}
                </a>
              </p>
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validation_errors}/>
            </div>
          </div>
        }
      </div>
    )
  }

  renderPendingMerchant(){
    let { t } = this.props;
    let {
      validation_errors,
      redirect_url,
      isLoading
    } = this.state;

    let isRunningWithinElectron = electronUtil.isRunningWithinElectron();
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>

            <div className="text-center py-2">
              <iframe src={c.links.merchantSetupVideoLink}
                      className="handle-embed-video"
                      frameBorder='0'
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
              />
            </div>

            <div className="form-group">
              {isLoading &&
                <div className="text-center">
                  <Loading className={'m-2'}
                           size={'sm'}/>
                </div>
              }
              {!isLoading && !isRunningWithinElectron &&
                <div>
                  <p>
                    {t("In order to request and receive payments from your Contacts, we need some information from you (like where to send your payments). Please click the button below to complete the process.")}
                    <a className="btn btn-sm btn-link primary-color ml-2"
                       onClick={() => this.learnMoreLinkClick()}
                       style={{
                         padding: '0px',
                         lineHeight: '24px',
                         border: 'none',
                         verticalAlign: 'baseline'
                       }}>
                      {t("Learn more.")}
                    </a>
                  </p>

                  {redirect_url &&
                    //FYI we need to close this window when you navigate, because you might need to
                    //do this stripe stuff a few times, and each time you make changes stripe gives us a new link to follow.
                    //so close the window to force people to reload this window and get a new stripe link.
                    <div className="text-right">
                      <ElectronAwareLink
                        target="_blank"
                        className="btn btn-primary"
                        onClick={() => this.closeModal(false)}
                        href={redirect_url}>
                        {t("Proceed")}
                      </ElectronAwareLink>
                    </div>
                  }

                </div>
              }
              {!isLoading && isRunningWithinElectron &&
                <div>
                  <div className={'alert alert-warning'}>
                    {t("Merchant activation is currently available only on the web. To proceed log into your account at verifyle.com/login.")}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validation_errors}/>
            </div>
          </div>
        }
      </div>
    )
  }

  renderHealthyMerchant(){
    let {
      validation_errors
    } = this.state;
    let {
      merchantInfo,
      t
    } = this.props;

    let merchantPercentDisplay = +_.get(merchantInfo, 'merchant_fee_percent', 0);
    let merchantFeeFlatDisplay = _.get(merchantInfo, 'merchant_fee_flat', 0);
    let feeString = '';
    if(_.isNumber(merchantPercentDisplay) && merchantPercentDisplay > 0){
      feeString = `${merchantPercentDisplay}% + $${merchantFeeFlatDisplay}`;
    }
    else{
      feeString = `$${merchantFeeFlatDisplay}`;
    }
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <p>
                {t("Your merchant account is active and in good standing! The terms of your account are as follows:")}
              </p>
              <p>
                {`${feeString} ${t("per transaction plus any applicable processing fees.")}`}
                <a className="btn btn-sm btn-link primary-color ml-2"
                   onClick={() => this.learnMoreLinkClick()}
                   style={{
                     padding: '0px',
                     lineHeight: '24px',
                     border: 'none',
                     verticalAlign: 'baseline'
                   }}>
                  {t("Learn more.")}
                </a>
              </p>
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validation_errors}/>
            </div>
          </div>
        }
      </div>
    )
  }

  renderDeactivatedMerchant(){
    let {
      disableAnyButtons
    } = this.state;
    let { t } = this.props;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <p>
                {t("Your ability to request payments has been disabled.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFishyMerchant(){
    let { t } = this.props;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <p className="text-left">
                {t("Your ability to request payments has been disabled. ")}
                <a style={{textDecoration: 'underline'}}
                   onClick={() => this.showContactUsWindow()}
                   className="dark-color has-pointer">
                  {t("Contact Verifyle Customer Support")}
                </a>
                {t(" to re-enable.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFooter(merchant_flag, merchantInfo){
    let { disableAnyButtons } = this.state;
    let { t } = this.props;
    if (!merchant_flag) {
      return (
        <div className="modal-footer">
          <Button className="btn btn-primary"
                  onClick={this.showUpgradeDialog.bind(this)}>
            {t("Upgrade to Enable")}
          </Button>
        </div>
      )
    }
    else{
      let pending_flag = _.get(merchantInfo, 'pending_flag', false);
      let active_flag = _.get(merchantInfo, 'active_flag', false);
      if((pending_flag && active_flag) || !merchantInfo){
        return null;
      }
      else if(pending_flag && !active_flag){
        return null;
      }
      else if(!pending_flag && active_flag){
        return (
          <div className="modal-footer">
            <div className="mr-auto">
              <button className="btn btn-danger has-pointer"
                      disabled={disableAnyButtons}
                      onClick={() => this.deactivateMerchantClick()}>
                {t("Disable")}
              </button>
              {disableAnyButtons &&
                <Loading inline={true}
                         className="ml-2"
                         color="primary"
                         size={'sm'}/>
              }
            </div>
            <div>
              <button className="btn btn-primary has-pointer"
                      onClick={() => this.closeModal(MerchantSetupWindow.WINDOW_RESULT.CANCEL)}>
                {t("Close")}
              </button>
            </div>
          </div>
        )
      }
      else if(!pending_flag && !active_flag){
        return (
          <div className="modal-footer">
            {disableAnyButtons &&
              <Loading inline={true}
                       className="mr-2"
                       color="primary"
                       size={'sm'}/>
            }
            <button className="btn btn-primary"
                    disabled={disableAnyButtons}
                    onClick={() => this.reactivateMerchantClick()}>
              {t("Re-enable")}
            </button>
          </div>
        )
      }
    }
  }

  render() {
    let {
      accountClassInfo,
      merchantInfo,
      t
    } = this.props;

    let content = null;
    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    if(!merchant_flag){
      content = this.renderUpgradeForMerchant()
    }
    else{
      let pending_flag = _.get(merchantInfo, 'pending_flag', false);
      let active_flag = _.get(merchantInfo, 'active_flag', false);
      if((pending_flag && active_flag) || !merchantInfo){
        content = this.renderPendingMerchant();
      }
      else if(pending_flag && !active_flag){
        content = this.renderFishyMerchant();
      }
      else if(!pending_flag && active_flag){
        content = this.renderHealthyMerchant();
      }
      else if(!pending_flag && !active_flag){
        content = this.renderDeactivatedMerchant();
      }
    }

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Request Payments")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, MerchantSetupWindow.WINDOW_RESULT.CANCEL)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              {content}
            </div>
          </div>
        </div>
        {this.renderFooter(merchant_flag, merchantInfo)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    merchantInfo: state.shared.merchantInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    modalAction : {...modalActions.mapToDispatch(dispatch)},
  };
};

MerchantSetupWindow.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MerchantSetupWindow)));
