import c from '../../../util/const'
import log from '../../../util/log'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import PdfPrintPreviewSvc from "./PdfPrintPreviewSvc";
import SignatureRequest from "../../../models/SignatureRequest";
import colors from "../../../util/colors";
import { AiOutlineFileAdd } from 'react-icons/ai';
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfSigningV1Header extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
    
    }
  }
  
  cancelBtnClick() {
    this.props.cancelBtnClick();
  }
  
  nextBtnClick(){
    this.props.nextBtnClick();
  }
  
  render() {
    let { nextBtnDisabled, t } = this.props;
    
    return (
      <>
        <div style={{paddingTop: '14px', paddingBottom: '14px'}} className="container-fluid signing-bg light-color">
          <div className="row">
            <div className="col-5">
              <Button className="btn btn-sm btn-outline-light signingv1"
                      onClick={() => this.props.addAnnotationClick()}>
                <i className="icon ion-edit mr-1"/>
                {t("Add Annotation")}
              </Button>
              <Button className="btn btn-sm btn-outline-light signingv1 ml-3"
                      onClick={() => this.props.mergePdfsClick()}>
                <AiOutlineFileAdd className="icon mr-1" />
                {t("Merge Documents")}
              </Button>
            </div>
            <div className="col-7 text-right">
                <span
                  style={{fontSize: '14px', verticalAlign: 'text-top'}}>{t("Click Next when you're ready to submit.")}</span>
              <Button className="btn btn-sm btn-outline-light signingv1 mx-3"
                      onClick={this.cancelBtnClick.bind(this)}>
                {t("Cancel")}
              </Button>
              <Button className="btn btn-sm btn-outline-light signingv1"
                      disabled={nextBtnDisabled}
                      onClick={this.nextBtnClick.bind(this)}>
                {t("Next")}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const styles = {

}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfSigningV1Header.propTypes = {
  nextBtnDisabled: PropTypes.bool.isRequired,
  nextBtnClick : PropTypes.func.isRequired,
  cancelBtnClick : PropTypes.func.isRequired,
  addAnnotationClick : PropTypes.func.isRequired,
  mergePdfsClick : PropTypes.func.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSigningV1Header));
