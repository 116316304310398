//Do not import ANYTHING before these polyfills.  They must be the first import
//in order to accommodate the babies at microsoft and their crappy browsers.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//This entire block of polyfills is apparently required to fix a problem using
//Intl.NumberFormat.formatToParts.  Looks like we'll need to update this when adding new languages.
//from https://formatjs.io/docs/polyfills/intl-numberformat/
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-numberformat/locale-data/fr'

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
// import registerServiceWorker from './registerServiceWorker';

import * as Sentry from "@sentry/react";
import './scss/import.scss';
import 'react-phone-number-input/style.css'
import 'react-virtualized/styles.css';
import 'react-resizable/css/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import config from "./util/config";
import log from "./util/log";
import './i18n';
import PushNotificationHelper from "./helpers/push-notification-helper";


let runDOMFragmentWorkaround = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function(child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    }

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function(newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    }
  }
}

//This is a workaround to prevent crashes during removal of dom nodes that don't exist.
//This commonly happens with google translate
//https://github.com/facebook/react/issues/11538#issuecomment-417504600
//This code WILL slow the app down, so I don't want to run it in case we really can't
//avoid it.  I leave it here for testing purposes only.
runDOMFragmentWorkaround();

let registerFirebaseServiceWorker = () => {
  Promise.all([
    PushNotificationHelper.isWebPushEnvironment(),
    PushNotificationHelper.isElectronEnvironment()
  ])
    .then((res) => {
      log.log('checking push support res', res);
      let isWebPushEnvironment = res[0];
      let isElectronEnvironment = res[1];
      if(!isElectronEnvironment && isWebPushEnvironment){
        //Surprisingly little documentation on
        //https://moshood.medium.com/implement-push-notification-with-service-workers-and-firebase-4dd9b35a3bf6

        if('serviceWorker' in navigator){
          let swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
          navigator.serviceWorker.register(swUrl)
            .then((register) => {
              console.log('Service Worker set up')
            })
        } else {
          console.log('Service Worker not supported')
        }
      }
    })
    .catch((err) => {
      log.log('error starting push', err);
    })
}

Sentry.init({
  dsn: "https://a4020a2f9ef3448aa94f99b9deb8485d@o384426.ingest.sentry.io/5215660",
  environment : config.debug ? "development": "production",
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Non-Error promise rejection captured",
    "a promise was created in a handler but was not returned from it",
    "Object captured as promise rejection with keys",
    "Object captured as promise rejection with keys: body, code, error, name",
    "Unexpected context found on stack",
    "the network is offline"
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    if(breadcrumb.category === "console"){
      //There is extra data available, but we trim it to keep personal info out
      //as well as just to keep the amount of data down
      //Without this, we can run into bug 2692
      delete breadcrumb.data.extra;
      delete breadcrumb.data.arguments;
      if(breadcrumb.message){
        //clip to first 100 chars
        breadcrumb.message = breadcrumb.message.substring(0, 100);
      }
    }
    //log.log('sendingBreadcrumb', breadcrumb);
    return breadcrumb;
  },
});

ReactDOM.render(<Root />, document.getElementById('root'));

//Kevin removed the service worker 11/15/18
//There's good reasons to have it, but equally good reasons to not.
//Long discussion here:
//https://github.com/facebook/create-react-app/issues/2398
//registerServiceWorker();

//This is required for web push, and Kevin disabled this 9/29/22
// registerFirebaseServiceWorker();
