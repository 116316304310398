import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import Promise from "bluebird";
import accountActions from "../../../../actions/account-actions";
import modalActions from "../../../../actions/modal-actions";
import _ from "lodash";
import c from "../../../../util/const";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class PaymentCtrl extends Component {

  constructor(props) {
    super(props);

    this.state = {
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    let {accountInfo} = this.props;

    this.setState({
      validation_errors: [],
      isSaving: false,
    })
  }

  getCardInfo() {
    let { t } = this.props;
    let {stripeData} = this.props;

    let cardInfo = _.get(stripeData, '[0].card_info');
    if (!cardInfo || _.isEmpty(cardInfo)) {
      return null;
    }

    return (
      <div className={'row form-row'}>
        <div className={'col-4'}>
          <h5>{t("Card Provider")}</h5>
        </div>
        <div className={'col-8'}>
          <p>{_.capitalize(cardInfo.brand)}</p>
        </div>
        <div className={'col-4'}>
          <h5>{t("Card Number")}</h5>
        </div>
        <div className={'col-8'}>
          <p>XXXX XXXX XXXX {cardInfo.last4}</p>
        </div>
        <div className={'col-4'}>
          <h5>{t('Expiration Date')}</h5>
        </div>
        <div className={'col-8'}>
          <p>{cardInfo.exp_month}/{cardInfo.exp_year}</p>
        </div>
      </div>
    )
  }

  showStripeCheckoutUpgrade(){
    let {
      upgradePlans,
      t
    } = this.props;

    let label = _.get(upgradePlans, 'class_info.label') || t("Verifyle Subscription")
    this.props.showStripeCheckout(
      upgradePlans.pub_key,
      label,
      upgradePlans.class_info.term_cost * 100,
      (res) => {
      log.log('stripe checkout res', res);
      if(res){
        this.props.onUpgradePaymentMethod(res, upgradePlans.class_info.class_id)
      }
    })
  }

  showStripeCheckoutUpdate(){
    let {
      upgradePlans,
      accountClassInfo,
      t
    } = this.props;

    log.log('showing update', upgradePlans, accountClassInfo);

    this.props.showStripeCheckout(
      upgradePlans.pub_key,
      t('Update Payment Method'),
      accountClassInfo.class_info.term_cost * 100,
      (res) => {
      log.log('stripe checkout res', res);
      if(res){
        this.props.onUpdatePaymentMethod(res)
      }
    })
  }

  render() {
    let {isSaving, validation_errors} = this.state;

    let {
      accountInfo,
      onUpdateToken,
      onUpgradeToken,
      getMobileSubscriptionType,
      doClose,
      t
    } = this.props;
    let mobileSubscriptionText = '';
    let mobileSubscriptionType = getMobileSubscriptionType();
    if (mobileSubscriptionType) {
      mobileSubscriptionText = (mobileSubscriptionType === c.payment.PAY_APPLE ?
        t("You should be able to manage your subscription from your Apple device. Email us at support@verifyle.com if you need help.") :
        t("You should be able to manage your subscription from your Android device. Email us at support@verifyle.com if you need help.")
      );
    }

    return (
      <div className={'mt-3 mb-3'}>

        {mobileSubscriptionType &&
        <div className={'row'}>
          <div className={'col'}>
            {mobileSubscriptionText}
          </div>
        </div>
        }
        {!mobileSubscriptionType &&
        <div>
          {this.getCardInfo()}
        </div>
        }
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        {!mobileSubscriptionType &&
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-secondary mr-2'}
                      onClick={doClose.bind(this)}>{t("Cancel")}</Button>
              <span>
                <Button onClick={this.showStripeCheckoutUpdate.bind(this)}
                        className={'btn btn-primary mr-2'}>
                  {t("Update")}
                </Button>
              </span>
            </div>
          </div>
        </div>
        }
      </div>
    )
  }
}

PaymentCtrl.propTypes = {
  doClose: PropTypes.func.isRequired,
  onRef: PropTypes.func,
  onUpgradePaymentMethod: PropTypes.func.isRequired,
  onUpdatePaymentMethod: PropTypes.func.isRequired,
  getMobileSubscriptionType: PropTypes.func.isRequired,
  upgradePlans : PropTypes.object.isRequired,
  stripeData : PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    stripeAvailables: state.shared.stripeAvailables,
    subscriptionList: state.account.subscriptionList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentCtrl)));
