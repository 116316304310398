import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import GeneralTab from "../account/GeneralTab";
import {Waypoint} from "react-waypoint";
import log from "../../../util/log";
import enums from "../../../util/enums";
import colors from "../../../util/colors";
import _ from 'lodash'
import MouseRectangleSelection from "../components/MouseRectangleSelection";
import PdfSignatureRequestOverlay from "./PdfSignatureRequestOverlay";
import SignatureRequest from "../../../models/SignatureRequest";
import PdfSignatureFulfillOverlay from "./PdfSignatureFulfillOverlay";
import PdfPreview from "./PdfPreview";
import utils from "../../../util/util";
import modalActions from "../../../actions/modal-actions";
import pdfPreviewActions from "../../../actions/pdf-preview-actions";
import {connect} from "react-redux";
import ColorGenerator from "../../../helpers/color-generator";

class PdfPage extends Component {

  //This came from the pdf.js viewer.
  //https://mozilla.github.io/pdf.js/web/viewer.html
  static getOutputScale (ctx) {
    let devicePixelRatio = _.get(window, 'devicePixelRatio', 1);
    let backingStoreRatio =
      _.get(ctx, 'webkitBackingStorePixelRatio',
        _.get(ctx, 'mozBackingStorePixelRatio',
          _.get(ctx, 'msBackingStorePixelRatio',
            _.get(ctx, 'oBackingStorePixelRatio',
              _.get(ctx, 'backingStorePixelRatio',
                1)))));
    let pixelRatio = devicePixelRatio / backingStoreRatio;
    return {
      sx: pixelRatio,
      sy: pixelRatio,
      scaled: pixelRatio !== 1
    };
  }

  isRendering = false;
  renderAgain = false;

  //The 300px is a little arbitrary.  I want the place holder to be something large enough
  //So that they vertically tile for the Waypoint detection to more easily figure out what's going on.
  INITIAL_STATE = {
    canvasHeight : 1,
    canvasWidth : 1,
    canvasStyleHeight : '300px',
    canvasStyleWidth : '100%',
    canvasStyleWidthValue : 1,
    canvasStyleHeightValue : 1
  };

  constructor(props){
    super(props);

    this.contextRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = _.extend({}, this.INITIAL_STATE);
  }

  componentDidMount() {
    if(this.props.onCanvasRef) {
      this.props.onCanvasRef(this.props.page.pageIndex, this)
    }

    this.updateCanvasSize();
  }

  componentWillUnmount(){
    if(this.props.onCanvasRef) {
      this.props.onCanvasRef(this.props.page.pageIndex, undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.isVisible && this.props.isVisible){
      log.log('rendering page', this.props.page.pageIndex);
      this.renderPage();
    }
    else if(prevProps.scale !== this.props.scale){
      if(this.props.isVisible){
        log.log('rendering page', this.props.page.pageIndex);
        this.renderPage();
      }
      else{
        this.updateCanvasSize();
      }
    }
    else if(prevProps.windowSigningState !== this.props.windowSigningState){
      this.renderPage();
    }
    else if(prevProps.allowSignatureRequestEditing !== this.props.allowSignatureRequestEditing){
      this.renderPage();
    }
  }

  getPagePositionInScroll(){
    let node = this.wrapperRef.current;
    let rect = node.getBoundingClientRect();

    //log.log('page rect', this.props.page.pageIndex, rect);

    return {
      height : rect.height,
      width : rect.width,
      top : node.offsetTop,
      left : node.offsetLeft,
      right : node.offsetLeft + rect.width,
      bottom : node.offsetTop + rect.height
    }
  }

  getCanvasDimensions(){
    let node = this.contextRef.current;
    let rect = node.getBoundingClientRect();

    //log.log('page rect', this.props.page.pageIndex, rect);

    return {
      height : rect.height,
      width : rect.width,
      top : node.offsetTop,
      left : node.offsetLeft,
      right : node.offsetLeft + rect.width,
      bottom : node.offsetTop + rect.height
    }
  }

  updateCanvasSize(){
    //This calculates the size that the canvas will render to, based on a shared
    //hidden canvas that is always present in the modal window.
    //I do this so the scroll window size doesn't need to be recalculated as frequently.
    let { page, scale, sharedCanvasRef } = this.props;
    utils.waitForCondition(() => {
      return !!(_.get(sharedCanvasRef, 'current'));
    })
      .then(() => {
        let viewport = page.getViewport({scale: 1}).clone({ scale });
        let shareRef = _.get(sharedCanvasRef, 'current');
        if(!shareRef){
          return;
        }

        let ctx = null;
        try{
          ctx = shareRef.getContext('2d', {
            alpha: false
          });
        }
        catch(err){
          console.warn('error fetching shared canvas context', err);
        }

        if(!ctx){
          return;
        }

        let outputScale = PdfPage.getOutputScale(ctx);

        this.setState({
          canvasHeight : viewport.height * outputScale.sy | 0,
          canvasWidth : viewport.width * outputScale.sx | 0,
          canvasStyleHeight : viewport.height + 'px',
          canvasStyleWidth : viewport.width + 'px',
          canvasStyleWidthValue : viewport.width,
          canvasStyleHeightValue : viewport.height
        })
      })
  }

  renderPage(){
    if(this.isRendering){
      this.renderAgain = true;
      return;
    }

    let contextRef = _.get(this, 'contextRef.current')
    if(!contextRef || !contextRef.getContext){
      log.warn('canvas does not exist', this.props.page.pageIndex, this.contextRef);
      this.updateCanvasSize();
      return;
    }

    this.isRendering = true;

    let { page, scale } = this.props;
    let viewport = page.getViewport({scale: 1}).clone({ scale })

    let ctx = null;
    try{
      ctx = contextRef.getContext('2d', {
        alpha: false
      });
    }
    catch(err){
      console.warn('error fetching page canvas context', err);
    }

    if(!ctx){
      log.warn('canvas context does not exist', this.props.page.pageIndex, this.contextRef);
      this.updateCanvasSize();
      return;
    }

    let outputScale = PdfPage.getOutputScale(ctx);

    this.setState({
      canvasHeight : viewport.height * outputScale.sy | 0,
      canvasWidth : viewport.width * outputScale.sx | 0,
      canvasStyleHeight : viewport.height + 'px',
      canvasStyleWidth : viewport.width + 'px',
      canvasStyleWidthValue : viewport.width,
      canvasStyleHeightValue : viewport.height
    })

    let drawViewport = viewport.clone({scale});

    //Word to the wise...you CANNOT call ctx.scale() in here.
    //Doing this works SOMETIMES, but causes render problems on certain pdfs.  bug 1726
    //Instead, you're apparently supposed to use this undocumented method with this
    //transform property below.
    // I got that out of the pdf.js viewer code here - https://mozilla.github.io/pdf.js/web/viewer.html
    //I can't find any further documentation on this.
    let renderContext = {
      canvasContext: ctx,
      transform : !outputScale.scaled ? null : [outputScale.sx, 0, 0, outputScale.sy, 0, 0],
      viewport: drawViewport
    };

    return page.render(renderContext).promise
      .then(() => {
        if(this.renderAgain){
          this.renderAgain = false;
          this.isRendering = false;
          log.log('RERENDERING PAGE', this.props.page.pageIndex);
          return this.renderPage();
        }
      })
      .catch((err) => {
        log.log('error rendering page', err);
      })
      .finally(() => {
        this.isRendering = false;
      })
  }

  onMouseSelectionFinished(res){
    log.log('mouse selection finished', res);

    let { canvasStyleWidthValue } = this.state;

    let width = Math.abs(res.origin.x - res.target.x);
    let height = Math.abs(res.origin.y - res.target.y);

    //Special case for clicking on the page.
    //If you drew any box at all, we preserve the dimensions you entered.
    //Otherwise we want to intelligently place a scaled point where you clicked.
    if(width === 0 && height === 0){
      width = Math.max(PdfSignatureRequestOverlay.MIN_OVERLAY_WIDTH, (canvasStyleWidthValue / this.props.scale) / 4)
    }

    if(width < PdfSignatureRequestOverlay.MIN_OVERLAY_WIDTH){
      width = PdfSignatureRequestOverlay.MIN_OVERLAY_WIDTH;
    }
    if(height < PdfSignatureRequestOverlay.MIN_OVERLAY_HEIGHT){
      height = PdfSignatureRequestOverlay.MIN_OVERLAY_HEIGHT;
    }

    let coords = {
      x : res.origin.x,
      y : res.origin.y
    }

    let editGuestInfo = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    this.props.addSignatureRequest({
      id : _.uniqueId('vf-sig-request-overlay-'),
      pageIndex : this.props.page.pageIndex,
      coords,
      scale : this.props.scale,
      width,
      height,
      signatureType : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE,
      guest_uid : editGuestInfo.guest_uid,
      signaturePrimaryColor : ColorGenerator.generateColorFromId(editGuestInfo.guest_uid)
    });
  }

  onSignatureOverlayTypeChange(id, newType){

    let update = {
      id,
      signatureType : newType
    }

    //Make sure this gets set to an empty string, and not null, or react complains that
    //you're changing the input from controlled to uncontrolled.
    if(newType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      update.signatureCustomLabel = '';
    }

    this.props.updateSignatureRequest(update)
  }

  onSignatureOverlayCustomLabelChange(id, val){
    this.props.updateSignatureRequest({
      id,
      signatureCustomLabel : val
    })
  }

  onSignatureOverlayMove(id, coords){

    this.props.updateSignatureRequest({
      id,
      coords
    })
  }

  onSignatureOverlayResize(id, dimensions){
    this.props.updateSignatureRequest({
      id,
      width : dimensions.width,
      height : dimensions.height,
    })
  }

  onDeleteSignature(id){
    this.props.deleteSignatureRequest(id);
  }

  onSignatureConfirmClick(id){
    this.props.signatureRequestOverlayConfirmed(id);
  }

  onSignatureMarkerSelect(id){
    this.props.signatureRequestOverlaySelected(id);
  }

  renderSignatureRequestOverlays(){
    let {
      canvasStyleWidthValue,
      canvasStyleHeightValue
    } = this.state;
    let {
      allowSignatureRequestEditing,
      scale,
      signatureRequestOverlays,
      isFulfillingSignatureRequest
    } = this.props;

    if(isFulfillingSignatureRequest){
      return null;
    }

    let editingGuestInfo = this.props.pdfActions.getCurrentlyEditingGuestInfo();

    return (
      <>
        {signatureRequestOverlays.map((overlay) => {

          //There is some tricky business with these overlay boundaries
          //We have to offset most of our calculations by PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH so that the signature box appears where you drew it.
          //This also means we need to set it's draggable boundary wide enough to accommodate this same button, so you can
          //move a signature all the way to the edge of a pdf.
          //This also means that each overlay needs bounds calculated to it's scale, so that the offset is correct when you zoom.
          let leftPadding = PdfSignatureRequestOverlay.calculateOverlayRelativeScale(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH, overlay.scale, scale);
          let rightPadding = PdfSignatureRequestOverlay.calculateOverlayRelativeScale(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH, overlay.scale, scale);
          let totalPadding = leftPadding + rightPadding;

          return (
            <div className="position-absolute"
                 key={overlay.id}
                 style={{top: 0, bottom: 0, left: 0, right: 0, pointerEvents: 'none'}}>
              <div style={{
                position : 'relative',
                height: '100%',
                pointerEvents: 'none',
                marginLeft: `-${leftPadding}px`,
                width: `calc(${canvasStyleWidthValue}px + ${totalPadding}px)`
              }}>
                <PdfSignatureRequestOverlay
                  key={overlay.id}
                  signaturePrimaryColor={overlay.signaturePrimaryColor}
                  overlay={overlay}
                  viewScale={scale}
                  isSignatureForCurrentUser={(editingGuestInfo ? editingGuestInfo.guest_uid === overlay.guest_uid : false)}
                  scaledPageBounds={{width: canvasStyleWidthValue + leftPadding, height : canvasStyleHeightValue}}
                  allowChanges={allowSignatureRequestEditing}
                  isFulfillingSignatureRequest={isFulfillingSignatureRequest}
                  onSignatureCustomLabelChange={this.onSignatureOverlayCustomLabelChange.bind(this, overlay.id)}
                  onSignatureTypeChange={this.onSignatureOverlayTypeChange.bind(this, overlay.id)}
                  deleteSignature={this.onDeleteSignature.bind(this, overlay.id)}
                  onSignatureResize={this.onSignatureOverlayResize.bind(this, overlay.id)}
                  onSignatureMove={this.onSignatureOverlayMove.bind(this, overlay.id)} />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  renderSignatureFulfillOverlays(){
    let {
      canvasStyleWidthValue,
      canvasStyleHeightValue
    } = this.state;
    let {
      allowSignatureRequestEditing,
      scale,
      signatureRequestOverlays,
      isFulfillingSignatureRequest
    } = this.props;
    let {
      accountInfoGuest
    } = this.props.sharedState;

    if(!isFulfillingSignatureRequest){
      return null;
    }

    return (
      <>
        {signatureRequestOverlays.map((overlay) => {
          let isYou = accountInfoGuest && accountInfoGuest.guest_uid === overlay.guest_uid;
          let leftPadding = PdfSignatureRequestOverlay.calculateOverlayRelativeScale(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH, overlay.scale, scale);
          let rightPadding = PdfSignatureRequestOverlay.calculateOverlayRelativeScale(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH, overlay.scale, scale);
          let totalPadding = leftPadding + rightPadding;

          return (
            <div className="position-absolute"
                 key={overlay.id}
                 style={{top: 0, bottom: 0, left: 0, right: 0, pointerEvents: 'none'}}>
              <div style={{
                position : 'relative',
                height: '100%',
                pointerEvents: 'none',
                marginLeft: `-${leftPadding}px`,
                width: `calc(${canvasStyleWidthValue}px + ${totalPadding}px)`
              }}>
                <PdfSignatureFulfillOverlay
                  key={overlay.id}
                  signaturePrimaryColor={isYou ? colors.PRIMARY : overlay.signaturePrimaryColor}
                  allowInteraction={isYou}
                  overlay={overlay}
                  viewScale={scale}
                  onRef={(id, ref) => this.props.onFulfillSignatureOverlayRef(id, ref)}
                  onMarkerSelect={this.onSignatureMarkerSelect.bind(this, overlay.id)}
                  onConfirmClick={this.onSignatureConfirmClick.bind(this, overlay.id)} />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  render() {
    let {
      canvasHeight,
      canvasWidth,
      canvasStyleHeight,
      canvasStyleWidth,
    } = this.state;

    let {
      isVisible,
      page,
      allowSignatureRequestEditing,
      windowSigningState
    } = this.props;

    let editingGuestInfo = this.props.pdfActions.getCurrentlyEditingGuestInfo();

    return (
      <div ref={this.wrapperRef}
           key={page.pageIndex}
           style={PdfPage.styles.pageWrap}>
        <div className="text-center" style={
          windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING ?
            {display: 'contents'} :
            (isVisible ? {
              position : 'relative',
              marginTop : PdfPage.CANVAS_MARGIN + 'px',
              marginBottom : PdfPage.CANVAS_MARGIN + 'px',
            } : {position : 'relative'})
        }>
          {this.renderSignatureRequestOverlays()}
          {this.renderSignatureFulfillOverlays()}

          <MouseRectangleSelection disabled={!allowSignatureRequestEditing || !isVisible}
                                   {...(allowSignatureRequestEditing && editingGuestInfo &&
                                     {
                                       signaturePrimaryColor: ColorGenerator.generateColorFromId(editingGuestInfo.guest_uid)
                                     }
                                   )}
                                   onSelectFinished={this.onMouseSelectionFinished.bind(this)}>
            <canvas height={isVisible ? canvasHeight : 0}
                    width={isVisible ? canvasWidth : 0}
                    className={`pdf-page-canvas pdf-canvas-${page.pageIndex} ${!isVisible ? 'd-none' : ''}`}
                    style={{
                      ...(windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING ? PdfPage.styles.canvas : null),
                      ...(allowSignatureRequestEditing ? PdfPage.styles.canvasCrosshair : null),
                      ...{
                        width: canvasStyleWidth,
                        height: canvasStyleHeight
                      }
                    }}
                    ref={(isVisible ? this.contextRef : null)}/>
          </MouseRectangleSelection>
        </div>

        {!isVisible &&
        <div style={{...PdfPage.styles.canvasPlaceholder, width: canvasStyleWidth, height: canvasStyleHeight}}
             className="d-inline-block light-bg"
             ref={this.contextRef}/>
        }
      </div>
    )
  }
}

PdfPage.CANVAS_MARGIN = 20;
PdfPage.styles = {
  pageWrap : {
    textAlign: 'center',
    backgroundColor : colors.TRANSPARENT,
    display: 'flex',
    justifyContent : 'center'
  },
  canvas : {
    margin: PdfPage.CANVAS_MARGIN + 'px',
  },
  canvasWrap : {
    position : 'relative',
    margin : PdfPage.CANVAS_MARGIN + 'px',
  },
  canvasPlaceholder : {
    margin : PdfPage.CANVAS_MARGIN + 'px'
  },
  canvasCrosshair : {
    cursor : 'crosshair'
  }
}

PdfPage.propTypes = {
  pdf : PropTypes.object.isRequired,
  page : PropTypes.object.isRequired,
  scale : PropTypes.number.isRequired,
  isVisible : PropTypes.bool.isRequired,
  sharedCanvasRef : PropTypes.object.isRequired,
  onCanvasRef : PropTypes.func.isRequired,

  isFulfillingSignatureRequest : PropTypes.bool.isRequired,
  allowSignatureRequestEditing : PropTypes.bool.isRequired,
  addSignatureRequest : PropTypes.func.isRequired,
  updateSignatureRequest : PropTypes.func.isRequired,
  deleteSignatureRequest :PropTypes.func.isRequired,
  signatureRequestOverlays : PropTypes.array.isRequired,
  signatureRequestOverlaySelected : PropTypes.func.isRequired,
  signatureRequestOverlayConfirmed : PropTypes.func.isRequired,
  onFulfillSignatureOverlayRef : PropTypes.func.isRequired,

  windowSigningState : PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    sharedState : {...state.shared},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfPage);
