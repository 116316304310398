import React, {Component} from 'react';
import {connect} from 'react-redux';

import _ from 'lodash';

import Promise from 'bluebird';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import filters from '../../../helpers/filters';
import c from '../../../util/const';
import Button from "../elements/Button";
import log from "../../../util/log";
import sapi from '../../../util/sapi';

import sharedActions from '../../../actions/shared-actions';
import modalActions from '../../../actions/modal-actions';
import accountActions from '../../../actions/account-actions';
import Loading from "../util/Loading";
import classNames from "classnames";
import {CSSTransition} from "react-transition-group";
import ExpandableRow from "../components/ExpandableRow";
import BlockListCtrl from "./general/BlockListCtrl";
import SubscriptionCtrl from "./subscription/SubscriptionCtrl";
import PaymentCtrl from "./subscription/PaymentCtrl";
import {getMessageForError} from "../../../util/errors";
import stripeHelper from "../../../util/stripe-helper";
import {withStripeElements} from "../../../containers/StripeInjectedComponent";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import Scroll from "react-scroll";
import utils from "../../../util/util";
import InformationServicesCtrl from "./subscription/InformationServicesCtrl";

class SubscriptionTab extends Component {

  PAYMENT_INFO_ID = 'subscription-payment-info-id';
  INFO_SERVICES_ID = 'info-services-id';

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showLoadError : false,
      activeControl : null,
      showUpdatePaymentBtn : false,
      showCancelPaymentBtn : false,
      showNewPaymentBtn : false,
      showPaymentText : '',
      mobileSubscriptionText : '',
      upgradeMsgText : '',
      showAnything : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.accountInfo !== this.props.accountInfo ||
      prevProps.stripeAvailables !== this.props.stripeAvailables ||
      prevProps.stripeData !== this.props.stripeData) {
        this.updateStateFromAccountData();
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(false);
    }
  }

  updateStateFromAccountData(){
    return new Promise((resolve, reject) => {
      utils.waitForCondition(() => {
          return this.props.accountInfo && this.props.stripeAvailables && this.props.stripeData;
        })
        .then(() => {
          //This whole method largely came from the logic built into the old webapp.
          //Some of the states that this logic describes may not be possible anymore, I just
          //wanted to maintain support for as much as possible.
          let {
            accountInfo,
            stripeAvailables,
            stripeData,
            t
          } = this.props;

          //log.log('subscription tab setup', accountInfo, stripeAvailables, stripeData);
          //Note that in certain circumstances, stripe can cancel the subscription out from under us.
          //When that happens, we return the card info in stripe data.  A valid stripe data object
          //has an ID field, which we check here.
          let isValidStripeData = stripeData && stripeData.length > 0 && stripeData[0].id;
          let isHost = accountInfo['host_flag'];
          let isAboutToExpire = accountInfo['expiry_ttl'] <= c.account.ACC_EXPIRY_WARN_PERIOD;

          let upgradeUser = (stripeAvailables.class_info && !_.isEmpty(stripeAvailables.class_info)) ? stripeAvailables.class_info : null;

          let showAnything = false; //in ui this was called showPreupgradeWindow
          let showUpdatePaymentBtn = false;
          let showCancelPaymentBtn = false;
          let showNewPaymentBtn = false;
          let showPaymentText = false; //for showing text prompting users to buy in.

          if (accountInfo) {
            if (!c.isFreeTier(accountInfo.class_id)) {
              if (isValidStripeData) {
                showAnything = true;
                showUpdatePaymentBtn = true;
                showCancelPaymentBtn = true;
              }
            }
            else {
              showUpdatePaymentBtn = isValidStripeData;
              showCancelPaymentBtn = isValidStripeData;
              showPaymentText = !isValidStripeData;
            }
          }

          if (isValidStripeData) {
            showAnything = true;
          }

          if (upgradeUser) {
            showNewPaymentBtn = true;
            showAnything = true;
          }

          //This is a carefully maintained block of code that's been built on since the early vf app.  There's some delicate cases in here,
          //and I'm not sure that all of them are even possible anymore.  Just FYI, careful in here!
          let upgradeMsgText = '';
          if (!accountInfo['class_id'] || accountInfo['class_id'] === 0) {
            showAnything = true;
            upgradeMsgText = t("To begin your Verifyle Pro subscription, click \"Upgrade\" and enter your payment information.");
          }
          else if (accountInfo['class_id'] < 100) {
            if (!isHost) {
              showAnything = true;
              upgradeMsgText = t("To reactivate your Verifyle Pro subscription, click \"Upgrade\" and enter your payment information.");
            }
            else if (isAboutToExpire) {
              showAnything = true;
              upgradeMsgText = t("Your trial account will expire soon.  To continue as a Verifyle Pro, click \"Upgrade\" and follow the instructions.");
            }
            else if (!isAboutToExpire) {
              showAnything = true;
              upgradeMsgText = t("To subscribe as a Verifyle Pro, click \"Upgrade\" and follow the instructions.");
            }
          }
          else if (accountInfo['class_id'] >= 100) {
            if (!isHost) {
              showAnything = true;
              upgradeMsgText = t("To reactivate your Verifyle Pro subscription, click \"Update Payment Method\" and enter your payment information.");
            }
            else if (isAboutToExpire) {
              showAnything = true;
              if (!isValidStripeData) {
                upgradeMsgText = t("To update your credit card information, click \"Update Payment Method\" below.");
              }
              else {
                upgradeMsgText = t("Your Verifyle Pro subscription will expire soon.  To update your payment method, click \"Update Payment Method\" below.");
              }
            }
            else if (!isAboutToExpire) {
              if (!isValidStripeData) {
                upgradeMsgText = '';
              }
              else {
                upgradeMsgText = '';
              }

              if (upgradeUser) {
                showPaymentText = true;
                upgradeMsgText = t("To subscribe as a Verifyle Pro, click \"Upgrade\" and follow the instructions.");
              }
            }
          }

          let mobileSubscriptionType = this.getMobileSubscriptionType();

          this.setState({
            showUpdatePaymentBtn,
            showCancelPaymentBtn,
            showNewPaymentBtn,
            showPaymentText,
            mobileSubscriptionType,
            upgradeMsgText,
            showAnything
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getMobileSubscriptionType(){
    let {subscriptionList} = this.props;

    if(subscriptionList){
      let keys = _.keys(subscriptionList);

      let activeLicenses = [];
      let bestActiveLicense = null;

      for(let i = 0; i < keys.length; i++){
        let key = keys[i];
        let license = subscriptionList[key]
        if(license.active_flag){
          activeLicenses.push({
            class_id: +key,
            license: license
          });
        }

        if(!bestActiveLicense || bestActiveLicense.class_id < key){
          var thisLicense = subscriptionList[key];
          if(thisLicense && thisLicense.active_flag){
            bestActiveLicense = thisLicense;
          }
        }
      }

      if(bestActiveLicense){
        return bestActiveLicense.trans_type;
      }
      else{
        return null;
      }
    }
  }

  cancelSubscription() {
    let {
      showConfirm,
      updateAccountInfo,
      updateStripeAvailables,
      updateStripeData,
      updateLogo,
      t
    } = this.props;

    showConfirm(t('Are you sure?'),
        t('Upon cancelling you will immediately lose your Verifyle Pro capabilities and will be subject to reduced capacity limits.  Click OK to cancel now.'),
        (res) => {
          if (res) {
            this.setState({loading:true})
            sapi.Stripe.unsubscribe()
                .then((res) => {
                  log.log('cancel res', res);
                  return Promise.all([
                    updateAccountInfo(),
                    updateStripeData(),
                    updateStripeAvailables(),
                    updateLogo(),
                  ])
                })
                .catch((err) => {
                  log.log('error canceling', err);
                })
                .finally(() => {
                  this.setState({loading:false})
                })
          }
        })
  }

  onSubscribeSuccess(res){
    log.log('subscribe success', res);
    return Promise.all([
      this.props.updateAccountInfo(),
      this.props.updateStripeData(),
      this.props.updateStripeAvailables(),
      this.props.updateLogo(),
    ])
  }

  onSubscribeFailure(err){
    let { t } = this.props;
    log.log('subscribe error', err);

    this.props.showAlert(t("Error processing card"), stripeHelper.getMessageForStripeOrVfError(err, t));

    return Promise.all([
        this.props.updateAccountInfo(),
        this.props.updateStripeData(),
        this.props.updateStripeAvailables(),
        this.props.updateLogo()
      ])
  }

  onFixUpgradePayment(class_id, paymentMethodId){
    log.log('onFixUpgradePayment', arguments);
    let {
      accountInfo,
      stripe,
      elements,
      t
    } = this.props;

    this.setState({loading:true})
    sapi.Stripe.subscribeV2(paymentMethodId, class_id, null)
      .then((res) => {
        return this.onSubscribeSuccess(res);
      })
      .catch((err) => {
        let stripeClientSecret = _.get(err, 'body.data.client_secret');
        if(err && err.name === 'APP_PARTIAL_FAIL' && stripeClientSecret){
          log.warn('got partial fail', err);
          stripeHelper.handleStripe3DAuth(stripe, stripeClientSecret, paymentMethodId, t)
            .then((res) => {
              this.onSubscribeSuccess(res);
            })
            .catch((err) => {
              this.onSubscribeFailure(err);
            })
        }
        else{
          this.onSubscribeFailure(err);
        }
      })
      .finally(() => {
        this.setState({loading:false})
      })
  }

  onSuccessfulPaymentMethod(paymentMethodId, toClassId, couponId){
    log.log('onSuccessfulPaymentMethod', arguments);

    let {
      stripe,
      elements,
      t
    } = this.props;

    this.setState({loading:true});
    sapi.Stripe.subscribeV2(paymentMethodId, toClassId, couponId)
      .then((res) => {
        return this.onSubscribeSuccess(res);
      })
      .catch((err) => {
        let stripeClientSecret = _.get(err, 'body.data.client_secret');
        if(err && err.name === 'APP_PARTIAL_FAIL' && stripeClientSecret){
          log.warn('got partial fail', err);
          stripeHelper.handleStripe3DAuth(stripe, stripeClientSecret, paymentMethodId, t)
            .then((res) => {
              this.onSubscribeSuccess(res);
            })
            .catch((err) => {
              this.onSubscribeFailure(err);
            })
        }
        else{
          this.onSubscribeFailure(err);
        }
      })
      .finally(() => {
        this.setState({loading:false})
      })
  }

  onUpdatePaymentMethod(paymentMethodId){
    let {
      accountInfo,
      stripe,
      elements,
      t
    } = this.props;

    log.log('onUpdatePaymentMethod', arguments);

    //bug 3191 - if this subscription status is past_due we need to
    //pass a flag to the backend to tell it to immediately change.
    let { stripeData } = this.props;
    let is_charge = _.get(stripeData, '[0].status') === 'past_due';

    this.setState({loading:true})
    sapi.Stripe.updateV2(paymentMethodId, null, is_charge)
      .then((res) => {
        return this.onSubscribeSuccess(res);
      })
      .catch((err) => {
        let stripeClientSecret = _.get(err, 'body.data.client_secret');
        if(err && err.name === 'APP_PARTIAL_FAIL' && stripeClientSecret){
          log.warn('got partial fail', err);
          stripeHelper.handleStripe3DAuth(stripe, stripeClientSecret, paymentMethodId, t)
            .then((res) => {
              this.onSubscribeSuccess(res);
            })
            .catch((err) => {
              this.onSubscribeFailure(err);
            })
        }
        else{
          this.onSubscribeFailure(err);
        }
      })
      .finally(() => {
        this.setState({loading:false})
      })
  }

  trySubscriptionClick(){
    //When linking to this page, we don't always know what the subscription page status is
    //This just tests if the ref exists first.
    if(this.subscriptionCtrl){
      this.subscriptionClick();
    }
  }

  tryInfoServicesClick(){
    if(this.infoSvcsCtrl){
      this.infoServicesClick();
    }
  }

  tryUpdateFixPayment(){
    utils.waitForCondition(() => {
      return !this.state.loading;
    })
      .then(() => {
        let {
          mobileSubscriptionType,
          showUpdatePaymentBtn,
          showCancelPaymentBtn
        } = this.state;

        // log.log('try payment click', mobileSubscriptionType, showUpdatePaymentBtn, showCancelPaymentBtn);

        if(showUpdatePaymentBtn || showCancelPaymentBtn || mobileSubscriptionType){
          //When linking to this page, we don't always know what the subscription page status is
          //This just tests if the ref exists first.
          utils.waitForCondition(() => {
              return !!(this.paymentCtrl);
            })
            .then(() => {
              setTimeout(() => {
                this.paymentCtrl.showStripeCheckoutUpdate();
              })
            })
        }
        else{
          this.showOptionsClick();
        }
      })
  }

  subscriptionClick(){
    let { activeControl } = this.state;

    if(activeControl === 'subscription_info'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('subscription_info');
      this.subscriptionCtrl.init();
    }
  }

  togglePaymentReceipts(evt){
    let { updateAccountInfo } = this.props;

    sapi.AccountInfo.update({receipt_flag : evt.target.checked})
      .then((res) => {
        return updateAccountInfo();
      })
      .catch((err) => {
        log.log('error toggling auto alerts');
      })
  }

  infoServicesClick(){
    let { activeControl } = this.state;

    if(activeControl === 'vf_info_services'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('vf_info_services');
      this.infoSvcsCtrl.init();
      this.scrollWithinContainer(this.INFO_SERVICES_ID)
    }
  }

  paymentClick(){
    let { activeControl } = this.state;

    if(activeControl === 'payment_info'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('payment_info');
      this.paymentCtrl.init();
      this.scrollWithinContainer(this.PAYMENT_INFO_ID)
    }
  }

  closeActivePanel(){
    this.setActiveControl(null);
  }

  setActiveControl(control){
    this.setState({activeControl: control})
  }

  getSubscriptionTypeIcon(){
    let {
      mobileSubscriptionType,
    } = this.state;
    let {
      stripeData,
      t
    } = this.props;

    let showCardIcon = false;
    let type = '';
    if(mobileSubscriptionType && mobileSubscriptionType === c.payment.PAY_APPLE){
      type = t('Apple');
    }
    else if(mobileSubscriptionType && mobileSubscriptionType === c.payment.PAY_GOOGLE){
      type = t('Google Play');
    }
    else{
      showCardIcon = true;
      let cardBrand = _.get(stripeData, '[0].card_info.brand');
      if(cardBrand){
        type = _.capitalize(cardBrand);
      }
      else{
        type = t("Credit Card");
      }
    }

    return (
        <>
          <div className="d-flex">
            {showCardIcon &&
            <div className="mr-2">
              <i className="icon ion-card light-grey-color align-middle"
                 style={{
                   fontSize: '20px',
                   lineHeight: '38px'
                 }}/>
            </div>
            }
            <input type="text"
                   readOnly
                   className="form-control-plaintext light-grey-color no-pointer"
                   id="payment-info-field"
                   value={type}/>
          </div>
        </>
    )
  }

  getPublisherText(){
    let {
      publisherList,
      t
    } = this.props;

    let subscribeCount = 0;
    _.each(publisherList, (publisher) => {
      if(publisher.subscribed_flag){
        subscribeCount++;
      }
    })

    return `${subscribeCount === 0 ? t("No") : subscribeCount} ${subscribeCount === 1 ? t("Service") : t("Services")}`
  }

  showOptionsClick() {
    let {
      t
    } = this.props;
    let {
      mobileSubscriptionType,
      showUpdatePaymentBtn,
      showCancelPaymentBtn,
      showNewPaymentBtn,
      showPaymentText,
      upgradeMsgText,
      showAnything
    } = this.state;

    //Check mobile first
    if (mobileSubscriptionType) {
      if (mobileSubscriptionType && mobileSubscriptionType === c.payment.PAY_APPLE) {
        this.props.showAlert(
          t("Verifyle Subscription Status"),
          t("It looks like your subscription was established through the Apple Store.  You should be able to manage your subscription from your iOS device.  Email us at support@verifyle.com if you need help.")
        );
      }
      else if (mobileSubscriptionType && mobileSubscriptionType === c.payment.PAY_GOOGLE) {
        this.props.showAlert(
          t("Verifyle Subscription Status"),
          t("It looks like your subscription was established through the Google Play Store.  You should be able to manage your subscription from your Android device.  Email us at support@verifyle.com if you need help.")
        );
      }
    }
    else {
      //show the upgrade!
      this.props.showUpgradeDialog(null, (res) => {
        log.log('upgrade dlg res', res);
      })
    }
  }

  scrollWithinContainer(elemId){
    setTimeout(() => {
      Scroll.scroller.scrollTo(elemId, {
        duration: 300,
        smooth: true,
        offset: -65
      })
    }, 250)
  }

  render() {
    let {
      accountInfo,
      stripeAvailables,
      accountClassInfo,
      publisherList,
      stripe,
      elements,
      stripeData,
      t
    } = this.props;

    let {
      loading,
      showLoadError,
      showUpdatePaymentBtn,
      showCancelPaymentBtn,
      showNewPaymentBtn,
      showPaymentText,
      upgradeMsgText,
      showAnything,
      activeControl,
      mobileSubscriptionType
    } = this.state;

    let hasStripeAccount = stripeData && stripeData.length > 0 && stripeData[0].id;

    if(!accountInfo || !stripeAvailables){
      return null;
    }

    if(loading){
      return (
        <div>
          <div>
            <h3 className={'ml-3'}>{t("Subscription Settings")}</h3>
          </div>
          <div className={'card'}>
            <div className={'card-body'}>
              <Loading className={'m5'} centered={true} size={'sm'}/>
            </div>
          </div>
        </div>
      )
    }

    if(showLoadError){
      return (
        <div>
          <div>
            <h3 className={'ml-3'}>{t("Subscription Settings")}</h3>
          </div>
          <div className={'card'}>
            <div className={'card-body'}>
              <div className="text-center">
                <p className="my-2">
                  {t("There was a problem loading subscription data.  Please try again.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div>
          <h3 className={'ml-3'}>{t("Subscription Settings")}</h3>
        </div>
        <div className={'card'}>

          <div>
            <div
              className={classNames('card-header transition-bg-color bg-white')}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'subscription-info-field'}>
                    {t("Subscription")}
                  </label>
                </div>
                <div className={'col-5'}>
                  <input type="text"
                         readOnly
                         className="form-control-plaintext light-grey-color no-pointer"
                         id="subscription-info-field"
                         value={_.get(accountClassInfo, 'class_info.label', '')}/>
                </div>
                <div className={'col-3 text-right'}>
                  <Button className={'btn btn-link'}
                          onClick={this.showOptionsClick.bind(this)}>{t("Show Options")}</Button>
                </div>
              </div>
            </div>
            {/*<ExpandableRow isActive={activeControl === 'subscription_info'}>*/}
            {/*  <div className={'card-body card-border-bottom'}>*/}
            {/*    <SubscriptionCtrl onRef={ref => (this.subscriptionCtrl = ref)}*/}
            {/*                      doCancelSubscription={this.cancelSubscription.bind(this)}*/}
            {/*                      doClose={this.closeActivePanel.bind(this)}/>*/}
            {/*  </div>*/}
            {/*</ExpandableRow>*/}
          </div>

          {(showUpdatePaymentBtn || showCancelPaymentBtn || mobileSubscriptionType) &&
          <div>
            <div
              className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'payment_info'})}
              onClick={this.paymentClick.bind(this)}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'payment-info-field'}>
                    {t("Payment Method")}
                  </label>
                </div>
                <div className={'col-5'}>
                  {this.getSubscriptionTypeIcon()}
                </div>
                <div className={'col-3 text-right'}>
                  <div className={'pt-2 pr-4'}>
                    <CSSTransition in={activeControl === 'payment_info'}
                                   timeout={400}
                                   classNames={'rotate-90'}>
                      <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
            <ExpandableRow isActive={activeControl === 'payment_info'}>
              <div id={this.PAYMENT_INFO_ID} className={'card-body card-border-bottom'}>
                <PaymentCtrl onRef={ref => (this.paymentCtrl = ref)}
                             stripeData={this.props.stripeData}
                             upgradePlans={this.props.stripeAvailables}
                             onUpdatePaymentMethod={this.onUpdatePaymentMethod.bind(this)}
                             onUpgradePaymentMethod={this.onSuccessfulPaymentMethod.bind(this)}
                             getMobileSubscriptionType={this.getMobileSubscriptionType.bind(this)}
                             doClose={this.closeActivePanel.bind(this)}/>
              </div>
            </ExpandableRow>
          </div>
          }

          {publisherList.length > 0 &&
          <div>
            <div
              className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'vf_info_services'})}
              onClick={this.infoServicesClick.bind(this)}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'vf-info-svcs-field'}>
                    {t("Verifyle Information Services")}
                  </label>
                </div>
                <div className={'col-5'}>
                  <input type="text"
                         readOnly
                         id="vf-info-svcs-field"
                         className="form-control-plaintext light-grey-color no-pointer"
                         value={this.getPublisherText()}/>
                </div>
                <div className={'col-3 text-right'}>
                  <div className={'pt-2 pr-4'}>
                    <CSSTransition in={activeControl === 'vf_info_services'}
                                   timeout={400}
                                   classNames={'rotate-90'}>
                      <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
            <ExpandableRow isActive={activeControl === 'vf_info_services'}>
              <div id={this.INFO_SERVICES_ID} className={'card-body card-border-bottom'}>
                <InformationServicesCtrl onRef={ref => (this.infoSvcsCtrl = ref)}
                                         doClose={this.closeActivePanel.bind(this)}/>
              </div>
            </ExpandableRow>
          </div>
          }

          {!c.isFreeTier(accountInfo.class_id) && hasStripeAccount &&
          <div
            className={classNames('card-header transition-bg-color', {'bg-white': activeControl !== 'auto_send_alerts'})}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'send-payment-receipts'}>
                  {t("Send Payment Receipts")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color"
                       value={(accountInfo.receipt_flag ? t('ON') : t('OFF'))}/>
              </div>
              <div className={'col-3 text-right'}>

                <span className="custom-control custom-switch custom-switch-lg">
                  <input type="checkbox"
                         className="custom-control-input"
                         onChange={this.togglePaymentReceipts.bind(this)}
                         checked={accountInfo.receipt_flag}
                         id="send-payment-receipts"/>
                    <label className="custom-control-label" htmlFor={'send-payment-receipts'}/>
                </span>

              </div>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

SubscriptionTab.propTypes = {
  onRef : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    accountInfo : state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,
    stripeAvailables : state.shared.stripeAvailables,
    stripeData : state.shared.stripeData,
    subscriptionList : state.account.subscriptionList,
    publisherList : state.shared.publisherList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStripeElements(SubscriptionTab))));
