import c from '../../util/const';
import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import log from "../../util/log";
import sapi from '../../util/sapi';
import Loading from "../partials/util/Loading";

import workspaceActions from '../../actions/workspace-actions';

import Promise from 'bluebird';
import Image from "../partials/elements/Image";
import filters from "../../helpers/filters";
import ImgPreview from "../partials/doc-preview/ImgPreview";
import PdfPreview from "../partials/pdf-preview/PdfPreview";
import TextPreview from "../partials/doc-preview/TextPreview";
import SvgPreview from "../partials/doc-preview/SvgPreview";
import classnames from 'classnames'
import AnimateHeight from "react-animate-height";
import utils from "../../util/util";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import SignatureRequest from "../../models/SignatureRequest";
import _ from "lodash";
import pdfPreviewActions from "../../actions/pdf-preview-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import sharedActions from "../../actions/shared-actions";
import msgHelper from "../../helpers/msg-helper";

class PreviewWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading : true,
      doc_info : null,
      displayMode : null,
      panelRef : null
    }
  }

  setWindowMode(mode){
    this.setState({
      displayMode : mode
    })
  }

  getPreviewMode(label){
    let mode = null;
    if (utils.isPDFPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.PDF_PREVIEW;
    } else if (utils.isImgPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.IMG_PREVIEW;
    } else if (utils.isTextPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.TEXT_PREVIEW;
    } else if (utils.isSvgPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.SVG_PREVIEW;
    }

    return mode;
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;
    let { doc_id, forum_id, host_uid, isSignArchiveContext, signArchiveRow } = this.props.modalProps;

    if(isSignArchiveContext){
      this.setState({loading: true});

      //Stubbing doc_info in this case, we don't actually have it except for the label.
      this.setState({
        doc_info: {
          label : signArchiveRow.label
        },
        displayMode : this.getPreviewMode(signArchiveRow.label)
      }, () => {
        this.setState({loading: false})
      })
    }
    else{
      this.setState({loading: true})
      Promise.all([
          sapi.Threads.docInfo(forum_id, host_uid, doc_id)
        ])
        .then((res) => {
          this.setState({
            doc_info: res[0].data,
            displayMode : this.getPreviewMode(res[0].data.label)
          })
        })
        .catch((err) => {
          log.log('error loading doc info', err);
          this.props.showAlert(t("Unable to load document"), getMessageForError(err, t), () => {
            this.props.close();
          })
        })
        .finally(() => {
          this.setState({loading: false})
        })
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  doCloseFromPreview(result){
    this.props.close(result || !!this.props.pdfState.signatureRequestData);
  }

  buildSignatureRequest(){
    let { thread, dm, forum_id, host_uid, doc_id } = this.props.modalProps;
    let promise = null;
    if(thread){
      promise = sapi.Threads.messages(forum_id, host_uid, thread.chat_id, null, true)
        .then((res) => {
          let foundMesg = null;
          let foundDocStatus = null;
          _.each(_.get(res, 'data.mesg_data', []), (mesg) => {
            if(mesg.docs){
              _.each(mesg.docs, (doc) => {
                if(doc.doc_id === doc_id){
                  foundMesg = mesg;
                  foundDocStatus = doc;
                  return false;
                }
              })
            }
            if(foundMesg){
              return false;
            }
          })
          return {
            foundMesg,
            foundDocStatus
          };
        })
        .then((mesg) => {
          let { foundMesg, foundDocStatus } = mesg;

          return SignatureRequest.newThreadSignatureRequest(
            forum_id,
            host_uid,
            doc_id,
            thread.chat_id,
            foundMesg.mesg_id,
            foundDocStatus ? foundDocStatus : {signer_info : []} //Hack, we don't have doc status in this scenario
          );
        })
    }
    else if(dm){
      promise = sapi.DM.messages(dm.guest_uid)
        .then((res) => {
          let foundMesg = null;
          let foundDoc = null;
          _.each(_.get(res, 'data.mesg_data', []), (mesg) => {
            if(mesg.docs){
              _.each(mesg.docs, (doc) => {
                if(doc.doc_id === doc_id){
                  foundMesg = mesg;
                  foundDoc = doc;
                  return false;
                }
              })
            }
            if(foundMesg){
              return false;
            }
          })
          return {
            foundMesg,
            foundDoc
          };
        })
        .then((mesg) => {
          let { foundMesg, foundDoc } = mesg;
          return SignatureRequest.newDMSignatureRequest(
            dm.guest_uid,
            dm.forum_id,
            dm.host_uid,
            doc_id,
            foundMesg.mesg_id,
            foundDoc ? foundDoc : {signer_info : []} //Hack, we don't have doc status in this scenario
          )
        })
    }
    else{
      throw new Error('Unable to initiate signature request.  Needed data not present');
    }

    return promise;
  }

  closeAndReattachDocument(guest_uid, forum_id, chat_id, doc_id, filename, docData){
    let doc = {
      doc_id : doc_id,
      name : filename,
      filename : filename,
      isPDFXHRUpload : true,
      doCheckRequestSignature : true,
      data : docData,
      sizeString : '', //I don't know if I can get this?  Maybe?
      uniqueId : _.uniqueId('vf-pdf-submit'),
    };

    let destination = {};
    if(guest_uid){
      destination.guest_uid = guest_uid;
    }
    if(forum_id){
      destination.forum_id = forum_id;
    }
    if(chat_id){
      destination.chat_id = chat_id;
    }

    let result = {
      doc,
      ...destination,
      isPdfSubmitResult : true
    }

    this.doCloseFromPreview(result);
  }

  initializeThreadSignatureRequest(sigRequest) {
    let { threadParticipantLookup, accountInfo, t } = this.props;
    let { thread, dm, forum_id, host_uid, doc_id } = this.props.modalProps;
    let {doc_info} = this.state;

    let participants = _.filter(this.props.threadParticipantLookup[thread.chat_id], (user) => {
      return user.guest_uid !== accountInfo.uid;
    })

    if(participants.length === 0){
      this.props.showAlert(t('No Guests in Thread'), t("There are no Guests in this Thread.  To request a signature, please add a Guest first."));
      return;
    }

    log.log('initialize thread sig request', sigRequest);
    if(msgHelper.isSigningStatusIncomplete(sigRequest.status) || msgHelper.isSigningStatusNone(sigRequest.status)){
      this.props.showRequestSignatureWindow(
        forum_id,
        thread.chat_id,
        sigRequest.mesg_id,
        doc_id,
        doc_info.label,
        participants,
        null,
        null,
        false,
        (res) => {
          if (res) {
            let guestInfos = [];
            _.each(res, (guestRes) => {
              let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === guestRes.guest_uid});
              if(guest){
                guestInfos.push(guest);
              }
            })

            sigRequest.setUseCancelLanguage(false);
            sigRequest.setSigners(res, guestInfos);
            sigRequest.setDocInfo(doc_info);

            this.props.pdfActions.loadSignatureRequestData(false, sigRequest);
          }
        }
      )
    }
    else{
      this.props.showConfirm(t('Unable to initiate Signature Request'),
        t("There is an active signature request on this document. To make a new signature request, please re-attach the document."),
        (res) => {
          if(res){
            //then close and re-attach to current thread.
            this.props.pdfState.pdfWriter.getUintPdfData()
              .then((res) => {
                this.closeAndReattachDocument(null, forum_id, thread.chat_id, doc_id, doc_info.label, res);
              })
          }
        },
        t('Re-attach'),
        t('Cancel'));
    }
  }

  initializeDMSignatureRequest(sigRequest){
    let { t } = this.props;
    let { dm, doc_id } = this.props.modalProps;
    let {doc_info} = this.state;

    log.log('initialize thread sig request', sigRequest);
    if(msgHelper.isSigningStatusIncomplete(sigRequest.status) || msgHelper.isSigningStatusNone(sigRequest.status)){
      this.props.showDMRequestSignatureWindow(
        dm.guest_uid,
        sigRequest.mesg_id,
        doc_id,
        doc_info.label,
        [dm],
        null,
        null,
        false,
        (res) => {
          if (res) {
            sigRequest.setUseCancelLanguage(false);
            sigRequest.setSigners(res, [dm]);
            sigRequest.setDocInfo(doc_info);
            this.props.pdfActions.loadSignatureRequestData(false, sigRequest);
          }
        }
      )
    }
    else{
      this.props.showConfirm(t('Unable to initiate Signature Request'),
        t("There is an active signature request on this document. To make a new signature request, please re-attach the document."),
        (res) => {
          if(res){
            //then close and re-attach to current thread.
            this.props.pdfState.pdfWriter.getUintPdfData()
              .then((res) => {
                this.closeAndReattachDocument(dm.guest_uid, null, null, doc_id, doc_info.label, res);
              })
          }
        },
        t('Re-attach'),
        t('Cancel'));
    }
  }

  onInitiateSignatureRequestClick(){
    let { t } = this.props;
    let { thread, host_uid } = this.props.modalProps;

    this.buildSignatureRequest()
      .then((sigRequest) => {
        if(thread) {
          this.initializeThreadSignatureRequest(sigRequest);
        }
        else{
          this.initializeDMSignatureRequest(sigRequest);
        }
      })
      .catch((err) => {
        log.error('error building signature request', err);
        this.props.showAlert(t('Unable to initiate Signature Request'), t("There was a problem initiating your signature request.  Please try again."));
      })
  }

  onEscapeKey(){
    if(this.state.panelRef){
      this.state.panelRef.onEscapeKey();
    }
  }

  render() {
    let {
      loading,
      displayMode,
    } = this.state;
    let { t } = this.props;

    let content = null;
    if (loading) {
      content = <div className="modal-content">
        <div className="modal-body">
          <Loading centered size={'sm'}/>
        </div>
      </div>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.IMG_PREVIEW) {
      content = <ImgPreview {...this.props.modalProps}
                            onRef={(ref) => this.setState({panelRef : ref})}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.props.close}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.PDF_PREVIEW) {
      content = <PdfPreview {...this.props.modalProps}
                            onRef={(ref) => this.setState({panelRef : ref})}
                            shownInSignatureRequestContext={false}
                            onInitiateSignatureRequest={this.onInitiateSignatureRequestClick.bind(this)}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.doCloseFromPreview.bind(this)}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.TEXT_PREVIEW) {
      content = <TextPreview {...this.props.modalProps}
                             onRef={(ref) => this.setState({panelRef : ref})}
                             setWindowMode={this.setWindowMode.bind(this)}
                             doc_info={this.state.doc_info}
                             close={this.props.close}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.SVG_PREVIEW) {
      content = <SvgPreview {...this.props.modalProps}
                            onRef={(ref) => this.setState({panelRef : ref})}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.props.close}/>
    }
    return (
      content
    )
  }
}

const mapStateToProps = (state) => {
  return {
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    accountInfo : state.shared.accountInfo,
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
    sharedActions : {...sharedActions.mapToDispatch(dispatch)}
  };
};

PreviewWindow.MODAL_XL = true;

PreviewWindow.WINDOW_MODES = {
  INFO : 'info',
  IMG_PREVIEW : 'img.preview',
  PDF_PREVIEW : 'pdf.preview',
  TEXT_PREVIEW : 'text.preview',
  SVG_PREVIEW : 'svg.preview'
}

PreviewWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PreviewWindow));
