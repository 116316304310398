import moment from 'moment';
import _ from 'lodash';
import LocaleCurrency from 'locale-currency'

let parseDate = (val) => {
  return new Date(+val * 1000)
}

let momentFilter = (val, format) => {
  if(!val){
    return null;
  }

  return moment(parseDate(val)).format(format);
}

let momentUTCFilter = (val, format) => {
  if(!val){
    return null;
  }

  return moment(parseDate(val)).utc().format(format);
}

let momentDate = (val) => {
  if(!val){
    return null;
  }

  return moment(parseDate(val));
}

let getDisplayCurrency = (lng, centsVal) => {

  let val = +centsVal;
  if(_.isNaN(val)){
    return "";
  }
  let currency = LocaleCurrency.getCurrency(lng);
  let formatOpts = {
    style: 'currency',
    currency
  }
  if(centsVal % 100 === 0){
    formatOpts.maximumFractionDigits = 0;
    formatOpts.minimumFractionDigits = 0;
  }

  //We basically want to format by currency, but then still show the dollar as the currency.
  return Intl.NumberFormat(lng, formatOpts)
    .formatToParts(val / 100)
    .map(({type, value}) => {
      switch (type) {
        case 'currency':
          return `$`;
        default :
          return value;
      }
    }).reduce((string, part) => string + part);
}

let formatCurrency = (locale, currencySymbol, centsVal) => {
  let val = +centsVal;
  if(_.isNaN(val)){
    return "";
  }
  return new Intl.NumberFormat(locale, { style: "currency", currency: currencySymbol }).format(val);
}

let getCurrency = (lng, centsVal, noDecimal) => {

  let val = +centsVal;
  if(_.isNaN(val)){
    return "";
  }
  let currency = LocaleCurrency.getCurrency(lng);
  let formatOpts = {
    style: 'currency',
    currency
  }
  if(noDecimal){
    formatOpts.maximumFractionDigits = 0;
    formatOpts.minimumFractionDigits = 0;
  }

  //We basically want to format by currency, but then still show the dollar as the currency.
  return Intl.NumberFormat(lng, formatOpts)
    .formatToParts(val / 100)
    .map(({type, value}) => {
      switch (type) {
        case 'currency':
          return `$`;
        default :
          return value;
      }
    }).reduce((string, part) => string + part);
}

let getFriendlyDate = (t, date) => {
  let thisDate = moment(parseDate(date));

  let today = moment().startOf('d');
  let yesterday = moment(today).subtract(1, 'd').startOf('d');
  let withinOneWeek = moment(today).subtract(7, 'd').startOf('d');
  if (moment(thisDate).isSame(today, 'day')) {
    return thisDate.format('LT')
  }
  else if (moment(thisDate).isSame(yesterday, 'day')) {
    return t('Yesterday ') + thisDate.format('LT')
  }
  else if (moment(thisDate).isAfter(withinOneWeek, 'day')) {
    return thisDate.format('dddd LT')
  }
  else {
    return thisDate.format('lll')
  }
}

let getFriendlyTermLength = (t, dayCount) => {
  var week = 7;
  var month = 30;
  var year = 365;

  if ((dayCount >= 0) && (dayCount < week)) {
    if(dayCount === 1){
      return t('day');
    }
    else{
      return dayCount + t(' days');
    }
  }
  else if ((dayCount >= week) && (dayCount < month)) {
    let val = (dayCount / week);
    if(val === 1){
      return t('week');
    }
    else if(dayCount % week === 0){
      return val + t(' weeks');
    }
    else{
      return val.toFixed(1) + t(' weeks');
    }
  }
  else {
    let val = (dayCount / month);
    if(val === 1){
      return t('month');
    }
    else if(dayCount % month === 0){
      return val + t(' months');
    }
    else{
      return val.toFixed(1) + t(' months');
    }
  }
  // else {
  //   let val = (dayCount / year);
  //   if(val === 1){
  //     return t('year');
  //   }
  //   else if(dayCount % year === 0){
  //     return val + t(' years');
  //   }
  //   else{
  //     return val.toFixed(1) + t(' years');
  //   }
  // }
}

let getFileSizeString = (t, bytes, precision) => {
  bytes = parseInt(bytes, 10);

  var kilobyte = 1024;
  var megabyte = kilobyte * 1024;
  var gigabyte = megabyte * 1024;
  var terabyte = gigabyte * 1024;

  if ((bytes >= 0) && (bytes < kilobyte)) {
    return bytes + t(' B');
  } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
    return (bytes / kilobyte).toFixed(precision) + t(' KB');
  } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
    return (bytes / megabyte).toFixed(precision) + t(' MB');
  } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
    return (bytes / gigabyte).toFixed(precision) + t(' GB');
  } else if (bytes >= terabyte) {
    return (bytes / terabyte).toFixed(precision) + t(' TB');
  } else {
    return bytes + t(' B');
  }
}

export default {
  formatCurrency,
  getCurrency,
  getDisplayCurrency,
  momentFilter,
  momentUTCFilter,
  getFriendlyDate,
  getFriendlyTermLength,
  getFileSizeString,
  momentDate
}
