import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import {getMessageForError} from "../../../../util/errors";
import {validateEmail} from "../../../../util/validation";
import modalActions from "../../../../actions/modal-actions";
import Promise from "bluebird";
import api from "../../../../util/api";
import _ from "lodash";
import c from "../../../../util/const";
import utils from "../../../../util/util";
import VFPopover from "../../components/VFPopover";
import popoverActions from "../../../../actions/popover-actions";
import popupHelper from "../../../../helpers/popup-helper";

class ChangeEmailCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      new_email : '',
      confirmation_code : '',
      showConfirmationCode : true,
      validation_errors: [],
      isSendingCode: false,
      isConfirmingEmail: false,
      showConfirmSuccess: false,

      emailValidationStatus : null,
      needConfirmEmailClickThrough : false,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    this.setState({
      new_email : '',
      confirmation_code : '',
      showConfirmationCode : false,
      validation_errors: [],
      isSendingCode: false,
      isConfirmingEmail: false,
      showConfirmSuccess: false
    })
  }

  doValidateEmailAddress(email){
    return new Promise((resolve, reject) => {
      api.AccountEmail.validateEmail(email)
        .then((res) => {
          log.log('validate email res', res);
          let validateEmailRes = res.data;
          this.setState({
            emailValidationStatus : validateEmailRes,
            validatedEmailAddress : email,
          }, () => resolve(validateEmailRes))
          let didYouMeanSuggestion = _.get(validateEmailRes, 'did_you_mean');
          if(didYouMeanSuggestion){
            this.props.popoverAction.showPopover(c.popovers.DID_YOU_MEAN_MENU)
          }
        })
        .catch((err) => {
          log.log('error during email validation', err);
          resolve(null);
        })
    })
  }

  needValidateEmailAddress(){
    let {
      needConfirmEmailClickThrough
    } = this.state;

    //basic email form validation has been done, so the only needed
    //check here is the click through
    if(!needConfirmEmailClickThrough) {
      return true;
    }

    return false;
  }

  doSendCodeValidation(){
    let {
      new_email,
    } = this.state;
    let { t } = this.props;

    let err = [];

    if(!validateEmail(new_email)){
      err.push(t("Please enter your email address"));
    }

    this.setState({validation_errors : err})

    return err.length === 0;
  }

  doConfirmEmailValidation(){
    let {
      confirmation_code,
    } = this.state;
    let { t } = this.props;

    let err = [];

    if(!confirmation_code || confirmation_code.length === 0){
      err.push(t("Please enter your confirmation code"));
    }

    this.setState({validation_errors : err})

    return err.length === 0;
  }

  doSendCodeClick(){
    if(!this.doSendCodeValidation()){
      return;
    }

    this.doSendCode();
  }

  doSendCode(){
    let { t } = this.props;
    let {
      new_email
    } = this.state;

    let emailValidationPromise = null;
    if(this.needValidateEmailAddress()){
      log.log('need to validate email', new_email);
      emailValidationPromise = this.doValidateEmailAddress(new_email)
    }
    else{
      emailValidationPromise = Promise.resolve(true);
    }

    emailValidationPromise
      .then((emailValidationRes) => {

        log.log('email validate res', emailValidationRes);
        if (utils.hasEmailValidationInvalidFlag(emailValidationRes)) {
          this.setState({needConfirmEmailClickThrough: true});
          return false;
        }

        this.setState({isSendingCode: true})
        return sapi.AccountEmail.addEmail(new_email)
      })
      .then((res) => {

        if(!res){
          return;
        }

        if(this.state.needConfirmEmailClickThrough){
          sapi.AccountEmail.validationStatus(new_email, true)
          this.setState({needConfirmEmailClickThrough: false});
        }

        log.log('add email res', res);
        this.setState({
          isSendingCode : false,
          showConfirmationCode : true
        })
      })
      .catch((err) => {
        log.log('error adding email', err);
        this.setState({
          validation_errors : [ getMessageForError(err, t) ],
          isSendingCode : false
        })

      })
  }

  doConfirmEmailClick(){
    if(!this.doConfirmEmailValidation()){
      return;
    }

    let { t } = this.props;
    let {
      new_email,
      confirmation_code
    } = this.state;
    this.setState({isConfirmingEmail : true})
    sapi.AccountEmail.confirm(new_email, confirmation_code)
      .then((res) => {
        log.log('email confirm res', res);
        this.setState({
          isConfirmingEmail : false,
          showConfirmSuccess : true
        }, () => {
          this.doSuccess();
        })
      })
      .catch((err) => {
        log.log('error confirming email', err);
        this.setState({
          validation_errors : [ getMessageForError(err, t) ],
          isConfirmingEmail : false
        })
      })
  }

  showResendEmailDialog(){
    this.props.showResendConfirmationEmailDialog(this.state.new_email, (res) => {
      log.log('resend email finished', res);
    })
  }

  renderDidYouMeanContents(popupArgs) {
    let { t } = this.props;
    let { emailValidationStatus } = this.state;
    let didYouMeanSuggestion = _.get(emailValidationStatus, 'did_you_mean');
    if (didYouMeanSuggestion) {
      let useItClick = () => {
        this.setState({
          needConfirmEmailClickThrough: false,
          new_email : didYouMeanSuggestion
        })
        this.props.popoverAction.hidePopover();
      }
      let loseItClick = () => {
        this.props.popoverAction.hidePopover()
      }

      return popupHelper.getDidYouMeanContents(
        t,
        didYouMeanSuggestion,
        popupArgs,
        () => useItClick(),
        () => loseItClick()
      )
    }
  }

  doSuccess(){
    //show success for a sec
    this.props.updateAccountInfo();
    setTimeout(() => {
      this.props.doClose();
    }, 2000)
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  renderEmailValidationWarningMessage(){
    let { t } = this.props;
    return (
      <div className="alert alert-info mb-0" role="alert">
        <h5 className="alert-heading mb-1">
          <i className="icon ion-information-circled pr-2" />
          {t("Informational")}
        </h5>
        <p style={{fontSize: '14px'}}>
          {t("The email address you entered may contain a typo, or may be an invalid email address. Please double-check what you’ve entered for errors.") + " " + t("Tap \"Send Code Anyway\" if the email address is correct.")}
        </p>
      </div>
    )
  }

  render() {
    let {
      new_email,
      confirmation_code,
      showConfirmationCode,
      isConfirmingEmail,
      isSendingCode,
      showConfirmSuccess,
      needConfirmEmailClickThrough,
      validation_errors
    } = this.state;
    let { t } = this.props;

    return (
      <div>
        <div style={Account.styles.wideRowContents}>
          <div className="row">
            <div className="col">
              {t("To change the email address you use for logging in and receiving alerts, enter the address you want to use and click \"Send Code.\" A Confirmation Code will be sent to the new email address.")}
            </div>
          </div>
        </div>
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("New Email Address")}</label>
                <VFPopover
                  isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DID_YOU_MEAN_MENU)}
                  positions={['right', 'bottom']}
                  reposition={false}
                  containerClassName="preview-container-cls copied-popover-container"
                  onClickOutside={() => this.props.popoverAction.hidePopover()}
                  getMenuContent={this.renderDidYouMeanContents.bind(this)}>
                <input className={'form-control'}
                       type={'email'}
                       value={new_email}
                       disabled={showConfirmationCode}
                       onChange={(evt) => this.setState({new_email: evt.target.value})}
                       placeholder={t('Enter desired email address')} />
                </VFPopover>
              </div>
            </div>
          </div>

          {!showConfirmationCode &&
          <div>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'text-right'}>
                  <Button disabled={isSendingCode}
                          className={'btn btn-secondary mr-2'}
                          onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
                  <Button disabled={isSendingCode || new_email.length === 0}
                          className={'btn btn-primary '}
                          onClick={this.doSendCodeClick.bind(this)}>
                    {isSendingCode &&
                      <Loading centered={true}
                               color="light"
                               size={'sm'}/>
                    }
                    {!isSendingCode &&
                      needConfirmEmailClickThrough ? t("Send Code Anyway") : t("Send Code")
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>
          }
        </div>

        {needConfirmEmailClickThrough && this.renderEmailValidationWarningMessage()}

        {showConfirmationCode &&
        <div style={Account.styles.wideRowContents} className={'mt-3 mb-3'}>
          <div className="row">
            <div className="col green-color">
              <i className="icon ion-android-checkmark-circle mr-2"/>
              {t("One more step! Check the new email account for a confirmation email and enter the code below.")}
            </div>
          </div>
        </div>
        }
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          {showConfirmationCode &&
          <div>
            <div className={'row'}>
              <div className={'col'}>
                <div className="form-group">
                  <label>{t("Confirmation Code")}</label>
                  <input className={'form-control'}
                         type={'tel'}
                         value={confirmation_code}
                         onChange={(evt) => this.setState({confirmation_code: evt.target.value})}
                         placeholder={t('e.g. 12345678')}/>
                </div>
              </div>
            </div>
          </div>
          }

          {validation_errors.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validation_errors}/>
            </div>
          </div>
          }

          {showConfirmationCode &&
          <div className={'row'}>
            <div className={'col d-flex'}>
              <div className="text-left">
                <Button disabled={isConfirmingEmail} className={'btn btn-link'}
                        onClick={this.showResendEmailDialog.bind(this)}>{t("Didn't get email")}</Button>
              </div>
              <div className={'text-right flex-fill'}>
                <Button disabled={isConfirmingEmail} className={'btn btn-secondary mr-2'}
                        onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>

                {!showConfirmSuccess &&
                <Button disabled={isConfirmingEmail} className={'btn btn-primary'}
                        onClick={this.doConfirmEmailClick.bind(this)}>
                  {isConfirmingEmail &&
                  <Loading centered={true}
                           color="light"
                           size={'sm'}/>
                  }
                  {!isConfirmingEmail &&
                  t("Confirm Email")
                  }
                </Button>
                }
                {showConfirmSuccess &&
                <Button disabled={true} className={'btn btn-success'}>
                  <i className="icon ion-android-checkmark-circle mr-2"/>
                  {t("Success!")}
                </Button>
                }

              </div>
            </div>
          </div>
          }

        </div>
      </div>
    )
  }
}

ChangeEmailCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    ...modalActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeEmailCtrl)));
