import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import classnames from 'classnames'
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {connect} from "react-redux";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {getMessageForError} from "../../../util/errors";
import colors from "../../../util/colors";
import ExpandableRow from "../components/ExpandableRow";
import Promise from "bluebird";
import Image from "../elements/Image";
import DownloadHelper from "../components/DownloadHelper";
import log from "../../../util/log";
import _ from "lodash";
import Button from "../elements/Button";
import utils from "../../../util/util";
import c from "../../../util/const";
import sharedActions from "../../../actions/shared-actions";
import {Element } from 'react-scroll'
import homeActions from "../../../actions/home-actions";
import downloadActions from "../../../actions/download-actions";
import { isMobile } from 'react-device-detect';
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import { DragSource } from 'react-dnd'
import {withVFTranslation} from "../../../util/withVFTranslation";
import msgHelper from "../../../helpers/msg-helper"
import threadActions from "../../../actions/thread-actions";
import popupHelper from "../../../helpers/popup-helper";
import popoverActions from "../../../actions/popover-actions";
import VFPopover from "../components/VFPopover";
import memoizeOne from "memoize-one";
import SignatureRequest from "../../../models/SignatureRequest";

class DocRow extends PureComponent {

  constructor(props){
    super(props)

    this.itemClick = this.itemClick.bind(this);
    this.itemClickMenu = this.itemClickMenu.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.downloadDoc = this.downloadDoc.bind(this);
    this.downloadDocMenu = this.downloadDocMenu.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.showPreviewMenu = this.showPreviewMenu.bind(this);
    this.setNotifyFlagMenu = this.setNotifyFlagMenu.bind(this);

    this.state = {
      isPreviewable: utils.isPreviewable(props.row.label),
      hovering : false,

      thumbnailDocId : null,
      thumbnail : null,
      loadingThumbnailDocId : null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(_.get(prevProps, 'row.label') !== _.get(this.props, 'row.label')){
      this.setState({
        isPreviewable: utils.isPreviewable(_.get(this.props, 'row.label')),
      })
    }
    if(_.get(prevProps, 'isSelected') !== _.get(this.props, 'isSelected')){
      if(this.props.isSelected){
        this.itemClick();
      }
    }
  }

  itemClick(evt){
    let { row} = this.props;

    if(row.notify_flag){
      this.setNotifyFlag(false, evt);
    }
    this.showThumbnailMenu();
  }

  itemClickMenu(evt){
    this.itemClick(evt);
  }

  onMouseEnter(){
    if(this.state.hovering){
      return;
    }

    this.setState({
      hovering : true
    })
  }

  onMouseLeave(){
    this.setState({hovering : false})
  }

  showMenu(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    let {
      row
    } = this.props;
    this.props.popoverAction.showPopover(c.popovers.DOC_GEAR_MENU, row.doc_id);
  }

  hidePopover(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.props.popoverAction.hidePopover();
  }

  getDownloadHeaders() {
    let {workspace, dm, row, isOutsideCurrentContext} = this.props;

    let params = {
      doc_id : row.doc_id,
    }

    if(dm && !isOutsideCurrentContext){
      if (dm && dm.forum_id) {
        params.forum_id = dm.forum_id
      }
      if (dm.host_uid) {
        params.host_uid = dm.host_uid;
      }
    }
    else{
      if (workspace && workspace.forum_id) {
        params.forum_id = workspace.forum_id
      }
      if (workspace && workspace.host_uid) {
        params.host_uid = workspace.host_uid;
      }
    }

    params[c.api.X_TOKEN] = sapi.getToken();

    return params;
  }

  fetchThumbnailIfNeeded(){
    let { row } = this.props;
    let { thumbnailDocId, isPreviewable } = this.state;

    if(isPreviewable && row.doc_id !== thumbnailDocId){
      this.setState({loadingThumbnailDocId : row.doc_id});
      this.fetchThumbnail()
        .then((res) => {
          this.setState({
            thumbnail : res,
            thumbnailDocId : row.doc_id
          })
        })
        .finally(() => {
          this.setState({loadingThumbnailDocId : null});
        })
    }
  }

  fetchThumbnail() {
    let {row, workspace, dm, isOutsideCurrentContext} = this.props;

    return new Promise((resolve, reject) => {

      if(dm && !isOutsideCurrentContext){
        sapi.Docs.thumbnail(dm.forum_id, dm.host_uid, row.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
      else if(workspace){
        sapi.Docs.thumbnail(workspace.forum_id, workspace.host_uid, row.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
      else{
        reject('No workspace or DM present');
      }
    })
  }

  downloadDocMenu(evt){
    this.downloadDoc(evt);
  }

  downloadDoc(evt){
    this.hidePopover(evt);
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));

    let { row} = this.props;
    if(row.notify_flag) {
      this.setNotifyFlag(false, evt);
    }
  }

  showDocumentHistory(evt){
    this.hidePopover(evt);

    let { workspace, dm, row, isOutsideCurrentContext } = this.props;

    let params = {};
    if(dm && !isOutsideCurrentContext){
      params.guest_uid = dm.guest_uid;
    }
    else{
      params.forum_id = workspace.forum_id;
      params.host_uid = workspace.host_uid;
    }
    params.doc_id = row.doc_id;

    this.props.showDocViewHistory(params, () => {
      log.log('history window closed');
    })
  }

  showRename(evt){
    let { workspace, row, thread, t } = this.props;

    this.hidePopover(evt);

    let doRename = (label) => {
      return sapi.Threads.docRename(workspace.forum_id, row.doc_id, label);
    }

    this.props.showRename(t('Rename Document'), row.label, doRename, (res) => {
      if(res){

        this.props.refreshDocs(workspace.forum_id, workspace.host_uid)
          .then(() => {
            this.doDMMessageUpdate();
            if(thread){
              this.props.threadAction.refreshActiveThreadMessages();
            }
          })
      }
    })
  }

  setNotifyFlagMenu(notify_flag, evt){
    this.setNotifyFlag(notify_flag, evt);
  }

  setNotifyFlag(notify_flag, evt){
    let { row, thread, workspace, showAlert, isOutsideCurrentContext, dm, t } = this.props;

    this.hidePopover(evt);

    if(dm && !isOutsideCurrentContext){
      sapi.Docs.mark(dm.forum_id, dm.host_uid, row.doc_id, notify_flag)
        .then((res) => {
          this.doDMMessageUpdate();
        })
        .catch((err) => {
          showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
    else if(workspace){
      sapi.Docs.mark(workspace.forum_id, workspace.host_uid, row.doc_id, notify_flag)
        .then((res) => {
          this.props.refreshDocs(workspace.forum_id, workspace.host_uid)
            .then(() => {
              if(thread){
                this.props.threadAction.refreshActiveThreadMessages();
              }
            })
        })
        .catch((err) => {
          showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
  }

  showPreviewMenu(evt){
    this.showPreview(evt);
  }

  doDMMessageUpdate(){
    let {
      workspace,
      row,
      thread,
      dm,
      isOutsideCurrentContext
    } = this.props;

    log.log('doc row dm message update', workspace, dm, thread);

    this.props.threadAction.refreshActiveThreadMessages();
    this.props.threadAction.refreshDocsDM();
  }

  getDocLabel(){
    let { row } = this.props;
    return row.label;
  }

  showPreview(evt){

    this.hidePopover(evt);
    let {
      workspace,
      row,
      thread,
      dm,
      isOutsideCurrentContext
    } = this.props;

    if(thread){
      this.props.showDocInfoThread(workspace.forum_id, workspace.host_uid, row.doc_id, thread, (res) => {
        log.log('preview window closed', res);
        if(res){
          this.props.threadAction.refreshActiveThreadMessages();
          if(res.isPdfSubmitResult) {
            this.props.onPdfSubmit(res);
          }
        }
      })
    }
    else if(dm){
      let forum_id = null;
      let host_uid = null;
      if(isOutsideCurrentContext){
        forum_id = workspace.forum_id;
        host_uid = workspace.host_uid;
      }
      else{
        forum_id = dm.forum_id;
        host_uid = dm.host_uid;
      }

      this.props.showDocInfoDM(forum_id, host_uid, row.doc_id, dm, (res) => {
        log.log('preview window closed', res);
        if(res){

          this.doDMMessageUpdate();
          if(res.isPdfSubmitResult) {
            this.props.onPdfSubmit(res);
          }
        }
      })
    }
    else{
      this.props.showDocInfo(workspace.forum_id, workspace.host_uid, row.doc_id, (res) => {
        log.log('preview window closed', res);
        if(res && res.isPdfSubmitResult) {
          this.props.onPdfSubmit(res);
        }
      })
    }
    if(row.notify_flag) {
      this.setNotifyFlag(false, evt);
    }
  }

  showDownloadMultiple(evt){
    this.hidePopover(evt);

    let {
      row,
      workspace,
      dm,
      thread,
      isOutsideCurrentContext
    } = this.props;


    let initial_selection_forum_id = null;
    let initial_selection_guest_uid = null;
    let initial_selection_chat_id = null;
    if(isOutsideCurrentContext){
      initial_selection_forum_id = workspace.forum_id;
    }
    else if(dm){
      initial_selection_guest_uid = dm.guest_uid;
    }
    else if(workspace){
      initial_selection_forum_id = workspace.forum_id;
    }
    else{
      console.error('what context is this?');
      return;
    }

    if(thread){
      initial_selection_chat_id = thread.chat_id;
    }

    this.props.showDownloadMultiple(initial_selection_forum_id, initial_selection_guest_uid, initial_selection_chat_id , [row.doc_id], () => {
      log.log('show download multiple closed')
    })
  }

  attachToThreadClick(evt){
    this.hidePopover(evt);

    let {
      row,
      workspace,
      dm,
      isOutsideCurrentContext,
      accountInfo
    } = this.props;

    //NOTE: yes, it's a little weird that we set src_host_uid if it doesn't exist on the workspace/dm.
    //The attach call requires it as of 4/23/20
    //There's tricky logic here that doesn't quite match the if/else.
    //If this doc is:
    //1. in a DM on the home page - workspace not set.  isOutsideContext not possible.  Source is dm
    //2. in a DM in a workspace - workspace is set.  dm is set.  isOutsideContext is set.  Source is workspace (because of outside context)
    //3. in a DM in a workspace - workspace is set.  dm is set.  is OutsideContext is NOT set.  Source is dm.
    //4. in a thread in a workspace - workspace is set.  dm not set.  isOutsideContext could change, but does not matter here.  Source is workspace.
    let src_forum_id = null;
    let src_host_uid = null;
    let initial_selection_forum_id = null;
    let initial_selection_guest_uid = null;
    if(isOutsideCurrentContext){
      src_forum_id = workspace.forum_id;
      src_host_uid = workspace.host_uid || accountInfo.uid;
      initial_selection_forum_id = src_forum_id;
    }
    else if(dm){
      src_forum_id = dm.forum_id;
      src_host_uid = dm.host_uid || accountInfo.uid;
      initial_selection_guest_uid = src_forum_id;
    }
    else if(workspace){
      src_forum_id = workspace.forum_id;
      src_host_uid = workspace.host_uid || accountInfo.uid;
      initial_selection_forum_id = src_forum_id;
    }
    else{
      console.error('what context is this?');
      return;
    }

    this.props.showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, row, (res) => {
      log.log('show attach doc window', res);
      if(res){
        this.props.onAttachToThread(res);
      }
    })
  }

  getPopoverContent(){
    let { row, workspace, dm, isOutsideCurrentContext, t } = this.props;
    let { isPreviewable } = this.state;

    if(dm && !isOutsideCurrentContext){
      return (
        <div>
          <ul className="popover-content list-group">
            <a onClick={this.itemClickMenu.bind(this)}
               style={styles.menuHeader}
               className="list-group-item list-group-item-action">
              <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
              {row.label}
            </a>

            <a onClick={this.downloadDocMenu}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-android-download"/>
              {t("Download Document")}
            </a>
            {isPreviewable &&
            <a onClick={this.showPreviewMenu}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-eye"/>
              {t("Preview Document")}
            </a>
            }

            <a onClick={this.showDocumentHistory.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
              {t("View Access History")}
            </a>

            <a onClick={this.attachToThreadClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-paperclip"/>
              {t("Send to...")}
            </a>

            <a onClick={this.showDownloadMultiple.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
              {t("Download Multiple Documents")}
            </a>

            {!row.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, true)}
               style={!workspace ? styles.menuItemBottom : styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
              {t("Mark as Unread")}
            </a>
            }
            {row.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, false)}
               style={!workspace ? styles.menuItemBottom : styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
              {t("Mark as Read")}
            </a>
            }
          </ul>
        </div>
      )
    }

    let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
    let isHost = workspace && !workspace.host_uid;
    return (
      <div>
        <ul className="popover-content list-group">
          <a onClick={this.itemClickMenu}
             style={styles.menuHeader}
             className="list-group-item list-group-item-action">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
            {row.label}
          </a>

          {isHost && !isContentWorkspace &&
            <a onClick={this.showRename.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-compose"/>
              {t("Rename Document")}
            </a>
          }

          {isHost && !isContentWorkspace &&
          <a onClick={this.showDocumentHistory.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
            {t("View Access History")}
          </a>
          }

          {workspace &&
          <a onClick={this.attachToThreadClick.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-paperclip"/>
            {t("Send to...")}
          </a>
          }

          <a onClick={this.downloadDocMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-download"/>
            {t("Download Document")}
          </a>
          {isPreviewable &&
          <a onClick={this.showPreviewMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("Preview Document")}
          </a>
          }
          <a onClick={this.showDownloadMultiple.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
            {t("Download Multiple Documents")}
          </a>

          {!row.notify_flag &&
          <a onClick={this.setNotifyFlagMenu.bind(this, true)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
            {t("Mark as Unread")}
          </a>
          }
          {row.notify_flag &&
          <a onClick={this.setNotifyFlagMenu.bind(this, false)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
            {t("Mark as Read")}
          </a>
          }
        </ul>
      </div>
    )
  }

  showThumbnailMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    let {
      row
    } = this.props;
    let didOpen = this.props.popoverAction.showPopover(c.popovers.DOC_THUMBNAIL_PREVIEW, row.doc_id);
    if(didOpen){
      this.fetchThumbnailIfNeeded();
    }
    else{
      this.hidePreviewMenu(evt);
    }
  }

  hidePreviewMenu(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
    setTimeout(() => {
      this.setState({
        thumbnail : null,
        thumbnailDocId : null,
        loadingThumbnailDocId : null,
      })
    }, VFPopover.ANIMATION_TIME_MS)
  }

  findMessageForDoc = memoizeOne((doc, messageList) => {
    let found = null;
    if(doc){
      _.each(doc.mesg_id, (id) => {
        let msgToSearch = +id;
        found = _.find(messageList, (msg) => +msgToSearch === +msg.mesg_id);
        if(found){
          return false;
        }
      })
    }
    return found;
  })

  findDoc = memoizeOne((msg, doc_id) => {
    if(msg && msg.docs && msg.docs.length > 0) {
      return _.find(msg.docs, (doc) => doc.doc_id === doc_id);
    }
    return null;
  })

  areYouTheNextSigner = memoizeOne((doc, accountInfoGuest) => {
    let nextUserToSignIsYou = false;
    if(doc && doc.signer_info && accountInfoGuest) {
      let nextRequestedSignInfo = _.find(doc.signer_info, (info) => info.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED)
      nextUserToSignIsYou = nextRequestedSignInfo && nextRequestedSignInfo.signer_uid === accountInfoGuest.guest_uid;
    }
    return nextUserToSignIsYou;
  })

  getPreviewMenuContent({position, childRect, popoverRect}){
    let {
      thread,
      dm,
      row,
      accountInfoGuest,
      t
    } = this.props;
    let {thumbnail, loadingThumbnailDocId} = this.state;

    let label = this.getDocLabel();

    let messageList = [];
    if(thread){
      messageList = this.props.activeThreadMessages;
    }
    else if(dm){
      messageList = this.props.activeDMMessages;
    }
    let msg = this.findMessageForDoc(row, messageList);
    let foundDoc = this.findDoc(msg, row.doc_id);
    let nextUserToSignIsYou = this.areYouTheNextSigner(foundDoc, accountInfoGuest);

    let data = {
      thumbnail,
      isLoadingThumbnail : loadingThumbnailDocId,
      label,
      nextUserToSignIsYou
    }

    let popupArgs = {
      position,
      childRect,
      popoverRect
    }

    let controls = {
      t,
      hideMenu : this.hidePreviewMenu.bind(this),
      onPreviewClick : this.showPreview.bind(this),
      onDownloadClick : this.downloadDoc.bind(this),
      fulfillSignatureRequestClick : () => this.props.fulfillSignatureRequestClick(msg, foundDoc)
    }

    return popupHelper.getThumbnailPreviewContent(popupArgs, data, controls)
  }

  render() {
    let {
      row,
      thread,
      isOutsideCurrentContext,
      t
    } = this.props;
    let {
      hovering,
    } = this.state;

    return (
      <div id={`doc-${row.doc_id}`}>
        <VFPopover onClickOutside={this.hidePreviewMenu.bind(this)}
                   positions={['left', 'top', 'bottom']}
                   reposition={true}
                   isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DOC_THUMBNAIL_PREVIEW, row.doc_id)}
                   align="start"
                   containerClassName="preview-container-cls"
                   getMenuContent={this.getPreviewMenuContent.bind(this)}>
          <div onClick={this.itemClick}
               onMouseEnter={this.onMouseEnter}
               onMouseLeave={this.onMouseLeave}
               className={classnames("d-flex flex-row doc-row pt-1 pb-2 item-row-wrap doc-row-wrap")}>
            <div style={styles.iconRowStyle}>
              <p>
                <i className={'icon ion-record ' + (row.notify_flag ? ' primary-color' : ' transparent-color')}/>
              </p>
            </div>
            <div className="flex-grow-1">
              <p className="mb-0 doc-label">
                {row.label}
                <VFPopover
                  isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DOC_GEAR_MENU, row.doc_id)}
                  positions={['left', 'top', 'bottom']}
                  reposition={true}
                  onClickOutside={this.hidePopover.bind(this)}
                  getMenuContent={this.getPopoverContent.bind(this)}>
                  <span style={{lineHeight: '18px'}}
                        className="pl-2 d-inline-block"
                        onClick={this.showMenu.bind(this)}>
                    <i style={styles.gearIcon}
                       className={classnames("icon item-menu-icon ion-gear-b", {'invisible': !isMobile && !hovering})}/>
                  </span>
                </VFPopover>
              </p>
              <p style={styles.updateDate} className="doc-preview mb-0 secondary-text-color">
                {t("Last Updated:")} {filters.getFriendlyDate(t, row.updated_date)}
              </p>
              {isOutsideCurrentContext && thread &&
                <p style={styles.updateDate} className="doc-preview mb-0 secondary-text-color">
                  <i className="icon ion-chatbox"/> {thread.label}
                </p>
              }
            </div>
          </div>
        </VFPopover>
      </div>
    )
  }
}

const styles = {
  updateDate : {

  },

  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },
}

DocRow.propTypes = {
  thread : PropTypes.object,
  dm : PropTypes.object,
  workspace : PropTypes.object,
  row: PropTypes.object.isRequired,

  isSelected : PropTypes.bool,
  isOutsideCurrentContext : PropTypes.bool.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  onAttachToThread : PropTypes.func,
  onPdfSubmit : PropTypes.func
}

const mapStateToProps = (state) => {
  return {

    //only needed for doc attach.  refactor?
    threads: state.workspace.threads,
    accountInfo: state.shared.accountInfo,

    //these are only needed for download multiple window.  maybe we can refactor this?
    docs : state.workspace.docs,
    activeDMDocs: state.thread.activeDMDocs,

    accountInfoGuest : state.shared.accountInfoGuest,
    activeThreadMessages: state.thread.activeThreadMessages,
    activeDMMessages: state.thread.activeDMMessages,

    activeUpload : state.upload.activeUpload,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    threadAction : {...threadActions.mapToDispatch(dispatch)},
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},

    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

const cardSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    log.log('begin drag', props);

    let src_forum_id = null;
    let src_host_uid = null;
    if(props.isOutsideCurrentContext){
      src_forum_id = props.workspace.forum_id;
      src_host_uid = props.workspace.host_uid || props.accountInfo.uid;
    }
    else if(props.dm){
      src_forum_id = props.dm.forum_id;
      src_host_uid = props.dm.host_uid || props.accountInfo.uid;
    }
    else if(props.workspace){
      src_forum_id = props.workspace.forum_id;
      src_host_uid = props.workspace.host_uid || props.accountInfo.uid;
    }
    else{
      console.error('what context is this?');
      return;
    }

    const item = {
      row: props.row,
      src_forum_id,
      src_host_uid
    }
    return item
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }

    // When dropped on a compatible target, do something
    // const item = monitor.getItem()
    // const dropResult = monitor.getDropResult()
    //
    // log.log('endDrag', item, dropResult);
  }
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  }
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DragSource(c.DRAG_DROP_TYPES.THREAD_DOC, cardSource, collect)(DocRow)));
