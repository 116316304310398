import React, {Component} from 'react';
import {connect} from "react-redux";
import log from "../../util/log";
import PropTypes from 'prop-types';
import sapi from "../../util/sapi";
import _ from 'lodash';
import Button from '../partials/elements/Button';
import colors from "../../util/colors";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement, } from '@stripe/react-stripe-js';
import Promise from "bluebird";
import ValidationErrors from "../partials/components/ValidationErrors";
import filters from "../../helpers/filters";
import {getMessageForError} from "../../util/errors";
import sharedActions from "../../actions/shared-actions";
import accountActions from "../../actions/account-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import moment from "moment";

class StripeCheckoutDialog extends Component {

  constructor(props) {
    super(props);

    log.log('StripeCheckoutDialog load', props);

    this.state = {
      loading : false,
      postal : '',
      hasSomeCardInput : false,
      stripePromise : null,
      validationErr : []
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    this.setState({loading : true})

    this.setState({
      stripePromise : loadStripe(this.props.stripePubKey)
    })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    let {close} = this.props;

    log.log('stripe checkout close modal', res);
    close(res);
  }

  onSubmitButtonClick(stripe, elements, evt){
    evt.preventDefault();
    let { postal } = this.state;
    stripe.createPaymentMethod({
      type: 'card',

      //This is a weird parameter, and is NOT documented in the stripe docs.
      //You don't need to pass the rest of the card elements into the card.
      //You just give stripe the first element and it will apparently detect the rest.  Weird guys.
      //https://github.com/stripe/react-stripe-elements/issues/482
      // card: elements.getElement(CardNumberElement),
      card: elements.getElement(CardElement),

      //This is an expected stripe field
      //We could pass more if we wanted.
      //https://stripe.com/docs/js/payment_methods/create_payment_method
      // billing_details: {
      //   address: {
      //     postal_code: postal,
      //   },
      // },
    })
      .then((res) => {
        const {error, paymentMethod} = res;
        log.log('payment method res', error, paymentMethod);

        if(error && !_.isEmpty(error)){
          // code: "invalid_number"
          // message: "Your card number is invalid."
          // type: "validation_error"
          this.setState({validationErr : [error.message]})
          return;
        }
        else{
          this.setState({validationErr : []}, () => {
            if(paymentMethod && paymentMethod.id){
              log.log('got payment method', paymentMethod);
              this.closeModal(paymentMethod.id);
            }
          })
        }
      })

  }

  onEscapeKey(){
    this.closeModal(false);
  }

  onCardChange(evt){
    this.setState({
      hasSomeCardInput : !_.get(evt, 'empty', true),
      validationErr: []
    })
  }

  render() {
    let {description, termCostCents, t, i18n } = this.props;
    let { hasSomeCardInput } = this.state;
    return (
      <div className="modal-content">
        <>
          <Elements stripe={this.state.stripePromise} options={{locale: i18n.language}} key={i18n.language}>
            <ElementsConsumer>
              {({stripe, elements}) => {
                return (
                  <>
                    <form onSubmit={this.onSubmitButtonClick.bind(this, stripe, elements)}>
                      <div className="modal-header draggable-header">
                        <h5 className="modal-title">{t("Payment Information")}</h5>
                        <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
                          <i className="icon ion-ios-close-empty"/>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col">

                            <div className="card">
                              <div className="card-body" style={{minHeight: '150px'}}>
                                <h5 className="card-title">
                                  {description ? description : t('Upgrade to Pro')}
                                </h5>
                                <p className="card-subtitle mb-2 text-muted">
                                  {filters.getCurrency(this.props.i18n.language, termCostCents)} {t("/ month")}
                                </p>

                                <CardElement onChange={(evt) => this.onCardChange(evt)}
                                             options={{
                                               iconStyle: 'solid',
                                               style: {
                                                 base: {
                                                   fontSize: '16px',
                                                   color: colors.DARK,
                                                   '::placeholder': {
                                                     color: colors.SECONDARY_TEXT,
                                                   },
                                                 },
                                                 invalid: {
                                                   color: colors.ASSERTIVE,
                                                 },
                                               },
                                             }}/>

                                {/*<div className="form-group">*/}
                                {/*  <label htmlFor="cardNumber">Card Number</label>*/}
                                {/*  <CardNumberElement*/}
                                {/*    id="cardNumber"*/}
                                {/*    className="form-control"*/}
                                {/*    options={ELEMENT_OPTIONS}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                {/*<div className="form-group">*/}
                                {/*  <label htmlFor="expiry">Card Expiration</label>*/}
                                {/*  <CardExpiryElement*/}
                                {/*    id="expiry"*/}
                                {/*    className="form-control"*/}
                                {/*    options={ELEMENT_OPTIONS}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                {/*<div className="form-group">*/}
                                {/*  <label htmlFor="cvc">CVC</label>*/}
                                {/*  <CardCvcElement*/}
                                {/*    id="cvc"*/}
                                {/*    className="form-control"*/}
                                {/*    options={ELEMENT_OPTIONS}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                {/*<div className="form-group">*/}
                                {/*  <label htmlFor="postal">Postal Code</label>*/}
                                {/*  <input*/}
                                {/*    id="postal"*/}
                                {/*    className="form-control"*/}
                                {/*    placeholder="12345"*/}
                                {/*    value={postal}*/}
                                {/*    onChange={(event) => {*/}
                                {/*      this.setState({postal: event.target.value});*/}
                                {/*    }}*/}
                                {/*  />*/}
                                {/*</div>*/}

                                {this.state.validationErr.length > 0 &&
                                <div className="my-2">
                                  <ValidationErrors errors={this.state.validationErr}/>
                                </div>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <Button className={'btn btn-secondary'}
                                type={'button'}
                                onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
                        <Button className={'btn btn-primary'}
                                type="submit"
                                disabled={!stripe || !hasSomeCardInput}>
                          {t("Update")}
                        </Button>
                      </div>
                    </form>
                  </>
                )
              }}
            </ElementsConsumer>
          </Elements>
        </>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    updateLogo : () => dispatch(sharedActions.updateLogo()),
  };
};

StripeCheckoutDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutDialog));
