import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import appActions from "../../../../actions/app-actions";
import Account from "../../../pages/Account";
import vfLocalStorage from "../../../../util/local-storage";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import _ from "lodash";
import filters from "../../../../helpers/filters";
import colors from "../../../../util/colors";
import modalActions from "../../../../actions/modal-actions";

class TrustedDevicesCtrl extends Component {

  tableRow = {
    borderBottom : `1px solid ${colors.STABLE}`,
    height : '49px',
    lineHeight : '49px',
    verticalAlign : 'baseline'
  }

  constructor(props){
    super(props);

    this.state = {
      trustedDevices: null,
      showLoading : false,
      validation_errors : []
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    let { t } = this.props;
    this.setState({showLoading : true})
    sapi.TFA.trustedDeviceList()
      .then((res) => {
        this.setState({trustedDevices : res.data})
      })
      .catch((err) => {
        this.setState({errors : [ getMessageForError(err, t) ]})
        log.log('get qr code error', err);
      })
      .finally(() => {
        this.setState({showLoading : false})
      })
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  renderDevicesListTable(){
    let { trustedDevices } = this.state;
    let { t } = this.props;

    if(trustedDevices && trustedDevices.length === 0){
      return (
        <div className="mb-3">
          <h4 className="text-center">{t("Trusted Devices")}</h4>
          <div style={this.tableRow} className="row mb-5">
            <div className="col text-center">
              <p className="secondary-text-color font-italic">
                {t("No Trusted Devices")}
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="mb-5">
        <h4 className="text-center">{t("Trusted Devices")}</h4>
        <div style={this.tableRow} className="row">
          <div className="col-4">
            {t("Label")}
          </div>
          <div className="col-4">
            {t("Last Authorized")}
          </div>
          <div className="col-2">

          </div>
          <div className="col-2">

          </div>
        </div>
        {_.map(trustedDevices, (device, index) => {
          return (
            <div key={device.device_id} style={this.tableRow} className="row">
              <div className="col-4">
                {device.label}
              </div>
              <div className="col-4 secondary-text-color font-italic">
                {filters.momentFilter(device.last_auth_date, 'lll')}
              </div>
              <div className="col-2">
                <a className="btn btn-link primary-color" onClick={this.renameTrustedDevice.bind(this, device)}>
                  {t("Rename")}
                </a>
              </div>
              <div className="col-2">
                <a className="btn btn-link primary-color" onClick={this.removeTrustedDevice.bind(this, device)}>
                  {t("Remove")}
                </a>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  removeTrustedDevice(device) {
    let { t } = this.props;

    this.props.modalAction.showConfirm(
      t('Remove Trusted Device'),
      t('You are about to remove ') + device.label + t(' from your list of trusted devices. Are you sure you want to do this?'),
      (res) => {
        if(res){
          sapi.TFA.trustedDeviceDelete(device.device_id)
            .then((res) => {
              return sapi.TFA.trustedDeviceList()
            })
            .then((res) => {
              this.setState({trustedDevices : res.data})
            })
            .catch((err) => {
              this.props.modalAction.showAlert(t('Error Removing Device'), getMessageForError(err, t));
            })
        }
      })
  }

  renameTrustedDevice(device){
    let { t } = this.props;

    let doRename = (label) => {
      return sapi.TFA.trustedDeviceRename(device.device_id, label);
    }

    this.props.modalAction.showRename(t('Trusted Device'), device.label, doRename, (res) => {
      if(res){
        return sapi.TFA.trustedDeviceList()
          .then((res) => {
            this.setState({trustedDevices : res.data})
          })
          .catch((err) => {
            this.props.modalAction.showAlert(t('Error Renaming Device'), getMessageForError(err, t));
          })
      }
    })
  }

  render() {
    let { showLoading } = this.state;
    let { t } = this.props;

    if(showLoading){
      return (
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    return (
      <div style={Account.styles.wideRowContents} className={'mt-3'}>
        {this.renderDevicesListTable()}
      </div>
    )
  }
}

TrustedDevicesCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    cleanup : () => dispatch(appActions.cleanup()),
    modalAction : {...modalActions.mapToDispatch(dispatch)},
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TrustedDevicesCtrl)));
