import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import UpgradeDialogNew from "./UpgradeDialogNew";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";
import sharedActions from "../../actions/shared-actions";

class ConfirmNameDialog extends Component {

  constructor(props) {
    super(props);

    this.firstInputRef = React.createRef()

    this.state = {
      firstName : '',
      lastName : '',
      validationErr : []
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let ref = null;
    utils.waitForCondition(() => {
        ref = _.get(this.firstInputRef, 'current');
        return !!(ref)
      })
      .then(() => {
        ref.focus();
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  closeModal(res) {
    this.props.close(res);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  submit(){
    let me = this;
    let {close, t} = this.props;

    if (this.doValidate()) {
      let {firstName, lastName} = this.state;

      let req = {
        first_name : firstName,
        last_name : lastName,
        intro_status: { name: false }
      }
      sapi.AccountInfo.update(req)
        .then(() => {
          return this.props.updateAccountInfo();
        })
        .then(() => {
          this.closeModal(true);
        })
        .catch((err) => {
          this.setState({
            validationErr: [getMessageForError(err, t)]
          })
        })
    }
  }

  doValidate() {
    let { t } = this.props;
    let { firstName, lastName } = this.state;

    let err = [];
    if(!firstName && firstName.length === 0){
      err.push(t('Please enter your first name.'));
    }
    if(!lastName && lastName.length === 0){
      err.push(t('Please enter your last name.'));
    }

    this.setState({
      validationErr: err
    })
    return err.length === 0;
  }

  render() {
    let {modalProps, t} = this.props;
    let {firstName, lastName, validationErr} = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Your Name")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <p className="text-center">
                {t("This is the name that your Verifyle contacts will see.")}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("First Name")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={firstName}
                       ref={this.firstInputRef}
                       onChange={(evt) => this.setState({firstName: evt.target.value})}
                       placeholder={t("Enter your first name")} />
              </div>
              <div className="form-group">
                <label>{t("Last Name")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={lastName}
                       onChange={(evt) => this.setState({lastName: evt.target.value})}
                       placeholder={t("Enter your last name")} />
              </div>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} />}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-primary'} disabled={firstName.length === 0 || lastName.length === 0} onClick={this.submit.bind(this)}>{t("Save Name")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    ...modalActions.mapToDispatch(dispatch)
  };
};

ConfirmNameDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmNameDialog)));
