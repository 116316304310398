import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash'
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import utils from "../../util/util";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import config from "../../util/config";

class ContactUsWindow extends Component {

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      message : ''
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    setTimeout(() => {
      this.inputRef.current.focus();
    })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  doSend(){
    let { t } = this.props;
    let { message } = this.state;

    let errorObj = {
      build : _.get(config, 'versions.web'),
      ua : _.get(window, 'navigator.userAgent'),
    }

    sapi.Notify.reportError(t("Contact Us submission from web"), message, JSON.stringify(errorObj))
      .then((res) => {
        this.closeModal();
      })
      .catch((err) => {
        log.warn('error submitting report', err);
        this.props.showAlert(t("Error Submitting"), getMessageForError(err, t));
      })
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { accountInfoGuest, t} = this.props;
    let { message } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Contact Us")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div>
            <label className="mb-1">
              {t("To:")} <span className="secondary-text-color">{t("Verifyle Support")} {"<"}{t("support@verifyle.com")}{">"}</span>
            </label>
            <label className="mb-1">
              {t("From:")} <span
              className="secondary-text-color">{`${accountInfoGuest.first_name} ${accountInfoGuest.last_name} <${accountInfoGuest.email}>`}</span>
            </label>
          </div>
          <div className="mt-3">
            <label>
              {t("Tell us how we can help!")}
            </label>
            <textarea value={message}
                      rows={10}
                      ref={this.inputRef}
                      placeholder={t("Describe your question or problem.  The more detail the better!")}
                      onChange={(evt) => this.setState({message: evt.target.value})}
                      className="no-resize form-control"/>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={message.length === 0}
                  onClick={this.doSend.bind(this, true)}>{t("Send")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  docControlWrap: {
    borderRadius: '20px',
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  },
  docDeletedWrap: {
    color: colors.SECONDARY_TEXT,
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    workspace : state.workspace.workspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

ContactUsWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUsWindow));
