import moment from 'moment';
import c from './const';
import _ from 'lodash';
import he from 'he'
import log from "./log";

let utils = {
  //https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  b64DecodeUnicode: function (str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  },

  waitFor(waitTimeMs){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, waitTimeMs || 50)
    })
  },

  hasEmailValidationInvalidFlag(res){
    return _.get(res, 'invalid_flag', false) || _.get(res, 'vf_invalid_flag', false);
  },

  //Shamelessly stolen from:
  //http://stackoverflow.com/questions/948172/password-strength-meter
  //Good passwords start scoring around 60ish
  //always returns between 0 and 100
  //We had this in the old ui code too.
  scorePassword(pass) {
    let score = 0;
    if (!pass)
      return score;

    // award every unique letter until 5 repetitions
    let letters = {};
    for (var i=0; i<pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    }

    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return Math.min(100, parseInt(score));
  },

  convertFileInputToUIntArray(input){
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.addEventListener("loadend", e => resolve(new Uint8Array(_.get(e, 'target.result'))))
      reader.addEventListener("error", reject)
      reader.readAsArrayBuffer(input)
    })
  },

  transparentizeHex(hex, alpha){
    //Nice one-liner from here:
    //https://stackoverflow.com/questions/19799777/how-to-add-transparency-information-to-a-hex-color-code
    return hex + Math.floor(alpha * 255).toString(16).padStart(2, 0);
  },

  waitForCondition(evaluateFn, waitTime){
    return new Promise((resolve, reject) => {
      if(evaluateFn()){
        resolve(true);
      }
      else{
        setTimeout(() => {
          resolve(this.waitForCondition(evaluateFn, waitTime))
        }, waitTime || 50)
      }
    })
  },

  trimEndOfStringToLength(str, length){
    let result = '';
    if(str.length >= length){
      result = str.substr(0, length);
    }
    else{
      result = str;
    }
    return result;
  },

  makeSuggestedFilename(originalName, maxFilenameLength) {
    let fileNameWithoutExtension = this.getFilenameWithoutExtension(originalName);

    let suffix = '.pdf';
    let ellipsis = '...';
    let result = fileNameWithoutExtension + suffix;
    if (maxFilenameLength && result.length > maxFilenameLength) {
      //We want to trim the filename to account for ellipsis and suffix, since we're going to add it back.
      let trimmed = this.trimEndOfStringToLength(fileNameWithoutExtension, (maxFilenameLength - ellipsis.length - suffix.length));
      result = trimmed + ellipsis + suffix;
    }
    return result;
  },

  //https://stackoverflow.com/questions/12168909/blob-from-dataurl
  convertDataURIToArrayBuffer(dataURI){
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return ab;
  },

  arrayBufferToBase64: function (buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  },

  htmlDecodeObj: (obj) => {
    _.forOwn(obj, (value, key) => {
      if (value && (typeof value === 'string' || value instanceof String)) {
        obj[key] = he.decode(value);
      }
      else if(value && (typeof value === 'object')){
        utils.htmlDecodeObj(value)
      }
    });
  },

  base64Encode: function (str) {
    return btoa(unescape(encodeURIComponent(str)));
  },

  calculateResizedImage(uri, targetWidth, targetHeight){
    return new Promise((resolve, reject) => {
      let sourceImage = new Image();

      sourceImage.onload = function () {
        log.log('source image', sourceImage.width, sourceImage.height);
        let ratio = Math.min(1,targetWidth / sourceImage.width, targetHeight / sourceImage.height);
        let newWidth = sourceImage.width * ratio;
        let newHeight = sourceImage.height * ratio;
        resolve( {width : newWidth, height : newHeight});
      }

      sourceImage.onerror = function (err) {
        reject(err);
      }

      sourceImage.src = uri;
    })
  },

  generateHashCode(str){
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (var i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  },

  getMomentDate: function(val){
    return moment(new Date(+val * 1000));
  },

  bytesToSize: function (t, bytes, precision) {
    bytes = parseInt(bytes, 10);

    var kilobyte = 1024;
    var megabyte = kilobyte * 1024;
    var gigabyte = megabyte * 1024;
    var terabyte = gigabyte * 1024;

    if ((bytes >= 0) && (bytes < kilobyte)) {
      return bytes + t(' B');
    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
      return (bytes / kilobyte).toFixed(precision) + t(' KB');
    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
      return (bytes / megabyte).toFixed(precision) + t(' MB');
    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
      return (bytes / gigabyte).toFixed(precision) + t(' GB');
    } else if (bytes >= terabyte) {
      return (bytes / terabyte).toFixed(precision) + t(' TB');
    } else {
      return bytes + t(' B');
    }
  },

  getFilenameWithoutExtension(filename){
    let fileNameWithoutExtension = '';
    let extensionIndex = filename.lastIndexOf(".");
    if (extensionIndex > 0) {
      fileNameWithoutExtension = filename.substr(0, extensionIndex);
    }
    else {
      fileNameWithoutExtension = filename;
    }
    return fileNameWithoutExtension;
  },

  getFilenameExtension: function (filename) {
    return filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2);
  },

  fileExtensionIsPDF: function(fileName){
    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return ext.toLowerCase() === 'pdf'
  },

  isPreviewable: function(fileName){

    return this.isTextPreview(fileName) || this.isPDFPreview(fileName) || this.isImgPreview(fileName) || this.isSvgPreview(fileName)
  },

  isTextPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.textExtensions.indexOf(ext) >= 0;
  },

  isPDFPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.pdfExtensions.indexOf(ext) >= 0;
  },

  isHeicPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.heicExtensions.indexOf(ext) >= 0;
  },

  isImgPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.imgExtensions.indexOf(ext) >= 0;
  },

  isSvgPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.svgExtensions.indexOf(ext) >= 0;
  },
}

export default utils;
