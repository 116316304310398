import c from '../../../util/const'
import log from '../../../util/log'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import {Waypoint} from "react-waypoint";
import PdfPage from "./PdfPage";
import classnames from 'classnames';
import Button from "../elements/Button";
import Promise from 'bluebird'
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";

import { osName } from 'react-device-detect';
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import util from "../../../util/util";

class PdfPrintPreviewSvc extends Component {

  pageSizes = {};
  canvasRefs = {};

  constructor(props){
    super(props);

    this.printRef = React.createRef();

    this.state = {
      rendering : false,
      showing : false,
      progress : 0,
      showProgress : false,
      pages : []
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  loadAndRender(data){
    let me = this;
    var init = {
      data: data,
      cMapUrl: "/node_modules/pdfjs-dist/cmaps/",
      cMapPacked: true,
    }

    //NOTE: this had to be relocated to the PUBLIC directory.  There were weird runtime problems referencing
    //it from node_modules
    pdfjsLib.GlobalWorkerOptions.workerSrc = c.pdf.pdfjs_workerPath;

    return pdfjsLib.getDocument(init).promise
      .then((pdf) => {
        var calls = [];
        for (var num = 1; num <= pdf.numPages; num++) {
          calls.push(pdf.getPage(num)
            .then((page) => {
              page.pageIndex = page._pageIndex
              return page;
            }));
        }

        return Promise.all(calls);
      })
      .then((pageRes) => {
        log.log('pageRes', pageRes);
        me.setState({pages : pageRes})
      })
      .then(() => {
        _.each(me.state.pages, (page) => {
          this.initializePageSize(page.pageIndex);
        })

        return this.renderAllPages();
      })
  }

  renderAllPages(){
    this.setState({
      showProgress : false,
      progress : 0
    })
    return new Promise((resolve, reject) => {
      this.setState({rendering : true, showing : true}, () => {
        return this.waitForCanvasRefs()
          .then(() => {
            let pageRenders = [];
            _.each(this.state.pages, (page) => {
              pageRenders.push(this.renderPage(page.pageIndex))
            })

            let increment = (100 / this.state.pages.length);

            return Promise.all(pageRenders)
              .each((eachRes) => {
                setTimeout(() => {
                  let { progress } = this.state;
                  this.setState({progress : progress + increment})
                }, 0)
              })
          })
          .catch((err) => {
            log.warn('error rendering pages', err);
            reject(err);
          })
          .finally(() => {
            this.setState({
              progress : 100,
              showProgress : false,
              rendering : false
            }, () => {
              setTimeout(() => {
                resolve(true);
              })
            })
          })
      })
    })

  }

  getPage(index){
    let { pages } = this.state;

    let found = null;
    _.each(pages, (page) => {
      if(page.pageIndex === index){
        found = page;
      }
    })
    return found;
  }

  calculateViewportScale(outputScale){
    //this is kind of a weird hack.  I'm trying to calculate a scale for this page that is larger than
    //the render size.  The css here is designed to scale it appropriately if it is.
    //The trick is that we don't want to scale the viewport too larger because it's a performance problem.
    //At higher pixel ratios we're able to get away with a lower scaling, so lower the scale as the pixel ratio goes up.
    return 2 / outputScale.sx;
  }

  initializePageSize(pageIndex){
    let { sharedCanvasRef } = this.props;
    let page = this.getPage(pageIndex);
    let ctx = sharedCanvasRef.current.getContext('2d', {
      alpha: false
    });

    let outputScale = PdfPage.getOutputScale(ctx);
    let viewport = page.getViewport({scale: 1}).clone({ scale : this.calculateViewportScale(outputScale) })
    this.pageSizes[pageIndex] = {
      canvasHeight : viewport.height * outputScale.sy | 0,
      canvasWidth : viewport.width * outputScale.sx | 0,
      canvasStyleHeight : viewport.height + 'px',
      canvasStyleWidth : viewport.width + 'px'
    }
  }

  waitForCanvasRefs(){
    return new Promise((resolve, reject) => {
      util.waitForCondition(() => {
        return _.keys(this.canvasRefs).length === this.state.pages.length;
      })
        .then(() => {
          log.log('all canvas refs loaded', this.canvasRefs);
          resolve(true);
        })
    })
  }

  renderPage(pageIndex){
    let page = this.getPage(pageIndex);
    let canvasRef = this.canvasRefs[pageIndex];

    let ctx = canvasRef.getContext('2d', {
      alpha: false
    });

    let outputScale = PdfPage.getOutputScale(ctx);
    let viewport = page.getViewport({scale: 1}).clone({ scale : this.calculateViewportScale(outputScale) })
    this.pageSizes[pageIndex] = {
      canvasHeight : viewport.height * outputScale.sy | 0,
      canvasWidth : viewport.width * outputScale.sx | 0,
      canvasStyleHeight : viewport.height + 'px',
      canvasStyleWidth : viewport.width + 'px'
    }

    let drawViewport = viewport.clone({scale : this.calculateViewportScale(outputScale)});

    //Word to the wise...you CANNOT call ctx.scale() in here.
    //Doing this works SOMETIMES, but causes render problems on certain pdfs.  bug 1726
    //Instead, you're apparently supposed to use this undocumented method with this
    //transform property below.
    // I got that out of the pdf.js viewer code here - https://mozilla.github.io/pdf.js/web/viewer.html
    //I can't find any further documentation on this.
    let renderContext = {
      canvasContext: ctx,
      transform : !outputScale.scaled ? null : [outputScale.sx, 0, 0, outputScale.sy, 0, 0],
      viewport: drawViewport
    };

    return page.render(renderContext).promise;
  }

  setRef(index, ref) {
    this.canvasRefs[index] = ref;
  }

  getProgressDialog(){
    let { progress } = this.state;
    let { t } = this.props;

    log.log('get progress', progress);
    return (
      <div className={'modal'} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header draggable-header">{t("Generating Preview...")}</div>
            <div className="modal-body">
              <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated"
                     role="progressbar"
                     aria-valuenow={progress}
                     aria-valuemin="0"
                     aria-valuemax="100"
                     style={{width : progress + '%'}} />
              </div>
            </div>
            {/*<div className="modal-footer">*/}
            {/*  <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>OK</Button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {pages} = this.state;
    let { rendering, showing, showProgress } = this.state;

    //I put d-none on the outside wrapper, but put the ref on the inner div.
    //The ref is what gets passed to the printer, so the style would not apply.
    return (
      <Fragment>
        <div className={'d-none'}>
          <div style={PdfPrintPreviewSvc.styles.wrap} ref={this.printRef}>
            {showing &&
            _.map(pages, (page) => {
              let canvasSize = this.pageSizes[page.pageIndex];
              return (
                <div style={PdfPrintPreviewSvc.styles.page} key={page.pageIndex}>
                  <div style={PdfPrintPreviewSvc.styles.pageWrap}>
                    <div style={PdfPrintPreviewSvc.styles.canvasWrap}>
                      <canvas height={canvasSize.canvasHeight}
                              width={canvasSize.canvasWidth}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              ref={this.setRef.bind(this, page.pageIndex)}/>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Fragment>
    )
  }
}

PdfPrintPreviewSvc.styles = {
  wrap : {
    maxWidth: '100%',
  },
  page :{
    textAlign: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  pageWrap:{
    position: 'relative',
    maxWidth: '100%',
  },
  canvasWrap:{
    height: 'inherit',
    width: 'inherit',
    maxWidth: '100%',
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfPrintPreviewSvc.propTypes = {
  onRef : PropTypes.func.isRequired,
  sharedCanvasRef : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfPrintPreviewSvc));
