import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash'
import Button from '../partials/elements/Button';
import ValidationErrors from "../partials/components/ValidationErrors";
import {withVFTranslation} from "../../util/withVFTranslation";
import {withRouter} from "react-router-dom";

class PdfSubmitDialog extends Component {

  constructor(props) {
    super(props);

    let threadOrDMLabel = null;
    if(props.modalProps.thread){
      threadOrDMLabel = props.modalProps.thread.label
    }
    else if(props.modalProps.dm){
      threadOrDMLabel = props.modalProps.dm.first_name + ' ' + props.modalProps.dm.last_name
    }

    this.state = {
      filename: props.modalProps.suggestedFilename,
      mesg: '',
      validationErr: [],
      threadOrDMLabel
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    let {close} = this.props;
    let { filename, mesg } = this.state;
    close({filename, mesg});
  }

  doValidation(){
    let { t } = this.props;
    let { filename } = this.state;

    let err = [];
    if(!filename || filename.length === 0){
      err.push(t("Please enter a filename"));
    }

    this.setState({
      validationErr : err
    })

    return err.length === 0;
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, t} = this.props;

    let {
      filename,
      mesg,
      validationErr,
      threadOrDMLabel
    } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          {threadOrDMLabel &&
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group text-center">
                <h4>
                  <i className="icon ion-chatbox mr-2" />
                  {threadOrDMLabel}
                </h4>
              </div>
            </div>
          </div>
          }
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("New Name")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={filename}
                       onChange={(evt) => this.setState({filename: evt.target.value})}
                       placeholder={t('File Name')}/>
              </div>
            </div>
          </div>
          {threadOrDMLabel &&
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Message")}</label>
                <textarea className={'form-control'}
                          rows={4}
                          value={mesg}
                          onChange={(evt) => this.setState({mesg: evt.target.value})}
                          placeholder={t('Message')}/>
              </div>
            </div>
          </div>
          }
          {validationErr.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={validationErr}/>
            </div>
          </div>
          }
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.save.bind(this)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfSubmitDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSubmitDialog));
