import c from '../util/const';
import log from "../util/log";
import _ from "lodash";

let initialState = {
  messageBlocksId : null,
  activeDM : null,
  activeDMMessages: null,
  activeDMMessageBlocks: null,
  activeDMDataDate: null,
  activeDMDocs: null,

  activeThread : null,
  guest_data : null,
  activeThreadMessages: null,
  activeThreadMessageBlocks: null,
  activeThreadDataDate: null,
  activeThreadDocs : null,
  activeThreadMesgEditFlag : false,
}

const thread = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.thread.setActiveThread:
      return {
        ...state,
        activeThread: action.thread
      }

    case c.actions.thread.setActiveDM:
      return {
        ...state,
        activeDM: action.dm
      }

    case c.actions.thread.updateThreadMessages:
      return {
        ...state,
        ...(action.guest_data ? {guest_data: action.guest_data} : {}),
        ...(action.messages ? {activeThreadMessages: action.messages} : {}),
        ...(action.messageBlocks ? {activeThreadMessageBlocks: action.messageBlocks} : {}),
        ...(action.messageBlocksId ? {messageBlocksId: action.messageBlocksId} : {}),
        ...(action.data_date ? {activeThreadDataDate: action.data_date} : {}),
        ...(action.docs ? {activeThreadDocs: action.docs} : {}),
        ...(action.mesg_edit_flag ? {activeThreadMesgEditFlag: action.mesg_edit_flag} : {}),
      };

    case c.actions.thread.updateActiveDMMessages:
      return {
        ...state,
        ...(action.guest_data ? {guest_data: action.guest_data} : {}),
        ...(action.messages ? {activeDMMessages: action.messages} : {}),
        ...(action.messageBlocks ? {activeDMMessageBlocks: action.messageBlocks} : {}),
        ...(action.messageBlocksId ? {messageBlocksId: action.messageBlocksId} : {}),
        ...(action.data_date ? {activeDMDataDate: action.data_date} : {}),
        ...(action.docs ? {activeDMDocs: action.docs} : {}),
      };

    case c.actions.thread.cleanup:
      return {...initialState}

    default:
      return state;
  }
}

export default thread;
