import c from '../util/const';
import sapi from "../util/sapi";
import api from "../util/api";

import sharedActions from './shared-actions';

import Promise from 'bluebird';
import log from "../util/log";
import _ from "lodash";
import DocPermissionsHelper from "../helpers/doc-permissions-helper";
import msgHelper from "../helpers/msg-helper";
import utils from "../util/util";
import threadActions from "./thread-actions";

const workspaceActions = {

  init(workspace){
    let me = this;

    return (dispatch, getState) => {

      dispatch({
        type : c.actions.workspace.resetForNewWorkspace
      })
      dispatch(me.updateWorkspace(workspace));

      dispatch(me.setPublisherInfoIfNeeded(workspace.forum_id));

      let firstCalls = [];
      if(!workspace.host_uid){
        firstCalls.push(dispatch(me.refreshGuests(workspace.forum_id, workspace.host_uid )))
      }

      return Promise.all(firstCalls)
        .then(() => {

          //Careful!  refreshThreads with doRefreshGuests set to true
          //contains a dependency on workspace guests.
          //make sure this is populated first.
          let calls = [
            dispatch(me.refreshThreads(workspace.forum_id, workspace.host_uid, true)),
            dispatch(me.refreshDocs(workspace.forum_id, workspace.host_uid)),
          ]

          //don't wait for logo, bump it out so it loads after the main calls.
          setTimeout(() => {
            dispatch(me.refreshLogo(workspace));
          }, 500);

          return Promise.all(calls)
        })
        .then(() => {
          log.log('workspace init done');
          return true;
        })
    }
  },

  setPublisherInfoIfNeeded(forum_id){
    return (dispatch, getState) => {
      let publisherList = _.get(getState(), 'shared.publisherList');
      let info = _.find(publisherList, (p) => p.forum_id === forum_id);
      if(info){
        dispatch({
          type : c.actions.workspace.setPublisherInfo,
          publisherInfo : info
        })
      }
    }
  },

  setLoadingForumId(forum_id){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.workspace.setLoadingForumId,
        forum_id
      })
    }
  },

  findGuestInActiveThreadParticipants(guest_uid){
    return (dispatch, getState) => {
      let threadParticipantLookup = _.get(getState(), 'workspace.threadParticipantLookup', {});
      let thread = _.get(getState(), 'thread.activeThread', {});
      let accountInfoGuest = _.get(getState(), 'shared.accountInfoGuest', {});

      let guests = threadParticipantLookup ? threadParticipantLookup[thread.chat_id] : [];

      if(accountInfoGuest && accountInfoGuest.guest_uid === guest_uid){
        //This is kind of nasty...add yourself as a fake guest.
        return accountInfoGuest;
      }

      let found = _.find(guests, (g) => {
        return g.guest_uid === guest_uid
      })

      if(!found){
        let guestList = _.get(getState(), 'workspace.guests', []);
        found = _.find(guestList, (g) => g.guest_uid === guest_uid);
      }

      if(!found){
        found = _.get(getState(), `thread.guest_data[${guest_uid}]`);
        if(found){
          //guest_data doesn't actually have a guest_uid in the object, so add it here.
          found = _.extend(found, {guest_uid});
        }
      }

      return found;
    }
  },

  cleanup(){
    return {
      type: c.actions.workspace.cleanup
    }
  },

  updateWorkspace(workspace){
    return {
      type: c.actions.workspace.updateWorkspace,
      workspace
    }
  },

  setActiveThread(forum_id, host_uid, thread) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: c.actions.workspace.setActiveDoc,
          doc: null,
        })
        dispatch(threadActions.initThread(thread))

        if(!thread){
          dispatch({
            type : c.actions.workspace.setActiveThreadGuests,
            guests : []
          })
          resolve(thread);
        }
        else{
          sapi.Threads.guests(forum_id, host_uid, thread.chat_id)
            .then((res) => {
              dispatch({
                type : c.actions.workspace.setActiveThreadGuests,
                guests : res.data
              })
              // log.log('set active thread here', thread, res);
              resolve(thread);
            })
            .catch((err) => {
              reject(err);
            })
        }
      })
    }
  },

  setActiveDoc(doc){
    return (dispatch) => {
      dispatch({
        type: c.actions.workspace.setActiveDoc,
        doc,
      })
    }
  },

  refreshWorkspace(forum_id){
    let me = this;
    return (dispatch, getState) => {
      return dispatch(sharedActions.updateWorkspaces())
        .then((workspaces) => {

          let currentForumId = _.get(getState(), 'workspace.workspace.forum_id');
          if(currentForumId !== forum_id){
            log.log('skipping workspace update, it has changed since we called refresh', currentForumId, forum_id);
            return;
          }

          let foundWorkspace = _.find(workspaces, ['forum_id', forum_id])
          if(!foundWorkspace){
            throw new Error('Unable to find workspace');
          }

          dispatch(me.updateWorkspace(foundWorkspace));
        })
    }
  },

  refreshLogo(workspace){
    return (dispatch, getState) => {
      let promise = null;
      let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
      if(isContentWorkspace){

        let { publisherList } = getState().shared;
        let found = _.find(publisherList, (p) => p.forum_id === workspace.forum_id);
        if(found){
          promise = api.AcctLogo.logo(found.uid);
        }
        else{
          log.warn('unable to find publisher for content workspace');
          promise = Promise.resolve(null);
        }
      }
      else{

        if(!workspace.host_uid){
          promise = sapi.Workspace.logo();
        }
        else{
          promise = sapi.Workspace.logo(workspace.host_uid, workspace.forum_id);
        }
      }

      return promise
          .then((res) => {

            let logoRes = _.get(res, 'data.logo');

            //Weird, but the backend returns a single pixel response when there's no logo.
            if(!logoRes || logoRes === c.BLANK_LOGO){
              dispatch({
                type : c.actions.workspace.updateLogo,
                data : null
              })
            }
            else{
              dispatch({
                type : c.actions.workspace.updateLogo,
                data : logoRes
              })
            }
          })
    }
  },

  setActiveDM(dm){
    return (dispatch) => {

      dispatch({
        type: c.actions.workspace.setActiveDoc,
        doc : null,
      })

      return dispatch(threadActions.initDM(dm));
    }
  },

  refreshWorkspaceThreadGuests(forum_id, host_uid, workspaceThreads){
    return (dispatch, getState) => {
      let currentAccountUid = _.get(getState(), 'shared.accountInfo.uid');

      //a little extra complexity here for hosts vs guests.
      //hosts can get thread permissions by calling perm/list, and looking up
      //guest data by calling workspace/guest/list
      //if you're a guest, you can't call workspace/guest/list, so we have to
      //loop through threads and call guest list

      if(!host_uid){
        return Promise.all([
          sapi.Threads.getPermList(forum_id)
        ])
          .then((res) => {
            let threadPerm = res[0].data;
            let guestList = _.get(getState(), 'workspace.guests', []);
            let participantLookup = {};
            _.each(workspaceThreads, (thread) => {
              let guestIdsInThread = threadPerm[thread.chat_id] ? _.keys(threadPerm[thread.chat_id].guest) : [];
              let foundGuests = [];
              _.each(guestIdsInThread, (guest_uid) => {
                let found = _.find(guestList, (guest) => guest.guest_uid === guest_uid);
                if(found){
                  foundGuests.push(found);
                }
              })

              //Enforce alphabetical order here.  bug 3428
              participantLookup[thread.chat_id] = _.sortBy(foundGuests, (g) => _.get(g, 'first_name', '') + _.get(g, 'last_name', ''));
            })
            log.log('refreshWorkspaceThreadGuests', participantLookup);
            dispatch({
              type: c.actions.workspace.updateThreadParticipantLookup,
              lookup: participantLookup
            })

            //We wait for just a sec to allow dispatch to update the thread lookup above.
            return utils.waitFor(50);
          })
      }
      else{
        let participantLookup = {};
        return Promise.map(workspaceThreads, (t) => {
          return sapi.Threads.guests(forum_id, host_uid, t.chat_id)
            .then((res) => {
              participantLookup[t.chat_id] = _.remove(res.data, (u) => { return u.guest_uid !== currentAccountUid})

              //Enforce alphabetical order here.  bug 3428
              participantLookup[t.chat_id] = _.sortBy(participantLookup[t.chat_id], (g) => _.get(g, 'first_name', '') + _.get(g, 'last_name', ''));
            })
        })
          .then(() => {
            // log.log('refreshWorkspaceThreadGuests', participantLookup);
            dispatch({
              type: c.actions.workspace.updateThreadParticipantLookup,
              lookup: participantLookup
            })

            //We wait for just a sec to allow dispatch to update the thread lookup above.
            return utils.waitFor(50);
          })
      }
    }
  },

  refreshThreads(forum_id, host_uid, doRefreshGuests){
    return (dispatch, getState) => {
      let threadList = null;
      return sapi.Threads.get(forum_id, host_uid || null)
        .then((threadRes) => {
          threadList = threadRes.data

          //Make sure we're still working with the same workspace that we thought we were.
          let { workspace } = getState().workspace;
          if(doRefreshGuests && _.get(workspace, 'forum_id') === forum_id) {
            return dispatch(this.refreshWorkspaceThreadGuests(forum_id, host_uid, threadList));
          }
          else{
            return Promise.resolve(true);
          }
        })
        .then(() => {
          //Make sure we're still working with the same workspace that we thought we were.
          let { workspace } = getState().workspace;
          if(_.get(workspace, 'forum_id') === forum_id) {
            dispatch({
              type: c.actions.workspace.updateThreads,
              threads: threadList
            })
            let {activeThread} = getState().workspace;
            if (activeThread) {
              let foundThread = _.find(threadList, (t) => t.chat_id === activeThread.chat_id)
              if (foundThread) {
                dispatch({
                  type: c.actions.thread.setActiveThread,
                  thread: foundThread
                })
              }
            }
          }
          // else{
          //   log.warn('PREVENTED THREAD LIST SET FOR WRONG WS');
          // }
        })
    }
  },

  refreshDocs(forum_id, host_uid){
    return (dispatch, getState) => {
      DocPermissionsHelper.clearCachedItem(forum_id);

      return sapi.Docs.list(forum_id, host_uid)
        .then((res) => {
          let docList = res.data;
          dispatch({
            type: c.actions.workspace.updateDocs,
            docs: docList
          })

          let { activeDoc } = getState().workspace;
          if(activeDoc){
            let foundDoc = null;
            _.each(docList, (doc) => {
              if(doc.doc_id === activeDoc.doc_id){
                foundDoc = doc;
              }
            })

            if(foundDoc){
              return dispatch(this.setActiveDoc(foundDoc));
            }
          }
        })
    }
  },

  refreshGuests(forum_id, host_uid){
    return (dispatch) => {
      return sapi.Guests.get(forum_id, host_uid || null)
        .then((guestRes) => {
          dispatch({
            type: c.actions.workspace.updateGuests,
            guests: guestRes.data
          })
          return guestRes.data;
        })
    }
  },

  mapToDispatch(dispatch){
    return {
      findGuestInActiveThreadParticipants : (guest_uid) => dispatch(this.findGuestInActiveThreadParticipants(guest_uid)),
      setLoadingForumId:(forum_id) => dispatch(this.setLoadingForumId(forum_id)),
      refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(this.refreshThreads(forum_id, host_uid, doRefreshGuests)),
      refreshDocs: (forum_id, host_uid) => dispatch(this.refreshDocs(forum_id, host_uid)),
      refreshGuests: (forum_id, host_uid) => dispatch(this.refreshGuests(forum_id, host_uid)),
      refreshWorkspace: (forum_id) => dispatch(this.refreshWorkspace(forum_id)),
      setActiveThread: (forum_id, host_uid, thread) => dispatch(this.setActiveThread(forum_id, host_uid, thread)),
      setActiveDM: (dm) => dispatch(this.setActiveDM(dm)),
    }
  }
}

export default workspaceActions;
