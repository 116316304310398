import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {withVFTranslation} from "../../util/withVFTranslation";

class AlertDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  onEscapeKey(){
    this.closeModal();
  }

  render() {
    let {modalProps, t} = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p className="whitespace-prewrap">{modalProps.message}</p>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

AlertDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AlertDialog));
