import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import PropTypes from 'prop-types';
import colors from "../../util/colors";
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import Loading from "../partials/util/Loading";
import sapi from "../../util/sapi";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import classnames from "classnames";
import utils from "../../util/util";
import ColorGenerator from "../../helpers/color-generator";
import {last} from "pdf-lib";
import he from "he";
import accountActions from "../../actions/account-actions";
import Promise from 'bluebird'
import c from "../../util/const";
import appActions from "../../actions/app-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import {validateEmail} from "../../util/validation";
import ValidationErrors from "../partials/components/ValidationErrors";
import VFPopover from "../partials/components/VFPopover";
import sharedActions from "../../actions/shared-actions";
import api from "../../util/api";
import popupHelper from "../../helpers/popup-helper";
import popoverActions from "../../actions/popover-actions";

class ContactInfoWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading : true,
      showingMenu : false,
      contactInfo : null,
      isEditing : false,
      isSavingContact : false,
      validationErr : [],
      email : '',
      first_name : '',
      last_name : '',
      title : '',
      company : '',
      phone : '',
      address : '',
      note : '',
      isContentWorkspaceContext : false,
      contactEmailValidationStatus : null,

      validatedEmailAddress : '',
      emailValidationResults : null,
      needConfirmEmailClickThrough : false,

      publisherInfo : null,
      changesMade : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;
    this.setState({loading : true})
    this.refreshContactInfo()
      .then(() => {
        this.handleQsActionIfNeeded();
      })
      .catch((err) => {
        log.error('error getting contact', err);
        this.props.showAlert(t("Error loading contact info"), getMessageForError(err, t), () => {
          this.closeModal(false)
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  updateContactEmailValidationStatus() {
    return new Promise((resolve, reject) => {
      let {
        contactInfo
      } = this.state;
      let email = _.get(contactInfo, 'email');

      sapi.AccountEmail.validationStatus(email)
        .then((rawResult) => {
          let res = _.get(rawResult, 'data');
          log.log('emailValidationStatus res', res);

          this.setState({
            contactEmailValidationStatus: res
          }, () => resolve(res));
        })
        .catch((err) => {
          log.log('validationStatus err', err);
          resolve(null);
        })
    })
  }

  doValidateEmailAddress(email){
    return new Promise((resolve, reject) => {
      api.AccountEmail.validateEmail(email)
        .then((res) => {
          log.log('validate email res', res);
          resolve(res.data);
        })
        .catch((err) => {
          log.log('error during email validation', err);
          reject(err);
        })
    })
  }

  handleQsActionIfNeeded(){
    let { qsActionNeeded, qs } = this.props;
    let { contactInfo } = this.state;

    if(qsActionNeeded && qs[c.querystring.goto_block_user] && contactInfo.email_alert_flag){
      this.props.setQsActionNeeded(false);
      this.blockContact();
    }
  }

  isContentWorkspaceContext(contactInfo){
    let { workspaces } = this.props;
    return !!_.find(workspaces,
      (ws) => _.get(ws, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT && ws.forum_id === _.get(contactInfo, 'forum_id'))
  }

  updatePublisherInfo(){
    return new Promise((resolve, reject) => {
      this.props.updatePublisherList()
        .finally(() => {
          let { guest_uid } = this.props.modalProps;
          let foundPublisher = _.find(this.props.publisherList, (p) => p.uid === guest_uid);
          this.setState({publisherInfo : foundPublisher}, () => resolve(true))
        })
    })
  }

  refreshContactInfo(){
    return new Promise((resolve, reject) => {
      let { guest_uid } = this.props.modalProps;
      let contactInfoData = null;
      let isContentWorkspaceContext = false;
      return sapi.Contacts.get(guest_uid)
        .then((res) => {

          contactInfoData = res.data;

          isContentWorkspaceContext = this.isContentWorkspaceContext(contactInfoData);
          if(isContentWorkspaceContext){
            return this.updatePublisherInfo();
          }
          else{
            return Promise.resolve(true);
          }
        })
        .then(() => {
          if(contactInfoData.address){
            contactInfoData.address = he.decode(contactInfoData.address);
          }
          if(contactInfoData.note) {
            contactInfoData.note = he.decode(contactInfoData.note);
          }

          return this.setStateFromContactInfo(contactInfoData)
            .then(() => {
              if(!isContentWorkspaceContext) {
                return this.updateContactEmailValidationStatus()
              }
              else{
                return Promise.resolve(true);
              }
            })
            .then(() => {
              this.setState({isContentWorkspaceContext}, () => resolve(true))
            })
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  setStateFromContactInfo(contactInfoData){
    return new Promise((resolve, reject) => {
      this.setState({
        contactInfo : contactInfoData,
        email : contactInfoData.email,
        first_name : contactInfoData.first_name || '',
        last_name : contactInfoData.last_name || '',
        title : contactInfoData.title || '',
        company : contactInfoData.company || '',
        phone : contactInfoData.phone || '',
        address : contactInfoData.address || '',
        note : contactInfoData.note || ''
      }, () => resolve(true))
    })
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  showMenu(evt) {
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.setState({
      showingMenu : true
    })
  }

  hidePopover(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.setState({
      showingMenu : false
    })
  }

  editContactClickMenu(){
    if(!this.state.showingMenu){
      return;
    }

    this.editContactClick();
  }

  editContactClick(){
    this.hidePopover();
    this.setState({isEditing : true})
  }

  blockContactMenu(){
    if(!this.state.showingMenu){
      return;
    }

    this.blockContact();
  }

  blockContact(){
    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    let {
      contactInfo,
      publisherInfo,
      isContentWorkspaceContext
    } = this.state;

    this.hidePopover();

    let name = null;
    if(isContentWorkspaceContext){
      name = _.get(publisherInfo, 'title');
    }
    else{
      name = `${_.get(contactInfo, 'first_name')} ${_.get(contactInfo, 'last_name')}`;
    }

    this.props.showContactConfirm(
      t('Blocking Contact'),
      name,
      t("Are you sure you want to block all email alerts from this user? The user will not know they've been blocked."),
      t("Block"),
      t("Cancel"),
      (res) => {
        if(res){
          sapi.NotifyBlock.blockGuest(guest_uid)
            .then((res) => {
              return Promise.all([
                this.refreshContactInfo(),
                this.props.updateUserBlockList()
              ])
            })
            .catch((err) => {
              log.error('error blocking guest', err);
              this.props.showAlert(t("Error blocking contact"), getMessageForError(err, t))
            })
            .finally(() => {
              this.setState({changesMade:true})
            })
        }
      }
    )
  }

  unblockContact(){
    if(!this.state.showingMenu){
      return;
    }

    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    this.hidePopover();

    sapi.NotifyBlock.unblockGuest(guest_uid)
      .then((res) => {
        return Promise.all([
          this.refreshContactInfo(),
          this.props.updateUserBlockList()
        ])
      })
      .catch((err) => {
        log.error('error blocking guest', err);
        this.props.showAlert(t("Error unblocking contact"), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({changesMade:true})
      })
  }

  deleteContact(){
    if(!this.state.showingMenu){
      return;
    }

    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    let { contactInfo } = this.state;

    this.hidePopover();

    this.props.showContactConfirm(
      t('Deleting Contact'),
      `${_.get(contactInfo, 'first_name')} ${_.get(contactInfo, 'last_name')}`,
      t("WARNING: Deleting here means deleting everywhere. If ") +
      t("this user is a Guest in any of your Workspaces, they will be ") +
      t("removed from those Workspaces. You will lose any access ") +
      t("you have to Workspaces hosted by this contact. And any ") +
      t("Private Messages with this contact will be lost. ") +
      t("Messages submitted by this contact to Workspace Threads will not be deleted."),
      t("Delete"),
      t("Cancel"),
      (res) => {
        if(res){
          sapi.Contacts.delete(guest_uid)
            .then((res) => {
              this.closeModal(res);
            })
            .catch((err) => {
              log.error("Error deleting contact", err);
              this.props.showAlert(t("Error deleting contact"), getMessageForError(err, t))
            })
        }
      }
    )
  }

  hasUnsavedContactInfoChanges(){
    let {
      contactInfo,
      email,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note,
    } = this.state;

    let sEmail = _.get(contactInfo, 'email') || '';
    let sFirstName = _.get(contactInfo, 'first_name') || '';
    let sLastName = _.get(contactInfo, 'last_name') || '';
    let sTitle = _.get(contactInfo, 'title') || '';
    let sCompany = _.get(contactInfo, 'company') || '';
    let sPhone = _.get(contactInfo, 'phone') || '';
    let sAddress = _.get(contactInfo, 'address') || '';
    let sNote = _.get(contactInfo, 'note') || '';

    log.log('hasUnsavedContactInfoChanges', this.state);

    return sEmail !== email ||
      sFirstName !== first_name ||
      sLastName !== last_name ||
      sTitle !== title ||
      sCompany !== company ||
      sPhone !== phone ||
      sAddress !== address ||
      sNote !== note;

  }

  cancelSaveContact(){
    let { t } = this.props;

    if(!this.hasUnsavedContactInfoChanges()){
      this.setState({
        validationErr : [],
        isEditing : false
      })
      return;
    }

    let { contactInfo } = this.state;
    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to discard your changes?"), (res) => {
      if(res){
        this.setStateFromContactInfo(contactInfo)
        this.setState({
          validationErr : [],
          isEditing : false
        })
      }
    })
  }

  sendInvitationEmail() {
    let { t } = this.props;
    let {contactInfo} = this.state;
    this.props.showConfirm(t('Are you sure?'),
      t("Are you sure you want Verifyle to send a new invitation email to ") + contactInfo.first_name + " " +  contactInfo.last_name + t("?"),
      (res) => {
        if(res){
          sapi.Contacts.sendInvitationLink(contactInfo.guest_uid)
            .then((res) => {
              log.log('invitation sent', res);
              this.props.showAlert(t('Success'), t('Invitation email sent.'));
            })
            .catch((err) => {
              log.error('error sending invitation link', err);
              this.props.showAlert(t('Failed to send invitation'), getMessageForError(err, t));
            })
        }
      })
  }

  doContactValidation(){
    let { t } = this.props;
    let {
      contactInfo,
      email,
      first_name,
      last_name
    } = this.state;
    let err = [];

    //email validation only needs to be done for pending users, confirmed users can't change this.
    if(contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
      if(contactInfo.email !== email){
        if(!validateEmail(email)){
          err.push(t("Please check your contact's email address."));
        }
      }
      if(contactInfo.first_name !== first_name){
        if(!first_name){
          err.push(t("Please enter your contact's first name."));
        }
      }
      if(contactInfo.last_name !== last_name){
        if(!last_name){
          err.push(t("Please enter your contact's last name."));
        }
      }
    }
    this.setState({validationErr : err})
    return err.length === 0;
  }

  needValidateEmailAddress(){
    let {
      email,
      validatedEmailAddress
    } = this.state;

    //email has changed
    if(email !== validatedEmailAddress){
      return true;
    }
    return false;
  }

  saveContact(){
    if(this.state.isSavingContact){
      return;
    }

    this.setState({ isSavingContact : true});
    let { t } = this.props;
    let {
      contactInfo,
      email,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note
    } = this.state;

    if(!this.doContactValidation()){
      this.setState({ isSavingContact : false});
      return;
    }

    let didValidateEmailAddress = false;
    let emailValidationPromise = null;
    if(this.needValidateEmailAddress()){
      this.setState({needConfirmEmailClickThrough : false})
      didValidateEmailAddress = true;
      emailValidationPromise = this.doValidateEmailAddress(email)
    }
    else{
      emailValidationPromise = Promise.resolve(true);
    }

    emailValidationPromise
      .then((emailValidationRes) => {

        if(didValidateEmailAddress){
          this.setState({
            validatedEmailAddress : email,
            emailValidationResults : emailValidationRes
          });
        }

        log.log('email validate res', emailValidationRes);
        if(utils.hasEmailValidationInvalidFlag(emailValidationRes)){
          let didYouMeanSuggestion = _.get(emailValidationRes, 'did_you_mean');
          if(didYouMeanSuggestion){
            this.props.popoverAction.showPopover(c.popovers.DID_YOU_MEAN_MENU)
          }
          this.setState({needConfirmEmailClickThrough : true});
          return false;
        }
        else{
          if(this.state.needConfirmEmailClickThrough){
            sapi.AccountEmail.validationStatus(email, true)
            this.setState({needConfirmEmailClickThrough: false});
          }
        }

        //Don't save email address unless it has changed, and the guest is pending.  bug 2367
        //we want to do the same for first_name/last_name as well.
        let firstNameSave = null;
        if(contactInfo.first_name !== first_name && contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
          firstNameSave = first_name;
        }
        let lastNameSave = null;
        if(contactInfo.last_name !== last_name && contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
          lastNameSave = last_name;
        }
        let saveEmail = null;
        if(contactInfo.email !== email && contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
          saveEmail = email;
        }

        return sapi.Contacts.update(
          contactInfo.guest_uid,
          saveEmail,
          firstNameSave,
          lastNameSave,
          title,
          company,
          phone,
          address,
          note
        )
      })
      .then((res) => {
        if(res){
          this.setState({isEditing : false, changesMade : true})
          return this.refreshContactInfo()
        }
      })
      .catch((err) => {
        log.error('error updating contact', err);
        this.props.showAlert(t("Unable to save contact"), getMessageForError(err, t));
      })
      .finally(() => {
        this.setState({ isSavingContact : false});
      })
  }

  renderStatus(status){
    let { t } = this.props;
    let {
      contactEmailValidationStatus
    } = this.state;

    let isEmailStatusInvalid = utils.hasEmailValidationInvalidFlag(contactEmailValidationStatus);

    if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_CONFIRM){
      if(isEmailStatusInvalid){
        return (
          <div>
            <p className="font-italic text-warning mb-0">
              {t("- Unable to Validate -")}
            </p>
            <div className="mt-2">
              {this.renderConfirmedEmailValidationWarningMessage()}
            </div>
          </div>
        )
      }
      else{
        return (
          <p className="font-italic secondary-text-color">
            {t("- Confirmed -")}
          </p>
        )
      }
    }
    else if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_DELETED){
      return (
        <p className="font-italic secondary-text-color">
          {t("- Deleted -")}
        </p>
      )
    }
    else if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
      if(isEmailStatusInvalid){
        return (
          <div>
            <p className="font-italic text-warning mb-0">
              {t("- Unable to Validate -")}
            </p>
            <div className="mt-2">
              {this.renderEmailValidationWarningMessage()}
            </div>
          </div>
        )
      }
      else{
        return (
          <div className="mb-2">
            <p className="font-italic secondary-text-color mb-0">
              {t("- Pending -")}
            </p>
            <div>
              <button className="btn btn-link primary-color"
                      onClick={this.sendInvitationEmail.bind(this)}>
                {t("Send Invitation Email")}
              </button>
            </div>
          </div>
        )
      }
    }
  }

  getPopoverContent() {
    let {guest_uid, t} = this.props;
    let {
      contactInfo,
      publisherInfo,
      isContentWorkspaceContext
    } = this.state;

    return (
      <div>
        <ul className="popover-content list-group" style={styles.popoverWrap}>

          {isContentWorkspaceContext &&
            <a style={{...styles.menuItemTop, ...{color : colors.LIGHT, backgroundColor : ColorGenerator.generateColorFromId(publisherInfo.title)}}}
               className="list-group-item list-group-item-action">
              <span>
                <i style={styles.menuIcons} className="icon ion-android-person mr-3"/>
                {_.get(publisherInfo, 'title', '')}
              </span>
            </a>
          }

          {!isContentWorkspaceContext &&
          <a style={{...styles.menuItemTop, ...{color : colors.LIGHT, backgroundColor : ColorGenerator.generateColorFromId(guest_uid)}}}
             className="list-group-item list-group-item-action">
            <span>
              <i style={styles.menuIcons} className="icon ion-android-person mr-3"/>
              {contactInfo.first_name} {contactInfo.last_name}
            </span>
          </a>
          }

          {!isContentWorkspaceContext &&
          <a onClick={this.editContactClickMenu.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-edit mr-3"/>
              {t("Add/Edit Contact Info")}
            </div>
          </a>
          }

          {contactInfo.email_alert_flag &&
          <a onClick={this.blockContactMenu.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-android-notifications-off mr-3"/>
              {t("Block Contact")}
            </div>
          </a>
          }

          {!contactInfo.email_alert_flag &&
          <a onClick={this.unblockContact.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-android-notifications mr-3"/>
              {t("Unblock Contact")}
            </div>
          </a>
          }

          {!isContentWorkspaceContext &&
            <a onClick={this.deleteContact.bind(this)}
               style={styles.menuItemBottom}
               className="list-group-item list-group-item-action has-pointer assertive-color">
            <span>
              <i style={styles.menuIcons} className="icon ion-close mr-3"/>
              {t("Delete Contact")}
            </span>
            </a>
          }

        </ul>
      </div>
    )
  }

  renderEmailValidationWarningIfNeeded(){
    let {
      needConfirmEmailClickThrough
    } = this.state;
    if(needConfirmEmailClickThrough){
      return this.renderEmailValidationWarningMessage();
    }
  }

  renderEmailValidationWarningMessage(){
    let { isEditing } = this.state;
    let { t } = this.props;
    return (
      <div className="alert alert-info mb-0" role="alert">
        <h5 className="alert-heading mb-1">
          <i className="icon ion-information-circled pr-2" />
          {t("Informational")}
        </h5>
        <p style={{fontSize: '14px'}}>
          {t("The email address you entered may contain a typo, or may be an invalid email address. Please double-check what you’ve entered for errors.")}
          {isEditing && (" " + t("Tap \"Save Anyway\" if the email address is correct."))}
        </p>
      </div>
    )
  }

  renderConfirmedEmailValidationWarningMessage(){
    let { t } = this.props;
    return (
      <div className="alert alert-info mb-0" role="alert">
        <h5 className="alert-heading mb-1">
          <i className="icon ion-information-circled pr-2" />
          {t("Informational")}
        </h5>
        <p style={{fontSize: '14px'}}>
          {t("Verifyle has had trouble successfully delivering email to this address. The address may not be valid anymore. You many need to notify this user directly.")}
        </p>
      </div>
    )
  }

  renderDidYouMeanContents(popupArgs) {
    let { t } = this.props;
    let { emailValidationResults } = this.state;
    let didYouMeanSuggestion = _.get(emailValidationResults, 'did_you_mean');
    if (didYouMeanSuggestion) {
      let useItClick = () => {
        this.setState({
          email : didYouMeanSuggestion,
          needConfirmEmailClickThrough: false,
        })
        this.props.popoverAction.hidePopover();
      }
      let loseItClick = () => {
        this.props.popoverAction.hidePopover()
      }

      return popupHelper.getDidYouMeanContents(
        t,
        didYouMeanSuggestion,
        popupArgs,
        () => useItClick(),
        () => loseItClick()
      )
    }
  }

  renderEditing(){
    let { t } = this.props;
    let {
      contactInfo,
      email,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note,
      validationErr
    } = this.state;

    return (
      <div className="text-center mt-5" style={{ minHeight : '300px' }}>
        <UserBadge large={true} guest={contactInfo}/>
        <h3 className="mt-2">
          {contactInfo.email}
        </h3>
        <p>
          {`${first_name} ${last_name}`}
        </p>
        <div className="text-left">

          {contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING &&
            <>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">{t("Email")}</label>
                <div className="col-sm-9">
                  <VFPopover
                    isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DID_YOU_MEAN_MENU)}
                    positions={['right', 'bottom']}
                    reposition={false}
                    containerClassName="preview-container-cls copied-popover-container"
                    onClickOutside={() => this.props.popoverAction.hidePopover()}
                    getMenuContent={this.renderDidYouMeanContents.bind(this)}>
                  <input className="form-control"
                         type={'email'}
                         value={email}
                         onChange={(evt) => this.setState({email: evt.target.value})}
                         placeholder={t('Email')}/>
                  </VFPopover>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">{t("First Name")}</label>
                <div className="col-sm-9">
                  <input className="form-control"
                         type={'text'}
                         value={first_name}
                         onChange={(evt) => this.setState({first_name: evt.target.value})}
                         placeholder={t('First Name')} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">{t("Last Name")}</label>
                <div className="col-sm-9">
                  <input className="form-control"
                         type={'text'}
                         value={last_name}
                         onChange={(evt) => this.setState({last_name: evt.target.value})}
                         placeholder={t('Last Name')} />
                </div>
              </div>
            </>
          }

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Title")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={title}
                     onChange={(evt) => this.setState({title: evt.target.value})}
                     placeholder={t('Title')} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Company")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={company}
                     onChange={(evt) => this.setState({company: evt.target.value})}
                     placeholder={t('Company')} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Phone")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={phone}
                     onChange={(evt) => this.setState({phone: evt.target.value})}
                     placeholder={t('Phone')} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Address")}</label>
            <div className="col-sm-9">
              <textarea className="form-control no-resize"
                     rows={3}
                     value={address}
                     onChange={(evt) => this.setState({address: evt.target.value})}
                     placeholder={t('Address')} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Notes")}</label>
            <div className="col-sm-9">
              <textarea className="form-control no-resize"
                     rows={3}
                     value={note}
                     onChange={(evt) => this.setState({note: evt.target.value})}
                     placeholder={t('Notes')} />
            </div>
          </div>

          {this.renderEmailValidationWarningIfNeeded()}
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} />}

        </div>
      </div>
    )
  }

  renderView(){
    let { t } = this.props;
    let {
      contactInfo,
      publisherInfo,
      isContentWorkspaceContext
    } = this.state;

    if(!contactInfo){
      return null;
    }

    return (
      <div className="text-center mt-5" style={{ minHeight : '300px' }}>
        <UserBadge large={true} publisherInfo={publisherInfo} guest={contactInfo}/>
        {!isContentWorkspaceContext &&
          <h3 className="mt-3">
            {contactInfo.first_name} {contactInfo.last_name}
            <a onClick={this.editContactClick.bind(this)} className="btn btn-link pl-3">
              <i className="icon ion-edit"/>
            </a>
          </h3>
        }
        {isContentWorkspaceContext &&
          <h3 className="mt-3">
            {_.get(publisherInfo, 'title', '')}
          </h3>
        }

        {!isContentWorkspaceContext &&
          <>
            {contactInfo.title &&
              <h4>
                {contactInfo.title}
              </h4>
            }
            {contactInfo.company &&
              <h4 className="font-weight-bold">
                {contactInfo.company}
              </h4>
            }
            {contactInfo.email &&
              <p>
                {contactInfo.email}
              </p>
            }
            {contactInfo.phone &&
              <p>
                {contactInfo.phone}
              </p>
            }
            {contactInfo.address &&
              <p>
                {contactInfo.address}
              </p>
            }
            {contactInfo.note &&
              <p>
                {contactInfo.note}
              </p>
            }
          </>
        }
        {contactInfo.status && this.renderStatus(contactInfo.status)}
        {!contactInfo.email_alert_flag && contactInfo.status !== ContactInfoWindow.CONFIRM_STATUS.GUEST_DELETED &&
        <div style={styles.blockedBadge} className="d-inline-block">
          {t("BLOCKED")}
        </div>
        }

      </div>
    )
  }

  onEscapeKey(){
    this.closeModal(this.state.changesMade);
  }

  render() {
    let { t } = this.props;
    let { loading, isEditing, showingMenu, needConfirmEmailClickThrough, changesMade } = this.state;

    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <button type="button" className="close" onClick={this.closeModal.bind(this, changesMade)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
          {!isEditing &&
          <VFPopover
            isPopoverOpen={showingMenu}
            positions={['bottom']}
            reposition={false}
            onClickOutside={this.hidePopover.bind(this)}
            getMenuContent={this.getPopoverContent.bind(this)}>
            <div style={styles.topRightButton}
                 onClick={this.showMenu.bind(this)}>
              <i className="icon ion-gear-b" />
            </div>
          </VFPopover>
          }
        </div>
        <div className="modal-body position-relative">
          {!isEditing &&
            this.renderView()
          }
          {isEditing &&
          this.renderEditing()
          }
        </div>
        {isEditing && <div className="modal-footer">
          <Button className={'btn btn-secondary mr-2'}
                  onClick={this.cancelSaveContact.bind(this, changesMade)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={this.state.isSavingContact}
                  onClick={this.saveContact.bind(this, changesMade)}>{needConfirmEmailClickThrough ? t("Save Anyway") : t("Save")}</Button>
        </div>
        }
      </div>
    )
  }
}

ContactInfoWindow.CONFIRM_STATUS = {
  GUEST_PENDING : 'GUEST_PENDING',
  GUEST_CONFIRM : 'GUEST_CONFIRM',
  GUEST_DELETED : 'GUEST_DELETED'
}

const styles = {
  topLeftButton : {
    position : 'absolute',
    top: '15px',
    left : '30px',
    fontSize : '60px',
    cursor: 'pointer',
    opacity: 1
  },
  topRightButton : {
    position : 'absolute',
    top: '5px',
    right : '16px',
    fontSize : '30px',
    zIndex : 1000,
    cursor: 'pointer'
  },
  blockedBadge : {
    backgroundColor : colors.ASSERTIVE,
    color : colors.LIGHT,
    borderRadius: '3px',
    padding : '3px 5px'
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsActionNeeded : state.app.qsActionNeeded,
    showingPopoverKey : state.popover.showingPopoverKey,

    //we need workspaces so that we can look up any content workspaces that might
    //correspond to the forum_id for this contact, which would indicate it's the contact/info for content workspace.
    workspaces : state.shared.workspaces,
    publisherList : state.shared.publisherList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserBlockList: () => dispatch(accountActions.updateUserBlockList()),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  }
};

ContactInfoWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactInfoWindow));
