import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import {withVFTranslation} from "../../util/withVFTranslation";

class FinishSignatureRequestDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    const {modalProps} = this.props;

    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  onEscapeKey(){
    this.closeModal(FinishSignatureRequestDialog.DIALOG_RESULT.Cancel);
  }

  render() {
    let {modalProps, t} = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Request Signature")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, FinishSignatureRequestDialog.DIALOG_RESULT.Cancel)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p className="whitespace-prewrap">
            {t("Your signature request is ready to submit! Once submitted you will be able to cancel the request, but you will not be able to edit it. If you want to edit or double check anything, review your request now.")}
          </p>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, FinishSignatureRequestDialog.DIALOG_RESULT.Review)}>{t("Review Request")}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this, FinishSignatureRequestDialog.DIALOG_RESULT.Submit)}>{t("Submit Request")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

FinishSignatureRequestDialog.DIALOG_RESULT = {
  Cancel : 'cancel',
  Review : 'review',
  Submit : 'submit'
}

FinishSignatureRequestDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(FinishSignatureRequestDialog));
