class ChangeManagerUpdate{

  constructor(newMessages, updatedMessages, allMessages){
    this.newMessages = newMessages;
    this.updatedMessages = updatedMessages;
    this.allMessages = allMessages;
  }
}

export default ChangeManagerUpdate;
