import c from "./const";
import React from "react";
import {
  AE,
  AU,
  BR,
  CA,
  CH,
  CZ,
  DK,
  EU,
  GB,
  HK,
  HR,
  HU,
  JP,
  MX,
  MY,
  NO,
  NZ,
  PL,
  US,
  RO, SE, SG, TH, GI
} from "country-flag-icons/react/3x2";
import log from "./log";

const flagIconHelper = {

  //These come back as <svg>
  getFlagForCurrencyCode(code){
    if(code === c.INVOICE_CURRENCY_CODES.USD){
      return <US />
    }
    else if( code === c.INVOICE_CURRENCY_CODES.HKD){
      return <HK />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.JPY){
      return <JP />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.GBP){
      return <GB />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.AED){
      return <AE />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.AUD){
      return <AU />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.BRL){
      return <BR />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.CAD){
      return <CA />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.CHF){
      return <CH />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.CZK){
      return <CZ />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.DKK){
      return <DK />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.EUR){
      return <EU />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.HRK){
      return <HR />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.HUF){
      return <HU />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.MXN){
      return <MX />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.MYR){
      return <MY />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.NOK){
      return <NO />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.NZD){
      return <NZ />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.PLN){
      return <PL />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.RON){
      return <RO />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.SEK){
      return <SE />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.SGD){
      return <SG />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.THB){
      return <TH />
    }
    else if(code === c.INVOICE_CURRENCY_CODES.GIP){
      return <GI />
    }
    else{
      log.warn('flag not found for code', code);
      return null;
    }
  },

}

export default flagIconHelper;
