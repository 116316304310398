import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import sharedActions from "../../actions/shared-actions";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import colors from "../../util/colors";
import {withVFTranslation} from "../../util/withVFTranslation";
import VFPopover from "../partials/components/VFPopover";
import _ from 'lodash';
import PasswordStrengthMeter from "../partials/components/PasswordStrengthMeter";
import util from "../../util/util";

class GeneratedPasswordDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      generatedPwd : '',
      validationErr : [],
      newPassword : '',
      cfmPassword : '',
      showPopover : false,
      isDoingSave : false,
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { t } = this.props;
    sapi.Password.getPasswordReset()
      .then((res) => {
        this.setState({
          generatedPwd : res.data.password
        })
      })
      .catch((err) => {
        log.error('error loading password reset', err);
        this.props.showAlert(t('There was a problem generating a password for you'), getMessageForError(err, t), () => {
          this.closeModal();
        })
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    this.props.close();
  }

  doChangePassword(){
    if(!this.doValidate()){
      return;
    }
    let { t } = this.props;
    let { newPassword, generatedPwd} = this.state;
    this.setState({isDoingSave : true})
    sapi.Password.reset(generatedPwd, newPassword)
      .then((res) => {
        return this.props.updateAccountInfo();
      })
      .then(() => {
        this.closeModal();
        this.setState({isDoingSave : false})
      })
      .catch((err) => {
        log.error('error changing password', err);
        this.setState({
          validationErr : [ getMessageForError(err, t) ],
          isDoingSave : false
        })
      })
  }

  doValidate() {
    let { t } = this.props;
    let { newPassword, cfmPassword } = this.state;

    let err = [];
    if(!newPassword && newPassword.length === 0){
      err.push(t('Please enter your new password'));
    }
    if(!cfmPassword && cfmPassword.length === 0){
      err.push(t('Please confirm your new password'));
    }

    if(newPassword && cfmPassword && newPassword !== cfmPassword){
      err.push(t('Your passwords do not match'));
    }

    if(newPassword && cfmPassword && newPassword.length < 6){
      err.push(t('Your new password must be at least 6 characters.'));
    }

    if(err.length === 0){
      let score = util.scorePassword(newPassword);
      if(score < PasswordStrengthMeter.MEDIUM_PASS_SCORE){
        err.push(t("Please choose a stronger password."))
      }
    }

    this.setState({
      validationErr : err
    })
    return err.length === 0;
  }

  renderHiddenUserName(){
    let { accountInfo, t } = this.props;

    let login = _.get(accountInfo, 'login', '');
    return (
      <div className="d-none">
        <input type={'text'}
               name="email"
               autoComplete={'username'}
               onChange={_.noop}
               value={login}/>
      </div>
    )
  }

  onFormSubmit(evt){
    //This form is here to prevent a browser warning about password fields outside forms
    //I think it might be related to a problem focusing the password input in some cases.
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    return false;
  }

  renderChangePassword(){
    let { t } = this.props;
    let { validationErr, generatedPwd, newPassword, cfmPassword, isDoingSave } = this.state;

    return (
      <>
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Verifyle Password")}</h5>
        </div>
        <form onSubmit={this.onFormSubmit.bind(this)}>
          <div className="modal-body">

            {this.renderHiddenUserName()}
            <div className="row">
              <div className="col">
                <p className="text-center">
                  {t("Create a password for logging into your Verifyle account.")}
                </p>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <div className="form-group">
                  <label>{t("New Password")}</label>
                  <input className={'form-control'}
                         type={'password'}
                         autoComplete="new-password"
                         value={newPassword}
                         onChange={(evt) => this.setState({newPassword: evt.target.value})}
                         placeholder={t('Enter your new password')}/>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col'}>
                <div className="form-group">
                  <label>{t("Confirm Password")}</label>
                  <input className={'form-control'}
                         type={'password'}
                         autoComplete="new-password"
                         value={cfmPassword}
                         onChange={(evt) => this.setState({cfmPassword: evt.target.value})}
                         placeholder={t('Confirm your new password')}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mb-2">
                <div className="form-group">
                  <label>
                  <span className="mr-2">
                    {t("Password Strength")}
                  </span>
                    {PasswordStrengthMeter.renderPasswordStrengthText(t, newPassword)}
                  </label>
                  <PasswordStrengthMeter password={newPassword}/>
                </div>
              </div>
            </div>

            {validationErr.length > 0 && <ValidationErrors errors={validationErr}/>}
          </div>
          <div className="modal-footer">
            <Button className={'btn btn-primary'}
                    disabled={isDoingSave || newPassword.length === 0 || cfmPassword.length === 0}
                    onClick={this.doChangePassword.bind(this)}>
              {t("Save New Password")}
            </Button>
          </div>
        </form>
      </>
    )
  }

  onEscapeKey(){
    //ignore escape key here.  We do not want to dismiss this dialog.
  }

  render() {
    return (
      <div className="modal-content">
        {this.renderChangePassword()}
      </div>
    )
  }
}

const styles = {
  tooltipWrap: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: colors.DARK,
    color: colors.LIGHT,
    fontSize: '12px'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch)
  };
};

GeneratedPasswordDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(GeneratedPasswordDialog));
