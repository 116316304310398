import React, {Component, Fragment} from 'react';
import authActions from "../../actions/auth-actions";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import {connect} from "react-redux";
import Image from "../partials/elements/Image";
import logo from "../../resources/logo-w-text-white.png";
import _ from "lodash";
import log from "../../util/log";
import api from "../../util/api";
import colors from "../../util/colors";
import c from "../../util/const";
import Button from "../partials/elements/Button";
import UserBadge from "../partials/badges/UserBadge";
import UploadHelper from "../partials/components/UploadHelper";
import DocExtensionPlaceholder from "../partials/elements/DocExtensionPlaceholder";
import {Helmet} from "react-helmet";
import vfLocalStorage from "../../util/local-storage";
import config from "../../util/config";
import EndpointPicker from "../partials/auth/EndpointPicker";
import EmailCard from "../partials/auth/EmailCard";
import {CSSTransition} from "react-transition-group";
import PasswordCard from "../partials/auth/PasswordCard";
import BoardingCard from "../partials/auth/BoardingCard";
import ForgotPasswordCard from "../partials/auth/ForgotPasswordCard";
import TFACard from "../partials/auth/TFACard";
import TFAPwdResetCard from "../partials/auth/TFAPwdResetCard";
import AuthControls from "../partials/auth/AuthControls";
import PulseButton from "../partials/elements/PulseButton";
import authHelper from "../../helpers/auth-helper";
import cookieHelper from "../../helpers/cookie-helper";
import redirectHelper from "../../util/redirect-helper";
import sapi from "../../util/sapi";
import ProgressBar from "../partials/elements/ProgressBar";
import ValidationErrors from "../partials/components/ValidationErrors";
import Loading from "../partials/util/Loading";
import Scroll from "react-scroll";
import HandleBoardingCard from "../partials/auth/HandleBoardingCard";
import HandleForgotPasswordCard from "../partials/auth/HandleForgotPasswordCard";
import HandleTFAPwdResetCard from "../partials/auth/HandleTFAPwdResetCard";
import filters from "../../helpers/filters";
import util from "../../util/util";

class Handle extends Component {

  ELEMENT_IDS = {
    PAGE_BOTTOM : 'page-bottom',
    AUTH_CONTROLS : 'auth-controls'
  }

  constructor(props) {
    super(props);

    this.state = {
      appLoad : false,
      appLoadTimer : null,

      isThisYourHandle : false,
      handleLoading : false,
      handleLoadError : null,
      handleData : null,
      handleUserName : '',
      handleText : '',
      handleLogo : null,
      hoveringDocId : null,
      sendMsgText : '',
      sendAttachments : [],
      hasUploadStarted : false,
      uploadProgress : 0,
      hasUploadFinished : false,

      hasValidToken : false,
      localStorageEmailAddress : null,
      hasStartedAuthentication : false,
      emailCardRef : null,
      pwdCardRef : null,
      boardingCardRef : null,
      forgotPwdCardRef : null,
      tfaCardRef : null,
      tfaPwdResetCardRef : null,
      isAuthLoading : false,
      validationErrors : [],
      hideAuthButtonControls : false,
    }
  }

  componentDidMount() {
    this.init();
  }

  init(){
    this.loadHandleData();

    let {
      step
    } = this.props;

    this.authenticateSessionIfNeeded();
    let lsEmail = vfLocalStorage.get(c.localstorage.email);
    if(lsEmail){
      //If they already have email populated, just start them at the password screen.
      this.props.setEmail(lsEmail);
      this.props.setNextStep(c.authSteps.password);
      this.setState({localStorageEmailAddress : lsEmail});
    }
    else if(!step){
      this.props.setNextStep(c.authSteps.email);
    }

    let timer = setTimeout(() => {
      this.setState({
        appLoad: true
      })
    }, 500);
    this.setState({appLoadTimer : timer});
  }

  authenticateSessionIfNeeded(){
    authHelper.testSavedAuthentication()
      .then((res) => {
        log.log('auth success', res);
        this.setState({hasValidToken : true})
      })
      .catch((err) => {
        log.log('no auth', err);
        this.setState({hasValidToken : false})
      })
  }

  componentWillUnmount() {
    let { appLoadTimer, emailCardRef } = this.state;

    if(appLoadTimer){
      clearTimeout(appLoadTimer);
    }

    if(emailCardRef){
      this.setState({emailCardRef : null})
    }
  }

  loadHandleData(){
    let {location} = this.props;
    let pathname = _.get(location, 'pathname', '');
    log.log('handle page loaded', location, pathname);

    let parsedHandle = pathname.substr(_.lastIndexOf(pathname, "@") + 1);
    log.log('parsed handle', parsedHandle);

    this.setState({handleLoading : true})
    api.Handle.info(parsedHandle)
      .then((res) => {
        log.log('fetched handle', res);
        let { localStorageEmailAddress } = this.state;

        let handleData = _.get(res, 'data');
        let handleUserName = _.get(handleData, 'first_name') + ' ' + _.get(handleData, 'last_name');
        let handleEmail = _.get(handleData, 'email_address');
        let handleLogo = _.get(handleData, 'logo');
        let isThisYourHandle = handleEmail === localStorageEmailAddress;
        if(handleLogo === c.BLANK_LOGO){
          handleLogo = null;
        }
        let handleText = _.get(handleData, 'text');
        this.setState({
          handleData,
          handleUserName,
          handleEmail,
          handleLogo,
          handleText,
          isThisYourHandle
        })
      })
      .catch((err) => {
        log.log('error fetching handle', err);
        this.setState({handleLoadError : err})
      })
      .finally(() => {
        this.setState({handleLoading : false})
      })
  }

  onAddAttachment(files){
    log.log('onAddAttachment', files);
    this.setState({validationErrors : []})
    this.setState({sendAttachments : _.concat(this.state.sendAttachments, files)}, () => {
      setTimeout(() => {
        this.scrollToElement(this.ELEMENT_IDS.PAGE_BOTTOM)
      }, 250)
    })
  }

  onRemoveAttachment(docId){
    let copy = _.concat([], this.state.sendAttachments);
    _.remove(copy, (d) => d.uniqueId === docId);
    this.setState({sendAttachments : copy});
  }

  onFormSubmit(evt){
    //This form is here to prevent a browser warning about password fields outside forms
    //I think it might be related to a problem focusing the password input in some cases.
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    return false;
  }

  setAuthLoading(isLoading){
    this.setState({isAuthLoading : isLoading});
  }

  setAuthErrors(errors){
    this.setState({validationErrors : errors});
  }

  setHideAuthButtonControls(hideControls){
    this.setState({
      hideAuthButtonControls : hideControls
    })
  }

  getCardRefForStep(){
    let { step } = this.props;

    let ref = null;
    if(step === c.authSteps.email){
      ref = this.state.emailCardRef;
    }
    else if(step === c.authSteps.forgotPassword){
      ref = this.state.forgotPwdCardRef;
    }
    else if(step === c.authSteps.password){
      ref = this.state.pwdCardRef;
    }
    else if(step === c.authSteps.signup){
      ref = this.state.boardingCardRef;
    }
    else if(step === c.authSteps.tfa){
      ref = this.state.tfaCardRef;
    }
    else if(step === c.authSteps.tfaPwdReset){
      ref = this.state.tfaPwdResetCardRef;
    }

    return ref;
  }

  doStartOverButtonClick(){

    this.props.setEmailValidationPerformed(false);
    this.props.setEmailValidationResults(null);
    this.props.setShowingInvalidEmailWarning(false);

    this.setState({
      isAuthLoading : false,
      validationErrors : [],
      hideAuthButtonControls : false
    })

    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }

    if(ref.onStartOverButtonClick) {
      //Each card has this function implemented.
      ref.onStartOverButtonClick();
    }
  }

  onNextBtnClick(){
    let { t } = this.props;
    let { isThisYourHandle } = this.state;
    if(isThisYourHandle){
      this.props.showAlert(t('Unable to Submit'), t("This Public Upload Page is yours. You cannot send documents or messages to yourself."))
      return;
    }

    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }

    //Each card has this function implemented.
    ref.doNextButtonClicked();
    this.scrollToElement(this.ELEMENT_IDS.AUTH_CONTROLS)
  }

  onWelcomeLinkClick(){
    log.log('on welcome link click');
    this.props.history.push('/');
  }

  getHelpText() {
    let {
      step,
      t
    } = this.props;

    let text = t("Who should we say this is from?");

    let headerText = null;
    if(text){
      headerText = (
        <div className={'row'}>
          <div className={'col text-center'}>
            <p className={'light-color my-1'}
               style={{fontSize: '20px', minHeight: '54px'}}>{text}</p>
          </div>
        </div>
      )
    }
    else{
      headerText = (
        <div className={'row'}>
          <div className={'col'}>
            <p className={'light-color my-1'}
               style={{fontSize: '20px', minHeight: '54px'}} />
          </div>
        </div>
      )
    }

    return (
      <div className="mb-4">
        {headerText}
      </div>
    )
  }

  doUploadValidation(){
    let err = [];
    let {
      t
    } = this.props;
    let {
      sendAttachments,
      sendMsgText
    } = this.state;

    if(sendAttachments.length === 0 && sendMsgText.length === 0){
      err.push(t("Please enter a message, or add at least one attachment to upload."));
    }

    this.setState({validationErrors : err});
    return err.length === 0;
  }

  addRecipientToContactsIfNeeded(){
    return new Promise((resolve, reject) => {
      let {
        handleData
      } = this.state;
      let recipientUid = _.get(handleData, 'uid');
      sapi.DM.list()
        .then((res) => {
          let dmList = _.get(res, 'data', []);
          let found = _.find(dmList, (dm) => dm.guest_uid === recipientUid);
          if(!found){
            let contact = {
              first_name : _.get(handleData, 'first_name'),
              last_name : _.get(handleData, 'last_name'),
              email : _.get(handleData, 'email_address')
            }
            return resolve(sapi.Contacts.add([contact]));
          }
          else{
            //found them!
            resolve(true);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  doUploadFiles(){
    let {
      handleData,
      sendMsgText,
      sendAttachments
    } = this.state;
    let recipientUid = _.get(handleData, 'uid');

    let progressFn = (p) => {
      log.log('handle progressfn', p);
      this.setState({uploadProgress : p})
    }

    //We want to show the progress for a minimum of 2 seconds
    //So get a 2 second promise, and wait for it alongside the request.
    //Make sure to return the result of the request here.
    let minWaitPromise = util.waitFor(2000);
    if(sendAttachments.length === 0){

      //hack the upload progress to show 100 percent.
      //in this case we don't have normal progress, and the
      //request is likely very fast, which means we're likely waiting for the
      //min wait timer.  Better to show 100% progress for a second.
      progressFn({percent: 100});

      return Promise.all([
        sapi.DM.sendMessage(recipientUid, sendMsgText, null, true),
        minWaitPromise
      ])
        .then((res) => {
          return res[0];
        })
    }
    else{
      return Promise.all([
        sapi.DM.upload(recipientUid, sendAttachments, sendMsgText, false, progressFn, null, true),
        minWaitPromise
      ])
        .then((res) => {
          return res[0];
        })
    }
  }

  uploadMessageAndFiles(){
    if(!this.doUploadValidation()){
      return;
    }

    this.setState({hasUploadStarted : true});
    this.addRecipientToContactsIfNeeded()
      .then((res) => {
        return this.doUploadFiles();
      })
      .then((res) => {
        log.log('upload finished!', res);
        this.setState({ hasUploadFinished : true})
      })
      .catch((err) => {
        log.log('error during msg upload', err);
        this.setState({hasUploadStarted : false});
      })
  }

  onFirstNextButtonClicked(){
    let { t } = this.props;
    let {
      hasValidToken,
      isThisYourHandle
    } = this.state;
    if(isThisYourHandle){
      this.props.showAlert(t('Unable to Submit'), t("This Public Upload Page is yours. You cannot send documents or messages to yourself."))
      return;
    }

    if(hasValidToken){
      this.uploadMessageAndFiles();
      this.scrollToElement(this.ELEMENT_IDS.PAGE_BOTTOM)
    }
    else{
      if(!this.doUploadValidation()){
        return;
      }

      this.setState({hasStartedAuthentication : true}, () => {
        this.scrollToElement(this.ELEMENT_IDS.AUTH_CONTROLS)
      })
    }
  }

  onTokenReceived(token){
    log.log('token received!', token);

    //If we have a valid token, that means we have a valid user.
    //set their email.
    let {
      email
    } = this.props;
    vfLocalStorage.set(c.localstorage.email, email);

    this.uploadMessageAndFiles();
    this.scrollToElement(this.ELEMENT_IDS.PAGE_BOTTOM)
  }

  scrollToElement(id){
    Scroll.scroller.scrollTo(id, {
      duration: 500,
      smooth: true,
    })
  }

  onLogoutClick(){
    this.setState({localStorageEmailAddress : ''})
    Promise.all([
      vfLocalStorage.remove(c.localstorage.email),
      vfLocalStorage.remove(c.localstorage.token),
      vfLocalStorage.remove(c.localstorage.vip),
      vfLocalStorage.remove(c.localstorage.sid)
    ])
      .then(() => {
        this.init();
      })
  }

  renderHeader(){
    let {
      t
    } = this.props;

    return (
      <>
        <div className={'row justify-content-center'}>
          <div className="col text-center" style={{marginTop: '5vh'}}>
            <div className="d-flex justify-content-center h-100">
              <Image src={logo}
                     className={'mx-auto d-block'}
                     imgHeight={90}
                     alt={t('logo')}/>
            </div>
          </div>
        </div>

        <div className={'row justify-content-center mt-3'}>
          <div className={'col text-center'}>
            <div className="typing-animation-container">
              <p className="typed">{t("Private ad-free encrypted communication.")}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderWelcomeCardIfNeeded(showLoginCardIfNoAuth){
    let {
      localStorageEmailAddress,
      hasUploadStarted
    } = this.state;
    let {
      t
    } = this.props;

    if(localStorageEmailAddress && localStorageEmailAddress.length > 0){
      return (
        <div className={'row justify-content-center mt-3'}>
          <div className={'col-md-8 col-lg-6 text-center'}>

            <div className="handle-welcome-card">
              <p>
                {t("Welcome back to Verifyle!")}
              </p>
              <div className="handle-welcome-card-inner"
                   onClick={() => !hasUploadStarted ? this.onWelcomeLinkClick() : _.noop}
                   style={{display: 'flex'}}>
                <div className="text-left" style={{flex: 'auto'}}>
                  <p className="font-weight-bold mb-0">
                    {localStorageEmailAddress}
                  </p>
                  <p className="mb-0">
                    {t("Go to your account")}
                  </p>
                </div>
                <div className="mx-1" style={{lineHeight: '48px'}}>
                  <i className="icon ion-chevron-right" />
                </div>
              </div>

              <div>
                <div className="text-right mt-3 small">
                  <span style={{display: 'inline-block', verticalAlign: 'middle'}}>
                    {t("Not you?")}
                  </span>
                  <Button className={`btn btn-link ml-2 p-0 btn-sm`}
                          onClick={() => this.onLogoutClick()}
                          type="button">
                    {t("Logout")}
                  </Button>
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    }
    else if(showLoginCardIfNoAuth){
      return (
        <div className={'row justify-content-center mt-3'}>
          <div className={'col-md-8 col-lg-6 text-center'}>
            <div>
              <Button className={`btn btn-outline-light`}
                      onClick={() => this.onWelcomeLinkClick()}
                      type="button">
                {t("Start Sharing in Verifyle")}
                <i className="icon ion-chevron-right ml-2" />
              </Button>
            </div>
          </div>
        </div>
      )
    }
    return null;
  }

  renderHandleData(){
    let {
      handleUserName,
      handleEmail,
      handleText,
      handleLogo,
      handleLoadError,
      hasUploadFinished,
      handleLoading
    } = this.state;
    let {
      t
    } = this.props;

    if(hasUploadFinished){
      return null;
    }
    else if(handleLoadError){
      return (
        <>
          <div className={'row justify-content-center my-5'}>
            <div className={'col-md-8 col-lg-6'}>
              <h4 className="word-break-all text-center" style={{marginTop: '5vh', marginBottom : '5vh'}}>
                {t("Sorry, this page has been disabled.")}
              </h4>
            </div>
          </div>

          {this.renderWelcomeCardIfNeeded(true)}
        </>
      )
    }
    else{
      return (
        <>
          {this.renderWelcomeCardIfNeeded()}
          <div className={'row justify-content-center mt-4'}>
            <div className={'col-md-8 col-lg-6'}>

              {handleLoading &&
                <div className={'p-3 light-bg primary-color text-center'}
                     style={{ borderRadius: '10px'}}>
                  <Loading centered size="sm"/>
                </div>
              }

              {!handleLoading &&
                <div className={'p-3 light-bg primary-color text-center'}
                     style={{
                       borderRadius: '10px',
                       fontSize: '14px'
                     }}>
                  {handleLogo &&
                    <div className={'text-center'}>
                      <Image src={handleLogo}
                             imgHeight={50}
                             alt={t('Logo')}/>
                    </div>
                  }
                  <div className={'text-center font-weight-bold'}
                       style={{
                         paddingTop: '10px',
                         fontSize: '24px'
                       }}>
                    <div>{handleUserName}</div>
                  </div>
                  <div className={'text-center'}
                       style={{
                         paddingBottom: '20px',
                         fontSize: '20px'
                       }}>
                    <div>{handleEmail}</div>
                  </div>
                  <div className={'text-center'}
                       style={{
                         whiteSpace: 'pre-line',
                         wordWrap: 'break-word'
                       }}>
                    {handleText}
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      )
    }
  }

  renderAttachments(){
    let {
      sendAttachments,
      hoveringDocId,
      hasUploadStarted
    } = this.state;

    let {
      t
    } = this.props;

    return (
      <div className={'row justify-content-center mt-3'}>
        <div className={'col-md-8 col-lg-6 text-left'}>
          {_.map(sendAttachments, (doc) => {
            return (
              <div key={doc.uniqueId}
                   onMouseEnter={() => this.setState({hoveringDocId : doc.uniqueId})}
                   onMouseLeave={() => this.setState({hoveringDocId : null})}
                   className="d-flex mb-1"
                    style={{
                      lineHeight: '32px'
                    }}>
                <div className="ml-4 mr-2">
                  <i className="icon ion-document-text"
                     style={{
                       fontSize: '24px'
                     }}/>
                </div>
                <span style={{cursor:'default'}}>
                  {doc.name}
                  <span className="ml-2 font-italic small">
                    {filters.getFileSizeString(t, doc.sizeBytes)}
                  </span>
                </span>
                {doc.uniqueId === hoveringDocId && !hasUploadStarted &&
                  <span
                    onClick={() => this.onRemoveAttachment(doc.uniqueId)}
                    className="ml-2 has-pointer">
                  <i style={{fontSize: '24px'}} className="icon ion-ios-close-outline"/>
                </span>
                }
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderHelmetTags(){
    return(
      <div>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: c.smartbanner.mobile_viewport_banner
          }]}
        />
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
      </div>
    )
  }

  renderAuthenticationControls(){
    let {
      step,
      emailFieldIsVisible,
      showEmailValidationWarning,
      preventEmailDisabled,
      t
    } = this.props;
    let {
      emailCardRef,
      isAuthLoading,
      validationErrors,
      hideAuthButtonControls,
      hasStartedAuthentication,
      hasUploadStarted
    } = this.state;

    if(!hasStartedAuthentication){
      return null;
    }

    return (
      <>
        { emailFieldIsVisible && this.getHelpText()}
        {emailFieldIsVisible &&
          <EmailCard preventNavOnEmailFocus={preventEmailDisabled}
                     isLoading={isAuthLoading || hasUploadStarted}
                     doNextButtonClick={this.onNextBtnClick.bind(this)}
                     setLoading={this.setAuthLoading.bind(this)}
                     setValidationErrors={this.setAuthErrors.bind(this)}
                     onRef={(ref) => {this.setState({emailCardRef: ref})}}/>
        }
        <CSSTransition in={step === c.authSteps.password}
                       unmountOnExit
                       timeout={400}
                       classNames={'fade'}>
          <PasswordCard isLoading={isAuthLoading || hasUploadStarted}
                        doRedirectOnSuccess={false}
                        doNextButtonClick={this.onNextBtnClick.bind(this)}
                        onTokenReceived={(token) => this.onTokenReceived(token)}
                        setLoading={this.setAuthLoading.bind(this)}
                        setValidationErrors={this.setAuthErrors.bind(this)}
                        onRef={(ref) => { this.setState({pwdCardRef : ref}) }}/>
        </CSSTransition>
        <CSSTransition in={step === c.authSteps.signup}
                       unmountOnExit
                       timeout={400}
                       classNames={'fade'}>
          <HandleBoardingCard emailCardRef={emailCardRef}
                              isLoading={isAuthLoading || hasUploadStarted}
                              onTokenReceived={(token) => this.onTokenReceived(token)}
                              setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                              setLoading={this.setAuthLoading.bind(this)}
                              setValidationErrors={this.setAuthErrors.bind(this)}
                              doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                              onRef={(ref) => {
                                this.setState({boardingCardRef: ref})
                              }}/>
        </CSSTransition>
        <CSSTransition in={step === c.authSteps.forgotPassword}
                       unmountOnExit
                       timeout={400}
                       classNames={'fade'}>
          <HandleForgotPasswordCard isLoading={isAuthLoading || hasUploadStarted}
                                    setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                                    doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                                    onTokenReceived={(token) => this.onTokenReceived(token)}
                                    setLoading={this.setAuthLoading.bind(this)}
                                    setValidationErrors={this.setAuthErrors.bind(this)}
                                    emailFieldRef={this.state.emailCardRef}
                                    onRef={(ref) => {
                                      this.setState({forgotPwdCardRef: ref})
                                    }}/>
        </CSSTransition>
        <CSSTransition in={step === c.authSteps.tfa}
                       unmountOnExit
                       timeout={400}
                       classNames={'fade'}>
          <TFACard isLoading={isAuthLoading || hasUploadStarted}
                   doRedirectOnSuccess={false}
                   setLoading={this.setAuthLoading.bind(this)}
                   onTokenReceived={(token) => this.onTokenReceived(token)}
                   setValidationErrors={this.setAuthErrors.bind(this)}
                   onRef={(ref) => { this.setState({tfaCardRef : ref}) }}/>
        </CSSTransition>
        <CSSTransition in={step === c.authSteps.tfaPwdReset}
                       unmountOnExit
                       timeout={400}
                       classNames={'fade'}>
          <HandleTFAPwdResetCard isLoading={isAuthLoading || hasUploadStarted}
                                 onTokenReceived={(token) => this.onTokenReceived(token)}
                                 setLoading={this.setAuthLoading.bind(this)}
                                 setValidationErrors={this.setAuthErrors.bind(this)}
                                 onRef={(ref) => {
                                   this.setState({tfaPwdResetCardRef: ref})
                                 }}/>
        </CSSTransition>

        <AuthControls isLoading={isAuthLoading || hasUploadStarted}
                      showEmailValidationWarning={showEmailValidationWarning}
                      hideControls={hideAuthButtonControls}
                      disabled={hasUploadStarted}
                      newPasswordLinkClick={() => this.props.setNextStep(c.authSteps.forgotPassword)}
                      hideStartOverButton={step === c.authSteps.email}
                      authValidationErrors={validationErrors}
                      nextBtnClick={this.onNextBtnClick.bind(this)}
                      startOverBtnClick={this.doStartOverButtonClick.bind(this)}/>
        <div id={this.ELEMENT_IDS.AUTH_CONTROLS}></div>

      </>
    )
  }

  renderUploadProgress(){
    let {
      uploadProgress,
      hasUploadStarted
    } = this.state;
    let {
      t
    } = this.props;

    if(!hasUploadStarted){
      return null;
    }

    return (
      <div className={'row justify-content-center mt-3'}>
        <div className={'col-md-8 col-lg-6 text-center mb-5'}>
          <h4 className="mb-0">
            {t("Confirmed!")}
          </h4>
          <p className="mb-2">
            {t("Encrypted Submission in Progress...")}
          </p>
          <ProgressBar percentProgress={uploadProgress.percent}/>
          <h4 className="mt-2 mb-4">
            <i className="icon ion-android-warning align-baseline pr-2 text-warning light-color"/>
            <span>
              {t("Please do not close this browser tab!")}
            </span>
          </h4>
        </div>
      </div>
    )
  }

  renderControls(){
    let {
      t
    } = this.props;
    let {
      isAuthLoading,
      hasStartedAuthentication,
      hasValidToken,
      validationErrors,
      hasUploadStarted
    } = this.state;

    return (
      <div className={'row justify-content-center mt-3'}>
        <div className={'col-md-8 col-lg-6'}>
          <div className="d-flex">
            <div className="text-left" style={{flex: 1}}>
              <UploadHelper allowMultiple={true}
                            disabled={hasUploadStarted}
                            clickOnly={true}
                            onDrop={this.onAddAttachment.bind(this)}>
                <Button className={`btn btn-outline-light`}
                        disabled={hasUploadStarted}
                        type="button">
                  <i className={'ion-paperclip mr-2'}/>
                  {t("Select Attachments")}
                </Button>
              </UploadHelper>
            </div>
            <div className="text-right" style={{flex: 1}}>
              {!hasStartedAuthentication &&
                <PulseButton onClick={() => this.onFirstNextButtonClicked()}
                             disabled={hasUploadStarted}
                             isLoading={isAuthLoading || hasUploadStarted}
                             className={'ml-auto'}
                             btnText={hasValidToken ? t('Send') : t('Next')}/>
              }
            </div>
          </div>

          <div className="my-3 handle-controls">
            {this.renderAuthenticationControls()}
          </div>
          {!hasStartedAuthentication &&
            <div>
              <ValidationErrors errors={validationErrors} />
            </div>
          }
        </div>
      </div>
    )
  }

  renderHandleInputs(){
    let {
      sendMsgText,
      handleLoadError,
      hasUploadStarted
    } = this.state;
    let {
      t
    } = this.props;

    if(handleLoadError){
      return null;
    }

    return (
      <>
        <div className={'row justify-content-center mt-4'}>
          <div className={'col-md-8 col-lg-6'}>
            <p className="text-center light-color" style={{fontSize: '20px'}}>
              {t("What would you like to securely share?")}
            </p>
            <div className="form-group mb-0">
                <textarea className={'form-control no-resize'}
                          rows={4}
                          disabled={hasUploadStarted}
                          maxLength={c.limits.maxThreadMessage}
                          value={sendMsgText}
                          onChange={(evt) => this.setState({sendMsgText: evt.target.value})}
                          placeholder={t('Message (optional)')}/>
            </div>
          </div>
        </div>
        {this.renderAttachments()}
        {this.renderControls()}
        {this.renderUploadProgress()}
      </>
    )
  }

  renderUploadFinished(){
    let {
      t
    } = this.props;
    let {
      handleData,
    } = this.state;
    let recipientUid = _.get(handleData, 'uid');
    return (
      <div className={'row justify-content-center mt-3'}>
        <div className={'col text-center'}>
          <h3 className="my-5">
            {t("Success!")}
          </h3>
          <div>
            <Button className={`btn btn-outline-light`}
                    onClick={() => this.props.history.push(`/home?guest_uid=${recipientUid}`)}
                    type="button">
              {t("View What You Shared")}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render(){
    let {
      appLoad,
      hasUploadFinished
    } = this.state;

    return (
      <div className={'portal-bg light-color'}>
        {this.renderHelmetTags()}
        <form onSubmit={this.onFormSubmit.bind(this)}>
          <div className="container-fluid">
            {this.renderHeader()}
          </div>
          <div className="container">
            <CSSTransition in={appLoad} timeout={400} unmountOnExit classNames={'fade'}>
              <div>
                {this.renderHandleData()}
                {hasUploadFinished &&
                  <div>
                    {this.renderUploadFinished()}
                  </div>
                }
                {!hasUploadFinished &&
                  <div>
                    {this.renderHandleInputs()}
                  </div>
                }
                <div id={this.ELEMENT_IDS.PAGE_BOTTOM} />
              </div>
            </CSSTransition>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    step : state.auth.step,
    email : state.auth.email,
    institution : state.auth.institution,
    emailFieldIsVisible : state.auth.emailFieldIsVisible,
    preventEmailDisabled : state.auth.preventEmailDisabled,
    showEmailValidationWarning : state.auth.showEmailValidationWarning
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNextStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setEmailValidationResults : (res) => dispatch(authActions.setEmailValidationResults(res)),
    setEmailValidationPerformed : (res) => dispatch(authActions.setEmailValidationPerformed(res)),
    setShowingInvalidEmailWarning : (isShowing) => dispatch(authActions.setShowingInvalidEmailWarning(isShowing)),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(Handle));
