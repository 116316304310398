import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import {getMessageForError} from "../../../util/errors";
import redirectHelper from '../../../util/redirect-helper';

import Button from "../elements/Button";
import FancyInput from '../elements/FancyInput';
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import PulseButton from "../elements/PulseButton";
import PropTypes from "prop-types";
import _ from "lodash";
import stripeHelper from "../../../util/stripe-helper";
import cookieHelper from "../../../helpers/cookie-helper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import utils from "../../../util/util";

class PasswordCard extends Component {

  constructor(props){
    super(props);

    this.state = {
      password: '',
      focusOnRef : true,
      validationErr: null,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    //Safari auto-fill bug fix.
    //When the password card mounts, we want to ignore focus events on the email field
    //for a brief period, otherwise focus events coming in could navigate us away from the
    //password card.
    this.props.setPreventEmailDisabled(true);
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  validateForm() {
    let {password} = this.state;

    this.props.setValidationErrors([]);
    this.setState({
      validationErr: null,
    });

    if (!password || password.length === 0) {
      this.setState({
        validationErr: true,
      });
      this.pwdField.triggerValidationErr()
      return false;
    } else {
      return true;
    }
  }

  handleStripe3DIfNeeded(res, payment_method_id){
    //In payment cases we need to handle potential 3d auth cases, so do it, then re-call with updated payment data.
    // {
    //   "data" : {
    //   "payment_error" : {
    //     "client_secret" : "seti_1HXXywFJy4l0f3VY8PtaUrx7_secret_I7nauc13LtAZY7hJcgvD68B07BYDseb",
    //       "payment_method_id" : "pm_1HXXyuFJy4l0f3VY0WRmTlEu",
    //       "seti_id" : "seti_1HXXywFJy4l0f3VY8PtaUrx7"
    //   },
    //   "payment_flag" : false,
    //     "test" : {
    //     "code" : "48655186"
    //   }
    // },
    //   "success" : true
    // }

    return new Promise((resolve, reject) => {
      log.log('handleStripe3DIfNeeded', res);
      let { t } = this.props;
      let stripeClientSecret = _.get(res, 'data.payment_error.client_secret');
      let hasPaymentFlag = _.get(res, 'data.payment_flag');
      if (!hasPaymentFlag && stripeClientSecret) {
        log.warn('got partial fail');
        stripeHelper.handleStripe3DAuth(this.props.stripe, stripeClientSecret, payment_method_id, t)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(false);
      }
    })
  }

  doLogin(paymentMethodId, targetClassId){
    let {password} = this.state;
    let {
      setStep,
      setTFAAuth,
      setAuth,
      email,
      history,
      qs,
      validCouponDeets,
      doRedirectOnSuccess,
      onTokenReceived,
      t
    } = this.props;

    if (!this.validateForm()) {
      return;
    }

    this.props.setPreventEmailDisabled(true);
    this.props.setLoading(true);

    let loginRes = null;
    let couponId = _.get(validCouponDeets, 'coupon.id', null);
    api.Login.post(email, password, paymentMethodId, targetClassId, couponId)
      .then((res) => {
        loginRes = res;
        return this.handleStripe3DIfNeeded(loginRes, paymentMethodId);
      })
      .then((stripeRes) => {
        log.log('login', loginRes, stripeRes);
        this.props.setLoading(false);
        if (loginRes.data.tfa_totp_flag || loginRes.data.tfa_sms_flag) {
          //save our info, go there.
          setTFAAuth(loginRes.data);
          setStep(c.authSteps.tfa);
        } else {
          setAuth(loginRes.data.sid, loginRes.data.vip, loginRes.data.token);
          cookieHelper.clearTrackingCookies();
          if(doRedirectOnSuccess) {
            redirectHelper.redirectToApp(history, qs, loginRes.data.vip);
          }
          if(onTokenReceived){
            onTokenReceived(loginRes.data.token)
          }
        }
      })
      .catch((err) => {
        log.log('error on login', err);
        this.props.setValidationErrors([
          stripeHelper.getMessageForStripeOrVfError(err, t)
        ])
        this.props.setLoading(false);
      })
  }

  doNextButtonClicked() {
    this.doLogin(this.props.paymentMethodId, this.props.targetClassId);
  }

  onStartOverButtonClick(evt){
    let { setStep } = this.props;

    setStep(c.authSteps.email)
  }

  onPasswordChange(val) {
    this.setState({
      password: val
    })
  }

  showInfo() {
    let { t } = this.props;

    let resetPasswordBtnClick = () => {
      //Close the top level modal here, it will always be this dialog.
      this.props.closeTopLevelModal();

      //Then set forgot password state once the window is closed.
      setTimeout(() => {
        let { setStep } = this.props;
        setStep(c.authSteps.forgotPassword);
      })
    }

    this.props.showAuthInfo(
      t('Password trouble?'),
      <div>
        <p>
          {t("Every Verifyle user has a password. This one password is used to access your account at verifyle.com and via the mobile application.")}
        </p>
        <p>
          {t("If you’re having trouble signing in, you’ll probably need to reset your password, which you can do by clicking below.")}
        </p>
        <p className="text-center">
          <Button className={'btn btn-primary'}
                  onClick={() => resetPasswordBtnClick()}>{t("Reset Password")}</Button>
        </p>
        <p>
          {t("If you can't remember your password and have opted to disable password reset on your account, we will not be able to give you access to your account. That’s not policy, that’s cryptography.")}
        </p>
        <p className="pt-3">
          {t("Contact us at")}
          <br/>
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
        </p>
      </div>
    )
  }

  onPasswordFieldRef(ref){
    this.pwdField = ref;
    if(ref && this.state.focusOnRef){
      this.setState({focusOnRef : false})
      setTimeout(() => {
        if(this.pwdField){
          this.pwdField.focus();
        }
      }, 250)
    }
  }

  render() {
    let {validationErr} = this.state;
    let {step, isLoading, t} = this.props;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <FancyInput onRef={ref => this.onPasswordFieldRef(ref)}
                          placeholder={t('Password')}
                          inputDisabled={false}
                          autoComplete={'current-password'}
                          inputReadonly={isLoading}
                          fieldType={'password'}
                          isValid={!validationErr}
                          inputValue={this.state.password}
                          infoBtnCls={'ion-ios-information-outline auth-info-icon has-pointer'}
                          onInfoClick={this.showInfo.bind(this)}
                          onEnter={() => this.props.doNextButtonClick()}
                          onChange={this.onPasswordChange.bind(this)}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PasswordCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  paymentMethodId : PropTypes.string,
  targetClassId : PropTypes.number,
  stripe : PropTypes.object,
  validCouponDeets : PropTypes.object,
  doNextButtonClick : PropTypes.func.isRequired,
  doRedirectOnSuccess : PropTypes.bool,
  onTokenReceived : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    qs: state.app.qs,
    step : state.auth.step,
    email : state.auth.email,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setTFAAuth: auth => dispatch(authActions.setTFAAuth(auth)),
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled)),
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordCard)));
