import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../partials/elements/Button';
import sapi from "../../../util/sapi";
import modalActions from "../../../actions/modal-actions";
import log from "../../../util/log";
import UserBadge from "../../partials/badges/UserBadge";
import colors from "../../../util/colors";
import {getMessageForError} from "../../../util/errors";
import ValidationErrors from "../../partials/components/ValidationErrors";
import {last} from "pdf-lib";
import AddContactWindow from "../../modals/AddContactWindow";
import Loading from "../util/Loading";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class CSVReview extends Component {

  constructor(props) {
    super(props);

    let contactCount = props.contactData.contact ? props.contactData.contact.length : 0;
    let errorCount = props.contactData.error ? props.contactData.error.length : 0;
    let totalRows = contactCount + errorCount;

    this.state = {
      contactData: props.contactData,
      allowImport: totalRows !== errorCount,
      importCount: props.contactData.contact.length,
      tableData: [],
      readyContacts : [],
      importing : false
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  componentDidMount() {
    let {contactData} = this.state;

    let tableData = [];
    let readyContacts = [];

    if (contactData.error) {
      let rowIndex = 1;
      _.each(contactData.error, (error) => {
        tableData.push({
          rowIndex : rowIndex,
          key: _.uniqueId('contact-key-'),
          first_name: error.first_name || null,
          last_name: error.last_name || null,
          email: error.email || null,
          error: this.getFriendlyError(error.error),
          line: error.line || null
        })

        rowIndex++;
      })
    }

    if (contactData.contact) {
      let rowIndex = 1;
      _.each(contactData.contact, (contact) => {
        let c = {
          rowIndex : rowIndex,
          key: _.uniqueId('contact-key-'),
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          error: null,
          line: null
        }
        tableData.push(c);
        readyContacts.push(c);
        rowIndex++;
      })
    }

    this.setState({tableData, readyContacts})
  }

  getFriendlyError(err) {
    if (!err) {
      return null;
    }
    let { t } = this.props;
    let errLookup = {
      APP_GUEST_DUP: t("Duplicate"),
      APP_REQ_MISSING: t("Missing Input"),
      APP_REQ_INVALID: t("Invalid Email"),
      APP_GUEST_MAX: t("Over Limit")
    }

    if (errLookup[err]) {
      return errLookup[err]
    }
    else {
      log.warn('unsupported csv error', err);
      return err;
    }
  }

  submit() {
    let {readyContacts} = this.state;
    let { t } = this.props;

    this.setState({importing : true})

    let contactUpload = _.map(readyContacts, (contact) => {
      return {
        first_name : contact.first_name,
        last_name : contact.last_name,
        email : contact.email
      }
    })

    sapi.Contacts.add(contactUpload)
      .then((res) => {
        log.log('add contact res', res);
        this.setState({importing : false})
        this.closeModal(res);
      })
      .catch((err) => {
        log.error('error adding contacts', err);
        this.setState({importing : false})
        this.props.showAlert(t('Error adding Contacts'), getMessageForError(err, t));
      })
  }

  render() {
    let { t } = this.props;
    let {contactData, allowImport, importCount, tableData, importing} = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Import Contacts")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty"/>
          </button>
        </div>
        <div className="modal-header py-0">
          <div className="w-100 d-block">
            <div className="row">
              <div className="col text-center">
                <h3 className="green-color mb-4" style={{lineHeight: '24px'}}>
                  <i className="icon ion-checkmark-circled mr-2"/>
                  {t("Upload Successful!")}
                </h3>
              </div>
            </div>

            {contactData.error && contactData.error.length > 0 &&
            <div className="row">
              <div className="col">
                <p className="text-center assertive-color">
                  {contactData.error.length} {t("of")} {contactData.contact.length + contactData.error.length} {t("contacts cannot be imported. To correct the issues, edit your file and re-submit.")}
                </p>
              </div>
            </div>
            }

            <div>
              <div className="d-flex px-2 py-1" style={{
                backgroundColor : colors.LIGHTER_GREY
              }}>
                <div className="auto-ellipsis px-1" style={{width: '6%'}}>
                </div>
                <div className="auto-ellipsis px-1" style={{width: '18%'}}>
                  {t("First Name")}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '18%'}}>
                  {t("Last Name")}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '44%'}}>
                  {t("Email Address")}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '14%'}}>
                  {t("Status")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body pt-0 mt-2">
          {_.map(tableData, (data) => {
            return (
              <div key={data.key} className={`${data.error ? 'assertive-color' : 'dark-color'}` + " d-flex px-2 py-1"}>
                <div className="auto-ellipsis px-1" style={{width: '6%'}}>
                  {data.rowIndex}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '18%'}}>
                  {data.first_name}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '18%'}}>
                  {data.last_name}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '44%'}}>
                  {data.email}
                </div>
                <div className="auto-ellipsis px-1" style={{width: '14%'}}>
                  {data.error &&
                    <span>{data.error}</span>
                  }
                  {!data.error &&
                  <span className="green-color">{t("Ready")}</span>
                  }
                </div>
              </div>
            )
          })}
        </div>
        <div className="modal-footer">
          {importing &&
          <Loading inline={true}
                   className={'mr-2'}
                   size={'sm'}/>
          }
          <Button className="btn btn-secondary"
                  disabled={importing}
                  onClick={this.closeModal.bind(this, false)}>
            {t("Cancel")}
          </Button>
          <Button className="btn btn-primary"
                  disabled={!allowImport || importing}
                  onClick={this.submit.bind(this)}>
            {t("Import")} {importCount} {importCount.length === 1 ? t('Contact') : t('Contacts')}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

CSVReview.propTypes = {
  setWindowMode: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(CSVReview));
