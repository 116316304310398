import React, {Component} from 'react';
import {connect} from 'react-redux';
import EmailCard from '../partials/auth/EmailCard';
import PasswordCard from '../partials/auth/PasswordCard';
import ForgotPasswordCard from '../partials/auth/ForgotPasswordCard';
import BoardingCard from '../partials/auth/BoardingCard';
import TFACard from '../partials/auth/TFACard';
import TFAPwdResetCard from '../partials/auth/TFAPwdResetCard';
import c from '../../util/const';
import config from '../../util/config';
import vfLocalStorage from '../../util/local-storage'
import {Helmet} from 'react-helmet'
import authActions from '../../actions/auth-actions';
import InstitutionPanel from "../partials/auth/InstitutionPanel";

import Image from '../partials/elements/Image';

import logo from '../../resources/logo-w-text-white.png';
import logoLockup from '../../resources/logo-lock-up-white.png'
import {CSSTransition} from "react-transition-group";
import EndpointPicker from "../partials/auth/EndpointPicker";
import _ from "lodash";
import log from "../../util/log";
import StripePaymentCard from "../partials/auth/StripePaymentCard";
import AuthControls from "../partials/auth/AuthControls";
import filters from "../../helpers/filters";
import {withStripeElements} from "../../containers/StripeInjectedComponent";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import UpgradeDialogNew from "../modals/UpgradeDialogNew";

class Auth extends Component {

  constructor(props) {
    super(props);

    this.state = {
      appLoad : false,
      appLoadTimer : null,

      emailCardRef : null,
      pwdCardRef : null,
      boardingCardRef : null,
      forgotPwdCardRef : null,
      tfaCardRef : null,
      tfaPwdResetCardRef : null,
      stripePaymentCardRef : null,
      isAuthLoading : false,
      authValidationErrors : [],
      hideAuthButtonControls : false,
      paymentMethodId : null,
      validCouponDeets : null
    };
  }

  componentWillUnmount() {
    let { appLoadTimer, emailCardRef } = this.state;

    if(appLoadTimer){
      clearTimeout(appLoadTimer);
    }

    if(emailCardRef){
      this.setState({emailCardRef : null})
    }
  }

  componentDidMount() {
    let me = this;
    let {
      setNextStep,
      setEmail,
      step,
      institution,
      loginWithPayment,
      upgradeClassInfo,
      t
    } = this.props;

    let legacy_flag = _.get(upgradeClassInfo, 'legacy_flag');
    if(loginWithPayment && upgradeClassInfo){
      if(legacy_flag){
        //then cancel login with payment, set message.
        this.props.setLoginWithPayment(false);
        this.setAuthErrors([t("This plan is no longer available. Please visit your Account Settings page or www.verifyle.com/pricing.html for more information.")]);
      }
    }

    let lsEmail = vfLocalStorage.get(c.localstorage.email);
    if(lsEmail){

      //If there's a default class id, then we need to allow prompting for member number,
      //and if that's an option, we need to make sure the user lookup call gets passed this member_number
      //So instead of passing them along to password, we need to drop them at the email step. bug 2378
      let defaultClassId = _.get(institution, 'info_json.default_class_id');

      //If we're logging in with payment controls, just start them on the email step.
      if(defaultClassId || (loginWithPayment && !legacy_flag)){
        setEmail(lsEmail);
        this.props.setNextStep(c.authSteps.email);
      }
      else{
        //If they already have email populated, just start them at the password screen.
        //We still show the email above anyway, so that's probably what they want. bug 1788
        setEmail(lsEmail);
        setNextStep(c.authSteps.password);
      }
    }
    else if(!step){
      setNextStep(c.authSteps.email);
    }

    let timer = setTimeout(() => {
      me.setState({
        appLoad: true
      })
    }, 500);
    me.setState({appLoadTimer : timer});
  }

  getHelpText() {
    let {
      step,
      t
    } = this.props;

    let text = null;
    if(step === c.authSteps.email){
      text = t("Let's get started!");
    }
    else if(step === c.authSteps.password){
      text = t('Welcome back!');
    }
    else if(step === c.authSteps.signup){
      text = t("Looks like you're creating a new account.  What should we call you?")
    }

    let headerText = null;
    if(text){
      headerText = (
        <div className={'row'}>
          <div className={'col text-center'}>
            <p className={'light-color mb-1'}
               style={{fontSize: '18px', minHeight: '54px'}}>{text}</p>
          </div>
        </div>
      )
    }
    else{
      headerText = (
        <div className={'row'}>
          <div className={'col'}>
            <p className={'light-color mb-1'}
               style={{fontSize: '18px', minHeight: '54px'}} />
          </div>
        </div>
      )
    }

    return (
      <div className="mb-4">
        {headerText}
        {this.getPaymentText()}
      </div>
    )
  }

  getPaymentText(){
    let {
      loginWithPayment,
      upgradeClassInfo,
      i18n,
      t
    } = this.props;
    let {
      validCouponDeets
    } = this.state;

    let paymentBlock = null;
    //Wait for language to be ready, it might take just a sec to load.  bug 2827
    if(loginWithPayment && upgradeClassInfo && i18n.language){
      let termCostCents = +(upgradeClassInfo['term_cost']) * 100;
      let priceMonthly = '';

      if(validCouponDeets){
        priceMonthly = validCouponDeets.monthly_amount
      }
      else if(upgradeClassInfo['term_length'] === UpgradeDialogNew.YEARLY_TERM_SECONDS){
        priceMonthly = filters.getCurrency(i18n.language, termCostCents) + t(" / year")
      }
      else{
        priceMonthly = filters.getCurrency(i18n.language, termCostCents) + t(" / month")
      }

      return (
        <div className={'row'}>
          <div className={'col text-center'}>
            <p className="light-color mb-1 font-weight-bold"
               style={{fontSize: '18px'}}>
              {upgradeClassInfo.label}
            </p>
            <p className="lighter-grey-color mb-1"
               style={{fontSize: '16px'}}>
              {priceMonthly}
            </p>
          </div>
        </div>
      )
    }

    return null;
  }

  getCardRefForStep(){
    let { step } = this.props;

    let ref = null;
    if(step === c.authSteps.email){
      ref = this.state.emailCardRef;
    }
    else if(step === c.authSteps.forgotPassword){
      ref = this.state.forgotPwdCardRef;
    }
    else if(step === c.authSteps.password){
      ref = this.state.pwdCardRef;
    }
    else if(step === c.authSteps.signup){
      ref = this.state.boardingCardRef;
    }
    else if(step === c.authSteps.tfa){
      ref = this.state.tfaCardRef;
    }
    else if(step === c.authSteps.tfaPwdReset){
      ref = this.state.tfaPwdResetCardRef;
    }

    return ref;
  }

  setAuthLoading(isLoading){
    this.setState({isAuthLoading : isLoading});
  }

  setAuthErrors(errors){
    this.setState({authValidationErrors : errors});
  }

  setHideAuthButtonControls(hideControls){
    this.setState({
      hideAuthButtonControls : hideControls
    })
  }

  submitPaymentMethod(){
    return new Promise((resolve, reject) => {
      this.state.stripePaymentCardRef.doFormSubmit()
        .then((res) => {
          log.log('got payment submit res', res);
          this.setState({
            paymentMethodId : res
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          log.log('caught error processing payment', err);
          this.setAuthErrors([err]);
          reject(null);
        })
    })
  }

  onNextBtnClick(){
    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }

    if (this.props.loginWithPayment) {
      if(this.props.step === c.authSteps.password || this.props.step === c.authSteps.signup) {
        //If they're on the password or boarding steps, and there's the payment flag
        //Then we need to submit the payment card here, wait for it, and upon success then fire the
        //Next button click upon success, or show an error if not.
        log.log('submitting payment');
        this.setAuthLoading(true);
        this.submitPaymentMethod()
          .then((res) => {
            this.setAuthLoading(false);
            ref.doNextButtonClicked();
          })
          .catch(() => {
            this.setAuthLoading(false);
          })
      }
      else{
        //Each card has this function implemented.
        ref.doNextButtonClicked();
      }
    }
    else{
      //Each card has this function implemented.
      ref.doNextButtonClicked();
    }
  }

  doStartOverButtonClick(){

    this.props.setEmailValidationPerformed(false);
    this.props.setEmailValidationResults(null);
    this.props.setShowingInvalidEmailWarning(false);
    this.props.setAllowEmailEditFromBoarding(false);

    this.setState({
      isAuthLoading : false,
      authValidationErrors : [],
      hideAuthButtonControls : false
    })

    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }

    if(ref.onStartOverButtonClick) {
      //Each card has this function implemented.
      ref.onStartOverButtonClick();
    }
  }

  newPasswordLinkClick(){
    this.props.setNextStep(c.authSteps.forgotPassword);
  }

  addRemovePromoCodeClick(){
    if(this.state.validCouponDeets){
      this.setState({validCouponDeets : null});
      return;
    }

    let {
      upgradeClassInfo
    } = this.props;

    this.props.showPromoCodeWindow(upgradeClassInfo, (res) => {
      log.log('showPromoCode res', res);
      if(res){
        this.setState({validCouponDeets : res})
      }
    })
  }

  pickHeaderLogo(){
    let {
      institution,
      i18n
    } = this.props;

    //This is a combination of old logic for showing a plain logo for institutions
    //and some new around localization.  The logoLockup currently has some english text in it,
    //so we're showing the plain logo in that case.  bug 2820
    let headerLogo = null;
    if(i18n.language === c.language.en_us){
      if(institution) {
        headerLogo = logo;
      }
      else{
        headerLogo = logoLockup;
      }
    }
    else{
      headerLogo = logo;
    }
    return headerLogo;
  }

  renderHelmetTags(){

    return(
      <div>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: c.smartbanner.mobile_viewport_banner
          }]}
        />
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
      </div>
    )
  }

  onFormSubmit(evt){
    //This form is here to prevent a browser warning about password fields outside forms
    //I think it might be related to a problem focusing the password input in some cases.
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    return false;
  }

  render() {
    let {
      step,
      institution,
      emailFieldIsVisible,
      preventEmailDisabled,
      loginWithPayment,
      upgradeClassInfo,
      showEmailValidationWarning,
      stripe,
      elements,
      t
    } = this.props;
    let {
      appLoad,
      emailCardRef,
      isAuthLoading,
      authValidationErrors,
      hideAuthButtonControls,
      paymentMethodId,
      validCouponDeets
    } = this.state;

    let headerLogo = this.pickHeaderLogo();

    return (
      <div className={'celestial-bg auth-wrapper'}>
        {this.renderHelmetTags()}
        <form onSubmit={this.onFormSubmit.bind(this)}>
          <div className={'container'}>
            {config.debug && step === c.authSteps.email && <EndpointPicker />}
            <div className={'row justify-content-center'}>
              <div className={'auth-header-img col'}>
                <Image src={headerLogo}
                       className={'mx-auto d-block'}
                       imgHeight={90}
                       alt={t('logo')}/>
              </div>
            </div>
            <CSSTransition in={appLoad} timeout={400} unmountOnExit classNames={'fade'}>
              <div className={'row justify-content-center'}>
                <div style={{marginTop : '3vh'}} className={'col-sm-8 col-md-6 col-lg-5 col-xl-4'}>
                  <div className={'card auth-card'}>
                    <div className={'card-body'}>
                      { !loginWithPayment && institution && <InstitutionPanel institution={institution}/> }
                      { emailFieldIsVisible && this.getHelpText()}
                      <CSSTransition in={loginWithPayment}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <StripePaymentCard isLoading={isAuthLoading}
                                           stripe={stripe}
                                           elements={elements}
                                           hideControls={hideAuthButtonControls}
                                           setLoading={this.setAuthLoading.bind(this)}
                                           setValidationErrors={this.setAuthErrors.bind(this)}
                                           onRef={(ref) => { this.setState({stripePaymentCardRef : ref}) }} />
                      </CSSTransition>
                      {emailFieldIsVisible &&
                      <EmailCard preventNavOnEmailFocus={preventEmailDisabled}
                                 isLoading={isAuthLoading}
                                 doNextButtonClick={this.onNextBtnClick.bind(this)}
                                 setLoading={this.setAuthLoading.bind(this)}
                                 setValidationErrors={this.setAuthErrors.bind(this)}
                                 onRef={(ref) => {this.setState({emailCardRef: ref})}}/>
                      }
                      <CSSTransition in={step === c.authSteps.password}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <PasswordCard isLoading={isAuthLoading}
                                      stripe={stripe}
                                      doRedirectOnSuccess={true}
                                      paymentMethodId={paymentMethodId}
                                      doNextButtonClick={this.onNextBtnClick.bind(this)}
                                      validCouponDeets={validCouponDeets}
                                      targetClassId={upgradeClassInfo ? upgradeClassInfo.class_id : -1}
                                      setLoading={this.setAuthLoading.bind(this)}
                                      setValidationErrors={this.setAuthErrors.bind(this)}
                                      onRef={(ref) => { this.setState({pwdCardRef : ref}) }}/>
                      </CSSTransition>
                      <CSSTransition in={step === c.authSteps.signup}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <BoardingCard emailCardRef={emailCardRef}
                                      stripe={stripe}
                                      isLoading={isAuthLoading}
                                      validCouponDeets={validCouponDeets}
                                      paymentMethodId={paymentMethodId}
                                      targetClassId={upgradeClassInfo ? upgradeClassInfo.class_id : -1}
                                      setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                                      setLoading={this.setAuthLoading.bind(this)}
                                      setValidationErrors={this.setAuthErrors.bind(this)}
                                      doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                                      onRef={(ref) => { this.setState({boardingCardRef : ref}) }}/>
                      </CSSTransition>
                      <CSSTransition in={step === c.authSteps.forgotPassword}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <ForgotPasswordCard isLoading={isAuthLoading}
                                            setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                                            doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                                            setLoading={this.setAuthLoading.bind(this)}
                                            setValidationErrors={this.setAuthErrors.bind(this)}
                                            emailFieldRef={this.state.emailCardRef}
                                            onRef={(ref) => { this.setState({forgotPwdCardRef : ref}) }}/>
                      </CSSTransition>
                      <CSSTransition in={step === c.authSteps.tfa}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <TFACard isLoading={isAuthLoading}
                                 doRedirectOnSuccess={true}
                                 setLoading={this.setAuthLoading.bind(this)}
                                 setValidationErrors={this.setAuthErrors.bind(this)}
                                 onRef={(ref) => { this.setState({tfaCardRef : ref}) }}/>
                      </CSSTransition>
                      <CSSTransition in={step === c.authSteps.tfaPwdReset}
                                     unmountOnExit
                                     timeout={400}
                                     classNames={'fade'}>
                        <TFAPwdResetCard isLoading={isAuthLoading}
                                         setLoading={this.setAuthLoading.bind(this)}
                                         setValidationErrors={this.setAuthErrors.bind(this)}
                                         onRef={(ref) => { this.setState({tfaPwdResetCardRef : ref}) }}/>
                      </CSSTransition>
                      <AuthControls isLoading={isAuthLoading}
                                    hideControls={hideAuthButtonControls}
                                    showEmailValidationWarning={showEmailValidationWarning}
                                    newPasswordLinkClick={this.newPasswordLinkClick.bind(this)}
                                    promoCodeLinkClick={this.addRemovePromoCodeClick.bind(this)}
                                    validCouponDeets={validCouponDeets}
                                    hideStartOverButton={step === c.authSteps.email}
                                    authValidationErrors={authValidationErrors}
                                    nextBtnClick={this.onNextBtnClick.bind(this)}
                                    startOverBtnClick={this.doStartOverButtonClick.bind(this)} />

                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </form>
        <footer className={'sticky-footer'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={'text-center'}>
                  <CSSTransition in={appLoad} timeout={400} unmountOnExit classNames={'fade'}>
                    <a className={'auth-link'}
                       style={{fontSize: '14px'}}
                       href={c.links.mainNoRedirect}
                       target={'_blank'}>{t("About Verifyle")}</a>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    step : state.auth.step,
    email : state.auth.email,
    institution : state.auth.institution,
    emailFieldIsVisible : state.auth.emailFieldIsVisible,
    preventEmailDisabled : state.auth.preventEmailDisabled,
    loginWithPayment : state.auth.loginWithPayment,
    upgradeClassInfo : state.auth.upgradeClassInfo,
    showEmailValidationWarning : state.auth.showEmailValidationWarning
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNextStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setEmailValidationResults : (res) => dispatch(authActions.setEmailValidationResults(res)),
    setEmailValidationPerformed : (res) => dispatch(authActions.setEmailValidationPerformed(res)),
    setShowingInvalidEmailWarning : (isShowing) => dispatch(authActions.setShowingInvalidEmailWarning(isShowing)),
    setLoginWithPayment : (setLoginWithPayment) => dispatch(authActions.setLoginWithPayment(setLoginWithPayment)),
    setAllowEmailEditFromBoarding : (allowEmailEditFromBoarding) => dispatch(authActions.setAllowEmailEditFromBoarding(allowEmailEditFromBoarding)),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStripeElements(Auth)));
