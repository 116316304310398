import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {List, AutoSizer} from 'react-virtualized';
import UploadHelper from "../components/UploadHelper";
import GuestRow from "./GuestRow";
import Measure from "react-measure";
import log from "../../../util/log";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import sharedActions from "../../../actions/shared-actions";
import {connect} from "react-redux";
import _ from "lodash";

class GuestItem extends PureComponent{
  render(){
    const { data, index, style } = this.props;
    const {
      contacts,
      onRemoveGuest,
      onGuestRowFileDrop,
      guestClick,
      activeDM,
      updateItemHeight,
      onBeforePrintPreview,
      onPrintPreviewError,
      onAfterPrintPreview,
      getPrintPreviewContents,
      onDocAttach,
      onSendInvoiceClick
    } = data;

    const guest = contacts[index];
    return (
      <div style={style}>
        <Measure
          bounds
          margin
          onResize={contentRect => {
            //This is kind of a hack, but I noticed that if the entry object doesn't exist on the resize event, the data is wrong.
            //It comes back at the wrong size.  If it doesn't have that, use the default height.
            let totalHeight = GuestItem.ROW_HEIGHT;
            if(contentRect.entry) {
              totalHeight = contentRect.bounds.height + contentRect.margin.top + contentRect.margin.bottom;
            }
            updateItemHeight(index, totalHeight);
          }}
        >
          {({measureRef}) => (
            <div ref={measureRef}>
              <div className="position-relative">
                <UploadHelper onDrop={onGuestRowFileDrop(guest, guest)}
                              disabled={guest.is_pending}
                              generateImagePreviews={true}
                              allowMultiple={true}
                              disableClick={true}>
                  <GuestRow key={guest.guest_uid}
                            row={guest}
                            dm={guest}
                            onPrintPreviewError={onPrintPreviewError}
                            onAfterPrintPreview={onAfterPrintPreview}
                            onBeforePrintPreview={onBeforePrintPreview}
                            getPrintPreviewContents={getPrintPreviewContents}
                            onDocAttach={onDocAttach}
                            onSendInvoiceClick={onSendInvoiceClick}
                            isActive={!!(activeDM && activeDM.guest_uid === guest.guest_uid)}
                            onRemoveGuest={onRemoveGuest}
                            onItemClick={guestClick}/>
                </UploadHelper>
              </div>
            </div>
          )}
        </Measure>
      </div>
    )
  }
}

GuestItem.ROW_HEIGHT = 64;

class GuestList extends PureComponent {

  constructor(props){
    super(props);

    this.getItemSize = this.getItemSize.bind(this);
    this.updateItemHeight= this.updateItemHeight.bind(this);

    this.listRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  scrollToIndex(index){
    let ref = _.get(this, 'listRef.current');
    if(ref){
      //This is really "enusre-visible", and not scroll to.
      //log.log('scrollToIndex', index, this.listRef.current);
      ref.scrollToRow(index);
    }
    else{
      log.warn('list ref not present');
    }
  }

  updateItemHeight(index, totalHeight) {
    if (this.state['item-index-' + index] !== totalHeight) {
      //log.log('update item height index', index, totalHeight);
      this.setState({['item-index-' + index]: totalHeight},
        () => {
          this.listRef.current.recomputeRowHeights(index);
        })
    }
  }

  getItemSize(index) {
    let height = this.state['item-index-' + index];
    if(!height){
      height = GuestItem.ROW_HEIGHT;
    }
    return height;
  }

  rowRenderer = ({ index, style, parent }) => {
    const {
      contacts,
      onRemoveGuest,
      onGuestRowFileDrop,
      guestClick,
      activeDM,
      onBeforePrintPreview,
      onPrintPreviewError,
      onAfterPrintPreview,
      getPrintPreviewContents,
      onDocAttach,
      onSendInvoiceClick
    } = this.props;

    return (
      <GuestItem key={index}
                 data={{
                     contacts,
                     onRemoveGuest,
                     onGuestRowFileDrop,
                     guestClick,
                     activeDM,
                     updateItemHeight : this.updateItemHeight,
                     onBeforePrintPreview,
                     onPrintPreviewError,
                     onAfterPrintPreview,
                     getPrintPreviewContents,
                     onDocAttach,
                     onSendInvoiceClick
                 }}
                 index={index}
                 style={style} />
    );
  };

  render(){
    const {
      contacts,
    } = this.props;

    if(!contacts){
      return PlaceholderLoaders.renderContactInfoPlaceholderRows(10);
    }

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            overscanRowCount={10}
            rowCount={contacts.length}
            rowHeight={obj => this.getItemSize(obj.index)}
            rowRenderer={this.rowRenderer}
          />
        )}
      </AutoSizer>
    )
  }
}

GuestList.propTypes = {
  contacts : PropTypes.array,
  onRemoveGuest : PropTypes.func.isRequired,
  onGuestRowFileDrop : PropTypes.func.isRequired,
  guestClick : PropTypes.func.isRequired,
  activeDM : PropTypes.object,
  onRef : PropTypes.func.isRequired,

  onBeforePrintPreview: PropTypes.func.isRequired,
  onPrintPreviewError: PropTypes.func.isRequired,
  onAfterPrintPreview:PropTypes.func.isRequired,
  getPrintPreviewContents:PropTypes.func.isRequired,
  onDocAttach:PropTypes.func.isRequired,
  onSendInvoiceClick:PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GuestList);
