import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import {withVFTranslation} from "../../util/withVFTranslation";
import Checkbox from "../partials/elements/Checkbox";

class DoNotShowAgainDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      doNotShowAgainChecked : false,
    }
  }

  componentDidMount() {
    const {modalProps} = this.props;

    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  okClick(){
    this.closeModal({
      result : true,
      doNotShowAgain : !!this.state.doNotShowAgainChecked
    })
  }

  cancelClick(){
    this.closeModal({
      result : false,
      doNotShowAgain : !!this.state.doNotShowAgainChecked
    });
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  onEscapeKey(){
    this.closeModal({
      result : false
    });
  }

  render() {
    let {
      doNotShowAgainChecked
    } = this.state;
    let {modalProps, t, showDoNotShowAgainCheckbox} = this.props;

    let okText = modalProps.okBtn || t('OK');
    let cancelText = modalProps.cancelBtn || t('Cancel')

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={() => this.cancelClick()} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p className="whitespace-prewrap">{modalProps.message}</p>
        </div>
        <div className="modal-footer" style={{justifyContent:'space-between'}}>
          <div className="d-inline-block">
            <Checkbox isChecked={!!doNotShowAgainChecked}
                      rootCls="big-round-checkbox form-control-lg"
                      label={t("Do not show again")}
                      onChange={() => this.setState({ doNotShowAgainChecked : !this.state.doNotShowAgainChecked})}
                      labelCls={'primary-color small'} />
          </div>

          <div>
            <Button className={'btn btn-secondary mr-1'} onClick={() => this.cancelClick()}>{cancelText}</Button>
            <Button className={'btn btn-primary'} onClick={() => this.okClick()}>{okText}</Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

DoNotShowAgainDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DoNotShowAgainDialog));
