import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash'
import log from "../../../util/log";

const PlaceholderLoaders = {

  //Delicate stuff in here!  The placeholder-shimmer class puts an animation on the background, then
  //using css absolute styling you cover the portions you want with white blocks.  So you basically
  //cut out the reverse of whatever pattern you want here

  renderAccountPlaceholderRows : (rowCount) => {
    return (
      <>
        {_.map(new Array(rowCount), (r, index) => {
          return PlaceholderLoaders.AccountRow(index);
        })}
      </>
    )
  },

  renderWorkspacePlaceholderRows : (rowCount) => {
    return (
      <>
        {_.map(new Array(rowCount), (r, index) => {
          return PlaceholderLoaders.WorkspaceRow(index);
        })}
      </>
    )
  },

  renderContactInfoPlaceholderRows : (rowCount) => {
    return (
      <>
        {_.map(new Array(rowCount), (r, index) => {
          return PlaceholderLoaders.ContactInfoRow(index);
        })}
      </>
    )
  },

  renderMessageBlockPlaceholderRows : (rowCount) => {
    return (
      <>
        {_.map(new Array(rowCount), (r, index) => {
          return PlaceholderLoaders.MessageBlockRow(index);
        })}
      </>
    )
  },

  renderDocPlaceholderRows : (rowCount) => {
    return (
      <>
        {_.map(new Array(rowCount), (r, index) => {
          return PlaceholderLoaders.DocRow(index);
        })}
      </>
    )
  },

  AccountRow : (key) => {
    return (
      <div key={key} className="mt-1 mb-2" style={style.acctRowWrap}>
        <div className="placeholder-shimmer-lg" style={style.acctRowBackground}>
          <div style={{
            ...style.acctElemBase,
            ...{
              left: '250px',
              top: '0px',
              height: '33px'
            }
          }}
          />
          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '33px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '98px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '163px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '238px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '303px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.acctElemBase,
            ...{
              left: '0px',
              top: '368px',
              height: '2px'
            }
          }}
          />

        </div>
      </div>
    )
  },

  WorkspaceRow : (key) => {
    //workspace row 49 px total
    //rows: 19px, 15px, 15px
    return (
      <div key={key} className="p-2" style={style.workspaceRowWrap}>
        <div className="placeholder-shimmer" style={style.workspaceRowBackground}>
          <div style={{
            ...style.workspaceElemBase,
            ...{
              height: '48px',
              left: '48px',
              right: 'auto',
              top: 0,
              width: '8px' //how far is text from placeholder icon?
            }
          }}
          />
          <div style={{
            ...style.workspaceElemBase,
            ...{
              left: '400px',
              top: '0px',
              height: '17px'
            }
          }}
          />
          <div style={{
            ...style.workspaceElemBase,
            ...{
              left: '48px',
              top: '17px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.workspaceElemBase,
            ...{
              left: '250px',
              top: '19px',
              height: '13px'
            }
          }}
          />

          <div style={{
            ...style.workspaceElemBase,
            ...{
              height: '2px',
              left: '48px',
              top: '32px',
            }
          }}
          />
          <div style={{
            ...style.workspaceElemBase,
            ...{
              left: '200px',
              top: '34px',
              height: '13px'
            }
          }}
          />
          <div style={{
            ...style.workspaceElemBase,
            ...{
              height: '2px',
              left: '48px',
              top: '47px',
            }
          }}
          />
        </div>
      </div>
    )
  },

  ContactInfoRow : (key) => {
    //workspace row 49 px total
    //rows: 19px, 15px, 15px
    return (
      <div key={key} className="p-2" style={style.contactRowWrap}>
        <div className="placeholder-shimmer" style={style.contactRowBackground}>
          <div style={{
            ...style.contactElemBase,
            ...{
              height: '48px',
              left: '48px',
              right: 'auto',
              top: 0,
              width: '8px' //how far is text from placeholder icon?
            }
          }}
          />
          <div style={{
            ...style.contactElemBase,
            ...{
              left: '200px',
              top: '0px',
              height: '17px'
            }
          }}
          />
          <div style={{
            ...style.contactElemBase,
            ...{
              left: '48px',
              top: '17px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.contactElemBase,
            ...{
              left: '150px',
              top: '19px',
              height: '13px'
            }
          }}
          />

          <div style={{
            ...style.contactElemBase,
            ...{
              height: '2px',
              left: '48px',
              top: '32px',
            }
          }}
          />
          <div style={{
            ...style.contactElemBase,
            ...{
              left: '100px',
              top: '34px',
              height: '13px'
            }
          }}
          />
          <div style={{
            ...style.contactElemBase,
            ...{
              height: '2px',
              left: '48px',
              top: '47px',
            }
          }}
          />
        </div>
      </div>
    )
  },

  DocRow : (key) => {
    //doc row 60 px total
    //margin left 30, top row 24, bottom 14
    return (
      <div key={key} className="pt-1 pb-2" style={style.docRowWrap}>
        <div className="placeholder-shimmer" style={style.docRowBackground}>
          <div style={{
            ...style.docElemBase,
            ...{
              left: '200px',
              top: '0px',
              height: '17px'
            }
          }}
          />
          <div style={{
            ...style.docElemBase,
            ...{
              left: '0px',
              top: '17px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.docElemBase,
            ...{
              left: '150px',
              top: '19px',
              height: '13px'
            }
          }}
          />

          <div style={{
            ...style.docElemBase,
            ...{
              height: '2px',
              left: '0px',
              top: '32px',
            }
          }}
          />
          <div style={{
            ...style.docElemBase,
            ...{
              height: '15px',
              left: '0px',
              top: '34px',
            }
          }}
          />
        </div>
      </div>
    )
  },

  MessageBlockRow : (key) => {
    return (
      <div key={key} className="m-2" style={style.messageBlockRowWrap}>
        <div className="placeholder-shimmer" style={style.messageBlockRowBackground}>
          <div style={{
            ...style.messageBlockElemBase,
            ...{
              height: '300px',
              left: '48px',
              right: 'auto',
              top: 0,
              width: '8px' //how far is text from placeholder icon?
            }
          }}
          />

          <div style={{
            ...style.messageBlockElemBase,
            ...{
              height: (300 - 48) + 'px',
              left: '0px',
              right: '48px',
              top: '48px',
              width: '48px' //how far is text from placeholder icon?
            }
          }}
          />

          <div style={{
            ...style.messageBlockElemBase,
            ...{
              left: '300px',
              top: '0px',
              height: '17px'
            }
          }}
          />
          <div style={{
            ...style.messageBlockElemBase,
            ...{
              left: '48px',
              top: '17px',
              height: '2px'
            }
          }}
          />

          <div style={{
            ...style.messageBlockElemBase,
            ...{
              left: '150px',
              top: '19px',
              height: '13px'
            }
          }}
          />

          <div style={{
            ...style.messageBlockElemBase,
            ...{
              height: '2px',
              left: '48px',
              top: '32px',
            }
          }}
          />

        </div>
      </div>
    )
  }
}

const style = {
  workspaceRowWrap : {
    marginLeft: '40px',
    maxWidth: '500px',
    maxHeight: '64px',
    animationDuration: '2s',
    animationName: 'fadeIn',
  },
  workspaceRowBackground : {
    backgroundSize: '800px 49px',
    height: '49px'
  },
  workspaceElemBase : {
    height: '6px',
    left: 0,
    position: 'absolute',
    right: 0,
    background: '#fff'
  },

  contactRowWrap : {
    marginLeft: '40px',
    maxWidth: '300px',
    maxHeight: '64px',
    animationDuration: '2s',
    animationName: 'fadeIn',
  },
  contactRowBackground : {
    backgroundSize: '800px 49px',
    height: '49px'
  },
  contactElemBase : {
    height: '6px',
    left: 0,
    position: 'absolute',
    right: 0,
    background: '#fff'
  },

  docRowWrap : {
    marginLeft: '30px',
    maxWidth: '600px',
    maxHeight: '60px',
    animationDuration: '2s',
    animationName: 'fadeIn',
  },
  docRowBackground : {
    backgroundSize: '800px 49px',
    height: '49px'
  },
  docElemBase : {
    height: '6px',
    left: 0,
    position: 'absolute',
    right: 0,
    background: '#fff'
  },

  acctRowWrap : {
    marginLeft: '30px',
    maxWidth: '1600px',
    maxHeight: '443px',
    animationDuration: '2s',
    animationName: 'fadeIn',
  },
  acctRowBackground : {
    backgroundSize: '1600px 443px',
    height: '443px'
  },
  acctElemBase : {
    height: '6px',
    left: 0,
    position: 'absolute',
    right: 0,
    background: '#fff'
  },

  messageBlockRowWrap : {
    maxWidth: '800px',
    maxHeight: '300px',
    animationDuration: '2s',
    animationName: 'fadeIn',
  },
  messageBlockRowBackground : {
    backgroundSize: '800px 300px',
    height: '300px'
  },
  messageBlockElemBase : {
    height: '6px',
    left: 0,
    position: 'absolute',
    right: 0,
    background: '#fff'
  }
}

export default PlaceholderLoaders;
