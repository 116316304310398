import c from '../util/const';

let initialState = {
  qs: null,
  qsEmail: null,
  qsActionNeeded : false,
  isBrowserTabActive: true,
  applicationError : null,
  serverConfig : null,
}

const app = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.app.setApplicationError:
      return {
        ...state,
        applicationError : action.error
      }

    case c.actions.app.setQueryString:
      return {
        ...state,
        qs: {...action.qs}
      };

    case c.actions.app.setBrowserTabActive:
      return {
        ...state,
        isBrowserTabActive: action.isActive
      }

    case c.actions.app.setBrowserTabMerchantStatus:
      return {
        ...state,
        merchantPendingFlag: action.merchantPendingFlag
      }

    case c.actions.app.setQsEmail:
      return {
        ...state,
        qsEmail: action.qsEmail
      };

    case c.actions.app.setQsActionNeeded:
      return {
        ...state,
        qsActionNeeded: action.qsActionNeeded
      };

    case c.actions.app.setServerConfig:
      return {
        ...state,
        serverConfig: {...action.data}
      };

    default:
      return state;
  }
}

export default app;
