import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';
import homeActions from "./home-actions";
import workspaceActions from "./workspace-actions";
import moment from 'moment';
import _ from 'lodash';
import log from "../util/log";
import api from "../util/api";
import config from "../util/config";
import electronUtil from "../util/electron-util";
import threadActions from "./thread-actions";

const sharedActions = {

  startup() {
    let me = this;

    return (dispatch) => {

      //Watch out what you add here!
      //Any crashes in the main returned promise will crash the app.
      //There's also somewhat delicate optimizations here around displaying
      //app ui as soon as possible.

      return Promise.all([
          dispatch(me.updateDirectMessages()),
          dispatch(me.updateAccountInfo()),
        ])
        .then(() => {

          dispatch({
            type : c.actions.shared.setStartupFirstCallsLoaded,
            hasLoaded : true
          })
          log.log('first startup calls finished')

          return Promise.all([
            dispatch(me.updateWorkspaces()),
            dispatch(me.updateStripeAvailables())
          ])
        })
        .then((res) => {
          dispatch({
            type: c.actions.shared.setLoaded,
            hasLoaded: true
          })

          //No need to put this in the main promise flow.
          //We can manage without this temporarily while loading,
          //and more importantly we don't want errors here to crash the main startup flow.
          Promise.all([
              dispatch(me.updateLogo()),
              dispatch(me.updateNotifications()),
              dispatch(me.refreshTermsTemplates()),
              dispatch(me.updatePublisherList())
            ])
            .catch((err) => {
              log.warn('Non-breaking error on startup', err);
            })
        })
    }
  },

  cleanup() {
    return {
      type : c.actions.shared.cleanup,
    }
  },

  setAccountLanguage(language){
    return (dispatch, getState) => {
      return sapi.AccountInfo.update({
          language: language
        })
        .then((res) => {
          dispatch(this.updateAccountInfo());
        })
    }
  },

  resolveUserLanguageSettings(i18n) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let language = _.get(getState(), 'shared.accountInfo.language');
        let supportedLanguages = _.get(i18n, 'options.supportedLngs', [c.language.en_us])
        if (language && supportedLanguages.indexOf(language) >= 0) {
          //then set the language here
          i18n.changeLanguage(language, (err, t) => {
            if (err) {
              log.log('something went wrong changing language', err);
              reject(false);
              return;
            }
            resolve(true);
          });
        }
        else {
          log.log('resolveUserAccountLanguage found unsupported language.  Setting default.', language);
          //otherwise it's not set, we need to set it from current settings
          dispatch(this.setAccountLanguage(i18n.language))
            .finally(() => {
              resolve(true);
            })
        }
      })
    }
  },

  refreshInvoiceHistoryList() {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        resolve(
          sapi.Merchant.listBills()
            .then((res) => {
              log.log('got merchant bill list res', res);

              dispatch({
                type: c.actions.shared.updateMerchantBillList,
                merchantBillList: _.get(res, 'data', [])
              })
            })
        )
      })
    }
  },

  refreshTermsTemplates(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let terms_id = '';
        resolve(
          sapi.SignTermsTemplate.list()
            .then((res) => {
              //at the moment, there is really only one or none terms templates.  so just pull the one, or we're default.
              terms_id = _.get(res, 'data[0].terms_id');
              if(terms_id){
                return sapi.SignTermsTemplate.get(terms_id)
              }
              else{
                return Promise.resolve(null);
              }
            })
            .then((termsRes) => {
              dispatch({
                type : c.actions.shared.updateTermsTemplates,
                template : _.get(termsRes, 'data'),
                terms_id
              })
            })
        )
      })
    }
  },

  updateStripeData(){
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        sapi.Stripe.list()
          .then((data) => {
            dispatch({
              type: c.actions.shared.updateStripeData,
              stripeData: data.data
            })
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  updateStripeAvailables() {
    return (dispatch) => {
      return sapi.Stripe.availablePlans()
        .then((data) => {
          dispatch({
            type: c.actions.shared.updateStripeAvailables,
            stripeAvailables: data.data
          })
        })
    }
  },

  updateLogo(){
    return (dispatch) => {
      return sapi.Workspace.logo()
        .then((res) => {

          let logoRes = res.data.logo;
          //Weird, but the backend returns a single pixel response when there's no logo.
          if(logoRes === c.BLANK_LOGO){
            dispatch({
              type : c.actions.shared.updateLogo,
              data : null
            })
          }
          else{
            dispatch({
              type : c.actions.shared.updateLogo,
              data : logoRes
            })
          }
        })
        .catch((err) => {
          log.log('error fetching logo', err);
          dispatch({
            type : c.actions.shared.updateLogo,
            data : null
          })
        })
    }
  },

  monitorContactImportQueue(callUpdateListWhenDone) {
    return (dispatch, getState) => {
      return sapi.Contacts.queue()
        .then((res) => {

          let queuedContacts = [];
          let queueRes = res.data;
          if (queueRes.status && queueRes.status.undone > 0) {

            _.each(queueRes.queue, (queuedItem) => {
              let existingContact = _.find(getState().shared.directMessages, (c) => { return c.email_address === queuedItem.email; })
              if(!existingContact) {
                queuedContacts.push({
                  email_alert_flag: true,
                  guest_uid: _.uniqueId('fake-guest-uid'), //just so we have something to use as a key.
                  first_name: queuedItem.first_name,
                  last_name: queuedItem.last_name,
                  email_address: queuedItem.email,
                  is_pending: queuedItem.status === 'undone',
                })
              }
            })

            let existingDms = getState().shared.directMessages;
            let combinedRes = _.concat(existingDms, queuedContacts);
            dispatch({
              type: c.actions.shared.updateDirectMessages,
              directMessages: combinedRes
            })

            setTimeout(() => {
              log.log('running new queue pass');
              dispatch(this.monitorContactImportQueue(true));
            }, 5000);
          }
          else if (callUpdateListWhenDone) {
            dispatch(this.updateDirectMessages());
          }
        })
    }
  },

  updateSignatures(){
    return (dispatch) => {
      return sapi.AccountInfo.getSignatures()
        .then((res) => {
          dispatch({
            type : c.actions.shared.updateAccountSignatures,
            signatures : res.data
          })
        })
        .catch((err) => {
          log.log('no signatures in account', err);
          dispatch({
            type : c.actions.shared.updateAccountSignatures,
            signatures : null
          })
        })
    }
  },

  updateWorkspaces() {
    return (dispatch) => {
      return sapi.Workspace.get()
        .then(data => {
          dispatch(homeActions.updateWorkspaceLists(data.data))
          dispatch({
            type: c.actions.shared.updateWorkspaces,
            workspaces: data.data
          });
          return data.data;
        })
    }
  },

  updateDirectMessages(checkImportQueue) {
    return (dispatch, getState) => {
      let dmList = null;

      return sapi.DM.list('updated_date', true)
        .then((res) => {
          dmList = res.data;
          dispatch({
            type: c.actions.shared.updateDirectMessages,
            directMessages: dmList
          })

          if(checkImportQueue){
            //Longer process that we don't want to wait for.  We want to check if there are more
            //queued contacts any time we check this list, it updates things in the background.
            dispatch(this.monitorContactImportQueue(false));
          }

          if(getState().thread.activeDM){
            let foundDM = null;
            _.each(dmList, (dm) => {
              if(dm.guest_uid === getState().thread.activeDM.guest_uid){
                foundDM = dm;
              }
            })

            if(foundDM){
              return dispatch({
                type: c.actions.thread.setActiveDM,
                dm : foundDM
              });
            }
          }
        })
    }
  },

  //The full update flag indicates if we should wait for accountClassInfo and merchantInfo to update
  //before this promise resolves.  If we want it to happen asynchronously, you can omit this.
  updateAccountInfo(waitForFullUpdate= false) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        let curAcctClassId = _.get(getState(), 'shared.accountInfo.class_id', null);

        return sapi.AccountInfo.get()
          .then(data => {
            let accountInfo = data.data;
            dispatch({
              type: c.actions.shared.updateAccountInfo,
              accountInfo,
              //just a helper, to normalize your guest info against normal guest calls.
              accountInfoGuest : {
                first_name : accountInfo.first_name,
                last_name : accountInfo.last_name,
                guest_uid : accountInfo.uid,
                email_address : accountInfo.login,
                email : accountInfo.login
              }
            });

            if(waitForFullUpdate){
              let newClassId = _.get(accountInfo, 'class_id');
              if(curAcctClassId !== newClassId && newClassId){
                resolve(
                  dispatch(this.updateAccountClassInfo(newClassId, waitForFullUpdate))
                    .then(() => {
                      return data.data;
                    })
                )
              }
              else{
                resolve(data.data);
              }
            }
            else{
              let newClassId = _.get(accountInfo, 'class_id');
              if(curAcctClassId !== newClassId && newClassId){
                dispatch(this.updateAccountClassInfo(newClassId))
              }
              resolve(data.data);
            }
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  updateAccountClassInfo(class_id, waitForFullUpdate){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        if(!class_id){
          reject('no class_id present');
          return;
        }
        api.ClassInfo.get(class_id)
          .then((classInfoRes) => {
            dispatch({
              type: c.actions.shared.updateAccountClassInfo,
              accountClassInfo : classInfoRes.data
            });
            if(waitForFullUpdate){
              resolve(
                dispatch(this.updateMerchantInfoIfNeeded(classInfoRes.data))
                  .then(() => {
                    return classInfoRes.data;
                  })
              )
            }
            else{
              dispatch(this.updateMerchantInfoIfNeeded(classInfoRes.data))
              resolve(classInfoRes.data);
            }
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  updateMerchantInfo(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        sapi.Merchant.info()
          .then((merchantInfo) => {
            dispatch({
              type: c.actions.shared.updateMerchantInfo,
              merchantInfo : merchantInfo.data
            });

            resolve(merchantInfo.data);
          })
          .catch((err) => {
            //This is a supported case for this to fail.
            //It could mean that the merchant account is not configured yet, or something other issue is going on.
            //we want to deal with this gracefully.
            log.log('error fetching merchant_info', err);
            dispatch({
              type: c.actions.shared.updateMerchantInfo,
              merchantInfo : null
            });
            resolve(null);
          })
      })
    }
  },

  updatePublisherList(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        sapi.Publisher.list()
          .then((publisherList) => {

            //Looks unecessary, but the server can return null for this list if you remove
            //all your publishers.  Just guarding.  bug 3804
            let list = _.get(publisherList, 'data', []) || [];
            dispatch({
              type: c.actions.shared.updatePublisherList,
              publisherList : list
            });

            resolve(list);
          })
          .catch((err) => {
            reject(err);
          })
      })
    }
  },

  updateMerchantInfoIfNeeded(accountClassInfo){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
        // log.log('update merchant info?', merchant_flag,  _.get(getState(), 'shared.accountClassInfo', false))
        if(!merchant_flag){
          dispatch({
            type: c.actions.shared.updateMerchantInfo,
            merchantInfo : null
          });
          resolve(null);
          return;
        }

        resolve(dispatch(this.updateMerchantInfo()));
      })
    }
  },

  //Helper call to refresh the account info only if they are a
  //user who has a license limit to refresh.
  updateAccountInfoForLimits(){
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        // "class_info" : {
        //   "class_id" : 1,
        //     "currency" : "USD",
        //     "domain" : null,
        //     "expiry_date" : null,
        //     "forum_max_doc" : 150,
        //     "forum_max_item" : 10,
        //     "forum_max_msgs" : 500,
        //     "forum_max_total" : 3,
        //     "grace_items" : 4,
        //     "grace_size" : 11,
        //     "inst_id" : "0000000000000000",
        //     "label" : "Basic",
        //     "max_guest" : 2500,
        //     "max_storage" : 5368709120,
        //     "next_class_id" : 100,
        //     "sign_req_max" : 5,
        //     "stripe_id" : null,
        //     "term_cost" : 0,
        //     "term_length" : 3153600000
        // },

        let currentClassInfo = _.get(getState(), 'shared.accountClassInfo.class_info', null);
        if(!currentClassInfo){
          reject('no class_info present');
        }

        //If it's not a number, then they're a user who has no limits to enforce.
        //No need to update this.
        if(!_.isNumber(currentClassInfo.sign_req_max)) {
          resolve(true);
        }

        resolve(dispatch(this.updateAccountInfo()));
      })
    }
  },

  updateNotifications() {
    return (dispatch, getState) => {

      let { accountInfo } = getState().shared;
      if(accountInfo && accountInfo.auto_notify_flag){
        return Promise.resolve(true);
      }

      return sapi.Notify.count()
        .then(data => {
          dispatch({
            type: c.actions.shared.updateNotificationCount,
            notifyCount: data.data.notification_count
          });
          return data.data;
        })
    }
  },

  queueDMMsgTransaction(transaction_id, guest_uid, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          guest_uid,
          mesg,
          type: this.TRANSACTION_TYPES.DM_MESSAGE,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  mapTransactionResult(transaction_id, res){
    return (dispatch, getState) => {
      if(res) {

        let parsedMesgId = _.get(res, 'data.mesg_id');
        if(!_.isNumber(parsedMesgId) || parsedMesgId < 0){

          //this happens during doc attach right now.
          //if you can't pull a mesg_id, then we don't know what to map this transaction to
          //so just clear it!

          log.warn('unable to parse mesg_id from response', res, _.get(res, 'data.mesg_id'));
          dispatch(this.clearTransaction(transaction_id));
        }
        else{
          dispatch({
            type : c.actions.shared.updateTransaction,
            transaction : {
              transaction_id,
              res,
              mesg_id : parsedMesgId,
              isComplete : true
            }
          })
        }
      }
      else{
        dispatch(this.clearTransaction(transaction_id));
      }
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  clearTransaction(transaction_id){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.shared.clearTransaction,
        transaction_id
      })
    }
  },

  queueDMDocTransaction(transaction_id, original_mesg_id, guest_uid, upload_id, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          original_mesg_id,
          guest_uid,
          upload_id,
          files,
          mesg,
          type: this.TRANSACTION_TYPES.DM_DOC,
          queue_time: moment(),
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueDMDocAttachTransaction(transaction_id, guest_uid, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          guest_uid,
          mesg,
          files,
          type: this.TRANSACTION_TYPES.DOC_ATTACH,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueThreadDocAttachTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          forum_id,
          host_uid,
          chat_id,
          mesg,
          files,
          type: this.TRANSACTION_TYPES.DOC_ATTACH,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueDMInvoiceTransaction(transaction_id, guest_uid, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          guest_uid,
          mesg,
          files,
          type: this.TRANSACTION_TYPES.INVOICE,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueThreadInvoiceTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          forum_id,
          host_uid,
          chat_id,
          mesg,
          files,
          type: this.TRANSACTION_TYPES.INVOICE,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueThreadMsgTransaction(transaction_id, forum_id, host_uid, chat_id, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          forum_id,
          host_uid,
          chat_id,
          mesg,
          type: this.TRANSACTION_TYPES.MESSAGE,
          queue_time: moment()
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  queueThreadDocTransaction(transaction_id, original_mesg_id, forum_id, host_uid, chat_id, upload_id, files, mesg){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.shared.queueThreadTransaction,
        threadTransactionQueue: [{
          transaction_id,
          original_mesg_id,
          forum_id,
          host_uid,
          chat_id,
          upload_id,
          files,
          mesg,
          type: this.TRANSACTION_TYPES.DOC,
          queue_time: moment(),
        }]
      })
      dispatch(threadActions.refreshPendingMessages())
    }
  },

  mapToDispatch(dispatch) {
    return {
      mapTransactionResult : (transaction_id, res) => dispatch(this.mapTransactionResult(transaction_id, res)),
      clearTransaction : (transaction_id) => dispatch(this.clearTransaction(transaction_id)),
      queueThreadMsgTransaction : (transaction_id, forum_id, host_uid, chat_id, mesg) => dispatch(this.queueThreadMsgTransaction(transaction_id, forum_id, host_uid, chat_id, mesg)),
      queueThreadDocTransaction : (transaction_id, original_mesg_id, forum_id, host_uid, chat_id, upload_id, files, mesg) => dispatch(this.queueThreadDocTransaction(transaction_id, original_mesg_id, forum_id, host_uid, chat_id, upload_id, files, mesg)),

      queueThreadDocAttachTransaction : (transaction_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(this.queueThreadDocAttachTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg)),
      queueDMDocAttachTransaction: (transaction_id, guest_uid, files, mesg) => dispatch(this.queueDMDocAttachTransaction(transaction_id, guest_uid, files, mesg)),
      queueThreadInvoiceTransaction : (transaction_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(this.queueThreadInvoiceTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg)),
      queueDMInvoiceTransaction: (transaction_id, guest_uid, files, mesg) => dispatch(this.queueDMInvoiceTransaction(transaction_id, guest_uid, files, mesg)),
      queueDMDocTransaction: (transaction_id, original_mesg_id, guest_uid, upload_id, files, mesg) => dispatch(this.queueDMDocTransaction(transaction_id, original_mesg_id, guest_uid, upload_id, files, mesg)),
      queueDMMsgTransaction:(transaction_id, guest_uid, mesg) => dispatch(this.queueDMMsgTransaction(transaction_id, guest_uid, mesg)),

      resolveUserLanguageSettings:(i18n) => dispatch(this.resolveUserLanguageSettings(i18n)),
      setAccountLanguage:(language) => dispatch(this.setAccountLanguage(language)),
      refreshTermsTemplates:() => dispatch(this.refreshTermsTemplates()),
      refreshInvoiceHistoryList:() => dispatch(this.refreshInvoiceHistoryList()),

      updateLogo: () => dispatch(this.updateLogo()),
      updateStripeData: () => dispatch(this.updateStripeData()),
      updateMerchantInfo: () => dispatch(this.updateMerchantInfo()),
      updatePublisherList: () => dispatch(this.updatePublisherList()),
      updateStripeAvailables: () => dispatch(this.updateStripeAvailables()),
      updateNotifications: () => dispatch(this.updateNotifications()),
      updateAccountInfo : (waitForFullUpdate) => dispatch(this.updateAccountInfo(waitForFullUpdate)),
      updateAccountClassInfo : (class_id, waitForFullUpdate) => dispatch(this.updateAccountClassInfo(class_id, waitForFullUpdate)),
      updateAccountInfoForLimits : () => dispatch(this.updateAccountInfoForLimits()),
      updateDirectMessages: (checkImportQueue) => dispatch(this.updateDirectMessages(checkImportQueue)),
      updateWorkspaces : () => dispatch(this.updateWorkspaces()),
      updateSignatures : () => dispatch(this.updateSignatures())
    }
  },
}

sharedActions.TRANSACTION_TYPES = {
  MESSAGE : 'msg',
  DOC_ATTACH: 'doc_attach',
  DOC : 'doc',
  DM_MESSAGE : 'dm_msg',
  DM_DOC : 'dm_doc',
  INVOICE : 'invoice'
}

export default sharedActions;
