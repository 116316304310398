import React, {PureComponent, Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import utils from '../../../util/util';
import c from '../../../util/const';

import moment from 'moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import MessageBlock from "./MessageBlock";
import colors from "../../../util/colors";
import Scroll from 'react-scroll';
import Autolinker from "autolinker";
import he from 'he';
import filters from "../../../helpers/filters";
import EmptyState from "../components/EmptyState";
import Button from "../elements/Button";
import ReactToPrint from "react-to-print";
import ChatPrintPreviewSvc from "./ChatPrintPreviewSvc";
import {List, AutoSizer} from 'react-virtualized';
import Measure from "react-measure";
import msgHelper from "../../../helpers/msg-helper";
import workspaceActions from "../../../actions/workspace-actions";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import ReactDOM from "react-dom";
import RenderPortal from "../util/RenderPortal";
import SignatureRequest from "../../../models/SignatureRequest";
import MessagePanelChangeManager from "./MessagePanelChangeManager";
import VirtualMessageBlock from "./VirtualMessageBlock";
import MessageList from "./MessageList";
import RenderedChatMessage from "../../../models/RenderedChatMessage";

class ChatPanelMessages extends Component {

  mounted = false;
  didUnmount = false;
  listRef = null;

  changeManagerRef = null;

  constructor(props) {
    super(props);

    this.mounted = false;
    this.didUnmount = false;

    this.printPreviewRef = React.createRef();

    this.state = {
      doingPrintPreview: false,
      pollingRunning: false,
      pollTimer: null,
      showNewMessageIndicator: false,

      autoSizerHeight: -1,
      autoSizerWidth: -1,

      renderedMessages : null,
    }
  }

  componentDidMount() {
    this.mounted = true;

    this.setState({pollingRunning: true}, () => this.doPollMessages());

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.state.pollTimer) {
      clearTimeout(this.state.pollTimer);
    }
    this.setState({
      pollingRunning: false,
      pollTimer: null
    });

    this.mounted = false;
    this.didUnmount = true;

    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let prevDM = _.get(prevProps, 'dm');
    let curDM = _.get(this.props, 'dm');
    let prevThread = _.get(prevProps, 'thread');
    let curThread = _.get(this.props, 'thread');

    if(prevDM && curDM && prevDM.guest_uid !== curDM.guest_uid){
      this.init(curDM.guest_uid);
    }
    else if(prevThread && curThread && prevThread.chat_id !== curThread.chat_id){
      this.init(curThread.chat_id);
    }
  }

  scrollToNewMessageOrIndicate() {
    setTimeout(() => {
      if(this.listRef) {
        let distanceFromBottom = this.listRef.getScrollDistanceFromBottom();
        log.log('handle new chat message', distanceFromBottom);
        if (distanceFromBottom <= 400) {
          this.scrollToBottom();
        }
        else {
          this.setState({showNewMessageIndicator: true});
        }
      }
    }, 0)
  }

  doAutoStartSignatureRequestIfNeeded(prevMessageBlocks, thisMessageBlocks){
    // log.log('do auto start called')
    //criteria here:
    //1. one new message present
    //2. it's yours
    //3. one doc present
    //4. doc has a signature request
    //do autostart

    let beforeMesgIds = [];
    let afterMesgIds = [];
    _.each(prevMessageBlocks, (beforeBlock) => {
      let blockList = _.get(beforeBlock.getMessageData(), 'blockList', []);
      _.each(blockList, (msg) => {
        beforeMesgIds.push(msg.mesg_id);
      })
    })
    _.each(thisMessageBlocks, (afterBlock) => {
      let blockList = _.get(afterBlock.getMessageData(), 'blockList', []);
      _.each(blockList, (msg) => {
        afterMesgIds.push(msg.mesg_id);
      })
    })

    let diff = _.difference(afterMesgIds, beforeMesgIds);

    //log.log('do auto start message diff', diff);
    if(diff.length === 1){
      let messageIdToTest = diff[0];
      let foundMessage = null;
      _.each(thisMessageBlocks, (afterBlock) => {
        let blockList = _.get(afterBlock.getMessageData(), 'blockList', []);
        _.each(blockList, (msg) => {
          if(msg.mesg_id === messageIdToTest){
            foundMessage = msg;
            return false; //early exit
          }
        })
        if(foundMessage){
          return false; //early exit
        }
      })

      // log.log('do auto start found message?', foundMessage, this.props.accountInfo);
      let currentUserUid = _.get(this.props, 'accountInfo.uid')
      // log.log('do auto start found message?', foundMessage, currentUserUid, foundMessage.guest_uid === currentUserUid);
      if(foundMessage && foundMessage.guest_uid === currentUserUid){
        // docs: Array(1)
        //    0: {doc_id: "60de1c6e0fb6e9d1", label: "plan_update (1).pdf", sign_request_id: "60de1c6e18c9bd56", sign_status: "pending request", signer_uid: null}
        //    length: 1
        //    __proto__: Array(0)
        // edited_flag: false
        // first_name: "Kevin"
        // guest_uid: "60dd0de25028da95"
        // last_name: "Swartz"
        // mesg: "44444ffffffff"
        // mesg_date: 1625169006
        // mesg_id: 14
        // tokens: [{…}]
        // updated_date: null

        // log.log('WE WOULD AUTO_START NOW????', foundMessage.docs[0], msgHelper.isSigningStatusIncomplete(foundMessage.docs[0]));
        //at this point, we know that there's 1 new message, you sent it, and foundMessage is the new message.
        //if there is one doc present, and it contains a signature request, do autostart.
        if(foundMessage.docs &&
          foundMessage.docs.length === 1 &&
          msgHelper.isSigningStatusIncomplete(foundMessage.docs[0])){
          //Then do auto-start!
          //log.log('WE WOULD AUTO_START NOW');
          this.props.completeSignatureRequestClick(foundMessage, foundMessage.docs[0]);
        }
      }
    }
  }

  init(dm_or_chat_id){
    log.log('chatpanelmessages - init called', dm_or_chat_id);

    //clear your state so you get the loading dialog
    this.setState({renderedMessages : null})
  }

  doPollMessages() {
    if (this.state.pollingRunning && this.mounted) {
      let timer = setTimeout(() => {
        if (!this.state.pollingRunning || !this.mounted) {
          return;
        }

        if (this.props.isBrowserTabActive) {
          log.log('polling messages');
          this.props.refreshMessagesForPolling()
            .catch((err) => {
              log.log('error doing message polling', err);
            })
        }

        this.doPollMessages();
      }, c.polling.threadPollTimeMs);
      this.setState({pollTimer: timer})
    }
  }

  renderNewMessageIndicator() {
    if (!this.state.showNewMessageIndicator) {
      return null;
    }
    let {t} = this.props;
    return (
      <div className="position-absolute fade show text-center"
           style={{
             bottom: 100,
             left: 0,
             right: 0,
             zIndex: 1000
           }}
      >
        <button className="btn btn-primary"
                onClick={this.onNewMessageButtonClick.bind(this)}>
          {t("New Message")}
          <br/>
          {t("Click to View")}
        </button>
      </div>
    )
  }

  onNewMessageButtonClick() {
    this.setState({showNewMessageIndicator: false})
    this.scrollToBottom();
  }

  doPrintPreview() {
    return new Promise((resolve, reject) => {
      this.setState({
        doingPrintPreview: true
      }, () => {
        utils.waitForCondition(() => {
            return !!this.printPreviewRef;
          })
          .then(() => {
            resolve(true);
          })
      })
    })

  }

  cleanupPrintPreview() {
    log.log('cleanup print preview')
    this.setState({
      doingPrintPreview: false
    })
  }

  scrollToBottom() {
    if(this.listRef) {
      this.listRef.scrollToBottom();
    }
  }

  onChangeManagerRef = node => {
    this.changeManagerRef = node;
    if(this.changeManagerRef){

      this.changeManagerRef.setEventHandlers([
        {
          event_type: MessagePanelChangeManager.EVENT_TYPES.INIT,
          callback: (res) => {
            log.log('chatpanelmessages - received init event', res);
            this.setState({renderedMessages : res}, () => {
              this.scrollToBottom();
            });
          }
        },
        {
          event_type: MessagePanelChangeManager.EVENT_TYPES.LOAD_MORE,
          callback: (res) => {
            log.log('chatpanelmessages - received loadmore event', res);

            //need to calc new height from old list so we can adjust virtual scroll to correct position
            let totalNewHeight = 0;
            _.each(res, (s) => {
              let foundRm = _.find(this.state.renderedMessages, (rm) => RenderedChatMessage.getKeyForMessageBlock(rm.getMessageData()) === RenderedChatMessage.getKeyForMessageBlock(s.getMessageData()))
              if(foundRm){
                // log.log('chatpanelmessages - foundRm', foundRm, (foundRm.getMeasuredHeight() - s.getMeasuredHeight()));
                totalNewHeight += foundRm.getMeasuredHeight() - s.getMeasuredHeight();
              }
              else{
                totalNewHeight += s.getMeasuredHeight();
              }
            })
            // log.log('chatpanelmessages - calculated loadmore new height', totalNewHeight);

            this.setState({
              renderedMessages : _.concat([], res)
            }, () => {
              let listRef = _.get(this, 'listRef');
              if(listRef) {
                listRef.addTopSpaceForLoadMore(totalNewHeight);
              }
            });
          }
        },
        {
          event_type: MessagePanelChangeManager.EVENT_TYPES.MESSAGE_UPDATE,
          callback: (res) => {
            log.log('chatpanelmessages - received message update event', res);

            let { renderedMessages } = this.state;
            if(res.length === 0 && (!renderedMessages || renderedMessages.length === 0)){
              log.log('ignore chat update event, no change');
              return;
            }

            let preRenderedMessages = this.state.renderedMessages ? _.concat([], this.state.renderedMessages) : [];
            this.setState({
              renderedMessages : _.concat([], res)
            }, () => {
              let listRef = _.get(this, 'listRef');
              if(listRef) {
                listRef.updateListView();
              }

              //Need to do this in an update check as well, to catch longer running uploads.
              this.doAutoStartSignatureRequestIfNeeded(preRenderedMessages, this.state.renderedMessages);
            });
          }
        },
        {
          event_type: MessagePanelChangeManager.EVENT_TYPES.MESSAGE_ADD,
          callback: (res) => {
            log.log('chatpanelmessages - received message add event', res);

            let preRenderedMessages = this.state.renderedMessages ? _.concat([], this.state.renderedMessages) : [];
            this.setState({
              renderedMessages : _.concat([], res)
            }, () => {
              let listRef = _.get(this, 'listRef');
              if(listRef) {
                listRef.updateListView();
              }

              //One thing to note...this does not distinguish between which user added a message
              //so this gets fired for a message you might have sent in another browser tab
              //there are checks in the auto-start call, but not in the scroll new message call
              //In that case it feels appropriate to flag a new message whether it's yours or not.
              this.scrollToNewMessageOrIndicate();
              this.doAutoStartSignatureRequestIfNeeded(preRenderedMessages, this.state.renderedMessages);
            });
          }
        }
      ])
    }
  };

  onAutoSizerResize({height, width}){
    if(this.didUnmount){
      log.log('ignoring resizer height update after unmounting');
      return;
    }

    let updateNeeded = this.state.autoSizerWidth !== width;
    this.setState({
      autoSizerHeight: height,
      autoSizerWidth: width
    }, () => {
      if(updateNeeded) {
        //if the width changed, then we need to handle the resize.
        //if it's just height, that can't change message block size, so no update needed.
        if (this.changeManagerRef) {
          this.changeManagerRef.handleContainerSizeChange();
        }
      }
    })
  }

  onMessageBlockLoad(index, height){
    let found = this.state.renderedMessages[index];
    // log.log('onMessageBlockLoad', found, found.getMeasuredHeight());
    if(found && found.getMeasuredHeight() !== height){
      //log.log('visible item height update - ', found, height);

      let update = _.concat([], this.state.renderedMessages);
      let found = update[index];
      if(found){
        update[index] = RenderedChatMessage.buildRenderedChatMessage(found.getMessageData(), height);
      }
      this.setState({renderedMessages : update}, () => {
        if(this.listRef){
          this.listRef.updateListView(index);
        }
      });
      if(this.changeManagerRef) {
        this.changeManagerRef.setBlockHeightWithoutUpdate(found)
      }
    }
  }

  onLoadMore(e){
    if(this.changeManagerRef){
      this.changeManagerRef.loadMoreMessages(e)
    }
  }

  renderMessagePanelChangeManager(){
    let {
      autoSizerHeight,
      autoSizerWidth
    } = this.state;

    let {
      dm,
      thread,
      messageBlocks,
      messageBlocksId
    } = this.props;

    if(!autoSizerHeight || !autoSizerWidth || !messageBlocks || !(dm || thread)){
      return null;
    }

    return (
      <MessagePanelChangeManager onRef={(ref) => this.onChangeManagerRef(ref)}
                                 accountInfo={this.props.accountInfo}
                                 findDocInfo={this.props.findDocInfo}
                                 panelHeight={autoSizerHeight}
                                 panelWidth={autoSizerWidth}
                                 findGuestInActiveThreadParticipants={this.props.findGuestInActiveThreadParticipants}
                                 dm={dm}
                                 thread={thread}
                                 messageBlocks={messageBlocks}
                                 messageBlocksId={messageBlocksId} />
    )
  }

  renderMessageList() {
    let {thread, dm, t} = this.props;
    let {
      autoSizerHeight,
      autoSizerWidth,
      renderedMessages,
    } = this.state;

    return (
        <MessageList onRef={node => this.listRef = node}
                     height={autoSizerHeight}
                     width={autoSizerWidth}
                     onScroll={(e) => this.props.onUserInteractionScroll(e)}
                     doLoadMore={(e) => this.onLoadMore(e)}
                     renderedMessages={renderedMessages}
                     rowRenderer={({index, style, parent}) => {
                       let previousItem = index > 0 ? this.state.renderedMessages[index - 1].getMessageData() : null;
                       const item = this.state.renderedMessages[index].getMessageData();
                       return (
                           <div key={index} style={style}>
                             <VirtualMessageBlock currentItem={item}
                                                  windowHeight={autoSizerHeight}
                                                  windowWidth={autoSizerWidth}
                                                  index={index}
                                                  onHeightUpdate={height => this.onMessageBlockLoad(index, height)}
                                                  data={{
                                                     dm,
                                                     thread,
                                                     mesg_edit_flag : this.props.mesg_edit_flag,
                                                     onDocClick: this.props.docClick,
                                                     findDocInfo: this.props.findDocInfo,
                                                     onMesgEditClick : this.props.onMesgEditClick,
                                                     onMesgHistoryClick : this.props.onMesgHistoryClick,
                                                     completeSignatureRequestClick : this.props.completeSignatureRequestClick,
                                                     fulfillSignatureRequestClick : this.props.fulfillSignatureRequestClick,
                                                     cancelSignatureRequestClick : this.props.cancelSignatureRequestClick,
                                                     findGuestInActiveThreadParticipants : this.props.findGuestInActiveThreadParticipants,
                                                     onAttachDocToThread : this.props.onAttachDocToThread,
                                                     onPdfSubmit : this.props.onPdfSubmit
                                                   }}
                                                  previousItem={previousItem}/>
                           </div>
                       )
                     }}/>
    )
  }

  render() {
    let {thread, dm, messageBlocks, t} = this.props;
    let {
      doingPrintPreview,
      renderedMessages,
    } = this.state;

    return (
      <>
        {this.renderMessagePanelChangeManager()}
        {doingPrintPreview &&
          <ChatPrintPreviewSvc onRef={ref => (this.printPreviewRef = ref)}
                               thread={thread}
                               dm={dm}
                               messageBlocks={messageBlocks}
                               findDocInfo={this.props.findDocInfo}/>
        }
        <div style={styles.messagePanelScroll}>
          <>
            {this.renderNewMessageIndicator()}
            <div className="d-block position-relative h-100 no-outline overflow-hidden">
              <AutoSizer
                onResize={(res) => this.onAutoSizerResize(res)}>
                {({height, width}) => {
                  if (!renderedMessages || !messageBlocks) {
                    //the reverse flex direction here is to show the loading blocks from the bottom,
                    //same orientation as the messages, without actually scrolling the panel to the bottom.
                    return (
                      <div style={{
                        height: height,
                        width: width,
                        display: 'flex',
                        flexDirection: 'column-reverse'
                      }}>
                        {PlaceholderLoaders.renderMessageBlockPlaceholderRows(5)}
                      </div>
                    )
                  }
                  else if (renderedMessages.length === 0 && messageBlocks.length === 0) {
                    return (
                      <div style={{height: height, width: width}}>
                        <EmptyState>
                          <div className="text-center secondary-text-color"
                               style={{margin: '0px 100px', paddingTop: '30vh'}}>
                            <p>
                              {t("There are no Documents or Messages in this Thread yet.  Start the conversation below.")}
                            </p>
                          </div>
                        </EmptyState>
                      </div>
                    )
                  }
                  else {
                    return this.renderMessageList();
                  }
                }}
              </AutoSizer>
            </div>
          </>
        </div>
      </>
    )
  }
}

const styles = {
  messagePanelScroll: {
    height: '100%',
    overflow: 'auto',
  },
  msgDateWrap: {
    borderTop: '1px solid ' + colors.LIGHT_GREY,
    margin: '20px auto 0'
  },
  msgDateBlock: {
    position: 'relative',
    backgroundColor: colors.LIGHT,
    textAlign: 'center',
    display: 'table',
    margin: 'auto',
    top: '-9px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '11px'
  }
}

ChatPanelMessages.propTypes = {
  thread: PropTypes.object,
  dm: PropTypes.object,
  messageBlocks: PropTypes.array,
  messageBlocksId : PropTypes.string,
  mesg_edit_flag: PropTypes.bool.isRequired,
  refreshMessagesForPolling: PropTypes.func.isRequired,
  findDocInfo: PropTypes.func.isRequired,
  onMesgHistoryClick: PropTypes.func,
  onMesgEditClick: PropTypes.func,
  onRef: PropTypes.func,
  docClick: PropTypes.func.isRequired,
  completeSignatureRequestClick: PropTypes.func.isRequired,
  fulfillSignatureRequestClick: PropTypes.func.isRequired,
  cancelSignatureRequestClick : PropTypes.func.isRequired,
  onUserInteractionScroll : PropTypes.func.isRequired,

  onAttachDocToThread : PropTypes.func,
  onPdfSubmit : PropTypes.func
}

ChatPanelMessages.defaultProps = {
  messageBlocks: [],
}

const mapStateToProps = (state) => {
  return {
    isBrowserTabActive: state.app.isBrowserTabActive,
    accountInfo: state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    findGuestInActiveThreadParticipants: (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid)),
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChatPanelMessages));
