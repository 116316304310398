import _ from 'lodash'

class RenderedChatMessage{

  static buildRenderedChatMessage(msg, height){
    return new RenderedChatMessage(msg, height)
  }

  static getKeyForMessageBlock(msg){
    return _.get(msg, 'blockList[0].mesg_id');
  }

  constructor(msg, height){
    this.msg = msg;
    this.height = height;
  }

  getMeasuredHeight(){
    return this.height;
  }

  getMessageData(){
    return this.msg;
  }
}

export default RenderedChatMessage;
