import React, {Component} from 'react';
import c from "../../../util/const";
import {Popover} from "react-tiny-popover";
import PropTypes from "prop-types";
import _ from 'lodash'
import log from "../../../util/log";

class VFPopover extends Component {

  //This should be the same amount of time as the .fade-{in/out}-{left/top/right/down}
  //classes.  Otherwise you could get weird flickering and jitter.
  static ANIMATION_TIME_MS = 150;

  static getPopoverKey(popoverName, key){
    return `${popoverName}${key ? '-' + key : ''}`
  }

  constructor(props) {
    super(props);

    this.state = {
      popoverShouldBeOpen : false,
      isPopoverClosing : false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isPopoverOpen && !this.props.isPopoverOpen){
      this.setState({
        isPopoverClosing : true,
      })
      setTimeout(() => {
        this.setState({
          isPopoverClosing : false,
          popoverShouldBeOpen : false
        })
      }, VFPopover.ANIMATION_TIME_MS) //same timeout as fade animation
    }
    else if(!prevProps.isPopoverOpen && this.props.isPopoverOpen){
      //opening, just do it.
      this.setState({
        popoverShouldBeOpen : true
      })
    }
  }

  onClickOutSide(){
    if(this.props.onClickOutside){
      this.props.onClickOutside(...arguments);
    }
  }

  getFadeClassForPosition(position){
    let {
      isPopoverClosing
    } = this.state;

    //log.log('closing popover class', position, isPopoverClosing);
    if(isPopoverClosing){
      if(position === "left"){
        return "fade-out-right";
      }
      else if(position === "right"){
        return "fade-out-left";
      }
      else if(position === "bottom"){
        return "fade-out-up";
      }
      else if(position === "top"){
        return "fade-out-down";
      }
    }
    else{
      if(position === "left"){
        return "fade-in-left";
      }
      else if(position === "right"){
        return "fade-in-right";
      }
      else if(position === "bottom"){
        return "fade-in-down";
      }
      else if(position === "top"){
        return "fade-in-up";
      }
    }
  }

  getContent(){
    if(this.props.getMenuContent){
      let {
        position
      } = arguments[0];
      //log.log('get popover content', arguments);
      return (
        <div className={this.getFadeClassForPosition(position)}>
          {this.props.getMenuContent(...arguments)}
        </div>
      )
    }
  }

  render() {
    let {
      children,
      positions,
      align,
      containerClassName,
      containerStyle,
      reposition
    } = this.props;

    let {
      popoverShouldBeOpen
    } = this.state;

    // log.log('popover render', popoverShouldBeOpen);
    return (
      <Popover isOpen={popoverShouldBeOpen}
               onClickOutside={this.onClickOutSide.bind(this)}
               positions={positions}
               align={align}
               containerStyle={containerStyle}
               boundaryInset={15}
               containerClassName={containerClassName}
               reposition={reposition}
               content={this.getContent.bind(this)}>
        {children}
      </Popover>
    )
  }
}

VFPopover.propTypes = {
  getMenuContent: PropTypes.func.isRequired,
  reposition: PropTypes.bool,
  onClickOutside: PropTypes.func,
  isPopoverOpen: PropTypes.bool.isRequired,
  positions: PropTypes.array,
  align: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object
}

export default VFPopover;