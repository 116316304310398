import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import c from '../../util/const';
import Button from '../partials/elements/Button';
import logo from "../../resources/logo-w-text-white.png";
import logoLockup from "../../resources/logo-lock-up-white.png";
import Image from "../partials/elements/Image";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import slide1Img from '../../resources/tutorial/tutorial_slide1.png';
import slide2Img from '../../resources/tutorial/tutorial_slide2.png';
import slide3Img from '../../resources/tutorial/tutorial_slide3.png';
import slide4Img from '../../resources/tutorial/tutorial_slide4.png';
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class TutorialWindow extends Component {

  SLIDE_COUNT = 5

  constructor(props) {
    super(props);

    this.state = {
      slideIndex : 0
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    let {close} = this.props;

    close();
  }

  getSlideText(){
    let { t } = this.props;
    return [
      t("Click the arrow to see a little about how this works."),
      t("Use Private Messages for always-encrypted one-on-one communications with your Verifyle Contacts."),
      t("Use message attachments to securely share documents and request signatures."),
      t("Attached Documents are always easily accessible, so you don't waste time hunting through old messages."),
      t("Create Workspaces to take control of the situation: organize, orchestrate, administer.")
    ]
  }

  backBtnClick(){
    if(this.state.slideIndex === 0){
      return;
    }

    this.setState({slideIndex : this.state.slideIndex - 1})
  }

  nextBtnClick(){
    if(this.state.slideIndex === this.SLIDE_COUNT){
      return;
    }

    this.setState({slideIndex : this.state.slideIndex + 1})
  }

  contactUsClick(){
    this.props.showContactUsWindow();
  }

  getTextForSlideIndex(){
    return this.getSlideText()[this.state.slideIndex]
  }

  onCarouselSlideChange(newIndex){
    this.setState({slideIndex : newIndex})
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, t} = this.props;
    let { slideIndex } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header black-bg light-color">
          <h5 className="modal-title">{t("The Verifyle Basics")}</h5>
          <button type="button" className="close light-color" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body p-0">

          <div>
            <Carousel showArrows={false}
                      showThumbs={false}
                      showStatus={false}
                      onChange={this.onCarouselSlideChange.bind(this)}
                      selectedItem={slideIndex}
            >
              <div className="pb-5">
                <div className="text-center light-color">
                  <h1 className="font-weight-bold" style={{marginTop: '170px'}}>
                    {t("Welcome to Verifyle!")}
                  </h1>
                </div>
              </div>
              <div className="pb-5">
                <img src={slide1Img} />
              </div>
              <div className="pb-5">
                <img src={slide2Img} />
              </div>
              <div className="pb-5">
                <img src={slide3Img} />
              </div>
              <div className="pb-5">
                <img src={slide4Img} />
              </div>
              <div className="pb-5">
                <div className="text-center light-color">
                  <h3 className="font-weight-bold mt-5">{t("To learn more:")}</h3>
                  <div className="form-inline">
                    <div className="form-group ml-auto">
                      {t("Watch the videos on our")}
                    </div>
                    <div className="form-group mr-auto">
                      <a href={c.links.youtubeChannel} style={{textDecoration: 'underline'}}
                         target="_blank"
                         className="btn btn-link light-color pl-1">{t("YouTube channel")}</a>
                    </div>
                  </div>
                  <div className="form-inline">
                    <div className="form-group ml-auto">
                      {t("Read our")}
                    </div>
                    <div className="form-group mr-auto">
                      <a href={c.links.faq} style={{textDecoration: 'underline'}}
                         target="_blank"
                         className="btn btn-link light-color pl-1">{t("FAQ page")}</a>
                    </div>
                  </div>
                  <div className="form-inline">
                    <div className="form-group ml-auto">
                      {t("Contact us at")}
                    </div>
                    <div className="form-group mr-auto">
                      <a onClick={this.contactUsClick.bind(this)} style={{textDecoration: 'underline'}}
                         className="btn btn-link light-color pl-1">{t("support@verifyle.com")}</a>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
          <div className="d-flex black-bg light-color" style={{minHeight: '100px'}}>
            <div className="px-3">
              <button onClick={this.backBtnClick.bind(this)}
                      style={{height: '100px'}}
                      className={"btn btn-icon light-color btn-lg has-pointer " + (slideIndex === 0 ? 'invisible' : '')}>
                <i style={{fontSize: '38px'}} className="icon ion-chevron-left" />
              </button>
            </div>
            <div className="flex-grow-1">
              <p className="text-center pt-3">
                {this.getTextForSlideIndex()}
              </p>
            </div>
            <div className="px-3">
              <button onClick={this.nextBtnClick.bind(this)}
                      style={{height: '100px'}}
                      className={"btn btn-icon light-color btn-lg has-pointer " + (slideIndex === this.SLIDE_COUNT ? 'invisible' : '')}>
                <i style={{fontSize: '38px'}} className="icon ion-chevron-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TutorialWindow.MODAL_LARGE = true;

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

TutorialWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(TutorialWindow));
