import _ from "lodash";

const heicExtensions = [
  'heif',
  'heifs',
  'heic',
  'heics',
  'avci',
  'avcs',
  'avif',
  'hif'
]

const c = {
  api: {
    X_TOKEN: 'x-vf-token',
    X_VF_DEVICE: 'x-vf-device',
    X_FORUM_ID: 'x-forum-id',
    X_FILE_NAME: 'x-file-name',
    X_VF_OTP: 'X-VF-OTP',
    X_CHAT_ID : 'x-chat-id',
    X_DOC_ID: 'x-doc-id',
    X_HOST_UID: 'x-host-uid',
    X_MESG: 'x-mesg',
    X_SIGN_REQUEST_ID: 'x-sign-request-id',
    X_SIGN_FLAG: 'x-sign-flag',
    X_GUEST_UID : 'x-guest-uid',
    X_LAST_FORUM_ID : 'x-last-forum-id',
    X_CUSTOM_INPUT : 'x-custom-input',
    X_VF_DB : 'x-vf-db',

    CONTENT_TYPE: 'Content-Type',
    APPLICATION_OCTET_STREAM: 'application/octet-stream',
    APPLICATION_JSON: 'application/json',
    MULTIPART_FORM_DATA: 'multipart/form-data',

    API_RETRY_COUNT: 3,
    TIMEOUT_MS : 30000,

    //File uploading requires some different limits than regular api calls.
    //This is the amount of time to take when connecting to the server.
    FILE_UPLOAD_TIMEOUT : 30 * 60 * 1000,
    //30 min - this should be something giant. The upload will fail at the end of this time.
    FILE_UPLOAD_DEADLINE : 30 * 60 * 1000,
  },
  language : {
    en_us : 'en-US',
    fr_fr : 'fr-FR',
    es_es : 'es-ES'
  },
  google: {
    captcha_key : '6LdA-bMUAAAAAIJpEXXRX930LECuputHAAfJtRKC',
    //For automated testing, from here
    //https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha-what-should-i-do
    always_pass_recaptcha_key : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  },
  links: {
    mainNoRedirect : 'https://verifyle.com?noredirect=true',
    electronDownloadPage : 'https://verifyle.com/download-verifyle.html?noredirect=true',
    privacy: 'https://verifyle.com/privacypolicy.html',
    smsTerms: 'https://verifyle.com/smsterms.html',
    tos: 'https://verifyle.com/terms.html',
    faq: 'https://verifyle.com/faq.html',
    mailToSupport: 'mailto:support@verifyle.com',
    youtubeChannel: 'https://www.youtube.com/channel/UCOP_R1kBITnCyU4QoDqkpAA/videos',
    youtubeHandleTutorialLink: 'https://www.youtube-nocookie.com/embed/vwt8fp7eU_Y?rel=0',
    merchantSetupVideoLink: 'https://www.youtube-nocookie.com/embed/jPQU2IsOgLg?rel=0'
  },
  localstorage: {
    token: 'vf.auth.token',
    email: 'vf.user.email',
    vip: 'vf.auth.vip',
    sid: 'vf.auth.sid',
    device: 'vf.tfa.device',
    endpoint : 'vf.endpoint',
    lastSelectedHomeTab : 'vf.lastSelectedHomeTab',
    wsSort : 'vf.wsSort',
    contactSort : 'vf.contactSort',
    signArchiveSort : 'vf.signArchiveSort',
    invoiceHistorySort : 'vf.invoiceHistorySort',
    api_db_header : 'vf.api.db'
  },
  sessionstorage: {
    qs : 'vf.session.qs'
  },
  querystring: {
    inst_id: "inst",
    goto_confirm: "gotocfmstep",
    goto_reset: "gotoresetstep",
    goto_proreg: "goproregstep",
    goto_reg: "gotoregstep",
    goto_upgrade: 'gotoupgrade',
    goto_publisher_settings:'gotopublishersettings',
    goto_dnd: "gotodnd",
    goto_block_user: "gotoblockuser",
    goto_publisher_subscribe: "gotosubscribe",
    email: 'email',
    confirmation_code: 'code',
    forum_id: 'forum_id',
    doc_id: 'doc_id',
    thread_id: 'thread_id',
    chat_id: 'chat_id',
    guest_uid: 'guest_uid',
    uid:'uid',
    sign_request_id : 'sign_request_id',
    last_forum_id : 'last_forum_id',
    mesg_id : 'mesg_id',
    stripe_refresh : 'stripe_refresh',
    stripe_return : 'stripe_return',
    logout : 'logout'
  },
  authSteps: {
    email : 'auth.steps.email',
    password : 'auth.steps.password',
    tfa: 'auth.steps.tfa',
    tfaPwdReset: 'auth.steps.tfa-pwd-reset',
    forgotPassword : 'auth.steps.forgot-password',
    signup: 'auth.steps.signup'
  },
  modal: {
    authInfo : 'modal.auth-info',
    tfaInfo : 'modal.tfaInfo',
    newItem : 'modal.new-item',
    newWorkspace : 'modal.new-workspace',
    renameItem : 'modal.rename-item',
    search : 'modal.search',
    preview : 'modal.preview',
    addDoc : 'modal.add-doc',
    doNotShowAgain : 'modal.doNotShowAgain',
    mergePdfs : 'modal.merge-pdfs',
    invoiceHistoryItemDialog : 'modal.invoiceHistoryItemDialog',
    finalizeAttachmentWindow : 'modal.finalize-attachment-window',
    notifications: 'modal.notifications',
    downloadMultiple: 'modal.downloadMultiple',
    requestSignature: 'modal.requestSignature',
    pdfSignatureRequest: 'modal.pdfSignatureRequest',
    addGuestWindow: 'modal.addGuestWindow',
    addContactWindow: 'modal.addContactWindow',
    manageObjectPermissionsWindow: 'modal.manageObjectPermissionsWindow',
    generatedPasswordDialog : 'modal.generatedPasswordDialog',
    contactInfoWindow : 'modal.contactInfoWindow',
    docViewHistory: 'modal.docViewHistory',
    mesgEditWindow : 'modal.mesgEditWindow',
    mesgHistoryWindow : 'modal.mesgHistoryWindow',
    stripeCheckoutDialog : 'modal.stripeCheckoutDialog',
    tutorialWindow : 'modal.tutorialWindow',
    showConfirmName : 'modal.showConfirmName',
    attachDocToThreadWindow : 'modal.attachDocToThreadWindow',
    signingMetadata : 'modal.signingMetadata',
    contactUsWindow : 'modal.contactUsWindow',
    resendConfirmationEmailDialog : 'modal.resendConfirmationEmailDialog',
    customInputReviewDialog : 'modal.customInputReviewDialog',
    helpResourceWindow : 'modal.helpResourceWindow',
    upgradeDialogNew : 'modal.upgradeDialogNew',
    addPromoCodeDialog : 'modal.addPromoCodeDialog',
    smsVerificationDialog : 'modal.smsVerificationDialog',
    requestSignatureCodeDialog : 'modal.requestSignatureCodeDialog',
    requestSignatureCodePhoneCallDialog : 'modal.requestSignatureCodePhoneCallDialog',
    finishSignatureRequestDialog : 'modal.finishSignatureRequestDialog',
    pdfFixerToolDialog : 'modal.pdfFixerToolDialog',
    electronAppDownloadDialog : 'modal.electronAppDownloadDialog',
    sendInvoiceWindow : 'modal.sendInvoiceWindow',
    refundInvoiceDialog : 'modal.refundInvoiceDialog',
    merchantSetupWindow : 'modal.merchantSetupWindow',
    viewInvoiceLinkModal : 'modal.viewInvoiceLinkModal'
  },
  dialog: {
    confirm : 'modal.confirm',
    contactConfirm : 'modal.contactConfirm',
    alert : 'modal.alert',
    alertCustomContent : 'modal.alertCustomContent',
    pdf_submit : 'modal.pdf_submit',
    agree_to_terms : 'dialog.agree_to_terms',
    add_signatures : 'dialog.add_signatures',
    submit_signed_doc : 'dialog.submit_signed_doc'
  },
  actions: {
    app: {
      setQueryString: 'app.actions.app.setQueryString',
      setQsEmail: 'app.actions.app.setQsEmail',
      setQsActionNeeded : 'app.actions.app.setQsActionNeeded',
      setBrowserTabActive : 'app.actions.app.setBrowserTabActive',
      setBrowserTabMerchantStatus : 'app.actions.app.setBrowserTabMerchantStatus',
      setApplicationError : 'app.actions.app.setApplicationError',
      setServerConfig : 'app.actions.app.setServerConfig',
      logout: 'app.actions.app.logout'
    },
    popover: {
      showPopover : 'app.actions.popover.showPopover',
      hidePopover : 'app.actions.popover.hidePopover',
      cleanup : 'app.actions.popover.cleanup'
    },
    auth: {
      nothing : 'aupp.actions.auth.nothing',
      setStep: 'app.actions.auth.setStep',
      setEmailValidationResults: 'app.actions.auth.setEmailValidationResults',
      setShowingInvalidEmailWarning: 'app.actions.auth.setShowingInvalidEmailWarning',
      setAllowEmailEditFromBoarding: 'app.actions.auth.setAllowEmailEditFromBoarding',
      setEmailValidationPerformed: 'app.actions.auth.setEmailValidationPerformed',
      setInstitution: 'app.actions.auth.setInstitution',
      setUpgradePlan: 'app.actions.auth.setUpgradePlan',
      setEmail: 'app.actions.auth.setEmail',
      setMemberNumber: 'app.actions.auth.setMemberNumber',
      setConfirmationCode: 'app.actions.auth.setConfirmationCode',
      setTFAAuth: 'app.actions.auth.setTFAAuth',
      setAuth: 'app.actions.auth.setAuth',
      logout: 'app.actions.auth.logout',
      setEmailFieldVisible: 'app.actions.auth.setEmailFieldVisible',
      preventEmailDisabled: 'app.actions.auth.preventEmailDisabled',
      loginWithPayment: 'app.actions.auth.loginWithPayment',
    },
    upload: {
      setActiveUpload: 'app.actions.upload.setActiveUpload',
      addToUploadQueue: 'app.actions.upload.addToUploadQueue',
      updateQueue : 'app.actions.upload.updateQueue',
      updateProgress : 'app.actions.upload.updateProgress',
      cleanup : 'app.actions.upload.cleanup'
    },
    download: {
      doDownload: 'app.actions.download.doDownload',
      updatePendingQueue : 'app.actions.download.updatePendingQueue',
      addToCurrentQueue : 'app.actions.download.addToCurrentQueue',
      cleanup : 'app.actions.download.cleanup'
    },
    home: {
      updateWorkspaceLists : 'app.actions.home.updateWorkspaceLists',
      cleanup : 'app.actions.home.cleanup'
    },
    shared: {
      setLoaded: 'app.actions.shared.setLoaded',
      setStartupFirstCallsLoaded: 'app.actions.shared.setStartupFirstCallsLoaded',
      updateDirectMessages: 'app.actions.shared.updateDirectMessages',
      updateWorkspaces: 'app.actions.shared.updateWorkspaces',
      updateAccountInfo: 'app.actions.shared.updateAccountInfo',
      updateAccountClassInfo: 'app.actions.shared.updateAccountClassInfo',
      updateNotificationCount: 'app.actions.shared.updateNotificationCount',
      cleanup: 'app.actions.shared.cleanup',
      queueThreadTransaction : 'app.actions.shared.queueThreadTransaction',
      updateTransaction : 'app.actions.shared.updateTransaction',
      clearTransaction : 'app.actions.shared.clearTransaction',
      updateAccountSignatures: 'app.actions.shared.updateAccountSignatures',
      updateContacts : 'app.actions.shared.updateContacts',
      updateStripeAvailables: 'app.actions.shared.updateStripeAvailables',
      updateStripeData: 'app.actions.shared.updateStripeData',
      updateLogo: 'app.actions.shared.updateLogo',
      updateMerchantBillList: 'app.actions.shared.updateMerchantBillList',
      updateTermsTemplates: 'app.actions.shared.updateTermsTemplates',
      updateMerchantInfo: 'app.actions.shared.updateMerchantInfo',
      updatePublisherList: 'app.actions.shared.updatePublisherList',
    },
    account: {
      updatePasswordInfo: 'app.actions.account.updatePasswordInfo',
      updateUserSubscriptionList: 'app.actions.account.updateUserSubscriptionList',
      updateDownloadAlertsAreBlocked: 'app.actions.account.updateDownloadAlertsAreBlocked',
      updateUserBlockList: 'app.actions.account.updateUserBlockList',
      updateUserHandle: 'app.actions.account.updateUserHandle',
      updateTFAStatus: 'app.actions.account.updateTFAStatus',
      cleanup: 'app.actions.account.cleanup'
    },
    thread : {
      updateThreadMessages: 'app.actions.thread.updateThreadMessages',
      updateActiveDMMessages: 'app.actions.thread.updateActiveDMMessages',
      setActiveThread: 'app.actions.thread.setActiveThread',
      setActiveDM: 'app.actions.thread.setActiveDM',
      cleanup: 'app.actions.thread.cleanup'
    },
    workspace: {
      setLoadingForumId: 'app.actions.workspace.setLoadingForumId',
      updateWorkspace: 'app.actions.workspace.updateWorkspace',
      updateThreads: 'app.actions.workspace.updateThreads',
      updateThreadParticipantLookup : 'app.actions.workspace.updateThreadParticipantLookup',
      updateDocs: 'app.actions.workspace.updateDocs',
      updateGuests: 'app.actions.workspace.updateGuests',
      updateLogo: 'app.actions.workspace.updatelogo',
      setActiveThreadGuests: 'app.actions.workspace.setActiveThreadGuests',
      setActiveDoc: 'app.actions.workspace.setActiveDoc',
      setPublisherInfo: 'app.actions.workspace.setPublisherInfo',
      resetForNewWorkspace: 'app.actions.workspace.resetForNewWorkspace',
      cleanup: 'app.actions.workspace.cleanup'
    },
    pdf:{
      initialize:'app.actions.pdf.initialize',
      setRawPdf:'app.actions.pdf.setRawPdf',
      setPdfJsModel: 'app.actions.pdf.setPdfJsModel',
      setPdfPageCanvasLookup : 'app.actions.pdf.setPdfPageCanvasLookup',
      setPdfJsPages: 'app.actions.pdf.setPdfJsPages',
      setPdfJsLoadErr : 'app.actions.pdf.setPdfJsLoadErr',
      loadSignatureRequestData : 'app.actions.pdf.loadSignatureRequestData',
      updateSignatureRequestOverlays : 'app.actions.pdf.updateSignatureRequestOverlays',
      updateSignatureRequest : 'app.actions.pdf.updateSignatureRequest',
      setSigningTemplates : 'app.actions.pdf.setSigningTemplates',
      loadSigningTemplate : 'app.actions.pdf.loadSigningTemplate',
      clearSigningTemplate : 'app.actions.pdf.clearSigningTemplate',
      setSignatureRequestEditingGuestIndex : 'app.actions.pdf.setSignatureRequestEditingGuestIndex',
      setWindowSigningMode : 'app.actions.pdf.setWindowSigningMode',
      setPdfWriterLoadErr : 'app.actions.pdf.setPdfWriterLoadErr',

      updateV1SignatureOverlays : 'app.actions.pdf.updateV1SignatureOverlays',
      updateV1ActiveOverlays : 'app.actions.pdf.updateV1ActiveOverlays',
      updateV1InactiveOverlays : 'app.actions.pdf.updateV1InactiveOverlays',
      updateV1HasCommittedSignatures : 'app.actions.pdf.updateV1HasCommittedSignatures',
      updateV1HasValidSigning : 'app.actions.pdf.updateV1HasValidSigning',
      updateV1PdfChangesMade : 'app.actions.pdf.updateV1PdfChangesMade',
      resetV1SigningState : 'app.actions.pdf.resetV1SigningState',

      cleanup: 'app.actions.pdf.cleanup'
    },
    utility:{
      updateAppWindowDimensions : 'app.actions.utility.updateAppWindowDimensions',
      setWatermarkCanvasRef : 'app.actions.utility.setWatermarkCanvasRef',
      generateWatermarkImg: 'app.actions.utility.generateWatermarkImg',
      cleanup: 'app.actions.utility.cleanup'
    },
    modal: {
      show: 'app.actions.modal.show',
      close: 'app.actions.modal.close',
      cleanup : 'modal.cleanup',
      setRootRef : 'modal.setRootRef',
      updateSize: 'app.actions.modal.updateSize'
    }
  },
  popovers:{
    DOC_THUMBNAIL_PREVIEW : 'doc-thumbnail-popover',
    DOC_GEAR_MENU : 'doc-gearmenu-popover',
    MSG_MENU : 'msg-popover',
    MSG_DOC_THUMBNAIL_PREVIEW : 'msg-thumbnail-popover',
    MSG_DOC_GEAR_MENU : 'msg-doc-gearmenu-popover',
    DM_PANEL_HEADER_MENU : 'dm-panel-header-menu',
    THREAD_PANEL_HEADER_MENU : 'thread-panel-header-menu',
    SORT_POPOVER_MENU : 'sort-popover-menu',
    ACCOUNT_MENU : 'account-menu',
    GUEST_ROW_MENU : 'guest-row-menu',
    SIGN_ARCHIVE_MENU : 'sign-archive-menu',
    THREAD_INVOICE_MENU : 'thread-invoice-menu',
    CURRENCY_MENU : 'currency-menu',
    THREAD_MENU : 'thread-menu',
    WORKSPACE_MENU : 'workspace-menu',
    SIGN_TEMPLATE_MENU : 'sign-template-menu',
    RESEND_CODE_POPOVER : 'resend-code-popover',
    SHARE_HANDLE_POPOVER : 'share-handle-popover',
    COPIED_MENU : 'copied-menu',
    DID_YOU_MEAN_MENU : 'did-you-mean-menu'
  },
  electron: {
    WINDOWS_DOWNLOAD_FILENAME : "Verifyle-1.0.0.exe",
    WINDOWS_DOWNLOAD_FILEPATH : "https://verifyle-desktop.s3.us-west-1.amazonaws.com/Verifyle-1.0.0.exe",
    WINDOWS_DOWNLOAD_FILESIZE : "122MB",

    MAC_DOWNLOAD_FILENAME : "Verifyle-1.0.0.pkg",
    MAC_DOWNLOAD_FILEPATH : "https://verifyle-desktop.s3.us-west-1.amazonaws.com/Verifyle-1.0.0.pkg",
    MAC_DOWNLOAD_FILESIZE : "145MB",
  },
  pdf:{
    SIGNATURE_REQUEST_BG_OPACITY : .1,

    //bug 2475 - Make sure this folder gets versioned, or if a new web version gets released user's could be
    //pointing at a cached version that won't be compatible with the pdf-js version and things
    //won't work until users refresh.
    pdfjs_workerPath : '/pdf/v2.8.335/pdf.worker.js'
  },
  polling: {
    threadPollTimeMs : 30 * 1000
  },
  smartbanner: {
    mobile_viewport_banner: '!!(typeof VerifyleBanner != "undefined") && new VerifyleBanner({iosAppId : \'1182065115\', androidAppId: \'com.verifyle.android\', dismissTimerDays: 15, installTimerDays: 90, hasMobileViewport: true});',
    non_mobile_viewport_banner: '!!(typeof VerifyleBanner != "undefined") && new VerifyleBanner({iosAppId : \'1182065115\', androidAppId: \'com.verifyle.android\', dismissTimerDays: 15, installTimerDays: 90, hasMobileViewport: false});'
  },
  limits: {

    maxHandleChars : 72,
    maxHandleTextChars : 256,

    pdfSubmitFilenameMaxLength : 72,

    //Before adding things in here, consider pulling this from the server config in limit-helper
    maxBulkDownloadDocs : 100,
    maxBulkDownloadSizeBytes : 1073741824,
    maxAttachDocs : 100,
    maxAttachDocSizeBytes : 1073741824,

    maxFileSizeSigningBytes : 5242880,
    maxTermsChars : 1000,
    maxThreadMessage : 8192,
    warnThreadMessage : 7500,
    maxInvoiceDescription : 255,
  },
  account: {
    ACC_EXPIRY_WARN_PERIOD: 1209600,
  },
  DRAG_DROP_TYPES : {
    THREAD_DOC : 'thread_doc',
    PDF_THUMBNAIL : 'pdf_thumbnail'
  },

  //FYI, the order these are listed is also the order in which
  //currencies show up in the picker.
  INVOICE_CURRENCY_CODES: {
    USD : 'USD',
    CAD : 'CAD',
    EUR : 'EUR',
    AUD : 'AUD',
    JPY : 'JPY',
    BRL : 'BRL',
    CZK : 'CZK',
    DKK : 'DKK',
    GIP : 'GIP',
    HKD : 'HKD',
    HUF : 'HUF',
    CHF : 'CHF',
    MYR : 'MYR',
    MXN : 'MXN',
    NZD : 'NZD',
    NOK : 'NOK',
    PLN : 'PLN',
    RON : 'RON',
    SGD : 'SGD',
    SEK : 'SEK',
    THB : 'THB',
    AED : 'AED',
    GBP : 'GBP',
  },
  FORUM_TYPES: {
    //I'm not sure of all the types here, but right now the only one we care about it FORUM_CONTENT.
    FORUM_CONTENT : 4
  },
  payment: {
    PAY_APPLE: 'PAY_APPLE',
    PAY_GOOGLE: 'PAY_GOOGLE',
  },
  browsers: {
    chrome: 'chrome',
    safari: 'safari',
    ie: 'ie',
    edge: 'edge',
    firefox: 'firefox'
  },
  threads : {
    fileExtensionConflicts : [
      'py',
      'sh',
      'ai',
      'ps',
      'pl',
    ],

    fullPathPrefixes : [
      'http',
      'www',
      'ftp'
    ]
  },
  preview: {
    heicImgGenQuality: .3, // between 0 and 1
    pdfExtensions: [
      'pdf',
      'csv',
      'docx',
      'pptx',
      'doc',
      'ppt',
      'txt',
      'log'
    ],
    svgExtensions: ['svg'],
    textExtensions: [],
    heicExtensions,
    imgExtensions: _.concat([
      'jpg',
      'jpeg',
      'gif',
      'png'
    ], heicExtensions)
  },
  HANDLE_SECURE_PAGE_IMG_URL_EN : "https://verifyle.com/images/verifyle_upload_en.svg",
  HANDLE_SECURE_PAGE_IMG_URL_FR : "https://verifyle.com/images/verifyle_upload_fr.svg",
  HANDLE_SECURE_PAGE_IMG_URL_ES : "https://verifyle.com/images/verifyle_upload_es.svg",
  BLANK_LOGO : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
  BLANK_INST_ID : "0000000000000000",
  ACCOUNT_LOGO_TYPES : {
    PRO : 'pro',
    GOLD : 'gold',
    PLATINUM : 'platinum'
  },
  isFreeTier:(class_id) => {
    if(!class_id){
      return false;
    }

    switch(+class_id) {
      case 1:
      case 243000: //cstc plans have their own free tier.
        return true;
      default:
        return false;
    }
  }
}

export default c;
