import React, {Component} from 'react';
import PulseButton from "./PulseButton";

import PropTypes from 'prop-types';
import FancyInput from "./FancyInput";
import log from "../../../util/log";
import c from "../../../util/const";
import _ from "lodash";

class SafariAuthCodeInput extends Component {

  constructor(props){
    super(props);

    this.state = {
      showShake: false,
      timer: null,
      code: ''
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }

    if(this.timer){
      clearTimeout(this.timer);
    }
  }

  triggerValidationErr(){
    var me = this;

    me.setState({
      showShake: true,
    }, () => {
      this.timer = setTimeout(() => {
        me.setState({
          showShake: false
        })
      }, 400) //same as the animation time
    })
  }

  focus(){
    this.codeFieldRef.focus();
  }

  getCodeValue(){
    return this.state.code;
  }

  onEnter(evt){
    let { onEnter } = this.props;

    if(onEnter){
      onEnter();
    }
  }

  onFocus(evt) {
    this.codeFieldRef.selectAllText();
  }

  onFieldChange(val){
    let result = '';
    for(let i = 0;i<val.length;i++){
      let c = +val[i];
      if(_.isNumber(c) && c >= 0 && c <= 9){
        result += c;
      }
    }

    this.setState({code : result}, () => {
      let { onChange } = this.props;
      onChange(this.getCodeValue());
    })
  }

  render() {
    let { inputDisabled, inputCls, label, useNonFloatingLabel } = this.props;

    let {
      showShake,
      code
    } = this.state;

    let wrapStyle = {
      position: 'relative',
      overflow: 'initial',
      width: '100%'
    }

    if(showShake){
      wrapStyle.animation = 'shake 400ms ease-in-out';
    }

    let applyInputCls = "safari-auth-input";
    if(inputCls){
      applyInputCls += " " + inputCls;
    }

    return (
      <div style={wrapStyle} className={'form-group'}>
        {useNonFloatingLabel && label && label.length > 0 && <label style={{}}>{label}</label> }
        <FancyInput onRef={ref => (this.codeFieldRef = ref)}
                    inputCls={applyInputCls}
                    placeholder={label}
                    inputDisabled={inputDisabled}
                    inputPattern="[0-9]*"
                    autoComplete={'one-time-code'}
                    fieldType={'text'}
                    inputNumeric={true}
                    maxInputLength={6}
                    inputValue={code}
                    onFocus={this.onFocus.bind(this)}
                    onEnter={this.onEnter.bind(this)}
                    onChange={this.onFieldChange.bind(this)}/>
      </div>
    )
  }
}

SafariAuthCodeInput.propTypes = {
  onRef : PropTypes.func,
  onEnter: PropTypes.func,
  useNonFloatingLabel:PropTypes.bool,
  onChange:PropTypes.func,
  label: PropTypes.string,
  inputCls:PropTypes.string,
  inputDisabled : PropTypes.bool.isRequired,
}

export default SafariAuthCodeInput;
