import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';
import log from '../util/log';
import _ from 'lodash';
import {fontConstants} from "../util/font-constants";
import colors from "../util/colors";
import PdfPage from "../components/partials/pdf-preview/PdfPage";
import utils from "../util/util";
import VFPopover from "../components/partials/components/VFPopover";

const popoverActions = {

  isShowing(type, key){
    return (dispatch, getState) => {
      // log.log('isShowing called', type, key, _.get(getState(), 'popover.showingPopoverKey'));
      return _.get(getState(), 'popover.showingPopoverKey') === VFPopover.getPopoverKey(type, key);
    }
  },

  showPopover(type, key){
    return (dispatch, getState) => {
      let newPopover = VFPopover.getPopoverKey(type, key);
      let currentPopover = _.get(getState(), 'popover.showingPopoverKey');

      //log.log('showPopover called', newPopover, currentPopover);

      //If we call showPopover for the same popover that's already being shown, we should close it.
      //This achieves the effect of clicking on something once to open it, and clicking again closes the thing that you just opened.
      if(currentPopover === newPopover){
        setTimeout(() => {
          dispatch(this.hidePopover());
        })
        return false;
      }
      else{
        setTimeout(() => {
          dispatch({
            type: c.actions.popover.showPopover,
            popoverKey: VFPopover.getPopoverKey(type, key)
          })
        })
        return true;
      }
    }
  },

  hidePopover(){
    return (dispatch, getState) => {
      //log.log('hidePopover called', _.get(getState(), 'popover.showingPopoverKey'));
      dispatch({
        type: c.actions.popover.hidePopover
      })
    }
  },
  
  cleanup (){
    return {
      type : c.actions.popover.cleanup
    }
  },

  mapToDispatch(dispatch) {
    return {
      showPopover: (type, key) => dispatch(this.showPopover(type, key)),
      isShowing: (type, key) => dispatch(this.isShowing(type, key)),
      hidePopover: () => dispatch(this.hidePopover()),
      cleanup: () => dispatch(this.cleanup()),
    }
  }
}

export default popoverActions;
