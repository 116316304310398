import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Draggable from "react-draggable";
import log from '../../../util/log';
import colors from "../../../util/colors";
import config from "../../../util/config";
import Button from "../elements/Button";
import classnames from 'classnames';
import _ from 'lodash'
import SignatureRequest from "../../../models/SignatureRequest";
import moment from 'moment';
import filters from "../../../helpers/filters";
import utils from "../../../util/util";
import PdfSigningOverlay from "./PdfSigningOverlay";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import PdfSignatureRequestCustomScalingInput from "./PdfSignatureRequestCustomScalingInput";
import PdfSignatureRequestSignedDateInput from "./PdfSignatureRequestSignedDateInput";
import c from "../../../util/const";
import PdfSignatureRequestOverlay from "./PdfSignatureRequestOverlay";

class PdfSignatureFulfillOverlay extends Component {

  static INITIAL_CUSTOM_INPUT_FONT_SIZE = 16;

  INITIAL_STATE = {
    customText : '',
    customTextFont : null,
    customTextFontCls : null,
    confirmEnabled : false,
    customDate : null,
  }

  signedDateRef = null;
  customInputRef = null;

  constructor(props){
    super(props);

    this.signatureRef = React.createRef();

    this.state = _.extend({}, this.INITIAL_STATE);
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, this)
    }

    if(!this.state.customDate){
      this.setState({customDate : moment().unix()})
    }
  }

  componentWillUnmount() {
    this.setState({customDate : null})
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, null)
    }
  }

  getOverlayPositionOffsetsForCustomType(){
    let node = _.get(this.customInputRef.getInputRef(), 'current');
    let nodeStyle = window.getComputedStyle(node)

    let paddingLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-left'))
    let paddingTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-top'))
    let borderLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-left'))
    let borderTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-top'))
    let inputFontSize = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('font-size'))
    let inputLineHeight = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('line-height'))
    let inputLineHeightOffset = (inputLineHeight - inputFontSize) / 2;
    let verticalOffset = PdfSigningOverlay.getVerticalFontOffset(PdfSigningOverlay.FONTS.HELVETICA, inputFontSize + 'px');

    return {
      left : paddingLeft + borderLeft,
      top : paddingTop + borderTop + inputLineHeightOffset + verticalOffset,
      inputFontSize,
    }
  }

  getOverlayPositionOffsetsForSignedDate(){
    let node = _.get(this.signedDateRef.getInputRef(), 'current')
    let nodeStyle = window.getComputedStyle(node)

    let borderLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-left'))
    let borderTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-top'))

    let inputFontSize = this.signedDateRef.getSignedDateFontSize();
    let inputLineHeight = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('line-height'))
    let inputLineHeightOffset = (inputLineHeight - inputFontSize) / 2;
    let verticalOffset = PdfSigningOverlay.getVerticalFontOffset(PdfSigningOverlay.FONTS.COURIER, inputFontSize + 'px');
    // log.log('getOverlayPositionOffsetsForSignedDate', borderLeft, inputFontSize, inputLineHeightOffset, verticalOffset);
    let hackHeight = 0;
    return {
      left : borderLeft,
      top : borderTop + hackHeight + inputLineHeightOffset + verticalOffset,
      inputFontSize,
    }
  }

  getOverlayDataResult(){
    let { overlay } = this.props;
    if(SignatureRequest.isSignedDate(overlay.signatureType)){
      let offsets = this.getOverlayPositionOffsetsForSignedDate();
      return {
        x : overlay.coords.x + offsets.left,
        y : overlay.coords.y + offsets.top,
        text : SignatureRequest.isSignedDateUTC(overlay.signatureType) ?
          filters.momentUTCFilter(moment().unix(), SignatureRequest.getDateMaskForSignatureType(overlay.signatureType)) :
          filters.momentFilter(moment().unix(), SignatureRequest.getDateMaskForSignatureType(overlay.signatureType)),
        font : PdfSigningOverlay.FONTS.COURIER,
        fontSize : `${offsets.inputFontSize}px`,
      }
    }
    else if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      let offsets = this.getOverlayPositionOffsetsForCustomType()
      return {
        x : overlay.coords.x + offsets.left,
        y : overlay.coords.y + offsets.top,
        text : this.state.customText,
        font : this.state.customTextFont ? this.state.customTextFont : PdfSigningOverlay.FONTS.HELVETICA,
        fontSize : `${offsets.inputFontSize}px`
      }
    }
    else{
      throw new Error('unsupported signature type ' + overlay.signatureType);
    }
  }

  setCustomText(text){
    this.setState({customText : text});
  }

  setCustomTextFont(customFontFamily, customTextFontCls){
    this.setState({
      customTextFont : customFontFamily,
      customTextFontCls
    });
  }

  generateOverlayImage(){
    let { accountSignatures, overlay } = this.props;

    return new Promise((resolve, reject) => {
      let scaledOverlayWidth = overlay.width;
      let scaledOverlayHeight = overlay.height;
      if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE){
        utils.calculateResizedImage(accountSignatures.sign_image, scaledOverlayWidth, scaledOverlayHeight)
          .then((newDimensions) => {
            resolve({
              width : newDimensions.width,
              height : newDimensions.height,
              data : accountSignatures.sign_image
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
      else if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS){
        utils.calculateResizedImage(accountSignatures.init_image, scaledOverlayWidth, scaledOverlayHeight)
          .then((newDimensions) => {
            resolve({
              width : newDimensions.width,
              height : newDimensions.height,
              data : accountSignatures.init_image
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        throw new Error('unsupported signature type ' + overlay.signatureType);
      }
    })
  }

  static calculateOverlayRelativeScale(val, overlayScale, viewScale){
    return val * (viewScale / overlayScale);
  }

  doSelectSignature(){
    setTimeout(() => {
      if(this.props.overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER && this.props.overlay.selected){
        let inputRef = _.get(this.customInputRef.getInputRef(), 'current')
        if(inputRef){
          inputRef.focus();
        }
      }
    })
  }

  scaleRelativeToOverlay(val){
    let { overlay, viewScale } = this.props;
    return PdfSignatureFulfillOverlay.calculateOverlayRelativeScale(val, overlay.scale, viewScale);
  }

  onCustomTextChange(val){
    this.setState({customText : val});
  }

  onConfirmClick(){
    this.props.onConfirmClick()
  }

  onMarkerClick(){
    this.props.onMarkerSelect();
  }

  getConfirmButtonDisabled(){
    let { overlay } = this.props;
    let { customText } = this.state;

    if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      return customText.length === 0;
    }
    return false;
  }

  render(){
    let {
      overlay,
      accountSignatures,
      signaturePrimaryColor,
      allowInteraction,
      t
    } = this.props;
    let { customText, customTextFontCls, customDate } = this.state;

    let scaledOverlayWidth = this.scaleRelativeToOverlay(overlay.width);
    let scaledOverlayHeight = this.scaleRelativeToOverlay(overlay.height);

    let boxHeight = scaledOverlayHeight;
    if(SignatureRequest.isSignedDate(overlay.signatureType) && !overlay.confirmed){
      boxHeight += this.scaleRelativeToOverlay(DATE_FIELD_WARNING_SIZE);
    }

    if(!accountSignatures){
      return null;
    }

    let baseColor = !overlay.confirmed ? (signaturePrimaryColor || colors.SIGNING_REQUEST) : null;
    let bgColor = !overlay.confirmed ? utils.transparentizeHex(baseColor, c.pdf.SIGNATURE_REQUEST_BG_OPACITY) : null;

    let showConfirmButton = !overlay.confirmed && overlay.selected;

    let overlayZIndex = 999999;
    if(allowInteraction){
      overlayZIndex += 1;
    }
    if(showConfirmButton){
      overlayZIndex += 1;
    }
    return (
      <Draggable
        handle=".handle"
        disabled={true}
        bounds={'parent'}
        position={{
          x : this.scaleRelativeToOverlay(overlay.coords.x),
          y : this.scaleRelativeToOverlay(overlay.coords.y)
        }}>
        <div style={{
          position : 'absolute',
          zIndex : overlayZIndex,
          pointerEvents: 'all',
        }}>
          <div className="d-flex" style={{height: `${boxHeight}px`}}>
            <div>
              <button onClick={_.noop}
                      disabled={!allowInteraction}
                      className={`btn btn-icon handle default-cursor`}
                      style={{
                        opacity: 1,
                        paddingTop: '0px',
                        paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                        paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                        fontSize: `${this.scaleRelativeToOverlay(32)}px`,
                        color: signaturePrimaryColor,
                        boxShadow: 'none',
                        display: 'block',
                        height: this.scaleRelativeToOverlay(30) + 'px',
                        minWidth: `${this.scaleRelativeToOverlay(PdfSignatureRequestOverlay.SIDE_BUTTON_WIDTH)}px`,
                        maxHeight: scaledOverlayHeight + 'px',
                      }}>
                <i className={`icon icon-top ion-bookmark default-cursor signature-request-color`} />
              </button>
            </div>

            {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER && (overlay.selected || overlay.confirmed) &&
            <PdfSignatureRequestCustomScalingInput
              hasRemoveButton={false}
              overlayIsConfirmed={overlay.confirmed}
              signaturePrimaryColor={signaturePrimaryColor}
              inputClassName={`form-control flex-grow-1 signature-request-input animate-font-size ${overlay.confirmed ? 'confirmed' : ''} ${customTextFontCls ? customTextFontCls : 'helvetica'}`}
              inputText={customText}
              placeholderText={overlay.signatureCustomLabel}
              scaleRelativeToOverlay={(val) => this.scaleRelativeToOverlay(val)}
              onInputChange={this.onCustomTextChange.bind(this)}
              inputPaddingStyle="0px 5px 0px 5px"
              width={scaledOverlayWidth}
              height={scaledOverlayHeight}
              lineHeight={scaledOverlayHeight}
              onRef={(ref) => this.customInputRef = ref}
              initialFontSize={PdfSignatureFulfillOverlay.INITIAL_CUSTOM_INPUT_FONT_SIZE}
              inputDisabled={overlay.confirmed}/>
            }

            {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE && (overlay.selected || overlay.confirmed) &&
            <div className={`signature-request-input ${overlay.confirmed ? 'confirmed' : ''}`}
                 ref={this.signatureRef}
                 style={{
                   backgroundColor: bgColor,
                   borderColor: baseColor,
                   textAlign: 'left',
                   width: `${scaledOverlayWidth}px`,
                   height: `${scaledOverlayHeight}px`
                 }}>
              <img src={accountSignatures.sign_image}
                   style={{
                     verticalAlign : 'super',
                     maxWidth: `${scaledOverlayWidth}px`,
                     maxHeight: `${scaledOverlayHeight}px`
                   }}/>
            </div>
            }

            {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS && (overlay.selected || overlay.confirmed) &&
            <div className={`signature-request-input ${overlay.confirmed ? 'confirmed' : ''}`}
                 ref={this.signatureRef}
                 style={{
                   backgroundColor: bgColor,
                   borderColor: baseColor,
                   textAlign: 'left',
                   width: `${scaledOverlayWidth}px`,
                   height: `${scaledOverlayHeight}px`
                 }}>
              <img src={accountSignatures.init_image}
                   style={{
                     verticalAlign : 'super',
                     maxWidth: `${scaledOverlayWidth}px`,
                     maxHeight: `${scaledOverlayHeight}px`
                   }}/>
            </div>
            }

            {SignatureRequest.isSignedDate(overlay.signatureType) && (overlay.selected || overlay.confirmed) &&
            <PdfSignatureRequestSignedDateInput
              signaturePrimaryColor={signaturePrimaryColor}
              scaleRelativeToOverlay={(val) => this.scaleRelativeToOverlay(val)}
              initialFontSize={this.scaleRelativeToOverlay(SIGNED_DATE_FONT_SIZE)}
              overlay={overlay}
              scale={this.props.viewScale}
              calculatedDate={customDate}
              onRef={(ref) => this.signedDateRef = ref}
              containerHeight={boxHeight}
              overlayWidth={scaledOverlayWidth}
              dateFieldWarningSize={this.scaleRelativeToOverlay(DATE_FIELD_WARNING_SIZE)}/>
            }


            {showConfirmButton &&
              <div className="text-right pl-1">
                <button onClick={this.onConfirmClick.bind(this)}
                        style={{
                          transform: `scale(${this.scaleRelativeToOverlay(1)}, ${this.scaleRelativeToOverlay(1)})`,
                          transformOrigin: 'right top',
                        }}
                        disabled={this.getConfirmButtonDisabled()}
                        className="btn btn-sm btn-primary">
                  {t("Confirm")}
                </button>
              </div>
            }
          </div>
        </div>
      </Draggable>
    )
  }
}

const DATE_FIELD_WARNING_SIZE = 16;
const SIGNED_DATE_FONT_SIZE = 14;

const mapStateToProps = (state) => {
  return {
    accountSignatures : state.shared.signatures
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfSignatureFulfillOverlay.propTypes = {
  viewScale : PropTypes.number.isRequired,
  overlay : PropTypes.object.isRequired,
  onConfirmClick : PropTypes.func.isRequired,
  onMarkerSelect : PropTypes.func.isRequired,
  signaturePrimaryColor : PropTypes.string,
  allowInteraction : PropTypes.bool,
  onRef : PropTypes.func,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSignatureFulfillOverlay));
