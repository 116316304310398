import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../partials/elements/Button';
import sapi from "../../../util/sapi";
import modalActions from "../../../actions/modal-actions";
import log from "../../../util/log";
import UserBadge from "../../partials/badges/UserBadge";
import colors from "../../../util/colors";
import {getMessageForError} from "../../../util/errors";
import ValidationErrors from "../../partials/components/ValidationErrors";
import {last} from "pdf-lib";
import AddContactWindow from "../../modals/AddContactWindow";
import UploadHelper from "../components/UploadHelper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class CSVUpload extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uploading : false,
      uploadErr : '',
      hoveringUpload : false
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  handleFileError(err){
    let { t } = this.props;
    log.error('error uploading contacts', err);

    if(err.name === 'APP_REQ_INVALID'){
      this.setState({uploadErr : t("The file you uploaded appears to have an invalid format.  Please try again.")});
    }
    else if(err.name === 'APP_REQ_MISSING'){
      this.setState({uploadErr : t("We were able to open your file, but the column format doesn't appear correct.  Please check your file and try again.")});
    }
    else{
      this.setState({uploadErr : t("There was a problem uploading your contact list.  Please try again.")});
    }
  }

  onFileDrop(files, rejected) {
    log.log('onFileDrop', files, rejected);
    if (files.length > 0) {
      this.setState({uploadErr : ''})
      let csv = files[0];

      this.setState({uploading : true})
      sapi.Contacts.uploadCSV(csv.name, csv)
        .then((res) => {
          log.log('upload file res', res);
          this.props.setWindowMode(AddContactWindow.WINDOW_MODES.CSV_REVIEW, {
            contactData : res.data,
            filename : csv.name
          });
        })
        .catch((err) => {
          this.handleFileError(err);
        })
        .finally(() => {
          this.setState({uploading : false})
        })
    }
  }

  onMouseEnterLogo() {
    if (this.state.hoveringUpload) {
      return;
    }

    this.setState({hoveringUpload: true});
  }

  onMouseLeaveLogo() {
    this.setState({hoveringUpload: false});
  }

  render() {
    let { t } = this.props;
    let {uploadErr, hoveringUpload} = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Import Contacts")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col text-center mb-3">
              {t("Upload a CSV file with these column headers:")}
            </div>
          </div>
          <div className="row dark-color mb-3">
            <div className="col font-weight-bold text-center pl-3">
              {t("First Name")}
            </div>
            <div className="col text-center font-weight-bold" style={{borderRight : '1px solid' + colors.DARK, borderLeft : '1px solid' + colors.DARK}}>
              {t("Last Name")}
            </div>
            <div className="col text-center font-weight-bold pr-3">
              {t("Email Address")}
            </div>
          </div>
          <UploadHelper allowMultiple={false}
                        preventDragOverlay={true}
                        onDrop={this.onFileDrop.bind(this)}>
            {({isDragActive}) => {
              let panelColorStyle = null;
              if(isDragActive){
                panelColorStyle = {color : colors.PRIVATE_GREEN};
              }
              else if(hoveringUpload){
                panelColorStyle = {color : colors.PRIMARY};
              }
              else{
                panelColorStyle = {color : colors.DARK};
              }

              return (
                <div onMouseEnter={this.onMouseEnterLogo.bind(this)}
                     onMouseLeave={this.onMouseLeaveLogo.bind(this)}
                     className="drag-drop-panel"
                     style={{...styles.dragDropEmpty, ...panelColorStyle, ...(hoveringUpload ? styles.dragDropEmptyHover : null)}}>
                  <div className="text-center">
                    <i style={styles.dragDropIcon} className={`icon ion-upload`}/>
                  </div>
                  {isDragActive ? t('Ready!') : t('Drag and Drop or Click to Browse')}
                </div>
              )
            }}
          </UploadHelper>
          <div className="mt-3">
            {uploadErr &&
            <ValidationErrors errors={[uploadErr]}/>
            }
          </div>
        </div>
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  dragDropEmpty : {
    height: '160px',
    padding: '5px',
    border: 'dashed 3px black',
    borderColor: colors.DARK,
    borderRadius: '12px',
    textAlign: 'center'
  },
  dragDrop: {
    maxHeight: '160px',
    padding: '5px',
    border: 'dashed 3px black',
    borderColor: colors.DARK,
    borderRadius: '12px',
    textAlign: 'center',
  },
  dragDropEmptyHover: {
    cursor: 'pointer'
  },
  dragDropIcon: {
    fontSize: '100px',
    display: 'inline-flex',
  },
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

CSVUpload.propTypes = {
  setWindowMode : PropTypes.func.isRequired,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(CSVUpload));
