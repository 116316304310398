import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import { getErrorMessage} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";

class RenameDialog extends Component {

  constructor(props) {
    super(props);

    let {oldName} = props.modalProps;
    this.firstInputRef = React.createRef();
    this.state = {
      oldName: oldName,
      newName: oldName,
      validationErr: null
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let ref = null;
    utils.waitForCondition(() => {
        ref = _.get(this.firstInputRef, 'current');
        return !!(ref)
      })
      .then(() => {
        ref.focus();
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal() {
    this.props.close();
  }

  submit() {
    let me = this;
    let {close, renameFn, t} = this.props;

    this.setState({
      validationErr: null
    })

    if (this.doValidate()) {
      let {newName} = this.state;

      renameFn(newName)
        .then((res) => {
          log.log('rename res', res);

          close(true);
        })
        .catch((err) => {

          me.setState({
            validationErr: getErrorMessage(err.name, t)
          })
        })
    }
  }

  doValidate() {
    let { t } = this.props;
    let {newName} = this.state;

    if (!newName && newName.length === 0) {
      this.setState({
        validationErr: t('Please enter a new name')
      })
      return false;
    }

    return true;
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let {modalProps, t} = this.props;
    let {oldName, newName, validationErr} = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Current Name:")}</label>
                <p className="w-100 word-break-all">{oldName}</p>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("New Name")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={newName}
                       ref={this.firstInputRef}
                       onChange={(evt) => this.setState({newName: evt.target.value})}
                       placeholder={t('New Name')}/>
              </div>
            </div>
          </div>
          {validationErr && <ValidationErrors errors={[validationErr]} />}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} disabled={newName.length === 0} onClick={this.submit.bind(this)}>{t("Rename")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

RenameDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(RenameDialog));
