import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import c from '../../../util/const';
import filters from "../../../helpers/filters";
import colors from "../../../util/colors";
import UserBadge from "../badges/UserBadge";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {getMessageForError} from "../../../util/errors";
import _ from "lodash";
import utils from "../../../util/util";
import he from "he";
import classnames from "classnames";
import sharedActions from "../../../actions/shared-actions";
import ManageObjectPermissionsWindow from "../../modals/ManageObjectPermissionsWindow";
import log from "../../../util/log";
import { isMobile } from 'react-device-detect';
import ReactToPrint from "react-to-print";
import homeActions from "../../../actions/home-actions";
import { DropTarget } from 'react-dnd'
import UploadHelper from "../components/UploadHelper";
import DocAttach from "../../../models/DocAttach";
import {withVFTranslation} from "../../../util/withVFTranslation";
import TruncateMarkup from 'react-truncate-markup';
import VFPopover from "../components/VFPopover";
import popoverActions from "../../../actions/popover-actions";

class SignArchiveRow extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.itemClick = this.itemClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
    this.state = {
      hovering : false,
      hasSignedData : false,
      signInfo : null
    }
  }
  
  showMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    let { row } = this.props;
    let didOpen = this.props.popoverAction.showPopover(c.popovers.SIGN_ARCHIVE_MENU, row.sign_request_id);

    //If it didn't open, nothing to do.
    if(!didOpen){
      return;
    }

    this.props.fetchSignInfo(row.sign_request_id, row.host_uid)
      .then((res) => {
        this.setState({
          signInfo : res,
          hasSignedData : SignArchiveRow.hasSignedData(res.data)
        })
      })
      .catch((err) => {
        log.log('error fetching sign info', err);
      })
  }

  hidePopover(evt){

    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
  }

  itemClickMenu(evt){
    this.itemClick(evt);
  }
  
  itemClick(evt){
    let {onItemClick, row} = this.props;
    
    this.hidePopover(evt);
    
    onItemClick(row);
  }
  
  onMouseEnter(){
    if(this.state.hovering){
      return;
    }
    
    this.setState({
      hovering : true
    })
  }
  
  onMouseLeave(){
    this.setState({hovering : false})
  }
  
  onViewSigningInfoClick(evt){
    this.hidePopover(evt);
    let { row } = this.props;
    this.props.onViewSigningInfoClick(row)
  }
  
  onDeleteRecord(evt){
    this.hidePopover(evt);
    this.props.onDeleteRecord(this.props.row)
  }
  
  onDownloadDocumentClick(evt){
    this.hidePopover(evt);
    this.props.onDocumentDownload(this.props.row);
  }
  
  onPreviewDocumentClick(evt){
    this.hidePopover(evt);
    this.props.onPreviewDocumentClick(this.props.row);
  }

  static hasSignedData(signArchiveInfo){
    let showMetadataReportButton = false;
    if(signArchiveInfo) {
      _.each(signArchiveInfo, (info) => {
        if (info.signed_date) {
          showMetadataReportButton = true;
        }
      })
    }
    return showMetadataReportButton;
  }

  getPopoverContent(){
    let {
      row,
      t
    } = this.props;

    let {
      signInfo,
      hasSignedData,
    } = this.state;

    let showMetadataReportButton = !!(signInfo) && hasSignedData;

    //log.log('render archive row popup content', this.state);

    return (
      <div>
        <ul className="popover-content list-group">
  
          <a onClick={this.itemClickMenu.bind(this)}
             style={styles.menuHeader}
             className="list-group-item list-group-item-action has-pointer">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
            {row.label}
          </a>

          <a onClick={showMetadataReportButton ? this.onViewSigningInfoClick.bind(this) : _.noop}
             style={styles.menuItem}
             className={`list-group-item list-group-item-action has-pointer ${showMetadataReportButton ? '' : 'disabled'}`}>
            <i style={styles.menuIcons} className="icon ion-information-circled"/>
            {t("View Signing Info")}
          </a>
          
          <a onClick={this.onPreviewDocumentClick.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("Preview Document")}
          </a>
          <a onClick={this.onDownloadDocumentClick.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-download"/>
            {t("Download Document")}
          </a>
  
          <a onClick={this.onDeleteRecord.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Delete Record")}
          </a>
        </ul>
      </div>
    )
  }
  
  render() {
    let {
      row,
      t
    } = this.props;
    let {
      hovering
    } = this.state;
    
    return (
      <div onClick={this.itemClick}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           className={classnames('d-flex flex-row sign-archive-row p-2 ml-2')}>
        <div className="d-flex flex-row item-row-wrap guest-row-wrap">
          <div className="flex-grow-1">
            <p className="mb-1 guest-label">
              {row.label}
              <VFPopover
                isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.SIGN_ARCHIVE_MENU, row.sign_request_id)}
                positions={['right', 'top', 'bottom']}
                onClickOutside={this.hidePopover.bind(this)}
                getMenuContent={this.getPopoverContent.bind(this)}>
                <span style={{lineHeight: '18px'}}
                      className="pl-2 d-inline-block"
                      onClick={this.showMenu.bind(this)}>
                  <i style={styles.gearIcon}
                     className={classnames(`icon item-menu-icon ion-gear-b`, {'invisible': !isMobile && !hovering})}/>
                </span>
              </VFPopover>
            </p>
            <p className="guest-preview mb-1 secondary-text-color">
              <TruncateMarkup lines={2} ellipsis={t('...')}>
                <span>{utils.getMomentDate(row.updated_date).format('LLL')}</span>
              </TruncateMarkup>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  attachmentIcon : {
    marginRight: '5px',
    verticalAlign : 'baseline',
    fontSize : '16px'
  },
  
  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    borderBottom: 'none'
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

SignArchiveRow.propTypes = {
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDeleteRecord : PropTypes.func.isRequired,
  onDocumentDownload : PropTypes.func.isRequired,
  onViewSigningInfoClick : PropTypes.func.isRequired,
  onPreviewDocumentClick : PropTypes.func.isRequired,
  fetchSignInfo : PropTypes.func.isRequired,
}
const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...workspaceActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)((SignArchiveRow)));
