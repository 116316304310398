import React, {Component} from 'react';
import PulseButton from "./PulseButton";

import PropTypes from 'prop-types';
import FancyInput from "./FancyInput";
import log from "../../../util/log";
import c from "../../../util/const";
import _ from "lodash";

import browser from '../../../util/browser';

import classNames from 'classnames';
import SafariAuthCodeInput from "./SafariAuthCodeInput";

class AuthCodeInput extends Component {

  constructor(props){
    super(props);

    this.state = {
      showShake: false,
      timer: null,
      digitOne : '',
      digitTwo : '',
      digitThree : '',
      digitFour : '',
      digitFive : '',
      digitSix : '',
      ie11hack : browser.isIE11()
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }

    if(this.timer){
      clearTimeout(this.timer);
    }
  }

  triggerValidationErr(){
    if(this.safariAuthInputRef) {
      return this.safariAuthInputRef.triggerValidationErr();
    }

    var me = this;

    me.setState({
      showShake: true,
    }, () => {
      this.timer = setTimeout(() => {
        me.setState({
          showShake: false
        })
      }, 400) //same as the animation time
    })
  }

  focus(){
    if(this.safariAuthInputRef) {
      return this.safariAuthInputRef.focus();
    }

    this.digitOneField.focus();
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   let { value } = this.props;
  //
  //   log.log('update?', value);
  //   if(value && value.length > 0 && value !== prevProps.value){
  //     log.log('componentDidUpdate', value);
  //     this.setState({
  //       digitOne: value && value[0] || '',
  //       digitTwo: value && value[1] || '',
  //       digitThree: value && value[2] || '',
  //       digitFour: value && value[3] || '',
  //       digitFive: value && value[4] || '',
  //       digitSix: value && value[5] || ''
  //     })
  //   }
  // }

  assembleCode(){
    if(this.safariAuthInputRef) {
      return this.safariAuthInputRef.getCodeValue();
    }

    let {
      digitOne,
      digitTwo,
      digitThree,
      digitFour,
      digitFive,
      digitSix
    } = this.state;

    let updatedCode = `${digitOne}${digitTwo}${digitThree}${digitFour}${digitFive}${digitSix}`;
    return updatedCode;
  }

  onKeyPress(index, evt) {
    let keyValue = FancyInput.GetNumericKeyValue(evt);
    if(_.isNaN(keyValue) || keyValue < 0 || keyValue > 9){
      return;
    }
    else if(evt.key === 'Enter'){
      return;
    }

    index = +index;

    this.onDigitChange(index, ''+keyValue)
    setTimeout(() => {
      if (index < 5) {
        let ref = this.getRefForIndex(index + 1);
        ref.focus();
      }
    })
  }

  onPaste(index, e) {
    let pastedText = e.clipboardData.getData('Text');

    if(pastedText && pastedText.length === 6 && !_.isNaN(+pastedText)){
      this.setState({
        digitOne : pastedText[0],
        digitTwo : pastedText[1],
        digitThree : pastedText[2],
        digitFour : pastedText[3],
        digitFive : pastedText[4],
        digitSix : pastedText[5],
      }, () => this.afterDigitChange())

      //Move the focus to the last input, like you typed it.
      let ref = this.getRefForIndex(5);
      ref.focus();
    }
    else{
      e.preventDefault();
    }
  }

  onDigitChange(index, val){
    index = +index;
    let update = {};
    switch(index){
      case 0:
        update.digitOne = val;
        break;
      case 1:
        update.digitTwo = val;
        break;
      case 2:
        update.digitThree = val;
        break;
      case 3:
        update.digitFour = val;
        break;
      case 4:
        update.digitFive = val;
        break;
      case 5:
        update.digitSix = val;
        break;
    }
    this.setState(update, () => this.afterDigitChange())
  }

  onEnter(index, evt){
    let { onEnter } = this.props;

    if(onEnter){
      onEnter();
    }
  }

  afterDigitChange(){
    if(this.props.onChange){
      this.props.onChange(this.assembleCode());
    }
  }

  onDelete(index, evt){

    //These digit values are BEFORE the delete.
    let {
      digitOne,
      digitTwo,
      digitThree,
      digitFour,
      digitFive,
      digitSix
    } = this.state;

    let nextRef = null;
    //index is 0 indexed.
    if(index === 0){
      if(digitOne && digitOne.length > 0){
        this.setState({digitOne : ''}, () => this.afterDigitChange())
      }
    }
    else if(index === 1){
      if(!digitTwo || digitTwo.length === 0){
        nextRef = this.digitOneField;
        this.setState({digitOne : ''}, () => this.afterDigitChange())
      }
      else{
        this.setState({digitTwo : ''}, () => this.afterDigitChange())
      }
    }
    else if(index === 2){
      if(!digitThree || digitThree.length === 0){
        nextRef = this.digitTwoField;
        this.setState({digitTwo : ''}, () => this.afterDigitChange())
      }
      else{
        this.setState({digitThree : ''}, () => this.afterDigitChange())
      }
    }
    else if(index === 3){
      if(!digitFour || digitFour.length === 0){
        nextRef = this.digitThreeField;
        this.setState({digitThree : ''}, () => this.afterDigitChange())
      }
      else{
        this.setState({digitFour : ''}, () => this.afterDigitChange())
      }
    }
    else if(index === 4){
      if(!digitFive || digitFive.length === 0){
        nextRef = this.digitFourField;
        this.setState({digitFour : ''}, () => this.afterDigitChange())
      }
      else{
        this.setState({digitFive : ''}, () => this.afterDigitChange())
      }
    }
    else if(index === 5){
      if(!digitSix || digitSix.length === 0){
        nextRef = this.digitFiveField;
        this.setState({digitFive : ''}, () => this.afterDigitChange())
      }
      else{
        this.setState({digitSix : ''}, () => this.afterDigitChange())
      }
    }

    if(nextRef){
      //This is a very weird edge case, but it looks like if I focus immediately, the delete hasn't been
      //handled yet, and it ends up deleting the character in the newly focused field.
      //I delay focus to fix. Seems crazy to me, but this fixes things.  Kind of scary.
      setTimeout(() => {
        nextRef.focus();
      }, 100)
    }
  }

  onFocus(index, evt) {
    let ref = this.getRefForIndex(index);
    if(ref){
      ref.selectAllText();
    }
  }

  getRefForIndex(index){
    let ref = null;
    switch(index){
      case 0:
        ref = this.digitOneField;
        break;
      case 1:
        ref = this.digitTwoField;
        break;
      case 2:
        ref = this.digitThreeField;
        break;
      case 3:
        ref = this.digitFourField;
        break;
      case 4:
        ref = this.digitFiveField;
        break;
      case 5:
        ref = this.digitSixField;
        break;
    }
    return ref;
  }

  render() {
    let {
      inputDisabled,
      inputCls,
      label,
      useNonFloatingLabel
    } = this.props;

    if(browser.isSafari()) {
      return (
        <SafariAuthCodeInput inputDisabled={inputDisabled}
                             inputCls={inputCls}
                             label={label}
                             useNonFloatingLabel={useNonFloatingLabel}
                             onChange={() => this.afterDigitChange()}
                             onEnter={this.onEnter.bind(this)}
                             onRef={(ref) => this.safariAuthInputRef = ref}/>
      )
    }

    let {
      digitOne,
      digitTwo,
      digitThree,
      digitFour,
      digitFive,
      digitSix,
      showShake,
      ie11hack
    } = this.state;

    let wrapStyle = {
      position: 'relative',
      overflow: 'initial',
    }

    if(showShake){
      wrapStyle.animation = 'shake 400ms ease-in-out';
    }

    let labelStyle = {
      pointerEvents: 'none',
      position: 'absolute',
      left: '0px',
      top: '-30px',
      fontSize: '12px',
      margin: '10px 10px 10px 25px',
      padding: '0 3px',
      backgroundColor: 'transparent',
      WebkitTransition: 'all .25s ease-in',
      transition: 'all .25s ease-in',
      color: '#fff'
    }
    if(useNonFloatingLabel){
      labelStyle = {};
    }

    let rowClass = classNames('d-flex');
    let colClass = classNames('mr-1', {'flex-fill' : ie11hack});
    let lastColClass = classNames('');
    let appliedInputCls = classNames('text-center', {'ie-11-hack' : ie11hack}, inputCls)

    return (
      <div className={'form-group auth-code-input'}>
        {label && label.length > 0 && <label style={labelStyle}>{label}</label> }
        <div style={wrapStyle} className={rowClass}>
          <div className={colClass}>
            <div>
              <FancyInput inputDisabled={inputDisabled}
                          onRef={ref => (this.digitOneField = ref)}
                          fieldType={'tel'}
                          inputCls={appliedInputCls}
                          inputNumeric={true}
                          inputValue={digitOne}
                          maxInputLength={1}
                          onPaste={this.onPaste.bind(this, 0)}
                          onFocus={this.onFocus.bind(this, 0)}
                          onEnter={this.onEnter.bind(this, 0)}
                          onDelete={this.onDelete.bind(this, 0)}
                          onKeyPress={this.onKeyPress.bind(this, 0)}/>
            </div>
          </div>
          <div className={colClass}>
            <FancyInput inputDisabled={inputDisabled}
                        onRef={ref => (this.digitTwoField = ref)}
                        fieldType={'tel'}
                        inputCls={appliedInputCls}
                        inputNumeric={true}
                        inputValue={digitTwo}
                        maxInputLength={1}
                        onPaste={this.onPaste.bind(this, 1)}
                        onFocus={this.onFocus.bind(this, 1)}
                        onEnter={this.onEnter.bind(this, 1)}
                        onDelete={this.onDelete.bind(this, 1)}
                        onKeyPress={this.onKeyPress.bind(this, 1)}/>
          </div>
          <div className={colClass}>
            <FancyInput inputDisabled={inputDisabled}
                        onRef={ref => (this.digitThreeField = ref)}
                        fieldType={'tel'}
                        inputCls={appliedInputCls}
                        inputNumeric={true}
                        inputValue={digitThree}
                        maxInputLength={1}
                        onPaste={this.onPaste.bind(this, 2)}
                        onFocus={this.onFocus.bind(this, 2)}
                        onEnter={this.onEnter.bind(this, 2)}
                        onDelete={this.onDelete.bind(this, 2)}
                        onKeyPress={this.onKeyPress.bind(this, 2)}/>
          </div>
          <div className={colClass}>
            <FancyInput inputDisabled={inputDisabled}
                        onRef={ref => (this.digitFourField = ref)}
                        fieldType={'tel'}
                        inputCls={appliedInputCls}
                        inputNumeric={true}
                        inputValue={digitFour}
                        maxInputLength={1}
                        onPaste={this.onPaste.bind(this, 3)}
                        onFocus={this.onFocus.bind(this, 3)}
                        onEnter={this.onEnter.bind(this, 3)}
                        onDelete={this.onDelete.bind(this, 3)}
                        onKeyPress={this.onKeyPress.bind(this, 3)}/>
          </div>
          <div className={colClass}>
            <FancyInput inputDisabled={inputDisabled}
                        onRef={ref => (this.digitFiveField = ref)}
                        fieldType={'tel'}
                        inputCls={appliedInputCls}
                        inputNumeric={true}
                        inputValue={digitFive}
                        maxInputLength={1}
                        onPaste={this.onPaste.bind(this, 4)}
                        onFocus={this.onFocus.bind(this, 4)}
                        onEnter={this.onEnter.bind(this, 4)}
                        onDelete={this.onDelete.bind(this, 4)}
                        onKeyPress={this.onKeyPress.bind(this, 4)}/>
          </div>
          <div className={lastColClass}>
            <FancyInput inputDisabled={inputDisabled}
                        onRef={ref => (this.digitSixField = ref)}
                        fieldType={'tel'}
                        inputCls={appliedInputCls}
                        inputNumeric={true}
                        inputValue={digitSix}
                        maxInputLength={1}
                        onPaste={this.onPaste.bind(this, 5)}
                        onFocus={this.onFocus.bind(this, 5)}
                        onEnter={this.onEnter.bind(this, 5)}
                        onDelete={this.onDelete.bind(this, 5)}
                        onKeyPress={this.onKeyPress.bind(this, 5)}/>
          </div>
        </div>
      </div>
    )
  }
}

AuthCodeInput.propTypes = {
  onRef : PropTypes.func,
  onChange : PropTypes.func,
  onEnter: PropTypes.func,
  label: PropTypes.string,
  useNonFloatingLabel: PropTypes.bool,
  inputDisabled : PropTypes.bool.isRequired,
  inputCls : PropTypes.string
}

export default AuthCodeInput;
