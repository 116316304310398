import c from '../util/const';
import vfLocalStorage from "../util/local-storage";
import DocPermissionsHelper from "../helpers/doc-permissions-helper";
import PendingMsgCache from "../helpers/pending-msg-cache";
import PushNotificationHelper from "../helpers/push-notification-helper";
import log from "../util/log";

const authActions ={

  setNextStep(step) {
    return {
      type: c.actions.auth.setStep,
      step
    }
  },

  setEmailValidationResults(res) {
    return {
      type: c.actions.auth.setEmailValidationResults,
      emailValidationResults : res
    }
  },

  setAllowEmailEditFromBoarding(allowEmailEditFromBoarding) {
    return {
      type: c.actions.auth.setAllowEmailEditFromBoarding,
      allowEmailEditFromBoarding : allowEmailEditFromBoarding
    }
  },

  setShowingInvalidEmailWarning(isShowing) {
    return {
      type: c.actions.auth.setShowingInvalidEmailWarning,
      showEmailValidationWarning : isShowing
    }
  },

  setEmailValidationPerformed(wasPerformed) {
    return {
      type: c.actions.auth.setEmailValidationPerformed,
      emailValidationPerformed : wasPerformed
    }
  },

  setEmailFieldVisible(isVisible){
    return {
      type : c.actions.auth.setEmailFieldVisible,
      emailFieldIsVisible : isVisible
    }
  },

  setLoginWithPayment(loginWithPayment){
    return {
      type : c.actions.auth.loginWithPayment,
      loginWithPayment
    }
  },

  setPreventEmailDisabled(preventDisabled){
    return {
      type : c.actions.auth.preventEmailDisabled,
      preventEmailDisabled : preventDisabled
    }
  },

  setConfirmationCode(code) {
    return {
      type: c.actions.auth.setConfirmationCode,
      code
    }
  },

  setTFAAuth(tfaAuth) {
    return {
      type: c.actions.auth.setTFAAuth,
      tfaAuth
    }
  },

  setInstitution(institution){
    return {
      type: c.actions.auth.setInstitution,
      institution
    }
  },

  setUpgradePlan(upgradeClassInfo){
    return {
      type: c.actions.auth.setUpgradePlan,
      upgradeClassInfo
    }
  },

  initPushSvc(){
    PushNotificationHelper.isSupportedEnvironment()
      .then((res) => {
        if(res){
          PushNotificationHelper.setupPushSvc()
            .then((res) => {
              log.log('push setup successfully', res);
            })
            .catch((err) => {
              log.warn('error initializing push notifications', err);
            })
        }
        else{
          log.log('unsupported environment for push notifications');
        }
      })
      .catch((err) => {
        log.log('error starting push', err);
      })
  },

  resetPushSvc(){
    PushNotificationHelper.isSupportedEnvironment()
      .then((res) => {
        if(res){
          PushNotificationHelper.resetPushSvc()
            .then((res) => {
              log.log('push reset successfully', res);
            })
            .catch((err) => {
              log.warn('error resetting push notifications', err);
            })
        }
      })
      .catch((err) => {
        log.log('error starting push', err);
      })
  },

  setAuthFromLocalStorage(){
    let sid = vfLocalStorage.get(c.localstorage.sid);
    let vip = vfLocalStorage.get(c.localstorage.vip);
    let token = vfLocalStorage.get(c.localstorage.token);

    if(!sid || !vip || !token){
      throw new Error("Localstorage not properly configured");
    }

    return this.setAuth(sid, vip, token);
  },

  setAuth(sid, vip, token){
    vfLocalStorage.set(c.localstorage.token, token);
    vfLocalStorage.set(c.localstorage.vip, vip);
    vfLocalStorage.set(c.localstorage.sid, sid);

    DocPermissionsHelper.clearCache();
    PendingMsgCache.cleanup();
    this.initPushSvc();

    return {
      type: c.actions.auth.setAuth,
      sid,
      vip,
      token
    }
  },

  setEmail(email) {
    return {
      type: c.actions.auth.setEmail,
      email
    }
  },

  setMemberNumber(number) {
    return {
      type: c.actions.auth.setMemberNumber,
      number
    }
  },

  cleanAuthentication(){
    vfLocalStorage.remove(c.localstorage.token);
    vfLocalStorage.remove(c.localstorage.api_db_header);
    vfLocalStorage.remove(c.localstorage.vip);
    vfLocalStorage.remove(c.localstorage.sid);

    DocPermissionsHelper.clearCache();
    PendingMsgCache.cleanup();
    this.resetPushSvc();

    //An action needs to return a type.  This takes no action, just makes it so
    //react doesn't complain.
    return {
      type : c.actions.auth.nothing
    };
  },

  logout(){
    this.cleanAuthentication();

    return {
      type : c.actions.auth.logout
    }
  }
}

export default authActions;
