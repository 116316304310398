import React, {PureComponent, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from "react-dropzone";
import log from "../../../util/log";
import colors from "../../../util/colors";
import utils from "../../../util/util";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class UploadHelper extends PureComponent {

  constructor(props){
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(evt){
    evt.preventDefault()
  }

  onDrop(acceptedFiles, rejectedFiles) {

    let { onDrop, generateImagePreviews, t } = this.props;

    if(onDrop) {
      let files = null;
      if(generateImagePreviews){
        files = acceptedFiles.map(file => Object.assign(file, {
          preview: file.type.indexOf('image') >= 0 ? URL.createObjectURL(file) : null,
          sizeString : utils.bytesToSize(t, file.size),
          sizeBytes : file.size,
          uniqueId : _.uniqueId('vf-file')
        }))
      }
      else{
        files = acceptedFiles.map(file => Object.assign(file, {
          sizeString : utils.bytesToSize(t, file.size),
          sizeBytes : file.size,
          uniqueId : _.uniqueId('vf-file')
        }))
      }

      if((files && files.length > 0) || (rejectedFiles && rejectedFiles.length > 0)){
        onDrop(files, rejectedFiles);
      }
      else{
        log.log('got weird empty ondrop event', arguments);
      }
    }
  }

  render() {
    let { children, disableClick, fileTypes, allowMultiple, clickOnly, disabled, preventDragOverlay, t } = this.props;

    if (disabled) {
      return (
        <Fragment>
          {children}
        </Fragment>
      )
    }

    if(clickOnly){
      return (
        <div className="dropzone-wrap">
          <Dropzone
            onDrop={this.onDrop}
            accept={fileTypes}
            multiple={allowMultiple || false}
          >
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {children}
              </div>
            )}
          </Dropzone>
        </div>
      )
    }

    if(disableClick){
      return (
        <div className="dropzone-wrap">
          <Dropzone
            onDrop={this.onDrop}
            accept={fileTypes}
            multiple={allowMultiple || false}
            noClick={true}
            noKeyboard={true}
          >
            {({getRootProps, getInputProps, isDragActive}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { isDragActive && !preventDragOverlay &&  <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.fileDrop}}>{t("Drop files here")}</div> }
                {typeof children === 'function' ? children({isDragActive}) : children}
              </div>
            )}
          </Dropzone>
        </div>
      );
    }

    return (
      <div className="dropzone-wrap">
        <Dropzone
          onDrop={this.onDrop}
          accept={fileTypes}
          multiple={allowMultiple || false}
        >
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              { isDragActive && !preventDragOverlay && <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.fileDrop}}>{t("Drop files here!")}</div> }
              {typeof children === 'function' ? children({isDragActive}) : children}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

UploadHelper.styles = {
  overlayStyle : {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '20px 0px',
    textAlign: 'center',
    zIndex: 100
  },
  fileDrop : {
    background: 'rgba(0,0,0,0.5)',
    color: colors.LIGHT,
  },
  dragDrop : {
    background: 'rgba(0,0,0,0.5)',
    color: colors.LIGHT,
  },
  canDragDrop : {
    background: 'rgba(0,0,0,0.2)',
    color: colors.LIGHT,
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

UploadHelper.propTypes = {
  onDrop : PropTypes.func.isRequired,
  clickOnly : PropTypes.bool,
  fileTypes : PropTypes.object,
  disableClick : PropTypes.bool,
  allowMultiple : PropTypes.bool,
  generateImagePreviews : PropTypes.bool,
  disabled: PropTypes.bool,
  preventDragOverlay : PropTypes.bool
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadHelper)));
