import React, {Component} from 'react';
import {connect} from 'react-redux';

import {CSSTransition} from 'react-transition-group';

import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import c from '../../../util/const';
import Button from "../elements/Button";
import log from "../../../util/log";
import utils from "../../../util/util";
import ExpandableRow from "../components/ExpandableRow";
import EditNameCtrl from "./general/EditNameCtrl";
import BlockListCtrl from "./general/BlockListCtrl";

import classNames from 'classnames';
import sapi from "../../../util/sapi";
import sharedActions from "../../../actions/shared-actions";
import accountActions from "../../../actions/account-actions";
import modalActions from "../../../actions/modal-actions";
import TutorialCtrl from "./general/TutorialCtrl";
import Image from "../elements/Image";
import EditLogoCtrl from "./general/EditLogoCtrl";
import Scroll from "react-scroll/modules";
import EditSignatureCtrl from "./general/EditSignatureCtrl";
import LanguageCtrl from "./general/LanguageCtrl";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import ChangeEmailCtrl from "./general/ChangeEmailCtrl";
import _ from "lodash";
import config from "../../../util/config";
import EditHandleCtrl from "./general/EditHandleCtrl";
import MerchantSetupWindow from "../../modals/MerchantSetupWindow";

class GeneralTab extends Component {

  BLOCK_LIST_ID = 'general-block-list-id';
  EDIT_LOGO_ID = 'general-edit-logo-id';
  EDIT_HANDLE_ID = 'general-edit-handle-id';
  CHANGE_EMAIL_ID = 'change-email-id';
  EDIT_NAME_ID = 'general-edit-name-id';
  EDIT_SIGNATURE_ID = 'general-edit-signature-id';
  EDIT_LANGUAGE_ID = 'general-edit-language-id';

  constructor(props) {
    super(props);

    this.state = {
      activeControl : null
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  setActiveControl(control){
    this.setState({activeControl: control})
  }

  editNameClick() {
    let { activeControl } = this.state;

    if(activeControl === 'edit_name'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('edit_name');
      this.editNameCtrl.init();
      this.scrollWithinContainer(this.EDIT_NAME_ID)
    }
  }

  changeEmailClick(){
    let { activeControl } = this.state;

    if(activeControl === 'change_email'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('change_email');
      this.changeEmailCtrl.init();
      this.scrollWithinContainer(this.CHANGE_EMAIL_ID)
    }
  }

  editSignatureClick(){
    let { activeControl } = this.state;

    if(activeControl === 'edit_signature'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('edit_signature');
      this.editSignatureCtrl.init();
      this.scrollWithinContainer(this.EDIT_SIGNATURE_ID)
    }
  }

  editLogoClick() {
    let { activeControl } = this.state;

    if(activeControl === 'edit_logo'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('edit_logo');
      this.editLogoCtrl.init();
      this.scrollWithinContainer(this.EDIT_LOGO_ID)
    }
  }

  editHandleClick() {
    let { activeControl } = this.state;

    if(activeControl === 'edit_handle'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('edit_handle');
      this.editHandleCtrl.init();
      this.scrollWithinContainer(this.EDIT_HANDLE_ID)
    }
  }

  showPDFFixerTool(){
    this.props.showPDFFixerTool(() => {
      log.log('closed fixer tool');
    });
  }

  langClick(){
    let { activeControl } = this.state;

    if(activeControl === 'change_lang'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('change_lang');
      this.langCtrl.init();
      this.scrollWithinContainer(this.EDIT_LANGUAGE_ID)
    }
  }

  userBlockListClick(){
    let { activeControl } = this.state;

    if(activeControl === 'user_block_list'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('user_block_list');
      this.blockListCtrl.init();
      this.scrollWithinContainer(this.BLOCK_LIST_ID)
    }
  }

  tutorialListClick(){
    let { activeControl } = this.state;

    if(activeControl === 'tutorial'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('tutorial');
      this.tutorialCtrl.init();
    }
  }

  closeActivePanel(){
    this.setActiveControl(null);
  }

  toggleAutomaticAlerts(evt){
    let { notifyCount, t } = this.props;
    let isTurningOn = evt.target.checked;

    let performOperation = () => {
      sapi.AccountInfo.update({auto_notify_flag : isTurningOn})
        .then((res) => {
          return Promise.all([
            this.props.updateAccountInfo(),
            this.props.updateNotifications()
          ])
        })
        .catch((err) => {
          log.log('error toggling auto alerts');
        })
    }

    if (isTurningOn && notifyCount > 0) {

      let msg = t("You have ") +
        (notifyCount === 1 ? t('1 alert') : notifyCount + t(" alerts")) +
        t(" in your outbox. They will be sent immediately when you enable automatic sending.")
      this.props.showConfirm(t("Automatically send alerts"),
        msg,
        (res) => {
          if (res) {
            performOperation();
          }
          else {
            evt.preventDefault();
            evt.stopPropagation();
          }
        })
    }
    else {
      performOperation();
    }
  }

  scrollToComponentId(id){
    Scroll.scroller.scrollTo(id, {
      duration: 300,
      smooth: true,
      offset: -15
    })
  }

  scrollToSubscriptionAndShowOptions(){
    this.scrollToComponentId('subscription-tab');

    //the timeout is for the scroll,
    //just so it looks like we scrolled there, then clicked.
    setTimeout(() => {
      this.props.showUpgradeDialog(null, (res) => {
        log.log('upgrade dlg res', res);
      })
    }, 500)
  }

  scrollWithinContainer(elemId){
    setTimeout(() => {
      Scroll.scroller.scrollTo(elemId, {
        duration: 300,
        smooth: true,
        offset: -65
      })
    }, 250)
  }

  toggleMessageEdit(evt) {
    let {updateAccountInfo, t} = this.props;

    let isEnabling = evt.target.checked;
    let msg = null;
    if (isEnabling) {
      msg = t("Are you sure you want to enable message editing? This will mean that your Guests can edit messages they submit in your ") +
        t("Workspaces and you can edit your own messages in your Workspaces. A complete history of messages and edits is always maintained and available.");
    }
    else {
      msg = t("Are you sure you want to disable message editing? This will mean that your Guests cannot edit messages they submit in your ") +
        t("Workspaces and you cannot edit your own messages in your Workspaces.");
    }

    this.props.showConfirm(t("Message Editing"),
      msg,
      (res) => {
        if (res) {
          sapi.AccountInfo.update({
              mesg_edit_flag: isEnabling
            })
            .then((res) => {
              return updateAccountInfo();
            })
            .catch((err) => {
              log.log('error updating msg edit', err);
            })
        }
      })
  }

  doEmailFixProcess(){
    log.log('general tab do email fix');
    this.changeEmailClick();
  }

  promptToBlockDocDownloadAlertsIfNotAlreadyBlocked(){
    let { downloadAlertsAreBlocked, t } = this.props;

    if(!downloadAlertsAreBlocked){
      this.props.showConfirm(t("Block Alerts"),
        t("Are you sure you want to block all document activity email alerts?  You will no longer receive emails when your Guests view or download the Documents you've shared."),
        (res) => {
          if(res){
            sapi.NotifyBlock.enableDND()
              .then(() => {
                return this.props.updateDownloadAlertState();
              })
              .catch((err) => {
                log.log('error enabling dnd', err);
              })
          }
        })
    }
  }

  toggleDocDownloadAlerts(evt){
    let { updateDownloadAlertState } = this.props;

    if(!evt.target.checked){
      this.promptToBlockDocDownloadAlertsIfNotAlreadyBlocked()
    }
    else{
      sapi.NotifyBlock.disableDND()
        .then(() => {
          return updateDownloadAlertState();
        })
        .catch((err) => {
          log.log('error disabling dnd', err);
        })
    }
  }

  showClientInvoicingDetailsClick(isStripeRefresh, isStripeReturn){
    this.props.showMerchantSetupWindow(isStripeRefresh, isStripeReturn, (res) => {
      if(res === MerchantSetupWindow.WINDOW_RESULT.UPGRADE){
        this.scrollToSubscriptionAndShowOptions();
      }
    })
  }

  render() {
    let {activeControl} = this.state;
    let {
      accountInfo,
      accountClassInfo,
      signatures,
      downloadAlertsAreBlocked,
      userBlockList,
      userHandle,
      logo,
      merchantInfo,
      t
    } = this.props;

    if(!accountInfo){
      return null;
    }

    let merchant_flag = _.get(accountClassInfo, 'class_info.merchant_flag', false);
    let isMerchantStatusHealthy = MerchantSetupWindow.isMerchantStatusHealthy(merchantInfo);
    let merchantStatus = "";
    if(!merchant_flag || !merchantInfo){
      merchantStatus = t("Disabled");
    }
    else {
      merchantStatus = isMerchantStatusHealthy ? t("Enabled") : t("Disabled");
    }

    let canAddLogo = !c.isFreeTier(accountInfo.class_id);
    let handleDisplay = _.get(userHandle, 'handle');
    if(!handleDisplay){
      handleDisplay = t('None');
    }
    else{
      handleDisplay = `@${handleDisplay}`;
    }

    return (
      <div>
        <div>
          <h3 className={'ml-3'}>{t("General Settings")}</h3>
        </div>
        <div className={'card'}>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'edit_name'})}
                onClick={this.editNameClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-name-field'}>
                  {t("Name")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext no-pointer"
                       id="account-name-field"
                       value={accountInfo.first_name + ' ' + accountInfo.last_name}/>
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'edit_name'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'edit_name'}>
            <div id={this.EDIT_NAME_ID} className={'card-body card-border-bottom'}>
              <EditNameCtrl onRef={ref => (this.editNameCtrl = ref)}
                            doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'change_email'})}
               onClick={this.changeEmailClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-email-field'}>
                  {t("Email")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext no-pointer"
                       id="account-email-field"
                       value={accountInfo.login}/>
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'change_email'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'change_email'}>
            <div id={this.CHANGE_EMAIL_ID} className={'card-body card-border-bottom'}>
              <ChangeEmailCtrl onRef={ref => (this.changeEmailCtrl = ref)}
                               doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'edit_signature'})}
               onClick={this.editSignatureClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-signatures-field'}>
                  {t("Signature")}
                </label>
              </div>
              <div className={'col-5'}>
                {!signatures && <input type="text"
                                       readOnly
                                       className="form-control-plaintext light-grey-color no-pointer"
                                       id="account-signatures-field"
                                       value={'None'}/>
                }
                {signatures && <Image src={signatures.sign_image}
                                      className={'no-pointer'}
                                      imgHeight={40}
                                      alt={'Signature'}/>
                }
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'edit_signature'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'edit_signature'}>
            <div id={this.EDIT_SIGNATURE_ID} className={'card-body card-border-bottom'}>
              <EditSignatureCtrl onRef={ref => (this.editSignatureCtrl = ref)}
                                 doClose={this.closeActivePanel.bind(this)} />
            </div>
          </ExpandableRow>

          {canAddLogo &&
            <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'edit_logo'})}
                 onClick={this.editLogoClick.bind(this)}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2'}
                         htmlFor={'account-logo-field'}>
                    {t("Logo")}
                  </label>
                </div>
                <div className={'col-5'}>
                  {(!logo) && <input type="text"
                                     readOnly
                                     className="form-control-plaintext light-grey-color no-pointer"
                                     id="account-logo-field"
                                     value={t('None')}/>
                  }
                  {logo && <Image src={logo} imgHeight={40} alt={t('Account Logo')} /> }
                </div>
                <div className={'col-3 text-right'}>
                  <div className={'pt-2 pr-4'}>
                    <CSSTransition in={activeControl === 'edit_logo'}
                                   timeout={400}
                                   classNames={'rotate-90'}>
                      <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
          }
          {!canAddLogo &&
            <div className={classNames('card-header transition-bg-color', {'bg-white': activeControl !== 'edit_logo'})}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2'}
                         htmlFor={'account-logo-field'}>
                    {t("Logo")}
                  </label>
                </div>
                <div className={'col-5'}>
                  {(!logo) && <input type="text"
                                     readOnly
                                     className="form-control-plaintext light-grey-color no-pointer"
                                     id="account-logo-field"
                                     value={t('None')}/>
                  }
                  {logo && <Image src={logo} imgHeight={40} alt={t('Account Logo')} /> }
                </div>
                <div className={'col-3 text-right'}>
                  <Button className={'btn btn-link'}
                          onClick={this.scrollToComponentId.bind(this, 'subscription-tab')}>{t("Upgrade to Add Logo")}</Button>
                </div>
              </div>
            </div>
          }

          <ExpandableRow isActive={activeControl === 'edit_logo'}>
            <div id={this.EDIT_LOGO_ID} className={'card-body card-border-bottom'}>
              <EditLogoCtrl onRef={ref => (this.editLogoCtrl = ref)}
                            doClose={this.closeActivePanel.bind(this)} />
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'edit_handle'})}
               onClick={this.editHandleClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'public-portal-field'}>
                  {t("Public Upload Page")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       id="public-portal-field"
                       value={handleDisplay}/>
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'edit_handle'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'edit_handle'}>
            <div id={this.EDIT_HANDLE_ID} className={'card-body card-border-bottom'}>
              <EditHandleCtrl onRef={ref => (this.editHandleCtrl = ref)}
                              doUpgradeClick={this.scrollToSubscriptionAndShowOptions.bind(this)}
                              doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          <div>
            <div
              className={classNames('card-header transition-bg-color bg-white')}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'client-invoicing-field'}>
                    {t("Request Payments")}
                  </label>
                </div>
                <div className={'col-5'}>
                  <input type="text"
                         readOnly
                         className="form-control-plaintext light-grey-color no-pointer"
                         id="client-invoicing-field"
                         value={merchantStatus}/>
                </div>
                <div className={'col-3 text-right'}>
                  <Button className={'btn btn-link'}
                          onClick={() => this.showClientInvoicingDetailsClick()}>{t("Show Details")}</Button>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames('card-header bg-white transition-bg-color')}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-storage-field'}>
                  {t("Storage Used")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext"
                       id="account-storage-field"
                       value={utils.bytesToSize(t, accountInfo.quota.used) + ' / ' + utils.bytesToSize(t, accountInfo.quota.size)}/>
              </div>
              <div className={'col-3 text-right'}/>
            </div>

          </div>

          <div className={classNames('card-header transition-bg-color', {'bg-white': activeControl !== 'auto_send_alerts'})}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-auto-alerts'}>
                  {t("Automatically send alerts")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color"
                       value={(accountInfo.auto_notify_flag ? t('ON') : t('OFF'))}/>
              </div>
              <div className={'col-3 text-right'}>

                <span className="custom-control custom-switch custom-switch-lg">
                  <input type="checkbox"
                         className="custom-control-input"
                         onChange={this.toggleAutomaticAlerts.bind(this)}
                         checked={accountInfo.auto_notify_flag}
                         id="account-auto-alerts"/>
                    <label className="custom-control-label" htmlFor={'account-auto-alerts'} />
                </span>

              </div>
            </div>
          </div>

          <div className={classNames('card-header transition-bg-color', {'bg-white': activeControl !== 'doc_activity_alerts'})}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-doc-download-alerts'}>
                  {t("Document activity alerts")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color"
                       value={(downloadAlertsAreBlocked ? t('OFF') : t('ON'))}/>
              </div>
              <div className={'col-3 text-right'}>

                <span className="custom-control custom-switch custom-switch-lg">
                  <input type="checkbox"
                         className="custom-control-input"
                         onChange={this.toggleDocDownloadAlerts.bind(this)}
                         checked={!downloadAlertsAreBlocked}
                         id="account-doc-download-alerts"/>
                    <label className="custom-control-label" htmlFor={'account-doc-download-alerts'} />
                </span>

              </div>
            </div>
          </div>

          <div className={classNames('card-header transition-bg-color', {'bg-white': activeControl !== 'mesg_editing'})}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}
                       htmlFor={'account-mesg-editing'}>
                  {t("Message Edit")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color"
                       value={(accountInfo.mesg_edit_flag ? t('ON') : t('OFF'))}/>
              </div>
              <div className={'col-3 text-right'}>

                <span className="custom-control custom-switch custom-switch-lg">
                  <input type="checkbox"
                         className="custom-control-input"
                         onChange={this.toggleMessageEdit.bind(this)}
                         checked={accountInfo.mesg_edit_flag}
                         id="account-mesg-editing"/>
                    <label className="custom-control-label" htmlFor={'account-mesg-editing'} />
                </span>

              </div>
            </div>
          </div>

          <div
            id="user_block_list"
            className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'user_block_list'})}
            onClick={this.userBlockListClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2 no-pointer'}
                       htmlFor={'account-block-list-field'}>
                  {t("Block List")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       id="account-block-list-field"
                       value={(userBlockList.length === 1 ? t('One user blocked') : userBlockList.length + t(' users blocked'))}/>
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'user_block_list'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>
          <ExpandableRow isActive={activeControl === 'user_block_list'}>
            <div id={this.BLOCK_LIST_ID} className={'card-body card-border-bottom'}>
              <BlockListCtrl onRef={ref => (this.blockListCtrl = ref)}
                             doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          <div
            id="change_lang"
            className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'change_lang'})}
            onClick={this.langClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2 no-pointer'}
                       htmlFor={'change-lang-field'}>
                  {t("Language")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       id="change-lang-field"
                       value={LanguageCtrl.lookupLanguageDisplay(t, this.props.i18n.language)}/>
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'change_lang'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>
          <ExpandableRow isActive={activeControl === 'change_lang'}>
            <div id={this.EDIT_LANGUAGE_ID} className={'card-body card-border-bottom'}>
              <LanguageCtrl onRef={ref => (this.langCtrl = ref)}
                             doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          {config.showPDFFixerTool &&
            <div
              className={classNames('card-header transition-bg-color bg-white')}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'pdf-fixer-field'}>
                    {t("PDF Fixer")}
                  </label>
                </div>
                <div className={'col-5'}>
                  <input type="text"
                         readOnly
                         className="form-control-plaintext light-grey-color no-pointer"
                         id="pdf-fixer-field"
                         value={''}/>
                </div>
                <div className={'col-3 text-right'}>
                  <Button className={'btn btn-link'}
                          onClick={this.showPDFFixerTool.bind(this)}>{t("Show")}</Button>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

GeneralTab.propTypes = {
  onRef : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    accountInfo: state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,
    signatures : state.shared.signatures,
    merchantInfo : state.shared.merchantInfo,
    passwordInfo: state.account.passwordInfo,
    subscriptionList: state.account.subscriptionList,
    downloadAlertsAreBlocked : state.account.downloadAlertsAreBlocked,
    userBlockList : state.account.userBlockList,
    userHandle : state.account.userHandle,
    logo : state.shared.logo,
    notifyCount : state.shared.notifyCount,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    updateDownloadAlertState : () => dispatch(accountActions.updateDownloadAlertState()),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralTab)));
