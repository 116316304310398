import React, {Component} from 'react';
import PropTypes from 'prop-types';
import c from "../../../util/const";
import {CSSTransition} from "react-transition-group";
import {BiSmile, BiWinkSmile, BiWinkTongue, BiAngry, BiDizzy, BiHappy, BiLaugh, BiMeh, BiMehAlt, BiShocked, BiTired, BiUpsideDown} from 'react-icons/bi'
import colors from "../../../util/colors";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import log from "../../../util/log";
import _ from 'lodash';
import {withVFTranslation} from "../../../util/withVFTranslation";
import VFPopover from "../components/VFPopover";

class ChatEmojiMenu extends Component {

  faces = {
    0: BiSmile,
    1: BiWinkSmile,
    2: BiWinkTongue,
    3: BiAngry,
    4: BiDizzy,
    5: BiHappy,
    6: BiLaugh,
    7: BiMeh,
    8: BiMehAlt,
    9: BiShocked,
    10: BiTired,
    11: BiUpsideDown
  }

  constructor(props) {
    super(props);

    this.faceTimer = null;

    this.state = {
      menuOpen : false,
      faceIndex : 0,
      pollTimer : null,
      pollingRunning : false
    }
  }

  openMenuClick(){
    this.setState({menuOpen : !this.state.menuOpen}, () => {
      if(this.state.menuOpen){
        if(this.props.onMenuOpen){
          this.props.onMenuOpen();
        }
      }
    })
  }

  onSelectEmoji(emoji){
    this.props.onEmojiSelect(emoji);

  }

  onClickOutsidePicker(){
    log.log('onClickOutsidePicker')
    this.setState({menuOpen : false})
  }

  getLocalizedText(){
    let { t } = this.props;

    //comes from https://raw.githubusercontent.com/missive/emoji-mart/refs/heads/main/packages/emoji-mart-data/i18n/en.json
    return {
      "search": t('Search'),
      "search_no_results_1": t('No Emoji Found'),
      //"search_no_results_2": "That emoji couldn’t be found",
      "pick": t("Pick an Emoji..."),
      "add_custom": t("Add Custom Emoji"),
      "categories": {
        "activity": t('Activity'),
        "custom": t('Custom'),
        "flags": t('Flags'),
        "foods": t('Food & Drink'),
        "frequent": t('Frequently Used'),
        "nature": t('Animals & Nature'),
        "objects": t('Objects'),
        "people": t('People & Body'),
        "places": t('Travel & Places'),
        "search": t('Search Results'),
        "symbols": t('Symbols'),
      },
      "skins": {
        "choose": t("Choose Default Skin Tone"),
        "1": t('Default Skin Tone'),
        "2": t('Light Skin Tone'),
        "3": t('Medium-Light Skin Tone'),
        "4": t('Medium Skin Tone'),
        "5": t('Medium-Dark Skin Tone'),
        "6": t('Dark Skin Tone'),
      }
    }
  }

  render() {
    let { showMenu, isDM } = this.props;
    let { menuOpen, faceIndex } = this.state;
    //let colorCls = isDM ? 'dm' : 'thread';

    let FaceIcon = this.faces[faceIndex];

    return (
      <div className="position-relative">
        <VFPopover
          isPopoverOpen={menuOpen}
          positions={['top']}
          containerStyle={{top: '-20px'}}
          onClickOutside={this.onClickOutsidePicker.bind(this)}
          getMenuContent={() => {
            return (
              <Picker data={data}
                      i18n={this.getLocalizedText()}
                      onEmojiSelect={this.onSelectEmoji.bind(this)}
                      style={{
                        zIndex: 101
                      }}/>
            )
          }}>
          <div style={{
            position: 'absolute',
            top: '-7px',
            left: '50%',
            marginLeft: '-9px', //half button width to center
            zIndex: '1000',
          }}>
            <div onClick={this.openMenuClick.bind(this)}
                 onMouseOver={() => this.setState({faceIndex: 5})}
                 onMouseLeave={() => this.setState({faceIndex: 0})}
                 style={{
                   textAlign: 'center',
                   width: '18px',
                   maxHeight: '1rem',
                   backgroundColor: isDM ? colors.DARK : colors.PRIMARY,
                   color: colors.LIGHT,
                   borderBottomRightRadius: '9px',
                   borderBottomLeftRadius: '9px',
                   lineHeight: '1rem',
                   verticalAlign: 'middle',
                   fontSize: '14px',
                   cursor: 'pointer'
                 }}>
              <FaceIcon />
            </div>
          </div>
        </VFPopover>
      </div>
    )
  }
}

ChatEmojiMenu.propTypes = {
  onEmojiSelect : PropTypes.func.isRequired,
  onMenuOpen : PropTypes.func.isRequired,
  isDM : PropTypes.bool.isRequired
}

export default withVFTranslation()(ChatEmojiMenu);
