import c from '../util/const';
import sapi from "../util/sapi";

import sharedActions from './shared-actions';

import Promise from 'bluebird';
import log from "../util/log";

const accountActions = {

  startup() {
    let me = this;

    return (dispatch) => {

      setTimeout(() => {
        //not in main promise flow.
        //This should already exist, we just want to make sure it gets updated as we navigate here.
        dispatch(sharedActions.updateSignatures());
        dispatch(sharedActions.updateStripeAvailables());
        dispatch(sharedActions.updateStripeData());
        dispatch(sharedActions.updateAccountInfo());
      }, 500);

      return Promise.all([
        dispatch(me.updateTFAStatus()),
        dispatch(me.updatePasswordInfo()),
        dispatch(me.updateSubscriptionList()),
        dispatch(me.updateDownloadAlertState()),
        dispatch(me.updateUserBlockList()),
        dispatch(me.updateUserHandle())
      ])
    }
  },

  cleanup() {
    return {
      type: c.actions.account.cleanup
    }
  },

  updateUserHandle(){
    return (dispatch) => {
      return sapi.Handle.get()
        .then((res) => {
          dispatch({
            type : c.actions.account.updateUserHandle,
            handle : res.data
          })
        })
        .catch((err) => {
          dispatch({
            type : c.actions.account.updateUserHandle,
            handle : null
          })
        })
    }
  },

  updateDownloadAlertState() {
    return (dispatch) => {
      return sapi.NotifyBlock.areDownloadAlertsBlocked()
        .then((res) => {
          dispatch({
            type : c.actions.account.updateDownloadAlertsAreBlocked,
            blocked : res
          })
        })
    }
  },

  updateUserBlockList() {
    return (dispatch) => {
      return sapi.NotifyBlock.userList()
        .then((res) => {
          dispatch({
            type : c.actions.account.updateUserBlockList,
            blockList : res.data
          })
        })
    }
  },

  updatePasswordInfo() {
    return (dispatch) => {
      return sapi.Password.info()
        .then(data => {
          dispatch({
            type: c.actions.account.updatePasswordInfo,
            passwordInfo: data.data
          });
          return true;
        })
    }
  },

  updateTFAStatus() {
    return (dispatch) => {
      return sapi.TFA.status()
        .then(data => {
          dispatch({
            type: c.actions.account.updateTFAStatus,
            tfaStatus: data.data
          });
          return true;
        })
    }
  },

  updateSubscriptionList() {
    return (dispatch) => {
      return sapi.Subscription.list()
        .then(data => {
          dispatch({
            type: c.actions.account.updateUserSubscriptionList,
            subscriptionList: data.data
          });
          return true;
        })
    }
  },
}

export default accountActions;
