import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import PulseButton from '../elements/PulseButton';
import FancyInput from '../elements/FancyInput';
import Button from '../elements/Button';
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import _ from "lodash";
import stripeHelper from "../../../util/stripe-helper";
import {withTranslation, Trans} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import HandleConfirmCodeInput from "../elements/HandleConfirmCodeInput";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../util/config";

class HandleBoardingCard extends Component {

  recaptchaRef = React.createRef();
  codeField = null;

  constructor(props){
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      firstNameValidationErr: null,
      lastNameValidationErr: null,
      boardingSuccessful: false,
      showCaptchaError: false,
      recaptchaToken : ''
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    setTimeout(() => {
      let { email, emailCardRef } = this.props;
      if(!email){
        if(emailCardRef){
          emailCardRef.focusEmail();
        }
      }
      else{
        this.firstNameField.focus();
      }
    }, 250)
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  validateForm() {
    let { emailCardRef, email } = this.props;
    let { first_name, last_name } = this.state;

    this.props.setValidationErrors([]);
    this.setState({
      firstNameValidationErr: null,
      lastNameValidationErr: null,
    });

    let success = true;
    //Check email from props first.
    if(!email || email.length === 0){
      let refEmail = emailCardRef.getValidatedEmailValue();
      if(!refEmail || refEmail.length === 0){
        emailCardRef.triggerValidationErr();
        success = false;
      }
    }

    if(!first_name || first_name.length === 0){
      this.setState({
        firstNameValidationErr: true
      })
      this.firstNameField.triggerValidationErr();
      success = false;
    }

    if(!last_name || last_name.length === 0){
      this.setState({
        lastNameValidationErr: true
      })
      this.lastNameField.triggerValidationErr();
      success = false;
    }

    return success;
  }

  doNextButtonClicked(){
    if(!this.state.boardingSuccessful) {
      this.doBoarding(this.props.paymentMethodId);
    }
    else{
      this.doSubmitConfirmCode();
    }
  }

  doSubmitConfirmCode(){
    let { t } = this.props;
    let code = this.codeField.assembleCode();
    if(code.length !== 5){
      this.codeField.triggerValidationErr();
      return;
    }
    let {
      recaptchaToken
    } = this.state;
    if(!recaptchaToken || recaptchaToken.length === 0){
      this.props.setValidationErrors([
        t("Please complete the CAPTCHA process")
      ]);
      return;
    }

    let {
      email,
      setAuth
    } = this.props;

    api.PasswordRegisterConfirm.post(email, code, recaptchaToken, true)
      .then((res) => {
        log.log('success confirming code', res);
        setAuth(res.data.sid, res.data.vip, res.data.token);
        if(this.props.onTokenReceived){
          this.props.onTokenReceived(_.get(res, 'data.token'))
        }
      })
      .catch((err) => {
        log.log('error confirming code', err);

        if(err && err.name === "APP_CAPTCHA_FAILED"){
          this.recaptchaRef.current.reset();
          this.setState({
            showCaptchaError : true
          })
        }
        else{
          this.setState({
            needCaptcha: false
          }, () => {
            this.onCaptchaError(err)
          })
        }
      })

  }

  onCaptchaError(err){
    this.setState({
      cfmErr : err
    });
  }

  onCaptchaFinish(recaptchaToken){
    log.log('captcha res change', recaptchaToken);

    this.setState({
      showCaptchaError: false,
      recaptchaToken
    })
  }

  doBoarding(paymentMethodId){
    let { first_name, last_name } = this.state;
    let {
      setStep,
      setEmail,
      email,
      memberNumber,
      setEmailFieldVisible,
      emailCardRef,
      institution,
      targetClassId,
      validCouponDeets,
      t
    } = this.props;

    if(!this.validateForm()){
      return;
    }

    this.props.setLoading(true);

    let reqEmail = email;
    if(!reqEmail || reqEmail.length === 0){
      reqEmail = emailCardRef.getValidatedEmailValue();
    }

    let language = this.props.i18n.language;
    let couponId = _.get(validCouponDeets, 'coupon.id', null);
    let domainFlag = _.get(institution, 'info_json.domain_flag', null);
    let boarding = null;
    if(domainFlag){
      //domain flag means call lookup with inst_id, and null member number.  bug 2966
      boarding = api.Boarding.post(reqEmail, first_name, last_name, institution.inst_id, null, paymentMethodId, targetClassId, couponId, language, true);
    }
    else if(memberNumber){
      boarding = api.Boarding.post(reqEmail, first_name, last_name, institution.inst_id, memberNumber, paymentMethodId, targetClassId, couponId, language, true);
    }
    else{
      boarding = api.Boarding.post(reqEmail, first_name, last_name, null, null, paymentMethodId, targetClassId, couponId, language, true)
    }

    boarding
      .then((res) => {
        log.log('boarding res', res);
        setEmail(reqEmail);
        setEmailFieldVisible(false);
        this.setState({
          boardingSuccessful : true,
        })
        this.props.setLoading(false);
      })
      .catch((err) => {
        this.props.setValidationErrors([
          stripeHelper.getMessageForStripeOrVfError(err, t)
        ]);
        this.props.setLoading(false);
      })
  }

  onStartOverButtonClick() {
    let { setStep, setEmailFieldVisible, setPreventEmailDisabled } = this.props;

    setStep(c.authSteps.email);
    setEmailFieldVisible(true);
    setPreventEmailDisabled(false);
  }

  onFirstNameChange(val){
    this.setState ({
      first_name: val
    })
  }

  onLastNameChange(val){
    this.setState ({
      last_name: val
    })
  }

  showInfo(){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Why do we need a name?'),
      <div>
        <p>
          {t("It’s nice to know what to call you! We use this name when we send you emails. It just seems friendlier that way.")}
        </p>
        <p>
          {t("More importantly, we use this name when you reach out to others in Verifyle. It helps them recognize and identify you.")}
        </p>
        <p className="pt-3">
          {t("Contact us at")}
          <br/>
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
        </p>
      </div>
    )
  }

  render() {
    let {
      firstNameValidationErr,
      lastNameValidationErr,
      boardingSuccessful,
      first_name,
      last_name,
      showCaptchaError
    } = this.state;
    let {
      email,
      isLoading,
      t
    } = this.props;

    let card = null;
    if(boardingSuccessful){
      card =
        <div className={'light-color'}>
          <div className="text-center my-4">
            <h4>{t("Welcome to Verifyle!")}</h4>
          </div>
          <p className="font-weight-bold mb-1 ml-4">
            {`${first_name} ${last_name}`}
          </p>
          <p className="mb-3 ml-4">
            {email}
          </p>
          <div>
            <Button className={`btn btn-outline-light`}
                    type="button"
                    disabled={this.props.isLoading}
                    onClick={() => this.props.doStartOverButtonClick()}>
              <i className={'ion-edit mr-2'}/>
              {t("Edit Sender Info")}
            </Button>
          </div>

          <div className="my-5 text-center">
            <ReCAPTCHA
              style={{display: "inline-block"}}
              ref={this.recaptchaRef}
              sitekey={config.automatedTesting ? c.google.always_pass_recaptcha_key : c.google.captcha_key}
              onChange={this.onCaptchaFinish.bind(this)}
            />

            {showCaptchaError &&
              <p className={'mt-2'}>
                {t("There was a problem confirming your account. Please try again.")}
              </p>
            }

            <p className="card-text mt-2" style={{fontSize: '14px'}}>
              {t("If you’re having problems confirming your account, contact")} <a href={c.links.mailToSupport}
                                                                                   className="hover-underline light-color">{t("support@verifyle.com")}</a>.
            </p>
          </div>

          <div className="text-center">
            <h4 className="mb-2">{t("Last Step!")}</h4>
            <h4 className="mb-2">{t("Check your Email")}</h4>
            <p className="mb-5">
              {t("Please enter the code we sent to")} <br/><b>{email}</b><br/>
            </p>
          </div>
          <div className="position-relative text-center" style={{maxWidth: '350px', margin: 'auto'}}>
            <HandleConfirmCodeInput onRef={ref => this.codeField = ref}
                                    onEnter={this.doNextButtonClicked.bind(this)}
                                    inputDisabled={isLoading}/>
          </div>

        </div>
    }
    else{
      card =
        <div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <FancyInput onRef={ref => (this.firstNameField = ref)}
                            placeholder={t('First Name')}
                            inputDisabled={false}
                            fieldType={'text'}
                            isValid={!firstNameValidationErr}
                            inputValue={this.state.first_name}
                            infoBtnCls={'ion-ios-information-outline auth-info-icon has-pointer'}
                            onInfoClick={this.showInfo.bind(this)}
                            onEnter={this.doNextButtonClicked.bind(this)}
                            onChange={this.onFirstNameChange.bind(this)}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <FancyInput onRef={ref => (this.lastNameField = ref)}
                            placeholder={t('Last Name')}
                            inputDisabled={false}
                            fieldType={'text'}
                            isValid={!lastNameValidationErr}
                            inputValue={this.state.last_name}
                            onEnter={this.doNextButtonClicked.bind(this)}
                            onChange={this.onLastNameChange.bind(this)}/>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={'col'}>
              <div className={'light-color mb-4'} style={{fontSize : '14px'}}>
                <Trans t={t}>
                  By clicking "Next" you agree to Verifyle's <a target={'_blank'} className={'auth-link'} href={c.links.tos}>terms of use</a> and <a target={'_blank'} className={'auth-link'} href={c.links.privacy}>privacy policy</a>.
                </Trans>
              </div>
            </div>
          </div>
        </div>
    }

    return (
      <div>
        {card}
      </div>
    );
  }
}

//There's some weird handling in here around the email
//If you come in via query string, we take the user right to boarding without
//populating email first.  We need to look at the existing email card and pull
//values from there manually in that case.
HandleBoardingCard.propTypes = {
  emailCardRef : PropTypes.object,
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  setHideAuthButtonControls : PropTypes.func.isRequired,
  doStartOverButtonClick : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  paymentMethodId : PropTypes.string,
  targetClassId : PropTypes.number,
  stripe : PropTypes.object,
  validCouponDeets : PropTypes.object,
  onTokenReceived : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    memberNumber : state.auth.memberNumber,
    institution : state.auth.institution,
    step : state.auth.step,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    showAuthInfo: (title, contents, cb) => dispatch(modalActions.showAuthInfo(title, contents, cb)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled))
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(HandleBoardingCard));
