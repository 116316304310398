import React, {Component} from 'react';
import {connect} from 'react-redux';

import Button from "../partials/elements/Button";
import Loading from '../partials/util/Loading';

import c from '../../util/const';
import config from '../../util/config';
import log from '../../util/log';
import api from '../../util/api';
import redirectHelper from '../../util/redirect-helper';

import authActions from "../../actions/auth-actions";
import modalActions from '../../actions/modal-actions';
import vfLocalStorage from "../../util/local-storage";
import ReCAPTCHA from "react-google-recaptcha";
import Image from "../partials/elements/Image";
import logo from "../../resources/logo-w-text-white.png";
import cookieHelper from "../../helpers/cookie-helper";
import _ from 'lodash';
import {withVFTranslation} from "../../util/withVFTranslation";

const recaptchaRef = React.createRef();

class Confirm extends Component {

  constructor(props) {
    super(props);

    let qs = _.get(props, 'qs', {});

    //I believe if 'qs' is null, that _.get will still return null rather than the
    //default value, because the property exists, it's just null.
    if(!qs){
      qs = {};
    }
    this.state = {
      loading : true,
      isReset : qs[c.querystring.goto_reset] && (qs[c.querystring.goto_reset] + "").toUpperCase() === "YES",
      cfmErr: null,
      resending: false,
      needCaptcha: false,
      showCaptchaError: false
    };
  }

  componentDidMount() {
    let { email, code, qs, history } = this.props;

    if(!qs || (!qs[c.querystring.goto_reset] && !qs[c.querystring.goto_confirm])){
      log.warn('User in confirm without proper querystring')
      history.replace('/')
      return;
    }


    if(this.state.isReset){
      let me = this;
      api.PasswordResetConfirm.post(email, code)
        .then((res) => {
          me.cfmSuccess(res);
        })
        .catch((err) => {
          me.cfmFail(err);
        })
    }
    else{
      this.setState({
        needCaptcha: true
      })
    }
  }

  doRegisterConfirm(recaptcha_token){
    let { email, code } = this.props;
    let me = this;

    api.PasswordRegisterConfirm.post(email, code, recaptcha_token)
        .then((res) => {
          me.cfmSuccess(res);
        })
        .catch((err) => {

          if(err && err.name === "APP_CAPTCHA_FAILED"){
            me.resetCaptcha();
            me.setState({
              showCaptchaError : true
            })
          }
          else{
            this.setState({
              needCaptcha: false
            }, () => {
              me.cfmFail(err)
            })
          }
        })
  }

  resetCaptcha(){
    recaptchaRef.current.reset();
  }

  cfmSuccess (args) {
    let { isReset } = this.state;
    let { setAuth, setStep, setTFAAuth, history, qs } = this.props;

    log.log('cfm success', JSON.stringify(args));
    if (args.error) {
      return this.cfmFail(args);
    }

    this.setState({ loading: false })

    //This is check necessary?
    //Normal tfa password reset stays in the Auth app, where you directly enter your auth code and new password.
    //If you generate a password reset link and THEN turn on TFA, the link doesn't work.
    //I only have it here because the webapp has it.
    if (args.data.tfa_totp_flag || args.data.tfa_sms_flag) {
      setTFAAuth(args.data);
      setStep(c.authSteps.tfa);
    }
    else {
      cookieHelper.clearTrackingCookies();
      setAuth(args.data.sid, args.data.vip, args.data.token);
      redirectHelper.redirectToApp(history, qs, args.data.vip);
    }
  }

  cfmFail (err) {
    this.setState({
      cfmErr : err,
      loading: false
    });
  }

  resendClick() {
    let { email, alert, history, t } = this.props;
    let { isReset } = this.state;

    var success = () => {
      alert(t("Success"), t("You should receive an email from us shortly."), () => {
        //go to login.
        history.replace('/')
      })
    }

    var fail = () => {
      alert(t("Unable to resend"),
        t("There was a problem ") + (isReset ? t("resetting your password. ") : t("resending your confirmation email. ")) + t("Please try again."),
        () => {})
    }

    if (isReset) {
      api.PasswordReset.post(email)
        .then( (res) => {
          success(res);
        })
        .catch( (err) => {
          fail(err);
        })
    }
    else {
      api.Boarding.resend(email)
        .then( (res) => {
          success(res);
        })
        .catch( (err) => {
          fail(err);
        })
    }
  }

  goToLogin() {
    let { history } = this.props;

    history.replace('/');
  }

  onCaptchaFinish(res){
    log.log('captcha res change', res);

    if(res) {
      //This delay is to show the captcha animation
      setTimeout(() => {

        this.doRegisterConfirm(res)
        this.setState({
          showCaptchaError: false
        })
      }, 1000)

    }
  }

  doCaptcha(){
    let { t } = this.props;
    let { showCaptchaError } = this.state;

    return (
      <div className={'celestial-bg'}>
        <div className="container">

          <div className={'row justify-content-center'}>
            <div className={'auth-header-img col'}>
              <Image src={logo}
                     className={'mx-auto d-block'}
                     imgHeight={90}
                     alt={t('logo')}/>
            </div>
          </div>
          <div className={'row justify-content-center'} style={{marginTop: '3vh'}}>
            <div className={'col'}>

              <div className="card auth-card">
                <div className="card-body light-color text-center">

                  <h3>{t("Welcome to secure sharing.")}</h3>
                  <p className="card-text">{t("We just need you to confirm one thing:")}</p>

                  <ReCAPTCHA
                    style={{display: "inline-block"}}
                    ref={recaptchaRef}
                    sitekey={config.automatedTesting ? c.google.always_pass_recaptcha_key : c.google.captcha_key}
                    onChange={this.onCaptchaFinish.bind(this)}
                  />

                  {showCaptchaError &&
                  <p className={'mt-3'}>
                    {t("There was a problem confirming your account. Please try again.")}
                  </p>
                  }

                  <p className="card-text mt-5" style={{fontSize: '14px'}}>
                    {t("If you’re having problems confirming your account, contact")} <a href={c.links.mailToSupport}
                                                                                  className="hover-underline light-color">{t("support@verifyle.com")}</a>.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { t } = this.props;
    let { loading, cfmErr, isReset, resendError, resending, needCaptcha } = this.state;

    if(needCaptcha){
      return this.doCaptcha();
    }

    let content = null;
    if(loading || !cfmErr){
      content = <Loading centered size={'sm'} />
    }
    else {

      let title = null;
      let message = null;
      let btnText = null;
      let showResendBtn = false;
      if (isReset) {
        showResendBtn = true;
        title = t("Unable to reset password")
        message = t("Click the button below to resend your password reset email");
        btnText = t("Reset Password");
      }
      else {
        if(cfmErr && cfmErr.code && cfmErr.code === 111){
          showResendBtn = false;
          title = t("Unable to activate account")
          message = t("Your account is already active.  If you've forgotten your password, please go to login and select \"Forgot Password?\".");
          btnText = t("Send New Email");
        }
        else{
          showResendBtn = true;
          title = t("Unable to confirm account")
          message = t("It looks like your activation email may have expired. Click below to have a new one sent.");
          btnText = t("Send New Email");
        }
      }

      content = <div className="card auth-card">
        <div className="card-body light-color">
          <h3>{title}</h3>
          <p className="card-text">{message}</p>
          <hr className={'light-border'} />
          <div className="row">
            <div className="col text-center">
              <Button disabled={resending}
                      onClick={this.goToLogin.bind(this)}
                      className="btn btn-link light-color">{t("Back")}</Button>
              {showResendBtn &&
              <Button disabled={resending}
                      onClick={this.resendClick.bind(this)}
                      className="btn btn-link light-color light-color">{btnText}</Button>
              }
            </div>
          </div>
        </div>
        {resendError && <div>
          <div className="alert alert-danger" role="alert">
            {resendError}
          </div>
        </div>
        }
      </div>
    }

    return (
      <div className={'celestial-bg'}>
        <div className="container">
          <div className={'row justify-content-center'}>
            <div className={'auth-header-img col'}>
              <Image src={logo}
                     className={'mx-auto d-block'}
                     imgHeight={90}
                     alt={t('logo')}/>
            </div>
          </div>
          <div className={'row justify-content-center'} style={{marginTop: '3vh'}}>
            <div className={'col-sm-6 col-lg-4'}>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    email : state.auth.email,
    code : state.auth.code
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    setStep: step => dispatch(authActions.setNextStep(step)),
    setTFAAuth: auth => dispatch(authActions.setTFAAuth(auth)),
    alert: (title, msg, cb) => dispatch(modalActions.showAlert(title, msg, cb))
  };
};
export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(Confirm));
