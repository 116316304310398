import React, {Component} from 'react';
import {connect} from "react-redux";
import log from "../../util/log";
import PropTypes from 'prop-types';
import sapi from "../../util/sapi";
import _ from 'lodash';
import Button from '../partials/elements/Button';
import colors from "../../util/colors";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement, } from '@stripe/react-stripe-js';
import Promise from "bluebird";
import ValidationErrors from "../partials/components/ValidationErrors";
import filters from "../../helpers/filters";
import { getErrorMessage, getMessageForError} from "../../util/errors";
import sharedActions from "../../actions/shared-actions";
import accountActions from "../../actions/account-actions";
import {BiCheck} from "react-icons/all";
import modalActions from "../../actions/modal-actions";
import utils from "../../util/util";
import api from "../../util/api";
import stripeHelper from "../../util/stripe-helper";
import Loading from "../partials/util/Loading";
import {withVFTranslation} from "../../util/withVFTranslation";
import moment from "moment";
import c from "../../util/const";
import {Carousel} from "react-responsive-carousel";
import ExpandableRow from "../partials/components/ExpandableRow";
import {Motion, spring} from '@serprex/react-motion';
import Slider from "react-slick";

class UpgradeDialogNew extends Component {

  BASIC_PLAN_ID = 'basic-plan';
  VF_PRO_PLAN_ID = 'vf-pro-plan';

  static MONTHLY_TERM_SECONDS = (60 * 60 * 24 * 30);
  static YEARLY_TERM_SECONDS = (60 * 60 * 24 * 365);

  SCROLL_BUTTON_WIDTH = 44;

  //This just came from me measuring a plan in the upgrade window via a browser.
  //some default distance to scroll if we can't find a width for some reason.
  DEFAULT_SCROLL_BY_WIDTH = 376;

  _isMounted = false;

  STEPS = {
    SELECT_PLAN : 'select_plan',
    CONFIRM_PLAN : 'confirm_plan'
  }

  planRefs = [];

  constructor(props) {
    super(props);

    this.planScrollPanelWrapRef = React.createRef();
    this.sliderRef = React.createRef();
    this.planSlideDestinationRef = React.createRef();

    this.state = {
      loading : false,
      stripePromise : null,
      validationErr : [],

      hasSomeCardInput : false,
      paymentWasSuccessful : false,

      selectedPlanBounds : null,
      planScrollContainerBounds : null,
      destinationBounds : null,
      doneAnimatingCard : false,

      windowStep : this.STEPS.SELECT_PLAN,
      selectedPlanId : null,
      selectedUIPlan : null,

      showMonthlyPlans : true,
      allAvailablePlans : null,
      monthlyPlans : null,
      yearlyPlans : null,
      classInfo : null,

      isHandlingSubmitButtonClick : false,

      waitForClassInfoToBeReady : false,

      isFetchingThings : true,
      limitMessage : null,

      downgradeInputVal: '',
      promo_code: '',
      valid_code_deets: null,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.props.onRef){
      this.props.onRef(this)
    }

    this.loadWindow();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  loadWindow(){
    let { t } = this.props;
    this.setState({isFetchingThings : true})
    this.loadNeededDataIntoState()
      .then(() => {
        return this.loadPlans();
      })
      .then(() => {

        this.setState({
          limitMessage : this.props.upgradeType ? this.getUpgradeMessage() : null,
          isFetchingThings : false
        }, () => {
          setTimeout(() => {
            this.scrollToCurrentPlan();
          }, 500)
        })
      })
      .catch((err) => {
        log.log('error loading upgrade dlg', err);
        this.props.showAlert(t("Error loading Subscription"), getMessageForError(err, t), () => {
          this.closeModal(false);
        });
      })
  }

  scrollToCurrentPlan(){
    let {
      selectedPlanId
    } = this.state;
    let {
      accountInfo
    } = this.props;

    this.scrollToPlan(selectedPlanId || +accountInfo.class_id);
  }

  getCurrentInstId(){
    let {
      accountClassInfo
    } = this.props;
    let currentInstId = _.get(accountClassInfo, 'class_info.inst_id', null);
    if(currentInstId === c.BLANK_INST_ID){
      currentInstId = null;
    }
    return currentInstId;
  }

  loadNeededDataIntoState(){
    return new Promise((resolve, reject) => {

      let currentInstId = this.getCurrentInstId();
      Promise.all([
          sapi.Stripe.availablePlans(),
          api.ClassInfo.list(currentInstId)
        ])
        .then(data => {
          let {
            accountClassInfo
          } = this.props;
          let termLength = _.get(accountClassInfo, 'class_info.term_length', 0);
          let startYearly = termLength === UpgradeDialogNew.YEARLY_TERM_SECONDS;

          let upgradePlans = data[0].data;
          this.setState({
            showMonthlyPlans : !startYearly,
            upgradePlans : upgradePlans,
            classInfo : data[1].data,
            stripePromise : loadStripe(upgradePlans.pub_key)
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  loadPlans() {
    return new Promise((resolve, reject) => {
      let {
        classInfo
      } = this.state;
      let {
        accountClassInfo,
        stripeAvailables
      } = this.props;

      //certain inst-related payment plans come in via the current and upgrade blocks.
      //If these exist, and we don't have them in class_info, then we should fetch them explicitly
      //and put those in with the normal plans.

      //EXTRA!!!! walk through next_class_id - make sure this 'nextness' is accounted for when ordering
      let currentBlock = _.get(accountClassInfo, 'class_info', {});
      let upgradeBlock = _.get(stripeAvailables, 'class_info', {});

      log.log('classInfo about to load', currentBlock, upgradeBlock, classInfo);
      let classIdsToFetch = [];
      if (currentBlock && !_.isEmpty(currentBlock) && !_.find(classInfo, (info) => +info.class_id === +currentBlock.class_id)) {
        classIdsToFetch.push(currentBlock.class_id);
        if(currentBlock.next_class_id && !_.find(classInfo, (info) => +info.class_id === +currentBlock.next_class_id)){
          classIdsToFetch.push(currentBlock.next_class_id);
        }
      }
      if (upgradeBlock && !_.isEmpty(upgradeBlock) && !_.find(classInfo, (info) => +info.class_id === +upgradeBlock.class_id)) {
        classIdsToFetch.push(upgradeBlock.class_id);
        if(upgradeBlock.next_class_id && !_.find(classInfo, (info) => +info.class_id === +upgradeBlock.next_class_id)){
          classIdsToFetch.push(upgradeBlock.next_class_id);
        }
      }

      //This is needed because we add class_id and next_class_id above.  In some configurations
      //these can point to each other.
      classIdsToFetch = _.uniq(classIdsToFetch);

      //hey now.  don't be gettin too fancy
      Promise.all(_.map(classIdsToFetch, (class_id) => api.ClassInfo.get(class_id)))
        .then((res) => {
          log.log('classInfo loaded', classIdsToFetch, res);

          let availablePlans = [];
          _.each(classInfo, (info) => {
            availablePlans.push(info);
          })
          //Add to end of list.
          _.each(res, (r) => {
            let ci = _.get(r, 'data.class_info');
            if(ci) {
              availablePlans.push(ci);
            }
          })

          let allAvailablePlans = _.sortBy(availablePlans, (p) => +p.term_cost);
          let monthlyPlans = [];
          let yearlyPlans = [];

          _.each(allAvailablePlans, (plan) => {
            //Find any plans with duplicate stripe_prod_id, but different class_id
            let foundDup = _.find(allAvailablePlans, (p) =>
              p.class_id !== plan.class_id &&
              p.stripe_prod_id &&
              p.stripe_prod_id === plan.stripe_prod_id
            );

            //If you found a duplicate, add it to the right place.
            //If you don't have a duplicate, then it's unique, and should show in both places.
            if(foundDup){
              log.log('dup right here', foundDup, plan)
              if(plan['term_length'] === UpgradeDialogNew.YEARLY_TERM_SECONDS){
                yearlyPlans.push(plan);
              }
              else{
                monthlyPlans.push(plan);
              }
            }
            else{
              //If there is no dup according to the rules above, we want to
              //display a special message for this plan because it doesn't have multiple
              //timeframe options available.  It's just monthly.
              plan._showNoAnnualPlan = true;

              monthlyPlans.push(plan);
              yearlyPlans.push(plan);
            }
          })

          log.log('loaded plans', monthlyPlans, yearlyPlans);
          this.setState({
            allAvailablePlans,
            monthlyPlans,
            yearlyPlans
          }, () => {
            resolve(availablePlans);
          })
        })
        .catch((err) => {
          log.log('error loading class infos', err);
          reject(err);
        })
    })
  }

  getUpgradeMessage(){
    let { upgradeType, t } = this.props;

    if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.WORKSPACE){
      return t("You've reached your limit on Workspaces.  Upgrade now to increase your limits.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.DOCS){
      return t("You've reached your limit on Documents for this thread.  Upgrade now to increase your limits.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.STORAGE){
      return t("You've reached your limit on storage.  Upgrade now to increase your limits.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.SIGNING){
      return t("Your plan doesn't allow you to request signatures.  Upgrade now to enable signing.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.SIGNING_V1){
      return t("Your plan doesn't allow you to annotate Documents.  Upgrade now to enable Document annotation.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.MAX_V2_SIGNING_LIMIT){
      return t("You've used up your free signature requests.  Please upgrade to continue.")
    }
    else if(upgradeType === UpgradeDialogNew.UPGRADE_TYPES.MERCHANT_INVOICING){
      return t("Invoicing is not included with your plan. Please upgrade to continue.")
    }
  }

  closeModal(res) {
    let {close} = this.props;

    log.log('stripe checkout close modal', res);
    close(res);
  }

  updateAccountData() {
    return Promise.all([
      this.props.updateAccountInfo(true),
      this.props.updateStripeAvailables(),
      this.props.updateStripeData(),
      this.props.updateLogo()
    ])
  }

  pollAvailablePlansUntilReadyForSubscribing(){
    if(!this._isMounted){
      return;
    }

    this.setState({
      waitForClassInfoToBeReady : true
    })
    let pollAvailablePlans = () => {
      return new Promise((resolve, reject) => {
        sapi.Stripe.availablePlans()
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            resolve(err);
          })
      })
    }

    return pollAvailablePlans()
      .then((res) => {
        let upgradePlans = res;
        let classInfo = _.get(upgradePlans, 'class_info');
        if(!classInfo || _.isEmpty(classInfo)){
          return utils.waitFor(500)
            .then(() => {
              return this.pollAvailablePlansUntilReadyForSubscribing();
            })
        }
        else{
          this.setState({
            upgradePlans : res,
            waitForClassInfoToBeReady : false
          })
          return true;
        }
      })
  }

  cancelSubscription() {
    let {
      t
    } = this.props;

    this.setState({loading:true})
    sapi.Stripe.unsubscribe()
      .then((res) => {
        log.log('cancel res', res);
        return this.onSubscribeSuccess(res);
      })
      .then(() => {
        this.setState({
          loading:false,
          isHandlingSubmitButtonClick : false
        })
      })
      .catch((err) => {
        log.log('error canceling', err);
        this.setState({loading:false})
        this.props.showAlert(t("Error cancelling subscription"), getMessageForError(err, t));
      })
  }

  onSubscribeSuccess(res){
    log.log('subscribe success', res);
    return new Promise((resolve, reject) => {
      this.setState({
        paymentWasSuccessful : true
      }, () => {
        //Kind of weird looking!  We want to set a flag to show success, and show it for
        //at least 3 seconds.  Might as well do work while we're waiting, so let's
        //update account data in parallel to that wait by waiting for both promises here.
        //Then close the window.
        Promise.all([
          utils.waitFor(3000),
          this.updateAccountData()
        ])
          .finally(() => {
            this.closeModal(res);
            resolve(true);
          })
      })
    })
  }

  onSubscribeFailure(err){
    let { t } = this.props;
    log.log('subscribe error', err);

    this.props.showAlert(t("Error processing card"), stripeHelper.getMessageForStripeOrVfError(err, t));

    return this.updateAccountData()
      .then((res) => {

        //In payment failure cases, we need to refresh stripe/2/avail, and detect
        //The case where class_info has been nulled.  If it has, we need to wait for it to repopulate.
        //Until this time, the backend is not ready to subscribe.
        //This is really just a problem for stripe 3d auth failure cases.  In that case
        //we know about the failure before the backend does, which means we need to wait for it.
        //bug 2704
        let { upgradePlans } = this.state;
        let classInfo = _.get(upgradePlans, 'class_info');
        log.log('after update account data', upgradePlans, classInfo);
        if(!classInfo || _.isEmpty(classInfo)){
          log.log('doing polling thing')
          this.pollAvailablePlansUntilReadyForSubscribing();
        }
      })
  }

  onSuccessfulPaymentMethod(paymentMethodId, toClassId, couponId, stripe){
    log.log('onSuccessfulPaymentMethod', arguments);
    let { t } = this.props;

    sapi.Stripe.subscribeV2(paymentMethodId, toClassId, couponId)
      .then((res) => {
        log.log('subscribe res', res);
        return this.onSubscribeSuccess(res);
      })
      .then(() => {
        this.setState({
          loading:false,
          isHandlingSubmitButtonClick : false
        })
      })
      .catch((err) => {
        let stripeClientSecret = _.get(err, 'body.data.client_secret');
        if(err && err.name === 'APP_PARTIAL_FAIL' && stripeClientSecret){
          log.warn('got partial fail', err);
          stripeHelper.handleStripe3DAuth(stripe, stripeClientSecret, paymentMethodId, t)
            .then((res) => {
              //upgrade is finished as soon as 3d auth returns.
              return this.onSubscribeSuccess(res);
            })
            .catch((err) => {
              this.onSubscribeFailure(err);
            })
            .finally(() => {
              this.setState({
                loading:false,
                isHandlingSubmitButtonClick : false
              })
            })
        }
        else{
          this.setState({
            loading:false,
            isHandlingSubmitButtonClick : false
          })
          this.onSubscribeFailure(err);
        }
      })
  }

  onSubmitButtonClick(stripe, elements, evt){
    evt.preventDefault();

    let { t } = this.props;
    if(this.state.isHandlingSubmitButtonClick){
      log.log('prevent stripe submit');
      return;
    }

    if(this.state.waitForClassInfoToBeReady){
      this.props.showAlert(t("Just a moment"), t("Sorry, we're updating our records. Please wait a moment and try again."));
      return;
    }

    this.setState({isHandlingSubmitButtonClick : true});

    let { postal } = this.state;
    stripe.createPaymentMethod({
      type: 'card',

      //This is a weird parameter, and is NOT documented in the stripe docs.
      //You don't need to pass the rest of the card elements into the card.
      //You just give stripe the first element and it will apparently detect the rest.  Weird guys.
      //https://github.com/stripe/react-stripe-elements/issues/482
      // card: elements.getElement(CardNumberElement),
      card: elements.getElement(CardElement),

      //This is an expected stripe field
      //We could pass more if we wanted.
      //https://stripe.com/docs/js/payment_methods/create_payment_method
      // billing_details: {
      //   address: {
      //     postal_code: postal,
      //   },
      // },
    })
      .then((res) => {
        const {error, paymentMethod} = res;
        log.log('payment method res', error, paymentMethod);

        if(error && !_.isEmpty(error)){
          // code: "invalid_number"
          // message: "Your card number is invalid."
          // type: "validation_error"
          this.setState({
            validationErr : [error.message],
            isHandlingSubmitButtonClick : false
          })
        }
        else if(!paymentMethod || !paymentMethod.id){
          //I'm not sure this is a real case.  I guarded against it because the docs did.
          this.setState({
            validationErr : [getErrorMessage("APP_UNKNOWN_ERROR", t)],
            isHandlingSubmitButtonClick : false
          })
        }
        else{
          this.setState({
            validationErr : [],
            loading:true
          });
          let {valid_code_deets, selectedPlanId} = this.state;
          let couponId = _.get(valid_code_deets, 'coupon.id');
          this.onSuccessfulPaymentMethod(paymentMethod.id, selectedPlanId, couponId, stripe)
        }
      })

  }

  selectPlan(selectedPlanId, uiPlan){
    let scrollPanelBounds = this.getPlanScrollPanelBounds();
    let selectedPlanBounds = this.getSelectedPlanBounds(selectedPlanId);
    // log.log('select plan here', selectedPlanId, uiPlan, scrollPanelBounds, selectedPlanBounds);
    this.setState({
      selectedPlanId,
      selectedUIPlan : uiPlan,

      doneAnimatingCard : false,
      selectedPlanBounds : selectedPlanBounds,
      planScrollContainerBounds : scrollPanelBounds,
    }, () => {
      this.setState({
        windowStep : this.STEPS.CONFIRM_PLAN
      })
    })
  }

  getPlanScrollPanelBounds(){
    let ref = _.get(this.planScrollPanelWrapRef, 'current')
    if(ref){
      let bounds = ref.getBoundingClientRect();
      return bounds;
    }
  }

  getSelectedPlanBounds(id){
    let res = null;
    let found = _.find(this.planRefs, (obj) => id === obj.id);
    if(found){
      //log.log('found selected plan ref', found.ref, found.ref.current, found.ref.offsetLeft);
      res = found.ref.getBoundingClientRect();
    }
    return res;
  }

  scrollToPlan(class_id){
    let {
      showMonthlyPlans,
      monthlyPlans,
      yearlyPlans
    } = this.state;

    let slideIndex = -1;
    if(showMonthlyPlans){
      slideIndex = _.findIndex(monthlyPlans, (p) => +p.class_id === +class_id);
    }
    else{
      slideIndex = _.findIndex(yearlyPlans, (p) => +p.class_id === +class_id);
    }

    if(slideIndex >= 0) {
      this.sliderRef.slickGoTo(slideIndex);
    }
  }

  onPlanRef(id, ref){
    let foundIndex = _.findIndex(this.planRefs, (plan) => plan && plan.id === id);
    if(foundIndex >= 0){
      this.planRefs[foundIndex] = {id, ref};
    }
    else{
      this.planRefs.push({
        id, ref
      })
    }
  }

  getPlanPointForMerchantLink(availablePlan){
    let { t } = this.props;
    let merchantFeeCents = availablePlan.merchant_fee_flat * 100;
    return {
      isMerchantLink : true,
      text : t("Request and Receive Payments for "),
      linkText : filters.getCurrency(this.props.i18n.language, merchantFeeCents) + t(" / transaction"),
      onLinkClick : (p) => {
        this.props.showAuthInfo(
          t('Verifyle Payment Requests'),
          <div>
            <div>
              <p>
                {t("Verifyle Platinum users pay no transaction fee to Verifyle. All other Verifyle subscribers pay a small flat transaction fee depending on their plan. Additional external processing fees apply. Details are available at stripe.com/pricing.")}
              </p>
            </div>
          </div>
        )
      }
    }
  }

  renderPlanPanel(){
    let {
      isFetchingThings,
      showMonthlyPlans,
      monthlyPlans,
      yearlyPlans
    } = this.state;
    let {
      t
    } = this.props;

    if(isFetchingThings){
      return (
        <div className="pb-3">
          <div className="row">
            <div className="col">
              <Loading className={'m-5'} centered={true} size={'sm'}/>
            </div>
          </div>
        </div>
      )
    }

    let availablePlans = showMonthlyPlans ? monthlyPlans : yearlyPlans;

    let plans = [];
    _.each(availablePlans, (availablePlan) => {
      let termCostCents = +(availablePlan['term_cost']) * 100;
      let termLengthDays = availablePlan['term_length'] / (60 * 60 * 24);

      //if we're yearly, force monthly display
      if(availablePlan['term_length'] === UpgradeDialogNew.YEARLY_TERM_SECONDS){
        termCostCents = termCostCents / 12;
        termLengthDays = 30;
      }

      let digitalSignatureLine = t("Unlimited Digital Signatures");
      if(_.isNumber(availablePlan['sign_req_max'])){
        digitalSignatureLine = t("Includes ") + availablePlan['sign_req_max'] + t(" Free Digital Signatures");
      }

      let showPortalPoint = _.get(availablePlan, 'handle_flag', false);
      let merchant_flag = _.get(availablePlan, 'merchant_flag', false);

      if(c.isFreeTier(availablePlan.class_id)){
        let points = [
          filters.getFileSizeString(t, +availablePlan['max_storage']) + t(" of Encrypted Storage"),
          t("Share Large Files"),
          t("Host up to ") + availablePlan['forum_max_total'] + t(" Workspaces"),
          t("Up to ") + availablePlan['chat_max_doc'] + t(" Documents per Workspace Thread"),
          digitalSignatureLine
        ]

        if(merchant_flag){
          points.push(this.getPlanPointForMerchantLink(availablePlan));
        }

        plans.push({
          id : +availablePlan.class_id,
          title : availablePlan.label,
          termCostCents,
          termCost : filters.getCurrency(this.props.i18n.language, termCostCents, true),
          term : t('forever'),
          points
        })
      }
      else{
        let points = [
          filters.getFileSizeString(t, +availablePlan['max_storage']) + t(" of Encrypted Storage"),
          t("Share Large Files"),
          t("Host up to ") + availablePlan['forum_max_total'] + t(" Workspaces"),
          t("Up to ") + availablePlan['chat_max_doc'] + t(" Documents per Workspace Thread"),
          digitalSignatureLine,
          t("Unlimited SMS Signer Verification"),
          t("Unlimited PDF Editing"),
          t("Display Your Logo")
        ]

        if(showPortalPoint){
          points.push(t("Custom Public Upload Page"))
        }

        if(merchant_flag){
          points.push(this.getPlanPointForMerchantLink(availablePlan));
        }

        plans.push({
          id : +availablePlan.class_id,
          title : availablePlan.label,
          termCostCents,
          showNoAnnualPlanWarning : availablePlan._showNoAnnualPlan,
          termCost : filters.getCurrency(this.props.i18n.language, termCostCents, true),
          term : filters.getFriendlyTermLength(t, termLengthDays),
          points
        })
      }
    })

    return this.renderCarousel(plans);
  }

  renderCarousel(plans){
    const buttonWrapStyle = {
      position: 'absolute',
      top: '40%',
      zIndex: 1
    }

    const buttonStyle = {
      height: '90px',
      width: '90px',
      borderRadius: '50%'
    }

    const iconStyle = {
      fontSize: '60px',
      lineHeight: 'normal'
    }

    const RenderPrevButton = (props) => {
      const { className, style, onClick } = props;

      let isDisabled = (className || "").indexOf("slick-disabled") >= 0;
      return (
        <div style={{...style, ...buttonWrapStyle, ...{left: '10px'}}} >
          <button onClick={onClick}
                  style={buttonStyle}
                  disabled={isDisabled}
                  className={"btn btn-icon btn-primary vf-upgrade-panel-btn"}>
            <i style={{...iconStyle, ...{paddingRight: '5px'}}} className="icon ion-chevron-left" />
          </button>
        </div>
      )
    }

    const RenderNextButton = (props) => {
      const { className, style, onClick } = props;
      let isDisabled = (className || "").indexOf("slick-disabled") >= 0;
      return (
        <div style={{...style,...buttonWrapStyle, ...{right: '10px'}}} >
          <button onClick={onClick}
                  style={buttonStyle}
                  disabled={isDisabled}
                  className={"btn btn-icon light-color btn-primary vf-upgrade-panel-btn"}>
            <i style={{...iconStyle, ...{}}} className="icon ion-chevron-right" />
          </button>
        </div>
      );
    }

    let carouselSettings = {
      className: "center",
      centerMode: true,
      infinite: false,
      centerPadding: "60px",
      dots: true,
      variableWidth: true,
      speed: 500,
      nextArrow:  <RenderNextButton />,
      prevArrow: <RenderPrevButton />,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      <div className="pb-3">
        <div className="slider-container" ref={this.planScrollPanelWrapRef}>
          <Slider {...carouselSettings}
                  ref={slider => this.sliderRef = slider}>
            {_.map(plans, (plan) => {
              return (
                <div key={plan.id} ref={(ref) => this.onPlanRef(plan.id, ref)}>
                  {this.renderPlan(plan)}
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }

  renderPaymentCard(){
    let {
      selectedUIPlan
    } = this.state;
    let { t } = this.props;
    return (
      <div className="card d-inline-block mx-1"
           style={{
             width: '23rem',
             height: PLAN_CARD_HEIGHT,
             borderRadius: '8px',
           }}>
        <div>
          <div className="text-center light-bg"
               style={{
                 borderTopLeftRadius: '8px',
                 borderTopRightRadius: '8px'
               }}>
            <h3 className="black-color py-3">
              {selectedUIPlan.termCostCents > 0 ? t("Payment Method") : ''}
            </h3>
          </div>

          {selectedUIPlan.termCostCents > 0 &&
          <div>
            <div className="mx-2" style={{marginTop: '46px'}}>
              {this.renderPaymentPanel()}
            </div>

            <div className="mx-2 mt-5">
              {this.renderPromoCodePanel()}
            </div>
          </div>
          }
          {selectedUIPlan.termCostCents === 0 &&
          <div>
            <div className="mx-2" style={{marginTop: '46px'}}>
              {this.renderFreePaymentPanel()}
            </div>
          </div>
          }
        </div>
      </div>
    )
  }

  leaveConfirmPanel(){
    this.setState({
      windowStep: this.STEPS.SELECT_PLAN,
      selectedPlanId: null,
      selectedUIPlan : null,

      validationErr : [],
      promo_code: '',
      valid_code_deets: null,

      selectedPlanBounds : null,
      planScrollContainerBounds : null,
      doneAnimatingCard : false,
      destinationBounds : null,
    }, () => {
      setTimeout(() => {
        this.scrollToCurrentPlan();
      }, 500)
    })
  }

  renderConfirmPanel(){
    let {
      t
    } = this.props;
    let {
      selectedUIPlan,

      selectedPlanBounds,
      planScrollContainerBounds,
      doneAnimatingCard,

      destinationBounds
    } = this.state;

    //This is a little complicated in here due to the animations.
    //When you select a plan, we get the plan card's bounding rectangle, as well as the scroll panel bounding rectangle.
    //I show an invisible placeholder for the plan card destination because the destination layout is flex and I don't know the exact position.
    //I show that invisible placeholder in flex, and pull it's coords once it's rendered.  This affects layout of the payment card too.
    //Once we have all those pieces, I transition a new card from where it was in the list, to where it should be
    //over the top of the invisible placeholder card, and fade in the payment card.

    let cardSpacing = 15; //px padding between cards, not accounted for with client rect.
    let leftRightPanelMargin = 0; //If you add window margins, account for it here.  We don't have it anymore but used to.
    let startX = selectedPlanBounds.left - planScrollContainerBounds.left;
    let endX = destinationBounds ? (destinationBounds.left - planScrollContainerBounds.left - cardSpacing + leftRightPanelMargin) : 0;

    //This weird padding here is to replicate the amount of space that the slick carousel dots take up,
    //so that the window does not resize.  There's probably a better solution but some of the layout here is tricky.
    return (
      <div style={{paddingBottom: '16px'}}>
        <div className="d-flex justify-content-center position-relative">

          {destinationBounds && !doneAnimatingCard &&
          <Motion onRest={() => this.setState({doneAnimatingCard : true})}
                  defaultStyle={{left: startX}}
                  style={{left: spring(endX, {stiffness: 150, damping: 15})}}>
            {interpolatingStyle => {
              return (
                <div style={{
                  ...interpolatingStyle,
                  ...{
                    position: 'absolute',
                    top: 0,
                    zIndex: 1000
                  }
                }}>
                  {this.renderPlan(selectedUIPlan, true)}
                </div>
              )
            }}
          </Motion>
          }

          {doneAnimatingCard &&
          <div style={{
            height: selectedPlanBounds.height,
            width: selectedPlanBounds.width
          }}>
            {this.renderPlan(selectedUIPlan, true)}
          </div>
          }
          {!doneAnimatingCard &&
          <div ref={(ref) => {
            if (ref && !this.state.destinationBounds) {
              this.setState({
                destinationBounds: ref.getBoundingClientRect()
              })
            }
          }} style={{
            height: selectedPlanBounds.height,
            width: selectedPlanBounds.width
          }}/>
          }

          <Motion defaultStyle={{opacity: -1}} style={{opacity: spring(1)}}>
            {interpolatingStyle => {
              return (
                <div style={interpolatingStyle}>
                  {this.renderPaymentCard()}
                </div>
              )
            }}
          </Motion>
        </div>
      </div>
    )
  }

  renderPlan(plan, isConfirmView){
    let {
      selectedPlanId,
      paymentWasSuccessful,
      loading
    } = this.state;
    let {
      accountInfo,
      t
    } = this.props;

    let isCurrentPlan = plan.id === +accountInfo.class_id;

    return (
      <>
        <div className={`card d-inline-block mx-1 corp-light-grey-bg upgrade-plan-panel`}
             onClick={() => isConfirmView ? _.noop : this.scrollToPlan(plan.id)}
             style={{
               width: '23rem',
               height: PLAN_CARD_HEIGHT,
               borderRadius: '8px',
               color: colors.PRIMARY,
               textAlign: 'center'
             }}>
          <div>
            <div className="text-center light-bg"
                 style={{
                   borderTopLeftRadius: '8px',
                   borderTopRightRadius: '8px'
                 }}>
              <h3 className="black-color py-3">
                {plan.title}
              </h3>
            </div>
            <div>
              <div className="text-center pt-2">
                {plan.termCostCents === 0 &&
                <h2 className="text-uppercase mb-0">
                  {t("Free")}
                </h2>
                }
                {plan.termCostCents > 0 &&
                <h2 className="mb-0">
                  {plan.termCost}
                  <span className="small pl-2">
                    /{plan.term}
                  </span>
                </h2>
                }
                {plan.showNoAnnualPlanWarning &&
                  <div className="secondary-text-color" style={{lineHeight: '24px'}}>
                    {t("Annual Billing Unavailable")}
                  </div>
                }
                {!plan.showNoAnnualPlanWarning &&
                  <div className="secondary-text-color" style={{minHeight: '24px'}}/>
                }
              </div>

              {isConfirmView &&
              <div className="text-center py-2 mx-2">
                <button className={`btn w-100 btn-primary`}
                        type="button"
                        disabled={paymentWasSuccessful || loading}
                        onClick={() => this.leaveConfirmPanel()}>
                  <i className={'ion-chevron-left mr-2'}/>
                  {t("Back to Plan Options")}
                </button>
              </div>
              }
              {!isConfirmView &&
              <div className="text-center py-2 mx-2">
                <button className={`btn w-100 ${isCurrentPlan ? 'btn-outline-primary' : 'btn-primary'}`}
                        type="button"
                        onClick={() => this.selectPlan(plan.id, plan)}
                        disabled={isCurrentPlan}>
                  {isCurrentPlan && <i className={'ion-checkmark-circled mr-2'}/> }
                  {isCurrentPlan ? t("Current Plan") : t("Select Plan")}
                </button>
              </div>
              }

              <div className="text-left px-2" style={{height: '13rem', marginLeft: '40px'}}>
                <ul className="list-group list-group-flush black-color my-1"
                    style={{
                      fontSize: '14px'
                    }}>
                  {_.map(plan.points, (p) => {

                    let pointContents = p;
                    if(p.isMerchantLink){
                      pointContents = <span>
                        {p.text}
                        <a className="primary-color has-pointer" onClick={() => p.onLinkClick(p)}>
                          {p.linkText}
                        </a>
                      </span>
                    }

                    return (
                      <li key={p.isMerchantLink ? p.linkText : p}
                          style={{
                            paddingTop: '14px',
                            paddingBottom: '14px',
                            lineHeight: '16px',
                            listStyle: 'disc'
                          }}>
                        {pointContents}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderPayButton(){
    let {
      valid_code_deets,
      allAvailablePlans,
      selectedPlanId,
      loading,
      isHandlingSubmitButtonClick,
      paymentWasSuccessful,
      hasSomeCardInput
    } = this.state;
    let { t } = this.props;

    if(paymentWasSuccessful){
      return (
        <button className={`btn w-100 btn-success`}
                onClick={_.noop}
                disabled={true}
                type="button">
          <i className="icon ion-checkmark-circled light-color mr-2" />
          {t("Success!")}
        </button>
      )
    }
    else if(loading){
      return (
        <button className={`btn w-100 btn-primary`}
                disabled={true}
                type="submit">
          <Loading centered={true}
                   color="light"
                   size={'sm'}/>
        </button>
      )
    }
    else{
      let selectedPlan = _.find(allAvailablePlans, (plan) => +plan.class_id === selectedPlanId)
      let termCostCents = 0;
      if(selectedPlan){
        termCostCents = +(selectedPlan['term_cost']) * 100;
      }
      if(valid_code_deets){
        termCostCents = valid_code_deets.price * 100;
      }

      return (
        <button className={`btn w-100 btn-primary`}
                disabled={isHandlingSubmitButtonClick || loading || !selectedPlanId || !hasSomeCardInput}
                type="submit">
          {t("Pay")} {filters.getDisplayCurrency(this.props.i18n.language, termCostCents)}
        </button>
      )
    }
  }

  doFreePlanValidation(){
    let { t } = this.props;
    let {
      downgradeInputVal
    } = this.state;
    let err = [];

    let formatted = _.trim(downgradeInputVal.toLowerCase());
    if(formatted.length === 0 || formatted !== t("downgrade")){
      err.push(t("Please type \'DOWNGRADE\'"));
    }

    this.setState({validationErr:err})
    return err.length === 0;
  }

  confirmFreePlanClick(){
    if(!this.doFreePlanValidation()){
      return;
    }

    this.cancelSubscription();
  }

  renderFreePayButton(){
    let {
      selectedPlanId,
      loading,
      isHandlingSubmitButtonClick,
      paymentWasSuccessful
    } = this.state;
    let { t } = this.props;

    if(paymentWasSuccessful){
      return (
        <button className={`btn w-100 btn-success`}
                onClick={_.noop}
                disabled={true}
                type="button">
          <i className="icon ion-checkmark-circled light-color mr-2" />
          {t("Success!")}
        </button>
      )
    }
    else if(loading){
      return (
        <button className={`btn w-100 btn-primary`}
                disabled={true}
                type="submit">
          <Loading centered={true}
                   color="light"
                   size={'sm'}/>
        </button>
      )
    }
    else{
      return (
        <button className={`btn w-100 btn-primary`}
                type="button"
                onClick={this.confirmFreePlanClick.bind(this)}
                disabled={isHandlingSubmitButtonClick || loading || !selectedPlanId}>
          {t("Confirm")}
        </button>
      )
    }
  }

  onCardChange(evt){
    this.setState({
      hasSomeCardInput : !_.get(evt, 'empty', true),
      validationErr: []
    })
  }

  renderPaymentPanel(){
    let {
      paymentWasSuccessful,
      loading
    } = this.state;
    let { t } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col p-0">

            <div className="form-group">
              <label className="form-label font-weight-bold w-100 text-left dark-color">{t("Credit Card Information")}</label>
              <CardElement onChange={(evt) => this.onCardChange(evt)}
                           className="form-control"
                           options={{
                             disabled: (paymentWasSuccessful || loading),
                             iconStyle: 'solid',
                             style: {
                               base: {
                                 fontSize: '16px',
                                 lineHeight: '1.5',
                                 color: colors.DARK,
                                 '::placeholder': {
                                   color: colors.SECONDARY_TEXT,
                                 },
                               },
                               invalid: {
                                 color: colors.ASSERTIVE,
                               },
                             },
                           }}/>
            </div>

            <div className="text-center my-2">
              {this.renderPayButton()}
            </div>

            {this.state.validationErr.length > 0 &&
            <div className="my-2">
              <ValidationErrors errors={this.state.validationErr}/>
            </div>
            }

          </div>
        </div>
      </div>
    )
  }

  renderFreePaymentPanel(){
    let { t } = this.props;
    let {
      downgradeInputVal
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col p-0">

            <h4 className="text-uppercase text-center text-danger my-2">
              {t("Warning:")}
            </h4>
            <p className="text-center mb-4">
              {t("Downgrading your Verifyle account to Verifyle Basic will mean your account will be limited in storage, features and functionality, which may have an impact on your Contacts’ ability to access information you’ve previously shared with them as well as their ability to share information with you.")}
            </p>
            <p className="text-center mb-2">
              {t("To confirm you’d like to downgrade your Verifyle account, please type DOWNGRADE in the field below, and then click the blue “Confirm” button.")}
            </p>
            <div className="form-group">
              <input className={'form-control text-uppercase'}
                     type={'text'}
                     value={downgradeInputVal}
                     onKeyDown={(e) => {
                       //need to catch this and cancel so that the form doesn't submit.
                       if (e.key === 'Enter') {
                         e.preventDefault();
                         this.confirmFreePlanClick();
                       }
                     }}
                     onChange={(evt) => this.setState({downgradeInputVal: evt.target.value})}
                     placeholder={t("Downgrade")}/>
            </div>
            <div className="text-center mt-3">
              {this.renderFreePayButton()}
            </div>

            {this.state.validationErr.length > 0 &&
            <div className="my-2">
              <ValidationErrors errors={this.state.validationErr}/>
            </div>
            }

          </div>
        </div>
      </div>
    )
  }

  validateCouponData(data) {
    let { t } = this.props;
    if (!data.valid) {
      return t('This Promo code is no longer valid.');
    }

    if (data.amount_off && data.amount_off > 0) {
      return null;
    }
    else if (data.percent_off && data.percent_off > 0) {
      return null;
    }
    else {
      log.error('Unhandled coupon configuration', data);
      return t('This Promo code is not valid.');
    }
  }

  getDisplayPrice(price) {
    var split = ('' + price).split('.')
    if (split.length > 1) {
      var cents = +split[1];
      if (cents === 0) {
        return +split[0];
      }
      else {
        return price.toFixed(2);
      }
    }
    else {
      return +price;
    }
  }

  applyPromoCode() {
    let { t } = this.props;
    let {promo_code, allAvailablePlans, selectedPlanId} = this.state;

    api.Stripe.validateCoupon(promo_code, selectedPlanId)
      .then((res) => {
        log.log('validate coupon res', res);

        let coupon = res.data;
        let msg = this.validateCouponData(coupon);
        if (msg) {
          this.setState({
            validationErr: [msg]
          })
          return;
        }
        else {
          this.setState({
            validationErr: []
          })
        }

        let selectedPlan = _.find(allAvailablePlans, (plan) => +plan.class_id === selectedPlanId)
        let selectedTermCost = +(selectedPlan['term_cost']);

        //Then it's valid.
        let couponDeets = null;
        var monthDuration = coupon.duration === 'once' ? 1 : coupon.duration_in_months;
        let termCostCents = selectedTermCost * 100;
        let month_duration = null;
        if(monthDuration){
          month_duration = monthDuration === 1 ? t('1 month') : monthDuration + t(' months')
        }
        if (coupon.amount_off && coupon.amount_off > 0) {

          //FYI - amount_off comes back in cents
          let price = this.getDisplayPrice((selectedTermCost - (coupon.amount_off / 100)));
          price = Math.max(price, 0);

          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : filters.getCurrency(this.props.i18n.language, price * 100) + t(' / month')),
            month_duration,
            normal_monthly: filters.getCurrency(this.props.i18n.language, termCostCents),
            coupon: coupon
          };
        }
        else if (coupon.percent_off && coupon.percent_off > 0) {

          let price = this.getDisplayPrice((selectedTermCost - (selectedTermCost * (coupon.percent_off / 100))));
          price = Math.max(price, 0);

          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : filters.getCurrency(this.props.i18n.language, price * 100) + t(' / month')),
            month_duration,
            normal_monthly: filters.getCurrency(this.props.i18n.language, termCostCents),
            coupon: coupon
          }
        }

        this.setState({
          valid_code_deets: couponDeets
        })
      })
      .catch((err) => {
        log.log('error validating coupon', err);

        this.setState({
          validationErr: [t('This Promo code is not valid.')]
        })
      })
  }

  clearCoupon() {
    this.setState({
      valid_code_deets: null,
      promo_code: '',
      validationErr : []
    })
  }

  getCouponControls() {
    let { t } = this.props;
    let {
      valid_code_deets,
      paymentWasSuccessful,
      loading
    } = this.state;

    //I disable the button this way to get around a display issue,
    //where it becomes semi-transparent
    let disableClearButton = (paymentWasSuccessful || loading);

    return (
      <div className="mb-1" style={styles.couponBox}>
        <Button className={'btn btn-dark no-focus'}
                type={'button'}
                style={styles.clearButton}
                onClick={disableClearButton ? _.noop : this.clearCoupon.bind(this)}>
          <i className="icon ion-close"/>
        </Button>
        <div className={'text-center'}>
          {/*This padding is to prevent overlapping text under the close button*/}
          <h4 style={{paddingRight: '20px'}}>
            {valid_code_deets.name} {t("Promo Applied")}
          </h4>
          {valid_code_deets.month_duration &&
            <>
              <h5 className={'green-color'}>
                {valid_code_deets.monthly_amount} {t("for")} {valid_code_deets.month_duration}
              </h5>
              <p className="mb-1">
                {valid_code_deets.normal_monthly}{t("/month after that")}
              </p>
            </>
          }
          {!valid_code_deets.month_duration &&
            <>
              <h5 className={'green-color'}>
                {valid_code_deets.monthly_amount} {t("forever")}
              </h5>
            </>
          }
        </div>
      </div>
    )
  }

  onTimeframeToggleChange(evt) {
    this.setState({showMonthlyPlans : !evt.target.checked})
  }

  renderPromoCodePanel(){
    let { t } = this.props;
    let {
      promo_code,
      valid_code_deets,
      paymentWasSuccessful,
      loading
    } = this.state;

    return (
      <>
        {!valid_code_deets &&
        <div>
          <label className="form-label font-weight-bold w-100 text-left dark-color">{t("Promo Code")}</label>
          <div className="form-inline">
            <input className="form-control mr-1"
                   style={{'flex': 1}}
                   type={'text'}
                   value={promo_code}
                   disabled={paymentWasSuccessful || loading}
                   placeholder={t("Optional")}
                   onChange={(evt) => this.setState({promo_code: evt.target.value.toUpperCase()})}/>
            <Button disabled={promo_code.length === 0 || (paymentWasSuccessful || loading)}
                    onClick={this.applyPromoCode.bind(this)}
                    type={'button'}
                    className={'btn btn-secondary'}>{t("Apply")}</Button>
          </div>
        </div>
        }
        {valid_code_deets &&
        <div className="position-relative d-block">
          {this.getCouponControls()}
        </div>
        }
      </>
    )
  }

  renderLimitMessage(){
    let {
      limitMessage,
      windowStep
    } = this.state;

    if(!limitMessage){
      return null;
    }

    let hidePanel = windowStep === this.STEPS.CONFIRM_PLAN;
    let hideStyle = {};
    if(hidePanel){
      hideStyle = {
        opacity: 0,
        pointerEvents: 'none'
      }
    }

    return (
      <div className="row" style={hideStyle}>
        <div className="col">
          <div className={'alert alert-warning'}>

            <div className="d-flex">
              <div className="mr-2">
                <i className="icon ion-android-warning align-middle"
                   style={{ fontSize: '20px', lineHeight: '30px' }}/>
              </div>
              <div style={{lineHeight: '30px'}}>
                {limitMessage}
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderTimeframeToggle(){
    let {
      showMonthlyPlans,
      paymentWasSuccessful,
      windowStep,
      loading
    } = this.state;
    let {
      t
    } = this.props;

    let hidePanel = windowStep === this.STEPS.CONFIRM_PLAN;
    let hideStyle = {};
    if(hidePanel){
      hideStyle = {
        opacity: 0,
        pointerEvents: 'none'
      }
    }
    return (
      <div className="row" style={hideStyle}>
        <div className="col">

          <div className="text-center mt-1 mb-3">
            <div className="d-flex justify-content-center">
              <div className="mr-3" style={{lineHeight: '40px'}}>
                {t("Monthly")}
              </div>
              <span className="custom-control custom-switch custom-switch-lg upgrade-time-toggle">
                <input type="checkbox"
                       disabled={paymentWasSuccessful || loading}
                       className="custom-control-input"
                       onChange={this.onTimeframeToggleChange.bind(this)}
                       checked={!showMonthlyPlans}
                       id="timeframe-checkbox"/>
                  <label className="custom-control-label" htmlFor={'timeframe-checkbox'}/>
              </span>
              <div className="ml-3" style={{lineHeight: '40px'}}>
                {t("Yearly")}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { t, i18n } = this.props;
    let {
      loading,
      windowStep
    } = this.state;

    return (
      <div className="modal-content">
        <Elements stripe={this.state.stripePromise} options={{locale: i18n.language}} key={i18n.language}>
          <ElementsConsumer>
            {({stripe, elements}) => {
              return (
                <>
                  <form onSubmit={this.onSubmitButtonClick.bind(this, stripe, elements)}>
                    <div className="modal-header draggable-header modal-header-sticky">
                      <h5 className="modal-title">{t("Subscription Management")}</h5>
                      <button type="button" className="close" onClick={this.closeModal.bind(this, false)}
                              aria-label={t("Close")}>
                        <i className="icon ion-ios-close-empty"/>
                      </button>
                    </div>
                    <div className="modal-body">

                      {windowStep === this.STEPS.SELECT_PLAN &&
                        <div>
                          {this.renderLimitMessage()}
                          {this.renderTimeframeToggle()}
                          <div className="row">
                            <div className="col gradient-carousel-wrapper">
                              {this.renderPlanPanel()}
                            </div>
                          </div>
                        </div>
                      }
                      {windowStep === this.STEPS.CONFIRM_PLAN &&
                      <div>
                        {this.renderLimitMessage()}
                        {this.renderTimeframeToggle()}
                        <div className="row">
                          <div className="col">
                            {this.renderConfirmPanel()}
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </form>
                </>
              )
            }}
          </ElementsConsumer>
        </Elements>
      </div>
    )
  }
}

UpgradeDialogNew.UPGRADE_TYPES = {
  WORKSPACE : 'workspace',
  DOCS : 'docs',
  STORAGE : 'storage',
  MAX_V2_SIGNING_LIMIT : 'max_v2_signing_limit',
  MERCHANT_INVOICING : 'merchant_invoicing',
  SIGNING : 'signing',
  SIGNING_V1 : 'signing_v1'
}

const PLAN_CARD_HEIGHT = '44rem';

const styles = {
  couponBox: {
    padding: '10px',
    border: 'dashed 2px grey',
    textAlign: 'center',
  },
  clearButton: {
    position: 'absolute',
    top: '-15px',
    right: '-8px',
    border: '10px solid',
    zIndex: '100',
    borderRadius: '50%'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    accountClassInfo: state.shared.accountClassInfo,
    stripeAvailables: state.shared.stripeAvailables,
    app : {...state.app},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    updateStripeAvailables : () => dispatch(sharedActions.updateStripeAvailables()),
    updateStripeData : () => dispatch(sharedActions.updateStripeData()),
    updateLogo : () => dispatch(sharedActions.updateLogo()),
    ...modalActions.mapToDispatch(dispatch)
  };
};

UpgradeDialogNew.WIDE_LARGE = true;

UpgradeDialogNew.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpgradeDialogNew));
