import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {List, AutoSizer, CellMeasurer} from 'react-virtualized';
import {withVFTranslation} from "../../../util/withVFTranslation";
import _ from "lodash";
import log from "../../../util/log";
import utils from "../../../util/util";

class MessageList extends PureComponent {

  LOAD_MORE_BACKOFF_TIME_MS = 1000;

  lastScrollEvent = null;
  listRef = null;

  constructor(props) {
    super(props);

    this.listRef = React.createRef();

    this.state = {
      waitForLoadMore : true //init to true
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.setLoadMoreBackoff();
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  setLoadMoreBackoff(){
    this.setState({waitForLoadMore : true});
    setTimeout(() => {
      // log.log('resetting loadmore backoff');
      this.setState({waitForLoadMore : false})
    }, this.LOAD_MORE_BACKOFF_TIME_MS)
  }

  getScrollDistanceFromBottom(){
    if (!this.lastScrollEvent) {
      return 0;
    }
    let distanceFromTop = this.lastScrollEvent.scrollTop;
    let height = this.lastScrollEvent.scrollHeight - this.lastScrollEvent.clientHeight;
    return height - distanceFromTop;
  }

  getListRef() {
    return _.get(this.listRef, 'current', {});
  }

  updateListView(updateFromIndex = 0) {
    if (this.isListReady()) {
      let listRef = this.getListRef();
      listRef.recomputeRowHeights(updateFromIndex);
      listRef.forceUpdateGrid();
    }
  }

  getRowHeight(index) {
    return this.props.renderedMessages[index].getMeasuredHeight();
  }

  isListReady() {
    return !!_.get(this.listRef, 'current');
  }

  waitForListToBeReady() {
    return utils.waitForCondition(() => {
      return this.isListReady();
    }, 100)
  }

  scrollToBottom() {
    this.waitForListToBeReady()
      .then(() => {
        let listRef = this.getListRef();
        listRef.scrollToRow(listRef.props.rowCount - 1);
        setTimeout(() => {
          listRef = this.getListRef();
          if (listRef) {
            listRef.scrollToRow(listRef.props.rowCount - 1);
          }
        }, 100)
      })
  }

  addTopSpaceForLoadMore(totalNewHeight) {
    if (this.isListReady()) {
      let listRef = this.getListRef();
      listRef.recomputeRowHeights(0);
      listRef.forceUpdateGrid();

      //Since we call load more before we're actually at the top of the view
      //when we add content, we need to adjust the scroll by the new space, plus
      //the current scroll top, which we can grab from the scroll events.
      let lastScrollTop = _.get(this.lastScrollEvent, 'scrollTop');
      if (lastScrollTop > 0) {
        totalNewHeight += lastScrollTop;
      }

      listRef.scrollToPosition(totalNewHeight);
    }
  }

  onScroll = e => {
    let scrollHeight = _.get(e, 'scrollHeight', 0)
    let thisScrollTop = _.get(e, 'scrollTop');
    let lastScrollTop = _.get(this.lastScrollEvent, 'scrollTop');
    // log.log('handleScroll', thisScrollTop, lastScrollTop);

    //This is some logic to guard against weird non-user-related scroll events we get from time to time.
    if (scrollHeight !== 0 &&
      thisScrollTop &&
      lastScrollTop &&
      Math.abs(thisScrollTop - lastScrollTop) > 0) {
      this.props.onScroll(e);
    }

    //From here on, we're looking for the need to load more blocks
    this.lastScrollEvent = e;

    if (scrollHeight === 0) {
      //ignore this one.  It's not really a scroll event we care about.
      return;
    }

    let clientHeight = _.get(e, 'clientHeight', 0)
    if (scrollHeight < clientHeight) {
      // log.log('ignoring load more, panel isnt tall enough.', scrollHeight, clientHeight);
      return;
    }

    // log.log('message list scrolltop', thisScrollTop, scrollHeight);

    if (thisScrollTop < (scrollHeight * .2)) {
      // log.log('message list loadmore', thisScrollTop, scrollHeight);
      if(!this.state.waitForLoadMore) {
        this.setLoadMoreBackoff();
        this.props.doLoadMore(e);
      }
      // else{
      //   log.log('loadmore called, but still in backoff');
      // }
    }
  }

  render() {
    let {
      height,
      width,
      renderedMessages
    } = this.props;

    return (
      <List
        ref={this.listRef}
        height={height}
        width={width}
        onScroll={this.onScroll}
        overscanRowCount={0}
        rowCount={renderedMessages.length}
        rowHeight={obj => this.getRowHeight(obj.index)}
        rowRenderer={(e) => this.props.rowRenderer(e)}
      />
    )
  }
}

MessageList.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onRef: PropTypes.func.isRequired,

  doLoadMore: PropTypes.func.isRequired,
  renderedMessages: PropTypes.array.isRequired,
  onScroll: PropTypes.func.isRequired,
  rowRenderer: PropTypes.func.isRequired
}

export default withVFTranslation()(MessageList);
