import c from './const';
import log from "./log";
import _ from 'lodash';

const limitHelper = {
  //This just does some lookup work for pulling server config values if we have them
  //or hardcoded defaults if we don't.
  
  // serverData = {
  //   "data" : {
  //     "archive" : {
  //       "max_docs" : "20",
  //       "max_size" : "10000000000",
  //       "stale_timer" : "300"
  //     },
  //     "contact" : {
  //       "max_total" : "2500",
  //       "min_queue" : "50"
  //     },
  //     "def_class" : {
  //       "class_id" : "1",
  //       "downgrade_id" : "1",
  //       "host_flag" : "1",
  //       "upgrade_id" : "1,100,12345"
  //     },
  //     "doc_copy" : {
  //       "max_docs" : "100",
  //       "max_size" : "10000000",
  //       "stale_timer" : "12000"
  //     },
  //     "forum_logo" : {
  //       "default_img" : "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
  //       "default_type" : "image/gif",
  //       "height" : "50",
  //       "max_size" : "1048576",
  //       "width" : "200"
  //     },
  //     "notifications" : {
  //       "alert_delay" : "10",
  //       "alert_expiry" : "60",
  //       "max_attach" : "10",
  //       "max_num" : "10",
  //       "support_email" : "swartzk@verifyle.com",
  //       "support_name" : "Test Support Verifyle"
  //     },
  //     "pbkdf2_pw" : {
  //       "max_pass_len" : "64",
  //       "min_pass_len" : "6"
  //     },
  //     "preview_obj" : {
  //       "convert_to" : "pdf",
  //       "convertible_ext" : "pdf docx? xlsx? pptx? csv txt",
  //       "max_convert_size" : "22057610",
  //       "previewable_ext" : "svg jpe?g png gif log",
  //       "thumb_convert_to" : "png",
  //       "thumb_size" : "300"
  //     },
  //     "session" : {
  //       "expiry" : "2592000"
  //     },
  //     "twilio" : {
  //       "live_phones" : [
  //         "+14088774110"
  //       ],
  //       "mode" : "test",
  //       "test_phones" : [
  //         "+14088774110"
  //       ]
  //     }
  //   },
  //   "success" : true
  // }
  
  getMaxAttachDocsLimit(serverData){
    let result = c.limits.maxAttachDocs;
    if(serverData){
      let data = _.get(serverData, 'doc_copy.max_docs', null);
      if(data){
        result = +data;
      }
    }
    return result;
  },
  
  getMaxAttachDocSizeBytes(serverData){
    let result = c.limits.maxAttachDocSizeBytes;
    if(serverData){
      let data = _.get(serverData, 'doc_copy.max_size', null);
      if(data){
        result = +data;
      }
    }
    return result;
  },
  
  getMaxBulkDocDownloadLimit(serverData){
    let result = c.limits.maxBulkDownloadDocs;
    if(serverData){
      let data = _.get(serverData, 'archive.max_docs', null);
      if(data){
        result = +data;
      }
    }
    return result;
  },
  
  getMaxBulkDocDownloadSizeBytes(serverData){
    let result = c.limits.maxBulkDownloadSizeBytes;
    if(serverData){
      let data = _.get(serverData, 'archive.max_size', null);
      if(data){
        result = +data;
      }
    }
    return result;
  }
}

export default limitHelper;
