import React, { useCallback, useRef } from "react";
import currency from "currency.js";
import log from "../../../util/log";
import _ from "lodash";

//Taken and modified from:
//https://github.com/rschpdr/react-money-input

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = (props) => {
  const inputRef = useRef(null)
  const {
    className = "",
    style = {},
    currencyConfig = {
      locale: "en-US",
      currencyCode: "USD",
      currencyDisplay: "symbol",
      useGrouping: true,
      minimumFractionDigits: undefined,
    },
    customInput,
    name,
    id,
    max = Number.MAX_SAFE_INTEGER,
    onChange,
    value,
  } = props;

  const fakeChangeEvent = {
    target: {
      type: "number",
      name,
      id,
    },
  };

  const {
    locale,
    currencyCode,
    currencyDisplay,
    useGrouping,
    minimumFractionDigits,
  } = currencyConfig;

  let valueInCents = currency(value).intValue;
  if(minimumFractionDigits === 0){
    valueInCents = currency(value / 100).intValue;
  }
  const valueAbsTrunc = Math.trunc(Math.abs(valueInCents));
  if (
    valueInCents !== valueAbsTrunc ||
    !Number.isFinite(valueInCents) ||
    Number.isNaN(valueInCents)
  ) {
    throw new Error(`invalid value property`);
  }

  const handleFocusOrClick = useCallback((e) => {

    //Force the cursor to the end on focus, because
    //that's where we're appending new numbers.

    //Also this timeout is needed because hooks are weird, and otherwise
    //this doesn't work for some reason.
    setTimeout(
      () => {
        if(inputRef && inputRef.current) {
          inputRef.current.setSelectionRange(
            Number.MAX_SAFE_INTEGER,
            Number.MAX_SAFE_INTEGER
          )
        }
      }
    );
  })

  const handleKeyDown = useCallback(
    (e) => {
      const {key, keyCode} = e;
      if (
        (valueInCents === 0 && !VALID_FIRST.test(key)) ||
        (valueInCents !== 0 &&
          !VALID_NEXT.test(key) &&
          keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = valueInCents.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString =
          valueInCents === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      }
      else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      if(_.isNumber(minimumFractionDigits) && minimumFractionDigits === 0){
        // Enforce our division with currency to prevent rounding errors
        fakeChangeEvent.target.value = currency(nextValue).value;
        onChange(fakeChangeEvent);
      }
      else{
        // Enforce our division with currency to prevent rounding errors
        fakeChangeEvent.target.value = currency(nextValue / 100).value;
        onChange(fakeChangeEvent);
      }
    },
    [max, onChange, valueInCents, fakeChangeEvent]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);

  let valueDisplayInput = valueInCents / 100;
  if(minimumFractionDigits === 0){
    valueDisplayInput = valueInCents;
  }

  const valueDisplay = currency(valueDisplayInput).value.toLocaleString(
    locale,
    {
      style: "currency",
      currency: currencyCode,
      currencyDisplay,
      useGrouping,
      minimumFractionDigits,
    }
  );

  const inputProps = {
    "data-testid": "currency-input",
    className: className,
    inputMode: "numeric",
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onFocus: handleFocusOrClick,
    onClick: handleFocusOrClick,
    style: style,
    value: valueDisplay,
  };

  if (customInput) {
    const customProps = { ...props, ...inputProps };
    delete customProps.customInput;
    delete customProps.currencyConfig;
    const CustomInput = customInput;
    return <CustomInput {...customProps} />;
  }

  return <input ref={inputRef} {...inputProps} />;
};

export default CurrencyInput;
