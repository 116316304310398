import c from '../util/const';
import log from "../util/log";
import _ from "lodash";

let initialState = {
  loadingForumId: null,
  workspace: null,
  threads: null,
  threadNotifyCount : 0,
  docs: null,
  docNotifyCount : 0,
  guests: null,
  activeThreadGuests: null,
  activeDoc : null,
  publisherInfo : null,
  threadParticipantLookup: null,
  logo: null
}

const workspace = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.workspace.setLoadingForumId:
      return {
        ...state,
        loadingForumId: action.forum_id
      }

    case c.actions.workspace.resetForNewWorkspace:
      return {
        ...initialState,
        loadingForumId : state.loadingForumId
      }

    case c.actions.workspace.updateWorkspace:
      return {
        ...state,
        workspace: {...action.workspace}
      };

    case c.actions.workspace.setActiveThreadGuests:
      return {
        ...state,
        activeThreadGuests: action.guests
      }

    case c.actions.workspace.setActiveDoc:
      return {
        ...state,
        activeDoc: action.doc
      }

    case c.actions.workspace.setPublisherInfo:
      return {
        ...state,
        publisherInfo: action.publisherInfo
      }

    case c.actions.workspace.updateLogo:
      return {
        ...state,
        logo: action.data
      }

    case c.actions.workspace.updateDocs:

      let docNotifyCount = 0;
      _.each(action.docs, (chatBlock) => {
        _.each(chatBlock.docs, (doc) => {
          if(doc.notify_flag){
            docNotifyCount++;
          }
        })
      })

      return {
        ...state,
        docs: action.docs,
        docNotifyCount
      };

    case c.actions.workspace.updateGuests:
      return {
        ...state,
        guests: action.guests
      };

    case c.actions.workspace.updateThreads:

      let threadNotifyCount = 0;
      _.each(action.threads, (thread) => {
        if(thread.notify_flag){
          threadNotifyCount++;
        }
      })

      return {
        ...state,
        threads: action.threads,
        threadNotifyCount
      };

    case c.actions.workspace.updateThreadParticipantLookup:
      return {
        ...state,
        threadParticipantLookup: action.lookup
      };

    case c.actions.workspace.cleanup:
      return {...initialState}

    default:
      return state;
  }
}

export default workspace;
