import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import modalActions from "../../actions/modal-actions";
import filters from "../../helpers/filters";
import Loading from "../partials/util/Loading";
import log from "../../util/log";
import {getMessageForError} from "../../util/errors";
import Autolinker from "autolinker";
import sapi from "../../util/sapi";
import _ from 'lodash'
import msgHelper from "../../helpers/msg-helper";
import c from "../../util/const";
import downloadActions from "../../actions/download-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class MesgHistoryWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      history : [],
      loading : true
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    this.doStartup();
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  doStartup(){
    let { workspace, thread, dm, mesg_id, t } = this.props;

    this.setState({loading : true});
    let msg = this.props.fetchMessageFn(mesg_id);
    log.log('doing startup', msg);
    if(thread){
      sapi.Threads.msgHistory(workspace.forum_id, workspace.host_uid, thread.chat_id, msg.mesg_id)
        .then((res) => {
          log.log('msg history', res);
          _.each(res.data, (entry) => {
            log.log('entry', entry);
            entry.tokens = msgHelper.buildMessageTokens(entry);
          })
          this.setState({
            history : _.sortBy(res.data, (entry) => -entry.mesg_date),
            msg
          })
        })
        .catch((err) => {
          log.error('error loading thread history', err);
          this.props.showAlert(t('Error loading history'), getMessageForError(err, t), () => {
            this.closeModal();
          })
        })
        .finally(() => {
          this.setState({loading : false})
        })
    }
    else if(dm){
      sapi.DM.history(dm.guest_uid, msg.mesg_id)
        .then((res) => {
          _.each(res.data, (entry) => {
            entry.tokens = msgHelper.buildMessageTokens(entry);
          })
          this.setState({
            history : _.sortBy(res.data, (entry) => -entry.mesg_date),
            msg
          })
        })
        .catch((err) => {
          log.error('error loading thread history', err);
          this.props.showAlert(t('Error loading history'), getMessageForError(err, t), () => {
            this.closeModal();
          })
        })
        .finally(() => {
          this.setState({loading : false})
        })
    }
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  getLink(token){
    let t = Autolinker.link( token.text, {
      replaceFn : ( match ) => {
        return `<a rel="nofollow" target="_blank" href="${match.getAnchorHref()}">${match.getMatchedText()}</a>`
      }
    } );

    return (
      <span key={token.id}
            style={styles.token}
            dangerouslySetInnerHTML={{__html: t}}/>
    )
  }

  getDownloadHeaders(doc_id) {
    let {workspace, dm} = this.props;

    let params = { doc_id };

    if(dm){
      params.forum_id = dm.forum_id;
      if (dm.host_uid) {
        params.host_uid = dm.host_uid;
      }
    }
    else{
      if (workspace && workspace.forum_id) {
        params.forum_id = workspace.forum_id
      }
      if (workspace.host_uid) {
        params.host_uid = workspace.host_uid;
      }
    }

    params[c.api.X_TOKEN] = sapi.getToken();

    return params;
  }

  doReattachDoc(doc_id){
    let { workspace, thread, dm, t } = this.props;
    let { msg } = this.state;

    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to re-attach your document?"), (res) => {
      if (res) {
        let reattachArgs = {
          [doc_id] : true
        };
        let promise = null;
        if(thread){
          promise = sapi.Threads.editMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, msg.mesg_id, msg.mesg, reattachArgs);
        }
        else{
          promise = sapi.DM.editMessage(dm.guest_uid, msg.mesg_id, msg.mesg, reattachArgs)
        }

        promise
          .then((res) => {
            log.log('re-attach res', res);
            this.props.refreshMessageFn()
              .then(() => {
                setTimeout(() => {
                  this.doStartup();
                }, 250);
              })
          })
          .catch((err) => {
            log.error('error re-attaching Document', err);
            this.props.showAlert(t('Error Re-attaching Document'), getMessageForError(err, t), () => {})
          })
      }
    })

  }

  doDocDelete(doc){
    let { workspace, thread, dm, t } = this.props;
    let { msg } = this.state;

    this.props.showConfirm(
      t("Delete Document"),
      t("Are you sure you want to delete the Document, ") + doc.label + t("?"),
      (res) => {
        if(res){

          let promise = null;
          if(thread){
            promise = sapi.Threads.docDelete(workspace.forum_id, workspace.host_uid, thread.chat_id, doc.doc_id);
          }
          else{
            promise = sapi.DM.docDelete(dm.guest_uid, doc.doc_id);
          }

          promise
            .then((res) => {
              this.props.refreshMessageFn()
                .then(() => {
                  setTimeout(() => {
                    this.doStartup();
                  }, 250);
                })
            })
            .catch((err) => {
              log.log('error deleting doc', err);
              this.props.showAlert(t('Error Deleting Document'), getMessageForError(err, t))
            })
        }
      }, t('Delete'))
  }

  showReattachButton(){
    let {
      accountInfoGuest,
      mesg_edit_flag
    } = this.props;
    let { msg } = this.state;
    return mesg_edit_flag && accountInfoGuest.guest_uid === msg.guest_uid;
  }

  isDetachedDocument(doc_id){
    let { msg } = this.state;

    return !_.find(_.get(msg, 'docs', []), (doc) => doc.doc_id === doc_id);
  }

  renderMsgDocs(entry){
    let { t } = this.props;
    return (
      <Fragment key={entry.mesg_date}>
        {_.map(entry.docs, (doc) => {

          let docIsDeleted = !(doc.label);
          let isDetachedDocument = this.isDetachedDocument(doc.doc_id);
          let showReattachButton = isDetachedDocument && this.showReattachButton();

          return (
            <p key={doc.doc_id}
               style={{
                 marginRight: '10px',
                 lineHeight: '16px',
                 maxWidth: '100%'
               }}
               className="mb-0">

              {!docIsDeleted &&
                <span className={"primary-color"}
                      style={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                        color: colors.DARK,
                        wordBreak: 'break-all'
                      }}>
                      <i style={{
                        fontSize: '30px',
                        verticalAlign: 'middle',
                        marginRight: '10px',
                      }}
                         className="icon ion-document-text"/>
                      {doc.label}
                  {showReattachButton &&
                    <span className="d-inline-block">
                      <span >
                        <i style={{
                          fontSize: '30px',
                          verticalAlign: 'middle',
                          marginLeft: '10px',
                        }}
                           title={t("Re-attach Document")}
                           onClick={this.doReattachDoc.bind(this, doc.doc_id)}
                           className="icon ion-ios-refresh-empty has-pointer"/>
                      <i style={{
                        fontSize: '22px',
                        marginTop: '3px',
                        verticalAlign: 'middle',
                        marginLeft: '10px',
                        minHeight: '30px',
                        display: 'inline-block'
                      }} title={t("Delete Document")}
                         onClick={this.doDocDelete.bind(this, doc)}
                         className="icon ion-close has-pointer"/>
                      </span>
                    </span>
                  }
                </span>
              }
              {docIsDeleted &&
                <span style={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: colors.DARK,
                        marginRight: '10px',
                        wordBreak: 'break-all'
                      }}>
                  <i style={{
                    fontSize: '30px',
                    color: colors.PRIMARY,
                    verticalAlign: 'middle',
                    marginRight: '10px',
                  }}
                     className="icon ion-document-text"/>
                  ({t('document deleted')})
                </span>
              }
            </p>
          )
        })}
      </Fragment>
    )
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  render() {
    let { accountInfoGuest, t } = this.props;
    let { loading, history, msg } = this.state;

    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }

    let isCurrentUser = msg.guest_uid === accountInfoGuest.guest_uid;
    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Message History")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">

          <Fragment>
            <div className="d-flex mb-2">
              {isCurrentUser &&
              <div className="mr-2">
                <UserBadge guest={msg}
                           overrideColor={colors.PRIMARY}/>
              </div>
              }
              <div className="flex-grow-1" style={{...styles.messageBlock, ...{backgroundColor : colors.THREAD_COLOR_YOU}}}>
                <p className="d-inline-block mb-0" style={{fontWeight: '600'}}>
                  {msg.first_name} {msg.last_name}
                  <span style={styles.blockTime}>
                    {t("(current)")}
                  </span>
                </p>
                <div>
                  {msg.tokens.map((token) => {
                    if(token.useHtml){
                      return this.getLink(token);
                    }
                    else{
                      return (
                        <span key={token.id} style={styles.token}>
                          {token.text}
                        </span>
                      )
                    }
                  })}
                </div>
                <div>
                  {this.renderMsgDocs(msg)}
                </div>
              </div>
              {!isCurrentUser &&
              <div className="ml-2">
                <UserBadge guest={msg} />
              </div>
              }
            </div>
          </Fragment>
          {_.map(history, (entry) => {
            return (
              <Fragment key={entry.mesg_date}>
                <div className="d-flex mb-2">
                  {isCurrentUser &&
                  <div className="mr-2">
                    <UserBadge guest={msg}
                               overrideColor={colors.PRIMARY}/>
                  </div>
                  }
                  <div className="flex-grow-1" style={{...styles.messageBlock, ...{backgroundColor : colors.THREAD_COLOR_YOU}}}>
                    <p className="d-inline-block mb-0" style={{fontWeight: '600'}}>
                      {msg.first_name} {msg.last_name}
                      <span style={styles.blockTime}>
                        {filters.momentFilter(entry.mesg_date, 'lll zz')}
                      </span>
                    </p>
                    <div>
                      {entry.tokens.map((token) => {
                        if(token.useHtml){
                          return this.getLink(token);
                        }
                        else{
                          return (
                            <span key={token.id} style={styles.token}>
                              {token.text}
                            </span>
                          )
                        }
                      })}
                    </div>
                    <div>
                      {this.renderMsgDocs(entry)}
                    </div>
                  </div>
                  {!isCurrentUser &&
                  <div className="ml-2">
                    <UserBadge guest={msg}/>
                  </div>
                  }
                </div>
              </Fragment>
            )
          })}

        </div>
        <div className="modal-footer">
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  token: {
    maxWidth: '100%',
    whiteSpace : 'pre-wrap',
    overflow : 'hidden',
    wordWrap : 'break-word'
  },
  editLink : {
    fontSize : '12px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    cursor: 'default'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    workspace : state.workspace.workspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

MesgHistoryWindow.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(MesgHistoryWindow));
