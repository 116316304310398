import React, {Component} from 'react';
import {connect} from "react-redux";
import pendingMsgCache from "../../helpers/pending-msg-cache";
import PropTypes from 'prop-types';
import UpgradeDialogNew from "./UpgradeDialogNew";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";
import sharedActions from "../../actions/shared-actions";
import filters from "../../helpers/filters";
import ElectronAwareLink from "../partials/elements/ElectronAwareLink";
import c from "../../util/const";
import colors from "../../util/colors";
import UserBadge from "../partials/badges/UserBadge";
import VFPopover from "../partials/components/VFPopover";
import popoverActions from "../../actions/popover-actions";
import popupHelper from "../../helpers/popup-helper";
import Loading from "../partials/util/Loading";

class InvoiceHistoryItemDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      invoice : null
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
    this.lookupInvoice();
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.merchantBillList !== this.props.merchantBillList) {
      this.lookupInvoice();
    }
  }

  lookupInvoice(){
    this.setState({invoice : _.find(this.props.merchantBillList, (i) => i.invoice_id === this.props.modalProps.invoice_id)})
  }

  closeModal(res) {
    this.props.close(res);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  onViewInThreadClick(bill){
    log.log('onViewInThreadClick', bill);

    //so we get a forum_id here, but it could be for a dm or a workspace.
    //At the time of writing I'm not sure how else to figure out what kind of thread
    //we're point at except to just search in both places.
    let {
      directMessages,
      workspaces
    } = this.props;
    let foundDM = _.find(directMessages, (dm) => dm.forum_id === bill.forum_id);
    let foundWorkspace = _.find(workspaces, (ws) => ws.forum_id === bill.forum_id);
    if(foundDM){
      this.props.history.push(`/home?guest_uid=${foundDM.guest_uid}`)
    }
    else if(foundWorkspace){
      this.props.history.push(`/workspace/${foundWorkspace.forum_id}?chat_id=${bill.chat_id}`);
    }

    this.closeModal(true);
  }

  onRefundPaymentClick(bill){
    log.log('onRefundPaymentClick', bill);
    let { t } = this.props;

    this.props.showRefundInvoiceDialog(bill, (res) => {
      if(res){
        sapi.Merchant.refundInvoice(bill.payer_uid, bill.trans_id, res.amount, bill.currency === c.INVOICE_CURRENCY_CODES.JPY, res.reason)
          .then((res) => {
            log.log('refund invoice res', res);
            this.props.refreshInvoiceHistoryList();
          })
          .catch((err) => {
            log.log('error refunding invoice', err);
            this.props.showAlert(t("Error Refunding Invoice"), getMessageForError(err, t));
            this.props.refreshInvoiceHistoryList();
          })
      }
    })
  }

  sendInvoiceAgainClick(bill){
    log.log('sendInvoiceAgainClick', bill);

    //Just create the invoice, add as pending message and navigate user there.
    //we also need to implement the resending call, which will be handled in Upload Manager resend.
    //Probable need some flag to handle that.
    let invoice = {
      uniqueId : _.uniqueId('vf-invoice-doc-'),
      guest_uid : _.get(bill, 'payer_uid'),
      guest_name : _.get(bill, 'first_name') + " " + _.get(bill, 'last_name'),
      amount : +bill.amount,
      amountCents : bill.currency !== c.INVOICE_CURRENCY_CODES.JPY ? +bill.amount * 100 : +bill.amount,
      currency : bill.currency,
      no_decimal_flag : bill.currency === c.INVOICE_CURRENCY_CODES.JPY,
      description : bill.description,
      forum_id : bill.forum_id,
      host_uid : bill.host_uid,
      chat_id : bill.chat_id,
      isInvoice : true,
      isInvoiceResend : true,
      original_bill : bill
    }

    let {
      directMessages
    } = this.props;
    let isDM = !!_.find(directMessages, (dm) => dm.forum_id === bill.forum_id);
    if(isDM){
      let guest_uid = _.get(bill, 'payer_uid');
      pendingMsgCache.addPendingInvoiceToDmCache(guest_uid, invoice);
    }
    else{
      let chat_id = bill.chat_id;
      pendingMsgCache.addPendingInvoiceToThreadCache(chat_id, invoice);
    }
    setTimeout(() => {
      this.onViewInThreadClick(bill);
    })
  }

  onVoidInvoiceClick(bill){
    log.log('onVoidInvoiceClick', bill);
    let { t } = this.props;

    let msgString = '';
    let amountString = `${filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)} ${bill.currency}`
    msgString = t("Are you sure you want to void the invoice you sent to ") + _.get(bill, 'payer_first_name') + " " + _.get(bill, 'payer_last_name') + t(" for ") + amountString + t("?");

    this.props.showConfirm(
      t("Are you sure?"),
      msgString,
      (res) => {
        if (res) {
          sapi.Merchant.voidInvoice(bill.payer_uid, bill.trans_id)
            .then((res) => {
              log.log('void invoice res', res);
              this.closeModal(res);
              this.props.refreshInvoiceHistoryList();
            })
            .catch((err) => {
              log.log('error voiding invoice', err);
              this.props.showAlert(t("Error Voiding Invoice"), getMessageForError(err, t));
              this.props.refreshInvoiceHistoryList();
            })
        }
      }, t("Yes"), t("No"))
  }

  showViewInvoiceLinkModal(evt, bill){
    this.props.showViewInvoiceLinkModal(bill.uid, bill, () => {
      log.log('done showing invoice link modal');
    })
  }

  renderPaymentState(bill){
    let { t } = this.props;
    if(bill.refund_amount && _.isNumber(bill.refund_amount)){
      return (
        <div className="payment-state-box refunded">
          <span className="text-uppercase">
            {t("Refunded")}
          </span>
        </div>
      )
    }
    else if(bill.paid_flag){
      return (
        <div className="payment-state-box paid">
          <span className="text-uppercase">
            <i className="icon ion-checkmark green-color mr-1" />
            {t("Paid")}
          </span>
        </div>
      )
    }
    else if(!bill.paid_flag){
      return (
        <div className="payment-state-box pending">
          <span className="text-uppercase">
            {t("Pending")}
          </span>
        </div>
      )
    }

  }

  renderPaymentAmount(bill){
    let refundedAmount = bill.refund_amount ? bill.refund_amount : 0;

    return (
      <h3>
        <div>
          <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, bill.currency, bill.amount)}</span>
          <span className="small d-inline ml-2">{bill.currency}</span>
        </div>
        {bill.refund_amount &&
          <div className="small">
            -{filters.formatCurrency(this.props.i18n.language, bill.currency, refundedAmount)}
          </div>
        }
      </h3>
    )
  }

  renderPaymentDates() {
    let {
      invoice
    } = this.state;
    let {t} = this.props;

    return (
      <div className="mt-4">
        <p className="hint-text mb-0">
          {t("Date of Request")}: {filters.momentFilter(invoice.request_date, 'lll')}
        </p>
        {invoice.payment_date &&
          <p className="hint-text mb-0">
            {t("Date of Payment")}: {filters.momentFilter(invoice.payment_date, 'lll')}
          </p>
        }
      </div>
    )
  }

  renderHeaderSection(){
    let {
      invoice
    } = this.state;
    let { accountInfo, t } = this.props;
    let areYouPayer = accountInfo.uid === invoice.payer_uid;
    let payer_first_name = areYouPayer ? accountInfo.first_name : invoice.payer_first_name;
    let payer_last_name = areYouPayer ? accountInfo.last_name : invoice.payer_last_name;
    let payer_email = areYouPayer ? accountInfo.login : invoice.payer_email_address;
    let payer_guest_uid = areYouPayer ? accountInfo.uid : invoice.payer_uid;

    let areYouRequestor = !invoice.merchant_flag;

    let requestor_first_name = areYouRequestor ? accountInfo.first_name : invoice.merchant_first_name;
    let requestor_last_name = areYouRequestor ? accountInfo.last_name : invoice.merchant_last_name;
    let requestor_email = areYouRequestor ? accountInfo.login : invoice.merchant_email_address;
    let requestor_guest_uid = areYouRequestor ? accountInfo.uid : invoice.uid;

    let requestorGuestObj = {
      first_name : requestor_first_name,
      last_name : requestor_last_name,
      guest_uid : requestor_guest_uid
    }
    let payerGuestObj = {
      first_name : payer_first_name,
      last_name : payer_last_name,
      guest_uid : payer_guest_uid
    }

    return (
      <>
        <div className={'row mx-5 mb-5'}>
          <div className={'col-6'}>
            <h4 className="mb-0">{t("Requested By")}</h4>
            <div className="d-flex">
              <UserBadge guest={requestorGuestObj} overrideColor={areYouRequestor ? colors.PRIMARY : null}/>
              <div className="ml-2">
                {areYouRequestor &&
                  <p className="mb-0">
                    {t("You")}
                  </p>
                }
                {!areYouRequestor &&
                  <p className="mb-0">
                    {`${requestor_first_name} ${requestor_last_name}`}
                  </p>
                }
                <p className="mb-0">
                  {requestor_email}
                </p>
              </div>
            </div>
          </div>
          <div className={'col-6'}>
            <h4 className="mb-0">{t("Payer")}</h4>
            <div className="d-flex">
              <UserBadge guest={payerGuestObj} overrideColor={areYouPayer ? colors.PRIMARY : null}/>
              <div className="ml-2">
                {areYouPayer &&
                  <p className="mb-0">
                    {t("You")}
                  </p>
                }
                {!areYouPayer &&
                  <p className="mb-0">
                    {`${payer_first_name} ${payer_last_name}`}
                  </p>
                }
                <p className="mb-0">
                  {payer_email}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={'row mb-4'}>
          <div className={'col text-center'}>
            <div>
              {this.renderPaymentAmount(invoice)}
            </div>
            <p>
              {invoice.description}
            </p>
            <div>
              {this.renderPaymentState(invoice)}
            </div>
            <div className="mt-2">
              {this.renderPaymentDates(invoice)}
            </div>
          </div>
        </div>
      </>
    )
  }

  renderButtonSection(){
    let { t } = this.props;
    let {
      invoice
    } = this.state;

    //don't allow a refund if it's already been fully refunded.
    let maxRefund = +_.get(invoice, 'amount', 0) - +_.get(invoice, 'refund_amount', 0);
    let allowRefund = invoice.paid_flag && maxRefund > 0;

    return (
      <div className="mx-5 my-3">
        <div className="list-group history-item-buttons">

          <a className={`list-group-item list-group-item-action has-pointer`}
             onClick={(evt) => this.showViewInvoiceLinkModal(evt, invoice)}>
            <i className="icon history-line-item-icon ion-link"/>
            {t("View Invoice")}
          </a>

          <button type="button"
                  onClick={this.onViewInThreadClick.bind(this, invoice)}
                  style={{borderTopLeftRadius:'0px', borderTopRightRadius:'0px'}}
                  className="list-group-item list-group-item-action">
            <i className="icon history-line-item-icon ion-eye"/>
            {t("View In Thread")}
          </button>

          {!invoice.merchant_flag && !invoice.paid_flag &&
            <button type="button"
                    onClick={this.sendInvoiceAgainClick.bind(this, invoice)}
                    className="list-group-item list-group-item-action">
              <i className="icon history-line-item-icon ion-refresh"/>
              {t("Send Invoice Again")}
            </button>
          }

          {!invoice.merchant_flag && allowRefund &&
          <button type="button"
                  onClick={this.onRefundPaymentClick.bind(this, invoice)}
                  className="list-group-item list-group-item-action">
            <i className="icon history-line-item-icon ion-cash"/>
            {t("Refund Payment")}
          </button>
          }

          {!invoice.merchant_flag && !invoice.paid_flag &&
            <button type="button"
                    onClick={this.onVoidInvoiceClick.bind(this, invoice)}
                    className="list-group-item list-group-item-action">
              <i className="icon history-line-item-icon ion-close"/>
              {t("Void Invoice")}
            </button>
          }
        </div>
      </div>
    )
  }

  render() {
    let { t } = this.props;
    let { invoice } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Invoice")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body container-fluid">
          {invoice && this.renderHeaderSection()}
          {invoice && this.renderButtonSection()}
        </div>
      </div>
    )
  }
}

InvoiceHistoryItemDialog.MODAL_LARGE = true;

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    merchantBillList : state.shared.merchantBillList,
    workspace : state.shared.workspace,
    workspaces : state.shared.workspaces,
    directMessages : state.shared.directMessages,
    showingPopoverKey : state.popover.showingPopoverKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

InvoiceHistoryItemDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceHistoryItemDialog)));
