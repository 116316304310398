import c from '../util/const';
import _ from 'lodash';
import log from "../util/log";

let initialState = {
  showingPopoverKey: null
}

const popover = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.popover.showPopover:
      return {
        ...state,
        showingPopoverKey: action.popoverKey
      }

    case c.actions.popover.hidePopover:
      return {
        ...state,
        showingPopoverKey: null
      }
    
    case c.actions.popover.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default popover;
