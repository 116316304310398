import React, {Component} from 'react';
import {connect} from 'react-redux';

import { withRouter } from 'react-router-dom';

import c from '../../../util/const';
import Button from "../elements/Button";
import log from "../../../util/log";
import classNames from "classnames";
import ExpandableRow from "../components/ExpandableRow";
import {CSSTransition} from "react-transition-group";
import ChangePwdCtrl from "./security/ChangePwdCtrl";
import TFAAuthenticatorCtrl from "./security/TFAAuthenticatorCtrl";
import sapi from "../../../util/sapi";
import PasswordResetCtrl from "./security/PasswordResetCtrl";
import DeleteAccountCtrl from "./security/DeleteAccountCtrl";
import SessionManagementCtrl from "./security/SessionManagementCtrl";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import Scroll from "react-scroll";
import TFAPhoneCtrl from "./security/TFAPhoneCtrl";
import TrustedDevicesCtrl from "./security/TrustedDevicesCtrl";

class SecurityTab extends Component {

  CHANGE_PASSWORD_ID = 'security-change-password-id';
  DELETE_ACCOUNT_ID = 'security-delete-account-id';
  PASSWORD_RESET_ID = 'security-password-reset-id';
  SESSION_MANAGEMENT_ID = 'security-session-mgmt-id';
  TFA_ID = 'security-tfa-id';
  TFA_PHONE_ID = 'security-tfa-phone-id';
  TRUSTED_DEVICES_ID = 'trusted-devices-id';

  constructor(props) {
    super(props);

    this.state = {
      activeControl : null
    }
  }

  setActiveControl(control){
    this.setState({activeControl: control})
  }

  closeActivePanel(){
    this.setActiveControl(null);
  }

  changePasswordClick(){
    let { activeControl } = this.state;

    if(activeControl === 'change_pwd'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('change_pwd');
      this.changePwdCtrl.init();
      this.scrollWithinContainer(this.CHANGE_PASSWORD_ID);
    }
  }

  sessionManagementClick(){
    let { activeControl } = this.state;

    if(activeControl === 'session_mgmt'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('session_mgmt');
      this.sessionMgmtCtrl.init();
      this.scrollWithinContainer(this.SESSION_MANAGEMENT_ID);
    }
  }

  deleteAccountClick(){
    let { activeControl } = this.state;

    if(activeControl === 'delete_account'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('delete_account');
      this.deleteAccountCtrl.init();
      this.scrollWithinContainer(this.DELETE_ACCOUNT_ID);
    }
  }

  tfaClick(){
    let { activeControl } = this.state;

    if(activeControl === 'tfa_controls'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('tfa_controls');
      this.tfaCtrl.init();
      this.scrollWithinContainer(this.TFA_ID);
    }
  }

  tfaPhoneClick(){
    let { activeControl } = this.state;

    if(activeControl === 'tfa_phone_controls'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('tfa_phone_controls');
      this.tfaPhoneCtrl.init();
      this.scrollWithinContainer(this.TFA_PHONE_ID);
    }
  }

  trustedDeviceClick(){
    let { activeControl } = this.state;

    if(activeControl === 'trusted_device_controls'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('trusted_device_controls');
      this.trustedDevicesCtrl.init();
      this.scrollWithinContainer(this.TRUSTED_DEVICES_ID);
    }
  }

  pwdResetClick(){
    let { activeControl } = this.state;

    if(activeControl === 'pwd_reset_ctrl'){
      this.setActiveControl(null);
    }
    else{
      this.setActiveControl('pwd_reset_ctrl');
      this.pwdResetCtrl.init();
      this.scrollWithinContainer(this.PASSWORD_RESET_ID);
    }
  }

  scrollWithinContainer(elemId){
    setTimeout(() => {
      Scroll.scroller.scrollTo(elemId, {
        duration: 300,
        smooth: true,
        offset: -65
      })
    }, 250)
  }

  render() {
    let { passwordInfo, t } = this.props;
    let { activeControl } = this.state;

    if(!passwordInfo){
      return null;
    }

    return (
      <div>
        <div>
          <h3 className={'ml-3'}>{t("Security Settings")}</h3>
        </div>
        <div className={'card'}>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'change_pwd'})}
               onClick={this.changePasswordClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}>
                  {t("Change Password")}
                </label>
              </div>
              <div className={'col-5'}>

              </div>
              <div className={'col-3 text-right'}>

                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'change_pwd'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'change_pwd'}>
            <div id={this.CHANGE_PASSWORD_ID} className={'card-body card-border-bottom'}>
              <ChangePwdCtrl doClose={this.closeActivePanel.bind(this)}
                             onRef={ref => (this.changePwdCtrl = ref)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'session_mgmt'})}
               onClick={this.sessionManagementClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}>
                  {t("Session Management")}
                </label>
              </div>
              <div className={'col-5'}>

              </div>
              <div className={'col-3 text-right'}>

                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'session_mgmt'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'session_mgmt'}>
            <div id={this.SESSION_MANAGEMENT_ID} className={'card-body card-border-bottom'}>
              <SessionManagementCtrl doClose={this.closeActivePanel.bind(this)}
                                     onRef={ref => (this.sessionMgmtCtrl = ref)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'tfa_controls'})}
               onClick={this.tfaClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2 no-pointer'}
                       htmlFor={'account-tfa-field'}>
                  {t("2FA Authenticator")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       id="account-tfa-field"
                       value={(passwordInfo.tfa_totp_flag ? t('Active') : t('None'))} />
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'tfa_controls'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'tfa_controls'}>
            <div id={this.TFA_ID} className={'card-body card-border-bottom'}>
              <TFAAuthenticatorCtrl doClose={this.closeActivePanel.bind(this)}
                                    onRef={ref => (this.tfaCtrl = ref)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'tfa_phone_controls'})}
               onClick={this.tfaPhoneClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2 no-pointer'}
                       htmlFor={'account-tfa-phone-field'}>
                  {t("2FA Phone")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       id="account-tfa-phone-field"
                       value={(passwordInfo.tfa_sms_flag ? t('Active') : t('None'))} />
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'tfa_phone_controls'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'tfa_phone_controls'}>
            <div id={this.TFA_PHONE_ID} className={'card-body card-border-bottom'}>
              <TFAPhoneCtrl doClose={this.closeActivePanel.bind(this)}
                                    onRef={ref => (this.tfaPhoneCtrl = ref)}/>
            </div>
          </ExpandableRow>

          {!!(passwordInfo.tfa_sms_flag || passwordInfo.tfa_totp_flag) &&
            <div
              className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'trusted_device_controls'})}
              onClick={this.trustedDeviceClick.bind(this)}>
              <div className={'form-row'}>
                <div className={'col-4'}>
                  <label className={'pt-2 no-pointer'}
                         htmlFor={'trusted-device-field'}>
                    {t("Trusted Devices")}
                  </label>
                </div>
                <div className={'col-5'}>
                  <input type="text"
                         readOnly
                         className="form-control-plaintext light-grey-color no-pointer"
                         id="trusted-device-field"
                         value={''}/>
                </div>
                <div className={'col-3 text-right'}>
                  <div className={'pt-2 pr-4'}>
                    <CSSTransition in={activeControl === 'trusted_device_controls'}
                                   timeout={400}
                                   classNames={'rotate-90'}>
                      <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
          }

          <ExpandableRow isActive={activeControl === 'trusted_device_controls'}>
            <div id={this.TRUSTED_DEVICES_ID} className={'card-body card-border-bottom'}>
              <TrustedDevicesCtrl doClose={this.closeActivePanel.bind(this)}
                            onRef={ref => (this.trustedDevicesCtrl = ref)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'pwd_reset_ctrl'})}
               onClick={this.pwdResetClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2 no-pointer'}
                       htmlFor={'account-pwd-reset-field'}>
                  {t("Password Reset")}
                </label>
              </div>
              <div className={'col-5'}>
                <input type="text"
                       readOnly
                       className="form-control-plaintext light-grey-color no-pointer"
                       value={(passwordInfo.disabled_flag ? t('OFF') : t('ON'))} />
              </div>
              <div className={'col-3 text-right'}>
                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'pwd_reset_ctrl'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'pwd_reset_ctrl'}>
            <div id={this.PASSWORD_RESET_ID} className={'card-body card-border-bottom'}>
              <PasswordResetCtrl onRef={ref => (this.pwdResetCtrl = ref)}
                                 doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

          <div className={classNames('card-header clickable transition-bg-color', {'active': activeControl === 'delete_account'})}
               onClick={this.deleteAccountClick.bind(this)}>
            <div className={'form-row'}>
              <div className={'col-4'}>
                <label className={'pt-2'}>
                  {t("Delete Account")}
                </label>
              </div>
              <div className={'col-5'}>

              </div>
              <div className={'col-3 text-right'}>

                <div className={'pt-2 pr-4'}>
                  <CSSTransition in={activeControl === 'delete_account'}
                                 timeout={400}
                                 classNames={'rotate-90'}>
                    <i className={'d-inline-block ion-chevron-right light-grey-color no-pointer'}/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>

          <ExpandableRow isActive={activeControl === 'delete_account'}>
            <div id={this.DELETE_ACCOUNT_ID} className={'card-body card-border-bottom'}>
              <DeleteAccountCtrl onRef={ref => (this.deleteAccountCtrl = ref)}
                                 doClose={this.closeActivePanel.bind(this)}/>
            </div>
          </ExpandableRow>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    passwordInfo : state.account.passwordInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityTab)));
