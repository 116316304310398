import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import UpgradeDialogNew from "./UpgradeDialogNew";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";
import sharedActions from "../../actions/shared-actions";
import ElectronAwareLink from "../partials/elements/ElectronAwareLink";
import popoverActions from "../../actions/popover-actions";
import threadActions from "../../actions/thread-actions";
import Loading from "../partials/util/Loading";

class ConfirmNameDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      previewInvoiceHref : null,
      wasErrorRegeneratingInvoice : false
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let { invoice } = this.props.modalProps;
    let previewInvoiceHref = _.get(invoice, 'invoice_url');
    if(!previewInvoiceHref || previewInvoiceHref.length === 0){
      this.refreshInvoiceUrl();
    }
    else{
      this.setState({
        previewInvoiceHref
      })
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  refreshInvoiceUrl(){
    this.setState({ wasErrorRegeneratingInvoice : false})
    let {
      sender_uid,
      invoice
    } = this.props.modalProps;

    sapi.Merchant.refreshInvoiceUrl(sender_uid, invoice.payer_uid, invoice.trans_id)
      .then((res) => {
        log.log('refresh invoice res', res);

        let previewInvoiceHref = _.get(res, 'data.invoice_url');
        this.setState({previewInvoiceHref})

        return Promise.all([
          this.props.refreshInvoiceHistoryList(),
          this.props.threadAction.refreshActiveThreadMessages()
        ])
      })
      .catch((err) => {
        log.log('error refreshing invoice', err);
        this.setState({
          wasErrorRegeneratingInvoice : true,
        })
      })
  }

  closeModal(res) {
    this.props.close(res);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  renderErrorSection(){
    let {  t } = this.props;
    return (
      <div className="text-center">
        <div className="alert mb-0" role="alert">
          <h5 className="alert-heading mb-1">
            <i className="icon ion-android-warning text-warning" />
          </h5>
          <p style={{fontSize: '14px'}}>
            {t("There was a problem generating the link for your invoice.  Please try again.")}
          </p>
        </div>
        <div className="text-center mt-2">
          <Button onClick={() => this.refreshInvoiceUrl()}
                  className={'btn btn-primary'}>
            {t("Try Again")}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    let { previewInvoiceHref, wasErrorRegeneratingInvoice } = this.state;
    let {modalProps, t} = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("View Invoice")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body" style={{minHeight:'250px'}}>
          <div className="row">
            <div className="col">

              {wasErrorRegeneratingInvoice && this.renderErrorSection()}

              {!wasErrorRegeneratingInvoice &&
                <p className="text-center">
                  {t("Click the button below to open a new tab outside of Verifyle and view your invoice")}
                </p>
              }
              <div className="text-center mt-5">
                {!previewInvoiceHref && !wasErrorRegeneratingInvoice &&
                  <Loading size="sm"/>
                }
                {previewInvoiceHref && !wasErrorRegeneratingInvoice &&
                  <ElectronAwareLink
                    onClick={() => this.closeModal(true)}
                    target="_blank"
                    href={previewInvoiceHref}
                    className="btn btn-lg btn-primary">
                    <i className="icon history-line-item-icon ion-link"/>
                    {t("View Invoice")}
                  </ElectronAwareLink>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    modalAction: {...modalActions.mapToDispatch(dispatch)},
    threadAction : {...threadActions.mapToDispatch(dispatch)},
  };
};

ConfirmNameDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmNameDialog)));
