import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux';
import app from './app';
import auth from './auth'
import modal from './modal'
import home from './home';
import workspace from './workspace';
import shared from './shared';
import upload from './upload';
import account from './account';
import download from './download';
import utility from './utility';
import pdfPreview from './pdf-preview';
import thread from './thread';
import popover from './popover';

const rootReducer = combineReducers({
  app,
  auth,
  modal,
  home,
  workspace,
  shared,
  upload,
  account,
  download,
  utility,
  pdfPreview,
  thread,
  popover,
  routerReducer
})

export default rootReducer;
