
import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import filters from "../../../helpers/filters";
import classnames from 'classnames'
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {connect} from "react-redux";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {getMessageForError} from "../../../util/errors";
import colors from "../../../util/colors";
import UserBadge from "../badges/UserBadge";
import Promise from "bluebird";
import Image from "../elements/Image";
import log from "../../../util/log";
import _ from "lodash";
import Button from "../elements/Button";
import utils from "../../../util/util";
import c from "../../../util/const";
import sharedActions from "../../../actions/shared-actions";
import {Element } from 'react-scroll'
import homeActions from "../../../actions/home-actions";
import downloadActions from "../../../actions/download-actions";
import { isMobile } from 'react-device-detect';
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import {DragPreviewImage, DragSource, DropTarget} from 'react-dnd'
import DocAttach from "../../../models/DocAttach";
import {getEmptyImage} from "react-dnd-html5-backend";

class PdfThumbnail extends PureComponent {

  onPageDeleteClick(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.props.onDeletePageClick(this.props.pageIndex);
  }

  renderPageDeleteButton(){
    let {
      isEditModeOn,
    } = this.props;

    if(!isEditModeOn){
      return null;
    }

    return (
      <button className="btn btn-dark light-color position-absolute"
              onClick={this.onPageDeleteClick.bind(this)}
              style={{
                top: '-7px',
                right: '-7px',
                width: '34px',
                height: '34px',
                borderRadius : '50%',
                padding: '6px',
                border: '1px solid ' + colors.LIGHT
              }}>
        <i className="icon ion-close d-inline-flex"
           style={{
             fontSize: '20px',
             height: '20px',
             display: 'unset'
           }}/>
      </button>
    )
  }

  render(){
    let {
      pageIndex,
      canvasRef,
      thumbnailImage,
      dragImage,
      isActivePage,
      isEditModeOn,
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
      isDragging
    } = this.props;

    return connectDropTarget(connectDragSource(
      (
        <div id={`pdf-thumbnail-page-${pageIndex}`}
             onClick={() => this.props.onThumbnailPageClick(pageIndex)}
             style={{marginBottom: '20px'}}
             className={
               `${isDragging ? 'opacity-0 ' : ''}` +
               `${isActivePage ? 'active ' : ''}` +
               `${isEditModeOn ? 'is-editing has-grabber ' : 'has-pointer '}` +
               'pdf-thumbnail text-center position-relative'
             }>
          <div>
            {this.renderPageDeleteButton()}
            <DragPreviewImage connect={connectDragPreview} src={dragImage ? dragImage.data : getEmptyImage()}/>
            {thumbnailImage &&
            <Image src={thumbnailImage.data}
                   alt="thumb"
                   className={'no-pointer'}
                   imgHeight={thumbnailImage.height}
                   imgWidth={thumbnailImage.width}/>
            }
            {!thumbnailImage && canvasRef.current &&
            <div style={{
              display: 'inline-block',
              width: canvasRef.current.style.width,
              height: canvasRef.current.style.height,
              backgroundColor: colors.LIGHT
            }}/>
            }
          </div>
        </div>
      )
    ))
  }
}

PdfThumbnail.propTypes = {
  pageIndex : PropTypes.number.isRequired,
  listIndex : PropTypes.number.isRequired,
  canvasRef : PropTypes.object,
  thumbnailImage : PropTypes.object,
  dragImage : PropTypes.object,
  isEditModeOn : PropTypes.bool.isRequired,
  isActivePage : PropTypes.bool.isRequired,
  onThumbnailPageClick : PropTypes.func.isRequired,
  onDeletePageClick : PropTypes.func.isRequired,
  dragThumbnailStart : PropTypes.func.isRequired,
  dragThumbnailEnd : PropTypes.func.isRequired,
  onDragCancel : PropTypes.func.isRequired,
  doHoverMove : PropTypes.func.isRequired,
  commitMove : PropTypes.func.isRequired,
  disableHoverEvents : PropTypes.bool.isRequired
}

const cardSource = {
  canDrag(props) {
    // You can disallow drag based on props
    return props.isEditModeOn
  },

  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    log.log('begin thumbnail drag', props);
    props.dragThumbnailStart(component);
   return {
     _originalPageIndex : props.pageIndex,
     pageIndex : props.pageIndex
   }
  },

  endDrag(props, monitor, component) {
    props.dragThumbnailEnd();
    if (!monitor.didDrop()) {
      props.onDragCancel();
      return
    }

    // When dropped on a compatible target, do something
    // const item = monitor.getItem()
    // const dropResult = monitor.getDropResult()
    //
    // log.log('endDrag', item, dropResult);
  }
}

function collectSrc(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  }
}

const dropTargetSpec = {

  hover(props, monitor, component){
    if (!component || props.disableHoverEvents) {
      return
    }
    const dragIndex = monitor.getItem().pageIndex;
    const hoverIndex = props.listIndex;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    //log.log('dragging hovering', dragIndex, hoverIndex);

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY =
      (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse positionx
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.doHoverMove(dragIndex, hoverIndex);
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().pageIndex = hoverIndex;
  },

  // hover1(props, monitor) {
  //   // You can disallow drop based on props or item
  //   const item = monitor.getItem()
  //   log.log('on hover', item, props);
  // },

  drop(props, monitor, component) {
    // Obtain the dragged item
    const dragIndex = monitor.getItem().pageIndex;
    const hoverIndex = props.pageIndex;

    log.log('pdf thumbnail drop', dragIndex, hoverIndex);

    if(dragIndex !== hoverIndex){
      props.commitMove(hoverIndex, dragIndex);
    }
    else{
      props.onDragCancel(hoverIndex);
    }

    //
    // let docs = [{
    //   ...item.row,
    //   forum_id : item.src_forum_id,
    //   host_uid : item.src_host_uid,
    // }]
    // let result = DocAttach.buildDMAttachResult(props.row.guest_uid, docs)
    //
    // props.onDocAttach(result);

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return props.row;
  }
}

function collectTarget(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  }
}

export default _.flow([
  DragSource(c.DRAG_DROP_TYPES.PDF_THUMBNAIL, cardSource, collectSrc),
  DropTarget(c.DRAG_DROP_TYPES.PDF_THUMBNAIL, dropTargetSpec, collectTarget),
])(PdfThumbnail)
