import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import SignatureRequest from '../../../models/SignatureRequest'
import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import UserBadge from "../badges/UserBadge";
import {withRouter} from "react-router-dom";
import utils from "../../../util/util";
import colors from "../../../util/colors";
import Autolinker from "autolinker";
import modalActions from "../../../actions/modal-actions";
import c from "../../../util/const";
import PendingDocRow from "../docs/PendingDocRow";
import ThreadDoc from "./ThreadDoc";
import workspaceActions from "../../../actions/workspace-actions";
import {getMessageForError} from "../../../util/errors";
import ProgressBar from "../elements/ProgressBar";
import { isMobile } from 'react-device-detect';
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import filters from "../../../helpers/filters";
import VFPopover from "../components/VFPopover";
import popoverActions from "../../../actions/popover-actions";
import ThreadBill from "./ThreadBill";
import VFMiddleTruncate from "../util/VFMiddleTruncate";

class MessageBlock extends PureComponent {

  constructor(props){
    super(props);

    this.onDocClick = this.onDocClick.bind(this);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      hoveringMesgId : null,
    }
  }

  getLink(token){
    let t = Autolinker.link( token.text, {
      replaceFn : ( match ) => {
        return `<a rel="nofollow" target="_blank" href="${match.getAnchorHref()}">${match.getMatchedText()}</a>`
      }
    } );

    return (
      <span key={token.id}
            style={styles.token}
            dangerouslySetInnerHTML={{__html: t}}/>
    )
  }

  onMouseEnter = (msg) => () => {
    if(!this.state.hoveringMesgId || msg && msg.mesg_id !== this.state.hoveringMesgId){
      this.setState({hoveringMesgId : msg.mesg_id})
    }
  }

  onMouseLeave(){
    this.setState({hoveringMesgId : null})
  }

  showMenu(mesg_id, evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.showPopover(c.popovers.MSG_MENU, mesg_id);
  }

  hidePopover(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
  }

  shouldShowEditedText(msg){
    return msg.edited_flag;
  }

  shouldShowEditLinkOnHover(msg){
    let { accountInfoGuest, mesg_edit_flag } = this.props;
    let isCurrentUser = accountInfoGuest.guest_uid === msg.guest_uid;
    return isCurrentUser && mesg_edit_flag;
  }

  renderMessage(msg){
    let {accountInfoGuest, block, uploadProgress, activeUpload, t } = this.props;
    let { hoveringMesgId} = this.state;

    let showRemovedText = msg.edited_flag && msg.mesg.length === 0 && !(msg.docs && msg.docs.length > 0);
    let showEditedLink = !showRemovedText && this.shouldShowEditedText(msg);

    //Currently if there is a trans_id, but no bill, that indicates that the bill was voided.
    let showPaymentCancelled = !!(!msg.bill && msg.trans_id);
    return (
      <div>
        <span style={styles.message}>
          {msg.tokens.map((token) => {
            if(token.useHtml){
              return this.getLink(token);
            }
            else{
              return (
                <span key={token.id} style={styles.token}>
                  {token.text}
                </span>
              )
            }
          })}
          {showEditedLink &&
            <span className="pl-1" style={styles.editLink}>
            ({t("edited")})
          </span>
          }
          {showRemovedText &&
          <span className="pl-1" style={styles.editLink}>
            ({t("removed")})
          </span>
          }
        </span>
        {msg.isPending && activeUpload && activeUpload.transaction_id === msg.transaction_id &&
          <div>
            {!msg.isInvoice &&
              <ProgressBar percentProgress={_.get(uploadProgress, 'percent')}/>
            }
            {msg.isInvoice &&
              <ProgressBar percentProgress={100}/>
            }
          </div>
        }
        {msg.bill && this.renderBill(msg, msg.bill)}
        {showPaymentCancelled &&
          <div className="pl-1" style={styles.editLink}>
            ({t("Payment Request Canceled")})
          </div>
        }
        {msg.docs && msg.docs.length > 0 &&
        <>
          <div />
          {!msg.isInvoice &&
          <span style={styles.message}>
            {msg.docs.map((doc) => this.renderDoc(msg, doc))}
          </span>
          }
          {msg.isInvoice &&
          <span style={styles.message}>
            {this.renderPendingInvoice(msg)}
          </span>
          }
        </>
        }
      </div>
    )
  }

  onDocClick(doc){
    this.props.docClick(doc);
  }

  renderBill(msg, bill){
    let {
      hoveringMesgId
    } = this.state;
    let showBillControls = hoveringMesgId === msg.mesg_id;
    // log.log('render bill',msg, bill);
    return (
      <div>
        <ThreadBill bill={bill}
                    msg={msg}
                    dm={this.props.dm}
                    showBillControls={isMobile || showBillControls} />
      </div>
    )
  }

  renderPendingInvoice(msg){
    //The only case that we'll be in here is if an invoice is within a messageblock doc.
    //this only happens when we've loaded pending data into the thread.
    //you can't use renderBill() because it uses server data we don't have yet.

    let firstDoc = _.get(msg, 'docs', [])[0];
    let invoice = _.get(firstDoc, 'invoice', {});

    return (
      <div>
        <div className="d-inline-block align-top thread-doc-wrap font-weight-bold secondary-text-color">
          <VFMiddleTruncate
            text={invoice.description}
            start={3}
            end={3}
          />
        </div>
        <div>
          <div className="secondary-text-color">
            <span className="font-weight-bold d-inline">{filters.formatCurrency(this.props.i18n.language, invoice.currency, invoice.amount)}</span>
            <span className="small d-inline ml-2">{invoice.currency}</span>
          </div>
        </div>
      </div>
    )
  }

  renderDoc(msg, doc) {
    let {
      hoveringMesgId
    } = this.state;
    let docInfo = this.props.findDocInfo(doc.doc_id);
    let showDocControls = hoveringMesgId === msg.mesg_id;
    return (
      <ThreadDoc key={doc.doc_id}
                 doc={doc}
                 msg={msg}
                 showControls={showDocControls}
                 docInfo={docInfo}
                 docClick={this.onDocClick}
                 onAttachToThread={this.props.onAttachDocToThread}
                 onPdfSubmit={this.props.onPdfSubmit}
                 dm={this.props.dm}
                 cancelSignatureRequestClick={this.props.cancelSignatureRequestClick}
                 fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                 completeRequestClick={this.props.completeSignatureRequestClick} />
    )
  }

  renderEditedLink(msg){
    let { accountInfoGuest, t } = this.props;
    let isCurrentUser = accountInfoGuest.guest_uid === msg.guest_uid;

    if(isCurrentUser){
      return (
        <a>{t("edited")}</a>
      )
    }
    else{
      return (
        <a>{t("edited")}</a>
      )
    }
  }

  messageEditClick(msg, evt){
    this.hidePopover(evt);
    this.props.onMesgEditClick(msg);
  }

  messageHistoryClick(msg, evt){
    this.hidePopover(evt);
    this.props.onMesgHistoryClick(msg)
  }

  render() {
    let {
      hoveringMesgId
    } = this.state;
    let { guest, block, accountInfoGuest, publisherInfo, t } = this.props;

    let isCurrentUser = accountInfoGuest.guest_uid === block.guest_uid;

    let userName = null;
    let rowStyle = {};
    if(isCurrentUser){
      userName = accountInfoGuest.first_name + ' ' + accountInfoGuest.last_name;
      rowStyle = _.extend(rowStyle,{backgroundColor : colors.THREAD_COLOR_YOU})
    }
    else{
      if(!guest.guest_uid || (!guest.first_name && !guest.last_name)){
        userName = "(" + t("Sender Removed") + ")"
      }
      else{
        userName = guest.first_name + ' ' + guest.last_name;
      }
      rowStyle = _.extend(rowStyle,{backgroundColor : colors.THREAD_COLOR_THEM})
    }

    //This is the size of the badge, plus 5px of margin on both left/right
    let namePadding = UserBadge.MEDIUM_SIZE_WIDTH + 10;

    return (
      <div ref={this.props.innerRef || null}>
        <div className={`${isCurrentUser ? 'text-left' : 'text-right'}`}
             style={isCurrentUser ? {paddingLeft: `${namePadding}px`} : {paddingRight: `${namePadding}px`}}>
          <div className={`font-weight-bold small d-inline-block dark-color`}>
            {userName}
          </div>
        </div>
          {block.blockList.map((msg, blockIndex) => {

            let time = utils.getMomentDate(msg.mesg_date).format('LT');
            let fullTime = utils.getMomentDate(msg.mesg_date).format('lll');
            let isHoveringMessage = hoveringMesgId === msg.mesg_id;
            let borderStyle = {border : `1px solid ${colors.TRANSPARENT}`};
            if(isCurrentUser && isHoveringMessage){
              borderStyle = _.extend(borderStyle, {border : `1px solid ${colors.PRIMARY}`})
            }

            let showEditLink = isHoveringMessage && this.shouldShowEditLinkOnHover(msg);

            return (
              <div key={msg.mesg_id}
                   className={`d-flex ${msg._doNewMsgAnimation ? 'fade-in-up' : ''}`}
                   onMouseEnter={this.onMouseEnter(msg)}
                   onMouseLeave={this.onMouseLeave}
                   style={styles.row}>

                <div style={{...styles.iconWrapper, ...styles.leftIcon}}>
                  {blockIndex === 0 && isCurrentUser && <UserBadge guest={accountInfoGuest} overrideColor={colors.PRIMARY}/>}
                  {!isCurrentUser &&
                    <div className={`d-inline-block`}
                         title={fullTime}
                         style={{...styles.blockTime, ...{paddingLeft : '5px',}}}>
                      {time}
                      <div>
                        {showEditLink &&
                          <a onClick={this.messageEditClick.bind(this, msg)}
                             className="has-pointer hover-underline">{t("edit")}</a>
                        }
                        {!showEditLink &&
                          <a className="hover-underline"></a>
                        }
                      </div>
                    </div>
                  }
                </div>

                <div className="flex-grow-1 message-block-text"
                     style={{...styles.messageBlock, ...rowStyle, ...borderStyle}}>
                  {this.renderMessage(msg)}
                </div>

                <div style={{...styles.iconWrapper, ...styles.rightIcon}}>
                  {blockIndex === 0 && !isCurrentUser && <UserBadge guest={guest} publisherInfo={publisherInfo}/>}
                  {isCurrentUser &&
                    <div className={`d-inline-block`}
                         title={fullTime}
                         style={{...styles.blockTime, ...{paddingRight : '5px',}}}>
                      <div className="text-right">
                        {time}
                        <div>
                          {showEditLink &&
                            <a onClick={this.messageEditClick.bind(this, msg)}
                               className="has-pointer hover-underline">{t("edit")}</a>
                          }
                          {!showEditLink &&
                            <a className="invisible">.</a>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>

              </div>
            )
          })}
      </div>
    )
  }
}

const styles = {


  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },


  row : {
    marginBottom : '5px'
  },
  message: {
    marginBottom: '0px',
    maxWidth : '100%',
    userSelect : 'auto',
  },
  token: {
    maxWidth: '100%',
    whiteSpace : 'pre-wrap',
    overflow : 'hidden',
    // display : 'inline-block',
    wordWrap : 'break-word',
    userSelect : 'auto',
    pointerEvents: 'all',
    cursor: 'text'
  },
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  leftIcon : {
    marginRight : '5px'
  },
  rightIcon : {
    marginLeft : '5px'
  },
  iconWrapper : {
    minWidth: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    minHeight: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    minWidth: '100%'
  },
  linkButton : {
    whiteSpace : 'pre-line',
    wordWrap : 'break-word',
    textAlign : 'left',
    maxWidth: '100%',
    overflow: 'hidden',
    marginTop : '5px',
    marginBottom : '5px',
    marginRight : '5px'
  },
  editIcon : {
    fontSize : '13px',
    color : colors.DARK,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  editDeleteLink : {
    color : colors.SECONDARY_TEXT
  },
  editLink : {
    fontSize : '12px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    cursor: 'default'
  }
}

MessageBlock.propTypes = {
  dm : PropTypes.object,
  guest : PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  docClick : PropTypes.func.isRequired,
  mesg_edit_flag: PropTypes.bool,
  innerRef : PropTypes.func,

  onAttachDocToThread : PropTypes.func,
  onPdfSubmit : PropTypes.func,
  onMesgHistoryClick : PropTypes.func,
  onMesgEditClick : PropTypes.func,
  findDocInfo : PropTypes.func.isRequired,

  completeSignatureRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  cancelSignatureRequestClick : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress,
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    publisherInfo : state.workspace.publisherInfo,
    messageBlocksId : state.thread.messageBlocksId,

    //this is pretty subtle...
    //Including these references ensures that this component re-renders when they change.
    //This triggers a re-render of the component, which will call findDocInfo in renderMessage > renderDoc again,
    //which will catch updates that exist only in the doc list, and not messages.
    activeDMDocs : state.thread.activeDMDocs,
    activeThreadDocs : state.thread.activeThreadDocs,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageBlock)));
