import { PDFDocument, drawText } from 'pdf-lib';
import log from "../util/log";
import Promise from 'bluebird';
import PdfFontLoader from "./pdf-font-loader";
import fontkit from '@pdf-lib/fontkit';
import request from 'superagent';
import { isIE, browserVersion } from 'react-device-detect';
import utils from "../util/util";
import {fontConstants} from "../util/font-constants";
import _ from 'lodash';

class PdfWritingSvc {

  pdfWriter = null;
  fontLoader = null;

  convertDataURIToArraybuffer(img){

    //So IE11 has a problem described by bug 2198, where the step to convert the image
    //to an array buffer failed.  We get a message "Access is Denied" back from the request.
    //It works everywhere else, and I think it's safer since it
    //goes through a larger library.  The method listed in utils seems alright, but I'm not sure
    //it's as well tested.  In the case of ie11 we might as well try it, since the other method won't work.
    if (isIE && +browserVersion === 11) {
      return Promise.resolve(utils.convertDataURIToArrayBuffer(img));
    }
    else {
      return request(img)
        .responseType('arraybuffer')
        .then((res) => {
          return res.body;
        })
    }
  }

  getPageDimensions(page){

    //Weird pdf-lib bug, I've seen pdfs where the height comes back negative.  Not sure why
    //But it causes my math to get weird in places...so if I just do this everything works!
    //I would open a bug with pdf-lib about this, but it's a sensitive document...so I guess just work around it?
    //bug 2776
    return {
      width : Math.abs(page.getWidth()),
      height : Math.abs(page.getHeight())
    }
  }

  hasLoaded(){
    return !!this.pdfWriter;
  }

  load (uintData, skipTeardown) {
    if(!skipTeardown) {
      this.tearDown();
    }

    this.fontLoader = new PdfFontLoader();
    this.fontLoader.loadFonts();
    return PDFDocument.load(uintData, { ignoreEncryption: true })
      .then((res) => {
        if(res.isEncrypted){
          throw new Error('This pdf is encrypted');
        }

        this.pdfWriter = res;

        //Apparently, the new version of pdf-lib throws exceptions on getPages() when certain types of doc protection is used.
        //Before it would throw an exception on load.  Now it throws an exception when we call this.
        //Fixes bug 1931
        this.pdfWriter.getPages();

        this.pdfWriter.registerFontkit(fontkit);
      })
  }

  getUintPdfData() {
    return this.pdfWriter.save({ useObjectStreams: false });
  }

  movePage(fromIndex, toIndex){
    return new Promise((resolve, reject) => {
      this.pdfWriter.copyPages(this.pdfWriter, [fromIndex])
        .then((res) => {
          let [ copiedPage ] = res;

          this.pdfWriter.removePage(fromIndex);
          this.pdfWriter.insertPage(toIndex, copiedPage);
          resolve(true);
        })
        .catch((err) => {
          resolve(true);
        })
    })
  }

  deletePage(index){
    return new Promise((resolve, reject) => {
      this.pdfWriter.removePage(index);
      resolve(true);
    })
  }

  attachAllPages(otherPdfWriter){
    let pageArray = _.map(otherPdfWriter.getPages(), (page, i) => i);
    log.log('attachAllPages', pageArray);
    return this.pdfWriter.copyPages(otherPdfWriter, pageArray)
      .then((copyPageRes) => {
        _.each(copyPageRes, (pageRes) => {
          this.pdfWriter.addPage(pageRes);
        })
      })
  }

  tearDown(){
    this.pdfWriter = null;
  }

  getAllPages(){
    return this.pdfWriter.getPages();
  }

  doesTextIncludeSpecialCharacters(text){
    var font = this.pdfWriter.embedStandardFont(fontConstants.HELVETICA.familyName);

    try {
      let encoded = font.encodeText(text);
    }
    catch(err){
      return true;
    }

    return false;
  }

  static isStandardFont(fontName){
    return [
      fontConstants.HELVETICA.familyName,
      fontConstants.TIMES_NEW_ROMAN.familyName,
      fontConstants.COURIER.familyName
    ].indexOf(fontName) >= 0;
  }

  embedNeededFonts(fontFamily){
    if(PdfWritingSvc.isStandardFont(fontFamily)){
      var font = this.pdfWriter.embedStandardFont(fontFamily);
      return Promise.resolve(font);
    }
    else{
      return this.pdfWriter.embedFont(this.fontLoader.getFontData(fontFamily))
    }
  }

  drawImage(pageIndex, x, y, scale, imgWidth, imgHeight, img){
    return new Promise((resolve, reject) => {
      try {

        let pages = this.pdfWriter.getPages();
        let page  = pages[pageIndex];

        let dimensions = this.getPageDimensions(page);

        //This, and the code below is some complicated code to correct for a hidden rotation in the page.
        //see bug 1724 for more information, as well as https://github.com/Hopding/pdf-lib/issues/65
        let pageRotation = page.getRotation();
        let rotationRads = pageRotation.angle * Math.PI / 180;

        //These coords are now from bottom/left
        let coordsFromBottomLeft = {
          x : (x / scale)
        }
        if(pageRotation.angle === 90 || pageRotation.angle === 270){
          coordsFromBottomLeft.y = dimensions.width - ((y / scale) + imgHeight);
        }
        else{
          coordsFromBottomLeft.y = dimensions.height - ((y / scale) + imgHeight);
        }

        let drawX = null;
        let drawY = null;
        // log.log('dimensions', dimensions);
        // log.log('input xy', x, y);
        // log.log('bottom left coords', coordsFromBottomLeft);
        // log.log('rotation', pageRotation, rotationRads);
        if(pageRotation.angle === 90){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads);
        }
        else if(pageRotation.angle === 180){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
        }
        else if(pageRotation.angle === 270){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads);
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
        }
        else{
          //no rotation
          drawX = coordsFromBottomLeft.x;
          drawY = coordsFromBottomLeft.y;
        }

        // log.log('after rotation coords', drawX, drawY);
        this.convertDataURIToArraybuffer(img)
          .then((res) => {
            log.log('got image buffer', res);

            return this.pdfWriter.embedPng(res);
          })
          .then((embedded) => {
            page.drawImage(embedded, {
              x: drawX,
              y: drawY,
              width: imgWidth,
              height: imgHeight,
              rotate: pageRotation,
            })

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          })
      }
      catch(err){
        log.log('error writing image', err);
        reject(err);
      }
    })
  }

  drawText(pageIndex, x, y, scale, text, font, fontSize){
    return new Promise((resolve, reject) => {
      log.log('drawText', pageIndex, x, y, scale, text, font, fontSize);
      try {
        let pages = this.pdfWriter.getPages();
        let page  = pages[pageIndex];

        let dimensions = this.getPageDimensions(page);
        fontSize = +fontSize.split('px')[0];

        //This, and the code below is some complicated code to correct for a hidden rotation in the page.
        //see bug 1724 for more information, as well as https://github.com/Hopding/pdf-lib/issues/65
        let pageRotation = page.getRotation();
        let rotationRads = pageRotation.angle * Math.PI / 180;

        //These coords are now from bottom/left
        let coordsFromBottomLeft = {
          x : (x / scale)
        }
        if(pageRotation.angle === 90 || pageRotation.angle === 270){
          coordsFromBottomLeft.y = dimensions.width - ((y + fontSize) / scale);
        }
        else{
          coordsFromBottomLeft.y = dimensions.height - ((y + fontSize) / scale);
        }

        let drawX = null;
        let drawY = null;
        // log.log('dimensions', dimensions);
        // log.log('input xy', x, y);
        // log.log('bottom left coords', coordsFromBottomLeft);
        // log.log('rotation', pageRotation, rotationRads);
        if(pageRotation.angle === 90){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads);
        }
        else if(pageRotation.angle === 180){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
        }
        else if(pageRotation.angle === 270){
          drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads);
          drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
        }
        else{
          //no rotation
          drawX = coordsFromBottomLeft.x;
          drawY = coordsFromBottomLeft.y;
        }
        // log.log('after rotation coords', drawX, drawY);

        this.embedNeededFonts(font)
            .then((embeddedFont) => {
              page.drawText(text, {
                x: drawX,
                y: drawY,
                size: (fontSize / scale),
                font: embeddedFont,
                colorRgb: [0, 0, 0],
                rotate: pageRotation,
              })

              resolve(true);
            })
            .catch((err) => {
              log.log('error writing signature point', err);
              reject(err);
            })
      }
      catch(err){
        log.log('error writing signature point', err);
        reject(err);
      }
    })
  }

  drawSignature(signature){
    let { pageIndex, x, y, scale, sigText, font, fontSize } = signature;
    return this.drawText(pageIndex, x, y, scale, sigText, font, fontSize);
  }
}

export default PdfWritingSvc;
