
const OS_TYPES = {
  UNKNOWN : 'unknown',
  WINDOWS : 'windows',
  MAC : 'mac'
}

const platformHelper = {
  OS_TYPES,

  getOSType(ua){
    if(platformHelper.isWindows(ua)){
      return OS_TYPES.WINDOWS;
    }
    else if(platformHelper.isMac(ua)){
      return OS_TYPES.MAC;
    }
    else{
      return OS_TYPES.UNKNOWN;
    }
  },

  isWindows(ua) {
    if(!ua){
      return false;
    }

    let lower = ua.toLowerCase();
    return lower.indexOf('windows') >= 0;
  },

  isMac(ua) {
    if(!ua){
      return false;
    }

    let lower = ua.toLowerCase();
    return lower.indexOf('mac') >= 0;
  },

}

export default platformHelper;
