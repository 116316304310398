import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ColorGenerator from "../../../helpers/color-generator";
import colors from "../../../util/colors";
import c from "../../../util/const";
import _ from "lodash";
import memoizeOne from "memoize-one";

class WorkspaceBadge extends PureComponent {

  getColorStyle = memoizeOne((forum, overrideColor) => {
    let colorStyle = null;
    if(overrideColor){
      colorStyle = {backgroundColor : overrideColor}
    }
    else{
      if(_.get(forum, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT){
        colorStyle = {backgroundColor : ColorGenerator.generateColorFromId(forum.label)}
      }
      else{
        colorStyle = {backgroundColor : ColorGenerator.generateColorFromId(forum.forum_id)}
      }
    }
    return colorStyle;
  })

  getIconClass = memoizeOne((forum) => {
    let iconClass = "icomoon-workspace";
    if(_.get(forum, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT){
      iconClass = "icomoon-newspaper enlarged-badge-font"
    }
    return iconClass;
  })

  render(){
    let { forum, overrideColor, small } = this.props;

    let colorStyle = this.getColorStyle(forum,overrideColor);
    let iconClass = this.getIconClass(forum);

    return (
      <div className="light-color" style={{...(small ? styles.badgeStyleSmall : styles.badgeStyle), ...colorStyle}}>
        <i style={styles.iconStyle} className={`icon ${iconClass}`} />
      </div>
    )
  }

}

const styles = {
  iconStyle : {
    lineHeight : '35px'
  },
  badgeStyle : {
    fontSize: '22px',
    padding: '5px',
    borderRadius: '6px',
    width: '48px',
    height: '48px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '36px',
    border: `1px solid ${colors.LIGHT}`
  },
  badgeStyleSmall : {
    fontSize: '8px',
    padding: '5px',
    borderRadius: '3px',
    width: '22px',
    height: '22px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '10px',
    border: `1px solid ${colors.LIGHT}`
  }
}

WorkspaceBadge.propTypes = {
  forum : PropTypes.object.isRequired,
  overrideColor : PropTypes.string,
  small : PropTypes.bool
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceBadge);
