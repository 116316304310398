import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '../../partials/elements/Button';
import sapi from "../../../util/sapi";
import modalActions from "../../../actions/modal-actions";
import log from "../../../util/log";
import UserBadge from "../../partials/badges/UserBadge";
import colors from "../../../util/colors";
import { getMessageForError, getErrorMessage} from "../../../util/errors";
import ValidationErrors from "../../partials/components/ValidationErrors";

import {last} from "pdf-lib";
import AddContactWindow from "../../modals/AddContactWindow";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../../util/withVFTranslation";
import {validateEmail} from "../../../util/validation";
import utils from "../../../util/util";
import Promise from "bluebird";
import api from "../../../util/api";
import c from "../../../util/const";
import VFPopover from "../components/VFPopover";
import popupHelper from "../../../helpers/popup-helper";
import popoverActions from "../../../actions/popover-actions";

class AddContact extends Component {

  constructor(props) {
    super(props);

    this.firstInputRef = React.createRef();
    this.emailInputRef = React.createRef();

    this.state = {
      first_name : '',
      last_name : '',
      email_address : '',
      validationErr : [],

      validatedEmailAddress : '',
      emailValidationStatus : null,
      needConfirmEmailClickThrough : false,
    }
  }

  componentDidMount() {
    let { searchString } = this.props.modalProps;

    if(searchString){
      let split = searchString.split(' ');
      let consumedTokens = [];
      let foundEmail = '';
      _.each(split, (token, index) => {
        if(token.indexOf("@") > 0){
          consumedTokens.push(index);
          foundEmail = token;
          return false;
        }
      })

      let foundFirstName = '';
      _.each(split, (token, index) => {
        if(consumedTokens.indexOf(index) < 0) {
          if (token.indexOf("@") < 0) {
            consumedTokens.push(index);
            foundFirstName = token;
            return false;
          }
        }
      })

      let foundLastName = '';
      _.each(split, (token, index) => {
        if(consumedTokens.indexOf(index) < 0) {
          consumedTokens.push(index);
          foundLastName += token + ' ';
        }
      })
      foundLastName = _.trim(foundLastName, ' ');
      this.setState({
        first_name : foundFirstName,
        last_name : foundLastName,
        email_address : foundEmail
      })
    }

    let ref = null;
    utils.waitForCondition(() => {
        ref = _.get(this.firstInputRef, 'current');
        return !!(ref)
      })
      .then(() => {
        ref.focus();
      })
  }

  focusEmailInput(){
    let ref = null;
    utils.waitForCondition(() => {
      ref = _.get(this.emailInputRef, 'current');
      return !!(ref)
    })
      .then(() => {
        ref.focus();
      })
  }

  doValidateEmailAddress(email){
    return new Promise((resolve, reject) => {
      api.AccountEmail.validateEmail(email)
        .then((res) => {
          log.log('validate email res', res);
          let validateEmailRes = res.data;
          this.setState({
            emailValidationStatus : validateEmailRes,
            validatedEmailAddress : email
          }, () => resolve(validateEmailRes))
          let didYouMeanSuggestion = _.get(validateEmailRes, 'did_you_mean');
          if(didYouMeanSuggestion){
            this.props.popoverAction.showPopover(c.popovers.DID_YOU_MEAN_MENU)
          }
        })
        .catch((err) => {
          log.log('error during email validation', err);
          resolve(null);
        })
    })
  }

  needValidateEmailAddress(){
    let {
      email_address,
      validatedEmailAddress
    } = this.state;

    //email has changed
    if(email_address !== validatedEmailAddress){
      return true;
    }
    return false;
  }

  closeModal(res) {
    let {close} = this.props;

    close(res);
  }

  doValidation(){
    let err = [];
    let { t } = this.props;

    if(!this.state.first_name){
      err.push(t('Please enter your first name'));
    }

    if(!this.state.last_name){
      err.push(t('Please enter your last name'));
    }

    if(!this.state.email_address || !validateEmail(this.state.email_address)){
      err.push(t("Please check your contact's email address."));
    }

    this.setState({validationErr : err})
    return err.length === 0;
  }

  submit(){
    if(!this.doValidation()){
      return;
    }

    let { t } = this.props;
    let { first_name, last_name, email_address } = this.state;

    let emailValidationPromise = null;
    if(this.needValidateEmailAddress()){
      log.log('need to validate email', email_address);
      emailValidationPromise = this.doValidateEmailAddress(email_address)
    }
    else{
      emailValidationPromise = Promise.resolve(true);
    }

    emailValidationPromise
      .then((emailValidationRes) => {

        log.log('email validate res', emailValidationRes);
        if (utils.hasEmailValidationInvalidFlag(emailValidationRes)) {
          this.setState({needConfirmEmailClickThrough: true});
          return false;
        }

        let contact = {
          first_name : _.trim(first_name),
          last_name : _.trim(last_name),
          email : _.trim(email_address)
        }

        return sapi.Contacts.add([contact])
      })
      .then((res) => {
        log.log('add contact res', res);

        if(!res){
          return;
        }

        if(this.state.needConfirmEmailClickThrough){
          sapi.AccountEmail.validationStatus(email_address, true)
          this.setState({needConfirmEmailClickThrough: false});
        }

        //This api call handles lists of contacts, so the response returns a list of errors.
        //There will only be on when called from here, but we need to look through the error list
        //differently because it's not the usual error response.
        let success = true;
        if(res && res.data){
          _.each(res.data, (contactAddRes) => {
            if(contactAddRes.error){
              success = false;
              //Any error other than APP_GUEST_DUP, we should warn about, because the guest probably didn't get added.
              this.props.showAlert(t('Error adding contact'), getErrorMessage(contactAddRes.error, t));
            }
          })
        }
        else{
          success = false;
          this.props.showAlert(t('Error adding contact'), t("There was a problem adding your contact.  Please try again."));
        }

        if(success) {
          this.closeModal(res.data[0]);
        }
      })
      .catch((err) => {
        this.props.showAlert(t('Error adding contact'), getMessageForError(err, t));
      })
  }

  importCSVClick(){
    this.props.setWindowMode(AddContactWindow.WINDOW_MODES.CSV_UPLOAD);
  }

  renderDidYouMeanContents(popupArgs) {
    let { t } = this.props;
    let { emailValidationStatus } = this.state;
    let didYouMeanSuggestion = _.get(emailValidationStatus, 'did_you_mean');
    if (didYouMeanSuggestion) {
      let useItClick = () => {
        this.setState({
          email_address : didYouMeanSuggestion,
          needConfirmEmailClickThrough : false
        }, () => this.focusEmailInput())
        this.props.popoverAction.hidePopover();
      }
      let loseItClick = () => {
        this.props.popoverAction.hidePopover()
      }

      return popupHelper.getDidYouMeanContents(
        t,
        didYouMeanSuggestion,
        popupArgs,
        () => useItClick(),
        () => loseItClick()
      )
    }
  }

  renderEmailValidationWarningMessage(){
    let { t } = this.props;
    return (
      <div className="alert alert-info mb-0" role="alert">
        <h5 className="alert-heading mb-1">
          <i className="icon ion-information-circled pr-2" />
          {t("Informational")}
        </h5>
        <p style={{fontSize: '14px'}}>
          {t("The email address you entered may contain a typo, or may be an invalid email address. Please double-check what you’ve entered for errors." + " " + t("You can click \"Add Anyway\" if the email address is correct."))}
        </p>
      </div>
    )
  }

  render() {
    let {allowBulkImport, t} = this.props;
    let {validationErr, first_name, last_name, email_address, needConfirmEmailClickThrough} = this.state;

    return (
      <div className="modal-content">

        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Add Contact")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, null)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("First Name:")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={first_name}
                       ref={this.firstInputRef}
                       placeholder={t("Enter your contact's first name")}
                       onChange={(evt) => this.setState({first_name: evt.target.value})}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Last Name:")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={last_name}
                       placeholder={t("Enter your contact's last name")}
                       onChange={(evt) => this.setState({last_name: evt.target.value})}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <VFPopover
                isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DID_YOU_MEAN_MENU)}
                positions={['right', 'bottom']}
                reposition={false}
                containerClassName="preview-container-cls copied-popover-container"
                onClickOutside={() => this.props.popoverAction.hidePopover()}
                getMenuContent={this.renderDidYouMeanContents.bind(this)}>
                <div className="form-group">
                  <label>{t("Email Address:")}</label>
                  <input className={'form-control'}
                         type={'text'}
                         ref={this.emailInputRef}
                         value={email_address}
                         placeholder={t("Enter your contact's email address")}
                         onChange={(evt) => this.setState({email_address: evt.target.value})}/>
                </div>
              </VFPopover>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={[validationErr]} />}
          {needConfirmEmailClickThrough && this.renderEmailValidationWarningMessage()}
          {allowBulkImport &&
            <div className="row">
              <div className="col text-center">
                <a className="btn btn-link primary-color btn-lg hover-underline" onClick={this.importCSVClick.bind(this)}>
                  {t("Import contacts from CSV file")}
                </a>
              </div>
            </div>
          }
        </div>
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this, null)}>{t("Cancel")}</Button>
          <Button className="btn btn-primary"
                  disabled={!first_name || !last_name || !email_address}
                  onClick={this.submit.bind(this)}>{needConfirmEmailClickThrough ? t("Add Anyway") : t("Add")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {

}

const mapStateToProps = (state) => {
  return {
    showingPopoverKey : state.popover.showingPopoverKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

AddContact.propTypes = {
  setWindowMode : PropTypes.func.isRequired,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddContact));
