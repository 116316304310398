import React, {PureComponent, Fragment} from 'react';
import Image from "../elements/Image";
import PropTypes from 'prop-types';
import c from '../../../util/const';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AccountHeader from "./AccountHeader";
import Button from "../elements/Button";
import _ from 'lodash';
import appActions from '../../../actions/app-actions';
import modalActions from '../../../actions/modal-actions';
import log from "../../../util/log";
import colors from "../../../util/colors";
import WorkspaceBadge from "../badges/WorkspaceBadge";
import NotificationIcon from "./NotificationIcon";
import SearchWindow from "../../modals/SearchWindow";
import homeActions from "../../../actions/home-actions";
import workspaceActions from "../../../actions/workspace-actions";
import Scroll from "react-scroll";
import sharedActions from "../../../actions/shared-actions";
import { withTranslation } from 'react-i18next';
import {withVFTranslation} from "../../../util/withVFTranslation";
import companyLogo from '../../../resources/logo-w-text-blue.png'
import proCompanyLogo from '../../../resources/pro-logo-w-text-blue.png'
import goldCompanyLogo from '../../../resources/logo-w-type-gold.png'
import platinumCompanyLogo from '../../../resources/logo-w-type-platinum.png'

class Header extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      searchString : ''
    }
  }

  showSearch(){
    let { searchString } = this.state;
    let {
      archivedWorkspaces,
      unarchivedWorkspaces,
      workspace,
      guests,
      docs,
      threads,
      searchContext,
      directMessages,
    } = this.props;
    if(searchContext === SearchWindow.SEARCH_CONTEXT.HOME){
      this.props.showSearchHome(searchString, _.concat([], archivedWorkspaces, unarchivedWorkspaces), (res) => {
        log.log('search dlg close', res);
        if(res.item) {
          this.setState({searchString: res.searchString})
          this.props.history.push(`/workspace/${res.item.forum_id}`);
        }
      })
    }
    else if(searchContext === SearchWindow.SEARCH_CONTEXT.WORKSPACE){
      this.props.showSearchWorkspace(searchString, workspace, _.concat([], archivedWorkspaces, unarchivedWorkspaces), guests, threads, docs, (res) => {
        log.log('search dlg close', res);
        if(res.item) {
          this.setState({searchString: res.searchString});
          if (res.item.guest_uid) {
            let dm = _.find(directMessages, (dm) => dm.guest_uid === res.item.guest_uid);
            if(dm) {
              this.props.setActiveDM(dm);
            }
          }
          else if (res.item.chat_id) {
            this.props.setActiveThread(workspace.forum_id, workspace.host_uid, res.item)
          }
          else if (res.item.doc_id) {
            this.props.onDocSearchResult(res.item, res.thread);
          }
        }
      })
    }
    else if(searchContext === SearchWindow.SEARCH_CONTEXT.CONTACTS){
      this.props.showSearchContacts(searchString, directMessages, (res) => {
        log.log('search dlg close', res);
        if(res.item) {
          this.setState({searchString: res.searchString});
          let dm = _.find(directMessages, (dm) => dm.guest_uid === res.item.guest_uid);
          if(dm) {
            this.props.setHomeActiveDM(dm)
            this.props.onContactSearchResult(dm.guest_uid);
          }
        }
      })
    }
  }

  showNotifications() {
    this.props.showNotifications(() => {
      log.log('notification window closed');
      this.props.updateNotifications();
    })

  }

  getAccountLogo(){
    let {
      accountClassInfo
    } = this.props;

    let logo_tag = _.get(accountClassInfo, 'class_info.logo_tag');
    if(logo_tag === c.ACCOUNT_LOGO_TYPES.PRO){
      return proCompanyLogo;
    }
    else if(logo_tag === c.ACCOUNT_LOGO_TYPES.GOLD){
      return goldCompanyLogo;
    }
    else if(logo_tag === c.ACCOUNT_LOGO_TYPES.PLATINUM){
      return platinumCompanyLogo;
    }
    else{
      return companyLogo;
    }
  }

  renderHeaderImage(){
    let {
      logo,
      accountLogo,
      workspace,
      accountClassInfo,
      t
    } = this.props;

    let headerLogo = null;
    if (workspace) {
      headerLogo = logo;
    }
    else {
      let inst_id = _.get(accountClassInfo, 'class_info.inst_id');
      if(!inst_id || inst_id === c.BLANK_INST_ID || !_.has(accountClassInfo, 'class_info.inst_logo_flag')){
        headerLogo = accountLogo;
      }
      else{
        let inst_logo_flag = _.get(accountClassInfo, 'class_info.inst_logo_flag');
        if(inst_logo_flag){
          headerLogo = accountLogo;
        }
      }
    }

    let companyLogo = this.getAccountLogo();
    return (
      <>
        {companyLogo &&
        <Image className={'mt-2'} src={companyLogo} alt={t('Logo')} imgHeight={50}/>
        }
        {headerLogo &&
        <Fragment>
          <div className="d-inline-block" style={{
            borderLeft: '1px solid ' + colors.PRIMARY,
            margin: '15px'
          }} />
          <Image className={'mt-2'}
                 src={headerLogo}
                 alt={t('Logo')}
                 imgHeight={50}/>
        </Fragment>
        }
      </>
    )
  }

  render() {
    let { searchString } = this.state;
    let {
      showAcctHeader,
      showSearch,
      showBackButton,
      isLockedToTop,
      logo,
      accountLogo,
      workspace,
      notifyCount,
      accountInfo,
      t
    } = this.props;

    let lockStyle = null;
    if(isLockedToTop){
      lockStyle = {
        position : 'absolute',
        top : '0',
        left: '0',
        right : '0'
      }
    }

    return (
      <div className={'vf-header'} style={lockStyle}>
        <div className={'container-fluid'}>
          <div className={'row'} style={{height: Header.HEADER_HEIGHT + 'px'}}>

            <div className="col-4" >
              <div className="d-flex" style={{overflow: 'hidden', maxWidth: '100%'}}>
                <div style={styles.backButtonWrap}>
                  {showBackButton &&
                    <Link style={styles.backLink} className="btn btn-link" to={'/home'}>
                      <i className={'icon ion-chevron-left pr-2'}/>
                      <i className="icon ion-home primary-color" />
                    </Link>
                  }
                </div>
                <div className="flex-grow-1 header-workspace-wrap">

                  {workspace && showSearch &&
                  <div className="d-flex">
                    <div className="ml-2 mr-2">
                      <WorkspaceBadge forum={workspace}/>
                    </div>
                    <div className="flex-grow-1 header-workspace-name-wrap" style={{lineHeight: '20px'}}>
                      <p style={{marginTop: '10px', marginBottom: '2px', fontWeight: '500'}}
                         className="dark-color auto-ellipsis">
                        {workspace.label}
                      </p>
                      <p className="mb-0 secondary-text-color d-inline-block has-pointer hover-underline"
                         onClick={this.showSearch.bind(this)}>
                        <i style={{fontSize: '18px'}} className="icon ion-ios-search-strong align-baseline mr-1"/>
                        {t('Search')}
                      </p>
                    </div>
                  </div>
                  }

                  {!workspace && showSearch &&
                  <div className="d-flex">
                    <h4 className="ml-3" style={{
                      marginTop: '10px',
                    }}>
                      <button onClick={this.showSearch.bind(this)}
                              className="btn btn-link secondary-text-color d-inline-block text-left">
                        <i style={{fontSize: '18px'}} className="icon ion-ios-search-strong align-baseline mr-2"/>
                        {t('Search')}
                      </button>
                    </h4>
                  </div>
                  }

                </div>
              </div>
            </div>

            <div className="col-4 text-center">
              <div className="d-flex justify-content-center h-100">
                {this.renderHeaderImage()}
              </div>
            </div>

            <div className="col-4">
              <div className="d-flex justify-content-end">
                {accountInfo && !accountInfo.auto_notify_flag && notifyCount > 0 &&
                <div style={styles.badgeIcon}
                     className="mr-3"
                     onClick={this.showNotifications.bind(this)}>
                  <NotificationIcon iconCls="ion-android-notifications"
                                    fontSizeOverride="30px"
                                    value={notifyCount}/>
                </div>
                }
                {showAcctHeader &&
                <div className="pr-3">
                  <AccountHeader/>
                </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  searchWrap : {
    marginTop : '15px'
  },
  searchInputAddon : {
    paddingRight : '2px',
    backgroundColor: colors.LIGHT
  },
  searchInput : {
    borderLeft : 'none',
  },
  backButtonWrap :{
    minWidth : '60px'
  },
  backLink : {
    marginTop: '-5px',
    fontSize : '20px'
  },
  badgeIcon : {
    lineHeight : '30px',
    marginTop: '18px',
    cursor: 'pointer'
  }
}

Header.HEADER_HEIGHT = 70;

Header.propTypes = {
  showBackButton: PropTypes.bool,
  showAcctHeader: PropTypes.bool,
  showSearch: PropTypes.bool,
  isLockedToTop: PropTypes.bool,
  searchContext: PropTypes.string,
  onDocSearchResult: PropTypes.func,
  onContactSearchResult: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    logo : state.workspace.logo,
    accountLogo : state.shared.logo,
    notifyCount : state.shared.notifyCount,
    accountInfo : state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,
    archivedWorkspaces: state.home.archivedWorkspaces,
    unarchivedWorkspaces: state.home.unarchivedWorkspaces,
    guests : state.workspace.guests,
    docs : state.workspace.docs,
    threads : state.workspace.threads,
    directMessages : state.shared.directMessages
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    setHomeActiveDM : (dm) => dispatch(homeActions.setActiveDM(dm)),
    ...sharedActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));
