import _ from 'lodash'
import log from "./log";

//This whole file is meant to wrap the electron ipc interface, if it exists.
//This is populated when the page is loaded via our electron native app.

const electronUtil = {

  isRunningWithinElectron(){
    return !!(this.getElectronAPI());
  },

  getElectronAPI() {
    //If we're operating within an electron environment, this electron api will be populated.
    //This all gets set up in ui-electron/src/preload.js
    return _.get(window, 'vfElectronAPI');
  },

  initPushSvc(){
    let api = this.getElectronAPI();
    if(api){
      api.initPushSvc()
    }
  },

  checkStripeInteractionFlagAndReset(){
    return new Promise((resolve, reject) => {
      let api = this.getElectronAPI();
      if(api){
        api.checkStripeInteractionFlagAndReset()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(true);
      }
    })
  },

  resetPushSvc(){
    let api = this.getElectronAPI();
    if(api) {
      api.resetPushSvc()
    }
  },

  getPushToken(){
    return new Promise((resolve, reject) => {
      let api = this.getElectronAPI();
      if(api){
        api.getPushToken()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(true);
      }
    })
  },

  setCheckVersion(version){
    return new Promise((resolve, reject) => {
      let api = this.getElectronAPI();
      if(api){
        api.setCheckVersion(version)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(true);
      }
    })
  },

  getConfigVersion(){
    return new Promise((resolve, reject) => {
      let api = this.getElectronAPI();
      if(api){
        api.getConfigVersion()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(true);
      }
    })
  },

  trySetNotificationCount(val){
    let api = this.getElectronAPI();
    if(api){
      api.setNotifications(val);
    }
  }
}

export default electronUtil;
