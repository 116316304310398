import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import accountActions from "../../../../actions/account-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class PasswordResetCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      password : '',
      i_understand_text: '',
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    this.setState({
      password : '',
      i_understand_text: '',
      validation_errors: [],
      isSaving: false,
    })
  }

  doValidation(){
    let { password, i_understand_text } = this.state;
    let { passwordInfo, t } = this.props;

    let err = [];
    if(!password || password.length === 0){
      err.push(t("Please enter your password"));
    }

    if(!passwordInfo.disabled_flag) {
      if (!i_understand_text || i_understand_text !== t('I Understand')) {
        err.push(t("Please type \'I Understand\'"));
      }
    }

    this.setState({validation_errors : err})
    return err.length === 0;
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    this.setState({isSaving: true});

    let { password } = this.state;
    let { passwordInfo, doClose, updatePasswordInfo, t } = this.props;

    sapi.Password.togglePasswordReset(!!passwordInfo.disabled_flag, password)
      .then((res) => {
        log.log('toggle password reset res', res);

        return updatePasswordInfo();
      })
      .then((res) => {
        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error changing password', err);
        this.setState({
          validation_errors : [ getMessageForError(err, t) ]
        })
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  getIsSaveDisabled(){
    let { passwordInfo } = this.props;
    let { password, i_understand_text, isSaving } = this.state;

    if(isSaving){
      return true;
    }
    else if(passwordInfo.disabled_flag){
        return password.length === 0;
    }
    else{
      return password.length === 0 || i_understand_text.length === 0;
    }
  }

  render() {
    let { passwordInfo, t } = this.props;
    let { password, i_understand_text, isSaving, validation_errors } = this.state;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>

        <div className={'row'}>
          <div className={'col text-center'}>
            {passwordInfo.disabled_flag &&
            <p>
              {t("To enable password reset for your account, please enter your password.")}
            </p>
            }
            {!passwordInfo.disabled_flag &&
            <div>
              <p>
                {t("You are about to disable password reset for your account. This action requires your password.")}
              </p>
            </div>
            }
          </div>
        </div>

        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={password}
                     onChange={(evt) => this.setState({password: evt.target.value})}
                     placeholder={t('Password')} />
            </div>
          </div>
        </div>
        {!passwordInfo.disabled_flag &&
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <p className="form-text text-muted">
                {t("With password reset disabled, you can no longer access your account using the \"Forgot Password?\" option at login.")}
                {t("To proceed, indicate your understanding that")} <b>{t("YOUR DATA WILL BE UNRECOVERABLE IF YOUR PASSWORD IS LOST OR FORGOTTEN")}</b> {t("by typing \"I Understand\" below.")}
              </p>
              <input className={'form-control'}
                     type={'text'}
                     value={i_understand_text}
                     onChange={(evt) => this.setState({i_understand_text: evt.target.value})}
                     placeholder={t('I Understand')}/>

            </div>
          </div>
        </div>
        }
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-secondary mr-2'} onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={this.getIsSaveDisabled()}
                      className={'btn btn-primary'}
                      onClick={this.save.bind(this)}>{t("Save")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PasswordResetCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    passwordInfo : state.account.passwordInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePasswordInfo : () => dispatch(accountActions.updatePasswordInfo())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetCtrl)));
