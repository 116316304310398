import c from '../../../util/const'
import log from '../../../util/log'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import PdfPrintPreviewSvc from "./PdfPrintPreviewSvc";
import SignatureRequest from "../../../models/SignatureRequest";
import colors from "../../../util/colors";
import pdfPreviewActions from "../../../actions/pdf-preview-actions";
import {getMessageForError} from "../../../util/errors";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {withVFTranslation} from "../../../util/withVFTranslation";
import UserBadge from "../badges/UserBadge";
import ColorGenerator from "../../../helpers/color-generator";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class PDFSignatureRequestHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  nextSignerClick(){
    let { t } = this.props;
    log.log('nextSigner click');

    if(!this.doesCurrentSignerHaveAtLeastOneOverlay()){
      this.props.showAlert(t("No Signatures"), t("Please add at least one Signature Request for this user."))
      return;
    }

    if(this.isLastEditingGuest()){
      this.props.finishSignatureRequestClick();
    }
    else{
      let {
        signatureRequestEditingGuestIndex
      } = this.props.pdfState;
      this.props.pdfActions.setSignatureRequestEditingGuestIndex(signatureRequestEditingGuestIndex + 1);
    }
  }

  doesCurrentSignerHaveAtLeastOneOverlay(){
    let {
      signatureRequestData,
      signatureRequestEditingGuestIndex,
      signatureRequestOverlays
    } = this.props.pdfState;

    let currentGuestUid = signatureRequestData.signer_results[signatureRequestEditingGuestIndex].guest_uid;
    let currentGuestsOverlays = _.filter(signatureRequestOverlays, (o) => o.guest_uid === currentGuestUid);
    return currentGuestsOverlays.length > 0;
  }

  previousSignerClick(){
    log.log('previous signer click');

    let {
      signatureRequestEditingGuestIndex
    } = this.props.pdfState;
    this.props.pdfActions.setSignatureRequestEditingGuestIndex(signatureRequestEditingGuestIndex - 1);
  }

  isFirstEditingGuest(){
    let {
      signatureRequestEditingGuestIndex
    } = this.props.pdfState;
    return signatureRequestEditingGuestIndex === 0;
  }

  isLastEditingGuest(){
    let {
      signatureRequestData,
    } = this.props;

    let {
      signatureRequestEditingGuestIndex
    } = this.props.pdfState;

    return (signatureRequestEditingGuestIndex + 1) === signatureRequestData.signer_results.length;
  }

  renderIncompleteRequest() {
    let {
      t
    } = this.props;
    let {
      signatureRequestEditingGuestIndex,
      signatureRequestData
    } = this.props.pdfState;

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col position-relative">
              <div className="text-left position-absolute"
                   style={{
                     minHeight: '50px',
                     lineHeight: '50px',
                     left: '10px',
                     top: '0px',
                     backgroundColor: colors.LIGHT,
                     zIndex: 10
                   }}>
                {!this.isFirstEditingGuest() &&
                <Button className="btn btn-outline-primary align-baseline"
                        onClick={() => this.previousSignerClick()}>
                  <i className="icon ion-chevron-left mr-2"/>
                  {t("Previous Signer")}
                </Button>
                }
              </div>

              <div className="text-center w-100">
                <Carousel className="user-terms-carousel"
                          onChange={_.noop}
                          showArrows={false}
                          showThumbs={false}
                          showStatus={false}
                          showIndicators={false}
                          selectedItem={signatureRequestEditingGuestIndex}
                >
                  {_.map(signatureRequestData.signer_results, (r) => {
                    let guest = _.find(signatureRequestData.guest_infos, (info) => info.guest_uid === r.guest_uid);
                    return (
                      <div key={r.guest_uid} className="d-flex justify-content-center">
                        <div className="mr-3 text-right"
                             style={{
                               minHeight: '50px',
                             }}>
                          <UserBadge guest={guest}/>
                        </div>
                        <div className="text-left dark-color" style={{minHeight: '50px'}}>
                          <h6 className="mb-0"
                              style={{
                                fontWeight: 'bold'
                              }}>
                            {guest.first_name} {guest.last_name}
                          </h6>
                          <p className="mb-0">
                            {guest.email_address}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </Carousel>
              </div>

              <div className="text-right position-absolute"
                   style={{
                     minHeight: '50px',
                     lineHeight: '50px',
                     right: '10px',
                     top: '0px',
                     backgroundColor: colors.LIGHT,
                     zIndex: 10
                   }}>
                <Button className="btn btn-outline-primary align-baseline"
                        onClick={() => this.nextSignerClick()}>
                  {this.isLastEditingGuest() ? t("Finish Request") : t("Next Signer")}
                  <i className="icon ion-chevron-right ml-2"/>
                </Button>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

  render() {
    let currentGuest = this.props.pdfActions.getCurrentlyEditingGuestInfo();
    if(!currentGuest){
      return null;
    }

    let color = ColorGenerator.generateColorFromId(currentGuest.guest_uid);
    return (
      <div className="light-bg primary-color pt-2 pb-1 transition-border-color"
           style={{
             borderBottom: `5px solid ${color}`
           }}>
        {this.renderIncompleteRequest()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

PDFSignatureRequestHeader.propTypes = {
  signatureRequestData: PropTypes.object.isRequired,
  finishSignatureRequestClick: PropTypes.func.isRequired,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PDFSignatureRequestHeader));
