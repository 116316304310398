import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import accountActions from "../../../../actions/account-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Image from "../../elements/Image";
import Account from "../../../pages/Account";
import _ from "lodash";
import filters from "../../../../helpers/filters";
import colors from "../../../../util/colors";
import modalActions from "../../../../actions/modal-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";
import ExpandableRow from "../../components/ExpandableRow";
import c from "../../../../util/const";
import VFPopover from "../../components/VFPopover";
import popupHelper from "../../../../helpers/popup-helper";
import popoverActions from "../../../../actions/popover-actions";

class TFAAuthenticatorCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      authCode: '',
      pwd: '',
      enablingTFA: false,
      disablingTFA: false,
      showLoading : false,
      isManualStepExpanded : false,
      qrCodeImg : null,
      authCodeSeed : null,
      errors : []
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  initTFAOn(){

  }

  initTFAOff(){
    let { t } = this.props;
    this.setState({showLoading : true})
    sapi.TFA.getQRCode()
      .then((res) => {
        this.setState({
          qrCodeImg : res.data.qrcode,
          authCodeSeed : res.data.seed
        })
      })
      .catch((err) => {
        this.setState({errors : [ getMessageForError(err, t) ]})
        log.log('get qr code error', err);
      })
      .finally(() => {
        this.setState({showLoading : false})
      })
  }

  init() {
    let { passwordInfo } = this.props;

    this.setState({
      authCode: '',
      pwd: '',
      enablingTFA: false,
      disablingTFA: false,
      showLoading : false,
      trustedDevices: null,
      qrCodeImg : null,
      authCodeSeed : null,
      errors : []
    })

    if(passwordInfo.tfa_totp_flag){
      //it's on, get block list
      this.initTFAOn();
    }
    else{
      //then we'll show controls to enable tfa
      //no trusted device list
      this.initTFAOff();
    }
  }

  doEnableTFAValidation(){
    let { t } = this.props;
    let { authCode, pwd } = this.state;

    let err = [];
    if(!authCode || authCode.length === 0){
      err.push(t("Please enter your Authenticator Code"));
    }

    if(!pwd || pwd.length === 0){
      err.push(t("Please enter your password"));
    }

    this.setState({errors : err})

    return err.length === 0;
  }

  doDisableTFAValidation(){
    let { t } = this.props;
    let { pwd } = this.state;

    let err = [];

    if(!pwd || pwd.length === 0){
      err.push(t("Please enter your password"));
    }

    this.setState({errors : err})

    return err.length === 0;
  }

  enableTFA(){
    if(!this.doEnableTFAValidation()){
      return;
    }

    let { updatePasswordInfo, t } = this.props;
    let { authCode, pwd } = this.state;

    this.setState({enablingTFA : true})
    sapi.TFA.enable(authCode, pwd)
      .then((res) => {
        log.log('tfa enabled result', res);
        return updatePasswordInfo();
      })
      .then((res) => {
        setTimeout(() => {
          this.init();
        })
      })
      .catch((err) => {
        log.log('error enabling tfa', err);
        this.setState({errors: [getMessageForError(err, t)]})
      })
      .finally(() => {
        this.setState({enablingTFA : false})
      })
  }

  disableTFA(){
    if(!this.doDisableTFAValidation()){
      return;
    }

    let { updatePasswordInfo, t } = this.props;
    let { pwd } = this.state;

    this.setState({disablingTFA : true})
    sapi.TFA.disable(pwd)
      .then((res) => {
        log.log('tfa disabled result', res);
        return updatePasswordInfo();
      })
      .then((res) => {
        setTimeout(() => {
          this.init();
        })
      })
      .catch((err) => {
        log.log('error disabling tfa', err);
        this.setState({errors: [getMessageForError(err, t)]})
      })
      .finally(() => {
        this.setState({disablingTFA : false})
      })
  }

  cancelTFA(){
    let { doClose } = this.props;

    doClose();
  }

  getTFAEnabledPanel(){
    let { t } = this.props;
    let {
      pwd,
      errors,
      disablingTFA
    } = this.state;

    return (
      <Fragment>
        <div style={Account.styles.rowContents} className={'mb-3'}>
          <div className={'row'}>
            <div className={'col'}>
              <p>
                {t("To disable TFA, please enter your password below.")}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Password")}</label>
                <input className={'form-control'}
                       type={'password'}
                       value={pwd}
                       onChange={(evt) => this.setState({pwd: evt.target.value})}
                       placeholder={t('Password')}/>
              </div>
            </div>
          </div>

          {errors.length > 0 &&
          <div className={'row'}>
            <div className={'col'}>
              <ValidationErrors errors={errors}/>
            </div>
          </div>
          }

          <div className={'row'}>
            <div className={'col'}>
              <div className={'text-right'}>
                {disablingTFA &&
                <Loading inline={true}
                         className={'mr-2'}
                         size={'sm'}/>
                }
                <Button disabled={disablingTFA} className={'btn btn-secondary'} onClick={this.cancelTFA.bind(this)}>{t("Cancel")}</Button>
                <Button disabled={disablingTFA || pwd.length === 0} className={'btn btn-primary ml-2'} onClick={this.disableTFA.bind(this)}>{t("Disable")}</Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  getCopiedPopoverContents() {
    return popupHelper.getCopiedContents(
      this.props.t("Copied!"),
      () => this.props.popoverAction.hidePopover()
    )
  }

  onCopyClick() {
    let {
      authCodeSeed
    } = this.state;
    navigator.clipboard.writeText(authCodeSeed)
      .then(() => {
        this.props.popoverAction.showPopover(c.popovers.COPIED_MENU)
        setTimeout(() => {
          this.props.popoverAction.hidePopover()
        }, 2000);
      })
      .catch((err) => {
        log.log('error copying to clipboard', err);
      })
  }

  getTFADisabledPanel(){
    let { t } = this.props;
    let {
      authCode,
      pwd,
      errors,
      qrCodeImg,
      authCodeSeed,
      enablingTFA,
      isManualStepExpanded
    } = this.state;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <p>
          {t("To enable Two-Factor Authentication")}
        </p>
        <p>
          {t("1. Install an authenticator app on your smart phone.")}
        </p>
        <p>
          {t("2. Use your authenticator app to scan the below QR code.")}
        </p>

        {qrCodeImg &&
          <div className={'row'}>
            <div className={'col text-center'}>
              <Image src={qrCodeImg} imgHeight={225} alt={t('QR Code')}/>
            </div>
          </div>
        }

        {authCodeSeed &&
          <div className={'row'}>
            <div className="col">

              <div className="text-center pb-2 small">
                <a onClick={() => this.setState({isManualStepExpanded : !this.state.isManualStepExpanded})}
                   style={{textDecoration: 'underline'}}
                   target="_blank"
                   className="btn btn-link small primary-color">
                  {(isManualStepExpanded ? t("Hide") : t("Show")) + " " + t("Manual Setup")}
                  <i className={`pl-2 ion-chevron-${isManualStepExpanded ? 'up' : 'down'} icon`}/>
                </a>
              </div>

              <ExpandableRow isActive={isManualStepExpanded}>
                <div className="pb-2">
                  <div className="px-5 small">
                    {t("For manual setup, copy the key below, open the authenticator, and paste the key into the authenticator.")}
                  </div>
                  <div className="px-5 py-2">
                    <div className="input-group">
                      <input type="text"
                             value={authCodeSeed}
                             readOnly={true}
                             className="form-control"/>
                      <div className="input-group-append">
                        <VFPopover
                          isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.COPIED_MENU)}
                          positions={['right', 'bottom']}
                          reposition={false}
                          containerClassName="preview-container-cls copied-popover-container"
                          onClickOutside={() => this.props.popoverAction.hidePopover()}
                          getMenuContent={this.getCopiedPopoverContents.bind(this)}>
                          <button className="btn btn-outline-primary" type="button"
                                  onClick={() => this.onCopyClick()}>
                            <i className="ion-clipboard icon"/>
                          </button>
                        </VFPopover>
                      </div>
                    </div>
                  </div>
                </div>
              </ExpandableRow>
            </div>
          </div>
        }

        <p>
          {t("3. Save a copy or your QR code by printing it or storing an image of it.")} <span className="font-weight-bold">{t("If your authenticator is lost and you don't have a copy of your QR code, you may not be able to regain access to your account.")}</span>
        </p>

        <p>
          {t("4. Enter your password and the current 6-digit code from your authenticator. Next time you log in, you'll enter a fresh code.")}
        </p>

        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={pwd}
                     onChange={(evt) => this.setState({pwd: evt.target.value})}
                     placeholder={t('Enter your password')}/>
            </div>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Authenticator Code")}</label>
              <input className={'form-control'}
                     type={'text'}
                     value={authCode}
                     onChange={(evt) => this.setState({authCode: evt.target.value})}
                     placeholder={t('Enter the current code from your authenticator')}/>
            </div>
          </div>
        </div>

        {errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={errors}/>
          </div>
        </div>
        }

        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {enablingTFA &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={enablingTFA} className={'btn btn-secondary'} onClick={this.cancelTFA.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={enablingTFA || pwd.length === 0 || authCode.length === 0} className={'btn btn-primary ml-2'} onClick={this.enableTFA.bind(this)}>{t("Enable")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      showLoading
    } = this.state;

    let { passwordInfo } = this.props;

    if(showLoading){
      return (
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <Loading centered={true} size={'sm'}/>
        </div>
      )
    }

    if(passwordInfo.tfa_totp_flag){
      return this.getTFAEnabledPanel();
    }
    else{
      return this.getTFADisabledPanel();
    }
  }
}

TFAAuthenticatorCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    passwordInfo: state.account.passwordInfo,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePasswordInfo: () => dispatch(accountActions.updatePasswordInfo()),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TFAAuthenticatorCtrl)));
