import React, {Component} from 'react';
import PropTypes from 'prop-types';

import browser from '../../../util/browser';

import dots from '../../../resources/three-dots.svg';

import c from '../../../util/const';
import util from '../../../util/util';
import log from "../../../util/log";
import _ from 'lodash';
import classNames from 'classnames';

class PulseButton extends Component {

  constructor(props){
    super(props);

    this.state = {
      centerRadius: 30,
      hovering: false,
      useSwellAnimation : browser.isChrome() || browser.isSafari()
    }
  }

  onClick(evt){
    this.props.onClick(evt)
  }

  onMouseEnter(evt){
    let { useSwellAnimation } = this.state;

    if(useSwellAnimation) {
      this.setState({
        centerRadius: 35,
        hovering: true
      })
    }
    else{
      this.setState({
        hovering: true
      })
    }
  }

  onMouseLeave(evt){
    let { useSwellAnimation } = this.state;

    if(useSwellAnimation) {
      this.setState({
        centerRadius: 30,
        hovering: false
      })
    }
    else{
      this.setState({
        hovering: false
      })
    }
  }

  render() {
    let {
      btnText ,
      isLoading,
      align,
      className,
      disabled
    } = this.props;

    let {
      centerRadius,
      hovering,
      useSwellAnimation
    } = this.state;

    let firstRadius = centerRadius + 5;
    let secondRadius = firstRadius + 5;
    let thirdRadius = secondRadius + 5;

    let wrapStyle = {};
    let centerFillColor = '#fff';
    let centerStrokeColor = '#fff';
    let centerStyle = {};
    if(!useSwellAnimation){
      centerStyle.transition = 'fill 250ms ease-in-out, stroke 250ms ease-in-out';
    }
    if(!disabled && hovering){
      wrapStyle.cursor = 'pointer';

      if(!useSwellAnimation){
        centerFillColor = '#dddddd';
        centerStrokeColor = '#dddddd';
      }
    }

    if(align){
      if(align === 'right'){
        wrapStyle.marginLeft = 'auto';
      }
      else if( align === 'left'){
        wrapStyle.marginRight = 'auto';
      }
    }

    return (
      <div className={classNames(className, 'pulse-btn')}
           style={wrapStyle}
           onMouseEnter={!disabled ? this.onMouseEnter.bind(this) : _.noop}
           onMouseLeave={!disabled ? this.onMouseLeave.bind(this) : _.noop}
           onClick={!disabled ? this.onClick.bind(this) : _.noop}>
        {!isLoading &&
        <div className={'pulse-btn-text'}>
          {btnText}
        </div>
        }
        {isLoading &&
        <div className={'pulse-btn-loading'}>
          <img src={dots}/>
        </div>
        }
        <svg>
          <circle className={classNames('center')} style={centerStyle} cx="50%" cy="50%" r={centerRadius}
                  strokeWidth={'1'} stroke={centerStrokeColor}
                  fill={centerFillColor}/>
          <circle className="pulse" cx="50%" cy="50%" r={firstRadius}
                  strokeWidth={'1'} stroke="white"
                  fill="transparent"/>
          <circle className="pulse" cx="50%" cy="50%" r={secondRadius}
                  strokeWidth={'1'} stroke="white"
                  fill="transparent"/>
          <circle className="pulse" cx="50%" cy="50%" r={thirdRadius}
                  strokeWidth={'1'} stroke="white"
                  fill="transparent"/>
        </svg>
      </div>
    )
  }
}

PulseButton.propTypes = {
  onClick : PropTypes.func.isRequired,
  btnText : PropTypes.string.isRequired,
  disabled : PropTypes.bool,
  isLoading: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right'])
}

export default PulseButton;
