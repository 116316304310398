import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getErrorMessage, getMessageForError} from "../../../util/errors";

import FancyInput from '../elements/FancyInput';
import PulseButton from '../elements/PulseButton';
import Button from '../elements/Button';

import authActions from "../../../actions/auth-actions";
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import colors from "../../../util/colors";
import _ from 'lodash';
import PropTypes from "prop-types";
import modalActions from "../../../actions/modal-actions";
import {withVFTranslation} from "../../../util/withVFTranslation";
import moment from "moment";

class StripePaymentCard extends Component {
  
  formRef = null;
  formStripe = null;
  formElements = null;
  
  constructor(props){
    super(props);
    
    this.state = {
      stripePromise : null,
      valid_code_deets : null,
      stripeInputFocused : false,
      stripeCardElementIsEmpty : true,
      
      formRef : null,
      formStripe : null,
      formElements : null
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    
    api.Stripe.pubKey()
      .then((res) => {
        let pub_key = _.get(res, 'data.pub_key');
        this.setState({
          stripePromise : pub_key ? loadStripe(pub_key) : null
        })
      })
      .catch((err) => {
        log.log('error getting available plans', err);
      })
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  validateForm() {
    let { email } = this.state;
    
    this.props.setValidationErrors([]);
    
    return false;
  }
  
  onSubmitButtonClick(stripe, elements, evt) {
    if(evt) evt.preventDefault();
    let { t } = this.props;
    return new Promise((resolve, reject) => {
      stripe.createPaymentMethod({
          type: 'card',
          
          //This is a weird parameter, and is NOT documented in the stripe docs.
          //You don't need to pass the rest of the card elements into the card.
          //You just give stripe the first element and it will apparently detect the rest.  Weird guys.
          //https://github.com/stripe/react-stripe-elements/issues/482
          // card: elements.getElement(CardNumberElement),
          card: elements.getElement(CardElement),
          
          //This is an expected stripe field
          //We could pass more if we wanted.
          //https://stripe.com/docs/js/payment_methods/create_payment_method
          // billing_details: {
          //   address: {
          //     postal_code: postal,
          //   },
          // },
        })
        .then((res) => {
          const {error, paymentMethod} = res;
          log.log('payment method res', error, paymentMethod);
          
          if (error && !_.isEmpty(error)) {
            // code: "invalid_number"
            // message: "Your card number is invalid."
            // type: "validation_error"
            reject(error.message);
            //this.props.setValidationErrors([error.message]);
          }
          else if (!paymentMethod || !paymentMethod.id) {
            //I'm not sure this is a real case.  I guarded against it because the docs did.
            reject(getErrorMessage("APP_UNKNOWN_ERROR", t));
          }
          else {
            //this.props.setValidationErrors([]);
            resolve(paymentMethod.id);
            //this.props.setPaymentMethodId(paymentMethod.id)
            
            // let { upgradePlans } = this.props;
            // let {valid_code_deets} = this.state;
            // let couponId = _.get(valid_code_deets, 'coupon.id');
            // this.onSuccessfulPaymentMethod(paymentMethod.id, upgradePlans.class_info.class_id, couponId)
          }
        })
    })
  }
  
  onCardElementFocus(){
    this.setState({stripeInputFocused : true})
  }
  
  onCardElementBlur(){
    this.setState({stripeInputFocused : false})
  }
  
  onCardElementChange(evt){
    this.setState({
      stripeCardElementIsEmpty : evt.empty,
    })
    this.props.setValidationErrors([]);
  }
  
  renderCardElement(){
    //This stripe input is kind of awkward to work with here
    //There are inner states of the field that don't play well with our
    //styling of fancy-input
    //I'll just say that the options they claim to support in the style object just
    //don't work the way they say they do - https://stripe.com/docs/js/appendix/style
    //Nothing inherits properly with respect to the base style.
    let cardElementCls = "fancy-input fancy-input-animate-cls form-control light-bg";
    if(this.state.stripeInputFocused){
      cardElementCls += " active";
    }
    
    return (
      <>
        <div className="form-group">
          <CardElement className={cardElementCls}
                       onChange={this.onCardElementChange.bind(this)}
                       onFocus={this.onCardElementFocus.bind(this)}
                       onBlur={this.onCardElementBlur.bind(this)}
                       options={{
                         iconStyle: 'solid',
                         style: {
                           base: {
                             iconColor: colors.PRIMARY,
                             fontSize: '16px',
                             lineHeight: '1.5',
                             '::placeholder': {
                               fontSize: '14px',
                               color: colors.PRIMARY,
                             },
                             ':focus': {
                               color: colors.PRIMARY,
                             },
                           },
                           invalid: {
                             iconColor: colors.ASSERTIVE,
                             color: colors.ASSERTIVE,
                           },
                           complete:{
                             color: colors.PRIMARY,
                           }
                         },
                       }}/>
        </div>
      </>
    )
  }
  
  doFormSubmit(){
    
    return this.onSubmitButtonClick(
      this.formStripe,
      this.formElements
    )
  }
  
  onFormRef(ref, stripe, elements){
    this.formRef = ref;
    this.formStripe = stripe;
    this.formElements = elements;
  }
  
  render() {
    let { stripePromise, valid_code_deets } = this.state;
    let { step, isLoading, hideControls, i18n } = this.props;
    
    if(!stripePromise){
      return null;
    }
  
    if(hideControls){
      return null;
    }
    return (
      <>
        <Elements stripe={stripePromise} options={{locale: i18n.language}} key={i18n.language}>
          <ElementsConsumer>
            {({stripe, elements}) => {
              return (
                <>
                  <form ref={(ref) => this.onFormRef(ref, stripe, elements)}
                        onSubmit={this.onSubmitButtonClick.bind(this, stripe, elements)}>
                    <div>
                      <div className="row">
                        <div className="col">
                          {this.renderCardElement()}
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )
            }}
          </ElementsConsumer>
        </Elements>
      </>
    )
  }
}

StripePaymentCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  hideControls : PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    step : state.auth.step,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible)),
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(StripePaymentCard));
