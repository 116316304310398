import React, {PureComponent, Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import utils from '../../../util/util';
import c from '../../../util/const';

import moment from 'moment';
import Promise from 'bluebird';
import _ from 'lodash';
import {List, AutoSizer} from 'react-virtualized';
import Measure from "react-measure";
import msgHelper from "../../../helpers/msg-helper";
import RenderPortal from "../util/RenderPortal";
import SignatureRequest from "../../../models/SignatureRequest";
import VirtualMessageBlock from "./VirtualMessageBlock";
import RenderedChatMessage from "../../../models/RenderedChatMessage";
import ChangeManagerUpdate from "../../../models/ChangeManagerUpdate";

class MessagePanelChangeManager extends Component {

  static EVENT_TYPES = {
    INIT : 'init',
    MESSAGE_ADD : 'msg_add',
    MESSAGE_UPDATE : 'msg_update',
    LOAD_MORE : 'load_more'
  }

  sizeCache = {};

  MESSAGE_BLOCK_PAGE_SIZE = 30;

  constructor(props) {
    super(props);

    this.state = {
      currentPageIndex : -1,

      currentLoadingDMOrChatID : -1,
      isLoadingMoreMessages : false,
      hasMoreMessages : false,
      renderingPage : null,

      //all messages that have corresponding size values in sizeCache
      renderedMessages : [],

      //evts expected to be array of keypair values:
      // let obj = {
      //   event_type: '',
      //   callback: {}
      // }
      updateListeners : [],

      waitingForLoadingToBeDone : false
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    this.detectUpdates(null, null);
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.detectUpdates(prevProps, prevState);
  }

  detectUpdates(prevProps, prevState){
    let prevDM = _.get(prevProps, 'dm');
    let curDM = _.get(this.props, 'dm');
    let prevThread = _.get(prevProps, 'thread');
    let curThread = _.get(this.props, 'thread');

    let prevMessageBlockId = _.get(prevProps, 'messageBlocksId');
    let thisMessageBlockId = _.get(this.props, 'messageBlocksId');

    // log.log('detect updates', prevDM, curDM, prevThread, curThread);
    // log.log('detect updates - dms', prevDM, curDM);
    // log.log('detect updates', prevMessageBlockId, thisMessageBlockId);
    if((!prevMessageBlockId && thisMessageBlockId) || (prevMessageBlockId && thisMessageBlockId && prevMessageBlockId !== thisMessageBlockId)){
      if(curDM){
        this.initForDM(curDM.guest_uid);
      }
      else if(curThread){
        this.initForThread(curThread.chat_id);
      }
    }
    else if(prevMessageBlockId && thisMessageBlockId && prevMessageBlockId === thisMessageBlockId && prevProps.messageBlocks !== this.props.messageBlocks){

      //process message block update here!

      //This looks a little funny, but we can't process multiple updates at once, and need to guard against that.
      //So if we're working on one while another comes in, just wait and do another pass when you're done.
      //Important to note that this approach requires that we build messageblock diffs with the CURRENT messageBlock
      //references, so that if we ignored an intermediate update, we'll catch it by processing a larger update the next pass.
      // log.log('*** got update event top', this.props.messageBlocks);
      if (this.isDoingPlannedLoad()) {
        if(this.state.waitingForLoadingToBeDone){
          //if we're already waiting on another update, just bail, we'll catch it in the next pass.
          log.warn('*** ignored update event, already waiting for one');
          return;
        }
        this.setState({waitingForLoadingToBeDone : true})
        log.log('*** got update event, but waiting until planned load finished');
        // console.time('wait-planned-load')
        utils.waitForCondition(() => {
          return !this.isDoingPlannedLoad()
        }, 10)
          .then(() => {
            // console.timeEnd('wait-planned-load')

            this.setState({waitingForLoadingToBeDone : false})
            let diffResult = this.getMessageIdsWithDifferences(prevProps.messageBlocks, this.props.messageBlocks);
            log.log('*** done waiting for update event', diffResult);
            if(diffResult) {
              this.handleMessageListUpdate(diffResult);
            }
          })
      }
      else{
        let diffResult = this.getMessageIdsWithDifferences(prevProps.messageBlocks, this.props.messageBlocks);
        // log.log('*** got update event', diffResult, prevProps.messageBlocks, this.props.messageBlocks);
        if(diffResult) {
          this.handleMessageListUpdate(diffResult);
        }
      }
    }
  }

  isDoingPlannedLoad(){
    let {
      currentLoadingDMOrChatID,
      currentPageIndex,
      isLoadingMoreMessages,
      renderingPage
    } = this.state;

    return !!(currentLoadingDMOrChatID < 0 || isLoadingMoreMessages || currentPageIndex < 0 || (renderingPage && renderingPage.length > 0));
  }

  getMessageIdsWithDifferences(prevMessageBlocks, thisMessageBlocks){
    // console.time('getMessageIdsWithDifferences');
    let beforeMesgIds = [];
    let afterMesgIds = [];
    let beforeMesgHashes = {};
    let afterMesgHashes = {};

    let afterPendingMsgs = {};

    _.each(prevMessageBlocks, (beforeBlock) => {
      _.each(beforeBlock.blockList, (msg) => {
        beforeMesgIds.push(msg.mesg_id);
        beforeMesgHashes[msg.mesg_id] = msgHelper.generateMessageHash(msg);
      })
    })
    _.each(thisMessageBlocks, (afterBlock) => {
      _.each(afterBlock.blockList, (msg) => {
        afterMesgIds.push(msg.mesg_id);
        afterMesgHashes[msg.mesg_id] = msgHelper.generateMessageHash(msg);
        if(msg.isPending){
          afterPendingMsgs[msg.mesg_id] = msg;
        }
      })
    })

    //This is kind of sneaky.  the docs on _.difference() actually only check for
    //values in the first array that don't exist in the second.
    //however we need the full list of differences because we have to process all changes.
    //Maybe there's a better way to do it, but I just diff both, then make a unique list of the diff.
    //This situation only happens when a pending message goes away and is not replaced by a server message result,
    //For example when there was a message sending error, and the UploadManager kills the upload.
    let addRemove1 = _.difference(afterMesgIds, beforeMesgIds);
    let addRemove2 = _.difference(beforeMesgIds, afterMesgIds);
    let addedOrRemoved = _.uniq(_.concat(addRemove1, addRemove2))

    // log.log('msg diffs top', beforeMesgIds, afterMesgIds, afterPendingMsgs, addedOrRemoved);
    let hasUpdate = [];
    //We check for things added or removed above, this only needs to look through things that exist in both places
    _.each(beforeMesgIds, (mesg_id) => {
      if(afterMesgHashes[mesg_id] && afterMesgHashes[mesg_id] !== beforeMesgHashes[mesg_id]){
        hasUpdate.push(mesg_id);
      }
    })

    _.each(addedOrRemoved, (mesg_id) => {
      let afterPending = afterPendingMsgs[mesg_id];
      if(afterPending && afterPending.isCompleteTransaction){
        //then we detected a message that has finished sending, and
        //it's new mesg_id is present in the list.
        //since we already did a message add here for the start of this
        //pending transaction, let's just do an update on this.
        // log.log('found complete tx in added or removed, moving to update', afterPending);
        hasUpdate.push(mesg_id);
        _.remove(addedOrRemoved, (id) => id === mesg_id);
      }
    })

    let res = _.uniq(_.concat(hasUpdate, addedOrRemoved));
    // log.log('generatedMessages with differences', res, hasUpdate, addedOrRemoved, afterPendingMsgs);
    // console.timeEnd('getMessageIdsWithDifferences');
    if(res.length > 0){
      //added or removed is kind of a misnomer, because we don't allow a message delete.
      //so it's really just added, even though in code we handle either case.
      return new ChangeManagerUpdate(addedOrRemoved, hasUpdate, res);
    }
    else{
      return null;
    }
  }

  emitEvent(type, data){
    _.each(this.state.updateListeners, (listener) => {
      if(listener.event_type === type){
        listener.callback(data);
      }
    })
  }

  initForDM(guest_uid){
    if(this.state.currentLoadingDMOrChatID === guest_uid){
      log.log('change mgr - init messages dm ignoring', guest_uid);
      return;
    }

    // log.warn('change mgr - init messages dm', guest_uid);
    this.setState({currentLoadingDMOrChatID : guest_uid}, () => {
      this.initMessages();
    })
  }

  initForThread(chat_id){
    if(this.state.currentLoadingDMOrChatID === chat_id){
      log.log('change mgr - init messages thread ignoring', chat_id);
      return;
    }

    // log.warn('change mgr - init messages thread', chat_id);
    this.setState({currentLoadingDMOrChatID : chat_id}, () => {
      this.initMessages();
    })
  }

  initMessages() {
    // log.log('change mgr - init messages', this.state);
    // log.log('change mgr - init messages, waiting for ', this.state.currentLoadingDMOrChatID);
    utils.waitForCondition(() => {
      return this.state.currentLoadingDMOrChatID === this.props.messageBlocksId && !this.state.isLoadingMoreMessages;
    })
        .then(() => {
          // log.warn('change mgr - init messages, done waiting for ', this.state.currentLoadingDMOrChatID);

          this.sizeCache = {};
          // log.log('change mgr - about to reset msg list', this.state);
          this.resetMessageList()
              .then(() => {
                // log.log('init done rendering', this.state.renderedMessages, this.sizeCache);

                let result = [];
                _.each(this.state.renderedMessages, (msg, i) => {
                  result.push(RenderedChatMessage.buildRenderedChatMessage(msg, this.sizeCache[RenderedChatMessage.getKeyForMessageBlock(msg)] || 1))
                })

                this.emitEvent(MessagePanelChangeManager.EVENT_TYPES.INIT, result);
              })
              .catch((err) => {
                log.log('error resetting message list', err);
              })
        })
  }

  setBlockHeightWithoutUpdate(index, height){
    let update = _.concat([], this.state.renderedMessages);
    let found = update[index];
    if(found){
      update[index] = RenderedChatMessage.buildRenderedChatMessage(found.getMessageData(), height);
    }
  }

  loadMoreMessages(e){
    // log.log('changemanager - loadmore called', e);
    if(this.state.isLoadingMoreMessages){
      log.log('load more messages called, but were already loading something', this.state.isLoadingMoreMessages);
      return Promise.resolve(true);
    }

    if(!this.state.hasMoreMessages){
      log.log('load more messages called, but hasMoreMessages is false', this.state.renderedMessages, this.props.messageBlocks);
      return Promise.resolve(true);
    }

    // log.log('load more messages!', this.state);
    return new Promise((resolve, reject) => {
      let nextPageIndex = this.state.currentPageIndex + 1;

      let slice = this.getPageDataForIndex(nextPageIndex);
      // log.log('loading next slice', nextPageIndex, slice);
      if(!slice){
        resolve(true);
        return;
      }

      // log.log('loadMoreMessages render page', _.map(slice, (p) => p.blockList[0].mesg_id));
      this.setState({
        isLoadingMoreMessages : true,
        currentPageIndex : nextPageIndex,
        renderingPage : slice,
      }, () => {
        this.waitForPortalToRender()
          .then(() => {

            // log.log('load more emitting event', !(slice.length < this.MESSAGE_BLOCK_PAGE_SIZE));
            this.setState({
              renderingPage : null,
              hasMoreMessages : (slice.length === this.MESSAGE_BLOCK_PAGE_SIZE),
              renderedMessages : _.concat([], slice, this.state.renderedMessages)
            }, () => {
              this.setState({isLoadingMoreMessages : false}, () => {
                let result = [];
                _.each(this.state.renderedMessages, (msg, i) => {
                  result.push(RenderedChatMessage.buildRenderedChatMessage(msg, this.sizeCache[RenderedChatMessage.getKeyForMessageBlock(msg)] || 1))
                })

                this.emitEvent(MessagePanelChangeManager.EVENT_TYPES.LOAD_MORE, result);
              })
              resolve(true);
            })
          })
      })
    })
  }

  setEventHandlers(evts){
    this.setState({updateListeners : evts});
  }

  getPageDataForIndex(pageIndex){
    let sortedBlocks = _.sortBy(this.props.messageBlocks, (b) => +RenderedChatMessage.getKeyForMessageBlock(b));
    // log.log('get page data for index', pageIndex, _.chunk(_.reverse(sortedBlocks), this.MESSAGE_BLOCK_PAGE_SIZE));
    let slice = _.chunk(_.reverse(sortedBlocks), this.MESSAGE_BLOCK_PAGE_SIZE)[pageIndex];
    // log.log('get page data for index2', slice);
    return _.reverse(slice);
  }

  handleContainerSizeChange(){
    //the idea is clear the cache for all items in renderedMessages
    //so that waitForPortalToRender will properly wait for everything

    log.log('before handle container size change', this.sizeCache);
    let newRenderingPage = [];
    _.each(this.state.renderedMessages, (renderedMessage) => {
      let sizeCacheKey = RenderedChatMessage.getKeyForMessageBlock(renderedMessage);
      delete this.sizeCache[sizeCacheKey];
      newRenderingPage.push(renderedMessage);
    })

    log.log('handle container size change', this.sizeCache, newRenderingPage);
    this.setState({
      renderingPage : newRenderingPage,
    }, () => {
      this.waitForPortalToRender()
        .then(() => {
          this.setState({
            renderingPage : null,
          }, () => {
            let result = [];
            _.each(this.state.renderedMessages, (msg, i) => {
              result.push(RenderedChatMessage.buildRenderedChatMessage(msg, this.sizeCache[RenderedChatMessage.getKeyForMessageBlock(msg)] || 1))
            })

            this.emitEvent(MessagePanelChangeManager.EVENT_TYPES.MESSAGE_UPDATE, result);
          })
        })
    })
  }

  handleMessageListUpdate(diffResult){
    // log.log('handleMessageListUpdate', diffResult, _.extend({}, this.sizeCache));

    // console.time('handleMessageListUpdate')
    let {
      messageBlocks
    } = this.props;

    //This is trickier than it might appear.  We detect updated blocks
    //and then re-render any pages of data those new blocks might sort to.
    //It's possible this could pass in more than needed?
    //it's much more efficient for groups of updates because we
    //remeasure whole pages at a time.  This also catches cases
    //where blocks measure to different sizes depending on how it combines with the surrounding data.

    let blocksWithUpdates = _.map(diffResult.allMessages, (mesg_id) => {
      return _.find(messageBlocks, (block) => _.find(block.blockList, (msg) => msg.mesg_id === mesg_id))
    })

    //log.log('blocks with updates', blocksWithUpdates);
    blocksWithUpdates = _.uniqBy(blocksWithUpdates, (block) => RenderedChatMessage.getKeyForMessageBlock(block));

    let slicesToRerender = [];
    for(let i = 0; i <= this.state.currentPageIndex;i++){
      let slice = this.getPageDataForIndex(i);
      _.each(blocksWithUpdates, (block) => {
        let foundInSlice = _.find(slice, (b) => RenderedChatMessage.getKeyForMessageBlock(b) === RenderedChatMessage.getKeyForMessageBlock(block))
        if(foundInSlice){
          slicesToRerender.push(i);
        }
      })
    }

    //blocksWithUpdates could have more than one block that maps to a given slice.
    slicesToRerender = _.uniq(slicesToRerender);

    let newRenderingPage = [];
    _.each(slicesToRerender, (sliceIndex) => {
      let foundSlice = this.getPageDataForIndex(sliceIndex);
      _.each(foundSlice, (block) => {
        let sizeCacheKey = RenderedChatMessage.getKeyForMessageBlock(block);
        delete this.sizeCache[sizeCacheKey];
        newRenderingPage.push(block);
      })
    })

    // log.log('found new blocks in update', newRenderingPage);

    this.setState({
      renderingPage : newRenderingPage,
    }, () => {
      this.waitForPortalToRender()
        .then(() => {
          // log.log('done waiting for portal', newRenderingPage);
          //this is a little tricky, but since we don't know how a new block has been combined with the
          //rest of the list, we don't necessarily know how to manually insert it into state.renderedMessages.
          //First we need to measure the new blocks, so they have entries in this.sizeCache
          //Now have things measured, but this.state.renderedMessages is out of date.
          //so rather than do the math on where to put things, just loop through all visible pages
          //and re-assemble things.  This works because getPageDataForIndex works against this.props.messageBlocks,
          //which has already been updated with the latest info.

          //One edge case here, is that since we're re-assembling the rendered message list against the master
          //you might have one or two messages at the top bumped out of the current page data, by the incoming.
          //I'm not sure this is important, just worth mentioning.  Things should work itself out in loadMore and via ui updates.
          let renderUpdate = [];
          for(let i = 0; i <= this.state.currentPageIndex; i++){
            let page = this.getPageDataForIndex(i);
            if(page) {
              renderUpdate = _.concat(renderUpdate, page);
            }
          }
          this.setState({
            renderingPage : null,
            renderedMessages : _.concat([], renderUpdate)
          }, () => {

            // log.log('done waiting for page to clear', newRenderingPage);
            if(diffResult.newMessages.length > 0){
              let result = [];
              _.each(this.state.renderedMessages, (msg, i) => {
                let renderedMessage = RenderedChatMessage.buildRenderedChatMessage(msg, this.sizeCache[RenderedChatMessage.getKeyForMessageBlock(msg)] || 1);

                //Add a _doNewMsgAnimation flag to any message coming back in the 'message add' event
                //this allows us to animate it in.
                //consider a more robust approach if you do this more frequently.  This feels a little fragile.
                //Putting this here also means that we need to pull it in clearAnimationFlags after it's rendered.
                //I'm not really happy with this solution.
                _.each(diffResult.newMessages, (mesg_id) => {
                  let found = _.find(msg.blockList, (m) => m.mesg_id === mesg_id)
                  if(found){
                    found._doNewMsgAnimation = true;
                  }
                })
                result.push(renderedMessage)
              })

              this.emitEvent(MessagePanelChangeManager.EVENT_TYPES.MESSAGE_ADD, result);
              setTimeout(() => {
                this.clearAnimationFlags()
              })
            }
            else{
              let result = [];
              _.each(this.state.renderedMessages, (msg, i) => {
                // _.each(msg.blockList, (msg) => {
                //   delete msg.$isNew;
                // })
                let renderedMessage = RenderedChatMessage.buildRenderedChatMessage(msg, this.sizeCache[RenderedChatMessage.getKeyForMessageBlock(msg)] || 1);
                result.push(renderedMessage)
              })

              this.emitEvent(MessagePanelChangeManager.EVENT_TYPES.MESSAGE_UPDATE, result);
            }
            // console.timeEnd('handleMessageListUpdate')
          })
        })
    })
  }

  clearAnimationFlags(){
    return new Promise((resolve, reject) => {
      let {
        renderedMessages
      } = this.state;

      let copy = _.concat([], renderedMessages);
      _.each(renderedMessages, (msg) => {
        _.each(msg.blockList, (m) => {
          m._doNewMsgAnimation = false;
        })
      })
      this.setState({renderedMessages : copy}, () => resolve(true));
    })
  }

  resetMessageList(){
    return new Promise((resolve, reject) => {
      utils.waitForCondition(() => {
          return this.props.messageBlocks
        })
        .then(() => {
          let slice = this.getPageDataForIndex(0);

          if(!slice){
            this.setState({
              currentPageIndex : 0,
              isLoadingMoreMessages : false,
              renderingPage : null,
              hasMoreMessages : false,
              renderedMessages : []
            }, () => {
              resolve(true);
            })
            return;
          }

          this.setState({
            currentPageIndex : 0,
            renderingPage : slice,
            renderedMessages : [],
            hasMoreMessages : (slice.length === this.MESSAGE_BLOCK_PAGE_SIZE)
          }, () => {
            //console.time('portal-render');
            this.waitForPortalToRender()
              .then(() => {
                //console.timeEnd('portal-render');
                this.setState({
                  isLoadingMoreMessages : false,
                  renderingPage : null,
                  hasMoreMessages : (this.state.renderingPage.length === this.MESSAGE_BLOCK_PAGE_SIZE),
                  renderedMessages : this.state.renderingPage ? _.concat([], this.state.renderingPage) : []
                }, () => {
                  // log.log('change mgr - done resetting msg list', this.state);
                  resolve(true);
                })
              })
          })
        })
    })
  }

  hasCurrentPageRendered(){

    //A little subtle, we don't add the current page to renderedMessages until
    //after it's done rendering, but we share the sizeCache.
    //So that means we need to calculate this.

    if(!this.state.renderingPage || !this.state.renderedMessages){
      return false;
    }
    else{
      let isMissingABlock = false;
      _.each(this.state.renderingPage, (p) => {
        let sizeCacheKey = RenderedChatMessage.getKeyForMessageBlock(p);
        if(!_.isNumber(this.sizeCache[sizeCacheKey])){
          //then we're not ready
          isMissingABlock = true;
          return false;
        }
      })

      return !isMissingABlock;
    }
  }

  waitForPortalToRender(){
    return utils.waitForCondition(() => {
      return this.hasCurrentPageRendered();
    }, 0)
  }

  getPortalRowHeight(index){
    let messageBlock = this.state.renderingPage[index];
    let mesgId = RenderedChatMessage.getKeyForMessageBlock(messageBlock);

    //log.log('getPortalRowHeight', index, mesgId)
    return this.sizeCache[mesgId] || 1;
  }

  onPortalItemLoad(index, height) {
    let messageBlock = this.state.renderingPage[index];
    let mesgId = RenderedChatMessage.getKeyForMessageBlock(messageBlock);
    //log.log('onPortalItemLoad', index, mesgId, height, this.state.renderingPage);
    if (!this.sizeCache[mesgId]) {
      this.sizeCache[mesgId] = height;
    }
  }

  portalRowRenderer = ({ index, style, parent }) => {
    const itemData = {
      dm: this.props.dm,
      thread: this.props.thread,
      mesg_edit_flag : false,
      onMesgEditClick : _.noop,
      onMesgHistoryClick : _.noop,
      onDocClick: _.noop,
      findDocInfo: this.props.findDocInfo,
      completeSignatureRequestClick : _.noop,
      fulfillSignatureRequestClick : _.noop,
      cancelSignatureRequestClick : _.noop,
      findGuestInActiveThreadParticipants : this.props.findGuestInActiveThreadParticipants
    }
    let {
      panelHeight,
      panelWidth
    } = this.props;
    //log.log('portalRowRenderer', index);
    return (
      <div key={index} style={style}>
        <VirtualMessageBlock currentItem={this.state.renderingPage[index]}
                             windowHeight={panelHeight}
                             windowWidth={panelWidth}
                             index={index}
                             onHeightUpdate={height => this.onPortalItemLoad(index, height)}
                             data={itemData}
                             previousItem={index > 0 ? this.state.renderingPage[index - 1] : null}/>
      </div>
    );
  };

  renderMeasuringPortal(){
    let {
      renderingPage,
      currentLoadingDMOrChatID
    } = this.state;

    let {
      panelHeight,
      panelWidth
    } = this.props;

    if(!renderingPage){
      return null;
    }

    return (
      <RenderPortal key={currentLoadingDMOrChatID}>
        <div className="d-block position-relative h-100 no-outline">
          <List
            height={panelHeight}
            width={panelWidth}
            overscanRowCount={renderingPage.length}
            rowCount={renderingPage.length}
            rowHeight={obj => this.getPortalRowHeight(obj.index)}
            rowRenderer={this.portalRowRenderer}
          />
        </div>
      </RenderPortal>
    )
  }

  render(){
    return this.renderMeasuringPortal();
  }
}

MessagePanelChangeManager.propTypes = {
  dm : PropTypes.object,
  thread : PropTypes.object,
  panelHeight : PropTypes.number.isRequired,
  panelWidth : PropTypes.number.isRequired,
  onRef : PropTypes.func.isRequired,
  findDocInfo : PropTypes.func.isRequired,
  findGuestInActiveThreadParticipants : PropTypes.func.isRequired,

  messageBlocks: PropTypes.array,
  messageBlocksId : PropTypes.string,
  accountInfo : PropTypes.object
}

export default MessagePanelChangeManager;
