import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Button from "../elements/Button";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfFulfillSignatureRequestHeader extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
    
    }
  }
  
  cancelBtnClick() {
    this.props.cancelBtnClick();
  }
  
  render() {
    let { bannerText, bannerCountText, t } = this.props;

    return (
        <>
          <div style={{paddingTop: '14px', paddingBottom: '14px'}}
               className="container-fluid signing-request-bg light-color">
            <div className="row">
              <div className="col-3">
              </div>
              <div className="col-6 text-center">
                {bannerText &&
                <div style={{fontSize: '14px', lineHeight: 1, verticalAlign: 'text-bottom', marginBottom: '3px'}}>
                  {bannerText}
                </div>
                }
                {bannerCountText &&
                <div style={{fontSize: '14px', lineHeight: 1, verticalAlign: 'text-bottom'}}>
                  {bannerCountText}
                </div>
                }
              </div>
              <div className="col-3 text-right">
                <Button className="btn btn-sm btn-outline-light mx-3"
                        onClick={this.cancelBtnClick.bind(this)}>
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          </div>
        </>
    )
  }
}

const styles = {

}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfFulfillSignatureRequestHeader.propTypes = {
  cancelBtnClick : PropTypes.func.isRequired,
  bannerText : PropTypes.string,
  bannerCountText : PropTypes.string,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfFulfillSignatureRequestHeader));
