import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import PulseButton from '../elements/PulseButton';
import FancyInput from '../elements/FancyInput';
import Button from '../elements/Button';
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import _ from "lodash";
import stripeHelper from "../../../util/stripe-helper";
import {withTranslation, Trans} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import utils from "../../../util/util";
import popoverActions from "../../../actions/popover-actions";

class BoardingCard extends Component {

  constructor(props){
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      firstNameValidationErr: null,
      lastNameValidationErr: null,
      boardingSuccessful: false
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    setTimeout(() => {
      let { email, emailCardRef } = this.props;
      if(!email){
        if(emailCardRef){
          emailCardRef.focusEmail();
        }
      }
      else{
        this.firstNameField.focus();
      }
    }, 250)
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  validateForm() {
    let { emailCardRef, email } = this.props;
    let { first_name, last_name } = this.state;

    this.props.setValidationErrors([]);
    this.setState({
      firstNameValidationErr: null,
      lastNameValidationErr: null,
    });

    let success = true;
    //Check email from props first.
    if(!email || email.length === 0){
      let refEmail = emailCardRef.getValidatedEmailValue();
      if(!refEmail || refEmail.length === 0){
        emailCardRef.triggerValidationErr();
        success = false;
      }
    }

    if(!first_name || first_name.length === 0){
      this.setState({
        firstNameValidationErr: true
      })
      this.firstNameField.triggerValidationErr();
      success = false;
    }

    if(!last_name || last_name.length === 0){
      this.setState({
        lastNameValidationErr: true
      })
      this.lastNameField.triggerValidationErr();
      success = false;
    }

    return success;
  }

  doNextButtonClicked(){
    this.doBoarding(this.props.paymentMethodId);
  }

  handleStripe3DIfNeeded(res, payment_method_id){
    //In payment cases we need to handle potential 3d auth cases, so do it, then re-call with updated payment data.
    // {
    //   "data" : {
    //   "payment_error" : {
    //     "client_secret" : "seti_1HXXywFJy4l0f3VY8PtaUrx7_secret_I7nauc13LtAZY7hJcgvD68B07BYDseb",
    //       "payment_method_id" : "pm_1HXXyuFJy4l0f3VY0WRmTlEu",
    //       "seti_id" : "seti_1HXXywFJy4l0f3VY8PtaUrx7"
    //   },
    //   "payment_flag" : false,
    //     "test" : {
    //     "code" : "48655186"
    //   }
    // },
    //   "success" : true
    // }
    return new Promise((resolve, reject) => {
      log.log('handleStripe3DIfNeeded', res);
      let { t } = this.props;
      let stripeClientSecret = _.get(res, 'data.payment_error.client_secret');
      let hasPaymentFlag = _.get(res, 'data.payment_flag');
      if (!hasPaymentFlag && stripeClientSecret) {
        log.warn('got partial fail');
        stripeHelper.handleStripe3DAuth(this.props.stripe, stripeClientSecret, payment_method_id, t)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        resolve(false);
      }
    })
  }

  updateEmailValidationResults(email){
    return new Promise((resolve, reject) => {
      this.props.setEmailValidationPerformed(true);
      api.AccountEmail.validateEmail(email)
        .then((res) => {
          log.log('validate email res', res);
          this.props.setEmailValidationResults(res.data);
          this.props.setShowingInvalidEmailWarning(utils.hasEmailValidationInvalidFlag(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          log.log('error during email validation', err);
          resolve(null);
        })
    })
  }

  doWeNeedToValidateEmail(){
    let {
      emailValidationPerformed,
      emailValidationResults
    } = this.props;

    return !emailValidationPerformed || !emailValidationResults;
  }

  doBoarding(paymentMethodId){
    let { first_name, last_name } = this.state;
    let {
      setStep,
      setEmail,
      email,
      memberNumber,
      setEmailFieldVisible,
      emailCardRef,
      institution,
      targetClassId,
      validCouponDeets,
      t
    } = this.props;

    if(!this.validateForm()){
      return;
    }

    this.props.setLoading(true);

    let reqEmail = email;
    if(!reqEmail || reqEmail.length === 0){
      reqEmail = emailCardRef.getValidatedEmailValue();
    }

    let validateEmailPromise = Promise.resolve(true);
    if(this.doWeNeedToValidateEmail()){
      validateEmailPromise = this.updateEmailValidationResults(reqEmail)
    }

    validateEmailPromise
      .then((validateEmailRes) => {
        let isValidResult = !utils.hasEmailValidationInvalidFlag(validateEmailRes);
        if(!isValidResult){
          //re-enable email focus behavior if we show the warning.
          this.props.setPreventEmailDisabled(false);
          this.setState({showEmailValidationWarning : true});

          let didYouMeanSuggestion = _.get(validateEmailRes, 'did_you_mean');
          if(didYouMeanSuggestion){
            this.props.popoverAction.showPopover(c.popovers.DID_YOU_MEAN_MENU)
          }
          return Promise.reject('invalid-email');
        }


        let language = this.props.i18n.language;
        let couponId = _.get(validCouponDeets, 'coupon.id', null);
        let domainFlag = _.get(institution, 'info_json.domain_flag', null);
        let boarding = null;
        if(domainFlag){
          //domain flag means call lookup with inst_id, and null member number.  bug 2966
          boarding = api.Boarding.post(reqEmail, first_name, last_name, institution.inst_id, null, paymentMethodId, targetClassId, couponId, language);
        }
        else if(memberNumber){
          boarding = api.Boarding.post(reqEmail, first_name, last_name, institution.inst_id, memberNumber, paymentMethodId, targetClassId, couponId, language);
        }
        else{
          boarding = api.Boarding.post(reqEmail, first_name, last_name, null, null, paymentMethodId, targetClassId, couponId, language)
        }

        return boarding;
      })
      .then((res) => {
        return this.handleStripe3DIfNeeded(res, paymentMethodId);
      })
      .then((res) => {
        log.log('stripe res', res);
        setEmail(reqEmail);
        setEmailFieldVisible(false);
        this.props.setHideAuthButtonControls(true);
        this.setState({
          boardingSuccessful : true,
        })
        this.props.setLoading(false);
      })
      .catch((err) => {
        this.props.setLoading(false);

        //user gets warned.
        if(err === 'invalid-email'){
          return;
        }

        this.props.setValidationErrors([
          stripeHelper.getMessageForStripeOrVfError(err, t)
        ]);
      })
  }

  onStartOverButtonClick() {
    let { setStep, setEmailFieldVisible, setPreventEmailDisabled } = this.props;

    setStep(c.authSteps.email);
    setEmailFieldVisible(true);
    setPreventEmailDisabled(false);
  }

  onFirstNameChange(val){
    this.setState ({
      first_name: val
    })
  }

  onLastNameChange(val){
    this.setState ({
      last_name: val
    })
  }

  showInfo(){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Why do we need a name?'),
      <div>
        <p>
          {t("It’s nice to know what to call you! We use this name when we send you emails. It just seems friendlier that way.")}
        </p>
        <p>
          {t("More importantly, we use this name when you reach out to others in Verifyle. It helps them recognize and identify you.")}
        </p>
        <p className="pt-3">
          {t("Contact us at")}
          <br/>
          <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
        </p>
      </div>
    )
  }

  render() {
    let { firstNameValidationErr, lastNameValidationErr, boardingSuccessful } = this.state;
    let { email, step, t } = this.props;

    let card = null;
    if(boardingSuccessful){
      card =
        <div className={'light-color text-center'}>
          <h3>{t("Check your Email")}</h3>
          <p>
            {t("We sent an email to")} <br/><b>{email}</b><br/> {t("to make sure you are you.")}
          </p>
          <hr className={'light-border'} />
          <div className={'text-center'}>
            <Button className="btn btn-link light-color"
                    disabled={this.props.isLoading}
                    onClick={() => this.props.doStartOverButtonClick()}>{t("Start Over")}</Button>
          </div>
        </div>
    }
    else{
      card =
        <div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <FancyInput onRef={ref => (this.firstNameField = ref)}
                            placeholder={t('First Name')}
                            inputDisabled={false}
                            fieldType={'text'}
                            isValid={!firstNameValidationErr}
                            inputValue={this.state.first_name}
                            infoBtnCls={'ion-ios-information-outline auth-info-icon has-pointer'}
                            onInfoClick={this.showInfo.bind(this)}
                            onEnter={this.doNextButtonClicked.bind(this)}
                            onChange={this.onFirstNameChange.bind(this)}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <FancyInput onRef={ref => (this.lastNameField = ref)}
                            placeholder={t('Last Name')}
                            inputDisabled={false}
                            fieldType={'text'}
                            isValid={!lastNameValidationErr}
                            inputValue={this.state.last_name}
                            onEnter={this.doNextButtonClicked.bind(this)}
                            onChange={this.onLastNameChange.bind(this)}/>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={'col'}>
              <div className={'light-color mb-4'} style={{fontSize : '14px'}}>
                <Trans t={t}>
                  By clicking "Next" you agree to Verifyle's <a target={'_blank'} className={'auth-link'} href={c.links.tos}>terms of use</a> and <a target={'_blank'} className={'auth-link'} href={c.links.privacy}>privacy policy</a>.
                </Trans>
              </div>
            </div>
          </div>
        </div>
    }

    return (
      <div>
        {card}
      </div>
    );
  }
}

//There's some weird handling in here around the email
//If you come in via query string, we take the user right to boarding without
//populating email first.  We need to look at the existing email card and pull
//values from there manually in that case.
BoardingCard.propTypes = {
  emailCardRef : PropTypes.object,
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  setHideAuthButtonControls : PropTypes.func.isRequired,
  doStartOverButtonClick : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  paymentMethodId : PropTypes.string,
  targetClassId : PropTypes.number,
  stripe : PropTypes.object,
  validCouponDeets : PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    memberNumber : state.auth.memberNumber,
    institution : state.auth.institution,
    step : state.auth.step,
    emailValidationResults : state.auth.emailValidationResults,
    emailValidationPerformed : state.auth.emailValidationPerformed,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    showAuthInfo: (title, contents, cb) => dispatch(modalActions.showAuthInfo(title, contents, cb)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled)),
    setEmailValidationResults : (res) => dispatch(authActions.setEmailValidationResults(res)),
    setEmailValidationPerformed : (res) => dispatch(authActions.setEmailValidationPerformed(res)),
    setShowingInvalidEmailWarning : (isShowing) => dispatch(authActions.setShowingInvalidEmailWarning(isShowing)),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(BoardingCard));
