import ColorHash from "color-hash";
import util from "../util/util";

class ColorGenerator {
  
  static colorHash = null;
  
  static createColorHashIfNeeded(){
    if(ColorGenerator.colorHash){
      return ColorGenerator.colorHash;
    }
    
    ColorGenerator.colorHash = new ColorHash({
      lightness: 0.6,
      hue: [
        {min: 30, max: 90},
        {min: 180, max: 210},
        {min: 270, max: 285}
      ],
      saturation: 0.7,
      hash: ColorGenerator.hashString,
    });
  }
  
  static hashString(str){
    var hash = util.generateHashCode(str);
    
    //For some reason the color hashing algorithm doesn't like big negative numbers.
    //Just a fast and semi random way to tweak them.
    if(hash < 0){
      hash = Math.abs(hash << 5);
    }
    return hash;
  }
  
  static generateColorFromId(id) {
    ColorGenerator.createColorHashIfNeeded();
    
    return ColorGenerator.colorHash.hex(id);
  }
}



export default ColorGenerator;
