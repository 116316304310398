import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import DMPanelHeader from "./DMPanelHeader";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import uploadActions from "../../../actions/upload-actions";
import sharedActions from "../../../actions/shared-actions";
import AttachedDocsPanel from "../docs/AttachedDocsPanel";
import Scroll from "react-scroll";
import UpgradeDialogNew from "../../modals/UpgradeDialogNew";
import modalActions from "../../../actions/modal-actions";
import SignatureRequest from "../../../models/SignatureRequest";
import {getMessageForError} from "../../../util/errors";
import PendingMsgCache from "../../../helpers/pending-msg-cache";
import utils from "../../../util/util";
import msgHelper from "../../../helpers/msg-helper";
import ChatPanelFooter from "./ChatPanelFooter";
import workspaceActions from "../../../actions/workspace-actions";
import ChatPanelMessages from "./ChatPanelMessages";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import homeActions from "../../../actions/home-actions";
import threadActions from "../../../actions/thread-actions";

class DMPanel extends PureComponent {

  constructor(props) {
    super(props);

    this.chatFileDrop = this.chatFileDrop.bind(this);
    this.onThreadDocClick = this.onThreadDocClick.bind(this);
    this.refreshMessages = this.refreshMessages.bind(this);
    this.onMsgPanelRef = this.onMsgPanelRef.bind(this);
    this.onFooterPanelRef = this.onFooterPanelRef.bind(this);
    this.onDocPanelRef = this.onDocPanelRef.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.state = {
      chatMessagesRef: null,
      docsPanelRef: null,
      footerRef: null,
      sendingMsg: false,
      contactInfo: null
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }

    utils.waitForCondition(() => {
      return !!this.state.footerRef && !!this.props.dm;
    })
      .then(() => {
        this.updateContactInfo();
        let cached = PendingMsgCache.fetchDm(this.props.dm.guest_uid);
        log.log('dm panel init', cached, this.state.footerRef);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
      })
  }

  componentWillUnmount() {
    setTimeout(() => {
      let { footerRef } = this.state;
      if(this.props.dm && footerRef) {
        let previousData = footerRef.getPendingData();
        PendingMsgCache.cacheDm(this.props.dm.guest_uid, previousData.msg, previousData.docs);
      }
    })

    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.dm && (prevProps.dm !== this.props.dm)){

      //Kind of ugly, but the reference can change, but the ids are the same.
      //We only want to run this if they're changing the panel.
      if(!prevProps.dm || prevProps.dm.guest_uid !== this.props.dm.guest_uid) {
        if (prevProps.dm) {
          //save previous pending data, and restore the upcoming data
          let previousData = this.state.footerRef.getPendingData();
          PendingMsgCache.cacheDm(prevProps.dm.guest_uid, previousData.msg, previousData.docs);
        }
        let cached = PendingMsgCache.fetchDm(this.props.dm.guest_uid);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);

        // if(prevProps.dm) {
        //   setTimeout(() => {
        //     this.state.chatMessagesRef.scrollToBottomWhenReady();
        //   })
        // }

        this.updateContactInfo();
      }
    }
  }

  mergeAttachDocsWithCurrentMsg(docs){
    let files = [];
    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-');
      files.push(f);
    })

    this.state.footerRef.updatePendingDocs(files);
  }

  updateContactInfo(){
    sapi.Contacts.get(this.props.dm.guest_uid)
      .then((res) => {
        this.setState({contactInfo : res.data})
      })
      .catch((err) => {
        log.error('error fetching contact info', err);
        this.setState({contactInfo : null})
      })
  }

  onMsgPanelRef(panelRef){
    this.setState({chatMessagesRef: panelRef})
  }

  onFooterPanelRef(panelRef){
    this.setState({footerRef: panelRef})
  }

  onDocPanelRef(panelRef){
    this.setState({docsPanelRef: panelRef})
  }

  doMessagePolling(){
    return this.props.refreshMessages(true)
      .then((res) => {
        let doFullUpdate = false;
        let newMsgs = _.get(res, 'data.mesg_data', []);
        if(newMsgs.length > 0){
          doFullUpdate = true;
        }
        return doFullUpdate;
      })
      .then((res) => {
        if(res){
          let { dm, workspace} = this.props;
          return Promise.all([
            this.props.updateDirectMessages(),
            this.props.updateNotifications(),
            this.props.threadAction.refreshDocsDM(),
            workspace ?  this.props.refreshDocs(workspace.forum_id, workspace.host_uid) : Promise.resolve(true)
          ])
        }
      })
  }

  refreshMessages(useDataDate) {
    let { dm, workspace} = this.props;
    return Promise.all([
      this.props.refreshMessages(useDataDate),
      this.props.updateDirectMessages(),
      this.props.updateNotifications(),
      this.props.threadAction.refreshDocsDM(),
      workspace ?  this.props.refreshDocs(workspace.forum_id, workspace.host_uid) : Promise.resolve(true)
    ])
  }

  sendMessageWithDocs(pendingMsg, pendingFiles){
    let {dm, workspace, t} = this.props;

    let pdfSubmitFiles = [];
    let docAttachFiles = [];
    let sigRequestFiles = [];
    let nonSigRequestFiles = [];
    let invoiceFiles = [];
    _.each(pendingFiles, (f) => {
      if(f.isInvoice){
        invoiceFiles.push(f);
      }
      else if(f.isPDFXHRUpload){
        pdfSubmitFiles.push(f);
      }
      else if(f.is_doc_copy){
        docAttachFiles.push(f);
      }
      else {
        if (f.signature_requested) {
          sigRequestFiles.push(f);
        }
        else {
          nonSigRequestFiles.push(f);
        }
      }
    })

    let queuePromise = Promise.resolve(true);
    let last_forum_id = workspace ? workspace.forum_id : null;
    let msgSent = false;
    if(invoiceFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueDMInvoiceTransaction(docAttachTransaction, dm.guest_uid, invoiceFiles, msgSent ? null : pendingMsg);
      this.props.queueDMInvoice(docAttachTransaction, upload_id, dm.guest_uid, invoiceFiles, msgSent ? null : pendingMsg, last_forum_id);
      msgSent = true;
    }
    if(nonSigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      msgSent = true;
      this.props.queueDMDocTransaction(transaction_id, null, dm.guest_uid, upload_id, nonSigRequestFiles, pendingMsg);
      this.props.queueDMUpload(transaction_id, upload_id, dm.guest_uid, false, nonSigRequestFiles, pendingMsg, last_forum_id);
    }
    if(sigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueDMDocTransaction(transaction_id, null, dm.guest_uid, upload_id, sigRequestFiles, msgSent ? null : pendingMsg);
      this.props.queueDMUpload(transaction_id, upload_id, dm.guest_uid, true, sigRequestFiles, msgSent ? null : pendingMsg, last_forum_id);
      msgSent = true;
    }
    if(docAttachFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueDMDocAttachTransaction(docAttachTransaction, dm.guest_uid, docAttachFiles, msgSent ? null : pendingMsg);
      this.props.queueDMDocAttach(docAttachTransaction, upload_id, dm.guest_uid, docAttachFiles, msgSent ? null : pendingMsg, last_forum_id);
      msgSent = true;
    }
    if(pdfSubmitFiles.length > 0){
      const queuePdfSubmit = (pdfSubmit) => {
        let pdfSubmitTxn = _.uniqueId('vf-transaction-id');
        let upload_id = _.uniqueId('vf-upload-id-');
        this.props.queueDMDocTransaction(pdfSubmitTxn, null, dm.guest_uid, upload_id, [pdfSubmit], msgSent ? null : pendingMsg);
        //transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, mesg, filename, pdfData, last_forum_id
        this.props.uploadActions.queuePDFSubmitUpload(pdfSubmitTxn, upload_id, null, null, dm.guest_uid, null, pdfSubmit.doc_id, null, pdfSubmit.signature_requested, msgSent ? null : pendingMsg, pdfSubmit.filename, pdfSubmit.data, last_forum_id);
      }

      //If there's only one pdf submit, and we haven't attached a message yet, attach it as part of the
      //submit call, so the file is attached to the message.
      //Otherwise, just send it attached to nothing, and queue the pdf submit
      //Otherwise, just send all pdfs, one at a time.
      if(pdfSubmitFiles.length === 1 && !msgSent){
        queuePdfSubmit(pdfSubmitFiles[0])
        msgSent = true;
      }
      else if(!msgSent && (pendingMsg && pendingMsg.length > 0)){
        queuePromise = new Promise((resolve, reject) => {
          this.sendMessageForPdfSubmit(pendingMsg)
            .then((res) => {
              msgSent = true;
              _.each(pdfSubmitFiles, (pdfSubmit) => {
                queuePdfSubmit(pdfSubmit);
              })
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            })
        })
      }
      else{
        _.each(pdfSubmitFiles, (pdfSubmit) => {
          queuePdfSubmit(pdfSubmit);
        })
      }
    }

    return queuePromise
      .then((res) => {
        this.state.footerRef.clear();

        return this.refreshMessages()
          .then(() => {
            PendingMsgCache.clearDM(dm.guest_uid);
            setTimeout(() => {
              this.state.chatMessagesRef.scrollToBottom(250);
            })
          })
          .catch((err) => {
            log.log('error during message refresh', err);
            this.state.footerRef.setNotSending();
            this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
          })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
  }

  sendMessageForPdfSubmit(pendingMsg){

    return new Promise((resolve, reject) => {
      let {dm, workspace, t} = this.props;
      let transaction_id = _.uniqueId('vf-transaction-id');
      let last_forum_id = workspace ? workspace.forum_id : null;
      this.props.queueDMMsgTransaction(transaction_id, dm.guest_uid, pendingMsg);
      sapi.DM.sendMessage(dm.guest_uid, pendingMsg, last_forum_id)
        .then((res) => {
          log.log('send message res', res);

          this.props.mapTransactionResult(transaction_id, res);
          resolve(res);
        })
        .catch((err) => {
          log.log('error during message send', err);
          this.props.mapTransactionResult(transaction_id, null);
          this.state.footerRef.setNotSending();
          this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
          reject(err);
        })
    })
  }

  sendMessage(pendingMsg, pendingFiles) {
    let {dm, workspace, t} = this.props;

    if(!dm){
      console.warn('sendMessage called with no DM present');
      return null;
    }

    pendingMsg = msgHelper.trimWhitespace(pendingMsg);

    if(pendingFiles.length > 0){
      this.sendMessageWithDocs(pendingMsg, pendingFiles)
        .catch((err) => {
          log.log('error during dm message sendwdocs', err);
        })
      return;
    }

    let transaction_id = _.uniqueId('vf-transaction-id');

    let last_forum_id = workspace ? workspace.forum_id : null;
    this.props.queueDMMsgTransaction(transaction_id, dm.guest_uid, pendingMsg);
    this.setState({sendingMsg: true})
    sapi.DM.sendMessage(dm.guest_uid, pendingMsg, last_forum_id)
      .then((res) => {
        log.log('send message res', res);

        this.props.mapTransactionResult(transaction_id, res);
        this.state.footerRef.clear();

        return this.refreshMessages()
      })
      .then(() => {
        PendingMsgCache.clearDM(dm.guest_uid);
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.props.mapTransactionResult(transaction_id, null);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({sendingMsg: false})
      })
  }

  chatFileDrop(files) {
    this.state.footerRef.docFileDrop(files);
  }

  onDocAttach(docs){
    this.state.footerRef.addCopyDocs(docs);
  }

  onThreadDocClick(doc){
    this.state.docsPanelRef.selectDocAndNavigate(doc);
  }

  selectDocAndNavigate(doc, thread){
    this.state.docsPanelRef.selectDocAndNavigate(doc, thread);
  }

  onPdfSubmit(res){
    this.props.onPdfSubmit(res);
  }

  onAttachDocToThread(res){
    this.props.onAttachDocToThread(res);
  }

  cancelSignatureRequestClick(msg, doc){
    let {
      t,
      dm
    } = this.props;
    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to cancel this signature request?"), (res) => {
      if(res){
        let sign_request_id = _.get(doc, 'sign_request_id');
        sapi.DM.deleteSignatureRequest(sign_request_id, dm.guest_uid)
          .then(() => {
            return Promise.all([
              this.props.updateAccountInfoForLimits(),
              this.refreshMessages()
            ])
          })
          .catch((err) => {
            log.error('error deleting signature request', err);
            this.props.showAlert(t("Unable to delete signature request"), getMessageForError(err, t));
          })
      }
    }, t('Yes'), t('No'))
  }

  fulfillDMSignatureRequest(mesg_id, doc_id, signatureRequest, sign_request_id){
    let {dm, accountInfoGuest, t} = this.props;
    Promise.all([
        sapi.Threads.docInfo(dm.forum_id, dm.host_uid, doc_id),
        sapi.Docs.mark(dm.forum_id, dm.host_uid, doc_id, false)
      ])
      .then((res) => {
        log.log('signature request load', res, signatureRequest, sign_request_id);

        let fakeDocStatus = {
          doc_id,
          sign_request_id,
          label : _.get(res[0], 'data.label'),
          signer_info : _.map(signatureRequest, (r) => {
            return ({
              sign_status : r.signed_date ? SignatureRequest.SIGN_STATUS.SIGNED : SignatureRequest.SIGN_STATUS.REQUESTED,
              signer_uid : r.signer_uid
            })
          })
        }
        let sigRequest = SignatureRequest.newDMSignatureRequest(
          dm.guest_uid,
          dm.forum_id,
          dm.host_uid,
          doc_id,
          mesg_id,
          fakeDocStatus
        )

        sigRequest.setSigners(fakeDocStatus.signer_info, [accountInfoGuest]);
        sigRequest.setRequestor(dm);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(signatureRequest);

        this.props.showFulfillSignatureRequest(null, dm, sigRequest, (res) => {
          log.log('pdf signature request window closed', res);

          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }

  fulfillSignatureRequest(msg, doc){
    let {dm, accountInfoGuest, t} = this.props;
    let sigRequest = SignatureRequest.newDMSignatureRequest(
      dm.guest_uid,
      dm.forum_id,
      dm.host_uid,
      doc.doc_id,
      msg.mesg_id,
      doc
    )

    Promise.all([
        sapi.Threads.docInfo(dm.forum_id, dm.host_uid, doc.doc_id),
        sapi.DM.getSignatureRequest(doc.sign_request_id, dm.guest_uid),
        sapi.Docs.mark(dm.forum_id, dm.host_uid, doc.doc_id, false)
      ])
      .then((res) => {
        log.log('signature request load', res);

        sigRequest.setSigners(doc.signer_info, [accountInfoGuest]);
        sigRequest.setRequestor(dm);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(msgHelper.formatSigningRequestForLegacyIfNeeded(res[1].data));

        this.props.showFulfillSignatureRequest(null, dm, sigRequest, (res) => {
          log.log('pdf signature request window closed', res);

          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }

  completeSignatureRequest(msg, doc){
    log.log('completeSignatureRequest', msg, doc)
    this.warnNonProUsersAboutSigning()
      .then((proceed) => {
        if(!proceed){
          return;
        }

        let { dm, t } = this.props;

        log.log('complete sig request', msg, doc, dm);
        let sigRequest = SignatureRequest.newDMSignatureRequest(
          dm.guest_uid,
          dm.forum_id,
          dm.host_uid,
          doc.doc_id,
          msg.mesg_id,
          doc
        )

        this.props.showDMRequestSignatureWindow(
          dm.guest_uid,
          msg.mesg_id,
          doc.doc_id,
          doc.label,
          [dm],
          null,
          null,
          true,
          (res) => {
            log.log('request signature form closed', res);
            if (res) {

              //No further data has been saved at this point, the signature request is still incomplete.
              Promise.all([
                  sapi.Threads.docInfo(dm.forum_id, dm.host_uid, sigRequest.doc_id),
                ])
                .then((loadRes) => {
                  sigRequest.setUseCancelLanguage(true);
                  sigRequest.setSigners(res, [dm]);
                  sigRequest.setDocInfo(loadRes[0].data);

                  this.props.completeSignatureRequest(null, dm, sigRequest, (res) => {
                    log.log('pdf signature request window closed', res);

                    this.refreshMessages();
                  })
                })
                .catch((err) => {
                  log.error('error loading signature request data', err);
                  this.props.showAlert(t('Error loading signature request data'), getMessageForError(err, t));
                })
            }
          }
        )
      })
  }

  warnNonProUsersAboutSigning(){
    return new Promise((resolve, reject) => {
      let {
        accountInfo,
        accountClassInfo,
        t
      } = this.props;

      log.log('warnNonProUsersAboutSigning', accountInfo, accountClassInfo);
      let sign_req_max = _.get(accountClassInfo, 'class_info.sign_req_max', null);

      if (_.isNumber(sign_req_max) && accountInfo.sign_req_count > 0) {
        //show a trial message
        this.props.showAlert(t("Trial Signing"),
          t("This signing request is on us! You have ") + accountInfo.sign_req_count + (accountInfo.sign_req_count === 1 ? t(" free signing request left, including this one.") : t(" free signing requests left, including this one.")),
          () => {
            resolve(true);
          });
        return;
      }

      // -if class/info:[user class_id] has non-null sign_req_max and user/info has
      // sign_req_count = 0:
      // the user has run out of free trial credits; show upgrade dialog
      if(_.isNumber(sign_req_max) && accountInfo.sign_req_count <= 0){
        // show upgrade dialog
        this.props.showUpgradeDialogOrError(UpgradeDialogNew.UPGRADE_TYPES.MAX_V2_SIGNING_LIMIT, null, this.props.t, (res) => {
          log.log('showUpgradeDialogOrError res', res);
        })
        resolve(false);
        return
      }

      resolve(true);
    })
  }

  doBeforePrint(){
    return this.state.chatMessagesRef.doPrintPreview()
  }

  onPrintError(err){
    log.error("error printing chat", err);
    this.state.chatMessagesRef.cleanupPrintPreview();
  }

  doAfterPrintPreview(){
    this.state.chatMessagesRef.cleanupPrintPreview();
  }

  getPrintPreviewContents(){
    return this.state.chatMessagesRef.printPreviewRef.printRef.current
  }

  onMesgHistoryClick(msg){
    let { dm } = this.props;

    let fetchMessageFn = (mesg_id) => {
      let { messageBlocks } = this.props;
      let found = null;
      _.each(messageBlocks, (block) => {
        found = _.find(block.blockList, (b) => b.mesg_id === mesg_id);
        if(found){
          return false;
        }
      })
      return found;
    }

    let refreshMsgFn = () => {
      return this.refreshMessages();
    }

    this.props.showDMMessageHistory(dm, msg.mesg_id, fetchMessageFn, refreshMsgFn);
  }

  onMesgEditClick(msg) {
    let { dm } = this.props;

    this.props.showEditDMMessage(dm, msg, (res) => {
      if(res){
        this.refreshMessages();
      }
    })
  }

  findDocInfo(doc_id){
    let { dmDocs, docs } = this.props;

    //This is ugly!  We only have props.docs if we're in a workspace.
    //this is not clear.
    //furthermore the datastructures are different.
    //eww.
    let found = null;
    if(docs) {
      _.each(docs, (chatBlock) => {
        _.each(chatBlock.docs, (doc) => {
          if (doc.doc_id === doc_id) {
            found = doc;
            return false;
          }
        })
        if (found) {
          return false;
        }
      })
    }

    if(!found){
      found = _.find(dmDocs, (d) => {
        return d.doc_id === doc_id;
      })
    }

    return found;
  }

  render() {
    let {dm, messageBlocks, dmDocs, isInTabView, workspace, messageBlocksId} = this.props;
    let { contactInfo} = this.state;

    let docNotifyCount = 0;
    _.each(dmDocs, (doc) => {
      if(doc.notify_flag) docNotifyCount++
    })
    return (
      <UploadHelper onDrop={this.chatFileDrop}
                    generateImagePreviews={true}
                    allowMultiple={true}
                    disableClick={true}>
        <div className={`center-col ${isInTabView ? 'tab-view' : ''} d-flex flex-column`}>

          <div>
            <DMPanelHeader dm={dm}
                           contactInfo={contactInfo}
                           sendInvoiceClick={() => this.props.onSendInvoiceClick(dm)}
                           onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                           onBeforePrintPreview={this.doBeforePrint.bind(this)}
                           onPrintPreviewError={this.onPrintError.bind(this)}
                           getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}/>
          </div>

          <div className="flex-grow-1" style={styles.messagePanelFlex}>
            <div className="row mx-0 height-100">
              <div className="col-7 height-100 pl-2 pr-0">
                <div className="d-flex flex-column" style={styles.messagePanelFlex}>
                  <div className="flex-grow-1" style={styles.messageContents}>
                    <ChatPanelMessages dm={dm}
                                       docClick={this.onThreadDocClick}
                                       messageBlocksId={messageBlocksId}
                                       messageBlocks={messageBlocks}
                                       mesg_edit_flag={true}
                                       refreshMessagesForPolling={this.doMessagePolling.bind(this)}
                                       onMesgEditClick={this.onMesgEditClick.bind(this)}
                                       onMesgHistoryClick={this.onMesgHistoryClick.bind(this)}
                                       findDocInfo={this.findDocInfo.bind(this)}
                                       onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                       onPdfSubmit={this.onPdfSubmit.bind(this)}
                                       onRef={this.onMsgPanelRef}
                                       onUserInteractionScroll={(evt) => this.props.onMessagePanelScroll(evt)}
                                       fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                       cancelSignatureRequestClick={this.cancelSignatureRequestClick.bind(this)}
                                       completeSignatureRequestClick={this.completeSignatureRequest.bind(this)}/>
                  </div>
                  <div>
                    <ChatPanelFooter dm={dm}
                                     onRef={this.onFooterPanelRef}
                                     sendMessage={this.sendMessage} />
                  </div>
                </div>
              </div>
              <div className="col-5 height-100 pl-2 pr-2 pb-2">
                <div id="attached-docs-col" style={styles.docsPanelContents}>
                  <AttachedDocsPanel docs={dmDocs}
                                     dm={dm}
                                     fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                     workspace={workspace}
                                     onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                     onPdfSubmit={this.onPdfSubmit.bind(this)}
                                     onRef={this.onDocPanelRef}
                                     docNotifyCount={docNotifyCount || 0}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UploadHelper>
    )
  }
}

const styles = {
  messagePanelFlex: {
    height: '100%',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: colors.DARK,
    borderBottomLeftRadius: '5px'
  },
  messageContents: {
    backgroundColor: colors.LIGHT,
    padding: '0px 5px',
    borderRadius: '5px',
    height: '0%', //I don't know why this works, but any other value screws things up in safari
  },
  docsPanelContents: {
    backgroundColor: colors.LIGHT,
    borderRadius: '5px',
    height: '100%',
    overflowY: 'auto'
  }
}

DMPanel.propTypes = {
  dm: PropTypes.object,
  messageBlocksId : PropTypes.string,
  messageBlocks : PropTypes.array,
  refreshMessages : PropTypes.func.isRequired,
  dmDocs : PropTypes.array,
  isInTabView : PropTypes.bool.isRequired,
  onRef : PropTypes.func.isRequired,
  onAttachDocToThread : PropTypes.func.isRequired,
  onSendInvoiceClick : PropTypes.func.isRequired,
  onPdfSubmit : PropTypes.func.isRequired,
  onMessagePanelScroll : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    docs : state.workspace.docs,
    accountInfoGuest : state.shared.accountInfoGuest,
    accountInfo : state.shared.accountInfo,
    accountClassInfo : state.shared.accountClassInfo,

    activeDMMessageBlocks : state.thread.activeDMMessageBlocks,
    messageBlocksId : state.thread.messageBlocksId,
    activeDMDataDate: state.thread.activeDMDataDate,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadActions : {
      queuePDFSubmitUpload: (transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, signature_requested, mesg, filename, pdfData, last_forum_id) => dispatch(uploadActions.queuePDFSubmitUpload(transaction_id, upload_id, forum_id, host_uid, guest_uid, chat_id, doc_id, sign_request_id, signature_requested, mesg, filename, pdfData, last_forum_id))
    },
    queueDMUpload: (transaction_id, upload_id, guest_uid, signature_requested, files, mesg, last_forum_id) => dispatch(uploadActions.queueDMUpload(transaction_id, upload_id, guest_uid, signature_requested, files, mesg, last_forum_id)),
    queueDMDocAttach:(transaction_id, upload_id, guest_uid, files, mesg) => dispatch(uploadActions.queueDMDocAttach(transaction_id, upload_id, guest_uid, files, mesg)),
    queueDMInvoice:(transaction_id, upload_id, guest_uid, files, mesg) => dispatch(uploadActions.queueDMInvoice(transaction_id, upload_id, guest_uid, files, mesg)),
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),

    threadAction : {...threadActions.mapToDispatch(dispatch)},
    refreshDocs : (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid))
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DMPanel));
