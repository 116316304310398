import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import log from "../../util/log";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import {getErrorMessage, getMessageForError, getMessageForVFOrTwilioError} from "../../util/errors";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import _ from 'lodash'
import he from "he";
import sentryHelper from "../../helpers/sentry-helper";
import {withVFTranslation} from "../../util/withVFTranslation";
import VFPopover from "../partials/components/VFPopover";
import c from "../../util/const";
import colors from "../../util/colors";
import {ArrowContainer} from "react-tiny-popover";
import popoverActions from "../../actions/popover-actions";
import Loading from "../partials/util/Loading";
import popupHelper from "../../helpers/popup-helper";

const SEND_CODE_TYPE = {
  SMS : 'sms',
  PHONE : 'phone'
}

export { SEND_CODE_TYPE };

class SMSVerificationDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      smsDisplay : '',
      code : '',
      validationErr : [],
      sendingVerificationCodeType : ''
    }
  }

  componentDidMount() {
    const {modalProps} = this.props;
    if(!modalProps.callback){
      throw Error('Showing modal without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {
      smsNumber,
      signatureRequest
    } = this.props;

    log.log('sms verification load', smsNumber, signatureRequest);

    let formattedNumber = formatPhoneNumberIntl(smsNumber);
    this.setState({
      smsDisplay : formattedNumber
    })

    let {
      verificationType
    } = modalProps;
    this.sendSMSVerificationCode(verificationType);
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  sendSMSVerificationCode(verificationType, showLoading){
    if(showLoading){
      this.setState({sendingVerificationCodeType:verificationType})
    }

    let { t } = this.props;
    this.setState({validationErr : []});
    let {
      smsNumber,
      signatureRequest
    } = this.props;

    let call_flag = verificationType === SEND_CODE_TYPE.PHONE;
    let sendCodePromise = null;
    if(signatureRequest.dm_guest_uid){
      sendCodePromise = sapi.DM.addSignCode(signatureRequest.status.sign_request_id, signatureRequest.dm_guest_uid, smsNumber, call_flag)
    }
    else{
      sendCodePromise = sapi.Workspace.addSignCode(signatureRequest.status.sign_request_id, signatureRequest.host_uid, smsNumber, call_flag)
    }

    sendCodePromise
      .then((res) => {
        log.log('sign code add res', res);
      })
      .catch((err) => {
        log.log('sign code add err', err);
        sentryHelper.captureMessage('add-sign-code-err', err);
        this.setState({validationErr : [getMessageForVFOrTwilioError(err, t)]});
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({sendingVerificationCodeType:''})
          this.props.popoverAction.hidePopover();
        }, 500)
      })
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  doValidation(){
    let err = [];
    let { t } = this.props;
    let {
      code
    } = this.state;

    if (!code || code.length === 0) {
      err.push(t("Please enter your Signature code"));
    }

    this.setState({validationErr : err});
    return err.length === 0;
  }

  validateCode(){
    this.setState({validationErr : []});
    if(!this.doValidation()){
      return;
    }

    let { code } = this.state;
    let { t } = this.props;
    let sigRequest = this.props.signatureRequest;
    let sendCodePromise = null;
    if(sigRequest.dm_guest_uid){
      sendCodePromise = sapi.DM.validateSignCode(sigRequest.status.sign_request_id, sigRequest.dm_guest_uid, code)
    }
    else{
      sendCodePromise = sapi.Workspace.validateSignCode(sigRequest.status.sign_request_id, sigRequest.host_uid, code)
    }

    sendCodePromise
      .then((res) => {
        log.log('validate sign code res', res);

        //then everything worked.  Let them in.
        this.closeModal(res);
      })
      .catch((err) => {
        log.log('validate sign code err', err);

        this.setState({
          validationErr : [ getMessageForError(err, t) ]
        })
      })

  }

  onEscapeKey(){
    this.closeModal(false);
  }

  renderPopoverContents({position, childRect, popoverRect}){
    let popupArgs = {
      position,
      childRect,
      popoverRect
    }
    let data = {
      sendingVerificationCodeType : this.state.sendingVerificationCodeType
    }
    let controls = {
      sendSMSVerificationCode : this.sendSMSVerificationCode.bind(this),
      t : this.props.t
    }
    return popupHelper.getResendCodePopoverContents(popupArgs, data, controls)
  }

  render() {
    let { t } = this.props;
    let {
      smsDisplay,
      code,
      validationErr
    } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Signature Code")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>{t("To proceed please enter the 7-digit code we sent to the following number:")}</p>
          <div className="m-3 text-center">
            <p className="font-italic secondary-text-color">{smsDisplay}</p>
          </div>
          <div className="m-auto" style={{width: '200px'}}>
            <div className="text-center">
              <input type="text"
                     inputMode="numeric"
                     pattern="[0-9]*"
                     autoComplete="one-time-code"
                     onChange={(evt) => this.setState({code : evt.target.value})}
                     value={code}
                     placeholder={t("Enter 7-digit code")}
                     className="form-control"
                     aria-label="" />
            </div>
            <div className="text-center">
              <VFPopover isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.RESEND_CODE_POPOVER)}
                         positions={['top', 'bottom']}
                         containerClassName="preview-container-cls"
                         onClickOutside={() => this.props.popoverAction.hidePopover()}
                         getMenuContent={this.renderPopoverContents.bind(this)}>
                <a className="btn btn-link px-0"
                   href="#"
                   onClick={() => this.props.popoverAction.showPopover(c.popovers.RESEND_CODE_POPOVER)}>
                  {t("Didn't get a code?")}
                </a>
              </VFPopover>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.validateCode.bind(this)}>{t("Submit")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showingPopoverKey : state.popover.showingPopoverKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

SMSVerificationDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SMSVerificationDialog));
