import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import log from "../../util/log";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import {getErrorMessage, getMessageForError} from "../../util/errors";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import _ from 'lodash'
import he from "he";
import sentryHelper from "../../helpers/sentry-helper";
import {withVFTranslation} from "../../util/withVFTranslation";
import {SEND_CODE_TYPE} from "./SMSVerificationDialog";

class RequestSignatureCodePhoneCallDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      smsDisplay : '',
      code : '',
      validationErr : []
    }
  }

  componentDidMount() {
    const {modalProps} = this.props;
    if(!modalProps.callback){
      throw Error('Showing modal without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {
      smsNumber,
      signatureRequest
    } = this.props;

    log.log('RequestSignatureCodePhoneCallDialog load', smsNumber, signatureRequest);

    let formattedNumber = formatPhoneNumberIntl(smsNumber);
    this.setState({
      smsDisplay : formattedNumber
    })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  closeModal(result) {
    let {close} = this.props;
    close(result);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  onGetByPhoneCallClick(){
    this.closeModal(SEND_CODE_TYPE.PHONE);
  }

  render() {
    let { t } = this.props;
    let {
      smsDisplay,
      code,
      validationErr
    } = this.state;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Verification Phone Number Issues")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">

          <div className="text-left px-2 mb-2" style={{marginLeft: '20px'}}>
            <ul className="list-group list-group-flush black-color my-1">
              <li style={{listStyle:'disc'}} className="py-2">
                {t("If you can't receive our text messages, you can receive the code by phone call by clicking below:")}
              </li>
            </ul>
          </div>
          <div className="mx-auto mb-4" style={{maxWidth: '250px'}}>
            <Button className="btn btn-primary btn-block" onClick={() => this.onGetByPhoneCallClick()}>
              {t("Get Code by Phone Call")}
            </Button>
          </div>
          <div className="text-left px-2" style={{marginLeft: '20px'}}>
            <ul className="list-group list-group-flush black-color my-1">
              <li style={{listStyle:'disc'}} className="py-2">
                {t("If the phone number is incorrect, the person requesting your signature can update or resend the request with a new phone number. Please contact your requestor directly.")}
              </li>
              <li style={{listStyle:'disc'}} className="py-2">
                {t("If you cannot or do not want to participate in this verification process, you will have to contact the requestor directly to make alternative arrangements. ")}
              </li>
            </ul>
          </div>

        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

RequestSignatureCodePhoneCallDialog.propTypes = {
  close : PropTypes.func.isRequired,
  onRef : PropTypes.func,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequestSignatureCodePhoneCallDialog));
