import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import accountActions from "../../../../actions/account-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import UploadHelper from "../../components/UploadHelper";
import Image from "../../elements/Image";
import {getMessageForError} from "../../../../util/errors";
import colors from "../../../../util/colors";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class EditLogoCtrl extends Component {

  constructor(props) {
    super(props);

    this.onMouseEnterLogo = this.onMouseEnterLogo.bind(this);
    this.onMouseLeaveLogo = this.onMouseLeaveLogo.bind(this);

    this.state = {
      newLogo: null,
      newLogoFile: null,
      validation_errors: [],
      logoChanged: false,
      hoveringLogo: false,
      isSaving: false,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  onMouseEnterLogo() {
    if (this.state.hoveringLogo) {
      return;
    }

    this.setState({hoveringLogo: true});
  }

  onMouseLeaveLogo() {
    this.setState({hoveringLogo: false});
  }

  init() {
    let {accountInfo, logo} = this.props;

    this.setState({
      newLogo: logo,
      validation_errors: [],
      logoChanged: false,
      isSaving: false,
    })
  }

  save() {
    this.setState({isSaving: true});

    let {newLogoFile} = this.state;
    let {updateAccountInfo, updateLogo, doClose, accountInfo, t} = this.props;

    let promise = null;
    if (newLogoFile) {
      promise = sapi.Workspace.uploadLogo(newLogoFile.name, newLogoFile)
    }
    else {
      promise = sapi.Workspace.deleteLogo()
    }

    promise
      .then((res) => {
        log.log('update logo res', res);

        return Promise.all([
          updateAccountInfo(),
          updateLogo()
        ]);
      })
      .then((res) => {
        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error updating logo', err);
        if(!newLogoFile){
          //then you tried to delete the logo and it failed.
          if(!accountInfo.logo_flag){
            //then a logo was present and you tried to delete it, however you had never configured one for your account
            //which means you must have tried to delete and inst logo.
            this.setState({
              validation_errors: [t("This logo comes from your institution.  You can add a new one, but you cannot delete this one.")]
            })
            return;
          }
        }

        this.setState({
          validation_errors: [getMessageForError(err, t)]
        })
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  clearFile() {
    this.setState({
      newLogo: null,
      newLogoFile: null,
      logoChanged: true
    })
  }

  onImgDrop(files, rejectFiles) {
    log.log('onImgDrop', files, rejectFiles)
    if (files.length > 0) {
      let imgFile = files[0];
      let preview = URL.createObjectURL(imgFile);

      this.setState({
        newLogo: preview,
        newLogoFile: imgFile,
        logoChanged: true
      })
    }
  }

  cancel() {
    let {doClose} = this.props;

    doClose();
  }

  render() {
    let {newLogo, isSaving, validation_errors, logoChanged, hoveringLogo} = this.state;
    let { t } = this.props;

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              {t("Your logo file may be a jpeg, jpg, png, or svg and up to 1MB in size.")}{" "}
              {t("It will appear at the top of the screen in all of your Workspaces for all of your Guests")}
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              {!newLogo &&
                <UploadHelper allowMultiple={false}
                              fileTypes={{
                                "image/*": [".png", ".jpeg", ".jpg", ".svg"]
                              }}
                              preventDragOverlay={true}
                              onDrop={this.onImgDrop.bind(this)}>
                {({isDragActive}) => {
                  let panelColorStyle = null;
                  if(isDragActive){
                    panelColorStyle = {color : colors.PRIVATE_GREEN};
                  }
                  else if(hoveringLogo){
                    panelColorStyle = {color : colors.PRIMARY};
                  }
                  else{
                    panelColorStyle = {color : colors.DARK};
                  }

                  return (
                    <div className={`drag-drop-panel`}
                         onMouseEnter={this.onMouseEnterLogo}
                         onMouseLeave={this.onMouseLeaveLogo}
                         style={{...EditLogoCtrl.styles.dragDropEmpty, ...panelColorStyle, ...(hoveringLogo ? EditLogoCtrl.styles.dragDropEmptyHover : null)}}>
                      <div className="text-center">
                        <i style={EditLogoCtrl.styles.dragDropIcon} className={`icon ion-upload`}/>
                      </div>
                      {isDragActive ? t('Ready!') : t('Drag and Drop or Click to Browse')}
                    </div>
                  )
                }}
              </UploadHelper>
              }
              {newLogo &&
              <div style={EditLogoCtrl.styles.dragDrop}>
                <Button className={'btn btn-dark no-focus'} style={EditLogoCtrl.styles.clearButton}
                        onClick={this.clearFile.bind(this)}>
                  <i className="icon ion-close"/>
                </Button>
                <Image imgHeight={145} src={newLogo} alt={t('Your logo')}/>
              </div>
              }
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving}
                      className={'btn btn-secondary mr-2'}
                      onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isSaving || !logoChanged}
                      className={'btn btn-primary'}
                      onClick={this.save.bind(this)}>{t("Save")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditLogoCtrl.styles = {
  dragDrop: {
    maxHeight: '160px',
    padding: '5px',
    border: 'dashed 3px black',
    borderColor: colors.DARK,
    borderRadius: '12px',
    textAlign: 'center',
  },
  dragDropEmpty: {
    height: '160px',
    padding: '5px',
    border: 'dashed 3px black',
    borderColor: colors.DARK,
    borderRadius: '12px',
    textAlign: 'center'
  },
  dragDropEmptyHover: {
    cursor: 'pointer'
  },
  dragDropIcon: {
    fontSize: '100px',
    display: 'inline-flex',
  },
  clearButton: {
    position: 'absolute',
    top: '-15px',
    right: '0px',
    border: '10px solid',
    zIndex: '100',
    borderRadius: '50%'
  }
}

EditLogoCtrl.propTypes = {
  doClose: PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    logo: state.shared.logo,
    accountInfo: state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo: () => dispatch(sharedActions.updateAccountInfo()),
    updateLogo: () => dispatch(sharedActions.updateLogo())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLogoCtrl)));
