import React, {Component} from 'react';
import {connect} from "react-redux";
import Promise from 'bluebird';
import PropTypes from 'prop-types';
import Button from "../partials/elements/Button";
import colors from "../../util/colors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import sharedActions from "../../actions/shared-actions";
import AccountSignaturesPanel from "../partials/account/general/AccountSignaturesPanel";
import Loading from "../partials/util/Loading";
import {getMessageForError} from "../../util/errors";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class AddAccountSignaturesDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editSignatureRef : null,
      isSaving : false,
      isLoading : false,

      isEditingSignatures : false
    }
  }

  componentDidMount() {
    const {modalProps, signatures} = this.props;

    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }

    if(this.props.onRef){
      this.props.onRef(this)
    }

    //not having signatures is a supported state
    //We just need to try and refresh if we don't have something here already.
    if(!signatures) {
      this.setState({isLoading: true})
      this.props.updateSignatures()
        .finally(() => {
          this.setState({isLoading: false})

          //If no signatures present, walk them right into edit mode.
          let { signatures } = this.props;
          if(!signatures){
            this.setState({
              isEditingSignatures : true
            })
          }
        })
    }
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined)
    }
  }

  editSignaturesClick(){
    this.setState({
      isEditingSignatures : true
    })
  }

  confirmSignatures(){
    //close and finish
    this.closeModal(true);
  }

  closeModal(res) {
    let {close} = this.props;
    close(res);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  saveSignatures(){
    let { editSignatureRef } = this.state;
    let { t } = this.props;

    if(!editSignatureRef.doValidation()){
      return;
    }

    if(!editSignatureRef.hasChanges()){
      //Nothing to do, just close out.
      this.closeModal(true);
      return;
    }

    this.setState({isSaving: true});
    let signatures = editSignatureRef.generateImages();
    return sapi.AccountInfo.addSignatures(signatures.sign_image, signatures.init_image)
      .then((res) => {
        log.log('finished adding signatures', res);
        return Promise.all([
          this.props.updateSignatures(),
          this.props.updateAccountInfo()
        ])
      })
      .then(() => {
        this.setState({isSaving: false});
        this.closeModal(true);
      })
      .catch((err) => {
        log.error('error generating images', err);
        this.props.showAlert(t("Error saving signatures"), getMessageForError(err, t));
        this.setState({isSaving: false});
      })
  }

  render() {
    let { isSaving, isLoading, isEditingSignatures } = this.state;
    let { t } = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Confirm Signature")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          {isLoading &&
          <Loading inline={true}
                   className={'m-2'}
                   size={'sm'}/>
          }
          {!isLoading &&
          <AccountSignaturesPanel preventEdit={!isEditingSignatures}
                                  onRef={(ref) => this.setState({editSignatureRef: ref})}/>
          }
        </div>
        <div className="modal-footer">
          {isSaving &&
          <Loading inline={true}
                   className={'mr-2'}
                   size={'sm'}/>
          }
          <Button className={'btn btn-secondary'}
                  disabled={isSaving}
                  onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          {!isEditingSignatures &&
          <Button className={'btn btn-primary'}
                  disabled={isSaving}
                  onClick={this.editSignaturesClick.bind(this)}>{t("Edit Signature")}</Button>
          }
          {!isEditingSignatures &&
          <Button className={'btn btn-primary'}
                  disabled={isSaving}
                  onClick={this.confirmSignatures.bind(this)}>{t("Confirm Signature")}</Button>
          }
          {isEditingSignatures &&
          <Button className={'btn btn-primary'}
                  disabled={isSaving}
                  onClick={this.saveSignatures.bind(this)}>{t("Save")}</Button>
          }
        </div>
      </div>
    )
  }
}

AddAccountSignaturesDialog.MODAL_LARGE = true;

const mapStateToProps = (state) => {
  return {
    accountInfo : state.shared.accountInfo,
    signatures : state.shared.signatures
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch)
  };
};

AddAccountSignaturesDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddAccountSignaturesDialog));
