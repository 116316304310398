import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import FancyInput from '../elements/FancyInput';

import { validateEmail } from '../../../util/validation';

import authActions from "../../../actions/auth-actions";
import modalActions from '../../../actions/modal-actions';
import PulseButton from "../elements/PulseButton";
import _ from "lodash";
import utils from "../../../util/util";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import VFPopover from "../components/VFPopover";
import popupHelper from "../../../helpers/popup-helper";
import popoverActions from "../../../actions/popover-actions";

class EmailCard extends Component {

  ignoreEmailFocus = false;

  constructor(props){
    super(props);

    this.state = {
      email: props.email || '',
      memberNumber: props.memberNumber || '',
      lookupResultDestination : null,
      doValidationErr: null,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }

    //If member number is
    let defaultClassId = _.get(this.props.institution, 'info_json.default_class_id');
    if(defaultClassId){
      utils.waitForCondition(() => {
        return !!this.memberNumberField;
      })
        .then(() => {
          this.memberNumberField.focus();
        })
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  triggerValidationErr() {
    if(this.emailField){
      this.emailField.triggerValidationErr();
    }
  }

  focusEmail(){
    this.emailField.focus();
  }

  getValidatedEmailValue(){
    let { email } = this.state;

    if(validateEmail(email)){
      return email;
    }
    else{
      return '';
    }
  }

  validateForm() {
    let { email } = this.state;

    this.props.setValidationErrors([]);
    this.setState({
      doValidationErr: false,
    });

    if(!validateEmail(email)){
      this.setState({
        doValidationErr: true,
      });
      this.emailField.triggerValidationErr();
      return false;
    }
    if(!this.validateMemberNumberRegexIfNeeded()){
      this.setState({
        doValidationErr: true,
      });
      this.memberNumberField.triggerValidationErr();
      return false;
    }
    else{
      return true;
    }
  }

  validateMemberNumberRegexIfNeeded(){

    //bug 2386 - we want to allow for a custom regex for a member number.
    //just load

    let defaultClassId = _.get(this.props.institution, 'info_json.default_class_id');
    let memberNumberFormat = _.get(this.props.institution, 'info_json.member_number_format');
    if(defaultClassId && memberNumberFormat){
      let result = null;
      try{
        result = this.state.memberNumber.match(memberNumberFormat);
      }
      catch(err){
        console.error('invalid regex', memberNumberFormat, err);
      }
      return result && result.length === 1;
    }
    return true;
  }

  updateEmailValidationResults(email){
    return new Promise((resolve, reject) => {
      this.props.setEmailValidationPerformed(true);
      api.AccountEmail.validateEmail(email)
        .then((res) => {
          log.log('validate email res', res);
          this.props.setEmailValidationResults(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          log.log('error during email validation', err);
          resolve(null);
        })
    })
  }

  updateEmailLookupResults(){
    return new Promise((resolve, reject) => {
      let { email, memberNumber } = this.state;
      let { setMemberNumber, institution, t } = this.props;
      let domainFlag = _.get(institution, 'info_json.domain_flag', null);

      let lookup = null;
      if(domainFlag){
        //domain flag means call lookup with inst_id, and null member number.  bug 2966
        lookup = api.Lookup.post(email, institution.inst_id, null);
      }
      else if(memberNumber){
        setMemberNumber(memberNumber);
        lookup = api.Lookup.post(email, institution.inst_id, memberNumber);
      }
      else{
        lookup = api.Lookup.post(email);
      }

      lookup
        .then((lookupRes) => {
          if(lookupRes && lookupRes.data && lookupRes.data.verified_flag){
            this.setState({lookupResultDestination : c.authSteps.password}, () => resolve(true));
          }
          else{
            this.setState({lookupResultDestination : c.authSteps.signup}, () => resolve(true));
          }
        })
        .catch((err) => {
          log.log('error on lookup', err);

          if(err.name === 'APP_NOT_FOUND') {
            this.setState({lookupResultDestination: c.authSteps.signup}, () => resolve(true));
          }
          else{
            this.props.setValidationErrors([getMessageForError(err, t)]);
            this.setState({lookupResultDestination : null}, () => resolve(true));
          }
        })
    })
  }

  doWeNeedInvalidEmailPrompt(){
    let {
      emailValidationPerformed,
      emailValidationResults
    } = this.props;

    //If next button gets clicked when you already have email validation results, it means that we've done this already and
    //the user is at the warning.  Just go to the place that the lookup was pointing.
    return !!(emailValidationPerformed && emailValidationResults && utils.hasEmailValidationInvalidFlag(emailValidationResults));
  }

  doNextButtonClicked(){
    if(this.doWeNeedInvalidEmailPrompt()){
      this.props.setStep(this.state.lookupResultDestination);
      this.props.setShowingInvalidEmailWarning(false);
      this.props.setLoading(false);
      this.ignoreEmailFocus = false;
      return;
    }

    //Prevents a problem, where if you hit enter and immediately start typing a password
    //Sometimes a couple password characters would end up in the email field.  bug 2588
    if(this.emailField){
      this.emailField.blur();
    }

    let { email, memberNumber } = this.state;
    let { setEmail, setMemberNumber, setStep, institution, t } = this.props;

    if(!this.validateForm()){
      return;
    }

    //Worth noting that we disable email focus here, but do NOT re-enable it.
    //This happens in PasswordCard/BoardingCard
    //This is to guard against focus events coming during navigation that could
    //trigger us to navigate back to the email phase.
    this.props.setPreventEmailDisabled(true);
    this.ignoreEmailFocus = true;
    this.props.setLoading(true);
    setEmail(email);

    this.updateEmailLookupResults()
      .then(() => {
        let { lookupResultDestination } = this.state;
        if(lookupResultDestination === c.authSteps.signup){
          this.updateEmailValidationResults(email)
            .then((validateEmailRes) => {
              let isValidResult = !utils.hasEmailValidationInvalidFlag(validateEmailRes);
              if(isValidResult){
                this.props.setStep(this.state.lookupResultDestination);
              }
              else{
                //re-enable email focus behavior if we show the warning.
                this.props.setPreventEmailDisabled(false);
                this.props.setShowingInvalidEmailWarning(true);

                let didYouMeanSuggestion = _.get(validateEmailRes, 'did_you_mean');
                if(didYouMeanSuggestion){
                  this.props.popoverAction.showPopover(c.popovers.DID_YOU_MEAN_MENU)
                }

              }
              this.props.setLoading(false);
              this.ignoreEmailFocus = false;
            })
        }
        else{
          this.props.setStep(lookupResultDestination);
          this.props.setLoading(false);
          this.ignoreEmailFocus = false;
        }
      })
  }

  onEmailChange(val){
    this.setState ({
      email: val
    }, () => this.props.setEmail(this.state.email))
  }

  onMemberNumberFocus(evt){
    let {
      step,
      setStep,
      setEmailFieldVisible,
      setPreventEmailDisabled,
      preventNavOnEmailFocus
    } = this.props;

    //On safari, when you click on the saved password dialog for some reason it fires a focus event on the email input
    //I'm not sure why this is, but it comes through as not trusted, and user input focus events come through as trusted,
    //Which is what we want.  Still weird.  Bug 1829
    if(evt && evt.nativeEvent && !evt.nativeEvent.isTrusted){
      log.log('received programatic focus on member card.  ignoring');
      return;
    }

    if(step !== c.authSteps.email && !preventNavOnEmailFocus){
      setStep(c.authSteps.email);
      setEmailFieldVisible(true);
      setPreventEmailDisabled(false);
    }
  }

  onEmailFocus(evt){

    //We used to "Start Over" if you focused the email field, but this was hard to maintain
    //due to the behavior of various autofill mechanisms.  Now we just disable the input.
    //I've left this handler here just for documentation, it's not used at this time.
    return;

    //This is all old handling from when focusing the email field would reset authentication.
    //Just leaving this for a bit until we're completely positive we don't need it anymore.
    // let {
    //   step,
    //   preventNavOnEmailFocus,
    //   showEmailValidationWarning
    // } = this.props;
    //
    // //On safari, when you click on the saved password dialog for some reason it fires a focus event on the email input
    // //I'm not sure why this is, but it comes through as not trusted, and user input focus events come through as trusted,
    // //Which is what we want.  Still weird.  Bug 1829
    // if(evt && evt.nativeEvent && !evt.nativeEvent.isTrusted){
    //   log.log('received programatic focus on email card.  ignoring');
    //   evt.preventDefault();
    //   return false;
    // }
    //
    // if(this.ignoreEmailFocus){
    //   log.log('received focus on email card while ignoring');
    //   evt.preventDefault();
    //   return false;
    // }

    // log.log('onEmailFocus', step, showEmailValidationWarning, preventNavOnEmailFocus);
    // if(!preventNavOnEmailFocus && (showEmailValidationWarning || step !== c.authSteps.email && step !== c.authSteps.forgotPassword)){
    //   this.props.setShowingInvalidEmailWarning(false);
    //   this.props.setEmailValidationPerformed(false);
    //   this.props.setEmailValidationResults(null);
    //   this.props.setStep(c.authSteps.email);
    //   this.props.setEmailFieldVisible(true);
    //   this.props.setPreventEmailDisabled(false);
    // }
    // else{
    //   log.log('ignore email focus');
    //   evt.preventDefault();
    //   return false;
    // }
  }

  onMemberNumberChange(val){
    this.setState ({
      memberNumber: val
    })
  }

  showMemberNumberInfo(text){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Member Number'),
      <div>
        <div>
          <p>
            {text}
          </p>
        </div>
      </div>
    )
  }

  showInfo(){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Why do we need an email address?'),
      <div>
        <div>
          <p>
            {t("Verifyle is a system for secure communication. We need to verify an email address for you so the people you share with can know it’s really you. We also use your email address to let you know when others in Verifyle have shared a message or file with you.")}
          </p>
          <p>
            {t("Verifyle doesn’t show ads and it doesn’t sell user information — not anonymously, not in aggregate, not ever. You can get more details in our")} <a target={'_blank'} href={c.links.privacy}>{t("Privacy Policy")}</a>.
          </p>
          <p className="pt-3">
            {t("Contact us at")}
            <br/>
            <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          </p>
        </div>
      </div>
    )
  }

  renderDidYouMeanContents(popupArgs) {
    let {emailValidationResults, t} = this.props;
    let didYouMeanSuggestion = _.get(emailValidationResults, 'did_you_mean');
    if (didYouMeanSuggestion) {
      let useItClick = () => {
        this.focusEmail();
        setTimeout(() => {
          this.onEmailChange(didYouMeanSuggestion);
          this.props.popoverAction.hidePopover();
        })
      }
      let loseItClick = () => {
        this.props.popoverAction.hidePopover()
      }

      return popupHelper.getDidYouMeanContents(
        t,
        didYouMeanSuggestion,
        popupArgs,
        () => useItClick(),
        () => loseItClick()
      )
    }
  }

  render() {
    let {
      step,
      institution,
      allowEmailEditFromBoarding,
      t
    } = this.props;
    let {
      doValidationErr,
    } = this.state;

    let memberNumberInfo = _.get(institution, 'info_json.member_number_info');
    let memberNumberAttrs = {};
    if(memberNumberInfo){
      memberNumberAttrs.infoBtnCls = 'ion-ios-information-outline auth-info-icon has-pointer';
      memberNumberAttrs.onInfoClick = this.showMemberNumberInfo.bind(this, memberNumberInfo);
    }

    let defaultClassId = _.get(institution, 'info_json.default_class_id', null);
    let domainFlag = _.get(institution, 'info_json.domain_flag', null);
    let showMemberNumber = !!(defaultClassId && !domainFlag);

    let allowEmailEdit = step === c.authSteps.email || (step === c.authSteps.signup && allowEmailEditFromBoarding);

    return (
      <div>
        {showMemberNumber &&
        <div className={'row'}>
          <div className={'col'}>
            <div className={'form-group'}>
              <FancyInput onRef={ref => (this.memberNumberField = ref)}
                          placeholder={t('Member Number')}
                          inputDisabled={false}
                          fieldType={'text'}
                          onFocus={this.onMemberNumberFocus.bind(this)}
                          inputValue={this.state.memberNumber}
                          onEnter={() => this.props.doNextButtonClick()}
                          onChange={this.onMemberNumberChange.bind(this)}
                          {...memberNumberAttrs}/>
            </div>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <VFPopover
              isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.DID_YOU_MEAN_MENU)}
              positions={['right', 'bottom']}
              reposition={false}
              containerClassName="preview-container-cls copied-popover-container"
              onClickOutside={() => this.props.popoverAction.hidePopover()}
              getMenuContent={this.renderDidYouMeanContents.bind(this)}>
              <div className={'form-group'}>
                <FancyInput onRef={ref => (this.emailField = ref)}
                            placeholder={t('Email')}
                            useDisabledStyles={true}
                            inputDisabled={!allowEmailEdit}
                            autoComplete={'username'}
                            fieldType={'email'}
                            isValid={!doValidationErr}
                            hideInfoBtn={step !== c.authSteps.email}
                            inputValue={this.state.email}
                            infoBtnCls={'ion-ios-information-outline auth-info-icon has-pointer'}
                            onFocus={this.onEmailFocus.bind(this)}
                            onInfoClick={this.showInfo.bind(this)}
                            onEnter={() => this.props.doNextButtonClick()}
                            onChange={this.onEmailChange.bind(this)}/>
              </div>
            </VFPopover>
          </div>
        </div>
      </div>
    );
  }
}

//There's some complicated interactions here around navigating directly
//to the boarding card, in which case email hasn't been initialized and we want
//the user to fill it in at that time.  This is the only case this is necessary and
//needs some extra controls around it.
EmailCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  doNextButtonClick : PropTypes.func.isRequired,
  preventNavOnEmailFocus: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    email : state.auth.email,
    step : state.auth.step,
    allowEmailEditFromBoarding : state.auth.allowEmailEditFromBoarding,
    institution : state.auth.institution,
    emailValidationResults : state.auth.emailValidationResults,
    emailValidationPerformed : state.auth.emailValidationPerformed,
    showEmailValidationWarning : state.auth.showEmailValidationWarning,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setMemberNumber: number => dispatch(authActions.setMemberNumber(number)),
    showAuthInfo: (title, contents, cb) => dispatch(modalActions.showAuthInfo(title, contents, cb)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled)),
    setEmailValidationResults : (res) => dispatch(authActions.setEmailValidationResults(res)),
    setEmailValidationPerformed : (res) => dispatch(authActions.setEmailValidationPerformed(res)),
    setShowingInvalidEmailWarning : (isShowing) => dispatch(authActions.setShowingInvalidEmailWarning(isShowing)),
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailCard));
