import request from 'superagent';
import Promise from 'bluebird';
import config from './config';
import c from './const';
import log from "./log";

import _ from 'lodash';

import vfLocalStorage from './local-storage';
import utils from "./util";
import api from "./api";
import heic2any from "heic2any";

const sapi = {};

sapi.endpoint = config.prodEndpoint;
sapi.sessionInvalidCB = null;
sapi.connectionErrorCB = null;

sapi.setEndpoint = (endpoint) => {
  sapi.endpoint = endpoint;
}

sapi.apiRoot = (uri) => {
  let root = config.debug ? sapi.endpoint : (window.location.protocol + '//' + window.location.host);
  return root + '/app' + uri;
}

sapi.globalHeaders = (manualToken = null) => {
  let headers = {};

  if(manualToken){
    if(manualToken && manualToken.length > 0){
      headers[c.api.X_TOKEN] = manualToken;
    }
  }
  else{
    let token = vfLocalStorage.get(c.localstorage.token);
    if(token && token.length > 0){
      headers[c.api.X_TOKEN] = token;
    }
  }

  let dbHeader = vfLocalStorage.get(c.localstorage.api_db_header);
  if(dbHeader && dbHeader.length > 0){
    headers[c.api.X_VF_DB] = dbHeader;
  }

  return headers;
}

sapi.detectDBBlockUpdate = (data) => {
  let dbBlock = _.get(data, 'db');
  if(dbBlock){
    let udb = _.get(dbBlock, 'udb');
    let oid = _.get(dbBlock, 'oid');
    let key = _.get(dbBlock, 'key');

    let headerString = '';
    if(udb && udb.length > 0){
      headerString += 'udb=' + udb + '&';
    }
    if(oid && oid.length > 0){
      headerString += 'oid=' + oid + '&';
    }
    if(key && key.length > 0){
      headerString += 'key=' + key + '&';
    }
    headerString = _.trim(headerString, ' &');

    //we could detect a change here if we needed to?
    //At this time, APP_SESS_INVALID happens when this changes, which clears this, so this is enough.
    vfLocalStorage.set(c.localstorage.api_db_header, headerString);
  }
}

sapi.getToken = () => {
  return vfLocalStorage.get(c.localstorage.token);
}

sapi.textResponseHdl = (res, err) => {
  if(err){
    return Promise.reject(err);
  }

  if(res){
    if(res.text){

      return Promise.resolve(res.text);

    }
  }

  return Promise.reject(new Error('Improper response body'));
}

sapi.rawResponseHdl = (res, err) => {
  if(err){
    return Promise.reject(err);
  }

  if(res){
    if(res.body){

      return Promise.resolve(res.body);

    }
  }

  return Promise.reject(new Error('Improper response body'));
}

sapi.dataUriResponseHdl = (res, err) => {
  if(err){
    return Promise.reject(err);
  }

  if(res){
    if(res.body){
      let b64 = utils.arrayBufferToBase64(res.body);
      return Promise.resolve(`data:${res.type};base64,${b64}`);
    }
  }

  return Promise.reject(new Error('Improper response body'));
}

sapi.dataUriHeicResponseHdl = (res, err) => {
  return new Promise((resolve, reject) => {
    if(err){
      return reject(err);
    }

    if(res){
      if(res.body){

        //according to docs, this must be image/jpeg in order for the quality flag to work.
        let imgType = 'image/jpeg';
        let blob = new Blob([res.body]);
        heic2any({
          blob,
          toType: imgType,
          quality: c.preview.heicImgGenQuality
        })
          .then((rBlob) => {
            return rBlob.arrayBuffer();
          })
          .then((buf) => {
            let b64 = utils.arrayBufferToBase64(buf);
            resolve(`data:${imgType};base64,${b64}`);
          })
          .catch((err) => {
            log.log('error while using heic2any', err);
            reject(err);
          })
        return;
      }
    }

    return reject(new Error('Improper response body'));
  })
}

sapi.htmlDecodeResponse = (data) => {
  if (Array.isArray(data)) {
    for (var i = 0; i < data.length; i++) {
      utils.htmlDecodeObj(data[i]);
    }
  }
  else {
    utils.htmlDecodeObj(data);
  }
}

sapi.responseHdl = (res, err, preventSessionErrors) => {
  // log.log('api response', res.body || null, err);

  if (err) {
    return Promise.reject(err);
  }

  if (res) {
    if(res.body){
      sapi.detectDBBlockUpdate(res.body);
      if(res.body.error){
        let errName = _.get(res, 'body.error.name');
        let errObj = _.get(res, 'body.error');
        if(!preventSessionErrors && errName && (errName === 'APP_SESS_INVALID' || errName === 'APP_ACCT_UNAVAIL')){
          sapi.onInvalidSessionCaught(errObj);
        }

        throw {
          name: (res.body && res.body.error && res.body.error.name) || null,
          code: (res.body && res.body.error && res.body.error.code) || null,
          error: (res.body && res.body.error) || null,
          body: res.body
        };
      }
      else{
        sapi.htmlDecodeResponse(res.body);
        return Promise.resolve(res.body);
      }
    }
    else if(res.text){
      //If it's a text response, then it's probably html that needs to be
      //parsed and decoded.

      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(res.text, 'text/html');
      if(!htmlDoc){
        return Promise.reject(new Error('expected html in response'));
      }
      let tags = htmlDoc.getElementsByTagName("pre")
      if(!tags || tags.length === 0){
        //FYI, this text is referenced in sapi.isConnectionError().  This should probably be shared better.
        //This essentially occurs when we take the servers down for maintenance,
        //So we want to treat this as if it's a connection error.
        return Promise.reject(new Error('no pre tags present in response'));
      }
      let preTag = tags[0];
      if(!preTag){
        return Promise.reject(new Error('unable to find pre tag in response'));
      }
      let preTagContents = preTag.innerHTML;

      let responseObj = null;
      try{
        responseObj = JSON.parse(preTagContents);
      }
      catch(err){
        //then it's not json.  Not worth handling this error.
      }

      if(!responseObj){
        responseObj = JSON.parse(utils.b64DecodeUnicode(preTagContents));
      }
      if(responseObj.error){
        if(!preventSessionErrors && (responseObj.error.name === 'APP_SESS_INVALID' || responseObj.error.name === 'APP_ACCT_UNAVAIL')){
          sapi.onInvalidSessionCaught(responseObj.error);
        }

        throw {
          name: (responseObj.error.name) || null,
          code: (responseObj.error.code) || null,
          error: (responseObj.error) || null,
          body: responseObj
        };
      }
      else{
        sapi.htmlDecodeResponse(responseObj);
        return Promise.resolve(responseObj);
      }
    }
    else{
      log.error('no body in request');
      return Promise.reject(new Error('no body in request'));
    }
  }

  log.error('no error, and no data!');
  return Promise.reject(new Error('no error or body in request'));
}

sapi.isConnectionError = (err) => {

  let hasMethod = err.hasOwnProperty('method');
  let hasUrl = err.hasOwnProperty('url');
  let errToString = '' + err;
  if(err && err.code === "ABORTED"){
    return true;
  }
  else if(hasMethod && hasUrl){
    if(err.code && err.name){
      //Then this is a vf error
      return false;
    }
    return true;
  }
  else if(err && err.status === 503){
    return true;
  }
  else if(errToString.indexOf('no pre tags present in response') >= 0){
    return true;
  }
  else{
    return false;
  }
}

sapi.registerApiHandlers = (invalidSessionCb, connectionErrorCb) => {
  sapi.sessionInvalidCB = invalidSessionCb;
  sapi.connectionErrorCB = connectionErrorCb;
}

sapi.onInvalidSessionCaught = (err) => {
  if(sapi.sessionInvalidCB){
    sapi.sessionInvalidCB(err);
  }
}

sapi.onConnectionErrorCaught = (err) => {
  if(sapi.connectionErrorCB){
    sapi.connectionErrorCB(err);
  }
}

sapi.fileUploadErrorHandlers = (err) => {
  return new Promise((resolve, reject) => {
    if(sapi.isConnectionError(err)){

      //Special connection handling for file uploads.
      //There are a couple cases here where I can't really tell what's going on.
      //mostly in native cases.  In particular, I can't tell between a broken link to a file that you've selected,
      //and a network problem.
      //Is this a problem with the superagent library?  It might be, but I'm not ready to bite off a library change at the moment.

      //Example of a broken file upload (this is not a mistake):
      // globalErrorHandler Error: Request has been terminated
      // Possible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.
      //   at Request.push../node_modules/superagent/lib/client.js.Request.crossDomainError (client.js:621)
      // at XMLHttpRequest.xhr.onreadystatechange (client.js:705)
      // at XMLHttpRequest.<anonymous> (instrument.ts:287)
      //   at XMLHttpRequest.sentryWrapped (helpers.ts:87) true undefined POST https://dev5.vfltest.com/app/sapi/dm/mesg/upload (4) ["crossDomain", "status", "method", "url"]

      //So I guess I just pop a quick ping on it if we really do get a connection error.
      //Since file uploads have special circumstances that ping does not, I hope it will be a
      //more reliable indicator of connection status.

      sapi.Ping.connectionPing()
        .then(() => {
          reject(err);
        })
        .catch((pingErr) => {
          //Subtle thing, I'm checking the ping error status
          //and then throwing the original error if it looks like we have a connection problem.
          //this is to make sure we're pairing the proper error response with the promise handler this returns to.
          //Might not be necessary, but it feels most correct here.
          if(sapi.isConnectionError(pingErr)){
            sapi.onConnectionErrorCaught(err);
          }
          reject(err);
        })
    }
    else if(err && err.status >= 400 && err.status < 404){
      //Special case, if we catch a 403 from an authenticated api, it means their session is invalid.
      //Some of the backend calls return this instead of an APP_SESS_INVALID error.
      //bug 2495
      sapi.onInvalidSessionCaught(err);
      reject(err);
    }
    else{
      //rethrow for all the error handlers.
      throw err;
    }
  })
}

sapi.globalErrorHandlers = (err) => {

  //careful in here, every sapi error comes through!
  //do what you need to with them, but make sure that an error gets thrown in this method
  //so that any listeners receive it.

  if(sapi.isConnectionError(err)){
    sapi.onConnectionErrorCaught(err);
    return Promise.reject(err);
  }
  else if(err && err.status >= 400 && err.status < 404){
    //Special case, if we catch a 403 from an authenticated api, it means their session is invalid.
    //Some of the backend calls return this instead of an APP_SESS_INVALID error.
    //bug 2495
    sapi.onInvalidSessionCaught(err);
    return Promise.reject(err);
  }
  else{
    //rethrow for all the error handlers.
    throw err;
  }
}

sapi.shouldUIErrorTriggerApplicationError = (err) => {

  if(sapi.isConnectionError(err)){
    return false;
  }

  let errName = _.get(err, 'name');
  let errCode = _.get(err, 'code');
  if(errName && errCode && (errName === 'APP_SESS_INVALID' || errName === 'APP_ACCT_UNAVAIL')){
    return false;
  }

  if(err && err.status >= 400 && err.status < 404){
    return false;
  }

  return true;
}

sapi.Ping = {
  uri: '/sapi/ping',

  url() {
    return sapi.apiRoot(this.uri);
  },

  connectionPing() {

    //Special call for the connection error page.  We need to ping without any of the usual error handling

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
  },

  test(token) {
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set({[c.api.X_TOKEN] : token})
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then((res, err) => {
        return sapi.responseHdl(res, err, true)
      })
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.TFA = {
  uri: '/sapi/acct/tfa',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  getQRCode(){
    return request
      .post(this.url('qrcode'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  status(){
    return request
      .post(this.url('status'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  enableSMS(code, password){
    let req = {
      code,
      password,
      method: 'sms'
    }
    return request
      .post(this.url('enable'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  disableSMS(password){
    let req = {
      method: 'sms',
      password
    }
    return request
      .post(this.url('disable'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  enable(code, password){
    let req = {
      code,
      password,
      method: 'totp'
    }
    return request
      .post(this.url('enable'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  disable(password){
    let req = {
      password,
      method: 'totp'
    }
    return request
      .post(this.url('disable'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  registerSMS(number, call_flag){
    let req = {number};
    if(call_flag){
      req.call_flag = 1;
    }
    return request
      .post(this.url('sms/register'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sendCode(token, call_flag){
    let req = {};
    req.call_flag = call_flag ? 1 : 0;

    return request
      .post(this.url('sms/send'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders(token))
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  login(code, add_device, label, token) {
    return request
      .post(this.url('login'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .set({[c.api.X_TOKEN] : token})
      .send({ code, add_device, label })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  trustedDeviceRename(device_id, label){
    return request
      .post(this.url('device/label'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({device_id, label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  trustedDeviceDelete(device_id){
    return request
      .post(this.url('device/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({device_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  trustedDeviceList(){
    return request
      .post(this.url('device/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.ResetConfirm = {
  uri : '/sapi/acct/reset/confirm',

  url() {
    return sapi.apiRoot(this.uri);
  },

  post(email, code) {
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ email, code })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.SendPwdReset = {
  uri : '/sapi/acct/reset/confirm',

  url() {
    return sapi.apiRoot(this.uri);
  },

  post(email, code) {
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ email, code })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Workspace = {
  uri: '/sapi/workspace',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  availableGuests(forum_id){
    return request
      .post(this.url('guest/avail'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  setNotifyFlag(forum_id, host_uid, notify){
    let params = { forum_id, flag : notify ? 1 : 0 }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('mark'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  archive(forum_id, host_uid, isArchived){

    let params = {forum_id, hide_flag : isArchived}

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('hide'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(forum_id){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  rename(forum_id, label){
    return request
      .post(this.url('label'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  add(label){
    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get() {
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send()
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  logo(host_uid, forum_id) {

    let req = {forum_id};
    if(host_uid){
      req.host_uid = host_uid;
    }

    return request
      .post(this.url('logo'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  downloadZip(forum_id, host_uid, doc_ids){
    let params = {forum_id, doc_ids};
    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('doc/zip'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  downloadZipStatus(queue_id){
    return request
      .post(this.url('doc/zip/status'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({queue_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteLogo() {
    return request
      .post(this.url('logo/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  uploadLogo(filename, file, progressFn) {

    if (!progressFn) {
      progressFn = _.noop;
    }

    return request
      .post(this.url('logo/upload'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .on('progress', progressFn)
      .attach(filename, file)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  getSignatureRequest(sign_request_id, host_uid){

    let params = {sign_request_id};
    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('sign/request/get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteSignatureRequest(sign_request_id){
    return request
      .post(this.url('sign/request/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({sign_request_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  validateSignCode(sign_request_id, host_uid, code){
    let params = {
      sign_request_id,
      code
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('sign/code/validate'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addSignCode(sign_request_id, host_uid, phone_number, call_flag){
    let params = {
      sign_request_id,
      phone_number
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    if(call_flag){
      params.call_flag = 1;
    }

    return request
      .post(this.url('sign/code/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addThreadSignatureRequest(forum_id, chat_id, mesg_id, doc_id, signer_info, expiry_date){
    let params = {forum_id, chat_id, mesg_id, doc_id, signer_info};

    if(expiry_date){
      params.expiry_date = expiry_date;
    }

    return request
      .post(this.url('sign/request/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  uploadSignedThreadDocument(forum_id, host_uid, doc_id, fileName, chat_id, sign_request_id, customInputArray, data, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }
    let headers = {};
    headers[c.api.X_FORUM_ID] = forum_id;
    headers[c.api.X_DOC_ID] = doc_id;
    headers[c.api.X_FILE_NAME] = utils.base64Encode(fileName);
    headers[c.api.X_CHAT_ID] = chat_id;
    headers[c.api.CONTENT_TYPE] = c.api.APPLICATION_OCTET_STREAM;
    headers[c.api.X_CUSTOM_INPUT] = utils.base64Encode(customInputArray);

    if(host_uid){
      headers[c.api.X_HOST_UID] = host_uid;
    }

    if(sign_request_id){
      headers[c.api.X_SIGN_REQUEST_ID] = sign_request_id;
    }

    return request
      .post(this.url('sign/upload/xhr'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .set(headers)
      .on('progress', progressFn)
      .send(data)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

}

sapi.Threads = {
  uri: '/sapi/workspace/thread',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  docInfo(forum_id, host_uid, doc_id){
    let params = { forum_id, doc_id };
    if(host_uid){
      params.host_uid = host_uid
    }

    return request
      .post(this.url('doc/info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  docRename(forum_id, doc_id, label){
    return request
      .post(this.url('doc/label'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, doc_id, label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  docPreupload(forum_id, host_uid, chat_id, doc_count, total_doc_size){

    let params = {
      forum_id: forum_id
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    if(chat_id){
      params.chat_id = chat_id;
    }

    if(doc_count){
      params.docs = doc_count;
    }

    if(total_doc_size){
      params.size = total_doc_size;
    }

    return request
      .post(this.url('doc/preupload'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  add(label, forum_id){
    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({label, forum_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  getPermList(forum_id){
    return request
      .post(this.url('perm/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ forum_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  docDelete(forum_id, host_uid, chat_id, doc_id){
    let params = {forum_id, doc_id, chat_id};
    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('doc/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  guests(forum_id, host_uid, chat_id){

    let req = {
      forum_id,
      chat_id
    }

    if (host_uid) {
      req.host_uid = host_uid;
    }

    return request
      .post(this.url('guest/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, host_uid, chat_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  rename(forum_id, chat_id, label){
    return request
      .post(this.url('label'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, chat_id, label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  threadDocAttach(forum_id, host_uid, chat_id, mesg, src_docs){

    // {
    //   "forum_id" : "51a534b5c62c6f75",
    //   "chat_id" : "56a2cafcb9017408",
    //   "mesg" : "This is my optional message",
    //   "source" : {
    //     "51a534b5c62c6f75" : {
    //       "host_uid" : "5e73beba43433af5",
    //         "docs" : [
    //         [ "51f1b5d090e98a6b", true ],
    //         [ "57bcc8e3693eb8b3", false ]
    //       ],
    //     }
    //   }
    // }


    let req = {
      forum_id,
      chat_id,
      mesg : mesg || '',
      source : {}
    }

    if(host_uid){
      req.host_uid = host_uid;
    }

    _.each(src_docs, (doc) => {
      //note, this is a ARRAY, not an object
      let param = [
        doc.doc_id,
        !!doc.signature_requested
      ]

      if(req.source[doc.forum_id]){
        req.source[doc.forum_id].docs.push(param);
      }
      else{
        let forumParam = {
          docs : [param]
        }
        if(doc.host_uid){
          forumParam.host_uid = doc.host_uid;
        }

        req.source[doc.forum_id] = forumParam;
      }
    })

    return request
      .post(this.url('mesg/attach/:new'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  removeQueueStatus(queue_id){
    return request
      .post(this.url('mesg/attach/status'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({queue_id, remove_flag: true})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  attachStatus(queue_id){
    return request
      .post(this.url('mesg/attach/status'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({queue_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  mark(forum_id, host_uid, chat_id, flag){

    let params = {
      forum_id,
      chat_id,
      flag : flag || 0
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('mark'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  updateReadlog(forum_id, host_uid, chat_id){
    let params = {
      forum_id,
      chat_id,
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('mesg/readlog/update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  messages(forum_id, host_uid, chat_id, data_date, no_readlog_flag){

    let params = {forum_id, host_uid, chat_id};
    if(data_date){
      params.data_date = data_date;
    }
    if(no_readlog_flag){
      params.no_readlog_flag = 1;
    }

    return request
      .post(this.url('mesg/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get(forum_id, host_uid, chat_id) {

    let params = { forum_id, host_uid };
    if(chat_id){
      params.chat_id = chat_id;
    }

    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sendMessage(forum_id, host_uid, chat_id, msg){

    let req = {
      forum_id,
      host_uid,
      chat_id,
      mesg: msg
    }

    return request
      .post(this.url('mesg/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  upload(forum_id, host_uid, chat_id, files, mesg, sign_flag, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }

    let fields = {
      forum_id,
      chat_id
    }

    if(mesg && mesg.length > 0){
      fields.mesg = mesg;
    }

    if(host_uid){
      fields.host_uid = host_uid
    }

    if(sign_flag) {
      fields.sign_flag = sign_flag;
    }

    let req = request
      .post(this.url('mesg/upload'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .field(fields)
      .on('progress', progressFn)

    _.each(files, (f) => {
      req.attach(f.name, f)
    })

    return req
      .then(sapi.responseHdl)
      .catch(sapi.fileUploadErrorHandlers)
  },

  uploadXhr(forum_id, host_uid, doc_id, fileName, chat_id, sign_request_id, signature_requested, msg, data, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }
    let headers = {};
    headers[c.api.X_FORUM_ID] = forum_id;
    headers[c.api.X_DOC_ID] = doc_id;
    headers[c.api.X_FILE_NAME] = utils.base64Encode(fileName);
    headers[c.api.X_CHAT_ID] = chat_id;
    headers[c.api.CONTENT_TYPE] = c.api.APPLICATION_OCTET_STREAM;

    if(msg && msg.length > 0){
      headers[c.api.X_MESG] = utils.base64Encode(msg);
    }

    if(host_uid){
      headers[c.api.X_HOST_UID] = host_uid;
    }

    if(sign_request_id){
      headers[c.api.X_SIGN_REQUEST_ID] = sign_request_id;
    }

    if(signature_requested){
      headers[c.api.X_SIGN_FLAG] = signature_requested;
    }

    return request
      .post(this.url('mesg/upload/xhr'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .set(headers)
      .on('progress', progressFn)
      .send(data)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(forum_id, chat_id){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, chat_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  editMessage(forum_id, host_uid, chat_id, mesg_id, msg, detachDocIds){
    let params = {forum_id, chat_id, mesg_id, mesg : msg}

    if(host_uid){
      params.host_uid = host_uid;
    }

    if(detachDocIds && _.keys(detachDocIds).length > 0){
      params.doc_id = detachDocIds;
    }

    return request
      .post(this.url('mesg/edit'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  msgHistory(forum_id, host_uid, chat_id, mesg_id){
    let params = {forum_id, chat_id, mesg_id}

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('mesg/history'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.DM = {
  uri: '/sapi/dm',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  list(sort, reverse) {

    let params = {
      no_preview_flag : true,
      limit : -1
    };
    if(sort){
      params.sort = sort;
    }

    if(reverse){
      params.reverse = reverse;
    }

    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  resendBill(guest_uid, mesg_id, mesg){
    let req = {
      guest_uid,
      mesg_id,
      mesg
    };

    return request
      .post(this.url('merchant/bill/resend'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addBill(guest_uid, amount, currency, description, days_until_due, no_decimal_flag, mesg){
    let req = {
      guest_uid,
      amount,
      currency,
      description,
      days_until_due
    };

    if(no_decimal_flag){
      req.no_decimal_flag = no_decimal_flag;
    }

    if(mesg){
      req.mesg = mesg;
    }

    return request
      .post(this.url('merchant/bill/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  dmMesgAttach(guest_uid, mesg, src_docs, last_forum_id){
    let req = {
      guest_uid,
      mesg : mesg || '',
      source : {}
    }

    if(last_forum_id){
      req.last_forum_id = last_forum_id;
    }

    _.each(src_docs, (doc) => {
      //note, this is a ARRAY, not an object
      let param = [
        doc.doc_id,
        !!doc.signature_requested
      ]

      if(req.source[doc.forum_id]){
        req.source[doc.forum_id].docs.push(param);
      }
      else{
        let forumParam = {
          docs : [param]
        }
        if(doc.host_uid){
          forumParam.host_uid = doc.host_uid;
        }

        req.source[doc.forum_id] = forumParam;
      }
    })

    return request
      .post(this.url('mesg/attach'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  previewList(guest_uids){
    let params = {}

    if(guest_uids){
      params.uid_list = guest_uids;
    }

    return request
      .post(this.url('preview/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  docDelete(guest_uid, doc_id){
    return request
      .post(this.url('doc/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({guest_uid, doc_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  docList(guest_uid){

    let params = { guest_uid, no_size_flag : true }

    if(guest_uid){
      params.guest_uid = guest_uid;
    }

    return request
      .post(this.url('doc/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(guest_uid){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({guest_uid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  mark(guest_uid, flag){

    let params = {
      guest_uid,
      flag : !!flag
    }

    return request
      .post(this.url('mark'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  messages(guest_uid, data_date){

    let params = {guest_uid};
    if(data_date){
      params.data_date = data_date;
    }

    return request
      .post(this.url('mesg/list/:new'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sendMessage(guest_uid, msg, last_forum_id, pup_flag){

    let req = {
      guest_uid,
      mesg: msg
    }

    if(last_forum_id){
      req.last_forum_id = last_forum_id;
    }

    if(pup_flag){
      req.pup_flag = 1;
    }

    return request
      .post(this.url('mesg/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  editMessage(guest_uid, mesg_id, msg, detachDocIds){
    let req = {
      guest_uid,
      mesg_id,
      mesg: msg
    }

    if(detachDocIds && _.keys(detachDocIds).length > 0){
      req.doc_id = detachDocIds;
    }

    return request
      .post(this.url('mesg/edit'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  history(guest_uid, mesg_id){
    let req = {
      guest_uid,
      mesg_id
    }

    return request
      .post(this.url('mesg/history'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  preupload(guest_uid, doc_count, total_doc_size){

    let params = {
      guest_uid: guest_uid
    }

    if(doc_count){
      params.docs = doc_count;
    }

    if(total_doc_size){
      params.size = total_doc_size;
    }

    return request
      .post(this.url('mesg/preupload'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  upload(guest_uid, files, mesg, sign_flag, progressFn, last_forum_id, pup_flag){

    if(!progressFn){
      progressFn = _.noop;
    }

    let fields = {
      guest_uid
    }

    if(mesg && mesg.length > 0){
      fields.mesg = mesg;
    }

    if(sign_flag) {
      fields.sign_flag = sign_flag;
    }

    if(last_forum_id){
      fields.last_forum_id = last_forum_id;
    }

    if(pup_flag){
      fields.pup_flag = 1;
    }

    let req = request
      .post(this.url('mesg/upload'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .field(fields)
      .on('progress', progressFn)

    _.each(files, (f) => {
      req.attach(f.name, f)
    })

    return req
      .then(sapi.responseHdl)
      .catch(sapi.fileUploadErrorHandlers)
  },

  uploadXhr(guest_uid, doc_id, fileName, sign_request_id, signature_requested, msg, data, last_forum_id, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }

    let headers = {};
    headers[c.api.X_GUEST_UID] = guest_uid;
    headers[c.api.X_DOC_ID] = doc_id;
    headers[c.api.X_FILE_NAME] = utils.base64Encode(fileName);
    headers[c.api.CONTENT_TYPE] = c.api.APPLICATION_OCTET_STREAM;

    if(msg && msg.length > 0){
      headers[c.api.X_MESG] = utils.base64Encode(msg);
    }

    if(sign_request_id){
      headers[c.api.X_SIGN_REQUEST_ID] = sign_request_id;
    }

    if(signature_requested){
      headers[c.api.X_SIGN_FLAG] = signature_requested;
    }

    if(last_forum_id){
      headers[c.api.X_LAST_FORUM_ID] = last_forum_id;
    }

    return request
      .post(this.url('mesg/upload/xhr'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .set(headers)
      .on('progress', progressFn)
      .send(data)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  uploadSignedDocument(guest_uid, doc_id, fileName, sign_request_id, customInputArray, data, last_forum_id, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }

    let headers = {};
    headers[c.api.X_GUEST_UID] = guest_uid;
    headers[c.api.X_DOC_ID] = doc_id;
    headers[c.api.X_FILE_NAME] = utils.base64Encode(fileName);
    headers[c.api.CONTENT_TYPE] = c.api.APPLICATION_OCTET_STREAM;
    headers[c.api.X_CUSTOM_INPUT] = utils.base64Encode(customInputArray);

    if(sign_request_id){
      headers[c.api.X_SIGN_REQUEST_ID] = sign_request_id;
    }

    if(last_forum_id){
      headers[c.api.X_LAST_FORUM_ID] = last_forum_id;
    }

    return request
      .post(this.url('sign/upload/xhr'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .set(headers)
      .on('progress', progressFn)
      .send(data)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  getSignatureRequest(sign_request_id, guest_uid){
    return request
      .post(this.url('sign/request/get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({sign_request_id, guest_uid})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteSignatureRequest(sign_request_id, guest_uid){
    return request
      .post(this.url('sign/request/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({sign_request_id, guest_uid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  validateSignCode(sign_request_id, guest_uid, code){
    let params = {
      guest_uid,
      sign_request_id,
      code
    }

    return request
      .post(this.url('sign/code/validate'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addSignCode(sign_request_id, guest_uid, phone_number, call_flag){
    let params = {
      sign_request_id,
      guest_uid,
      phone_number
    }

    if(call_flag){
      params.call_flag = 1;
    }

    return request
      .post(this.url('sign/code/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addThreadSignatureRequest(guest_uid, mesg_id, doc_id, signer_info, expiry_date){
    let params = {guest_uid, mesg_id, doc_id, signer_info};

    if(expiry_date){
      params.expiry_date = expiry_date;
    }

    return request
      .post(this.url('sign/request/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Docs = {
  uri: '/sapi/workspace/doc',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  guests(forum_id, host_uid, doc_id){
    return request
      .post(this.url('guest/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, host_uid, doc_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  permissions(forum_id){
    return request
      .post(this.url('perm/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  availableDocs(forum_id, inclusive_flag){

    let req = {};
    if(forum_id){
      req.forum_id = forum_id;
    }

    if(inclusive_flag === true || inclusive_flag === false){
      req.inclusive_flag = inclusive_flag;
    }

    return request
      .post(this.url('avail'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  metadata(forum_id, host_uid, doc_id){
    let params = {
      forum_id,
      doc_id
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('metadata'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  copyDocs(dest_forum_id, src_docs){
    let req = {
      dst: {
        forum_id : dest_forum_id
      },
      source : {}
    }

    _.each(src_docs, (doc) => {
      if(req.source[doc.forum_id]){
        req.source[doc.forum_id].push({
          [doc.doc_id] : !!doc.signature_requested
        });
      }
      else{
        req.source[doc.forum_id] = [{
          [doc.doc_id] : !!doc.signature_requested
        }];
      }
    })

    return request
      .post(this.url('copy'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  uploadXhr(forum_id, host_uid, doc_id, fileName, data, progressFn){
    if(!progressFn){
      progressFn = _.noop;
    }
    let headers = {};
    headers[c.api.X_FORUM_ID] = forum_id;
    headers[c.api.X_DOC_ID] = doc_id;
    headers[c.api.X_FILE_NAME] = utils.base64Encode(fileName);
    headers[c.api.CONTENT_TYPE] = c.api.APPLICATION_OCTET_STREAM;

    if(host_uid){
      headers[c.api.X_HOST_UID] = host_uid;
    }

    return request
      .post(this.url('upload/xhr'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .set(headers)
      .on('progress', progressFn)
      .send(data)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  upload(forum_id, host_uid, filename, file, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }

    let fields = {
      forum_id,
    }

    if(host_uid){
      fields.host_uid = host_uid
    }

    return request
      .post(this.url('upload'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .field(fields)
      .on('progress', progressFn)
      .attach(filename, file)
      .then(sapi.responseHdl)
      .catch(sapi.fileUploadErrorHandlers)
  },

  view(forum_id, host_uid, doc_id, useArraybuffer){
    let params = { forum_id, doc_id };

    if(host_uid){
      params.host_uid = host_uid;
    }

    if(useArraybuffer){
      return request
        .post(this.url('view'))
        .timeout({ response: c.api.TIMEOUT_MS })
        .responseType('arraybuffer')
        .set(sapi.globalHeaders())
        .send(params)
        .then(sapi.rawResponseHdl)
        .catch(sapi.globalErrorHandlers)
    }
    else{
      return request
        .post(this.url('view'))
        .timeout({ response: c.api.TIMEOUT_MS })
        .set(sapi.globalHeaders())
        .send(params)
        .then(sapi.textResponseHdl)
        .catch(sapi.globalErrorHandlers)
    }
  },

  viewImg(forum_id, host_uid, doc_id){
    let params = { forum_id, doc_id };

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('view'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .responseType('arraybuffer')
      .set(sapi.globalHeaders())
      .send(params)
      .then(sapi.dataUriResponseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  viewImgHeic(forum_id, host_uid, doc_id){
    let params = { forum_id, doc_id };

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('view'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .responseType('arraybuffer')
      .set(sapi.globalHeaders())
      .send(params)
      .then(sapi.dataUriHeicResponseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  thumbnail(forum_id, host_uid, doc_id){
    let params = {
      forum_id,
      doc_id
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('thumb'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  mark(forum_id, host_uid, doc_id, flag){

    let params = {
      forum_id,
      doc_id,
      flag : flag ? 1 : 0
    }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('mark'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(forum_id, doc_id){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({forum_id, doc_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  list(forum_id, host_uid, chat_id){

    let params = { forum_id, chat_id, no_size_flag : true }

    if(host_uid){
      params.host_uid = host_uid;
    }

    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.SignTermsTemplate = {
  uri: '/sapi/sign/terms',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  list(){
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get(terms_id) {
    let params = {terms_id};
    return request
      .post(this.url('get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  add(terms){
    let req = {terms};

    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  update(terms_id, terms){
    let req = {terms_id, terms};

    return request
      .post(this.url('update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(terms_id) {
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ terms_id })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}

sapi.Handle = {
  uri: '/sapi/handle',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  add(handle, text){
    let req = {handle};

    if(text){
      req.text = text;
    }

    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  update(handle, text, active_flag){
    let req = {};

    if(handle){
      req.handle = handle;
    }
    if(text){
      req.text = text;
    }
    if(!_.isUndefined(active_flag)){
      req.active_flag = active_flag ? 1 : 0
    }

    return request
      .post(this.url('update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get() {
    return request
      .post(this.url('get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete() {
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}

sapi.Merchant = {
  uri: '/sapi/merchant',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  register(merchant_id){
    let req = {};

    if(merchant_id){
      req.merchant_id = merchant_id;
    }

    return request
      .post(this.url('register'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  refreshInvoiceUrl(uid, payer_uid, trans_id){
    let req = {
      uid,
      payer_uid,
      trans_id
    };

    return request
      .post(this.url('bill/get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deregister(){
    let req = {};

    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  info() {
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  listBills(){
    return request
      .post(this.url('bill/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  refundInvoice(payer_uid, trans_id, amount, no_decimal_flag, reason){
    let req = { payer_uid, trans_id};

    if(amount){
      req.amount = amount;
    }

    if(no_decimal_flag){
      req.no_decimal_flag = no_decimal_flag;
    }

    if(reason){
      req.reason = reason;
    }

    return request
      .post(this.url('bill/refund'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  voidInvoice(payer_uid, trans_id){
    let req = {payer_uid,  trans_id};

    return request
      .post(this.url('bill/void'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  resendBill(forum_id, chat_id, mesg_id, mesg){
    let req = {
      forum_id,
      chat_id,
      mesg_id,
      mesg
    };

    return request
      .post(this.url('bill/resend'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addBill(payer_uid, amount, currency, description, forum_id, host_uid, chat_id, days_until_due, no_decimal_flag, mesg){
    let req = {
      payer_uid,
      amount,
      currency,
      description,
      forum_id,
      days_until_due
    };

    if(host_uid){
      req.host_uid = host_uid;
    }

    if(chat_id){
      req.chat_id = chat_id;
    }

    if(no_decimal_flag){
      req.no_decimal_flag = no_decimal_flag;
    }

    if(mesg){
      req.mesg = mesg;
    }

    return request
      .post(this.url('bill/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.SignTemplate = {
  uri: '/sapi/sign/template',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  add(label, sign_data){
    let req = {label, sign_data};

    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  rename(template_id, label){
    return request
      .post(this.url('label'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({template_id, label})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  list(sort, reverse){
    let params = {};
    if(sort){
      params.sort = sort;
    }
    if(reverse){
      params.reverse = reverse;
    }

    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  info(template_id) {
    let params = {template_id};
    return request
      .post(this.url('get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(template_id) {
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ template_id })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}

sapi.SignArchive = {
  uri: '/sapi/sign/archive',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  list(sort, reverse){
    let params = {};
    if(sort){
      params.sort = sort;
    }
    if(reverse){
      params.reverse = reverse;
    }

    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  info(sign_request_id, host_uid) {
    let params = {};
    if(sign_request_id){
      params.sign_request_id = sign_request_id;
    }
    if(host_uid){
      params.host_uid = host_uid;
    }
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(sign_request_id) {
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ sign_request_id })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  view(sign_request_id, host_uid, label, doc_key, useArraybuffer){
    let params = { sign_request_id, host_uid, label, doc_key };

    if(useArraybuffer){
      return request
        .post(this.url('view'))
        .timeout({ response: c.api.TIMEOUT_MS })
        .responseType('arraybuffer')
        .set(sapi.globalHeaders())
        .send(params)
        .then(sapi.rawResponseHdl)
        .catch(sapi.globalErrorHandlers)
    }
    else{
      return request
        .post(this.url('view'))
        .timeout({ response: c.api.TIMEOUT_MS })
        .set(sapi.globalHeaders())
        .send(params)
        .then(sapi.textResponseHdl)
        .catch(sapi.globalErrorHandlers)
    }
  },
}

sapi.Contacts = {
  uri: '/sapi/contact',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  add(contacts){
    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({
        contact : contacts
      })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sendInvitationLink(guest_uid){
    return request
      .post(this.url('rsvp'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({
        guest_uid
      })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  queue(){
    return request
      .post(this.url('queue'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  update(guest_uid, email, first_name, last_name, title, company, phone, address, note){

    let params = { guest_uid, title, company, phone, address, note };

    if(email){
      params.email_address = email;
    }
    if(first_name){
      params.first_name = first_name;
    }
    if(last_name){
      params.last_name = last_name;
    }

    return request
      .post(this.url('update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get(guest_uid) {
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ guest_uid })
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  delete(guest_uid) {
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ guest_uid })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  uploadCSV(filename, file, progressFn){

    if(!progressFn){
      progressFn = _.noop;
    }

    return request
      .post(this.url('upload'))
      .timeout({
        response: c.api.FILE_UPLOAD_TIMEOUT,
        deadline : c.api.FILE_UPLOAD_DEADLINE
      })
      .set(sapi.globalHeaders())
      .on('progress', progressFn)
      .attach(filename, file)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  subscribePublisher(uid){
    return request
      .post(this.url('publisher/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send({uid})
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  unsubscribePublisher(uid){
    return request
      .post(this.url('publisher/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send({uid})
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Guests = {
  uri: '/sapi/workspace/guest',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  updateGuestPermissions(forum_id, guest_uid, granting_chat_ids, denying_chat_ids){
    //add           forum_id                    guest_uid                   chat_uid        has access
    //{"forum_id":"576b065d1702cdf7","perm":{"579941d6b24cb6ed":{"chat":{"5799423e08582bb1":true}}}}

    let chatPerms = {};
    _.each(granting_chat_ids, (chat_id => {
      chatPerms[chat_id] = true
    }))

    _.each(denying_chat_ids, (chat_id => {
      chatPerms[chat_id] = false
    }))

    let req = {
      forum_id,
      perm : {
        [guest_uid]: {
          chat : chatPerms
        }
      }
    }

    return request
      .post(this.url('perm/update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  updateThreadPermissions(forum_id, chat_id, granting_guest_uids, denying_guest_uids){
    //add           forum_id                    guest_uid                   chat_uid        has access
    //{"forum_id":"576b065d1702cdf7","perm":{"579941d6b24cb6ed":{"chat":{"5799423e08582bb1":true}}}}

    let req = {
      forum_id,
      perm : {}
    }

    _.each(granting_guest_uids, (guest_uid) => {
      req.perm[guest_uid] = {
        chat : {
          [chat_id] : true
        }
      }
    })

    _.each(denying_guest_uids, (guest_uid) => {
      req.perm[guest_uid] = {
        chat : {
          [chat_id] : false
        }
      }
    })

    return request
      .post(this.url('perm/update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  add(forum_id, guest_uids){

    let params = {
      forum_id,
      guest_uid : guest_uids,
      no_thread : true
    };

    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  get(forum_id, host_uid) {
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ forum_id, host_uid })
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  remove(guest_uid, forum_id){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({ forum_id, guest_uid })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.AccountInfo = {
  uri: '/sapi/acct',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  get() {
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  update(user) {
    return request
      .post(this.url('update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(user)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sessionList(){
    return request
      .post(this.url('session/get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  sessionDelete(sid){
    return request
      .post(this.url('session/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({sid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addSignatures(sigB64, initialsB64){
    return request
      .post(this.url('signature/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({
        signature : sigB64,
        initials : initialsB64
      })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteSignatures(){
    return request
      .post(this.url('signature/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  getSignatures(){
    return request
      .post(this.url('signature/get'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  logout(){
    return request
      .post(this.url('logout'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Notify = {
  uri: '/sapi/notify',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  addPushToken(token){
    return request
      .post(this.url('push/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({token})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  reportError(emailSubject, userMessage, stack){

    let req = {
      subject : emailSubject,
      user_input : userMessage || '-- None supplied --',
      stack_trace : stack || ''
    }

    return request
      .post(this.url('email/log'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(req)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  count() {
    return request
      .post(this.url('count'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  list(){
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteAlert(alert_id){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({alert_id})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  deleteUserAlerts(guest_uid){
    return request
      .post(this.url('delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({guest_uid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  flush(){
    return request
      .post(this.url('flush'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.NotifyBlock = {
  uri: '/sapi/notify/block',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  areDownloadAlertsBlocked(){
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .then((res) => {
        let alertsAreBlocked = false;
        _.each(res.data, (block) => {
          if(block.type && block.type === 'download'){
            let hasNonNullEntry = false;
            _.each(_.keys(block), (key) => {
              if(key !== 'type' && block[key]){
                hasNonNullEntry = true;
              }
            })

            if(!hasNonNullEntry){
              alertsAreBlocked = true;
            }
          }
        })
        return alertsAreBlocked;
      })
      .catch(sapi.globalErrorHandlers)
  },

  userList(){
    return request
      .post(this.url('user/list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  blockGuest(guest_uid){
    return request
      .post(this.url('user/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({guest_uid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  unblockGuest(guest_uid){
    return request
      .post(this.url('user/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({guest_uid})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  disableDND(){
    return request
      .post(this.url('donotdisturb/delete'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({type:'download'})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  enableDND(){
    return request
      .post(this.url('donotdisturb/add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({type:'download'})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}

sapi.Subscription = {
  uri: '/sapi/acct/class/subscription',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  list(){
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.AccountEmail = {
  uri: '/sapi/acct/email',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  confirm(email, code){

    let req = {
      email,
      code
    }

    return request
      .post(this.url('confirm'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send(req)
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  addEmail(email){

    let req = {
      email
    }

    return request
      .post(this.url('add'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send(req)
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  validationStatus(email, clear_flag){
    let params = {
      email
    }

    if(clear_flag){
      params.clear_flag = 1;
    }

    return request
      .post(this.url('status'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Stripe = {
  uri: '/sapi/stripe',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  availablePlans(){
    return request
      .post(this.url('2/avail'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  subscribeV2(payment_method_id, class_id, couponId, pi_id){

    let req = {
      class_id,
    }

    if(payment_method_id){
      req.payment_method_id = payment_method_id;
    }

    if(couponId){
      req.coupon = couponId;
    }
    if(pi_id){
      req.pi_id = pi_id;
    }

    return request
      .post(this.url('2/subscribe'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send(req)
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  updateV2(payment_method_id, couponId, is_charge){

    let req = {
      payment_method_id
    }

    if(couponId){
      req.coupon = couponId;
    }
    if(is_charge){
      req.is_charge = is_charge;
    }

    return request
      .post(this.url('2/update'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send(req)
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  class_1(){
    return request
      .post(this.url('plan/1'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  class_100(){
    return request
      .post(this.url('plan/100'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  unsubscribe(){
    return request
      .post(this.url('2/unsubscribe'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .send({})
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  list(){
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.AccountDelete = {
  uri: '/sapi/acct/delete',

  url(){
    return sapi.apiRoot(this.uri);
  },

  deleteAccount(pwd) {
    return request
      .post(this.url())
      .timeout({response: c.api.TIMEOUT_MS})
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({
        'password': pwd
      })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Password = {
  uri: '/sapi/acct/password',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  togglePasswordReset(setEnabled, password){
    return request
      .post(this.url('control'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({
        password: password,
        function: setEnabled ? 'enable' : 'disable'
      })
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  getPasswordReset(){
    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send({})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  reset(old_pwd, new_pwd, send_mail_flag){

    let params = {
      new_password : new_pwd,
      cur_password : old_pwd,
    }
    if(send_mail_flag === true || send_mail_flag === false){
      params.send_mail_flag = send_mail_flag;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .send(params)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },

  info(){
    return request
      .post(this.url('info'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  }
}

sapi.Publisher = {
  uri: '/sapi/inst/publisher',

  url(action) {
    return sapi.apiRoot(this.uri) + (action ? `/${action}` : '')
  },

  list(){
    return request
      .post(this.url('list'))
      .timeout({ response: c.api.TIMEOUT_MS })
      .set(sapi.globalHeaders())
      .set({[c.api.CONTENT_TYPE] : c.api.APPLICATION_JSON})
      .retry(c.api.API_RETRY_COUNT)
      .then(sapi.responseHdl)
      .catch(sapi.globalErrorHandlers)
  },
}


export default sapi;
