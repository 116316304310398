import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import c from '../../../util/const'
import TextareaAutosize from 'react-textarea-autosize';

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import UserBadge from "../badges/UserBadge";
import utils from "../../../util/util";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import {getMessageForError} from "../../../util/errors";
import MaxChatMessageWarning from "./MaxChatMessageWarning";
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import SignatureRequest from "../../../models/SignatureRequest";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";
import msgHelper from "../../../helpers/msg-helper";
import ProgressBar from "../elements/ProgressBar";
import workspaceActions from "../../../actions/workspace-actions";

class DocSigningStatus extends Component {

  constructor(props){
    super(props);

    this.onCompleteRequestClick = this.onCompleteRequestClick.bind(this);
    this.onCancelRequestClick = this.onCancelRequestClick.bind(this);
    this.onFulfillSignatureRequestClick = this.onFulfillSignatureRequestClick.bind(this);

    this.state = {}
  }

  findGuest(guest_uid){
    let {
      accountInfoGuest,
      dm
    } = this.props;

    let user = null;
    if(dm){
      if(guest_uid === accountInfoGuest.guest_uid){
        user = _.extend({}, accountInfoGuest);
      }
      else{
        user = _.extend({}, dm);
      }
    }
    else{
      if(guest_uid === accountInfoGuest.guest_uid){
        user = _.extend({}, accountInfoGuest);
      }
      else{
        user = this.props.findGuestInActiveThreadParticipants(guest_uid);
      }
    }
    return user;
  }

  onCompleteRequestClick(){
    let {msg, doc} = this.props;
    this.props.completeRequestClick(msg, doc);
  }

  onCancelRequestClick(){
    let {msg, doc} = this.props;
    this.props.cancelSignatureRequestClick(msg, doc);
  }

  onFulfillSignatureRequestClick(){
    let {msg, doc} = this.props;
    this.props.fulfillSignatureRequestClick(msg, doc);
  }

  renderRequestIncomplete(){
    let {accountInfoGuest, msg, t} = this.props;
    if (msg.guest_uid === accountInfoGuest.guest_uid) {
      return (
        <Fragment>
          <div style={styles.signingText}>
            <i className="icon ion-android-warning align-baseline pr-1 text-warning"
               style={{ fontSize: '18px' }}/>
            {t("Signing request incomplete")}
            <Button onClick={this.onCompleteRequestClick} className="btn btn-sm btn-signing ml-2">
              {t("Finish Request")}
            </Button>
          </div>
          <div className="text-right">
            <Button onClick={this.onCancelRequestClick} className="btn btn-sm btn-link assertive-color">
              {t("Cancel Signature Request")}
            </Button>
          </div>
        </Fragment>
      )
    }
  }

  renderSigningStatus(doc){
    let {
      t,
      accountInfoGuest,
      msg,
      workspace,
      dm
    } = this.props;

    //doc_id: "6181c5b975884cd1"
    // label: "UTC date (2) (1).pdf"
    // sign_request_id: "618b21ac67c88969"
    // signer_info: Array(3)
    //  0:
    //    sign_status: "pending signature"
    //    signer_uid: "6181c4ef343abcd7"
    //    [[Prototype]]: Object
    //  1: {sign_status: 'pending signature', signer_uid: '6181c4eb53001ea7'}
    //  2: {sign_status: 'pending signature', signer_uid: '6181826c434cd496'}

    let isRequestor = false;
    if(dm){
      isRequestor = msg.guest_uid === accountInfoGuest.guest_uid;
    }
    else if(workspace){
      //Only hosts can create signature requests.  So if you're the host
      //then you're the requestor.
      isRequestor = !workspace.host_uid
    }

    let {
      uploadProgress,
      activeUpload
    } = this.props;
    let nextUserToSign = _.find(doc.signer_info, (info) => info.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED)
    return (
      <>
        {_.map(doc.signer_info, (info, signerOrderIndex) => {
          let guestInfo = this.findGuest(info.signer_uid);
          let isYou = info.signer_uid === accountInfoGuest.guest_uid;
          if(info.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED){

            if(isYou && uploadProgress && activeUpload && activeUpload.original_mesg_id === msg.mesg_id && activeUpload.doc_id === doc.doc_id){
              return (
                <div key={info.signer_uid} style={styles.signingProgressWrap}>
                  <ProgressBar percentProgress={uploadProgress.percent}/>
                </div>
              )
            }

            if(nextUserToSign && nextUserToSign.signer_uid === info.signer_uid){
              let displayText = "";
              if(isYou){
                displayText = `${t("Your signature is requested")}`;
              }
              else{
                if(!guestInfo){
                  displayText = `${t("Signer")} #${(signerOrderIndex + 1)} ${t("does not have access to this thread")}`
                }
                else{
                  displayText = `${guestInfo.first_name} ${t("has been notified")}`
                }
              }

              return (
                <div key={info.signer_uid} style={styles.signingText}>
                  {!guestInfo &&
                  <span className="mr-1" style={styles.warningIconWrap}>
                    <i className="icon ion-android-warning align-baseline text-warning" />
                  </span>
                  }
                  {guestInfo &&
                  <span className="mr-1">
                    <UserBadge guest={guestInfo}
                               overrideColor={isYou ? colors.PRIMARY : null}
                               small={true}/>
                  </span>
                  }
                  {displayText}
                  {isYou && <Button onClick={this.onFulfillSignatureRequestClick}
                                    className="btn btn-sm btn-signing ml-2">{t("Click Here to Sign")}</Button>}
                </div>
              )
            }
            else{
              //Then signing has been requested of this user, but it's not their turn yet.
              let displayText = '';
              let signerInfoBeforeThisOne = signerOrderIndex > 0 ? doc.signer_info[signerOrderIndex - 1] : null;
              let guestInfoBeforeThisOne = this.findGuest(signerInfoBeforeThisOne.signer_uid);
              let isBeforeGuestInfoYou = signerInfoBeforeThisOne.signer_uid === accountInfoGuest.guest_uid;

              if(!guestInfo){
                displayText = `${t("Signer")} #${(signerOrderIndex + 1)} ${t("does not have access to this thread")}`
              }
              else {
                if (isYou) {
                  displayText += `${t("You")}`
                }
                else {
                  displayText += `${guestInfo.first_name}`
                }
                displayText += ` ${t("will sign after")} `
                if (isBeforeGuestInfoYou) {
                  displayText += `${t("You")}`
                }
                else if (!guestInfoBeforeThisOne) {
                  //sneaky, this is previous guest in list, and the list is 0-indexed.  So just display index as we have it.
                  displayText += `${t("Signer")} #${(signerOrderIndex)}`
                }
                else {
                  displayText += `${guestInfoBeforeThisOne.first_name}`
                }
              }

              return (
                <div key={info.signer_uid} style={styles.signingText}>
                  {!guestInfo &&
                  <span className="mr-1" style={styles.warningIconWrap}>
                    <i className="icon ion-android-warning align-baseline text-warning" />
                  </span>
                  }
                  {guestInfo &&
                  <span className="mr-1">
                    <UserBadge guest={guestInfo}
                               overrideColor={isYou ? colors.PRIMARY : null}
                               small={true}/>
                  </span>
                  }
                  {displayText}
                </div>
              )
            }
          }
          else if(info.sign_status === SignatureRequest.SIGN_STATUS.SIGNED){
            let displayText = '';
            if(isYou){
              displayText = `${t("You have signed")}`;
            }
            else if(!guestInfo){
              displayText = `${t("Signer")} #${(signerOrderIndex + 1)} ${t("has signed")}`;
            }
            else{
              displayText = `${guestInfo.first_name} ${t("has signed")}`;
            }
            return (
              <div key={info.signer_uid} style={styles.signingText}>
                {!guestInfo &&
                <span className="mr-1" style={styles.warningIconWrap}>
                  <i className="icon ion-android-warning align-baseline text-warning"/>
                </span>
                }
                {guestInfo &&
                <span className="mr-1">
                  <UserBadge guest={guestInfo}
                             overrideColor={isYou ? colors.PRIMARY : null}
                             small={true}/>
                </span>
                }
                {displayText}
                <i className="icon ion-android-checkmark-circle ml-2"/>
              </div>
            )
          }
          else if(info.sign_status === SignatureRequest.SIGN_STATUS.EXPIRED){
            return <span key={info.signer_uid} />
          }
          else if(info.sign_status === SignatureRequest.SIGN_STATUS.UNKNOWN){
            return <span key={info.signer_uid} />
          }
        })}
        {isRequestor && nextUserToSign &&
        <div className="text-right">
          <Button onClick={this.onCancelRequestClick} className="btn btn-sm btn-link assertive-color">
            {t("Cancel Signature Request")}
          </Button>
        </div>
        }
      </>
    )
  }

  render(){
    let {
      doc,
    } = this.props;

    // log.log('doc signing status render', doc);
    let isIncomplete = msgHelper.isSigningStatusIncomplete(doc)
    return (
      <div>
          {isIncomplete && this.renderRequestIncomplete(doc) }
          {!isIncomplete && this.renderSigningStatus(doc) }
      </div>
    )
  }
}

const styles = {
  signingText : {
    fontSize : '14px',
    lineHeight: '31px',
    minHeight: '31px',
    color: colors.DARK,
    marginLeft: '25px',
    marginBottom: '5px'
  },
  signingProgressWrap : {
    marginLeft: '25px',
    marginTop: '10px',
    marginBottom: '5px'
  },
  warningIconWrap: {
    width: '26px',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '22px'
  }
}

DocSigningStatus.propTypes = {
  doc: PropTypes.object.isRequired,
  msg : PropTypes.object.isRequired,

  cancelSignatureRequestClick : PropTypes.func.isRequired,
  completeRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,

  workspace : PropTypes.object,
  dm : PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,

    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    findGuestInActiveThreadParticipants: (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid)),
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DocSigningStatus)));
