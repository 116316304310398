import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import UpgradeDialogNew from "./UpgradeDialogNew";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";
import utils from "../../util/util";
import _ from "lodash";
import c from "../../util/const";
import CurrencyInput from "../partials/elements/CurrencyInput";

class RefundInvoiceDialog extends Component {

  REFUND_REASONS = {
    NOT_SPECIFIED : null,
    DUPLICATE : 'duplicate',
    FRAUDULENT : 'fraudulent',
    REQUESTED_BY_CUSTOMER : 'requested_by_customer'
  }

  constructor(props) {
    super(props);

    this.firstInputRef = React.createRef()

    this.state = {
      amount : 0,
      maxRefund : 0,
      reason: '',
      validationErr : []
    }
  }

  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }

    let {
      invoice
    } = this.props.modalProps;
    let maxRefund = +_.get(invoice, 'amount', 0) - +_.get(invoice, 'refund_amount', 0);
    this.setState({
      amount : maxRefund,
      maxRefund
    })

    let ref = null;
    utils.waitForCondition(() => {
        ref = _.get(this.firstInputRef, 'current');
        return !!(ref)
      })
      .then(() => {
        ref.focus();
      })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }

  closeModal(res) {
    this.props.close(res);
  }

  onEscapeKey(){
    this.closeModal(false);
  }

  submit(){
    if (!this.doValidation()) {
      return
    }

    this.closeModal({
      amount : this.state.amount,
      reason : this.state.reason
    })
  }

  doValidation() {
    let { t } = this.props;
    let err = [];
    let {
      amount,
      maxRefund
    } = this.state;

    if(amount <= 0){
      err.push("Please enter the amount you would like to refund.");
    }
    if(amount > maxRefund){
      err.push("You cannot refund more than was charged.");
    }

    this.setState({ validationErr : err})
    return err.length === 0;
  }

  getRefundReasons(){
    let { t } = this.props;
    return [
      {
        val : this.REFUND_REASONS.NOT_SPECIFIED,
        display : t('Not Specified')
      },
      {
        val : this.REFUND_REASONS.DUPLICATE,
        display : t('Duplicate Transaction')
      },
      {
        val : this.REFUND_REASONS.FRAUDULENT,
        display : t('Fraudulent')
      },
      {
        val : this.REFUND_REASONS.REQUESTED_BY_CUSTOMER,
        display : t('Requested by Customer')
      }
    ]
  }

  render() {
    let { t } = this.props;
    let {amount, reason, validationErr} = this.state;
    let {
      invoice
    } = this.props.modalProps;

    return (
      <div className="modal-content">
        <div className="modal-header draggable-header">
          <h5 className="modal-title">{t("Refund Payment")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Amount")}</label>
                <CurrencyInput className="form-control"
                               currencyConfig={{
                                 locale: this.props.i18n.language,
                                 currencyCode: invoice.currency,
                                 minimumFractionDigits: invoice.currency === c.INVOICE_CURRENCY_CODES.JPY ? 0 : undefined
                               }}
                               onChange={(evt) => this.setState({amount : evt.target.value})}
                               value={amount} />
              </div>

              <div className="form-group">
                <label>{t('Reason')}</label>
                <select className="form-control"
                        value={reason}
                        onChange={(evt) => this.setState({reason : evt.target.value})}>
                  {this.getRefundReasons().map((option) => {
                    return <option key={option.val}
                                   value={option.val}>
                      {option.display}
                    </option>
                  })}
                </select>
              </div>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} />}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.submit.bind(this)}>{t("Refund")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

RefundInvoiceDialog.propTypes = {
  onRef : PropTypes.func,
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RefundInvoiceDialog)));
