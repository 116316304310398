import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import utilityActions from "../actions/utility-actions";

class WindowWatcher extends Component {

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions(){
    if(this.props.setAppWindowDimensions) {
      this.props.setAppWindowDimensions(document.body.getBoundingClientRect());
    }
  }

  render(){
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppWindowDimensions:(dimensions) => dispatch(utilityActions.setAppWindowDimensions(dimensions)),
  };
};

WindowWatcher.propTypes = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WindowWatcher));
