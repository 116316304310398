import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import log from "../../../util/log";
import _ from 'lodash';
import c from "../../../util/const";
import classNames from 'classnames';
import colors from "../../../util/colors";
import utils from "../../../util/util";
import PdfSigningOverlay from "./PdfSigningOverlay";
import SignatureRequest from "../../../models/SignatureRequest";
import {fontConstants} from "../../../util/font-constants";
import {withVFTranslation} from "../../../util/withVFTranslation";
import browser from "../../../util/browser";
import TextInputMeasurer from "../elements/TextInputMeasurer";

class PdfSignatureRequestSelectInput extends Component {
  
  PICKER_SPACE = browser.isSafari() ? 46 : 36;
  
  constructor(props){
    super(props)
    
    this.inputRef = React.createRef();
    
    this.state = {
      measuredText : '',
      inputFontSize : props.initialFontSize,
  
      textMeasurerRef : null
    }
  }
  
  componentDidMount(){
    if(this.props.onRef) {
      this.props.onRef(this)
    }
    this.pickTextAndMeasure();
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.selectedValue && prevProps.selectedValue !== this.props.selectedValue){
      this.pickTextAndMeasure();
    }
    else if(prevProps.width !== this.props.width){
      this.pickTextAndMeasure();
    }
    else if(prevProps.scale !== this.props.scale){
      this.pickTextAndMeasure();
    }
  }
  
  getDisplayText(){
    let foundOpt = _.find(this.getSignatureRequestOptions(), (opt) => opt.val === this.props.selectedValue)
    if(foundOpt){
      return foundOpt.display;
    }
    return "";
  }
  
  pickMeasureText(){
    let textToMeasure = this.getDisplayText();
    if(textToMeasure.length === 0){
      //then try placeholder text
      if(this.props.placeholderText && this.props.placeholderText.length > 0){
        textToMeasure = this.props.placeholderText;
      }
    }
    return textToMeasure;
  }
  
  pickTextAndMeasure(){
    utils.waitForCondition(() => {
        return !!(_.get(this.state, 'textMeasurerRef'));
      })
      .then(() => {
        let textToMeasure = this.pickMeasureText();
        this.setState({ measuredText : textToMeasure })
        let measurer = _.get(this.state, 'textMeasurerRef');
        measurer.startMeasure(textToMeasure, this.props.initialFontSize, this.props.width)
          .then((measuredFontSize) => {
            log.warn('done measuring select input', measuredFontSize);
            this.setState({inputFontSize : measuredFontSize});
          })
          .catch((err) => {
            log.warn('error during measuring', err);
          })
      })
  }
  
  getSignatureRequestOptions(){
    let { t } = this.props;
    
    return [
      { display : t('Signature'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE, cls : fontConstants.LA_BELLE_AURORE.class},
      { display : t('Initials'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS, cls : fontConstants.HELVETICA.class},
      
      //Important that this corresponds to what is returned from SignatureRequest.getDateMaskForSignatureType()
      { display : t('Date MM/DD/YYYY'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_1, cls : fontConstants.COURIER.class},
      { display : t('Date DD/MM/YYYY'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_2, cls : fontConstants.COURIER.class},
      { display : t('Date YYYY-MM-DD'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE, cls : fontConstants.COURIER.class},
      { display : t('Date YYYY-MM-DD HH:MM:SS UTC'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE_3, cls : fontConstants.COURIER.class},
      
      { display : t('Custom'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER, cls : fontConstants.TIMES_NEW_ROMAN.class},
    ]
  }
  
  onInputChange(evt){
    let { onInputChange } = this.props;
    
    if(onInputChange && evt.target) {
      onInputChange(evt.target.value);
    }
  }
  
  getInputRef(){
    return this.inputRef;
  }
  
  render(){
    let {
      inputClassName,
      width,
      height,
      lineHeight,
      inputDisabled,
      selectedValue,
      inputPaddingStyle,
      signaturePrimaryColor
    } = this.props;
    
    let {
      inputFontSize,
    } = this.state;
  
    let baseColor = signaturePrimaryColor || colors.SIGNING_REQUEST;
    let bgColor = utils.transparentizeHex(baseColor, c.pdf.SIGNATURE_REQUEST_BG_OPACITY);
    
    return (
      <>
        <TextInputMeasurer onRef={(ref) => this.setState({textMeasurerRef: ref})}
                           measureBufferSpace={this.PICKER_SPACE}
                           scaleRelativeToOverlay={this.props.scaleRelativeToOverlay} />
        <select className={inputClassName}
                style={{
                  border: `1px solid ${baseColor}`,
                  backgroundColor: bgColor,
                  color: `${baseColor}`,
                  padding: inputPaddingStyle,
                  width: `${width}px`,
                  height: `${height}px`,
                  fontSize: `${inputFontSize}px`,
                  lineHeight: `${lineHeight}px`,
                  zIndex : 10
                }}
                value={selectedValue}
                disabled={inputDisabled}
                ref={this.inputRef}
                onChange={this.onInputChange.bind(this)}>
          {this.getSignatureRequestOptions().map((option) => {
            return (
              <option className={option.cls}
                      key={option.val}
                      value={option.val}>
                {option.display}
              </option>
            )
          })}
        </select>
      </>
    )
  }
}

PdfSignatureRequestSelectInput.propTypes = {
  selectedValue : PropTypes.string.isRequired,
  onInputChange : PropTypes.func.isRequired,
  
  signaturePrimaryColor : PropTypes.string,
  inputPaddingStyle : PropTypes.string.isRequired,
  initialFontSize : PropTypes.number.isRequired,
  scaleRelativeToOverlay : PropTypes.func.isRequired,
  inputClassName : PropTypes.string.isRequired,
  inputDisabled : PropTypes.bool,
  width : PropTypes.number.isRequired,
  height : PropTypes.number.isRequired,
  lineHeight : PropTypes.number.isRequired,
  scale : PropTypes.number.isRequired
}

export default withVFTranslation()(PdfSignatureRequestSelectInput);
