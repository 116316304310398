import React, {Component} from 'react';
import PropTypes from "prop-types";
import utils from "../../../util/util";

class PasswordStrengthMeter extends Component {

  static STRONG_PASS_SCORE = 80
  static MEDIUM_PASS_SCORE = 50

  constructor(props) {
    super(props);

    this.state = {
      passwordScore : 0
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.password !== this.props.password){
      this.setState({passwordScore : utils.scorePassword(this.props.password)})
    }
  }

  getProgressbarClassForScore(score){
    if(score >= PasswordStrengthMeter.STRONG_PASS_SCORE){
      return "bg-success";
    }
    else if(score >= PasswordStrengthMeter.MEDIUM_PASS_SCORE){
      return "bg-warning";
    }
    else{
      return "bg-danger";
    }
  }

  static renderPasswordStrengthText(t, password){
    if(!password || password.length === 0){
      return null;
    }

    let score = utils.scorePassword(password);
    let textClass = "text-danger";
    let text = t("Weak");
    if(score >= PasswordStrengthMeter.STRONG_PASS_SCORE){
      textClass = "text-success";
      text = t("Strong");
    }
    else if(score >= PasswordStrengthMeter.MEDIUM_PASS_SCORE){
      textClass = "text-warning";
      text = t("Medium");
    }

    return (
      <div className={"d-inline-block " + textClass}>{text}</div>
    )
  }

  render() {
    let { passwordScore } = this.state;

    return (
      <div>
        <div className="progress">
          <div className={`progress-bar ${this.getProgressbarClassForScore(passwordScore)}`}
               role="progressbar"
               style={{width : `${passwordScore}%`}}
               aria-valuenow={`${passwordScore}`}
               aria-valuemin="0"
               aria-valuemax="100"></div>
        </div>
      </div>
    )
  }
}

PasswordStrengthMeter.propTypes = {
  password : PropTypes.string
}

export default PasswordStrengthMeter;