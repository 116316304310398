import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import querystring from 'query-string';

import config from '../util/config';

import _ from 'lodash';

import c from '../util/const';
import log from '../util/log';
import vfLocalStorage from '../util/local-storage';

import api from '../util/api';
import sapi from '../util/sapi';
import authHelper from '../helpers/auth-helper';

import appActions from '../actions/app-actions';
import authActions from '../actions/auth-actions';

import routing from '../routing';
import ModalRoot from './modals/ModalRoot';
import Loading from './partials/util/Loading';
import redirectHelper from "../util/redirect-helper";
import UploadManager from "../containers/UploadManager";

import browser from '../util/browser';
import vfSessionStorage from "../util/session-storage";
import modalActions from "../actions/modal-actions";
import DownloadManager from "../containers/DownloadManager";
import {fontsNeedingPreload} from "../util/font-constants";
import utilityActions from "../actions/utility-actions";
import cookieHelper from "../helpers/cookie-helper";
import {withVFTranslation} from "../util/withVFTranslation";
import electronUtil from "../util/electron-util";
import WindowWatcher from "../containers/WindowWatcher";
import NotificationUpdater from "../containers/NotificationUpdater";
import {getErrorMessage} from "../util/errors";
import sentryHelper from "../helpers/sentry-helper";
import sharedActions from "../actions/shared-actions";
import threadActions from "../actions/thread-actions";
import moment from "moment";

class App extends Component {

  showingVersionPrompt = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mountAuthenticationRoutesOnly: false,
      showingSessionWarning : false
    };
  }

  componentDidMount() {
    this.initEndpoint();
    this.startup();

    sapi.registerApiHandlers(
      this.onInvalidSessionCaught.bind(this),
      this.onConnectionError.bind(this)
    )

    log.log('Current Browser', JSON.stringify(browser.getBrowser()));

    if(browser.isIE11()){
      document.body.classList.add('browser-is-ie11');
    }

    document.body.classList.add(`browser-${browser.getBrowser().name}`);

    window.addEventListener("focus", this.onBrowserTabFocus.bind(this))
    window.addEventListener("blur", this.onBrowserTabBlur.bind(this))

    this.showVersionDialogIfNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onBrowserTabFocus.bind(this))
    window.removeEventListener("blur", this.onBrowserTabBlur.bind(this))
  }

  onBrowserTabFocus(){
    log.log('browser tab active')
    this.props.setBrowserTabActive(true);

    this.reloadPageOnTabFocusIfNeeded();

    this.showVersionDialogIfNeeded();

    this.updateCurrentThreadIfNeeded();
  }

  updateCurrentThreadIfNeeded(){
    let {
      activeDM,
      activeThread
    } = this.props;

    if(activeDM || activeThread){
      this.props.threadAction.refreshActiveThreadMessages();
    }
  }

  performMerchantFlagStatusCheck(merchantPendingFlag){
    let wasStatusFlagTrue = false;
    if(_.isBoolean(merchantPendingFlag) && merchantPendingFlag === true){
      wasStatusFlagTrue = true;
    }
    if(wasStatusFlagTrue){
      this.props.updateMerchantInfo()
        .then((res) => {
          let pending_flag = _.get(res, 'pending_flag');
          if(pending_flag !== merchantPendingFlag){
            window.location.reload();
          }
        })
        .catch((err) => {
          log.warn('error while doing merchant info check', err);
        })
    }
  }

  reloadPageOnTabFocusIfNeeded(){
    let lsToken = vfLocalStorage.get(c.localstorage.token);
    let { token } = this.props;

    //We save your authenticated token in the auth reducer.
    //When you re-focus your browser tab, we need to check if
    //your token has changed.  Either you logged out in another tab,
    //or you switched users or something.  This forces a reload
    //to get the most recent state.
    if(token && lsToken !== token){
      window.location.reload();
    }
    else{
      let {
        merchantPendingFlag
      } = this.props;
      this.performMerchantFlagStatusCheck(merchantPendingFlag);
    }
  }

  onBrowserTabBlur(){
    log.log('browser tab blur');
    this.props.setBrowserTabActive(false);

    let {
      merchantInfo
    } = this.props;
    let pending_flag = _.get(merchantInfo, 'pending_flag');
    this.props.setMerchantPendingFlag(pending_flag);
  }

  showWebUpdateDialogIfNeeded(versionRes){
    return new Promise((resolve, reject) => {
      let { t, qs } = this.props;
      let foundVersion = _.find(versionRes.data, (v) => v.platform === 'web');
      if(foundVersion){
        if(foundVersion.version_id > config.versions.web){
          this.showingVersionPrompt = true;
          let comment = _.get(foundVersion, 'comment');
          let msg = t("There is a new version of this page available.  Please reload to update.");
          if(comment && comment.length > 0){
            msg = comment;
          }
          this.props.showConfirm(t('New Version Available'), msg, (res) => {
            if(res){
              resolve(true);
              if(qs && !_.isEmpty(qs)){

                //this is meant to bust the cache in case setting window.location.href with the existing qs isn't good enough.
                //window.location.reload() does clear the cache as best as I can verify, but it's not totally
                //clear that setting window.location.href is the same.
                qs.t = moment().valueOf();

                let stringified = querystring.stringify(qs);
                let baseUrl = window.location.href.split('login')[0];
                window.location.href = `${baseUrl}login?${stringified}`;
              }
              else{
                window.location.reload();
              }
            }
            else{
              resolve(false);
            }
            this.showingVersionPrompt = false;
          })
        }
        else{
          resolve(false);
        }
      }
      else{
        resolve(false);
      }
    })
  }

  showElectronUpdateIfNeeded(versionRes){
    return new Promise((resolve, reject) => {
      if(electronUtil.isRunningWithinElectron()){
        log.log('checking electron force', versionRes);
        let foundVersion = _.find(versionRes.data, (v) => v.platform === 'electron');
        if(foundVersion){
          log.log('electron - doing setcheckversion', foundVersion);
          electronUtil.setCheckVersion(foundVersion.version_id)
            .then((res) => {
              log.log('electron - setcheckversion', res);
              if(res){
                this.showingVersionPrompt = true;
                let comment = _.get(foundVersion, 'comment');
                this.props.showElectronForceVersionDialog(comment, (res) => {
                  if(res){
                    resolve(true);
                    window.location.reload();
                  }
                  else{
                    resolve(false);
                  }
                  this.showingVersionPrompt = false;
                })
              }
              else{
                resolve(false);
              }
            })
            .catch((err) => {
              log.log('electron - error during setcheckversion', err);
              sentryHelper.captureMessage('version-force-error-electron', err);
              resolve(false);
            })
        }
        else{
          resolve(false);
        }
      }
      else{
        resolve(false);
      }
    })
  }

  showVersionDialogIfNeeded(){

    if(this.showingVersionPrompt){
      log.log('already showing prompt.  skipping');
      return;
    }

    let versionRes = null;
    api.Version.list()
      .then((res) => {
        versionRes = res;
        log.log('got version res', res);
        return this.showWebUpdateDialogIfNeeded(versionRes)
      })
      .then((res) => {
        log.log('version update web result', res);
        if(!res){
          return this.showElectronUpdateIfNeeded(versionRes);
        }
      })
      .catch((err) => {
        log.log('error fetching version', err);
        sentryHelper.captureMessage('version-force-error', err);
      })
  }

  initEndpoint(){
    if(config.debug){
      let savedEndpoint = vfLocalStorage.get(c.localstorage.endpoint);
      api.setEndpoint(savedEndpoint || config.prodEndpoint);
      sapi.setEndpoint(savedEndpoint || config.prodEndpoint);
    }
    else{
      api.setEndpoint(config.prodEndpoint);
      sapi.setEndpoint(config.prodEndpoint);
    }
  }

  onConnectionError(err){

    if(!sapi.isConnectionError(err)){
      console.warn('non-connection error in the wrong error handler', err);
    }

    this.props.setApplicationError(err);
  }

  onInvalidSessionCaught(err) {
    log.error('onInvalid Session caught', err);

    if(this.state.showingSessionWarning){
      return;
    }
    this.setState({showingSessionWarning: true});

    //tricky part of code!  This state flag tells the app to unmount all of the authenticated routes in the app
    //I'm hoping that this better deals with dangling api calls/render operations that may have been happening
    //At the time the invalid session came in.
    //So the idea here, is unmount the app, resetAuthenticatedAppState (which kills the whole app model)
    //Then redirect them to base of the app, and reload authenticated routing.
    this.setState({mountAuthenticationRoutesOnly: true},
      () => {
        this.props.resetAuthenticatedAppState();
        this.props.cleanAuthentication();
        this.props.history.replace("/");
        setTimeout(() => {
          this.props.cleanupModals();
          let { t } = this.props;

          let msg = '';
          let errName = _.get(err, 'name');
          if(errName === 'APP_SESS_INVALID'){
            msg = t('We need to start a new secure session for you.  Please log in to continue.');
          }
          else{
            msg = getErrorMessage(errName, t);
          }
          this.props.showAlert(
            t('Session Invalid'),
            msg,
            () => {
              setTimeout(() => {
                this.setState({showingSessionWarning: false})
              })
            })
          this.setState({mountAuthenticationRoutesOnly: false})
        })
      })

  }

  performEnvironmentalChecks(){

    if(!vfLocalStorage.testStorage()){
      //report localstorage problem
      this.props.setApplicationError({
        isEnvironmentalError : true,
        isLocalStorageProblem : true
      })
      return false;
    }

    if(!vfSessionStorage.testStorage()){
      //report session storage problem
      this.props.setApplicationError({
        isEnvironmentalError : true,
        isSessionStorageProblem : true
      })
      return false;
    }

    return true;
  }

  normalizeQS(qs){
    //The backend can call this both thread_id or chat_id depending on circumstance.
    //This just makes sure that chat_id is set regardless

    let thread_id = _.get(qs, 'thread_id');
    if(thread_id){
      qs.chat_id = thread_id;
    }
  }

  startup() {
    if (!this.performEnvironmentalChecks()) {
      return;
    }

    let {location, history} = this.props;
    let pathname = _.get(location, 'pathname', '');
    if(pathname.startsWith('/@')){
      //Then you're on a handle.  Nothing else to do.  Just keep them on the page and toggle the loading flag.
      this.setState({loading: false})
      return;
    }

    let parsed = querystring.parse(location.search);
    log.log('parsed querystring', parsed, location);

    this.normalizeQS(parsed);
    log.log('normalized querystring', parsed);

    //This is a little tricky, but the kinds of deeplinking needed around authentication and such
    //gets parsed and handled from the qs directly.  If there is querystring data stored in sessionstorage
    //we just save it as the querystring in app, but log in like normal.
    //This is part of handling querystrings for users that are not authenticated yet.
    if (parsed && !_.isEmpty(parsed) && this.validateQs(parsed)) {
      this.initializeQs(parsed);
    }
    else {
      log.log('no querystring handling');
      let qs = null;
      let sessQs = vfSessionStorage.get(c.sessionstorage.qs);
      if (sessQs) {
        qs = JSON.parse(sessQs);
      }
      if (qs) {
        log.log('setting qs from session storage', qs);
        this.props.setQueryString(qs);
        this.props.setQsActionNeeded(true);
      }
    }

    this.props.refreshServerConfig();

    if (_.isEmpty(parsed)) {
      this.loginFromSavedAuth()
    }
    else {
      this.processQueryString(parsed);
    }
  }

  validateQs(qs){
    if(qs.forum_id && qs.chat_id){
      return true;
    }
    else if(qs.forum_id && qs.doc_id){
      return true;
    }
    else if(qs[c.querystring.goto_reset]){
      return true;
    }
    else if(qs[c.querystring.goto_confirm]){
      return true;
    }
    else if(qs[c.querystring.goto_dnd]){
      return true;
    }
    else if(qs[c.querystring.goto_block_user] && qs.guest_uid){
      return true;
    }
    else if(qs[c.querystring.sign_request_id]){
      return true;
    }
    else if(qs[c.querystring.guest_uid]){
      return true;
    }
    else if(_.has(qs, c.querystring.stripe_refresh)){
      return true;
    }
    else if(_.has(qs, c.querystring.stripe_return)){
      return true;
    }
    else if(_.has(qs, c.querystring.goto_publisher_subscribe)){
      return true;
    }
    else if(_.has(qs, c.querystring.goto_publisher_settings)){
      return true;
    }
    else{
      return false;
    }
  }

  initializeQs(qs){
    //This is a weird special case.  If there's an email in the query string, we want to save it, but not
    //store it in the session.
    //It's possible there is an authenticated user, but not the one from the query string.
    //If that happens, we'll write the notification query string args to session storage and log them out.
    //Then when they log back in, we check local storage for the args, and navigate them there.
    //Possibly important to note that if the current user logged in as someone else, who happen to also
    //have a reference to the forum and chat/doc id in the query string, we'll navigate there, even if it's not the intended recipient after this first redirect.
    let qsClone = _.extend({}, qs);
    let savedEmail = null;
    if(qsClone.email){
      savedEmail = qsClone.email;
      delete qsClone.email;
    }

    //Save to session storage.  If the user gets bounced back out to login, we want to
    //save this so we can navigate them when they come back in.
    vfSessionStorage.set(c.sessionstorage.qs, JSON.stringify(qsClone));
    this.props.setQueryString(qsClone);
    this.props.setQsActionNeeded(true);
    this.props.setQsEmail(savedEmail)

    //If there's a user in the query string, clear localStorage and set the next login email to the one in teh qs.
    //So when they get bounced out, they correct email address will be set.  bug 934

    let isConfirm = qs[c.querystring.goto_confirm] && (qs[c.querystring.goto_confirm] + "").toUpperCase() === "YES";

    if(!isConfirm) {
      let lsEmail = vfLocalStorage.get(c.localstorage.email);
      if(lsEmail){
        lsEmail = lsEmail.toLowerCase();
      }
      if (savedEmail && savedEmail.toLowerCase() !== lsEmail) {
        //If we're handling a querystring for a different email,
        //We want to save the emailfrom the querystring to localstorage so
        //so that when they get bounced back to auth the right email is populated.
        //Important to note that this redirection happens in Home.js where we have all the
        //proper context.
        vfLocalStorage.set(c.localstorage.email, savedEmail);
      }
    }
  }

  updateInstitution(inst_id){
    let { setInstitution } = this.props;

    if(inst_id){
      return api.Institution.post(inst_id)
        .then((res) => {
          setInstitution(res.data);
        })
        .catch((err) => {
          //nothing to do.  The user can edit this in the querystring, so don't worry about failures.
          log.log(`no institution found for ${inst_id}`, err);
        })
    }
    else{
      return Promise.resolve(true);
    }
  }

  handleLogoutQs(qs){
    this.props.logout()
      .finally(() => {

        //Looks a little weird, but we want to respect this qs param if it's there, and we have to do it
        //after logout() because it clears the authentication store.
        let instPromise = new Promise((resolve, reject) => {
          if(qs[c.querystring.inst_id]){
            this.updateInstitution(qs[c.querystring.inst_id])
              .finally(() => {
                resolve(true);
              })
          }
          else{
            resolve(true);
          }
        })

        instPromise
          .then(() => {
            this.props.history.replace("/");
            setTimeout(() => {
              this.setState({ loading: false })
            })
          })
      })
  }

  loadNeededQSData(qs){
    return Promise.all([
      this.loadInstData(qs),
      this.loadUpgradeData(qs)
    ])
  }

  loadInstData(qs){
    return new Promise((resolve, reject) => {
      if(qs[c.querystring.inst_id]){
        this.updateInstitution(qs[c.querystring.inst_id])
          .finally(() => {
            resolve(true);
          })
      }
      else{
        resolve(true);
      }
    })
  }

  loadUpgradeData(qs){
    return new Promise((resolve, reject) => {
      if(qs[c.querystring.goto_upgrade] && +qs[c.querystring.goto_upgrade] >= 0){
        api.ClassInfo.get(+qs[c.querystring.goto_upgrade])
          .then((res) => {
            let upgradePlan = _.get(res, 'data.class_info');
            this.props.setUpgradePlan(upgradePlan);
            resolve(upgradePlan);
          })
          .catch((err) => {
            log.log('unable to load upgrade plan', err);
            reject(err);
          })
      }
      else{
        resolve(true);
      }
    })
  }

  processQueryString(qs) {
    let { setEmail, setNextStep, setConfirmationCode, setPreventEmailDisabled, setLoginWithPayment, history } = this.props;

    if(qs[c.querystring.logout] && (qs[c.querystring.logout] + "").toUpperCase() === "YES"){
      this.handleLogoutQs(qs);
      return;
    }

    let qsEmail = qs[c.querystring.email];
    let lsEmail = vfLocalStorage.get(c.localstorage.email);

    this.loadNeededQSData(qs)
      .then(() => {

        log.log('*** app.js processQueryString ***', qs);
        let loginLikeNormal = false;
        if (qs[c.querystring.goto_confirm] && (qs[c.querystring.goto_confirm] + "").toUpperCase() === "YES") {
          //If they clicked a confirm link, but their email is already in ls, it means they've been here before.
          //do normal login
          if(qsEmail && lsEmail && qsEmail === lsEmail){
            loginLikeNormal = true;
          }
          else{
            setEmail(qsEmail);
            setConfirmationCode(qs[c.querystring.confirmation_code]);
            this.setState({loading: false},
              () => {
                history.replace("/confirm");
              })
          }
        }
        else if (qs[c.querystring.goto_reset] && (qs[c.querystring.goto_reset] + "").toUpperCase() === "YES") {
          setEmail(qsEmail);
          setConfirmationCode(qs[c.querystring.confirmation_code])
          this.setState({loading: false},
            () => {
              history.replace("/confirm");
            })
        }
        else if (qs[c.querystring.goto_publisher_subscribe] && (qs[c.querystring.goto_publisher_subscribe] + "").toUpperCase() === "YES") {
          this.setState({loading: false},
            () => {
              history.replace("/publisher_subscribe");
            })
        }
        else if (qs[c.querystring.goto_reg] && (qs[c.querystring.goto_reg] + "").toUpperCase() === "YES") {
          if(qsEmail) {
            setEmail(qsEmail);
          }
          //special handling here, since we disable the email input once you've started the signup process.
          //Since users can come into boarding directly from this deeplink, we have to allow them to edit the email address
          //if it's not already input.
          this.props.setAllowEmailEditFromBoarding(true);
          setNextStep(c.authSteps.signup);
          setPreventEmailDisabled(true);
          this.setState({ loading: false })
        }
        else if (qs[c.querystring.goto_proreg] && (qs[c.querystring.goto_proreg] + "").toUpperCase() === "YES") {
          if(qsEmail) {
            setEmail(qsEmail);
          }
          setLoginWithPayment(true);
          setNextStep(c.authSteps.email);
          this.setState({ loading: false })
        }
        else if (qs[c.querystring.goto_upgrade] && +qs[c.querystring.goto_upgrade] >= 0) {
          if(qsEmail) {
            setEmail(qsEmail);
          }
          setLoginWithPayment(true, +qs[c.querystring.goto_upgrade]);
          setNextStep(c.authSteps.email);
          this.setState({ loading: false })
        }
        else{
          loginLikeNormal = true;
        }

        if(loginLikeNormal){
          if(qsEmail){
            setEmail(qsEmail);
          }
          else if(lsEmail){
            setEmail(lsEmail);
          }
          this.loginFromSavedAuth();
        }
      })
      .catch((err) => {
        log.log('error loading needed data', err);
        this.loginFromSavedAuth();
      })
  }

  loginFromSavedAuth() {
    let { history, setAuthFromLocalStorage, qs } = this.props;

    if(authHelper.hasSavedAuthentication()){
      this.props.resetAuthenticatedAppState();
      this.setState({ loading: true })
      authHelper.testSavedAuthentication()
        .then((res) => {
          log.log('auth success', res);
          cookieHelper.clearTrackingCookies();
          setAuthFromLocalStorage();
          this.setState({ loading: false })
          redirectHelper.redirectToApp(history, qs, vfLocalStorage.get(c.localstorage.vip));
        })
        .catch((err) => {
          log.log('no auth', err);
          this.setState({ loading: false });
          history.replace("/");
        })
    }
    else{
      this.setState({ loading: false });
      history.replace("/");
    }
  }

  renderUtilities(){

    return (
      <>
        <canvas height={utilityActions.WATERMARK_CANVAS_HEIGHT}
                width={utilityActions.WATERMARK_CANVAS_WIDTH}
                className="d-none"
                ref={(ref) => this.props.setWatermarkCanvasRef(ref)}/>
      </>
    )
  }

  render() {
    let { loading, mountAuthenticationRoutesOnly } = this.state;

    //NOTE: we don't want to render anything in the app that needs
    //localization until this flag has been flipped.
    let langIsInitialized = _.get(this, 'props.i18n.isInitialized');
    if(!langIsInitialized){
      return null;
    }

    let content = null;
    if(!loading){
      content = routing.getRoutes(!mountAuthenticationRoutesOnly);
    }
    else{
      content = <div />
    }

    return (
      <div x-ms-format-detection="none">
        <WindowWatcher />
        <UploadManager/>
        <DownloadManager/>
        <NotificationUpdater />
        <ModalRoot onRef={(ref) => this.props.setRootRef(ref)}/>
        {this.renderUtilities()}
        {content}
        {_.map(fontsNeedingPreload, (font) => {
          return (
            <div key={font.name} className={`font-pre-load ${font.class}`}>.</div>
          )
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    vip : state.auth.vip,
    qsEmail : state.app.qsEmail,
    qsActionNeeded : state.app.qsActionNeeded,
    merchantPendingFlag : state.app.merchantPendingFlag,
    token : state.auth.token,
    merchantInfo : state.shared.merchantInfo,

    activeDM : state.thread.activeDM,
    activeThread : state.thread.activeThread
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanupModals: () => dispatch(modalActions.cleanup()),
    logout: () => dispatch(appActions.logout()),
    refreshServerConfig: () => dispatch(appActions.refreshServerConfig()),
    setBrowserTabActive: (isActive) => dispatch(appActions.setBrowserTabActive(isActive)),
    setMerchantPendingFlag: (pending_flag) => dispatch(appActions.setMerchantPendingFlag(pending_flag)),
    setQueryString: (qs) => dispatch(appActions.setQueryString(qs)),
    setQsEmail: (email) => dispatch(appActions.setQsEmail(email)),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    cleanAuthentication: () => dispatch(authActions.cleanAuthentication()),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled)),
    setConfirmationCode: code => dispatch(authActions.setConfirmationCode(code)),
    setNextStep: step => dispatch(authActions.setNextStep(step)),
    setAuthFromLocalStorage: () => dispatch(authActions.setAuthFromLocalStorage()),
    setInstitution: (institution) => dispatch(authActions.setInstitution(institution)),
    setUpgradePlan: (upgradeClassInfo) => dispatch(authActions.setUpgradePlan(upgradeClassInfo)),
    setWatermarkCanvasRef: (ref) => dispatch(utilityActions.setWatermarkCanvasRef(ref)),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    resetAuthenticatedAppState : () => dispatch(appActions.resetAuthenticatedAppState()),
    setLoginWithPayment : (setLoginWithPayment) => dispatch(authActions.setLoginWithPayment(setLoginWithPayment)),
    updateMerchantInfo : () => dispatch(sharedActions.updateMerchantInfo()),
    setAllowEmailEditFromBoarding : (allowEmailEditFromBoarding) => dispatch(authActions.setAllowEmailEditFromBoarding(allowEmailEditFromBoarding)),
    threadAction : {...threadActions.mapToDispatch(dispatch)},
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
