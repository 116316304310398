import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import uploadActions from "../../../actions/upload-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import log from "../../../util/log";
import ProgressBar from "../elements/ProgressBar";
import colors from "../../../util/colors";
import Button from "../elements/Button";
import SignatureRequest from "../../../models/SignatureRequest";
import _ from "lodash";
import UserBadge from "../badges/UserBadge";
import DocSigningStatus from "./DocSigningStatus";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../../util/withVFTranslation";
import TruncateMarkup from "react-truncate-markup";
import VFMiddleTruncate from "../util/VFMiddleTruncate";
import utils from "../../../util/util";
import c from "../../../util/const";
import sapi from "../../../util/sapi";
import downloadActions from "../../../actions/download-actions";
import threadActions from "../../../actions/thread-actions";
import {getMessageForError} from "../../../util/errors";
import workspaceActions from "../../../actions/workspace-actions";
import msgHelper from "../../../helpers/msg-helper";
import Promise from "bluebird";
import Image from "../elements/Image";
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import popupHelper from "../../../helpers/popup-helper";
import VFPopover from "../components/VFPopover";
import popoverActions from "../../../actions/popover-actions";
import memoizeOne from "memoize-one";

class ThreadDoc extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      isPreviewable : false,

      thumbnail : null,
      thumbnailDocId : null,
      loadingThumbnailDocId : null
    }
  }

  componentDidMount() {
    this.updatePreviewable();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(_.get(prevProps, 'doc.label') !== _.get(this.props, 'doc.label')){
      this.updatePreviewable();
    }
  }

  updatePreviewable(){
    this.setState({
      isPreviewable: utils.isPreviewable(_.get(this.props, 'doc.label')),
    })
  }

  showMenu(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    let {
      doc
    } = this.props;
    this.props.popoverAction.showPopover(c.popovers.MSG_DOC_GEAR_MENU, doc.doc_id);
  }

  hidePopover(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
  }

  showThumbnailMenuClick(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    let {
      doc,
      docInfo
    } = this.props;
    let didOpen = this.props.popoverAction.showPopover(c.popovers.MSG_DOC_THUMBNAIL_PREVIEW, doc.doc_id);
    if(didOpen){
      this.loadThumbnailIfNeeded();
    }
    else{
      this.hideThumbnailMenu(evt);
    }

    let notifyFlag = _.get(docInfo, 'notify_flag', false)
    if(notifyFlag) {
      this.setNotifyFlag(false);
    }
  }

  setNotifyFlag(notify_flag){
    let {
      doc,
      workspace,
      dm,
      t
    } = this.props;

    if(dm){
      sapi.Docs.mark(dm.forum_id, dm.host_uid, doc.doc_id, notify_flag)
        .then((res) => {
          this.props.threadAction.refreshActiveThreadMessages();
          this.props.threadAction.refreshDocsDM();
        })
        .catch((err) => {
          this.props.modalAction.showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
    else if(workspace){
      sapi.Docs.mark(workspace.forum_id, workspace.host_uid, doc.doc_id, notify_flag)
        .then((res) => {
          this.props.workspaceAction.refreshDocs(workspace.forum_id, workspace.host_uid)
            .then(() => {
              this.props.threadAction.refreshActiveThreadMessages();
            })
        })
        .catch((err) => {
          this.props.modalAction.showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
  }

  hideThumbnailMenu(evt){
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.props.popoverAction.hidePopover();
    setTimeout(() => {
      this.setState({
        thumbnail : null,
        thumbnailDocId : null,
        loadingThumbnailDocId : null
      })
    }, VFPopover.ANIMATION_TIME_MS)
  }

  onDocClick(evt){
    //This has to be here, or you get errant "onOutsideClick" events due to the listener being on two elements.
    if(evt){
      evt.preventDefault();
      evt.stopPropagation();
    }

    let { doc } = this.props;
    if(doc.isPending){
      return;
    }

    this.showThumbnailMenuClick();
  }

  getDownloadHeaders() {
    let {workspace, dm, doc} = this.props;

    let params = {
      doc_id : doc.doc_id,
    }

    if(dm){
      if (dm && dm.forum_id) {
        params.forum_id = dm.forum_id
      }
      if (dm.host_uid) {
        params.host_uid = dm.host_uid;
      }
    }
    else{
      if (workspace && workspace.forum_id) {
        params.forum_id = workspace.forum_id
      }
      if (workspace && workspace.host_uid) {
        params.host_uid = workspace.host_uid;
      }
    }

    params[c.api.X_TOKEN] = sapi.getToken();

    return params;
  }

  onDownloadClick(evt){
    log.log('onDownloadClick')
    this.hidePopover(evt);
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));

    let {docInfo} = this.props;
    let notifyFlag = _.get(docInfo, 'notify_flag', false)
    if(notifyFlag) {
      this.setNotifyFlag(false);
    }
  }

  onSigningMetadataClick(evt){
    log.log('onSigningMetadataClick');
    this.hidePopover(evt);

    let {
      workspace,
      dm,
      activeThread,
      doc,
      t
    } = this.props;

    let forum_id = null;
    let host_uid = null;
    if(activeThread){
      forum_id = workspace.forum_id;
      host_uid = workspace.host_uid;
    }
    else if(dm){
      forum_id = dm.forum_id;
      host_uid = dm.host_uid;
    }

    return sapi.Docs.metadata(forum_id, host_uid, doc.doc_id)
      .then((res) => {
        let val = _.get(res, 'data');
        val = msgHelper.formatMetadataForLegacyIfNeeded(val);
        this.props.modalAction.showSigningMetadataWindow(
          forum_id,
          host_uid,
          (dm ? dm.guest_uid : null),
          val,
          (res) => {
            log.log('metadata window closed', res)
          })
      })
      .catch((err) => {
        this.props.modalAction.showAlert(t('Error loading Signing info'), getMessageForError(err, t))
      })
  }

  onDeleteDocumentClick(evt){
    log.log('onDeleteDocumentClick');
    this.hidePopover(evt);

    let {
      workspace,
      dm,
      activeThread,
      msg,
      doc,
      t
    } = this.props;
    this.props.modalAction.showConfirm(
      t("Delete Document"),
      t("Are you sure you want to delete the Document, ") + doc.label + t("?"),
      (res) => {
        if(res){
          if(!dm){
            sapi.Threads.docDelete(workspace.forum_id, workspace.host_uid, activeThread.chat_id, doc.doc_id)
              .then((res) => {
                log.log('doc delete res', res);
                this.props.workspaceAction.refreshDocs(workspace.forum_id, workspace.host_uid)
                  .then(() => {
                    this.props.threadAction.refreshActiveThreadMessages();
                  })
              })
              .catch((err) => {
                log.error('error deleting document', err);
                this.props.modalAction.showAlert(t('Error Deleting Document'), getMessageForError(err, t))
              })
          }
          else{
            sapi.DM.docDelete(dm.guest_uid, doc.doc_id)
              .then((res) => {
                log.log('doc delete res', res);
                this.props.threadAction.refreshActiveThreadMessages();
                this.props.threadAction.refreshDocsDM();
              })
              .catch((err) => {
                log.error('error deleting document', err);
                this.props.modalAction.showAlert(t('Error Deleting Document'), getMessageForError(err, t))
              })
          }
        }
      }, t('Delete'))
  }

  onPreviewClick(evt){
    log.log('onPreviewClick')
    this.hidePopover(evt);

    let {
      workspace,
      doc,
      activeThread,
      dm
    } = this.props;

    if(activeThread){
      this.props.modalAction.showDocInfoThread(workspace.forum_id, workspace.host_uid, doc.doc_id, activeThread, (res) => {
        log.log('preview window closed', res);
        if(res){
          this.props.threadAction.refreshActiveThreadMessages();
          if(res.isPdfSubmitResult) {
            this.props.onPdfSubmit(res);
          }
        }
      })
    }
    else if(dm){
      let forum_id = dm.forum_id;
      let host_uid = dm.host_uid;

      this.props.modalAction.showDocInfoDM(forum_id, host_uid, doc.doc_id, dm, (res) => {
        log.log('preview window closed', res);
        if(res){

          this.props.threadAction.refreshActiveThreadMessages();
          this.props.threadAction.refreshDocsDM();
          if(res.isPdfSubmitResult) {
            this.props.onPdfSubmit(res);
          }
        }
      })
    }
    else{
      log.warn('unknown context for doc info');
    }

    let {docInfo} = this.props;
    let notifyFlag = _.get(docInfo, 'notify_flag', false)
    if(notifyFlag) {
      this.setNotifyFlag(false);
    }
  }

  showDocumentHistory(evt){
    log.log('showDocumentHistory')
    this.hidePopover(evt);

    let { workspace, dm, doc } = this.props;

    let params = {};
    if(dm){
      params.guest_uid = dm.guest_uid;
    }
    else{
      params.forum_id = workspace.forum_id;
      params.host_uid = workspace.host_uid;
    }
    params.doc_id = doc.doc_id;

    this.props.modalAction.showDocViewHistory(params, () => {
      log.log('history window closed');
    })
  }

  attachToThreadClick(evt){
    log.log('attachToThreadClick')
    this.hidePopover(evt);

    let {
      doc,
      workspace,
      dm,
      accountInfo
    } = this.props;

    //NOTE: yes, it's a little weird that we set src_host_uid if it doesn't exist on the workspace/dm.
    //The attach call requires it as of 4/23/20
    let src_forum_id = null;
    let src_host_uid = null;
    let initial_selection_forum_id = null;
    let initial_selection_guest_uid = null;
    if(dm){
      src_forum_id = dm.forum_id;
      src_host_uid = dm.host_uid || accountInfo.uid;
      initial_selection_guest_uid = src_forum_id;
    }
    else if(workspace){
      src_forum_id = workspace.forum_id;
      src_host_uid = workspace.host_uid || accountInfo.uid;
      initial_selection_forum_id = src_forum_id;
    }
    else{
      console.error('what context is this?');
      return;
    }

    this.props.modalAction.showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, (res) => {
      log.log('show attach doc window', res);
      if(res){
        this.props.onAttachToThread(res);
      }
    })
  }

  showDownloadMessageDocuments(evt){
    log.log('downloadMessageDocuments');

    let {
      msg
    } = this.props;
    let docIds = _.map(msg.docs, (doc) => doc.doc_id);
    this.showDownloadMultiple(docIds, evt);
  }

  showDownloadMultiple(docIds, evt){
    log.log('showDownloadMultiple', evt, docIds);
    this.hidePopover(evt);

    let {
      doc,
      workspace,
      dm,
      thread
    } = this.props;


    let initial_selection_forum_id = null;
    let initial_selection_guest_uid = null;
    let initial_selection_chat_id = null;
    if(dm){
      initial_selection_guest_uid = dm.guest_uid;
    }
    else if(workspace){
      initial_selection_forum_id = workspace.forum_id;
    }
    else{
      console.error('what context is this?');
      return;
    }

    if(thread){
      initial_selection_chat_id = thread.chat_id;
    }

    this.props.modalAction.showDownloadMultiple(initial_selection_forum_id, initial_selection_guest_uid, initial_selection_chat_id , docIds, () => {
      log.log('show download multiple closed')
    })
  }

  setNotifyFlagMenu(notify_flag, evt){
    log.log('setNotifyFlagMenu', notify_flag, evt)
    this.hidePopover(evt);

    this.setNotifyFlag(notify_flag)
  }

  showRename(evt){
    log.log('showRename')
    this.hidePopover(evt);

    let {
      workspace,
      doc,
      t
    } = this.props;

    let doRename = (label) => {
      return sapi.Threads.docRename(workspace.forum_id, doc.doc_id, label);
    }

    this.props.modalAction.showRename(t('Rename Document'), doc.label, doRename, (res) => {
      if(res){

        this.props.workspaceAction.refreshDocs(workspace.forum_id, workspace.host_uid)
          .then(() => {
            this.props.threadAction.refreshActiveThreadMessages();
            this.props.threadAction.refreshDocsDM();
          })
      }
    })
  }

  getDocMenuPopoverContent(){
    let { doc, docInfo, msg, workspace, dm, accountInfoGuest, activeThreadMesgEditFlag, t } = this.props;
    let { isPreviewable } = this.state;

    let showMetadataButton = _.get(doc, 'signer_info', []).length > 0;
    let canDeleteDocument = false;
    if(dm){
      canDeleteDocument = msg.guest_uid === accountInfoGuest.guest_uid;
    }
    else{
      canDeleteDocument = activeThreadMesgEditFlag && msg.guest_uid === accountInfoGuest.guest_uid;
    }

    if(dm){
      return (
        <div>
          <ul className="popover-content list-group">
            <a style={styles.menuHeader}
               className="list-group-item list-group-item-action">
              <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
              {doc.label}
            </a>

            <a onClick={this.onDownloadClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-android-download"/>
              {t("Download Document")}
            </a>
            {isPreviewable &&
              <a onClick={this.onPreviewClick.bind(this)}
                 style={styles.menuItem}
                 className="list-group-item list-group-item-action has-pointer">
                <i style={styles.menuIcons} className="icon ion-eye"/>
                {t("Preview Document")}
              </a>
            }
            {showMetadataButton &&
              <a onClick={this.onSigningMetadataClick.bind(this)}
                 style={styles.menuItem}
                 className="list-group-item list-group-item-action has-pointer">
                <i style={styles.menuIcons} className="icon ion-ios-bookmarks-outline"/>
                {t("View signing info")}
              </a>
            }

            <a onClick={this.showDocumentHistory.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
              {t("View Access History")}
            </a>

            <a onClick={this.attachToThreadClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-paperclip"/>
              {t("Send to...")}
            </a>

            <a onClick={this.showDownloadMessageDocuments.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
              {t("Download Message Documents")}
            </a>

            {docInfo && !docInfo.notify_flag &&
              <a onClick={this.setNotifyFlagMenu.bind(this, true)}
                 style={styles.menuItem}
                 className="list-group-item list-group-item-action has-pointer">
                <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
                {t("Mark as Unread")}
              </a>
            }
            {docInfo && docInfo.notify_flag &&
              <a onClick={this.setNotifyFlagMenu.bind(this, false)}
                 style={styles.menuItem}
                 className="list-group-item list-group-item-action has-pointer">
                <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
                {t("Mark as Read")}
              </a>
            }

            {canDeleteDocument &&
              <a onClick={this.onDeleteDocumentClick.bind(this)}
                 style={styles.menuItemBottom}
                 className="list-group-item list-group-item-action has-pointer assertive-color">
                <i style={styles.menuIcons} className="icon ion-close"/>
                {t("Delete Document")}
              </a>
            }
          </ul>
        </div>
      )
    }

    let isContentWorkspace = _.get(workspace, 'forum_type') === c.FORUM_TYPES.FORUM_CONTENT;
    let isHost = workspace && !workspace.host_uid;

    return (
      <div>
        <ul className="popover-content list-group">
          <a style={styles.menuHeader}
             className="list-group-item list-group-item-action">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
            {doc.label}
          </a>

          {isHost && !isContentWorkspace &&
            <a onClick={this.showRename.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-compose"/>
              {t("Rename Document")}
            </a>
          }

          {isHost && !isContentWorkspace &&
            <a onClick={this.showDocumentHistory.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
              {t("View Access History")}
            </a>
          }

          {workspace &&
            <a onClick={this.attachToThreadClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-paperclip"/>
              {t("Send to...")}
            </a>
          }

          <a onClick={this.onDownloadClick.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-download"/>
            {t("Download Document")}
          </a>
          {isPreviewable &&
            <a onClick={this.onPreviewClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-eye"/>
              {t("Preview Document")}
            </a>
          }
          {showMetadataButton &&
            <a onClick={this.onSigningMetadataClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-bookmarks-outline"/>
              {t("View signing info")}
            </a>
          }

          <a onClick={this.showDownloadMessageDocuments.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
            {t("Download Message Documents")}
          </a>

          {docInfo && !docInfo.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, true)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
              {t("Mark as Unread")}
            </a>
          }
          {docInfo && docInfo.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, false)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
              {t("Mark as Read")}
            </a>
          }

          {canDeleteDocument &&
            <a onClick={this.onDeleteDocumentClick.bind(this)}
               style={styles.menuItemBottom}
               className="list-group-item list-group-item-action has-pointer assertive-color">
              <i style={styles.menuIcons} className="icon ion-close"/>
              {t("Delete Document")}
            </a>
          }
        </ul>
      </div>
    )
  }

  loadThumbnailIfNeeded(){
    let { doc } = this.props;
    let { thumbnail, thumbnailDocId } = this.state;

    if(doc.doc_id !== thumbnailDocId){
      this.setState({loadingThumbnailDocId : doc.doc_id})

      this.fetchThumbnail()
        .then((res) => {
          this.setState({
            thumbnail : res,
            thumbnailDocId : doc.doc_id
          })
        })
        .finally(() => {
          this.setState({loadingThumbnailDocId : null})
        })
    }
  }

  fetchThumbnail() {
    let {doc, workspace, dm} = this.props;

    return new Promise((resolve, reject) => {

      if(dm){
        sapi.Docs.thumbnail(dm.forum_id, dm.host_uid, doc.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
      else if(workspace){
        sapi.Docs.thumbnail(workspace.forum_id, workspace.host_uid, doc.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
      else{
        reject('No workspace or DM present');
      }
    })
  }

  getDocLabel(){
    let { doc, docInfo } = this.props;
    let label = '';
    if(doc.isPending){
      label = doc.filename;
    }
    else if(docInfo){
      label = docInfo.label;
    }
    else{
      label = doc.label;
    }
    return label;
  }

  areYouTheNextSigner = memoizeOne((doc, accountInfoGuest) => {
    let nextUserToSignIsYou = false;
    if(doc && doc.signer_info && accountInfoGuest) {
      let nextRequestedSignInfo = _.find(doc.signer_info, (info) => info.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED)
      nextUserToSignIsYou = nextRequestedSignInfo && nextRequestedSignInfo.signer_uid === accountInfoGuest.guest_uid;
    }
    return nextUserToSignIsYou;
  })

  getThumbnailMenuContent(){
    let { msg, doc, accountInfoGuest, t } = this.props;
    let { thumbnail, loadingThumbnailDocId } = this.state;
    let label = this.getDocLabel();

    let nextUserToSignIsYou = this.areYouTheNextSigner(doc, accountInfoGuest);

    let data = {
      thumbnail,
      isLoadingThumbnail : loadingThumbnailDocId,
      label,
      nextUserToSignIsYou
    }

    let controls = {
      t,
      hideMenu : this.hideThumbnailMenu.bind(this),
      onPreviewClick : this.onPreviewClick.bind(this),
      onDownloadClick : this.onDownloadClick.bind(this),
      fulfillSignatureRequestClick : () => this.props.fulfillSignatureRequestClick(msg, doc)
    }

    return popupHelper.getThumbnailPreviewContent(null, data, controls)
  }

  render() {
    let { doc, docInfo, showControls, t } = this.props;

    let label = this.getDocLabel();
    let isDeleted = !label && !doc.isPending;

    let showingDocGearMenu = this.props.popoverAction.isShowing(c.popovers.MSG_DOC_GEAR_MENU, doc.doc_id);

    return (
      <span key={doc.doc_id} className="d-inline-block w-100" style={styles.docWrap}>
          <span className="d-inline-block w-100">
            {label &&
            <span style={styles.docTopRow} className="d-inline-block w-100 mb-0">

              <span className={`d-inline-block w-100 ${doc.isPending ? 'secondary-text-color' : 'primary-color'}`}
                    style={styles.docTitle}>

                {!!(docInfo && docInfo.notify_flag) &&
                  <i style={{marginRight: '5px', lineHeight: '28px'}}
                     className={'icon ion-record primary-color'}/>
                }
                {!(docInfo && docInfo.notify_flag) &&
                  <i style={{marginRight: '5px', lineHeight: '28px'}}
                     className={'icon ion-record transparent-color'}/>
                }

                <VFPopover isPopoverOpen={this.props.popoverAction.isShowing(c.popovers.MSG_DOC_THUMBNAIL_PREVIEW, doc.doc_id)}
                           onClickOutside={this.hideThumbnailMenu.bind(this)}
                           positions={['top', 'bottom', 'left', 'right']}
                           reposition={true}
                           align="start"
                           containerClassName="preview-container-cls"
                           getMenuContent={this.getThumbnailMenuContent.bind(this)}>
                  <i onClick={this.onDocClick.bind(this)}
                     className="icon ion-document-text has-pointer"
                     style={{
                       fontSize: '30px',
                       verticalAlign: 'middle',
                       marginRight: '10px',
                     }}/>
                </VFPopover>
                <div className="d-inline-block align-top thread-doc-wrap">
                    <span onClick={this.onDocClick.bind(this)}
                          className={`${doc.isPending ? 'default-cursor' : 'has-pointer'}`}>
                      <VFMiddleTruncate
                        text={label}
                        start={3}
                        end={3}
                        drawGearIcon={() => {
                          if(!doc.isPending && !!(showControls || showingDocGearMenu)){
                            return (
                              <VFPopover isPopoverOpen={showingDocGearMenu}
                                         onClickOutside={this.hidePopover.bind(this)}
                                         reposition={true}
                                         positions={['top', 'bottom']}
                                         getMenuContent={this.getDocMenuPopoverContent.bind(this)}>
                                <i className={'icon item-menu-icon ion-gear-b align-top primary-color has-pointer'}
                                   onClick={this.showMenu.bind(this)}
                                   style={{
                                     fontSize: '16px',
                                     marginLeft: '5px',
                                     display: 'inline-block',
                                   }}/>
                              </VFPopover>
                            )
                          }
                          else{
                            return null;
                          }
                        }}/>
                    </span>
                  </div>
              </span>

            </span>
            }
            {isDeleted &&
            <span style={styles.docTopRow} className="mb-0">
              <span style={styles.docTitleDeleted}>({t("document deleted")})</span>
            </span>
            }
            {!doc.isPending && !isDeleted &&
            <DocSigningStatus doc={doc}
                              msg={this.props.msg}
                              cancelSignatureRequestClick={this.props.cancelSignatureRequestClick}
                              completeRequestClick={this.props.completeRequestClick}
                              fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                              workspace={this.props.workspace}
                              dm={this.props.dm} />
            }
          </span>
      </span>
    )
  }
}

const styles = {
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },
  docWrap : {
    marginTop: '5px',
    marginBottom : '5px'
  },
  docTopRow : {
    lineHeight : '16px',
    maxWidth : '100%'
  },
  docTitle : {
    fontSize : '14px',
    lineHeight: '24px',
    fontWeight : 'bold',
    color : colors.DARK,
    wordBreak: 'break-all'
  },
  docTitleDeleted : {
    fontSize : '12px',
    lineHeight: '20px',
    color : colors.SECONDARY_TEXT
  },
  docSize : {
    fontSize: '12px',
    color : colors.SECONDARY_TEXT
  }
}

ThreadDoc.propTypes = {
  msg : PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  showControls : PropTypes.bool,
  docInfo : PropTypes.object,
  completeRequestClick: PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  cancelSignatureRequestClick : PropTypes.func.isRequired,
  onAttachToThread : PropTypes.func,
  onPdfSubmit : PropTypes.func,
  docClick : PropTypes.func.isRequired,
  dm : PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    accountInfo : state.shared.accountInfo,
    accountInfoGuest : state.shared.accountInfoGuest,
    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress,
    activeThread : state.thread.activeThread,
    activeThreadMesgEditFlag : state.thread.activeThreadMesgEditFlag,
    showingPopoverKey : state.popover.showingPopoverKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url)),
    threadAction : {...threadActions.mapToDispatch(dispatch)},
    popoverAction : {...popoverActions.mapToDispatch(dispatch)},
    modalAction: {...modalActions.mapToDispatch(dispatch)},
    workspaceAction: {...workspaceActions.mapToDispatch(dispatch)}
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ThreadDoc));
